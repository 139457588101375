import React from 'react';
import { Form } from 'react-bootstrap';
import type { MultiValue } from 'react-select';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateProjectWithTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import DictionarySelector from '../Selects/DictionarySelector';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import EmployeeSelector from '../Selects/EmployeeSelector';
import { Option } from '../Selects/Selector';
import { useTranslation } from '../../../common/hooks/useTranslation';
import ProfileSelector from '../Selects/ProfileSelector';
import { usePokCore } from '../../../common/hooks/usePokCore';

import { ProjectDatesForm } from './ProjectDatesForm';

interface ProjectMainFieldsForm3Props {
  project: CreateUpdateProjectWithTeamDto;
  readOnly?: boolean;
  isCreatedFromOrder?: boolean;
  budgetId?: string;
  propertyChange?: (obj: Partial<CreateUpdateProjectWithTeamDto>) => void;
  onChangeStartDate?: (date: Date) => void;
}

export const ProjectMainFieldsForm3: React.FC<
  ProjectMainFieldsForm3Props
> = props => {
  const { t, tk } = useTranslation('projectLng');
  const pok = usePokCore();

  const handleEmployeeSelected = async (
    option: Option | MultiValue<Option> | null | undefined,
  ) => {
    if (option && (option as Option)?.value) {
      if (props.propertyChange) {
        props.propertyChange({ responsibleOwnerId: (option as Option)?.value });
      }
    }
  };

  return (
    <>
      <FormRow controlId="country" label={t(tk.projects.country)}>
        <DictionarySelector
          dictionary="Kraj/rynek"
          value={props.project.countryDictionaryId}
          clearable={true}
          readOnly={props.readOnly}
          onChange={option =>
            props.propertyChange &&
            props.propertyChange({
              countryDictionaryId: option?.value,
            })
          }
        />
      </FormRow>
      {!props.readOnly && !props.project.id && (
        <FormRow
          controlId="profileId"
          label={`${t(tk.common.profile)} ${t(tk.prLang.estimate2)}`}
        >
          <ProfileSelector
            provider={pok.profiles.getAllByCompanyIdsTeamIdsAuth}
            companyIds={
              props.project.companyId ? [props.project.companyId] : []
            }
            teamIds={props.project.teamIds}
            budgetId={props.budgetId}
            readOnly={props.project.teamIds.length === 0}
            onChange={option =>
              props.propertyChange &&
              props.propertyChange({
                profileId: option ? (option as Option).value : '',
              })
            }
          />
        </FormRow>
      )}
      {!props.readOnly && (
        <ProjectDatesForm
          project={props.project}
          propertyChange={props.propertyChange}
          startDateChanged={props.onChangeStartDate}
        />
      )}
      <FormRow
        controlId="purchaserDocNumber"
        label={t(tk.projects.purchaserDocNumber)}
      >
        <Form.Control
          type="text"
          value={props.project.purchaserDocNumber || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ purchaserDocNumber: e.target.value })
          }
          disabled={props.readOnly}
        />
      </FormRow>
      <FormRow controlId="notes" label={t(tk.projects.note)}>
        <DynamicTextarea
          value={props.project.notes || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ notes: e.target.value })
          }
          disabled={props.readOnly}
        />
      </FormRow>
      {props.project.id && (
        <FormRow
          controlId="responsibleOwner"
          label={t(tk.projects.responsiblePerson)}
        >
          <EmployeeSelector
            value={props.project.responsibleOwnerId || ''}
            onChange={handleEmployeeSelected}
            readOnly={props.readOnly}
          />
        </FormRow>
      )}
      <FormRow controlId="labelId" label={t(tk.prLang.projectLabel)}>
        <DictionarySelector
          dictionary="Etykieta kampanii"
          value={props.project.labelDictionaryId}
          clearable={true}
          readOnly={props.readOnly}
          onChange={option =>
            props.propertyChange &&
            props.propertyChange({
              labelDictionaryId: option?.value,
            })
          }
        />
      </FormRow>
    </>
  );
};
