import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateSymphonyClientDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import FormRow from '../../../app/components/FormRow';
import CompanySelector from '../Selects/CompanySelector';
import { Option } from '../Selects/Selector';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface SymphonyEditorProps {
  id?: string;
  symphonyClient: CreateUpdateSymphonyClientDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    symphonyClient: CreateUpdateSymphonyClientDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const SymphonyEditor: React.FC<SymphonyEditorProps> = props => {
  const [symphonyClient, setSymphonyClient] = useState(props.symphonyClient);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('fvsLng');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setSymphonyClient(props.symphonyClient);
    }
  }, [props.symphonyClient, props.show]);

  const createSymphonyClient = async () => {
    setIsLoading(true);
    props
      .onCompleted(symphonyClient, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateSymphonyClientDto>) => {
    setSymphonyClient({ ...symphonyClient, ...obj });
  };

  useEffect(() => {
    if (!props.id && !symphonyClient.companyId) {
      setSymphonyClient({
        ...symphonyClient,
        ...{ companyId: pok.getCompanyId() },
      });
    }
  }, [pok, setSymphonyClient, symphonyClient, props.id]);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? t(tk.symphony.symphonyEdit)
              : t(tk.symphony.symphonyAdd)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-4 mb-3">
            <FormRow controlId="code" label={t(tk.symphony.code)}>
              <Form.Control
                type="number"
                min={0}
                value={symphonyClient.code || ''}
                onChange={e =>
                  propertyChange({ code: e.target.value || undefined })
                }
              />
            </FormRow>
            <FormRow controlId="company" label={t(tk.common.company)}>
              <CompanySelector
                value={symphonyClient.companyId}
                onChange={option =>
                  propertyChange({ companyId: (option as Option).value })
                }
                readOnly
              />
            </FormRow>
            <FormRow controlId="active" label={t(tk.common.activeHe)}>
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={symphonyClient.active ?? true}
                onChange={e => propertyChange({ active: e.target.checked })}
              />
            </FormRow>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={pok.symphonyClients.getLastEditor}
            />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            {t(tk.button.close)}
          </Button>
          <Button
            variant="primary"
            onClick={createSymphonyClient}
            isLoading={isLoading}
          >
            {t(tk.button.save)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
