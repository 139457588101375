import { Buffer } from 'buffer';

import mime from 'mime';
import { saveAs } from 'file-saver';

import {
  GetAttachmentDto,
  DefaultApi,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import {
  AttachmentFileContent,
  CreateUpdateAttachmentDto,
  CreateUpdateAttachmentDtoEnObjectTypeEnum,
  CreateUpdateAttachmentDtoFromJSON,
} from '../autogenerated/pokApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { AttachmentSchema } from '../validation/schemas';
import { AssociateDocumentsWithFilter } from '../../../pok/components/AssociatedDocuments/AssociateDocumentsTypes';
import { NotificationsProviderType } from '../../notifications';
import { PokCoreContextType } from '../pokCore';
import { AttachmentObjectType } from '../validation/enums';

export interface AttachmentContextInterface {
  getAllForObject: (
    objectType: CreateUpdateAttachmentDtoEnObjectTypeEnum,
    objectId: string,
  ) => Promise<GetAttachmentDto[]>;
  getAllForObjectAndShortname: (
    objectType: CreateUpdateAttachmentDtoEnObjectTypeEnum,
    objectId: string,
    shortname: string,
  ) => Promise<GetAttachmentDto[]>;
  getAllForObjectInCompany: (
    objectType: CreateUpdateAttachmentDtoEnObjectTypeEnum,
    objectId: string,
    companyId: string,
  ) => Promise<GetAttachmentDto[]>;
  getLastForProjectByShortname: (
    shortname: string,
    projectId: string,
  ) => Promise<GetAttachmentDto[]>;
  getFile: (id: string) => Promise<AttachmentFileContent>;
  getFileByObjectIdAndObjectType: (
    id: string,
    type: string,
  ) => Promise<AttachmentFileContent>;
  saveFileInBrowser: (
    dto: GetAttachmentDto,
    method: 'download' | 'open' | string,
  ) => void;
  createVoid: (attachment: CreateUpdateAttachmentDto) => Promise<void>;
  create: (
    attachment: CreateUpdateAttachmentDto,
    onlyOneByObjectType?: boolean,
  ) => Promise<GetAttachmentDto>;
  update: (id: string, attachment: CreateUpdateAttachmentDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  getZipByAttachmentIds: (
    projectNumber: string,
    attachmentsIds: string[],
  ) => Promise<object>;
}

export type Attachment = GetAttachmentDto | CreateUpdateAttachmentDto;

export const newAttachment = () => {
  return CreateUpdateAttachmentDtoFromJSON({});
};

export const newSimpleAttachment = async (
  objectId: string,
  objectType: AttachmentObjectType,
  pok: PokCoreContextType,
) => {
  const dictionary = await pok.dictionaries.getByDictionaryTypeAndShortname(
    'Załączniki',
    'NOTE',
  );
  if (!dictionary) {
    throw new Error('Nie znaleziono słownika Załączniki: NOTE');
  }
  const converted = CreateUpdateAttachmentDtoFromJSON({});
  converted.objectId = objectId;
  converted.enObjectType = objectType;
  converted.name = 'Notatka';
  converted.companyId = pok.getCompanyId();
  converted.dictionaryTypeId = dictionary.id;
  return converted;
};

export const convert = (
  json: GetAttachmentDto,
  dictionaryShortname: string,
) => {
  if (!json) {
    return CreateUpdateAttachmentDtoFromJSON({});
  }
  const converted = CreateUpdateAttachmentDtoFromJSON(json);
  converted.companyId = json.company.id;
  converted.dictionaryTypeId = json.dictionaryType.id;
  converted.dictionaryShortname = dictionaryShortname;

  return converted;
};

export const validate = (attachment: CreateUpdateAttachmentDto) =>
  validateAgainst(AttachmentSchema, attachment);

export const AttachmentContext = (api: DefaultApi) => {
  return {
    getAllForObject: (
      objectType: CreateUpdateAttachmentDtoEnObjectTypeEnum,
      objectId: string,
    ) => api.attachmentControllerGetAllForObject(objectType, objectId),
    getAllForObjectAndShortname: (
      objectType: CreateUpdateAttachmentDtoEnObjectTypeEnum,
      objectId: string,
      shortname: string,
    ) =>
      api.attachmentControllerGetAllForObjectAndShortname(
        objectType,
        objectId,
        shortname,
      ),
    getAllForObjectInCompany: (
      objectType: CreateUpdateAttachmentDtoEnObjectTypeEnum,
      objectId: string,
      companyId: string,
    ) =>
      api.attachmentControllerGetAllForObjectInCompany(
        objectType,
        objectId,
        companyId,
      ),
    getLastForProjectByShortname: (shortname: string, projectId: string) =>
      api.attachmentControllerGetLastForProjectByShortname(
        shortname,
        projectId,
      ),
    getFile: (id: string) => api.attachmentControllerGetFileDownload(id),
    getFileByObjectIdAndObjectType: (id: string, type: string) =>
      api.attachmentControllerGetFileDownloadByObjectIdAndObjectType(id, type),
    createVoid: async (attachment: CreateUpdateAttachmentDto) => {
      await api.attachmentControllerCreate(attachment);
    },
    create: (
      attachment: CreateUpdateAttachmentDto,
      onlyOneByObjectType?: boolean,
    ) => {
      return api.attachmentControllerCreate(attachment, onlyOneByObjectType);
    },
    update: (id: string, attachment: CreateUpdateAttachmentDto) =>
      api.attachmentControllerUpdate(id, attachment),
    delete: (id: string) => api.attachmentControllerDelete(id),
    saveFileInBrowser: (
      dto: GetAttachmentDto,
      method: 'download' | 'open' | string,
    ) => {
      void api.attachmentControllerGetFileDownload(dto.id).then(fileContent => {
        const blob = new Blob(
          [Buffer.from(fileContent.buffer as ArrayBuffer)],
          {
            type: mime.getType(dto?.name || '') || undefined,
          },
        );
        if (method === 'open') {
          window.open(URL.createObjectURL(blob));
        } else {
          saveAs(blob, dto.name);
        }
      });
    },
    getLastEditor: (id: string) => api.attachmentControllerGetLastEditor(id),
    getZipByAttachmentIds: (projectNumber: string, attachmentsIds: string[]) =>
      api.attachmentControllerGetZipByAttachmentIds(
        projectNumber,
        attachmentsIds,
      ),
  };
};

export const forAssociatedDocuments = (
  pok: PokCoreContextType,
  notifications: NotificationsProviderType,
  attachments: GetAttachmentDto[],
  action?: () => void,
) => {
  const lang = pok.getLanguage();
  const label = (attachment: GetAttachmentDto) =>
    `${lang === 'PL' ? attachment.dictionaryType.value : attachment.dictionaryType.valueEn}: ${
      attachment.description || attachment.name
    } ${
      attachment.company?.shortname
        ? '(' + attachment.company?.shortname + ')'
        : ''
    }`;

  const addMethod = async (attachment: CreateUpdateAttachmentDto) => {
    await notifications.onPromise(
      pok.attachments.createVoid(attachment),
      action,
    );
  };

  const deleteMethod = async (id: string) => {
    await notifications.onPromise(pok.attachments.delete(id), action);
  };

  const getMethod = (attachment: GetAttachmentDto, method: string) => {
    pok.attachments.saveFileInBrowser(attachment, method);
  };

  const updateMethod = async (
    id: string,
    attachment: CreateUpdateAttachmentDto,
  ) => {
    await notifications.onPromise(
      pok.attachments.update(id, attachment),
      action,
    );
  };

  return AssociateDocumentsWithFilter({
    documents: attachments,
    labelGenerator: label,
    new: newAttachment,
    validate: validate,
    add: addMethod,
    delete: deleteMethod,
    get: getMethod,
    update: updateMethod,
    getLastEditor: (id: string) => pok.attachments.getLastEditor(id),
  });
};

export type MemoizedAttachment = {
  id: string;
  existing?: GetAttachmentDto;
  type?: string;
  getInjected?: (dto: GetAttachmentDto, method: string) => void;
  newOne?: CreateUpdateAttachmentDto;
};

export class ObjectType {
  static Budget: CreateUpdateAttachmentDtoEnObjectTypeEnum =
    CreateUpdateAttachmentDtoEnObjectTypeEnum.Budget;
  static Project: CreateUpdateAttachmentDtoEnObjectTypeEnum =
    CreateUpdateAttachmentDtoEnObjectTypeEnum.Project;
  static PurchaseInvoice: CreateUpdateAttachmentDtoEnObjectTypeEnum =
    CreateUpdateAttachmentDtoEnObjectTypeEnum.PurchaseInvoice;
}
