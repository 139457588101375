export default {
  common: {
    locale: 'pl-PL',
    localeShort: 'pl',
    company: 'Spółka',
    companies: 'Spółki',
    team: 'Zespół',
    teams: 'Zespoły',
    budget: 'Budżet',
    client: 'Kontrahent',
    profile: 'Profil',
    currency: 'Waluta',
    employee: 'Pracownik',
    employees: 'Pracownicy',
    user: 'Użytkownik',
    year: 'Rok',
    month: 'Miesiąc',
    monthShort: 'm-c',
    monthShort2: 'm-ce',
    monthShort6: 'm-cy',
    above: 'powyżej',
    status: 'Status',
    lp: 'L.p.',
    name: 'Nazwa',
    shortname: 'Skrót',
    description: 'Opis',
    sysNumber: 'Nr sys.',
    sysNumberLowerCase: 'nr sys.',
    history: 'Historia',
    lastModification: 'Ostatnia modyfikacja',
    who: 'Kto',
    when: 'Kiedy',
    noInformation: 'Brak informacji',
    creation: 'Utworzenie',
    activeShe: 'Aktywna',
    activeHe: 'Aktywny',
    nonActiveHe: 'Nieaktywny',
    yes: 'Tak',
    no: 'Nie',
    yesUpperCase: 'TAK',
    noUpperCase: 'NIE',
    notDefined: 'ND',
    partly: 'Częściowo',
    sumUpperCase: 'SUMA',
    sum: 'Suma',
    creator: 'Autor',
    creationDate: 'Data utworzenia',
    emails: 'Adresy e-mail',
    noData: 'Brak danych',
    downloadDocs: 'Pobierz dokumenty',
    selectMonth: 'Wybierz miesiąc',
    positionName: 'Nazwa pozycji',
    country: 'Państwo',
    purchaser: 'Zamawiający',
    brand: 'Brand',
    profilePosition: 'Pozycja profilu',
    type: 'Typ',
    number: 'Numer',
    startDate: 'Data rozpoczęcia',
    endDate: 'Data zakończenia',
    title: 'Tytuł',
    openNewWindow: 'Otwórz w nowym oknie',
    addDate: 'Data dodania',
    pok: 'Platforma Obsługi Kampanii',
    help: 'Pomoc',
    openNewCard: 'Otwórz w nowej karcie',
    invoiceType: 'Typ faktury',
    issueDate: 'Data wystawienia',
    saleDate: 'Data sprzedaży',
    exchangeRate: 'Kurs waluty',
    sales: 'Sprzedaż',
    amount: 'Kwota',
    sysError: 'Błąd systemu',
    alertNotifyAdmin:
      'Jeśli zdarzenie będzie się powtarzać, proszę powiadomić Administratora Systemu.',
    contractor: 'Kontrahent',
  },
  component: {
    pointOut: 'Wskaż...',
    enter: 'Wpisz...',
    quickSearch: 'Szybkie wyszukiwanie',
    searchInProgress: 'Trwa wyszukiwanie...',
    numberOfRows: 'Liczba wierszy',
    cleanFilter: 'Wyczyść filtr',
    noValues: 'Brak wartości',
    selectAll: 'Zaznacz wszystko',
    deselectAll: 'Odznacz wszystko',
    selected: 'Wybrano',
    noCurrency: 'Brak ustawionej waluty w spółce!',
  },
  button: {
    clean: 'Wyczyść',
    export: 'Eksportuj',
    close: 'Zamknij',
    save: 'Zapisz',
    delete: 'Usuń',
    add: 'Dodaj',
    edit: 'Edycja',
    addDots: 'Dodaj...',
    open: 'Otwórz',
    cancel: 'Anuluj',
    find: 'Szukaj',
    download: 'Pobierz',
    view: 'Pokaż',
    change: 'Zmień',
    restore: 'Przywróć dane',
    refresh: 'Odśwież',
  },
  attachment: {
    attachments: 'Załączniki',
    attachment: 'Załącznik',
    attachmentNew: 'Nowy załącznik / notatka',
    calculatorCurrency: 'Kalkulator walut',
    note: 'Notatka',
    noteNew: 'Nowa notatka',
    type: 'Typ',
    title: 'Tytuł',
    file: 'Plik',
    invoicing: 'Fakturujący',
    positionsSum: 'Powiązane pozycje - suma zakupu',
    relatedItems: 'Powiązane pozycje',
    addNote: 'Proszę podać notatkę.',
    askDeleteNote: 'Czy na pewno usunąć notatkę?',
    askDeleteAttachment: 'Czy na pewno usunąć załącznik?',
    noteAdded: 'Notatka dodana {{date}}',
    attachmentAdded: 'Załącznik dodany {{date}}',
    chooseFile: 'Wybierz plik',
    deleteAttachment: 'Usuń załącznik',
    deleteNote: 'Usuń notatkę',
    noFileSelected: 'Nie wybrano pliku',
    relatedTo: 'Powiązany z',
    noNotes: 'Brak notatek',
    noAttachments: 'Brak załączników',
    warningAnotherUserNote:
      'Prawdopodobnie inny użytkownik dodał własną notatkę. Zamknij okno i wejdź ponownie w notatkę.',
    newNote: 'Nowa notatka {{text}}',
    editNote: 'Edycja notatki {{text}}',
    comment: 'Komentarz',
    downloadError: 'Błąd przy pobieraniu załączników.',
    FVZLacks: 'Braki FVZ w pozycjach',
    withoutTv: 'Profile oprócz TV',
    withTv: 'TV',
    sendRemindersWithoutTv: 'Wysyłanie przypomnień (oprócz TV)',
    sendRemindersTv: 'Wysyłanie przypomnień (TV)',
    fileToLoad: 'Plik do załadowania',
  },
  email: {
    send: 'Wyślij',
    create: 'Utwórz e-mail',
    recipients: 'Odbiorcy',
    sendToTeam: 'Wyślij e-mail na adres zespołu',
    message: 'Wiadomość',
  },
  memo: {
    loading: 'Trwa ładowanie notatek...',
    createNewMessage: 'Utwórz nową wiadomość do wskazanego użytkownika',
    content: 'Treść',
    giveContent: 'Podaj treść',
    purchaseInvoice: 'Faktura zakupu nr sys.',
    saleInvoice: 'Faktura sprzedaży nr sys.',
    specialInvoicing: 'Niestandardowe fakturowanie nr sys.',
    memosNumber: 'Liczba notatek',
    noMemos: 'Brak notatek',
    information:
      'Notatki związane z {{subject}} będą widoczne w oknie notatek oraz dla wszystkich pracowników spółki. Tylko komentujący użytkownicy będą otrzymywać powiadomienia w przypadku zarejestrowania nowej wiadomości.',
    contentPlhdr: 'Treść...',
    noEmptyMessage: 'Treść wiadomości nie może być pusta',
    all: 'Wszystkie',
    private: 'Prywatne',
    documents: 'Dokumenty',
    threadsCount: 'Liczba wszystkich wątków: ',
    newMessageThreadsCount: 'Liczba wątków z nowymi wiadomościami: ',
    noMessageInSystem:
      'W systemie nie ma jeszcze wiadomości, które możemy tutaj pokazać',
    newMessage: 'Nowa wiadomość',
    turnOff: 'Wyłącz',
    turnOn: 'Włącz',
    newMessageSound: 'dźwięk przychodzącej wiadomości',
    to: 'Do',
  },
  concurrent: {
    editionMode: 'jest w trybie edycji.',
    makingChanges: 'właśnie wprowadza zmiany w danych',
    thisFVS: 'tą fakturą sprzedaży',
    thisFVZ: 'tą fakturą zakupu',
    thisNotStandardInvoices: 'tym niestandardowym fakturowaniem',
  },
} as const;
