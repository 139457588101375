import React from 'react';
import { Form } from 'react-bootstrap';

import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';

import ProfileFieldName from './ProfileFieldName';

interface ProfileParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  lang: string;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const ProfileParams: React.FC<ProfileParamsProps> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    if (props.propertyChange) {
      props.propertyChange(obj);
    }
  };

  return (
    <>
      {schema?.medium && (
        <ProfileFieldName
          controlId="medium"
          schema={schema?.medium}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Zagraniczne - medium"
            value={props.estimateItem?.mediumId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                mediumId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.ownName && (
        <ProfileFieldName
          controlId="ownName"
          schema={schema?.ownName}
          lang={props.lang}
        >
          <Form.Control
            type="text"
            value={props.estimateItem?.ownName ?? ''}
            onChange={e =>
              propertyChange({
                ownName: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.isUpSell && (
        <ProfileFieldName
          controlId="isUpSell"
          schema={schema?.isUpSell}
          lang={props.lang}
        >
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={props.estimateItem?.isUpSell}
            onChange={e => propertyChange({ isUpSell: e.target.checked })}
          />
        </ProfileFieldName>
      )}
    </>
  );
};
