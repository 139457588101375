import { VatEnum } from '../common/pokCore/validation/enums';

import mathUtils from './mathUtils';

const calculateVatAmount = (netAmount: number | string, coefficient: number) =>
  mathUtils
    .round(mathUtils.multiply(mathUtils.round(netAmount), coefficient))
    .toFixed(2);

export const getVatAmountByVat = (
  netAmount: number | string,
  vat: VatEnum,
): string => {
  switch (vat) {
    case VatEnum['23%']:
      return calculateVatAmount(netAmount, 0.23);
    case VatEnum['19%']:
      return calculateVatAmount(netAmount, 0.19);
    case VatEnum['8%']:
      return calculateVatAmount(netAmount, 0.08);
    case VatEnum['5%']:
      return calculateVatAmount(netAmount, 0.05);
    case VatEnum['0%']:
    case VatEnum['zw.']:
    case VatEnum['np.']:
      return calculateVatAmount(netAmount, 0);
    default:
      throw new Error('Invalid VatEnum option');
  }
};

export const getGrossPrice = (netAmount: number | string, vat: VatEnum) =>
  mathUtils.add(getVatAmountByVat(netAmount, vat as VatEnum), netAmount);
