/*eslint max-lines-per-function: ["error", 180]*/
import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import type { MultiValue } from 'react-select';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdateClientDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow, { FormRow66 } from '../../../app/components/FormRow';
import { usePokCore } from '../../../common/hooks/usePokCore';
import CountrySelector from '../Selects/CountrySelector';
import { Option } from '../Selects/Selector';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import CompanySelector from '../Selects/CompanySelector';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { PolishClient } from './PolishClient';
import { ForeignClient } from './ForeignClient';
import { ClientEditorControls } from './ClientEditorControls';

interface ClientEditorProps {
  id?: string;
  systemNumber?: number;
  client: CreateUpdateClientDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    client: CreateUpdateClientDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const ClientEditor: React.FC<ClientEditorProps> = props => {
  const [client, setClient] = useState(props.client);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const pok = usePokCore();
  const { t, tk } = useTranslation('clientLng');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setClient(props.client);
    }
  }, [props.client, props.show]);

  const propertyChange = (obj: Partial<CreateUpdateClientDto>) => {
    setClient({ ...client, ...obj });
  };

  const findByNip = async (nip?: string) => {
    if (!nip) {
      setSaveErrors([t(tk.client.enterNIP)]);
      setShowSaveErrors(true);
      return;
    }
    try {
      const nipClient = await pok.clients.getByNip(nip.trim());
      if (nipClient) {
        setShowSaveErrors(false);
        setClient({
          ...client,
          name: nipClient.name || '',
          postcode: nipClient.postcode || '',
          place: nipClient.place || '',
          street: nipClient.street || '',
          numbers: nipClient.numbers || '',
          flat: nipClient.flat || '',
          shortname: '',
        });
      }
    } catch (response) {
      const errors = await responseErrors(response as ResponseError);
      setSaveErrors(errors);
      setShowSaveErrors(true);
    }
  };

  const handleCompanyChange = (option: Option | MultiValue<Option>) => {
    const companyId = option ? (option as Option).value : undefined;
    propertyChange({
      companyId,
      isRelated: !!companyId,
    });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? `${t(tk.client.clientEdit)} ${props.systemNumber}`
              : t(tk.client.clientAdd)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-4 mb-3">
            <FormRow controlId="country" label={t(tk.client.country)}>
              <CountrySelector
                value={client?.countryId}
                onChange={data =>
                  propertyChange({
                    countryId: data.countryId,
                    countryIsMain: data.isMain,
                  })
                }
              />
            </FormRow>
            <FormRow controlId="company" label={t(tk.common.company)}>
              <CompanySelector
                clearable={true}
                loadData={() =>
                  pok.companies.getAllNotAssignedAndCurrent(
                    props.client.companyId,
                  )
                }
                value={client.companyId}
                onChange={handleCompanyChange}
              />
            </FormRow>
          </Form>
          {client.countryIsMain ? (
            <PolishClient
              client={client}
              propertyChange={propertyChange}
              findByNip={findByNip}
            />
          ) : (
            <ForeignClient client={client} propertyChange={propertyChange} />
          )}
          <hr />
          <Form className="d-grid gap-4 mb-4 mt-4">
            <FormRow
              controlId="dealGroupNumber"
              label={t(tk.client.dealGroupNumber)}
            >
              <Form.Control
                className="d-grid mb-2 mt-2"
                type="number"
                value={client?.dealGroupNumber ?? ''}
                onChange={e =>
                  propertyChange({ dealGroupNumber: parseInt(e.target.value) })
                }
              />
            </FormRow>
            <Row>
              <Col md={6}>
                <FormRow66 controlId="isRelated" label={t(tk.client.isRelated)}>
                  <Form.Check
                    className="d-grid mb-2 mt-2"
                    type="checkbox"
                    checked={client?.isRelated ?? false}
                    onChange={e =>
                      propertyChange({ isRelated: e.target.checked })
                    }
                    disabled={!!client.companyId}
                  />
                </FormRow66>
              </Col>
              <Col md={6}>
                <FormRow66
                  controlId="notToSymphony"
                  label={t(tk.client.notToSymphony)}
                >
                  <Form.Check
                    className="d-grid mb-2 mt-2"
                    type="checkbox"
                    checked={client?.notToSymphony ?? false}
                    onChange={e =>
                      propertyChange({ notToSymphony: e.target.checked })
                    }
                  />
                </FormRow66>
              </Col>
            </Row>
          </Form>

          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <ClientEditorControls
            {...props}
            client={client}
            setSaveErrors={setSaveErrors}
            setShowSaveErrors={setShowSaveErrors}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
