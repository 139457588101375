import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import { GetExcelConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';

interface HeaderSearchProps {
  config: GetExcelConfigDto;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const HeaderSearch: React.FC<HeaderSearchProps> = props => {
  const { t, tk } = useTranslation('excelImportLng');
  const pok = usePokCore();
  const lang = pok.getLanguage();

  const columns: GridColumn[] = [
    { header: t(tk.config.columnInPOK), property: 'pokColumnName' },
    { header: t(tk.config.columnInExcel), property: 'excelColumnName' },
    { header: t(tk.config.columnOrder), property: 'order' },
  ];

  const data = props.config.excelConfigHeaders
    ?.filter(header => header.active === true)
    .map(header => {
      return {
        key: header.id,
        values: {
          pokColumnName:
            lang === 'PL' ? header.pokColumnNamePl : header.pokColumnNameEn,
          excelColumnName: header.excelColumnName,
          order: header.order,
        },
        orderByStringValues: {
          order: header.order?.toString().padStart(10, '0') ?? '9999999999',
        },
      };
    });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={true}
      initialOrderColumn="order"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.config.noColumnMapping)}
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
