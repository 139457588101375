/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdatePositionDto
 */
export interface CreateUpdatePositionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePositionDto
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePositionDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePositionDto
     */
    groupName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePositionDto
     */
    dictionaryId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePositionDto
     */
    positionType: CreateUpdatePositionDtoPositionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePositionDto
     */
    teamId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdatePositionDto
     */
    teamIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdatePositionDto
     */
    companyIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdatePositionDto
     */
    profileIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdatePositionDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdatePositionDto
     */
    notPurchaseDeficiencies: boolean;
}


/**
 * @export
 */
export const CreateUpdatePositionDtoPositionTypeEnum = {
    Standard: 'Standard',
    Commission: 'Commission',
    TransferredCommission: 'TransferredCommission'
} as const;
export type CreateUpdatePositionDtoPositionTypeEnum = typeof CreateUpdatePositionDtoPositionTypeEnum[keyof typeof CreateUpdatePositionDtoPositionTypeEnum];


/**
 * Check if a given object implements the CreateUpdatePositionDto interface.
 */
export function instanceOfCreateUpdatePositionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "groupName" in value;
    isInstance = isInstance && "dictionaryId" in value;
    isInstance = isInstance && "positionType" in value;
    isInstance = isInstance && "teamIds" in value;
    isInstance = isInstance && "companyIds" in value;
    isInstance = isInstance && "profileIds" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "notPurchaseDeficiencies" in value;

    return isInstance;
}

export function CreateUpdatePositionDtoFromJSON(json: any): CreateUpdatePositionDto {
    return CreateUpdatePositionDtoFromJSONTyped(json, false);
}

export function CreateUpdatePositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdatePositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'name': json['name'],
        'groupName': json['groupName'],
        'dictionaryId': json['dictionaryId'],
        'positionType': json['positionType'],
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
        'teamIds': json['teamIds'],
        'companyIds': json['companyIds'],
        'profileIds': json['profileIds'],
        'active': json['active'],
        'notPurchaseDeficiencies': json['notPurchaseDeficiencies'],
    };
}

export function CreateUpdatePositionDtoToJSON(value?: CreateUpdatePositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'name': value.name,
        'groupName': value.groupName,
        'dictionaryId': value.dictionaryId,
        'positionType': value.positionType,
        'teamId': value.teamId,
        'teamIds': value.teamIds,
        'companyIds': value.companyIds,
        'profileIds': value.profileIds,
        'active': value.active,
        'notPurchaseDeficiencies': value.notPurchaseDeficiencies,
    };
}

