import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNavigation } from '../../../common/navigation';
import { FilterColumn } from '../../../common/types/FilterColumn';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { GridGetterFunction } from '../../../common/components/Grid/GridDataTypes';

import { PurchaseInvoiceSearch } from './PurchaseInvoiceSearch';

export const ProjectPurchaseInvoicesView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const { t, tk } = useTranslation('fvzLng');

  const handleClick = (id: string, newWindow?: boolean) => {
    nav.projectPurchaseInvoiceEditor(id, newWindow);
  };

  const getSummary = useMemo(
    () => (filterText?: string, filterColumns?: FilterColumn) =>
      pok.purchaseInvoices.getSumAllWithTeamsForPurchaseInvoices(
        t,
        tk,
        filterText,
        filterColumns,
      ),
    [pok.purchaseInvoices, t, tk],
  );

  const getData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.purchaseInvoices.getAllWithTeamsByPortion(
          t,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok, t],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.finance.purchaseInvoicesToBeClarified)}
        </Card.Title>
        <PurchaseInvoiceSearch
          getData={getData}
          getCount={pok.purchaseInvoices.getAllWithTeamsCount}
          getSummary={getSummary}
          onRowClick={handleClick}
          displayTeamsProjects={true}
          displayLastNote={true}
          withFilterInColumns={true}
          withCountDays={true}
        />
      </Card.Body>
    </Card>
  );
};
