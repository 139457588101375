import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import FormRow from '../../../app/components/FormRow';
import PositionSelector from '../Selects/PositionSelector';
import {
  GetPositionExtendedDtoPositionTypeEnum,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { Option } from '../Selects/Selector';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface PositionChangeFormProps {
  teamIds: string[];
  configId: string;
  positionId: string;
  positionName: string;
  profile: GetProfileDto;
  aggregatedRows: boolean;
  handleUpdate: (
    newPositionId: string,
    newPositionName: string,
    positionType: GetPositionExtendedDtoPositionTypeEnum,
  ) => void;
}
export const PositionChangeForm: React.FC<PositionChangeFormProps> = props => {
  const [newPositionId, setNewPositionId] = useState<string>(props.positionId);
  const [show, setShow] = useState(false);
  const pok = usePokCore();
  const { t, tk } = useTranslation('excelImportLng');

  useEffect(() => {
    setNewPositionId(props.positionId);
  }, [props.positionId]);

  const updateParameter = async () => {
    const position = await pok.positions.getById(newPositionId);
    props.handleUpdate(newPositionId, position.name, position.positionType);
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setShow(true);
  };

  const propertyChange = (option: Option) => {
    setNewPositionId(option.value);
  };

  return (
    <>
      {props.positionName ?? `[${t(tk.import.lack)}]`}
      {props.aggregatedRows ? (
        <></>
      ) : (
        <OverlayTrigger
          overlay={<Tooltip>{t(tk.import.changePosition)}</Tooltip>}
          trigger={['hover', 'hover']}
        >
          <Button
            className="py-0 btn-link btn-transparent"
            onClick={handleClick}
          >
            <Icon.ArrowRepeat size={18} className="icon-in-cell" />
          </Button>
        </OverlayTrigger>
      )}
      {show && (
        <>
          <Modal
            onHide={handleClose}
            show={show}
            centered={true}
            keyboard={true}
            backdrop="static"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t(tk.import.positionChange)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="params" label={t(tk.import.position)}>
                  <PositionSelector
                    teamIds={props.teamIds ? props.teamIds : []}
                    profileId={props.profile?.id}
                    commission={props.profile?.commission}
                    value={newPositionId}
                    onChange={option => propertyChange(option)}
                  />
                </FormRow>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleClose}>
                {t(tk.button.close)}
              </Button>
              <Button variant="primary" onClick={updateParameter}>
                {t(tk.button.save)}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};
