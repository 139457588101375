import { ResponseError } from '../autogenerated/pokApiClient';

import { CustomExceptionType } from './enums';

type ResponseErrorExt = {
  messages: string[];
  errorType?: CustomExceptionType;
};

export const responseErrors = async (e: ResponseError) => {
  const response = e.response;
  if (response === undefined) {
    return ['Błąd połączenia z serwerem'];
  }

  if ('message' in response) {
    if ((response as unknown as Error).message === 'Failed to fetch') {
      return ['Błąd połączenia z serwerem'];
    }
    return [(response as unknown as Error).message];
  }
  const errors: string[] = [];
  try {
    const validationError = await response.json();
    errors.push(
      ...(validationError.messages || [validationError.message].flat()),
    );
  } catch (err) {
    errors.push('Błąd wewnętrzny ' + err);
  }
  return errors;
};

export const responseErrorsExtended = async (
  e: ResponseError,
): Promise<ResponseErrorExt> => {
  const response = e.response;
  if ('message' in response) {
    if ((response as unknown as Error).message === 'Failed to fetch') {
      return { messages: ['Błąd połączenia z serwerem'] };
    }
    return { messages: [(response as unknown as Error).message] };
  }
  const errors: string[] = [];
  try {
    const validationError = await response.json();
    const enumX = getEnumValue(validationError.errorType);
    errors.push(
      ...(validationError.messages || [validationError.message].flat()),
    );
    return { messages: errors, errorType: enumX };
  } catch (err) {
    errors.push('Błąd wewnętrzny ' + err);
  }
  return { messages: errors };
};

function getEnumValue(value?: string): CustomExceptionType | undefined {
  if (!value) {
    return undefined;
  }
  const keys = Object.keys(
    CustomExceptionType,
  ) as (keyof typeof CustomExceptionType)[];

  const matchingKey = keys.find(key => CustomExceptionType[key] === value);

  if (matchingKey) {
    return CustomExceptionType[matchingKey];
  }

  return undefined;
}
