/*eslint complexity: ["error", 32]*/
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';

import {
  GetEstimateItemDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import mathUtils from '../../../utils/mathUtils';

export interface UsePurchaseInvoiceEstimateItemsFiltersReturn {
  filters: FiltersParams;
  handleChangeFilters: (filters: Partial<FiltersParams>) => void;
  applyFiltersToEstimateItems: (
    estimateItems: GetEstimateItemDto[],
  ) => GetEstimateItemDto[];
}

export interface FiltersParams {
  dateFrom: Date | null;
  dateTo: Date | null;
  showSettled: boolean;
  showSpecialTeam: boolean;
}

function usePurchaseInvoiceEstimateItemsFilters(
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto,
  specialTeamIds?: string[],
): UsePurchaseInvoiceEstimateItemsFiltersReturn {
  const prevSellDateRef = useRef<Date | null>(null);
  const [filters, setFilters] = useState<FiltersParams>({
    dateFrom: null,
    dateTo: null,
    showSettled: false,
    showSpecialTeam: true,
  });

  const handleChangeFilters = (obj: Partial<FiltersParams>) => {
    setFilters({ ...filters, ...obj });
  };

  const applyFiltersToEstimateItems = (
    estimateItems: GetEstimateItemDto[],
    onlyTV?: boolean,
  ): GetEstimateItemDto[] => {
    return orderBy(
      estimateItems.filter(estimateItem => {
        let isDateInRange = true;
        let isNotSettled = true;
        let isAgreeWithTV = true;
        let matchSpecialTeam = true;

        if (onlyTV) {
          isAgreeWithTV = estimateItem?.estimateByMonth?.profile?.tvBuying;
        }

        if (filters.dateFrom && filters.dateTo) {
          isDateInRange = moment(estimateItem.estimateByMonth?.date).isBetween(
            moment(filters.dateFrom),
            moment(filters.dateTo),
          );
        }

        if (!filters.showSettled) {
          const notAssignedAmount = mathUtils.round(
            mathUtils.subtract(
              estimateItem.purchaseNetTotal,
              estimateItem.purchaseInvoicesSum,
            ),
          );

          const savedItem =
            extendedPurchaseInvoice?.purchaseInvoiceEstimateItems?.find(
              ({ estimateItemId }) => estimateItemId === estimateItem.id,
            );

          if (
            notAssignedAmount === 0 &&
            (savedItem?.amount === '0' || !savedItem?.amount)
          ) {
            isNotSettled = false;
          }
        }

        if (
          filters.showSpecialTeam &&
          extendedPurchaseInvoice?.salesInvoice?.id &&
          estimateItem?.position?.team?.id
        ) {
          matchSpecialTeam =
            specialTeamIds?.includes(estimateItem?.position?.team?.id) || false;
        }

        return (
          isDateInRange && isNotSettled && isAgreeWithTV && matchSpecialTeam
        );
      }),
      ['estimateByMonth.date', 'position.name', 'id'],
      ['asc', 'asc', 'asc'],
    );
  };

  useEffect(() => {
    if (extendedPurchaseInvoice) {
      if (moment(extendedPurchaseInvoice.sellDate).isValid()) {
        if (
          momencik(prevSellDateRef.current) !==
          momencik(extendedPurchaseInvoice.sellDate)
        ) {
          handleChangeFilters({
            dateFrom: moment(extendedPurchaseInvoice.sellDate)
              .startOf('month')
              .toDate(),
            dateTo: moment(extendedPurchaseInvoice.sellDate)
              .endOf('month')
              .toDate(),
          });
          prevSellDateRef.current = extendedPurchaseInvoice.sellDate;
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendedPurchaseInvoice]);

  return {
    filters,
    handleChangeFilters,
    applyFiltersToEstimateItems,
  };
}

export default usePurchaseInvoiceEstimateItemsFilters;
