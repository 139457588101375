import React from 'react';
import { Form } from 'react-bootstrap';

import { CreateUpdateApplicationDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { ApplicationTypeEnum } from '../../../common/pokCore/validation/enums';
import PurchaserSelector from '../Selects/PurchaserSelector';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ApplicationAdminEditorInfoBrandProps {
  application: CreateUpdateApplicationDto;
}

export const ApplicationAdminEditorInfoBrand: React.FC<
  ApplicationAdminEditorInfoBrandProps
> = props => {
  const { application } = props;
  const { t, tk } = useTranslation('applicationLng');

  return (
    <>
      {application.applicationType === ApplicationTypeEnum.Brand && (
        <>
          <FormRow controlId="applicationName" label={t(tk.common.name)}>
            <Form.Control
              type="text"
              value={application.elementName}
              disabled
            />
          </FormRow>
          <FormRow controlId="purchaser" label={t(tk.common.purchaser)}>
            <PurchaserSelector
              role={PermissionsEnum.Projects}
              value={application.brandApplicationPurchaserId}
              readOnly
            />
          </FormRow>
        </>
      )}
    </>
  );
};
