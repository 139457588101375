import { Buffer } from 'buffer';

import React, { SyntheticEvent, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';

interface UploadFileProps {
  file: string;
  buttonText?: string;
  imageHeight?: number;
  setFile: (file: string) => void;
}

export const UploadFile: React.FC<UploadFileProps> = props => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClickImage = (event: SyntheticEvent) => {
    event.preventDefault();
    hiddenFileInput?.current?.click();
  };

  const setFile = (files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }

    const f: File = files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(f);
    reader.onload = function (e) {
      if (e.target) {
        const target: FileReader = e.target;
        if (target.result) {
          const file =
            'data:image/png;base64,' +
            Buffer.from(target.result as ArrayBuffer).toString('base64');
          props.setFile(file);
        }
      }
    };
  };

  return (
    <>
      {props.file ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <img
          src={props.file}
          alt="imageFile"
          height={props.imageHeight || 50}
          onClick={handleClickImage}
          style={{ cursor: 'pointer', border: '1px solid #ccc' }}
        />
      ) : (
        <Button variant="outline-secondary" onClick={handleClickImage}>
          {props.buttonText || 'Wgraj plik'}
        </Button>
      )}
      <Form.Control
        type="file"
        accept="image/*"
        onChange={event =>
          setFile((event.currentTarget as HTMLInputElement).files)
        }
        style={{ display: 'none' }}
        ref={hiddenFileInput}
      />
    </>
  );
};
