import React, { useMemo, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { EmployeeSearch } from '../../components/Employees/EmployeeSearch';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import { TabsHistory } from '../../../app/components/TabsHistory';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useTranslation } from '../../../common/hooks/useTranslation';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

export const EmployeesView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);
  const { t, tk } = useTranslation('administrationLng');

  const getFromOneTool = async () => {
    setWaiting(true);
    pok.employees
      .addToEmployeesFromOneTool()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const deleteEmployeeRoles = async () => {
    setWaiting(true);
    pok.employeesRoles
      .deleteEmployeeRoles()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const getAllData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.employees.getAllByPortion(
          t,
          tk,
          refresh,
          setRefresh,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
        )) as GridGetterFunction,
    [pok.employees, refresh, setRefresh, t, tk],
  );

  const getAllCount = useMemo(
    () =>
      (filterText =>
        pok.employees.getAllCount(filterText)) as GridCountFunction,
    [pok.employees],
  );

  const getNotActiveData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.employees.getNotActiveByPortion(
          t,
          tk,
          refresh,
          setRefresh,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
        )) as GridGetterFunction,
    [pok.employees, refresh, setRefresh, t, tk],
  );

  const getNotActiveCount = useMemo(
    () =>
      (filterText =>
        pok.employees.getNotActiveCount(filterText)) as GridCountFunction,
    [pok.employees],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.common.employees)}</Card.Title>
        <Button variant="outline-primary" onClick={getFromOneTool}>
          {t(tk.administration.synchrWithOneTool)}
        </Button>
        {waiting && <Waiting />}
        {!waiting && (
          <TabsHistory id="employees" activeKey="all" className="my-3">
            <Tab
              eventKey="all"
              title={t(tk.administration.all)}
              className="m-3"
            >
              <EmployeeSearch
                getData={getAllData}
                getCount={getAllCount}
                refresh={refresh}
                withHistory={true}
              />
            </Tab>
            <Tab
              eventKey="notActive"
              title={t(tk.administration.inactive)}
              className="m-3"
            >
              <EmployeeSearch
                getData={getNotActiveData}
                getCount={getNotActiveCount}
                refresh={refresh}
                withHistory={true}
              />
              <ConfirmationButton
                variant="outline-primary"
                onOK={deleteEmployeeRoles}
                confirmation={t(tk.administration.askDeletePermissions)}
              >
                {t(tk.administration.deletePermissions)}
              </ConfirmationButton>
            </Tab>
          </TabsHistory>
        )}
      </Card.Body>
    </Card>
  );
};
