import React from 'react';
import { Alert } from 'react-bootstrap';

import { GetResultsDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ExcelImportSummaryProps {
  results?: GetResultsDto;
}

export const ExcelImportSummary: React.FC<ExcelImportSummaryProps> = props => {
  const { t, tk } = useTranslation('excelImportLng');
  const results = props.results;
  return (
    <>
      <Alert variant="warning" className="mt-3">
        {results && (
          <>
            <b>
              {' '}
              {t(tk.import.correctlyImported)}:{' '}
              {results.addCount + results.updateCount}{' '}
            </b>{' '}
            ({t(tk.import.added)}: {results.addCount}, {t(tk.import.updated)}:{' '}
            {results.updateCount})
            <br />
            <ul>
              {results.okMessages &&
                results.okMessages.length > 0 &&
                results.okMessages?.map((e, i) => (
                  <li key={i} dangerouslySetInnerHTML={{ __html: e }}></li>
                ))}
            </ul>
            {results.errorCount > 0 && (
              <>
                <b>
                  {t(tk.import.notImported)}: {results.errorCount}{' '}
                </b>
                <br />
                {t(tk.import.errors)}:<br />
                <ul>
                  {results.errorMessages && results.errorMessages.length > 0 ? (
                    results.errorMessages?.map((e, i) => (
                      <li key={i} dangerouslySetInnerHTML={{ __html: e }}></li>
                    ))
                  ) : (
                    <li>{t(tk.import.noErrors)}</li>
                  )}
                </ul>
              </>
            )}
          </>
        )}
      </Alert>
    </>
  );
};
