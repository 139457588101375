import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { isEqual, uniqBy } from 'lodash';

import {
  CreateUpdateSalesInvoiceDto,
  GetCompanyDto,
  GetExtendedSalesInvoiceDto,
  GetProjectDto,
  GetPurchaserDto,
  GetSalesInvoicePositionDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { SalesInvoiceEditorTabs } from '../../containers/SalesInvoiceView/SalesInvoiceEditorTabs';
import { validate } from '../../../common/pokCore/contexts/SalesInvoicesContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { InvoiceStatusEnum } from '../../../common/pokCore/validation/enums';
import { useNavigation } from '../../../common/navigation';

import { SalesInvoiceSummaryEditor } from './SalesInvoiceSummaryEditor';
import { SalesInvoiceSummaryControls } from './SalesInvoiceSummaryControls';

interface SalesInvoiceSummaryProps {
  salesInvoice?: GetExtendedSalesInvoiceDto;
  convertedSalesInvoice: CreateUpdateSalesInvoiceDto;
  projects?: GetProjectDto[];
  positions?: GetSalesInvoicePositionDto[];
  id?: string;
  handleRefresh: () => void;
  handleRemove: () => void;
  readOnly?: boolean;
  isDeactivated?: boolean;
  setNotSavedTab: (tab: SalesInvoiceEditorTabs | undefined) => void;
  purchaser?: GetPurchaserDto;
  company?: GetCompanyDto;
}

export const SalesInvoiceSummary: React.FC<
  SalesInvoiceSummaryProps
> = props => {
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingStatusDraft, setIsLoadingStatusDraft] = useState(false);
  const [isLoadingStatusGenerated, setIsLoadingStatusGenerated] =
    useState(false);
  const [isLoadingStatusSent, setIsLoadingStatusSent] = useState(false);
  const [salesInvoice, setSalesInvoice] = useState(props.convertedSalesInvoice);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const prevSalesInvoiceRef = useRef(props.convertedSalesInvoice);
  const nav = useNavigation();
  const pok = usePokCore();
  const notifications = useNotifications();

  const isOnePositionEnabled = uniqBy(props.positions, 'vat').length === 1;

  const addEditSalesInvoice = async (
    salesInvoiceCU: CreateUpdateSalesInvoiceDto,
    id: string,
  ) => {
    const status = await validate(salesInvoiceCU);
    if (!status.valid) {
      return {
        saved: false,
        errors: status.errors,
      };
    }
    return saveUpdateSalesInvoice(salesInvoiceCU, id);
  };

  const saveUpdateSalesInvoice = async (
    salesInvoiceCU: CreateUpdateSalesInvoiceDto,
    id: string,
  ) => {
    try {
      await pok.salesInvoices.updateSummary(id, salesInvoiceCU);

      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as ResponseError),
      };
    }
  };

  const updateSalesInvoice = async (
    updatedStalesInvoice?: CreateUpdateSalesInvoiceDto,
  ) => {
    if (props.id) {
      addEditSalesInvoice(updatedStalesInvoice || salesInvoice, props.id)
        .then(saveStatus => {
          if (!saveStatus.saved) {
            setSaveErrors(saveStatus.errors);
            setShowSaveErrors(true);
            return;
          }
          setSaveErrors([]);
          setShowSaveErrors(false);
          props.setNotSavedTab(undefined);
          notifications.saveCompleted();
          void nav.reload();
        })
        .catch(async errorResponse => {
          notifications.caughtError(errorResponse);
        })
        .finally(() => {
          setIsLoadingStatusSent(false);
          setIsLoadingStatusDraft(false);
          setIsLoadingSave(false);
          setIsLoadingStatusGenerated(false);
        });
    }
  };

  const handleChangeToGenerated = async () => {
    setIsLoadingStatusGenerated(true);
    await updateSalesInvoice({
      ...salesInvoice,
      status: InvoiceStatusEnum.Generated,
    });
  };

  const handleChangeToDraft = async () => {
    setIsLoadingStatusDraft(true);
    await updateSalesInvoice({
      ...salesInvoice,
      status: InvoiceStatusEnum.Draft,
    });
  };

  const handleChangeToSent = async () => {
    setIsLoadingStatusSent(true);
    await updateSalesInvoice({
      ...salesInvoice,
      status: InvoiceStatusEnum.Sent,
    });
  };

  const handleSave = async () => {
    setIsLoadingSave(true);
    await updateSalesInvoice(salesInvoice);
  };

  const propertyChange = (obj: Partial<CreateUpdateSalesInvoiceDto>) => {
    const updatedSalesInvoice = {
      ...salesInvoice,
      ...obj,
    };
    const dataChanged = !isEqual(
      updatedSalesInvoice,
      props.convertedSalesInvoice,
    );
    props.setNotSavedTab(
      dataChanged ? SalesInvoiceEditorTabs.SUMMARY : undefined,
    );

    setTimeout(() => {
      setSalesInvoice(updatedSalesInvoice);
    }, 1);
  };

  useEffect(() => {
    if (!isEqual(prevSalesInvoiceRef.current, props.convertedSalesInvoice)) {
      setSalesInvoice(props.convertedSalesInvoice);
    }

    prevSalesInvoiceRef.current = props.convertedSalesInvoice;
  }, [props.convertedSalesInvoice]);

  return (
    <>
      <Form className="d-grid gap-4 mb-3">
        <SalesInvoiceSummaryEditor
          salesInvoice={salesInvoice}
          propertyChange={propertyChange}
          readOnly={props.readOnly}
          isOnePositionEnabled={isOnePositionEnabled}
          purchaser={props.purchaser}
          company={props.company}
        />
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="m-3"
        />
        <SalesInvoiceSummaryControls
          {...props}
          salesInvoice={props.convertedSalesInvoice}
          handleSave={handleSave}
          handleChangeToSent={handleChangeToSent}
          handleChangeToDraft={handleChangeToDraft}
          handleChangeToGenerated={handleChangeToGenerated}
          isOnePositionEnabled={isOnePositionEnabled}
          isOnePositionOnInvoice={salesInvoice.isOnePositionOnInvoice}
          isLoadingSave={isLoadingSave}
          isLoadingStatusDraft={isLoadingStatusDraft}
          isLoadingStatusSent={isLoadingStatusSent}
          isLoadingStatusGenerated={isLoadingStatusGenerated}
        />
      </Form>
    </>
  );
};
