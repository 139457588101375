import React, { useEffect, useState } from 'react';

import {
  LanguageType,
  LanguageTypeTextObj,
} from '../../../common/pokCore/validation/enums';
import enFlag from '../../../assets/en.png';
import plFlag from '../../../assets/pl.png';
import { changeLang } from '../../../translations/initTranslations';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { Selector, Option } from './Selector';

export interface LanguageSelectorProps {
  readOnly?: boolean;
  value?: string;
  onChange?: (option: Option) => void;
  clearable?: boolean;
  darkVersion?: boolean;
  withName?: boolean;
  changeLanguageForSystem?: boolean;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = props => {
  const [language, setLanguage] = useState<string>();
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t } = useTranslation('commonLng');
  const provider = async (text: string) => {
    const values = [];

    //TODO: usunąć jak już angielski wpuscimy na produkcje i bedzie w pełni obsłużony
    let keys = Object.keys(LanguageType);
    if (process.env.REACT_APP_LANGUAGES === 'off') {
      keys = keys.filter(k => k === 'PL');
    }

    for (const key of keys) {
      values.push({
        badge: '',
        label: (
          <>
            <img
              src={key === 'PL' ? plFlag : enFlag}
              width="20"
              alt="flag"
              className="flag"
            />
            {props.withName ? (
              <span>
                {'  ' +
                  t(
                    LanguageTypeTextObj[
                      key as keyof typeof LanguageTypeTextObj
                    ],
                  )}
              </span>
            ) : (
              ''
            )}
          </>
        ),
        value: key,
      });
    }
    return values.filter(v => v.value.includes(text.toLocaleLowerCase()));
  };

  useEffect(() => {
    let mounted = true;
    const companyId = pok.getCompanyId();
    if (companyId && language && props.changeLanguageForSystem) {
      pok.companies
        .getById(companyId)
        .then(company => {
          if (mounted) {
            changeLang(company.personalLanguage, language);
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
    return () => {
      mounted = false;
    };
  }, [language, pok, notifications, props.changeLanguageForSystem]);

  return (
    <Selector
      readOnly={props.readOnly}
      clearable={props.clearable}
      creatable={false}
      provider={provider}
      value={props.value}
      className={props.darkVersion ? 'language-select' : ''}
      onChange={option => {
        setLanguage((option as Option).value);
        if (props.onChange) {
          props.onChange(option as Option);
        }
      }}
    />
  );
};
