/*eslint max-lines-per-function: ["error", 300]*/
/*eslint complexity: ["error", 21]*/
import React, { useState, useEffect, useCallback } from 'react';
import { Alert } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { useLocation } from '../../hooks/useLocation';
import { useNotifications } from '../../hooks/useNotifications';
import { useAuth } from '../../hooks/useAuth';

import { GridFilter } from './GridFilter';
import { GridFooter } from './GridFooter';
import {
  GridColumn,
  GridRecord,
  GridGetterFunction,
  GridCountFunction,
  GridSummaryFunction,
} from './GridDataTypes';
import staticGetData from './GridStaticGetData';
import { nvl } from './nvl';
import { GridInnerTable } from './GridInnerTable';
import { GridLocationState } from './GridLocationState';

interface GridProps {
  columns: GridColumn[];
  availablePageSizes?: number[];
  pageSize?: number;
  showFilter?: boolean;
  data?: GridRecord[];
  summaryData?: GridRecord;
  emptyGridMessage?: string;
  hidePagination?: boolean;
  initialOrderColumn?: string;
  initialOrderDirection?: 'ASC' | 'DESC';
  additionalId?: string;
  refresh?: boolean;
  getData?: GridGetterFunction;
  getDataCount?: GridCountFunction;
  getSummaryData?: GridSummaryFunction;
  onRowClick?: (key: string) => void;
  enableMultiSelect?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
  withHistory?: boolean;
  withFilterInColumns?: boolean;
  trClassName?: string;
  withOverflowXAuto?: boolean;
}

export const Grid: React.FC<GridProps> = props => {
  const location = useLocation<GridLocationState & unknown>();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(
    (props.withHistory && location.state?.pageSize) || nvl(props.pageSize, 10),
  );
  const [pageNumber, setPageNumber] = useState(
    (props.withHistory && location.state?.page) || 1,
  );

  const [pageData, setPageData] = useState<GridRecord[]>();
  const [summaryGridData, setSummaryGridData] = useState<GridRecord>();
  const [orderBy, setOrderBy] = useState(
    (props.withHistory && location.state?.sort) || props.initialOrderColumn,
  );
  const [orderDirection, setOrderDirection] = useState<'ASC' | 'DESC'>(
    (props.withHistory && location.state?.sortDirection) ||
      nvl(props.initialOrderDirection, 'ASC'),
  );

  const [filterColumns, setFilterColumns] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [totalPages, setTotalPages] = useState<number>();
  const [filterText, setFilterText] = useState<string>(
    (props.withHistory && location.state?.filterText) || '',
  );
  const [filtering, setFiltering] = useState(false);
  const [getData, getDataCount, getSummaryData] = [
    props.getData,
    props.getDataCount,
    props.getSummaryData,
  ];
  const [dataLength, setDataLength] = useState(props.data?.length || 0);

  useEffect(() => {
    setPageNumber((props.withHistory && location.state?.page) || 1);
    setPageSize(
      (props.withHistory && location.state?.pageSize) ||
        nvl(props.pageSize, 10),
    );
    setFilterText((props.withHistory && location.state?.filterText) || '');
    setOrderBy(
      (props.withHistory && location.state?.sort) || props.initialOrderColumn,
    );
    setOrderDirection(
      (props.withHistory && location.state?.sortDirection) ||
        nvl(props.initialOrderDirection, 'ASC'),
    );
  }, [
    props.withHistory,
    location.state,
    props.initialOrderColumn,
    props.initialOrderDirection,
    props.pageSize,
  ]);

  useEffect(() => {
    if (props.data && props.data.length !== dataLength) {
      setPageNumber(1);
      setDataLength(props.data.length);
    }
  }, [dataLength, props.data]);

  const filter = (text: string) => {
    setPageNumber(1);
    setFilterText(text);
    if (props.withHistory) {
      void navigate(location.pathname, {
        state: {
          ...location.state,
          filterText: text || '',
          page: 1,
          pageSize: nvl(props.pageSize, 10),
        },
      });
    }
  };

  const setPageSizeWithHistory = (ps: number) => {
    setPageSize(ps);
    if (props.withHistory) {
      void navigate(location.pathname, {
        state: {
          ...location.state,
          pageSize: ps,
        },
      });
    }
  };

  const notifications = useNotifications();
  const auth = useAuth();

  const countAllRows = useCallback(async () => {
    const pd = props.data;
    if (pd) {
      return staticGetData(pd, pd.length, 1, undefined, undefined, filterText)
        .length;
    }

    if (!getDataCount) {
      throw new Error('No count method!');
    }
    const filters = _(filterColumns)
      .omitBy(v => v === '')
      .value();

    return await getDataCount(filterText, filters, props.additionalId);
  }, [filterText, filterColumns, getDataCount, props.data, props.additionalId]);

  useEffect(() => {
    let mounted = true;
    const order = orderBy || props.initialOrderColumn;
    const filters = _(filterColumns)
      .omitBy(v => v === '')
      .value();

    if (getData) {
      setFiltering(true);
      getData(
        pageSize,
        pageNumber,
        order,
        orderDirection,
        filterText,
        filters,
        props.additionalId,
      )
        .then(rows => {
          if (mounted) {
            // getData should return pageSize + 1 records if they exists
            const reachedTheEnd = rows.length <= pageSize;
            setTotalPages(reachedTheEnd ? pageNumber : undefined);
            setPageData(rows.slice(0, pageSize));
            setFiltering(false);
            if (getSummaryData) {
              getSummaryData(filterText, filters)
                .then(sumData => {
                  if (mounted) {
                    setSummaryGridData(sumData);
                  }
                })
                .catch(err => notifications.caughtError(err));
            }
          }
        })
        .catch(err => {
          notifications.caughtError(err);
        });
    } else if (props.data) {
      const rows = staticGetData(
        props.data,
        pageSize,
        pageNumber,
        order,
        orderDirection,
        filterText,
        filters,
      );

      setPageData(rows);
      setSummaryGridData(props.summaryData);
      countAllRows()
        .then(rowsCount => {
          if (mounted) {
            setTotalPages(Math.ceil(rowsCount / pageSize));
          }
        })
        .catch(err => {
          notifications.caughtError(err);
        });
    } else {
      throw new Error('Either set data or getData method!');
    }

    return () => {
      mounted = false;
    };
  }, [
    getData,
    props.summaryData,
    getSummaryData,
    props.data,
    props.initialOrderColumn,
    props.additionalId,
    props.refresh,
    pageNumber,
    pageSize,
    orderBy,
    orderDirection,
    filterText,
    filterColumns,
    countAllRows,
    notifications,
    auth,
  ]);

  return (
    <>
      {props.showFilter && (
        <div className="d-flex flex-row-reverse">
          <GridFilter
            defaultText={filterText}
            filter={filter}
            indicator={filtering}
          />
        </div>
      )}
      {!pageData ? (
        <Skeleton count={10} />
      ) : (
        <>
          <GridInnerTable
            columns={props.columns}
            pageData={pageData}
            orderBy={orderBy}
            orderDirection={orderDirection}
            summaryData={summaryGridData}
            setOrderBy={setOrderBy}
            setOrderDirection={setOrderDirection}
            setFilter={setFilterColumns}
            setPageNumber={setPageNumber}
            filter={filter}
            onRowClick={props.onRowClick}
            enableMultiSelect={props.enableMultiSelect}
            enableSingleSelect={props.enableSingleSelect}
            selectedKeys={props.selectedKeys}
            withHistory={props.withHistory}
            withFilterInColumns={props.withFilterInColumns}
            trClassName={props.trClassName}
            withOverflowXAuto={props.withOverflowXAuto}
          />
          {pageData.length === 0 && (
            <Alert variant="warning">
              {nvl(props.emptyGridMessage, 'Brak danych do pokazania')}
            </Alert>
          )}
          <GridFooter
            availablePageSizes={props.availablePageSizes}
            hidePagination={props.hidePagination}
            pageData={pageData}
            pageNumber={pageNumber}
            totalPages={totalPages}
            pageSize={pageSize}
            setPageNumber={setPageNumber}
            countAllRows={countAllRows}
            setPageSize={setPageSizeWithHistory}
            withHistory={props.withHistory}
          />
        </>
      )}
    </>
  );
};
