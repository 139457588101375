import React, { useMemo, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import saveAs from 'file-saver';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetSalesInvoiceWithAmountDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import momencik from '../../../common/momencik';
import { GridRecord } from '../../../common/components/Grid/GridDataTypes';
import FormSkeleton from '../../../app/components/FormSkeleton';
import { SalesInvoicesGrid } from '../Estimates/SalesInvoicesGrid';
import numberFormatter from '../../../common/numberFormatter';
import { OpenSaleInvoicePrintLink } from '../Estimates/OpenSaleInvoicePrintLink';
import {
  InvoiceStatusEnum,
  InvoiceStatusObj,
} from '../../../common/pokCore/validation/enums';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ProjectSalesInvoicesTabProps {
  projectId: string;
  downloadDocuments: boolean;
  projectNumber: string;
  projectName: string;
}

export const ProjectSalesInvoicesTab: React.FC<
  ProjectSalesInvoicesTabProps
> = props => {
  const [salesInvoices, setSalesInvoices] = useState<
    GetSalesInvoiceWithAmountDto[] | undefined
  >(undefined);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('projectLng');
  useMemo(() => {
    pok.salesInvoices
      .getByProject(props.projectId)
      .then(result => {
        if (result) {
          setSalesInvoices(result);
        }
      })
      .catch(error => {
        notifications.caughtError(error);
      });
  }, [notifications, pok.salesInvoices, props.projectId]);

  const generateData = () => {
    const data = salesInvoices?.map(invoice => {
      return {
        key: invoice.id,
        values: {
          'salesInvoice.number': invoice.number,
          'salesInvoice.invoiceDate': momencik(invoice.invoiceDate),
          'salesInvoice.systemNumber': invoice.systemNumber,
          'salesInvoice.amount': numberFormatter(invoice.amount, true),
          'salesInvoice.status': t(InvoiceStatusObj[invoice.status]),
          'client.name': invoice.purchaser?.client?.name,
          'employee.name': `${invoice.owner?.name} (${momencik(
            invoice.ts,
            'LLL',
          )})`,
          preview: (
            <OpenSaleInvoicePrintLink
              id={invoice.id}
              status={invoice.status as unknown as InvoiceStatusEnum}
              icon={Icon.FilePdf}
            />
          ),
        },
        orderByNumberValues: {
          'salesInvoice.systemNumber': invoice.systemNumber,
        },
      };
    });
    return data as GridRecord[];
  };

  const handleClick = async () => {
    if (salesInvoices) {
      pok.packagesXML
        .getZipBySalesInvoicesIds(
          props.projectNumber,
          salesInvoices.map(o => o.id),
        )
        .then(async zip => {
          const fileName =
            `${props.projectNumber}_(${props.projectName})_${momencik(
              new Date(),
              'YYYY-MM-DD_HH-mm-ss',
            )}.zip`.replace(/[ /]/g, '_');

          if (zip) {
            const blob = new Blob([Buffer.from(zip as Buffer)], {
              type: 'application/zip',
            });
            saveAs(blob, fileName);
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  };

  return (
    <>
      {salesInvoices ? (
        <>
          <SalesInvoicesGrid data={generateData()} />
          {props.downloadDocuments &&
            salesInvoices &&
            salesInvoices.length > 0 && (
              <Button onClick={handleClick}>{t(tk.common.downloadDocs)}</Button>
            )}
        </>
      ) : (
        <FormSkeleton />
      )}
    </>
  );
};
