import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { Alert } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  FilterYear,
  FilterYearReportData,
} from '../../components/Reports/FilterYear';
import { ReportType } from '../../../common/pokCore/autogenerated/pokApiClient';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import { useAuth } from '../../../common/hooks/useAuth';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const ReportFVZByFilterRawDataView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [waiting, setWaiting] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>('');

  const auth = useAuth();
  const [authorized, setAuthorized] = useState<boolean>();
  const { t, tk } = useTranslation('reportLng');

  useEffect(() => {
    setAuthorized(auth.check(PermissionsEnum.FinanceAdministration));
  }, [pok, auth]);

  const downloadExcel = (dataReports: ReportType) => {
    const worksheet = XLSX.utils.json_to_sheet(dataReports?.data || []);

    if (worksheet && worksheet['!ref']) {
      const columnsToFormat = ['H', 'I', 'J', 'K', 'M'];

      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        columnsToFormat.forEach(col => {
          const cellAddress = `${col}${rowNum + 1}`;

          if (worksheet[cellAddress] && worksheet[cellAddress].v !== null) {
            worksheet[cellAddress].t = 'n';
            if (col === 'M') {
              worksheet[cellAddress].z = '#,##0.0000';
            } else {
              worksheet[cellAddress].z = '#,##0.00';
            }
          }
        });
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'FVZ');

      const currentDate = new Date().toISOString().split('T')[0];
      XLSX.writeFile(
        workbook,
        `${t(tk.finance.reportFVZ)}_${currentDate}.xlsx`,
      );
    }
  };

  const onSearch = (filter: FilterYearReportData) => {
    setAlert(t(tk.menu.dataDownload));
    setWaiting(true);
    pok.reports
      .reportFVZByFilterRawData(filter)
      .then(dataReports => {
        downloadExcel(dataReports);
        setWaiting(false);
      })
      .catch(error => {
        notifications.caughtError(error);
        setWaiting(false);
      });
  };

  return (
    <>
      <FilterYear
        onSearch={onSearch}
        reportName={t(tk.finance.reportFVZLong)}
        withoutCompany={authorized ? false : true}
        withInvoiceType={true}
        withCurrency={true}
        withClient={true}
        withDataSales={true}
        withDataInvoices={true}
        withStatus={true}
        withTeam={true}
        isLoading={waiting}
      />
      {waiting && (
        <>
          <Alert variant="warning">{alert}</Alert>
          <Waiting />
        </>
      )}
    </>
  );
};
