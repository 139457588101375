import _ from 'lodash';

import administration from '../enProjects/administration';
import project from '../enProjects/project';
import personalLanguageCommon from '../enProjects/personalLanguage';
import report from '../enProjects/report';
import order from '../enProjects/order';
import fvz from '../enProjects/fvz';
import fvs from '../enProjects/fvs';
import menuNavigation from '../enProjects/menu';
import application from '../enProjects/application';
import excelImport from '../enProjects/excelImport';
import common from '../enProjects/common';
import role from '../enProjects/role';
import client from '../enProjects/client';
import validation from '../enProjects/validation';
import enumObjects from '../enProjects/enumObjects';

import personalLanguage from './personalLanguage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeTranslations = <T>(base: T, override: any): T => {
  return _.merge(
    _.cloneDeep({
      ...base,
    }),
    {
      ...override,
    },
  );
};

export const menuLng = mergeTranslations(
  {
    ...menuNavigation,
    ...enumObjects,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const commonLng = mergeTranslations(
  { ...enumObjects, ...common, ...validation, ...personalLanguageCommon },
  {
    ...personalLanguage,
  },
);

export const administrationLng = mergeTranslations(
  {
    ...administration,
    ...enumObjects,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const roleLng = mergeTranslations(
  {
    ...role,
    ...enumObjects,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const clientLng = mergeTranslations(
  {
    ...client,
    ...enumObjects,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const projectLng = mergeTranslations(
  {
    ...project,
    ...enumObjects,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const fvsLng = mergeTranslations(
  {
    ...fvs,
    ...enumObjects,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const fvzLng = mergeTranslations(
  {
    ...fvz,
    ...enumObjects,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const orderLng = mergeTranslations(
  {
    ...order,
    ...enumObjects,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const reportLng = mergeTranslations(
  {
    ...report,
    ...enumObjects,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const applicationLng = mergeTranslations(
  {
    ...application,
    ...enumObjects,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const excelImportLng = mergeTranslations(
  {
    ...excelImport,
    ...enumObjects,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);
