import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import Button from '../../../common/components/Button/Button';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { MailTeamForm } from './MailTeamForm';

export interface MailTeamParams {
  teamId?: string;
  employeesIds: string[];
  addTeamToEmails: boolean;
  message: string;
}

interface MailTeamModalProps {
  handleClose: () => void;
  show?: boolean;
  tv: boolean;
}

export const MailTeamModal: React.FC<MailTeamModalProps> = props => {
  const pok = usePokCore();
  const { t, tk } = useTranslation('commonLng');
  const notifications = useNotifications();
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [emailParams, setEmailParams] = useState<MailTeamParams>({
    teamId: '',
    employeesIds: [],
    addTeamToEmails: false,
    message: '',
  });
  const [emails, setEmails] = useState<string[]>([]);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
    } else {
      setEmailParams({
        teamId: '',
        employeesIds: [],
        addTeamToEmails: false,
        message: '',
      });
      setEmails([]);
    }
  }, [props.show]);

  const sendEmail = async () => {
    setIsLoadingSave(true);
    pok.emails
      .sendPurchaseDeficienciesEmail(
        props.tv,
        emails,
        emailParams.message,
        emailParams.teamId,
      )
      .then(() => props.handleClose())
      .catch(err => {
        notifications.caughtError(err);
      })
      .finally(() => setIsLoadingSave(false));
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t(tk.email.create)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MailTeamForm
            emailParams={emailParams}
            setEmailParams={setEmailParams}
            emails={emails}
            setEmails={setEmails}
            tv={props.tv}
          />
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex marginLeft gap-2">
              <Button
                variant="outline-secondary"
                onClick={props.handleClose}
                disabled={isLoadingSave}
              >
                {t(tk.button.close)}
              </Button>

              <Button
                variant="primary"
                onClick={sendEmail}
                isLoading={isLoadingSave}
                disabled={
                  emailParams.message === '' ||
                  emails.length === 0 ||
                  !emailParams.teamId ||
                  emailParams.teamId === ''
                }
              >
                {t(tk.email.send)}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
