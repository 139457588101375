export default {
  schemas: {
    chooseExcel: 'Please upload the Excel file.',
    chooseConfigImport: 'Please select the import configuration.',
    invalidAccountNumber: 'Invalid account number.',
    provideNip: 'Please provide the VAT ID.',
    invalidNip: 'The provided VAT ID is incorrect.',
    nipDigitsOnly: 'The VAT ID should consist only of digits.',
    invalidPesel: 'The provided PESEL is incorrect.',
    peselDigitsOnly: 'The PESEL should consist only of digits.',
    nipOrPeselRequired: 'Please provide VAT ID or PESEL.',
    provideClientName: 'Please provide the contractor name.',
    provideCountry: 'Please specify the country.',
    postcodeFormat: 'The postcode should be in the XX-XXX format.',
    providePlace: 'Please provide the city.',
    provideForeignAddress:
      'For foreign entities, the address must be provided.',
    provideCompanyName: 'Please provide the company name.',
    provideCompanyShortname: 'Please provide the company short name.',
    chooseNamingConvention: 'Please select the naming convention.',
    maxDaysDelayInteger: 'The maximum delay must be a non-negative integer.',
    provideMaxCampaignDelay:
      'Please provide the maximum allowed delay for starting the project/campaign.',
    orderNumberInteger: 'The order number must be a non-negative integer.',
    chooseInvoiceTemplate: 'Please select the invoice template.',
    chooseMainCurrency: 'Please select the main currency.',
    chooseCompanyLanguage: 'Please select the company language.',
    provideDictionaryValue: 'Please provide the dictionary value.',
    provideDictionaryValueEn:
      'Please provide the English version of the dictionary value.',
    provideRoleName: 'Please provide the role name.',
    choosePermissions: 'Please select the permissions.',
    chooseEmployee: 'Please select an employee.',
    chooseRole: 'Please select a role.',
    chooseUser: 'Please select a user.',
    chooseRoles: 'Please select roles.',
    chooseAtLeastOneRole: 'Please select at least one role.',
    provideTeamName: 'Please provide the team name.',
    provideEmail: 'Please provide the email address.',
    invalidEmail:
      "The email address: '{{email}}' is invalid. Please separate multiple addresses with commas.",
    provideTeamManager: 'Please provide the team leader.',
    provideBudgetName: 'Please provide the budget name.',
    provideBudgetManager: 'Please provide the budget manager.',
    provideBrandName: 'Please provide the brand name.',
    provideBrandBudget: 'Please provide the brand budget.',
    providePurchaserName: 'Please provide the purchaser name.',
    chooseCompany: 'Please select the company.',
    chooseClient: 'Please select the contractor.',
    chooseClientBudget: 'Please select the purchaser’s budget.',
    providePositionName: 'Please provide the name.',
    provideGroupName: 'Please provide the group name.',
    chooseCommunicationChannel: 'Please select the communication channel.',
    choosePositionType: 'Please select the position type.',
    chooseAtLeastOneCompany: 'Please select at least one company.',
    chooseProfile: 'Please select the profile.',
    chooseAtLeastOneProfile: 'Please select at least one profile.',
    specifyAttachmentType: 'Please specify the attachment type.',
    attachmentObjectTypeRequired:
      'The object type associated with the attachment must be defined.',
    specifyCompany: 'Please specify the company.',
    provideInvoicer: 'Please provide invoicing entity.',
    chooseRelatedItems: 'Please specify positions related to this attachment.',
    chooseAtLeastOneRelatedItem: 'Please select at least one related position.',
    addAttachmentOrNote: 'Please add an attachment or write a note.',
    provideProjectName: 'Please provide the project/campaign name.',
    choosePurchaser: 'Please select the purchaser.',
    chooseTeam: 'Please select the team.',
    chooseAtLeastOneTeam: 'Please select at least one team.',
    companySetupError: 'Error setting up the company.',
    chooseCountryOrMarket: 'Please select the country/market.',
    startDateAfterJan2024:
      'The start date cannot be earlier than January 1, 2024.',
    provideStartDate: 'Please provide the start date.',
    endDateAfterStartDate:
      'The end date cannot be earlier than the start date.',
    provideEndDate: 'Please provide the end date.',
    fillRequestContent: 'Please fill in the content of the request.',
    provideRejectionReason: 'Please provide the rejection reason.',
    integerValue: 'The value must be an integer.',
    nonNegativeCode: 'The code must be a non-negative number.',
    provideSymphonyCode: 'Please provide the Symphony code.',
    provideName: 'Please provide the name.',
    provideProfileName: 'Please provide the profile name.',
    namesNotUniquePl: 'Names are not unique.',
    namesNotUniqueEn: 'English names are not unique.',
    provideProfileCategoryName: 'Please provide the profile category name.',
    providePurchaseAccount: 'Please provide the purchase account.',
    provideSalesAccount: 'Please provide the sales account.',
    provideCommissionAccount: 'Please provide the commission account.',
    chooseApplicationType: 'Please select the request type.',
    provideElementName: 'Please provide the name of the requested element.',
    provideDescription: 'Please provide the description.',
    provideEmployee: 'Please provide the employee.',
    provideEmployeeBoss: 'Please provide the employee’s supervisor.',
    provideRejectionReason2: 'Please provide the rejection reason.',
    providePosition: 'Please provide the position.',
    providePurchaser: 'Please provide the purchaser.',
    provideBrand: 'Please provide the brand.',
    chooseValidCurrencyIso: 'Please provide a valid currency ISO code.',
    chooseProject: 'Project/campaign not selected.',
    chooseMonth: 'Please select the month.',
    chooseProfileCategory: 'Please select the profile category.',
    chooseStartDate: 'Please provide the start date.',
    chooseEndDate: 'Please provide the end date.',
    endDateNotEarlierThanStartDate:
      'The end date cannot be earlier than the start date.',
    chooseProfilePosition: 'Please select the profile position.',
    chooseNetAmount: 'Please provide the net amount for the invoice position.',
    chooseVatRate: 'Please select the VAT rate.',
    chooseVatAmount: 'Please provide the VAT amount for the invoice position.',
    chooseInvoiceNumber: 'Please provide the invoice number.',

    giveProject: 'Please provide the project/campaign.',
    chooseSpecialistTeam: 'Please select the specialist team.',
    chooseRecipientsOrTeamMail:
      'Select recipients or check the email send option to the team’s address.',
    chooseRejectionReason: 'Please provide the rejection reason.',
    addAtLeastOnePosition: 'Please add at least one position.',
    chooseAtLeastOnePosition:
      'At least one position is required in the order fulfillment.',
    choosePositionName: 'Please provide the position name.',
    choosePositionAmount: 'Please provide the position amount.',
    chooseSomeMonth: 'Please select a month.',
    chooseConfigType: 'Please select the configuration type.',
    chooseFrequency: 'Please provide the frequency.',
    chooseInvalidEmail:
      'The email address: {{email}} is invalid. Please separate multiple addresses with commas.',
    chooseExcelPositionName:
      'Please provide the position name in the Excel file.',
    choosePosition: 'Please select the position.',
    chooseSaleDate: 'Please provide the sale date.',
    chooseIssueDate: 'Please provide the issue date.',
    choosePaymentDate: 'Please provide the payment due date.',
    choosePaymentTerm: 'Please provide the payment due date.',
    chooseAttachment: 'Please add an attachment.',
    chooseTeamsOrProjects: 'Please add projects/campaigns or teams.',
    chooseConfigName: 'Please provide a configuration name.',
    choosePOKField: 'Please select a POK field.',
    chooseExcelColumnName: 'Please provide an Excel column name.',
    chooseColumnOrder: 'Please provide the column order.',
    chooseIntegerOrder: 'Order must be a non-negative integer.',
    chooseAcceptedInvoicingDescription:
      'Please complete the accepted invoicing description.',
    chooseEmailAddress: 'Please provide an email address.',
    chooseAtLeastOneEmail: 'Please provide at least one email address.',
    chooseAtLeastOneValidEmail:
      'Please provide at least one non-empty email address.',
    chooseAccountNumber: 'Please provide an account number.',
    chooseCurrency: 'Please select a currency.',
    chooseInvoiceType: 'Please select an invoice type.',
    chooseSwiftCode: 'Please provide a SWIFT code.',
    chooseBankName: 'Please provide the bank name.',
    chooseVatAccountNumber: 'Please provide the VAT account number.',
    chooseVatBankName: 'Please provide the VAT bank name.',
    chooseStatus: 'Please select a status.',
    chooseMonthlyProfile: 'Please select a monthly profile.',
    chooseReason: 'Please provide a reason for the request.',
    chooseDocumentationConfirmation:
      'Please indicate if the change is documented.',
    chooseSalesCorrection:
      'Please indicate if this request concerns a sales correction.',
    chooseSalesOrPurchaseBlock:
      'Please select either a sales or purchase block.',
    chooseRecipient: 'The message must be addressed to someone.',
    chooseMemoContent: 'Please provide the memo content.',
    chooseRequestContent: 'Please provide the request content.',
    chooseInvoicingDescription: 'Please provide the invoicing description.',
    chooseAtLeastOneProject: 'Please add at least one project/campaign.',
    chooseRequiredChanges: 'Please provide the required changes.',
    chooseRequestStatus: 'Please set the request status.',
    chooseAmount: 'Please provide the amount.',
    amountCannotBeNegative: 'Position amount must be a non-negative number.',
    chooseSaleAmount: 'Please provide the sell amount.',
    chooseCommissionAmount: 'Please provide the commission amount.',
    chooseMediaPlanPosition: 'Please select the position of the mediaplan.',
    chooseInvoice: 'Please select an invoice.',
    invalidEmails:
      'Email address: {{firstInvalidEmail}} is invalid. Please separate multiple addresses with a comma',
    namesNotUnique: 'Names are not unique ({{names}}).',
    englishNamesNotUnique: 'English names are not unique ({{names}}).',
    givePositionName: 'Position no. {{order}}: please provide a position name.',
    giveAmount:
      'Position no. {{order}}: please provide the amount for the position.',
  },
};
