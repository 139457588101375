import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useNotifications } from '../../hooks/useNotifications';
import { useTranslation } from '../../hooks/useTranslation';

interface CopyButtonProps {
  value?: string;
  clipboardItem?: ClipboardItem;
  buttonText?: string;
  tooltipText?: string;
  variant?: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({
  value,
  clipboardItem,
  tooltipText,
  buttonText,
  variant,
}) => {
  const [copied, setCopied] = useState(false);
  const notifications = useNotifications();
  const { t, tk } = useTranslation('menuLng');

  const copyToClipboard = (value?: string | ClipboardItem) => {
    if (!value) {
      return;
    }
    const writeToClipboard =
      value instanceof ClipboardItem
        ? navigator.clipboard.write([value])
        : navigator.clipboard.writeText(value);

    writeToClipboard.then(
      () => setCopied(true),
      () => {
        notifications.error(t(tk.system.copyClipboardError));
      },
    );
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  return (
    <OverlayTrigger
      overlay={
        <Tooltip>
          {copied
            ? t(tk.system.ctrlV)
            : (tooltipText ?? t(tk.system.copyClipboard))}
        </Tooltip>
      }
    >
      <Button
        onClick={() => copyToClipboard(value ?? clipboardItem)}
        variant={variant ?? 'outline-secondary'}
        className="mx-2"
      >
        {buttonText ??
          (copied ? <Icon.Clipboard2CheckFill /> : <Icon.Clipboard2Fill />)}
      </Button>
    </OverlayTrigger>
  );
};

export default CopyButton;
