import React, { useState } from 'react';

import ValidationAlert from '../ValidationAlert';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { SignIn } from './SignIn';

export type Props = {
  onSignIn: (usr: string, pwd: string) => Promise<void>;
  onSignInWithGoogle: (googleToken: string) => Promise<void>;
};

export const SignInDialog: React.FC<Props> = ({
  onSignIn,
  onSignInWithGoogle,
}) => {
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const { t, tk } = useTranslation('menuLng');

  const handleSignIn = async (usr: string, pwd: string) => {
    onSignIn(usr, pwd).catch(async response => {
      setErrorText(
        t(tk.system.loginError) +
          (await responseErrors(response as ResponseError)),
      );
      setShowError(true);
    });
  };

  const handleSignInWithGoogle = async (googleToken: string) => {
    onSignInWithGoogle(googleToken).catch(async response => {
      setErrorText(
        t(tk.system.loginError) +
          (await responseErrors(response as ResponseError)),
      );
      setShowError(true);
    });
  };

  return (
    <>
      <ValidationAlert
        heading={t(tk.system.login)}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />
      <SignIn
        onSignIn={handleSignIn}
        onSignInWithGoogle={handleSignInWithGoogle}
      />
    </>
  );
};
