import React from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import momencik from '../../../common/momencik';

import { Option, Selector } from './Selector';

export interface ProjectSelectorProps {
  onChange?: (option: Option) => void;
  value?: string;
}

export default (props: ProjectSelectorProps) => {
  const pok = usePokCore();
  const provider = async (text: string) => {
    const values = await pok.projects.getAllByFilter(text);
    if (props.value) {
      const value = await pok.projects.getById(props.value);
      if (value) {
        values.push(value);
      }
    }
    return Array.from(values, p => ({
      value: p.id,
      label: `${p.number} - ${p.name}   (${momencik(
        p.startDate,
        'L',
      )} - ${momencik(p.endDate, 'L')})`,
    }));
  };

  return (
    <Selector
      creatable={false}
      provider={provider}
      onChange={option => props.onChange && props.onChange(option as Option)}
      value={props.value}
    />
  );
};
