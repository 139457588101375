import type { TFunction } from 'i18next';

import { DefaultApi } from '../autogenerated/pokApiClient';
import { ProjectDeactivateSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { projectApplicationsForGrid } from '../../../pok/components/Projects/ProjectSearch';
import { ProjectApplicationTypeEnum } from '../validation/enums';
import { TKeys } from '../../hooks/useTranslation';

export interface ProjectApplicationContextInterface {
  getAllApplicationByPortion: (
    t: TFunction<'projectLng', 'projectLng'>,
    tk: TKeys<'projectLng'>,
    applicationType: ProjectApplicationTypeEnum,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllApplicationWaitingByPortion: (
    t: TFunction<'projectLng', 'projectLng'>,
    tk: TKeys<'projectLng'>,
    applicationType: ProjectApplicationTypeEnum,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllApplicationCount: (
    applicationType: ProjectApplicationTypeEnum,
    filterText?: string,
  ) => Promise<number>;
  getAllApplicationWaitingCount: (
    applicationType: ProjectApplicationTypeEnum,
    filterText?: string,
  ) => Promise<number>;
  application: (
    applicationType: ProjectApplicationTypeEnum,
    id: string,
    active: boolean,
    message?: string,
  ) => Promise<void>;
  applicationAccept: (
    applicationType: ProjectApplicationTypeEnum,
    id: string,
  ) => Promise<void>;
  applicationReject: (
    applicationType: ProjectApplicationTypeEnum,
    id: string,
    rejectedNote: string,
  ) => Promise<void>;
}

export const ProjectApplicationContext = (api: DefaultApi) => {
  return {
    getAllApplicationByPortion: async (
      t: TFunction<'projectLng', 'projectLng'>,
      tk: TKeys<'projectLng'>,
      applicationType: ProjectApplicationTypeEnum,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.projectControllerGetAllApplicationByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        applicationType,
      );
      return data.map(data =>
        projectApplicationsForGrid(t, tk, data, applicationType),
      );
    },
    getAllApplicationWaitingByPortion: async (
      t: TFunction<'projectLng', 'projectLng'>,
      tk: TKeys<'projectLng'>,
      applicationType: ProjectApplicationTypeEnum,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.projectControllerGetAllApplicationWaitingByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        applicationType,
      );
      return data.map(data =>
        projectApplicationsForGrid(t, tk, data, applicationType),
      );
    },
    getAllApplicationCount: (
      applicationType: ProjectApplicationTypeEnum,
      filterText?: string,
    ) =>
      api.projectControllerGetAllApplicationCount(
        filterText || '',
        applicationType,
      ),
    getAllApplicationWaitingCount: (
      applicationType: ProjectApplicationTypeEnum,
      filterText?: string,
    ) =>
      api.projectControllerGetAllApplicationWaitingCount(
        filterText || '',
        applicationType,
      ),
    application: (
      applicationType: ProjectApplicationTypeEnum,
      id: string,
      active: boolean,
      message?: string,
    ) =>
      api.projectControllerApplication(id, {
        applicationType,
        applicationVerification: active,
        applicationMessage: message ?? '',
      }),
    applicationAccept: (
      applicationType: ProjectApplicationTypeEnum,
      id: string,
    ) =>
      api.projectControllerApplicationAccept(id, {
        applicationType: applicationType,
      }),
    applicationReject: (
      applicationType: ProjectApplicationTypeEnum,
      id: string,
      rejectedNote: string,
    ) =>
      api.projectControllerApplicationReject(id, {
        applicationType,
        rejectedNote,
      }),
  };
};

export const validate = (data: { rejectedNote: string }) => {
  return validateAgainst(ProjectDeactivateSchema, data);
};
