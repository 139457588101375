/*eslint complexity: ["error", 26]*/
import React from 'react';
import type { TFunction } from 'i18next';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetEstimateItemPurchaseDeficienciesDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';
import mathUtils from '../../../utils/mathUtils';
import { TKeys, useTranslation } from '../../../common/hooks/useTranslation';
import { AttachmentObjectType } from '../../../common/pokCore/validation/enums';

import { SimpleAttachmentLink } from './SimpleAttachmentLink';

interface EstimateItemPurchaseDeficienciesSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  refresh?: boolean;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
}

export const estimateItemPurchaseDeficienciesForGrid = (
  t: TFunction<'projectLng', 'projectLng'>,
  tk: TKeys<'projectLng'>,
  estimateItem: GetEstimateItemPurchaseDeficienciesDto,
  refresh: boolean,
  setRefresh: (refresh: boolean) => void,
) => {
  const today = new Date();
  const estimateDate = estimateItem.estimateByMonth.date;
  const lastDayOfMonth = new Date(
    estimateDate.getFullYear(),
    estimateDate.getMonth() + 1,
    0,
  );
  const differenceInDays = Math.ceil(
    (today.getTime() - lastDayOfMonth.getTime()) / (1000 * 60 * 60 * 24) - 1,
  );
  const differenceInMonths =
    (today.getFullYear() - lastDayOfMonth.getFullYear()) * 12 +
    (today.getMonth() - lastDayOfMonth.getMonth());

  const countDaysCss =
    differenceInMonths === 1
      ? 'bg-custom-yellow amounts-align-right'
      : differenceInMonths > 1 && differenceInMonths < 4
        ? 'bg-custom-dark-yellow amounts-align-right'
        : differenceInMonths > 3 && differenceInMonths < 7
          ? 'bg-custom-pink amounts-align-right'
          : differenceInMonths > 6
            ? 'bg-custom-red amounts-align-right'
            : '';

  return {
    key: estimateItem.id,
    values: {
      'estimateByMonth.date': momencik(
        estimateItem.estimateByMonth.date,
        'YYYY-MM',
      ),
      'position.name': `${estimateItem.position.name} (${
        estimateItem.position.client?.shortname ||
        estimateItem.position.client?.name
      })`,
      'project.name': estimateItem.estimateByMonth.project.name,
      'project.number': estimateItem.estimateByMonth.project.number,
      'profile.name': estimateItem.estimateByMonth.profile.name,
      'responsibleOwner.name':
        estimateItem.estimateByMonth.project.responsibleOwner?.name,
      'budget.name': estimateItem.estimateByMonth.project.purchaser.budget.name,
      'team.name': estimateItem.estimateByMonth.project.projectTeams
        ?.map(({ team }) => team?.name)
        ?.join(', '),
      differenceInDays: differenceInDays,
      label: (
        <div className={countDaysCss}>
          {differenceInMonths === 1
            ? '1 ' + t(tk.common.monthShort)
            : differenceInMonths > 1 && differenceInMonths < 4
              ? `2-3 ` + t(tk.common.monthShort2)
              : differenceInMonths > 3 && differenceInMonths < 7
                ? `4-6 ` + t(tk.common.monthShort6)
                : differenceInMonths > 6
                  ? t(tk.common.above) + ` 6 ` + t(tk.common.monthShort6)
                  : ''}
        </div>
      ),
      'project.purchaseNetTotal': numberFormatter(
        estimateItem?.purchaseNetTotal,
        true,
      ),
      'project.purchaseInvoicesSum': numberFormatter(
        estimateItem?.purchaseInvoicesSum || 0,
        true,
      ),
      difference: numberFormatter(
        mathUtils.subtract(
          estimateItem?.purchaseNetTotal || 0,
          estimateItem?.purchaseInvoicesSum || 0,
        ),
        true,
      ),
      commentMediaplanner: (
        <SimpleAttachmentLink
          objectId={estimateItem.id}
          objectType={AttachmentObjectType.EstimateItemCommentMediaplanner}
          note={estimateItem.commentMediaplanner}
          setRefresh={setRefresh}
          refresh={refresh}
          additionalText={t(tk.estimates.mediaplanner)}
        />
      ),
      commentFinance: (
        <SimpleAttachmentLink
          objectId={estimateItem.id}
          objectType={AttachmentObjectType.EstimateItemCommentFinance}
          note={estimateItem.commentFinance}
          setRefresh={setRefresh}
          refresh={refresh}
          additionalText={t(tk.estimates.finance)}
          checkFinance={true}
        />
      ),
    },
  };
};

export const EstimateItemPurchaseDeficienciesSearch: React.FC<
  EstimateItemPurchaseDeficienciesSearchProps
> = props => {
  const { t, tk } = useTranslation('projectLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.teams), property: 'team.name' },
    { header: t(tk.common.budget), property: 'budget.name' },
    {
      header: `${t(tk.common.name)} ${t(tk.prLang.genitiveProject)}`,
      property: 'project.name',
    },
    {
      header: `${t(tk.common.number)} ${t(tk.prLang.genitiveProject)}`,
      property: 'project.number',
    },
    { header: t(tk.common.month), property: 'estimateByMonth.date' },
    { header: t(tk.common.profile), property: 'profile.name' },
    { header: t(tk.common.positionName), property: 'position.name' },
    {
      header: t(tk.projects.daysAfterDeadline),
      property: 'differenceInDays',
      noFilter: true,
      noSorting: true,
    },
    {
      header: t(tk.estimates.purchase),
      property: 'project.purchaseNetTotal',
      noFilter: true,
      noSorting: true,
    },
    {
      header: t(tk.projects.purchaseInvoicesSum),
      property: 'project.purchaseInvoicesSum',
      noFilter: true,
      noSorting: true,
    },
    {
      header: t(tk.projects.toClarify),
      property: 'difference',
      noFilter: true,
      noSorting: true,
    },
    {
      header: t(tk.projects.commentMediaPlanner),
      property: 'commentMediaplanner',
      noFilter: true,
      noSorting: true,
    },

    {
      header: t(tk.projects.commentFinance),
      property: 'commentFinance',
      noFilter: true,
      noSorting: true,
    },
    {
      header: t(tk.projects.responsiblePerson),
      property: 'responsibleOwner.name',
    },
    {
      header: t(tk.projects.label),
      property: 'label',
      noFilter: true,
      noSorting: true,
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="estimateByMonth.date" //, project.number, position.name"
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.projects.noFVZLack)}
      refresh={props.refresh}
      onRowClick={props.onRowClick}
      withHistory={true}
      withFilterInColumns={true}
      withOverflowXAuto={true}
    />
  );
};
