import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useNotifications } from '../../../common/hooks/useNotifications';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetClientDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { SymphonyTab } from '../../components/Symphony/SymphonyTab';
import { useTranslation } from '../../../common/hooks/useTranslation';

type ParamTypes = {
  clientId: string;
};

export const ClientSymphonyView: React.FC = () => {
  const [client, setClient] = useState<GetClientDto>();

  const { clientId } = useParams<ParamTypes>() as {
    clientId: string;
  };
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('clientLng');

  useEffect(() => {
    pok.clients
      .getById(clientId)
      .then(client => {
        setClient(client);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [clientId, notifications, pok.clients]);

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.common.contractor)} <b>{client?.name}</b>
          {client
            ? `, ${t(tk.common.sysNumberLowerCase)} ${client.systemNumber}`
            : null}
        </Card.Title>
        {client && <SymphonyTab clientId={client.id} filterToCurrentCompany />}
      </Card.Body>
    </Card>
  );
};
