import React from 'react';

import {
  GridColumn,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import { Grid } from '../../../common/components/Grid/Grid';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface PurchaseInvoicesGridProps {
  data: GridRecord[];
}

export const PurchaseInvoicesGrid: React.FC<
  PurchaseInvoicesGridProps
> = props => {
  const { t, tk } = useTranslation('projectLng');
  const columns: GridColumn[] = [
    { header: t(tk.common.sysNumber), property: 'invoice.systemNumber' },
    { header: t(tk.common.number), property: 'invoice.number' },
    {
      header: t(tk.estimates.issueDate),
      property: 'invoice.issueDate',
    },
    {
      header: t(tk.estimates.sellDate),
      property: 'invoice.sellDate',
    },
    { header: t(tk.estimates.invoiceAmount), property: 'invoice.netAmount' },
    { header: t(tk.estimates.settledAmount), property: 'invoice.amount' },
    { header: t(tk.estimates.billed), property: 'client.name' },
    { header: t(tk.estimates.lastModification), property: 'employee.name' },
    {
      header: t(tk.estimates.attachment),
      property: 'preview',
      noSorting: false,
    },
  ];
  return (
    <Grid
      data={props.data}
      columns={columns}
      showFilter={false}
      initialOrderColumn="invoice.systemNumber"
      initialOrderDirection="DESC"
      pageSize={20}
      emptyGridMessage={t(tk.estimates.noFVZAssigned)}
    />
  );
};
