import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { AmountEditor } from './AmountEditor';

interface AmountFormProps {
  amount: string;
  originalAmount: string;
  decimalScale?: number;
  handleUpdate: (value: string) => void;
}

export const AmountForm: React.FC<AmountFormProps> = props => {
  const [show, setShow] = useState(false);
  const { t, tk } = useTranslation('excelImportLng');

  const updateAmount = async (amount: string) => {
    if (amount === '') {
      return {
        saved: false,
        errors: [t(tk.import.amountNotEmpty)],
      };
    }
    props.handleUpdate(amount);
    setShow(false);
    return {
      saved: true,
      errors: [],
    };
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShow(true);
  };

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip>{t(tk.import.editAmount)}</Tooltip>}
        trigger={['hover', 'hover']}
      >
        <Button className="py-0 btn-link btn-transparent" onClick={handleClick}>
          <Icon.Pencil size={16} className="icon-in-cell" />
        </Button>
      </OverlayTrigger>
      {show && (
        <AmountEditor
          amount={props.amount}
          originalAmount={props.originalAmount}
          onCompleted={updateAmount}
          decimalScale={props.decimalScale}
          show={show}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
