/*eslint complexity: ["error", 22]*/
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { GetProjectDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { EstimatesTab } from '../Estimates/EstimatesTab';
import { OrdersTab } from '../Orders/OrdersTab';
import { OrdersExecutionTab } from '../OrdersExecution/OrdersExecutionTab';
import { useTranslation } from '../../../common/hooks/useTranslation';
import ValidationAlert from '../../../app/components/ValidationAlert';

import { ServiceActionTab } from './ServiceActionTab';
import { ProjectInvoicesTab } from './ProjectInvoicesTab';
import { ProjectAttachmentsTab } from './ProjectAttachmentsTab';
import { ProjectDetailsTab } from './ProjectDetailsTab';
import { ProjectSummaryTab } from './ProjectSummaryTab';
import { ProjectSalesInvoicesTab } from './ProjectSalesInvoicesTab';

interface ProjectTabsProps {
  project: GetProjectDto;
  uniqueServices?: string[];
  paeTeam: boolean;
  onRefresh(): void;
}

export const ProjectTabs: React.FC<ProjectTabsProps> = props => {
  const [activeTab, setActiveTab] = useState<string>('details');
  const [searchParams] = useSearchParams();
  const { t, tk } = useTranslation('projectLng');

  useEffect(() => {
    if (searchParams && searchParams.get('activeTab')) {
      setActiveTab(searchParams.get('activeTab') || 'details');
    }
  }, [searchParams]);

  return (
    <>
      {props.project?.active && (
        <Tabs
          activeKey={activeTab}
          onSelect={tab => {
            if (tab === 'summary' || tab === 'servicesActions') {
              props.onRefresh();
            }
            setActiveTab(tab as string);
          }}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Tab
            eventKey="details"
            title={t(tk.projects.parameters)}
            className="m-3"
          >
            <ProjectDetailsTab
              project={props.project}
              refresh={props.onRefresh}
            />
          </Tab>
          <Tab
            eventKey="summary"
            title={t(tk.projects.summary)}
            className="m-3"
          >
            <ProjectSummaryTab project={props.project} />
          </Tab>
          <Tab
            eventKey="estimates"
            title={t(tk.prLang.estimates)}
            className="m-3"
          >
            <EstimatesTab
              project={props.project}
              refresh={props.onRefresh}
              paeTeam={props.paeTeam}
            />
          </Tab>
          <Tab eventKey="orders" title={t(tk.projects.orders)} className="m-3">
            <OrdersTab project={props.project} />
          </Tab>
          <Tab
            eventKey="ordersExecution"
            title={t(tk.projects.ordersExecution)}
            className="m-3"
          >
            <OrdersExecutionTab project={props.project} />
          </Tab>
          <Tab
            eventKey="attachments"
            title={t(tk.projects.documents)}
            className="m-3"
          >
            <ProjectAttachmentsTab
              project={props.project}
              downloadDocuments={
                props.project.purchaser?.budget?.downloadDocuments
              }
              projectName={props.project.name}
              projectNumber={props.project.number}
            />
          </Tab>
          <Tab
            eventKey="purchaseInvoices"
            title={t(tk.projects.fvz)}
            className="m-3"
          >
            <ProjectInvoicesTab
              projectId={props.project.id}
              project={props.project}
              downloadDocuments={
                props.project.purchaser?.budget?.downloadDocuments
              }
              projectName={props.project.name}
              projectNumber={props.project.number}
              companyId={props.project?.company?.id}
              teamIds={props.project?.projectTeams?.map(pt => pt.teamId) || []}
              budgetId={props.project?.purchaser?.budget?.id}
            />
          </Tab>
          <Tab
            eventKey="salesInvoices"
            title={t(tk.projects.fvs)}
            className="m-3"
          >
            <ProjectSalesInvoicesTab
              projectId={props.project.id}
              downloadDocuments={
                props.project.purchaser?.budget?.downloadDocuments
              }
              projectName={props.project.name}
              projectNumber={props.project.number}
            />
          </Tab>
          {props.uniqueServices && props.uniqueServices?.length > 0 && (
            <Tab
              eventKey="servicesActions"
              title={t(tk.projects.services)}
              className="m-3"
            >
              <ServiceActionTab
                project={props.project}
                services={props.uniqueServices}
              />
            </Tab>
          )}
        </Tabs>
      )}
      {!props.project?.active && (
        <ValidationAlert
          show={true}
          errors={[
            `${t(tk.projects.for)} ${t(tk.prLang.genitiveProject)} ${t(tk.projects.cancelRequestApproved)}.`,
          ]}
          className="m-3"
        />
      )}
    </>
  );
};
