import { GetCountryDto } from '../common/pokCore/autogenerated/pokApiClient';

export const getCountryNameUI = (country?: GetCountryDto) => {
  if (country) {
    const lang =
      JSON.parse(sessionStorage.getItem('pok-language') || '{}') || '';
    if (lang === 'EN') {
      return country.nameEN;
    }
    return country.namePL;
  }
  return '-';
};
export const getCountryName = (lang: string, country?: GetCountryDto) => {
  if (country) {
    if (lang === 'EN') {
      return country.nameEN;
    }
    if (lang === 'DE') {
      return country.nameDE;
    }
    return country.namePL;
  }
  return '-';
};
