import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import {
  CreateUpdatePositionDto,
  GetClientDto,
  GetProfileDto,
  GetTeamDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import { Option } from '../Selects/Selector';
import DictionarySelector from '../Selects/DictionarySelector';
import {
  PositionTypeEnum,
  PositionTypeObj,
} from '../../../common/pokCore/validation/enums';
import TeamSelector from '../Selects/TeamSelector';
import CompanySelector from '../Selects/CompanySelector';
import { ClientSelector } from '../Selects/ClientSelector';
import ProfileSelector from '../Selects/ProfileSelector';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { getOptionValues } from '../Selects/selectUtils';

interface PositionEditorFormProps {
  id?: string;
  position: CreateUpdatePositionDto;
  client?: GetClientDto;
  team?: GetTeamDto;
  teams?: GetTeamDto[];
  profiles?: GetProfileDto[];
  companyId?: string | null;
  propertyChange: (obj: Partial<CreateUpdatePositionDto>) => void;
  positionsProvider: (text: string) => Promise<Option[]>;
}

export const PositionEditorForm: React.FC<PositionEditorFormProps> = props => {
  const { t, tk } = useTranslation('clientLng');

  const typeOptions = Object.entries(PositionTypeEnum).map(([key, value]) => ({
    value: key as PositionTypeEnum,
    label: t(PositionTypeObj[value]),
  }));

  return (
    <Form className="d-grid gap-4 mb-3">
      <FormRow controlId="client" label={t(tk.common.contractor)}>
        <ClientSelector
          onChange={e =>
            props.propertyChange({
              clientId: (e as Option).value,
            })
          }
          value={props.client}
          readOnly={!!props.client?.id}
        />
      </FormRow>
      <FormRow controlId="name" label={t(tk.common.name)}>
        <Form.Control
          type="text"
          value={props.position.name || ''}
          onChange={e =>
            props.propertyChange({ name: e.target.value || undefined })
          }
        />
      </FormRow>
      <FormRow controlId="groupName" label={t(tk.position.groupName)}>
        <CreatableClearableSelector
          value={props.position?.groupName}
          onChange={option =>
            props.propertyChange({
              groupName: (option as Option)?.label,
            })
          }
          provider={props.positionsProvider}
        />
      </FormRow>
      <FormRow controlId="positionType" label={t(tk.position.type)}>
        <Select
          className="full-width"
          value={
            props.position?.positionType
              ? typeOptions.find(
                  ({ value }) => props.position?.positionType === value,
                )
              : null
          }
          options={typeOptions}
          onChange={option =>
            props.propertyChange({
              positionType: option?.value,
            })
          }
          placeholder={t(tk.component.pointOut)}
        />
      </FormRow>
      <FormRow
        controlId="dictionaryId"
        label={t(tk.position.communicationCanal)}
      >
        <DictionarySelector
          dictionary="Kanały komunikacji"
          value={props.position?.dictionaryId}
          onChange={option =>
            props.propertyChange({ dictionaryId: option.value || undefined })
          }
        />
      </FormRow>
      <FormRow controlId="companies" label={t(tk.common.companies)}>
        <CompanySelector
          isMulti={true}
          value={props.position.companyIds}
          onChange={options => {
            props.propertyChange({
              companyIds: getOptionValues(options),
            });
          }}
        />
      </FormRow>
      <FormRow controlId="teams" label={t(tk.common.teams)}>
        <TeamSelector
          isMulti={true}
          value={props.position.teamIds || []}
          clearable={true}
          companyIds={props.position.companyIds}
          onChange={options => {
            props.propertyChange({
              teamIds: getOptionValues(options),
            });
          }}
        />
      </FormRow>
      <FormRow controlId="profiles" label={t(tk.position.profilesEstimates)}>
        <ProfileSelector
          isMulti={true}
          value={props.position.profileIds || []}
          companyIds={props.position.companyIds}
          teamIds={props.position.teamIds ?? []}
          onChange={options => {
            props.propertyChange({
              profileIds: getOptionValues(options),
            });
          }}
        />
      </FormRow>
      {props.companyId && (
        <FormRow controlId="team" label={t(tk.position.specialTeam)}>
          <TeamSelector
            value={props.team?.id}
            companyId={props.companyId}
            clearable={true}
            onChange={option =>
              props.propertyChange({
                teamId: (option as Option)?.value || undefined,
              })
            }
          />
        </FormRow>
      )}
      <FormRow
        controlId="notPurchaseDeficiencies"
        label={t(tk.position.notPurchaseDeficiencies)}
      >
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.position.notPurchaseDeficiencies ?? false}
          onChange={e =>
            props.propertyChange({ notPurchaseDeficiencies: e.target.checked })
          }
        />
      </FormRow>
      <FormRow controlId="active" label={t(tk.common.activeShe)}>
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.position.active ?? true}
          onChange={e => props.propertyChange({ active: e.target.checked })}
        />
      </FormRow>
    </Form>
  );
};
