import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  CreateUpdateSalesInvoiceDto,
  CreateUpdateSalesInvoiceDtoStatusEnum,
  GetSalesInvoiceDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { SalesInvoiceEditorTabs } from '../../containers/SalesInvoiceView/SalesInvoiceEditorTabs';
import { useNavigation } from '../../../common/navigation';
import { validate } from '../../../common/pokCore/contexts/SalesInvoicesContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { SalesInvoiceDeactivateConfirm } from './SalesInvoiceDeactivateConfirm';
import { SalesInvoicePrintControls } from './SalesInvoicePrintControls';

interface SalesInvoiceBasicDataControlsProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  invoiceStatus: CreateUpdateSalesInvoiceDtoStatusEnum;
  parent?: GetSalesInvoiceDto;
  readOnly?: boolean;
  isDeactivated?: boolean;
  id?: string;
  handleRefresh: () => void;
  handleRemove: () => void;
  setNotSavedTab: (tab: SalesInvoiceEditorTabs | undefined) => void;
  setSaveErrors: Dispatch<SetStateAction<string[]>>;
  setShowSaveErrors: Dispatch<SetStateAction<boolean>>;
  allowCorrection?: boolean;
  originalInvoice?: GetSalesInvoiceDto;
}

export const SalesInvoiceBasicDataControls: React.FC<
  SalesInvoiceBasicDataControlsProps
> = props => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [isCreateCorrectionLoading, setIsCreateCorrectionLoading] =
    useState(false);
  const nav = useNavigation();
  const notifications = useNotifications();
  const pok = usePokCore();
  const { t, tk } = useTranslation('fvsLng');

  const handleGoToNextStep = (id?: string) => {
    if (id) {
      props.handleRefresh();
      searchParams.set('salesInvoiceId', id);
      searchParams.delete('projectId');
      setSearchParams(searchParams, {
        state: {
          tabKey: SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS,
        },
      });
    }
  };

  const createSalesInvoice = async () => {
    setIsSaveLoading(true);
    props.setSaveErrors([]);
    props.setShowSaveErrors(false);
    addEditSalesInvoice(props.salesInvoice, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          props.setSaveErrors(saveStatus.errors);
          props.setShowSaveErrors(true);
          return;
        }

        props.setNotSavedTab(undefined);
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsSaveLoading(false));
  };

  const addEditSalesInvoice = async (
    salesInvoiceCU: CreateUpdateSalesInvoiceDto,
    id?: string,
  ) => {
    const status = await validate(salesInvoiceCU);
    if (!status.valid) {
      return {
        saved: false,
        errors: status.errors,
      };
    }
    return saveUpdateSalesInvoice(salesInvoiceCU, id);
  };

  const saveUpdateSalesInvoice = async (
    salesInvoiceCU: CreateUpdateSalesInvoiceDto,
    id?: string,
  ) => {
    try {
      if (id) {
        await pok.salesInvoices.update(id, salesInvoiceCU);
        handleGoToNextStep(id);
      } else {
        const data = await pok.salesInvoices.create(salesInvoiceCU);
        handleGoToNextStep(data.id);
      }

      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as ResponseError),
      };
    }
  };

  const createCorrection = async () => {
    setIsCreateCorrectionLoading(true);
    props.setSaveErrors([]);
    props.setShowSaveErrors(false);

    if (props.id) {
      pok.salesInvoices
        .createCorrection(props.id)
        .then(data => {
          nav.salesInvoiceEditor({
            salesInvoiceId: data.id,
            tab: SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS,
            notSavedTab: SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS,
          });
          void nav.reload();
        })
        .catch(async response => {
          const errors = await responseErrors(response as ResponseError);
          props.setSaveErrors(errors);
          props.setShowSaveErrors(true);
        })
        .finally(() => setIsCreateCorrectionLoading(false));
    }
  };

  return (
    <div className="d-flex marginLeft gap-2">
      {props.id && (
        <SalesInvoiceDeactivateConfirm
          invoiceStatus={props.invoiceStatus}
          isDeactivated={props.isDeactivated}
          handleRemove={props.handleRemove}
          disabled={isButtonsBlocked || isSaveLoading}
        />
      )}
      {props.allowCorrection && (
        <ConfirmationButton
          onOK={createCorrection}
          confirmation={t(tk.finance.askCreateInvoiceDraft, {
            number: props.originalInvoice?.number || props.salesInvoice.number,
          })}
          variant="outline-primary"
          modalSize="lg"
          isLoading={isCreateCorrectionLoading}
          disabled={isButtonsBlocked}
        >
          {t(tk.finance.issuingCorrection)}
        </ConfirmationButton>
      )}
      <SalesInvoicePrintControls
        {...props}
        setIsButtonsBlocked={setIsButtonsBlocked}
      />
      <Button
        variant="primary"
        onClick={createSalesInvoice}
        disabled={props.readOnly || isButtonsBlocked}
        isLoading={isSaveLoading}
      >
        {t(tk.finance.saveAndStepForward)}
      </Button>
    </div>
  );
};
