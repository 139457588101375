/*eslint max-lines-per-function: ["error", 223]*/
/*eslint complexity: ["error", 24]*/
import React, { useState } from 'react';
import { Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';

import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import FormRow from '../../../app/components/FormRow';
import PositionSelector from '../Selects/PositionSelector';
import ProfileCategorySelector from '../Selects/ProfileCategorySelector';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { NewDatePicker } from '../../../app/components/NewDatePicker';

import { fieldsToClear as getFieldsToClear } from './CommissionService';
import { ParametersTab } from './ParametersTab';
import { SalesTab } from './SalesTab';
import { EstimateItemTabsFooter } from './EstimateItemTabsFooter';
import { PurchaseInvoicesTab } from './PurchaseInvoicesTab';
import { SalesInvoicesTab } from './SalesInvoicesTab';

interface EstimateItemTabsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  positionName?: string;
  id?: string;
  project?: GetProjectDto;
  profile?: GetProfileDto;
  isSalesBlocked: boolean;
  isPurchaseBlocked: boolean;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
  onCompleted: (
    item: CreateUpdateEstimateItemDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
    amountFieldsValid?: boolean;
  }>;
  onDelete: (id?: string) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  cloneItem: (id: string, withAmounts: boolean) => () => void;
  closeAccordion: () => void;
}

export const EstimateItemTabs: React.FC<EstimateItemTabsProps> = props => {
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState('amounts');
  const [estimateItem, setEstimateItem] = useState<CreateUpdateEstimateItemDto>(
    props.estimateItem,
  );
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('projectLng');

  const createEstimateItem = async () => {
    setIsLoadingSave(true);
    props
      .onCompleted(estimateItem, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          if (saveStatus.amountFieldsValid === true) {
            setActiveTab('parameters');
          }
          return;
        }
        setSaveErrors([]);
        setShowSaveErrors(false);
        notifications.saveCompleted();
        props.closeAccordion();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoadingSave(false));
  };

  const deleteItem = async () => {
    setIsLoadingDelete(true);
    props
      .onDelete(props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoadingDelete(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    if (obj.positionId) {
      pok.positions
        .getById(obj.positionId)
        .then(position => {
          const fieldsToClear = getFieldsToClear(
            position.positionType,
            props.profile?.commission,
          );
          setEstimateItem({
            ...estimateItem,
            ...obj,
            ...fieldsToClear,
            positionType: position.positionType,
          });
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
    setEstimateItem({ ...estimateItem, ...obj });
  };

  const handleDateStartChange = (startDate: Date) => {
    if (propertyChange) {
      propertyChange({ startDate });
    }
  };

  const handleDateEndChange = (endDate: Date) => {
    if (propertyChange) {
      propertyChange({ endDate });
    }
  };

  return (
    <>
      <Row className="mt-3">
        <Col className="col-lg-6 col-12 mb-1">
          <Form className="d-grid gap-4 mb-3">
            <FormRow controlId="startDate" label={t(tk.common.startDate)}>
              <NewDatePicker
                value={estimateItem?.startDate}
                dateChanged={handleDateStartChange}
              />
            </FormRow>
            <FormRow controlId="position" label={t(tk.common.profilePosition)}>
              <PositionSelector
                teamIds={
                  props.project?.projectTeams
                    ? props.project?.projectTeams.map(proTeam => proTeam.teamId)
                    : []
                }
                profileId={props.profile?.id}
                commission={props.profile?.commission}
                budgetId={props.project?.purchaser?.budget?.id}
                value={estimateItem?.positionId}
                onChange={option =>
                  propertyChange({
                    positionId: option?.value,
                  })
                }
              />
            </FormRow>
          </Form>
        </Col>
        <Col className="col-lg-6 col-12 mb-1">
          <Form className="d-grid gap-4 mb-3">
            <FormRow controlId="endDate" label={t(tk.common.endDate)}>
              <NewDatePicker
                value={estimateItem?.endDate}
                dateChanged={handleDateEndChange}
              />
            </FormRow>
            <FormRow controlId="profileCat" label={t(tk.estimates.category)}>
              <ProfileCategorySelector
                autoSelect={true}
                profileId={props.profile?.id || ''}
                value={estimateItem?.profileCategoryId}
                onChange={option =>
                  propertyChange({
                    profileCategoryId: option?.value,
                  })
                }
              />
            </FormRow>
          </Form>
        </Col>
      </Row>
      <Card className="my-3">
        <Tabs
          className="mb-3"
          activeKey={activeTab}
          onSelect={tab => setActiveTab(tab as string)}
        >
          <Tab
            eventKey="amounts"
            title={t(tk.estimates.amounts)}
            className="m-3"
          >
            <SalesTab
              estimateItem={estimateItem}
              project={props.project}
              profile={props.profile}
              propertyChange={propertyChange}
              isSalesBlocked={props.isSalesBlocked}
              isPurchaseBlocked={props.isPurchaseBlocked}
              lang={pok.getLanguage()}
            />
          </Tab>
          <Tab
            eventKey="parameters"
            title={t(tk.estimates.parameters)}
            className="m-3"
          >
            <ParametersTab
              estimateItem={estimateItem}
              project={props.project}
              profile={props.profile}
              propertyChange={propertyChange}
            />
          </Tab>
          <Tab
            eventKey="purchaseInvoices"
            title={t(tk.estimates.fvz)}
            className="m-5"
          >
            <PurchaseInvoicesTab
              estimateItemId={props.id}
              downloadDocuments={
                props.project?.purchaser?.budget?.downloadDocuments || false
              }
              name={`${props.project?.name}_${props.positionName || ''}`}
              number={props.project?.number || ''}
            />
          </Tab>
          <Tab
            eventKey="salesInvoices"
            title={t(tk.estimates.fvs)}
            className="m-5"
          >
            <SalesInvoicesTab estimateItemId={props.id} />
          </Tab>
        </Tabs>
      </Card>
      <ValidationAlert
        show={showSaveErrors}
        errors={saveErrors}
        className="m-3"
      />
      <EstimateItemTabsFooter
        id={props.id}
        deleteItem={deleteItem}
        createEstimateItem={createEstimateItem}
        cloneItem={props.cloneItem}
        isSalesBlocked={props.isSalesBlocked}
        isPurchaseBlocked={props.isPurchaseBlocked}
        isLoadingSave={isLoadingSave}
        isLoadingDelete={isLoadingDelete}
      />
    </>
  );
};
