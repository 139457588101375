import React from 'react';

import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { CreateUpdateProjectWithTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ProjectDelayFormProps {
  project: CreateUpdateProjectWithTeamDto;
  readOnly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateProjectWithTeamDto>) => void;
}

export const ProjectDelayForm: React.FC<ProjectDelayFormProps> = props => {
  const { t, tk } = useTranslation('projectLng');
  return (
    <>
      <ValidationAlert
        show={true}
        errors={[t(tk.prLang.errCreateProjectSendApplication)]}
        className="m-3"
      />
      <FormRow
        controlId="delayMessage"
        label={t(tk.projects.applicationContent)}
      >
        <DynamicTextarea
          value={props.project?.delayMessage || ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ delayMessage: e.target.value })
          }
          readOnly={props.readOnly}
        />
      </FormRow>
    </>
  );
};
