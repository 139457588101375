import React from 'react';

import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';

import ProfileFieldName from './ProfileFieldName';

interface InternetProfileParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  lang: string;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const InternetProfileParams: React.FC<
  InternetProfileParamsProps
> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    if (props.propertyChange) {
      props.propertyChange(obj);
    }
  };

  return (
    <>
      {schema?.purchaseFormInternet && (
        <ProfileFieldName
          controlId="purchaseFormInternet"
          schema={schema?.purchaseFormInternet}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Internet - forma zakupu"
            value={props.estimateItem?.purchaseFormInternetId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                purchaseFormInternetId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.categoryInternet && (
        <ProfileFieldName
          controlId="categoryInternet"
          schema={schema?.categoryInternet}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Internet - kategoria"
            value={props.estimateItem?.categoryInternetId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                categoryInternetId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.formatInternet && (
        <ProfileFieldName
          controlId="formatInternet"
          schema={schema?.formatInternet}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Internet - format"
            value={props.estimateItem?.formatInternetId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatInternetId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
    </>
  );
};
