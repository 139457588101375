import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

import { usePokCore } from '../../hooks/usePokCore';
import { useNotifications } from '../../hooks/useNotifications';
import { GetCompanyDto } from '../../pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../hooks/useTranslation';

interface BadCompanyProps {
  companyId?: string;
}

export const BadCompany: React.FC<BadCompanyProps> = ({ companyId }) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [company, setCompany] = useState<GetCompanyDto>();
  const { t, tk } = useTranslation('menuLng');

  useEffect(() => {
    if (companyId) {
      pok.companies
        .getById(companyId)
        .then(company => {
          setCompany(company);
        })
        .catch(err => {
          notifications.caughtError(err);
        });
    }
  }, [pok, notifications, companyId]);

  return (
    <Alert variant="danger">
      <Alert.Heading>{t(tk.system.badCompany)}</Alert.Heading>
      <hr />
      <p>{t(tk.system.badCompanyLongText)}</p>
      {company && (
        <>
          {t(tk.system.rightCompany)} <b>{company?.name}</b>
        </>
      )}
    </Alert>
  );
};
