import React from 'react';
import { Spinner } from 'react-bootstrap';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { GetSalesInvoiceDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import Button from '../../../common/components/Button/Button';

interface SalesInvoiceMediaPlanPositionsControlsProps {
  salesInvoice?: GetSalesInvoiceDto;
  createInvoiceEstimateItems: () => void;
  isSaveDisabled: boolean;
  isSaving: boolean;
}

export const SalesInvoiceMediaPlanPositionsControls: React.FC<
  SalesInvoiceMediaPlanPositionsControlsProps
> = props => {
  const { t, tk } = useTranslation('fvsLng');

  return (
    <div className="d-flex justify-content-end">
      {props.salesInvoice?.salesInvoicePositions?.length &&
      !props.salesInvoice?.parent ? (
        <ConfirmationButton
          variant="primary"
          onOK={props.createInvoiceEstimateItems}
          confirmation={t(tk.prLang.estimateItemInvoiceModifyWarning)}
          disabled={props.isSaveDisabled || props.isSaving}
        >
          {props.isSaving && <Spinner size={'sm'} className="me-1" />}
          {t(tk.finance.saveAndStepForward)}
        </ConfirmationButton>
      ) : (
        <Button
          variant="primary"
          onClick={props.createInvoiceEstimateItems}
          disabled={props.isSaveDisabled}
          isLoading={props.isSaving}
        >
          {t(tk.finance.saveAndStepForward)}
        </Button>
      )}
    </div>
  );
};
