import React from 'react';

import { useTranslation } from '../../../common/hooks/useTranslation';

import { ReportFVSByFilterRawDataView } from './ReportFVSByFilterRawDataView';

export const ReportFVSWithoutPositionsByFilterRawDataView: React.FC = () => {
  const { t, tk } = useTranslation('reportLng');
  return (
    <ReportFVSByFilterRawDataView
      title={t(tk.finance.reportFVSLong)}
      withPositions={false}
    />
  );
};
