import React from 'react';
import { Button as ButtonBootstrap, Spinner } from 'react-bootstrap';
import type { ButtonProps as ButtonBootstrapProps } from 'react-bootstrap';

interface ButtonProps extends ButtonBootstrapProps {
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, isLoading, disabled, ...props }, ref) => {
    return (
      <ButtonBootstrap ref={ref} {...props} disabled={disabled || isLoading}>
        {isLoading && <Spinner animation="border" size="sm" className="me-1" />}
        {children}
      </ButtonBootstrap>
    );
  },
);

Button.displayName = 'Button';

export default Button;
