/**
 * This file is shared between pok-core & pok-web
 * If you have to change it then do it in pok-core and run yarn get:validation-schemas @pok-web
 */

import { tk } from './tk';

export enum ObjectEnum {
  Project = 'Project',
  Invoice = 'Invoice',
  CorrectInvoice = 'CorrectInvoice',
}

export enum PersonalLanguageType {
  Projects = 'Projects',
  Campaigns = 'Campaigns',
}

export const PersonalLanguageTypeObj = {
  Projects: tk.enum.projects,
  Campaigns: tk.enum.campaigns,
};

export enum LanguageType {
  PL = 'PL',
  EN = 'EN',
}

export const LanguageTypeTextObj = {
  PL: tk.enum.pl,
  EN: tk.enum.en,
};

export enum PositionTypeEnum {
  Standard = 'Standard',
  Commission = 'Commission',
  TransferredCommission = 'TransferredCommission',
}

export const PositionTypeObj = {
  Standard: tk.enum.standard,
  Commission: tk.enum.commission,
  TransferredCommission: tk.enum.transferredCommission,
};

export enum ApplicationTypeEnum {
  Position = 'Position',
  Purchaser = 'Purchaser',
  DictionaryValue = 'DictionaryValue',
  Brand = 'Brand',
  Permission = 'Permission',
}

export const ApplicationTypeObj = {
  Position: tk.enum.position,
  Purchaser: tk.enum.purchaser,
  DictionaryValue: tk.enum.dictionaryValue,
  Brand: tk.enum.brand,
  Permission: tk.enum.permission,
};

export enum ApplicationStatusEnum {
  New = 'New',
  Completed = 'Completed',
  Rejected = 'Rejected',
}

export const ApplicationStatusObj = {
  New: tk.enum.new,
  Completed: tk.enum.completed,
  Rejected: tk.enum.rejected,
};

export enum EmailStatus {
  NotSent = 'NotSent',
  Sent = 'Sent',
  Error = 'Error',
}

export const EmailStatusObj = {
  NotSent: tk.enum.notSent,
  Sent: tk.enum.sent,
  Error: tk.enum.error,
};

//TODO: usunac
export enum CurrencyIsoTypeEnum {
  PLN = 'PLN',
  USD = 'USD',
  EUR = 'EUR',
  CZK = 'CZK',
  RUB = 'RUB',
  HUF = 'HUF',
  GBP = 'GBP',
  BGN = 'BGN',
  RON = 'RON',
  HRK = 'HRK',
  AUD = 'AUD',
}

export enum ProjectEstimatesStatusEnum {
  New = 'New',
  AcceptedByClient = 'AcceptedByClient',
  ReadyForInvoice = 'ReadyForInvoice',
  Invoiced = 'Invoiced',
  Corrected = 'Corrected',
  Settled = 'Settled',
  Cancelled = 'Cancelled',
}

export const ProjectEstimatesStatusObj = {
  New: tk.enum.new,
  AcceptedByClient: tk.enum.acceptedByClient,
  ReadyForInvoice: tk.enum.readyForInvoice,
  Invoiced: tk.enum.invoiced,
  Corrected: tk.enum.corrected,
  Settled: tk.enum.settled,
  Cancelled: tk.enum.cancelled,
};

export enum CustomExceptionType {
  ProjectDelayed = 'ProjectDelayed',
}

export enum PackageTypeEnum {
  Purchase = 'Purchase',
  Sale = 'Sale',
}

export enum InvoiceTypeEnum {
  Foreign = 'Foreign',
  Domestic = 'Domestic',
}

export const InvoiceTypeObj = {
  Foreign: tk.enum.foreign,
  Domestic: tk.enum.domestic,
};

export enum InvoiceStatusEnum {
  Draft = 'Draft',
  Generated = 'Generated',
  Sent = 'Sent',
  Paid = 'Paid',
}

export const InvoiceStatusObj = {
  Draft: tk.enum.draft,
  Generated: tk.enum.generated,
  Sent: tk.enum.sentShe,
  Paid: tk.enum.paid,
};

export enum FilterInvoiceTypeEnum {
  PurchaseInvoice,
  SalesInvoice,
}

export enum PurchaseInvoiceStatusEnum {
  Open = 'Open',
  Approved = 'Approved',
  Sent = 'Sent',
}

export const PurchaseInvoiceStatusObj = {
  Open: tk.enum.open,
  Approved: tk.enum.approved,
  Sent: tk.enum.sentShe,
};

export enum PaymentFormEnum {
  BankTransfer = 'BankTransfer',
  Compensation = 'Compensation',
  Prepayment = 'Prepayment',
  Paid = 'Paid',
}

export enum PaymentFormEnumDe {
  BankTransfer = 'Überweisung',
  Compensation = 'Kompensation',
  Prepayment = 'Vorauszahlung',
  Paid = 'Bezahlt',
}

export enum PaymentFormEnumSymfoniaText {
  BankTransfer = 'przelew',
  Compensation = 'kompensata',
  Prepayment = 'przedpłata',
  Paid = 'zapłacono',
}

export enum PaymentFormEnumEnglishText {
  BankTransfer = 'payment',
  Compensation = 'compensation',
  Prepayment = 'prepayment',
  Paid = 'paid',
}

export const PaymentFormObj = {
  BankTransfer: tk.enum.bankTransfer,
  Compensation: tk.enum.compensation,
  Prepayment: tk.enum.prepayment,
  Paid: tk.enum.paidIt,
};

export enum OrderStatusEnum {
  New = 'New',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export const OrderStatusObj = {
  New: tk.enum.newPlural,
  Accepted: tk.enum.acceptedPlural,
  Rejected: tk.enum.rejectedPlural,
};

export enum OrderExecutionStatusEnum {
  New = 'New',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export const OrderExecutionStatusObj = {
  New: tk.enum.newPlural,
  Accepted: tk.enum.acceptedPlural,
  Rejected: tk.enum.rejectedPlural,
};

export enum AttachmentObjectType {
  Budget = 'Budget',
  Project = 'Project',
  PurchaseInvoice = 'PurchaseInvoice',
  EstimateItemCommentMediaplanner = 'EstimateItemCommentMediaplanner',
  EstimateItemCommentFinance = 'EstimateItemCommentFinance',
}

export const AttachmentObjectTypeObj = {
  Budget: tk.enum.budget,
  Project: tk.enum.project,
  PurchaseInvoice: tk.enum.purchaseInvoice,
  EstimateItemCommentMediaplanner: tk.enum.estimateItemCommentMediaplanner,
  EstimateItemCommentFinance: tk.enum.estimateItemCommentFinance,
};

export enum PdfTemplateType {
  Invoice = 'Invoice',
  InvoiceEng = 'InvoiceEng',
  InvoiceCorrection = 'InvoiceCorrection',
  InvoiceCorrectionEng = 'InvoiceCorrectionEng',
  InvoiceDataPrintout = 'InvoiceDataPrintout',
  OrderConfirmation = 'OrderConfirmation',
}

export enum EmailTemplateType {
  Order = 'Order',
  OrderExecution = 'OrderExecution',
  Application = 'Application',
  ApplicationExecution = 'ApplicationExecution',
  Project = 'Project',
  ProjectCancel = 'ProjectCancel',
  UnblockEstimate = 'UnblockEstimate',
  PurchaseDeficiencies = 'PurchaseDeficiencies',
  SpecialInvoicing = 'SpecialInvoicing',
  UnsettledInvoices = 'UnsettledInvoices',
  DelayedInvoices = 'DelayedInvoices',
}

export enum ApplicationUnblockStatusEnum {
  New = 'New',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export const ApplicationUnblockStatusObj = {
  New: tk.enum.new,
  Accepted: tk.enum.accepted,
  Rejected: tk.enum.rejected,
};

export enum ApplicationUnblockTypeEnum {
  Sales = 'Sales',
  Purchase = 'Purchase',
}

export const ApplicationUnblockTypeObj = {
  Sales: tk.enum.sales,
  Purchase: tk.enum.purchase,
};

export enum MemoSubjectType {
  Project = 'Project',
  PurchaseInvoice = 'PurchaseInvoice',
  SaleInvoice = 'SaleInvoice',
  SpecialInvoicing = 'SpecialInvoicing',
}

export const MemoSubjectTypeObj = {
  Project: tk.enum.projectNoun,
  PurchaseInvoice: tk.enum.purchaseInvoiceNoun,
  SaleInvoice: tk.enum.saleInvoiceNoun,
  SpecialInvoicing: tk.enum.specialInvoicingNoun,
};

export enum ProjectApplicationTypeEnum {
  Delayed = 'Delayed',
  ToCancel = 'ToCancel',
  All = 'All',
}

export const ProjectApplicationTypeEnumObj = {
  Delayed: tk.enum.delayed,
  ToCancel: tk.enum.toCancel,
  All: tk.enum.all,
};

export enum SpecialInvoicingStatus {
  New = 'New',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  ToBeCorrected = 'ToBeCorrected',
}

export const SpecialInvoicingStatusObj = {
  New: tk.enum.newPlural,
  Accepted: tk.enum.acceptedPlural,
  Rejected: tk.enum.rejectedPlural,
  ToBeCorrected: tk.enum.toBeCorrected,
};

export enum ExcelImportTypeEnum {
  Others = 'Others',
  TVBuying = 'TVBuying',
  MPC = 'MPC',
}

export const ExcelImportTypeObj = {
  Others: tk.enum.others,
  TVBuying: tk.enum.tvBuying,
  MPC: tk.enum.MPC,
};

export enum EmailConfigTypeEnum {
  Applications = 'Applications',
  PositionAppForeign = 'PositionAppForeign',
  ApplicationExecutions = 'ApplicationExecutions',
  Orders = 'Orders',
  OrdersRemind = 'OrdersRemind',
  OrderExecutions = 'OrderExecutions',
  OrderExecutionsRemind = 'OrderExecutionsRemind',
  UnblockEstimateByMonth = 'UnblockEstimateByMonth',
  ProjectsDelayed = 'ProjectsDelayed',
  ProjectsToCancel = 'ProjectsToCancel',
  PurchaseDeficiencies = 'PurchaseDeficiencies',
  SpecialInvoicing = 'SpecialInvoicing',
}

export const EmailConfigTypeObj = {
  Applications: tk.enum.applications,
  PositionAppForeign: tk.enum.positionAppForeign,
  ApplicationExecutions: tk.enum.applicationExecutions,
  Orders: tk.enum.orders,
  OrdersRemind: tk.enum.ordersRemind,
  OrderExecutions: tk.enum.orderExecutions,
  OrderExecutionsRemind: tk.enum.orderExecutionsRemind,
  UnblockEstimateByMonth: tk.enum.unblockEstimateByMonth,
  ProjectsDelayed: tk.enum.projectsDelayed,
  ProjectsToCancel: tk.enum.projectsToCancel,
  PurchaseDeficiencies: tk.enum.purchaseDeficiencies,
  SpecialInvoicing: tk.enum.specialInvoicing,
};

export enum ExcelImportStatusEnum {
  OK = 'OK',
  Error = 'Error',
  Duplicate = 'Duplicate',
}

export const ExcelImportStatusObj = {
  OK: tk.enum.ok,
  Error: tk.enum.error,
  Duplicate: tk.enum.duplicate,
};

export enum JobTypeEnum {
  OrderExecutionReminder = 'OrderExecutionReminder',
  OrderReminder = 'OrderReminder',
  DailyFVZReminder = 'DailyFVZReminder',
  WeekFVZReminder = 'WeekFVZReminder',
}

export const JobTypeObj = {
  OrderExecutionReminder: tk.enum.orderExecutionReminder,
  OrderReminder: tk.enum.orderReminder,
  DailyFVZReminder: tk.enum.dailyFVZReminder,
  WeekFVZReminder: tk.enum.weekFVZReminder,
};

export enum TemplateTypeEnum {
  Poland = 'Poland',
  UnitedStates = 'UnitedStates',
  Germany = 'Germany',
}

export const TemplateTypeObj = {
  Poland: tk.enum.poland,
  UnitedStates: tk.enum.unitedStates,
  Germany: tk.enum.germany,
};

export enum VatEnum {
  '23%' = '23%',
  '19%' = '19%',
  '8%' = '8%',
  '5%' = '5%',
  '0%' = '0%',
  'zw.' = 'zw.',
  'np.' = 'np.',
}
