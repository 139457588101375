export default {
  config: {
    // import configurations
    profile: 'Profile',
    profileCategory: 'Profile category',

    importConfigsByName: 'Data import configuration named',

    importConfig: 'Import configuration',
    importConfigs: 'External data import configurations',
    importConfigAdd: 'Adding a new configuration',
    importConfigNew: 'New configuration',
    importConfigEdit: 'Editing configuration',
    noImportConfigs: 'No import configurations in the system.',
    importConfigType: 'Type of import',
    importConfigDescription: 'Import instructions',
    companyIndependent: 'Company independent',
    updateExisting: 'Updating existing positions',

    mappingNew: 'New mapping',
    mappingEdit: 'Editing mapping',
    mappingAdd: 'Adding new mapping',
    columnInPOK: 'Column in POK',
    columnInExcel: 'Column in Excel',
    columnOrder: 'Column order in the import table',
    noColumnMapping: 'No column mappings defined',

    mappingColumn: 'Column mapping',
    mappingPosition: 'Position mapping',
    positionInPok: 'Position in POK',
    positionInExcel: 'Position in Excel',
    positionParameters: 'Position parameters set',
    noPosition: 'No positions defined',
    parameters: 'Parameters',
    noConfigsDefined: 'No configurations defined',
    addNewConfigsContactAdmin:
      'To add new configurations, please contact the administration.',
  },

  import: {
    correctlyImported: 'Correctly imported positions',
    added: 'added',
    updated: 'updated',
    notImported: 'Not imported positions',
    errors: 'Errors',
    noErrors: 'No errors',
    amountEdit: 'Amount edit',
    editAmount: 'Edit amount',
    amount: 'Amount',
    restoreOriginalAmount: 'Restore original value',
    amountNotEmpty: 'Amount cannot be empty',
    noPositionsSelected: 'No positions selected for import.',
    positions: 'Positions in POK',
    positionsToUpdate: 'Number of positions to update',
    newPositions: 'Number of new positions',
    fileName: 'Sheet name',
    configName: 'Configuration name',
    selected: 'Selected',
    positionAccusative: 'position',
    positionsAccusative: 'positions',
    positionsGenitive: 'positions',
    askImportSelected:
      'Are you sure you want to import the selected positions?',
    importSelected: 'Import selected',
    from: 'From',
    to: 'To',
    update: 'Update',
    new: 'New',
    noPositions: 'No positions.',
    choose: 'Please choose',
    chooseMonth: 'Please choose a month',
    fileWasModified: 'The file has been modified, please select it again.',
    loadingData: 'Loading data...',
    comparingData: 'Comparing data...',
    importingData: 'Importing data...',
    config: 'Configuration',
    excelFile: 'Excel file containing data to import',
    importInstruction: 'Import instructions',
    import: 'Import',
    newImport: 'New import',
    importSummary: 'Import summary',
    askCancelImport: 'Are you sure you want to exit the current import?',
    aggregatePositions: 'Aggregate positions without errors',
    hideErrorPositions: 'Hide erroneous positions',
    positionsPreview: 'Preview of positions from the sheet',
    imported: 'Imported',
    correct: 'correct',
    error: 'incorrect',
    goToImport: 'Go to import correct positions',
    lack: 'Lack',
    positionNameInExcel: 'Position name in Excel',
    noName: 'No name',
    notFound: 'Not found',
    rowNumber: 'Row number',
    fromConfig: ' (from config.)',
    changeParam: 'Change parameter',
    chooseParam: 'Choose parameter',
    nameFromExcel: 'Name from Excel',
    nameFromDictionary: 'Name from dictionary',
    changePosition: 'Change position',
    positionChange: 'Position change',
    position: 'Position',
    addPositionMapping: 'Add position mapping',
  },
} as const;
