/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDictionaryDto } from './GetDictionaryDto';
import {
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './GetDictionaryDto';
import type { GetEstimateByMonthDto } from './GetEstimateByMonthDto';
import {
    GetEstimateByMonthDtoFromJSON,
    GetEstimateByMonthDtoFromJSONTyped,
    GetEstimateByMonthDtoToJSON,
} from './GetEstimateByMonthDto';
import type { GetPositionDto } from './GetPositionDto';
import {
    GetPositionDtoFromJSON,
    GetPositionDtoFromJSONTyped,
    GetPositionDtoToJSON,
} from './GetPositionDto';
import type { GetProfileCategoryDto } from './GetProfileCategoryDto';
import {
    GetProfileCategoryDtoFromJSON,
    GetProfileCategoryDtoFromJSONTyped,
    GetProfileCategoryDtoToJSON,
} from './GetProfileCategoryDto';

/**
 * 
 * @export
 * @interface GetEstimateItemDocumentControlDto
 */
export interface GetEstimateItemDocumentControlDto {
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    id: string;
    /**
     * 
     * @type {GetEstimateByMonthDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    estimateByMonth: GetEstimateByMonthDto;
    /**
     * 
     * @type {Date}
     * @memberof GetEstimateItemDocumentControlDto
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetEstimateItemDocumentControlDto
     */
    endDate: Date;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    importFileName: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    salesInvoicesSum: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    purchaseInvoicesSum: string;
    /**
     * 
     * @type {GetPositionDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    position: GetPositionDto;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    positionType: GetEstimateItemDocumentControlDtoPositionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    rcSalesTotal: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    salesSurchargePercent: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    salesDiscountPercent: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    salesNet: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    salesCommissionPercent: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    salesCommissionAmount: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    salesNetTechnicalCost: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    salesNetTotal: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    rcPurchase: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    purchaseTechnicalCost: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    purchaseDiscountPercent: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    purchaseNetTotal: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    purchaseNet: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    purchaseSurchargePercent: string;
    /**
     * 
     * @type {GetProfileCategoryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    profileCategory: GetProfileCategoryDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetEstimateItemDocumentControlDto
     */
    isUpSell: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    netCustomerAccount: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    numberOfMedia: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    additionalDescription: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    orderNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    ownName: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    technologyDictionary: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    regionDictionary: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    formatRadio: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    formatPress: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    formatInternet: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    formatInternetST: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    emissionTypeRadio: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    emissionTypeCinema: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    purchaseFormInternet: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    purchaseFormInternetST: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    categoryInternet: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    categoryInternetST: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    categoryLabcon: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    copySize: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    purchaseMethod: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    medium: GetDictionaryDto;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetEstimateItemDocumentControlDto
     */
    labconChannel: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    salesCommissionSum: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    service: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    actionGroup: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    serviceExternalWorkPercent: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    serviceCreationPercent: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    serviceChangeProductionPercent: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    serviceStrategyPercent: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    serviceProductionBroadcastPercent: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    serviceClientServicePercent: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetEstimateItemDocumentControlDto
     */
    clone?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    mpXlsx: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    mpAssigned: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    pmProject: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    attachmentOrder: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    internalOrder: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    fvz: string;
    /**
     * 
     * @type {string}
     * @memberof GetEstimateItemDocumentControlDto
     */
    fvs: string;
}


/**
 * @export
 */
export const GetEstimateItemDocumentControlDtoPositionTypeEnum = {
    Standard: 'Standard',
    Commission: 'Commission',
    TransferredCommission: 'TransferredCommission'
} as const;
export type GetEstimateItemDocumentControlDtoPositionTypeEnum = typeof GetEstimateItemDocumentControlDtoPositionTypeEnum[keyof typeof GetEstimateItemDocumentControlDtoPositionTypeEnum];


/**
 * Check if a given object implements the GetEstimateItemDocumentControlDto interface.
 */
export function instanceOfGetEstimateItemDocumentControlDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "estimateByMonth" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "importFileName" in value;
    isInstance = isInstance && "salesInvoicesSum" in value;
    isInstance = isInstance && "purchaseInvoicesSum" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "positionType" in value;
    isInstance = isInstance && "rcSalesTotal" in value;
    isInstance = isInstance && "salesSurchargePercent" in value;
    isInstance = isInstance && "salesDiscountPercent" in value;
    isInstance = isInstance && "salesNet" in value;
    isInstance = isInstance && "salesCommissionPercent" in value;
    isInstance = isInstance && "salesCommissionAmount" in value;
    isInstance = isInstance && "salesNetTechnicalCost" in value;
    isInstance = isInstance && "salesNetTotal" in value;
    isInstance = isInstance && "rcPurchase" in value;
    isInstance = isInstance && "purchaseTechnicalCost" in value;
    isInstance = isInstance && "purchaseDiscountPercent" in value;
    isInstance = isInstance && "purchaseNetTotal" in value;
    isInstance = isInstance && "purchaseNet" in value;
    isInstance = isInstance && "purchaseSurchargePercent" in value;
    isInstance = isInstance && "profileCategory" in value;
    isInstance = isInstance && "isUpSell" in value;
    isInstance = isInstance && "netCustomerAccount" in value;
    isInstance = isInstance && "numberOfMedia" in value;
    isInstance = isInstance && "additionalDescription" in value;
    isInstance = isInstance && "orderNumber" in value;
    isInstance = isInstance && "ownName" in value;
    isInstance = isInstance && "technologyDictionary" in value;
    isInstance = isInstance && "regionDictionary" in value;
    isInstance = isInstance && "formatRadio" in value;
    isInstance = isInstance && "formatPress" in value;
    isInstance = isInstance && "formatInternet" in value;
    isInstance = isInstance && "formatInternetST" in value;
    isInstance = isInstance && "emissionTypeRadio" in value;
    isInstance = isInstance && "emissionTypeCinema" in value;
    isInstance = isInstance && "purchaseFormInternet" in value;
    isInstance = isInstance && "purchaseFormInternetST" in value;
    isInstance = isInstance && "categoryInternet" in value;
    isInstance = isInstance && "categoryInternetST" in value;
    isInstance = isInstance && "categoryLabcon" in value;
    isInstance = isInstance && "copySize" in value;
    isInstance = isInstance && "purchaseMethod" in value;
    isInstance = isInstance && "medium" in value;
    isInstance = isInstance && "labconChannel" in value;
    isInstance = isInstance && "salesCommissionSum" in value;
    isInstance = isInstance && "service" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "actionGroup" in value;
    isInstance = isInstance && "serviceExternalWorkPercent" in value;
    isInstance = isInstance && "serviceCreationPercent" in value;
    isInstance = isInstance && "serviceChangeProductionPercent" in value;
    isInstance = isInstance && "serviceStrategyPercent" in value;
    isInstance = isInstance && "serviceProductionBroadcastPercent" in value;
    isInstance = isInstance && "serviceClientServicePercent" in value;
    isInstance = isInstance && "mpXlsx" in value;
    isInstance = isInstance && "mpAssigned" in value;
    isInstance = isInstance && "pmProject" in value;
    isInstance = isInstance && "attachmentOrder" in value;
    isInstance = isInstance && "internalOrder" in value;
    isInstance = isInstance && "fvz" in value;
    isInstance = isInstance && "fvs" in value;

    return isInstance;
}

export function GetEstimateItemDocumentControlDtoFromJSON(json: any): GetEstimateItemDocumentControlDto {
    return GetEstimateItemDocumentControlDtoFromJSONTyped(json, false);
}

export function GetEstimateItemDocumentControlDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEstimateItemDocumentControlDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'estimateByMonth': GetEstimateByMonthDtoFromJSON(json['estimateByMonth']),
        'startDate': (new Date(json['startDate'])),
        'endDate': (new Date(json['endDate'])),
        'comment': json['comment'],
        'importFileName': json['importFileName'],
        'salesInvoicesSum': json['salesInvoicesSum'],
        'purchaseInvoicesSum': json['purchaseInvoicesSum'],
        'position': GetPositionDtoFromJSON(json['position']),
        'positionType': json['positionType'],
        'rcSalesTotal': json['rcSalesTotal'],
        'salesSurchargePercent': json['salesSurchargePercent'],
        'salesDiscountPercent': json['salesDiscountPercent'],
        'salesNet': json['salesNet'],
        'salesCommissionPercent': json['salesCommissionPercent'],
        'salesCommissionAmount': json['salesCommissionAmount'],
        'salesNetTechnicalCost': json['salesNetTechnicalCost'],
        'salesNetTotal': json['salesNetTotal'],
        'rcPurchase': json['rcPurchase'],
        'purchaseTechnicalCost': json['purchaseTechnicalCost'],
        'purchaseDiscountPercent': json['purchaseDiscountPercent'],
        'purchaseNetTotal': json['purchaseNetTotal'],
        'purchaseNet': json['purchaseNet'],
        'purchaseSurchargePercent': json['purchaseSurchargePercent'],
        'profileCategory': GetProfileCategoryDtoFromJSON(json['profileCategory']),
        'isUpSell': json['isUpSell'],
        'netCustomerAccount': json['netCustomerAccount'],
        'numberOfMedia': json['numberOfMedia'],
        'additionalDescription': json['additionalDescription'],
        'orderNumber': json['orderNumber'],
        'ownName': json['ownName'],
        'technologyDictionary': GetDictionaryDtoFromJSON(json['technologyDictionary']),
        'regionDictionary': GetDictionaryDtoFromJSON(json['regionDictionary']),
        'formatRadio': GetDictionaryDtoFromJSON(json['formatRadio']),
        'formatPress': GetDictionaryDtoFromJSON(json['formatPress']),
        'formatInternet': GetDictionaryDtoFromJSON(json['formatInternet']),
        'formatInternetST': GetDictionaryDtoFromJSON(json['formatInternetST']),
        'emissionTypeRadio': GetDictionaryDtoFromJSON(json['emissionTypeRadio']),
        'emissionTypeCinema': GetDictionaryDtoFromJSON(json['emissionTypeCinema']),
        'purchaseFormInternet': GetDictionaryDtoFromJSON(json['purchaseFormInternet']),
        'purchaseFormInternetST': GetDictionaryDtoFromJSON(json['purchaseFormInternetST']),
        'categoryInternet': GetDictionaryDtoFromJSON(json['categoryInternet']),
        'categoryInternetST': GetDictionaryDtoFromJSON(json['categoryInternetST']),
        'categoryLabcon': GetDictionaryDtoFromJSON(json['categoryLabcon']),
        'copySize': GetDictionaryDtoFromJSON(json['copySize']),
        'purchaseMethod': GetDictionaryDtoFromJSON(json['purchaseMethod']),
        'medium': GetDictionaryDtoFromJSON(json['medium']),
        'labconChannel': GetDictionaryDtoFromJSON(json['labconChannel']),
        'salesCommissionSum': json['salesCommissionSum'],
        'service': json['service'],
        'action': json['action'],
        'actionGroup': json['actionGroup'],
        'serviceExternalWorkPercent': json['serviceExternalWorkPercent'],
        'serviceCreationPercent': json['serviceCreationPercent'],
        'serviceChangeProductionPercent': json['serviceChangeProductionPercent'],
        'serviceStrategyPercent': json['serviceStrategyPercent'],
        'serviceProductionBroadcastPercent': json['serviceProductionBroadcastPercent'],
        'serviceClientServicePercent': json['serviceClientServicePercent'],
        'clone': !exists(json, 'clone') ? undefined : json['clone'],
        'mpXlsx': json['mpXlsx'],
        'mpAssigned': json['mpAssigned'],
        'pmProject': json['pmProject'],
        'attachmentOrder': json['attachmentOrder'],
        'internalOrder': json['internalOrder'],
        'fvz': json['fvz'],
        'fvs': json['fvs'],
    };
}

export function GetEstimateItemDocumentControlDtoToJSON(value?: GetEstimateItemDocumentControlDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'estimateByMonth': GetEstimateByMonthDtoToJSON(value.estimateByMonth),
        'startDate': (value.startDate.toISOString()),
        'endDate': (value.endDate.toISOString()),
        'comment': value.comment,
        'importFileName': value.importFileName,
        'salesInvoicesSum': value.salesInvoicesSum,
        'purchaseInvoicesSum': value.purchaseInvoicesSum,
        'position': GetPositionDtoToJSON(value.position),
        'positionType': value.positionType,
        'rcSalesTotal': value.rcSalesTotal,
        'salesSurchargePercent': value.salesSurchargePercent,
        'salesDiscountPercent': value.salesDiscountPercent,
        'salesNet': value.salesNet,
        'salesCommissionPercent': value.salesCommissionPercent,
        'salesCommissionAmount': value.salesCommissionAmount,
        'salesNetTechnicalCost': value.salesNetTechnicalCost,
        'salesNetTotal': value.salesNetTotal,
        'rcPurchase': value.rcPurchase,
        'purchaseTechnicalCost': value.purchaseTechnicalCost,
        'purchaseDiscountPercent': value.purchaseDiscountPercent,
        'purchaseNetTotal': value.purchaseNetTotal,
        'purchaseNet': value.purchaseNet,
        'purchaseSurchargePercent': value.purchaseSurchargePercent,
        'profileCategory': GetProfileCategoryDtoToJSON(value.profileCategory),
        'isUpSell': value.isUpSell,
        'netCustomerAccount': value.netCustomerAccount,
        'numberOfMedia': value.numberOfMedia,
        'additionalDescription': value.additionalDescription,
        'orderNumber': value.orderNumber,
        'ownName': value.ownName,
        'technologyDictionary': GetDictionaryDtoToJSON(value.technologyDictionary),
        'regionDictionary': GetDictionaryDtoToJSON(value.regionDictionary),
        'formatRadio': GetDictionaryDtoToJSON(value.formatRadio),
        'formatPress': GetDictionaryDtoToJSON(value.formatPress),
        'formatInternet': GetDictionaryDtoToJSON(value.formatInternet),
        'formatInternetST': GetDictionaryDtoToJSON(value.formatInternetST),
        'emissionTypeRadio': GetDictionaryDtoToJSON(value.emissionTypeRadio),
        'emissionTypeCinema': GetDictionaryDtoToJSON(value.emissionTypeCinema),
        'purchaseFormInternet': GetDictionaryDtoToJSON(value.purchaseFormInternet),
        'purchaseFormInternetST': GetDictionaryDtoToJSON(value.purchaseFormInternetST),
        'categoryInternet': GetDictionaryDtoToJSON(value.categoryInternet),
        'categoryInternetST': GetDictionaryDtoToJSON(value.categoryInternetST),
        'categoryLabcon': GetDictionaryDtoToJSON(value.categoryLabcon),
        'copySize': GetDictionaryDtoToJSON(value.copySize),
        'purchaseMethod': GetDictionaryDtoToJSON(value.purchaseMethod),
        'medium': GetDictionaryDtoToJSON(value.medium),
        'labconChannel': GetDictionaryDtoToJSON(value.labconChannel),
        'salesCommissionSum': value.salesCommissionSum,
        'service': value.service,
        'action': value.action,
        'actionGroup': value.actionGroup,
        'serviceExternalWorkPercent': value.serviceExternalWorkPercent,
        'serviceCreationPercent': value.serviceCreationPercent,
        'serviceChangeProductionPercent': value.serviceChangeProductionPercent,
        'serviceStrategyPercent': value.serviceStrategyPercent,
        'serviceProductionBroadcastPercent': value.serviceProductionBroadcastPercent,
        'serviceClientServicePercent': value.serviceClientServicePercent,
        'clone': value.clone,
        'mpXlsx': value.mpXlsx,
        'mpAssigned': value.mpAssigned,
        'pmProject': value.pmProject,
        'attachmentOrder': value.attachmentOrder,
        'internalOrder': value.internalOrder,
        'fvz': value.fvz,
        'fvs': value.fvs,
    };
}

