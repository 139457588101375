import React, { useEffect, useState } from 'react';
import { Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useLocation } from '../../common/hooks/useLocation';

type TabHistoryProps = React.PropsWithChildren<{
  activeKey: string;
  id: string;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
}>;

export type TabLocationState = {
  tabKey?: string;
};

export const TabsHistory = (props: TabHistoryProps) => {
  const location = useLocation<TabLocationState>();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(
    location.state?.tabKey || props.activeKey,
  );

  useEffect(() => {
    setActiveTab(location.state?.tabKey || props.activeKey);
    if (props.setActiveTab) {
      props.setActiveTab(location.state?.tabKey || props.activeKey);
    }
  }, [location.state, props]);

  const handleTabChange = (tabKey: string | null) => {
    void navigate(`${location.pathname}${location.search || ''}`, {
      state: {
        tabKey: tabKey || '',
      },
    });
  };

  return (
    <Tabs
      activeKey={activeTab}
      id={props.id}
      className={props.className}
      onSelect={handleTabChange}
      mountOnEnter={props.mountOnEnter}
      unmountOnExit={props.unmountOnExit}
    >
      {props.children}
    </Tabs>
  );
};
