import React from 'react';
import type { TFunction } from 'i18next';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetPackageInvoicesXMLDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import {
  InvoiceTypeObj,
  PackageTypeEnum,
} from '../../../common/pokCore/validation/enums';
import { TKeys, useTranslation } from '../../../common/hooks/useTranslation';

import { DownloadXMLLink } from './DownloadXMLLink';
import { DownloadZIPLink } from './DownloadZIPLink';

interface PackagesInvoicesXMLSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  packageType: PackageTypeEnum;
  refresh?: boolean;
  onRowClick?: (key: string) => void;
}

export const packageInvoicesXMLForGrid = (
  t: TFunction<'fvzLng', 'fvzLng'>,
  tk: TKeys<'fvzLng'>,
  packageXML: GetPackageInvoicesXMLDto,
) => {
  return {
    key: packageXML.id,
    values: {
      'packageXML.systemNumber': packageXML.systemNumber,
      'packageXML.creator': packageXML.creator.name,
      'packageXML.creationTs': momencik(packageXML.creationTs, 'LLL'),
      'packageXML.count':
        packageXML.packageType === PackageTypeEnum.Purchase
          ? packageXML.purchaseInvoices?.length
          : packageXML.saleInvoices?.length,
      'packageXML.isRepeat': packageXML.isRepeat
        ? t(tk.common.yes)
        : t(tk.common.no),
      'packageXML.invoiceType': packageXML.invoiceType
        ? t(InvoiceTypeObj[packageXML.invoiceType])
        : '',
      preview: <DownloadXMLLink packageXMLId={packageXML.id} />,
      zip: <DownloadZIPLink packageXMLId={packageXML.id} />,
      zipCopy: <DownloadZIPLink packageXMLId={packageXML.id} copy={true} />,
    },
  };
};

export const PackagesInvoicesXMLSearch: React.FC<
  PackagesInvoicesXMLSearchProps
> = props => {
  const { t, tk } = useTranslation('fvzLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.sysNumber), property: 'packageXML.systemNumber' },
    { header: t(tk.finance.generatedBy), property: 'packageXML.creator' },
    { header: t(tk.finance.generatedDate), property: 'packageXML.creationTs' },
    { header: t(tk.finance.invoicesType), property: 'packageXML.invoiceType' },
    ...(props.packageType === PackageTypeEnum.Purchase
      ? [{ header: t(tk.finance.repeat), property: 'packageXML.isRepeat' }]
      : []),
    {
      header: t(tk.finance.packageCount),
      property: 'packageXML.count',
      noSorting: true,
    },
    {
      header: t(tk.finance.downloadPackage),
      property: 'preview',
      noSorting: true,
    },
    {
      header: t(tk.finance.downloadPdfs),
      property: 'zip',
      noSorting: true,
    },
    ...(props.packageType === PackageTypeEnum.Sale
      ? [
          {
            header: t(tk.finance.downloadCopies),
            property: 'zipCopy',
            noSorting: true,
          },
        ]
      : []),
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="packageXML.creationTs"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.finance.noPackages)}
      refresh={props.refresh}
      withHistory={true}
      onRowClick={props.onRowClick}
    />
  );
};
