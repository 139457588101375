import React, { useState } from 'react';
import { Button, Form, Card } from 'react-bootstrap';
import * as Icons from 'react-bootstrap-icons';

import DynamicTextarea from '../../../app/components/DynamicTextarea';
import FormRow from '../../../app/components/FormRow';
import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreatePrivateMemoDto,
  CreatePrivateMemoDtoFromJSON,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import EmployeeSelector from '../Selects/EmployeeSelector';
import * as MemoContext from '../../../common/pokCore/contexts/MemoContext';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Option } from '../Selects/Selector';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const NewPrivateMemo = (props: {
  onSend: (memo: CreatePrivateMemoDto) => void;
}) => {
  const [memo, setMemo] = useState<CreatePrivateMemoDto>(
    CreatePrivateMemoDtoFromJSON({}),
  );
  const notifications = useNotifications();
  const { t, tk } = useTranslation('commonLng');
  const [content, setContent] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const validateAndSend = () => {
    MemoContext.validatePrivateMemo(memo)
      .then(status => {
        if (status.valid) {
          props.onSend(memo);
          return;
        }

        setShowErrors(true);
        setErrors(status.errors);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <Card className="flex-fill">
      <Card.Header>{t(tk.memo.createNewMessage)}</Card.Header>
      <Card.Body>
        <div className="d-flex flex-column flex-fill">
          <Form className="d-grid gap-3 mb-3">
            <FormRow controlId="employee" label={t(tk.memo.to)}>
              <EmployeeSelector
                //TODO: sprawdzic unrestricted
                value={memo.recipientId || ''}
                onChange={e =>
                  e && setMemo({ ...memo, recipientId: (e as Option).value })
                }
              />
            </FormRow>
            <FormRow controlId="memo" label={t(tk.memo.content)}>
              <DynamicTextarea
                placeholder={t(tk.memo.giveContent)}
                className="max-rows-5"
                minRows={3}
                onChange={e => setContent(e.target.value)}
                onBlur={() => setMemo({ ...memo, content })}
              />
            </FormRow>
          </Form>

          <ValidationAlert show={showErrors} errors={errors} />

          <div className="mt-3 d-flex justify-content-end">
            <Button variant="outline-primary" onClick={validateAndSend}>
              <Icons.SendFill />
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
