import saveAs from 'file-saver';

import { ApiResponse, DefaultApi } from '../autogenerated/pokApiClient';
import { LanguageType } from '../validation/enums';

export interface PdfPrinterContextInterface {
  downloadInvoice: (
    id: string,
    duplicate: boolean,
    copy: boolean,
  ) => Promise<void>;
  openInvoice: (id: string, duplicate: boolean, copy: boolean) => Promise<void>;
  invoiceDataPrintout: (id: string) => Promise<void>;
  getBlobInvoice: (id: string) => Promise<Blob>;
  downloadOrderExecution: (id: string, language: LanguageType) => Promise<void>;
  getZipOrderExecutionsByProject: (projectId: string) => Promise<object>;
}

export const PdfPrinterContext = (api: DefaultApi) => {
  const openFile = async (response: ApiResponse<void>) => {
    const file = await response.raw.blob();
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const getBlob = async (response: ApiResponse<void>) => {
    return response.raw.blob();
  };

  const downloadFile = async (response: ApiResponse<void>, name: string) => {
    const filename =
      response.raw.headers
        .get('Content-Disposition')
        ?.split('filename=')[1]
        ?.replaceAll('"', '') || `${name}.pdf`;
    const file = await response.raw.blob();
    saveAs(file, filename);
  };

  return {
    downloadInvoice: async (id: string, duplicate: boolean, copy: boolean) =>
      api
        .pdfPrinterControllerPrintInvoiceRaw({ id, duplicate, copy })
        .then(response => downloadFile(response, 'faktura')),
    openInvoice: async (id: string, duplicate: boolean, copy: boolean) =>
      api
        .pdfPrinterControllerPrintInvoiceRaw({ id, duplicate, copy })
        .then(openFile),
    invoiceDataPrintout: async (id: string) =>
      api.pdfPrinterControllerInvoiceDataPrintoutRaw({ id }).then(openFile),
    getBlobInvoice: async (id: string) =>
      api
        .pdfPrinterControllerPrintInvoiceRaw({
          id,
          duplicate: false,
          copy: false,
        })
        .then(getBlob),
    downloadOrderExecution: async (id: string, language: LanguageType) =>
      api
        .pdfPrinterControllerPrintOrderExecutionRaw({ id, language })
        .then(response => downloadFile(response, 'realizacja_zamowienia')),
    getZipOrderExecutionsByProject: (projectId: string) =>
      api.pdfPrinterControllerGetZipOrderExecutionsByProject(projectId),
  };
};
