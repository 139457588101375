import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Spinner,
} from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import ValidationAlert from '../../../app/components/ValidationAlert';
import 'react-calendar/dist/Calendar.css';
import '../../../theme/calendar.css';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { NewCalendar } from '../../../app/components/NewCalendar';

interface CloneProfileFormProps {
  estimateByMonthId: string;
  clone: (
    id: string,
    date: Date,
    withAmounts: boolean,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const CloneProfileForm: React.FC<CloneProfileFormProps> = props => {
  const [show, setShow] = useState(false);
  const [month, setMonth] = useState<Date | null>();
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const notifications = useNotifications();
  const { t, tk } = useTranslation('projectLng');

  useEffect(() => {
    if (show) {
      setShowSaveErrors(false);
      setSaveErrors([]);
    }
  }, [show]);

  const cloneProfile = async (withAmounts: boolean) => {
    if (!month) {
      setShowSaveErrors(true);
      setSaveErrors([t(tk.estimates.selectMonth)]);
      return;
    }
    setIsLoading(true);
    props
      .clone(props.estimateByMonthId, month!, withAmounts)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Button
        className="mx-1"
        variant="outline-primary"
        onClick={() => setShow(true)}
      >
        {t(tk.estimates.cloneEstimateByMonth)}
      </Button>
      <Modal
        onHide={handleClose}
        show={show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(tk.estimates.cloneSelectedEstimateByMonth)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-4 mb-3">
            <FormRow controlId="month" label={t(tk.common.selectMonth)}>
              <div className="small-calendar">
                <NewCalendar onChange={setMonth} />
              </div>
            </FormRow>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            {t(tk.button.close)}
          </Button>
          <DropdownButton
            title={
              <>
                {isLoading && <Spinner size={'sm'} className="me-1" />}
                {t(tk.estimates.clone)}
              </>
            }
            id="clone-dropdown"
            variant="outline-primary"
            className="mx-1 d-inline-block"
            disabled={isLoading}
          >
            <Dropdown.Item onClick={() => cloneProfile(false)} eventKey="1">
              {t(tk.estimates.withoutAmounts)}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => cloneProfile(true)} eventKey="2">
              {t(tk.estimates.withAmounts)}
            </Dropdown.Item>
          </DropdownButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};
