import { MemoSubjectType } from '../validation/enums';

export interface User {
  username: string;
  sub: string;
}

export enum DataChangeScope {
  purchaseInvoice = 'purchaseInvoice',
}

export interface DataKey {
  scope: DataChangeScope;
  id: string;
}

export const keyId = (key: DataKey) => `${key.scope}/${key.id}`;

export interface KnownClient {
  id: string;
  user: User;
  currentObjects: Map<string, number>;
  memosEmployeeId?: string;
}

export enum Message {
  concurrency = 'concurrency',
  objectInEditMode = 'objectInEditMode',
  objectOutEditMode = 'objectOutEditMode',
  dataChanged = 'dataChanged',
  subscribeMemos = 'subscribeMemos',
  unsubscribeMemos = 'unsubscribeMemos',
  threadChanged = 'threadChanged',
}

export interface ConcurrencyArgs {
  key: DataKey;
  user: User;
}

export interface DataChangedArgs {
  key: DataKey;
  user: User;
}

export interface ClientToServerArgs {
  token: string;
}

export interface ObjectEditModeArgs extends ClientToServerArgs {
  key: DataKey;
}

export interface ThreadChangedArgs {
  threadId: string;
  subjectId?: string;
  subjectType?: MemoSubjectType;
}

export type SubscribeMemosArgs = ClientToServerArgs;
