import React from 'react';

import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';

import { isFieldHidden } from './CommissionService';
import ProfileFieldName from './ProfileFieldName';

interface LabconProfileParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  lang: string;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const LabconProfileParams: React.FC<
  LabconProfileParamsProps
> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    if (props.propertyChange) {
      props.propertyChange(obj);
    }
  };

  return (
    <>
      {schema?.labconChannel &&
        !isFieldHidden(
          'labconChannel',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <ProfileFieldName
            controlId="labconChannel"
            schema={schema?.labconChannel}
            lang={props.lang}
          >
            <DictionarySelector
              dictionary="Labcon - kanał"
              value={props.estimateItem?.labconChannelId || ''}
              clearable={true}
              onChange={option =>
                propertyChange({
                  labconChannelId: option.value,
                })
              }
            />
          </ProfileFieldName>
        )}
      {schema?.categoryLabcon &&
        !isFieldHidden(
          'categoryLabcon',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <ProfileFieldName
            controlId="categoryLabcon"
            schema={schema?.categoryLabcon}
            lang={props.lang}
          >
            <DictionarySelector
              dictionary="Labcon - kategoria"
              value={props.estimateItem?.categoryLabconId || ''}
              clearable={true}
              onChange={option =>
                propertyChange({
                  categoryLabconId: option.value,
                })
              }
            />
          </ProfileFieldName>
        )}
    </>
  );
};
