import type { TFunction } from 'i18next';

import { validateAgainst } from '../validation/validateAgainst';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import {
  CreateUpdateClientDto,
  CreateUpdateClientDtoFromJSON,
  DefaultApi,
  GetClientDto,
  GetClientExtendedDto,
  GetLastEditorDto,
  GusClientDto,
} from '../autogenerated/pokApiClient';
import { ClientSchema } from '../validation/schemas';
import { clientForGrid } from '../../../pok/components/Clients/ClientSearch';
import {
  Option,
  defaultSelectorLimit,
} from '../../../pok/components/Selects/Selector';
import { clientWithInvoiceNumbersForGrid } from '../../../pok/components/Clients/ClientWithoutSymphonySearch';
import { FilterColumn } from '../../types/FilterColumn';
import { FilterInvoiceTypeEnum } from '../validation/enums';
import { TKeys } from '../../hooks/useTranslation';

export type Client = GetClientDto | CreateUpdateClientDto;

export const newClient = () => {
  return CreateUpdateClientDtoFromJSON({
    country: 'Polska',
  });
};

export interface ClientContextInterface {
  getById: (id: string) => Promise<GetClientDto>;
  getByIdFormattedAddress: (id: string) => Promise<GetClientExtendedDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllWithSymphonyByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllWithSymphonyCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllWithoutSymphonyByPortion: (
    invoiceType: FilterInvoiceTypeEnum,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllWithoutSymphonyCount: (
    invoiceType: FilterInvoiceTypeEnum,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  create: (dto: CreateUpdateClientDto) => Promise<GetClientDto>;
  update: (id: string, dto: CreateUpdateClientDto) => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  getByNip: (nip: string) => Promise<GusClientDto>;
  getOptionsByNipPeselOrName: (
    t: TFunction<'clientLng', 'clientLng'>,
    tk: TKeys<'clientLng'>,
    nipPeselOrName: string,
  ) => Promise<Option[]>;
}

export const ClientContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.clientControllerGet(id),

    getByIdFormattedAddress: (id: string) =>
      api.clientControllerFormattedAddress(id),

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      additionalId?: string,
    ) => {
      let data = await api.clientControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );
      if (additionalId) {
        data = data.map(o => {
          return { ...o, headId: additionalId };
        });
      }

      return data.map(clientForGrid);
    },

    getAllCount: (filterText?: string, filterColumns?: FilterColumn) =>
      api.clientControllerGetAllCount(filterText || '', filterColumns),

    getAllWithSymphonyByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      additionalId?: string,
    ) => {
      let data = await api.clientControllerGetAllWithSymphonyByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );
      if (additionalId) {
        data = data.map(o => {
          return { ...o, headId: additionalId };
        });
      }

      return data.map(clientForGrid);
    },

    getAllWithSymphonyCount: (
      filterText?: string,
      filterColumns?: FilterColumn,
    ) =>
      api.clientControllerGetAllWithSymphonyCount(
        filterText || '',
        filterColumns,
      ),

    getAllWithoutSymphonyByPortion: async (
      invoiceType: FilterInvoiceTypeEnum,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      additionalId?: string,
    ) => {
      let data = await api.clientControllerGetAllWithoutSymphonyByPortion(
        invoiceType,
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );
      if (additionalId) {
        data = data.map(o => {
          return { ...o, headId: additionalId };
        });
      }

      return data.map(clientWithInvoiceNumbersForGrid);
    },

    getAllWithoutSymphonyCount: (
      invoiceType: FilterInvoiceTypeEnum,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) =>
      api.clientControllerGetAllWithoutSymphonyCount(
        invoiceType,
        filterText || '',
        filterColumns,
      ),

    create: (client: CreateUpdateClientDto) =>
      api.clientControllerCreate(client),

    update: (id: string, dto: CreateUpdateClientDto) =>
      api.clientControllerUpdate(id, dto),

    getLastEditor: (id: string) => api.clientControllerGetLastEditor(id),

    getByNip: (nip: string) => api.gusApiControllerGetByNip(nip),

    getOptionsByNipPeselOrName: async (
      t: TFunction<'clientLng', 'clientLng'>,
      tk: TKeys<'clientLng'>,
      text: string,
    ) => {
      const data = await api.clientControllerGetByNipPeselOrName(
        text,
        defaultSelectorLimit,
      );

      return Array.from(data, client => {
        return {
          value: client.id,
          label:
            client.name +
            ' ' +
            (client.nip
              ? `(${t(tk.client.nip)}: ` + client.nip + ') '
              : client.pesel
                ? `(${t(tk.client.pesel)}: ` + client.pesel + ') '
                : ''),
        };
      });
    },
  };
};

export const validate = (client: CreateUpdateClientDto) =>
  validateAgainst(ClientSchema, client);

// export const partial = (property: keyof Client, value: string) => {
//   const obj: Partial<Client> = {};
//   obj[property] = value;
//   return obj;
// };

export const convert = (client?: GetClientDto) => {
  if (!client) {
    return newClient();
  }
  const converted = CreateUpdateClientDtoFromJSON(client);
  converted.countryId = client.country.id;
  converted.countryIsMain = client.country.isMain;
  return converted;
};
