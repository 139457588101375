import React, { useMemo } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { GridGetterFunction } from '../../../common/components/Grid/GridDataTypes';

import { OrderExecutionSearch } from './OrderExecutionSearch';

export const OrdersExecutionCompletedTab: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const { t, tk } = useTranslation('orderLng');

  const getData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.ordersExecution.getAllByPortionCompleted(
          t,
          tk,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok.ordersExecution, t, tk],
  );

  return (
    <OrderExecutionSearch
      getData={getData}
      getCount={pok.ordersExecution.getAllCountCompleted}
      onRowClick={nav.orderExecutionEditor}
    />
  );
};
