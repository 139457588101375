import React from 'react';
import Select from 'react-select';
import moment from 'moment';

import FormRow from '../../../app/components/FormRow';
import { CurrencyIsoTypeEnum } from '../../../common/pokCore/validation/enums';
import { CreateUpdateSalesInvoiceDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  getCurrencyOptions,
  getMainCurrencySymbol,
  isMainCurrencyPLN,
} from '../../../utils/currencyFormatUtils';
import { useTranslation } from '../../../common/hooks/useTranslation';

import ExchangeRateControl from './ExchangeRateControl';

interface SalesInvoiceBasicDataEditorProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdateSalesInvoiceDto>) => void;
  readOnly?: boolean;
}

export const SalesInvoiceCurrencyForm: React.FC<
  SalesInvoiceBasicDataEditorProps
> = ({ salesInvoice, propertyChange, readOnly }) => {
  const { t, tk } = useTranslation('fvsLng');
  const mainCurrency = getMainCurrencySymbol();
  return (
    <>
      <FormRow controlId="currency" label={t(tk.common.currency)}>
        <Select
          value={
            salesInvoice?.currency
              ? getCurrencyOptions().find(
                  ({ value }) => salesInvoice?.currency === value,
                )
              : null
          }
          options={getCurrencyOptions()}
          onChange={option =>
            propertyChange({
              currency: option?.value,
              ...(option?.value === mainCurrency
                ? { exchangeRate: null, exchangeRateDescription: null }
                : {}),
            })
          }
          isDisabled={readOnly}
        />
      </FormRow>
      {salesInvoice.currency !== mainCurrency && isMainCurrencyPLN() && (
        <FormRow controlId="exchangeRate" label={t(tk.common.exchangeRate)}>
          <ExchangeRateControl
            date={
              moment(salesInvoice.invoiceDate).isBefore(
                moment(salesInvoice.saleDate),
              )
                ? salesInvoice.invoiceDate
                : salesInvoice.saleDate
            }
            rate={salesInvoice.exchangeRate}
            description={salesInvoice.exchangeRateDescription}
            currency={salesInvoice.currency as CurrencyIsoTypeEnum}
            onChange={(exchangeRate, exchangeRateDescription) =>
              propertyChange({ exchangeRate, exchangeRateDescription })
            }
            readOnly={readOnly}
          />
        </FormRow>
      )}
    </>
  );
};
