import React, { useState, useEffect, useRef } from 'react';
import { Modal, Toast, ToastContainer, Spinner } from 'react-bootstrap';

import { useNotifications } from '../../common/hooks/useNotifications';
import { usePokCore } from '../../common/hooks/usePokCore';
import { useTranslation } from '../../common/hooks/useTranslation';

export type StatusType = 'online' | 'offline';
type HangingPromise = () => void;

export default () => {
  const notifications = useNotifications();
  const pok = usePokCore();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [autohide, setAutohide] = useState(false);
  const [connectionText, setConnectionText] = useState<string>();
  const hangingPromisesRef = useRef<HangingPromise[]>([]);
  const onlineRef = useRef(true);
  const { t, tk } = useTranslation('menuLng');

  useEffect(() => {
    function onlineHandler() {
      setConnectionText(t(tk.system.connectionRestored));
      setAutohide(true);
      setShowModal(false);
    }

    function offlineHandler() {
      setConnectionText(t(tk.system.problemsWithConnection));
      setAutohide(false);
      setShow(true);
      setShowModal(true);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, [t, tk.system]);

  useEffect(() => {
    notifications.online = () => {
      setConnectionText(t(tk.system.connectionRestored));
      setAutohide(true);
      setShow(true);
      setShowModal(true);
      onlineRef.current = true;

      if (hangingPromisesRef.current.length > 0) {
        setShowModal(false);

        for (const hangingResolve of hangingPromisesRef.current) {
          hangingResolve();
        }
        hangingPromisesRef.current = [];
      }
    };

    notifications.offline = () => {
      setConnectionText(t(tk.system.problemsWithConnection));
      setAutohide(false);
      setShow(true);
      setShowModal(true);
      onlineRef.current = false;
    };
  }, [notifications, pok, t, tk.system]);

  return (
    <>
      {show && (
        <ToastContainer className="p-3" position="bottom-start">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            autohide={autohide}
            delay={5000}
            bg="light"
          >
            <Toast.Body className="text-center">{connectionText}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      <Modal
        centered={true}
        backdrop="static"
        show={showModal}
        className="modal-on-modal"
        backdropClassName="modal-on-modal-backdrop"
      >
        <Modal.Header>
          <Modal.Title>{t(tk.system.waitingForConnection)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="info" />
        </Modal.Body>
      </Modal>
    </>
  );
};
