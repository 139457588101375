export default {
  prLang: {
    importExcel: 'Import campaigns from spreadsheets',
    projects: 'Campaigns',
    projectsAndTeams: 'Campaigns and teams',
    projectsApplications: 'Campaign requests',
    delayedProjects: 'Overdue campaigns',
    canceledProjects: 'Campaigns to be canceled',
    project: 'Campaign',
    genitiveProject: 'of the campaign',
    genitiveProjectWithoutSpace: 'of_the_campaign',
    accusativeProject: 'the campaign',
    genitiveProjectPlural: 'of the campaigns',
    projectNumber: 'Campaign number',
    internalProject: 'Commissioning campaign',
    projectList: 'Campaign list',
    withProjects: 'with campaigns',
    connectionsWithProjectsFVZ:
      'Connections with campaigns will be explained by teams',
    estimate: 'Mediaplan',
    estimate2: 'of the mediaplan',
    estimates: 'Mediaplans',
    addNewProject: 'Add a new campaign',
    newProjectName: 'New campaign name',
    chooseProject: 'Select campaign',
    sourceProject: 'Source campaign',
    sourceProject2: 'source campaign',
    addProject: 'Add campaign',
    editProject: 'Edit campaign',
    emptyProjectsList: 'No campaigns in the system.',
    projectNotFound: 'No campaign found with such ID.',
    salesInvoiceToProject: 'to the campaign',
    estimateItemPosition: 'Mediaplan position',
    addEstimateItemPosition: 'Add mediaplan position',
    chooseEstimateItem: 'Select mediaplan position',
    estimateItemInvoiceModifyWarning:
      'Invoice items are already saved. Are you sure you want to update the mediaplan position?',
    estimateItemInvoiceTabTitle: 'Mediaplan position',
    deleteProjectWarning:
      'Are you sure you want to delete the selected campaign?',
    deleteProjectTooltip: 'Delete campaign',
    unsettledPurchaseInvoicesAssignedToProject:
      'Unsettled purchase invoices assigned to campaigns',
    purchaseInvoicesAssignedToProject:
      'Purchase invoices assigned to campaigns',
    orderResultProject: 'Subcontracted campaign',
    readyForInvoicing: 'Campaigns ready for invoicing',
    readyForCorrection: 'Campaigns with negative invoicing amount',
    applicationUnblocks: 'Requests to unblock mediaplan',
    projectsListModifiedInvoiceAlert:
      'The campaign list has been modified. After saving, mediaplan positions and invoice positions will be reset.',
    selectProjectsPurchaseInvoiceAlert:
      'To add multiple campaigns, select them by clicking on the row, selected campaigns will turn yellow. After saving changes, the selected campaigns (one or more) will be added',
    selectProjectPurchaseInvoiceAlertError:
      'Please select one or more campaigns.',
    sameProject: 'of the same campaign',
    projectLabel: 'Campaign label',
    projectControl: 'Campaign control',
    estimateMonth: 'Mediaplan month',
    monthRangeEstimates: 'Mediaplan month range',
    teamRelatedEstimates: 'Mediaplan positions related to the team',
    forProject: 'for campaign',
    projectPurchase: 'Campaign purchase',
    estimatePositionsSum: 'Mediaplan positions sum',
    importProjectsFromFile: 'Import campaigns from file',
    projectNo: 'Project sys. no.',
    printProjectNumber: 'Print campaign number',
    saleAndCommissionSum: 'Total sales and commission cost of mediaplan',
    fromProject: 'from campaign',
    projectClient: 'Campaign contractor',
    noProjects: 'No campaigns.',
    errCreateProjectSendApplication:
      'Creating a campaign in the selected period is blocked, send an request to create the campaign.',
    parentProjectNumber: 'Parent campaign number',
    askDownloadFVZ:
      'Download purchase invoices (FVZ) attached to the campaign?',
    askDownloadFVZDespiteWarnings:
      'Download purchase invoices (FVZ) attached to the campaign despite the following warnings?',
    paePositionsList:
      'PAE position list for campaign {{projectNo}} for {{date}}',
    maxDelay: 'Max. campaign delay',
    thisProject: 'this campaign',
    withoutTeamsProjects: 'Without teams and campaigns',
    settingUpProjects: 'Setting up campaigns and completing requests',
    importFromProjectToProject: 'Importing items from a campaign to a campaign',
    projectOrders: 'Campaign orders',
    importProjects: 'Import campaigns from sheets',
    warningPurchaseAndSalesAmountDiff:
      'The total purchase amount from the commissioning campaign differs from the sales amount.',
    projectsByMonths: 'Campaigns by months',
    positionsProjects: 'Campaign positions',
    reportToBudgetByMonth: 'Budget Report - Monthly by Campaign',
    pbProject: 'PB campaign',
  },
} as const;
