export default {
  menu: {
    menu: 'Menu',
    applications: 'Requests',
    specialInvoicingApplication: 'Special Invoicing Requests',
    purchaseInvoicesToBeClarified: 'Purchase Invoices to be Clarified',
    purchaseInvoicesTv: 'TV Purchase Invoices',
    estimateItemPurchaseDeficiencies: 'Clarifications of Purchase Deficiencies',
    orders: 'Orders',
    ordersExecutions: 'Order Executions',
    finance: 'Finance',
    salesInvoices: 'Sales Invoices',
    salesInvoicesReadyToCorrection: 'Sales Invoices Ready for Correction',
    purchaseInvoices: 'Purchase Invoices',
    verifications: 'Verifications',
    imports: 'Imports',
    specialInvoicingDecision: 'Special Invoicing Decisions',
    blockade: 'Blockades',
    exportFVZToXml: 'Export purchase invoices (FVZ) to XML',
    exportFVSToXml: 'Export sales invoices (FVS) to XML',
    exportFVSToSymphony: 'Export sales invoices (FVS) to Symfonia Handel',
    clientsWithSymphony: 'Contractors - Symphony',
    reportsFinance: 'Financial Reports',
    reportsFinanceAdministration: 'Finance - Administration',
    bankAccounts: 'Bank Accounts',
    clients: 'Contractors',
    clientsList: 'Contractors List',
    purchasers: 'Purchasers',
    positions: 'Profile Positions',
    budgets: 'Budgets',
    brands: 'Brands',
    administrations: 'Administration',
    companies: 'Companies',
    employees: 'Employees',
    teams: 'Teams',
    profiles: 'Mediaplan profiles',
    profileCategories: 'Profile categories',
    dictionaries: 'Dictionaries',
    calendar: 'Calendar',
    roles: 'Roles',
    employeesRoles: 'Permissions',
    emailConfigs: 'Email Configurations',
    jobConfig: 'Scheduled Task Configurations',
    excelConfig: 'Import Configuration',
    reports: 'Reports',
    help: 'Help',
    instructions: 'User Manuals',
    procedures: 'Procedures',
    documentation: 'Documentation',
    logout: 'Logout',
  },
  docs: {
    addingPurchaseInvoices: 'Adding and accepting purchase invoices',
    settlementWithholdingTax: 'Settlement of withholding tax',
    orderFulfillment: 'Order fulfillment (specialists)',
    acceptanceInvoices: 'Acceptance of invoices',
  },
  system: {
    noPermissions: 'Lack of required permissions',
    unknownError: 'Unknown error',
    cannotBePerformed: 'This operation cannot be performed',
    increaseRights: 'To elevate permissions, please contact administration.',
    badCompany: 'Incompatible company',
    badCompanyLongText:
      'The company you are currently working in cannot handle the selected object.',
    rightCompany: 'The object is managed by the company:',
    waitingForConnection: 'Waiting for connection to be restored...',
    problemsWithConnection: '⛈️ Connection issues - data may not be saved!',
    connectionRestored: '☀️ Connection restored',
    cannotLoad: 'The page cannot be loaded',
    writingError: 'Error during saving.',
    savedChanges: 'Changes have been saved.',
    copyClipboardError:
      'Something went wrong while copying text to the clipboard.',
    copyClipboard: 'Copy to clipboard',
    ctrlV: 'Copied to clipboard. Paste using Ctrl+V.',
    serverNotResponse: 'Server is not responding...',
    login: 'LOGIN',
    loginError: 'Login error: ',
  },
} as const;
