import React from 'react';
import { groupBy, mapValues, sortBy } from 'lodash';
import moment from 'moment';
import { Table } from 'react-bootstrap';

import { GetEstimateItemDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import numberFormatter from '../../../common/numberFormatter';
import mathUtils from '../../../utils/mathUtils';
import { CurrencyIsoTypeEnum } from '../../../common/pokCore/validation/enums';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface OrderEstimateItemsGridMonthsSumProps {
  data: GetEstimateItemDto[];
  currencyCode?: string;
}

const getItemsToMonthSummary = (data: GetEstimateItemDto[]) =>
  sortBy(
    Object.entries(
      mapValues(
        groupBy(data, estimateItem =>
          moment(estimateItem.estimateByMonth.date).format('YYYY-MM'),
        ),
        items =>
          mathUtils.add(
            ...items.map(({ purchaseNetTotal }) =>
              mathUtils.round(purchaseNetTotal),
            ),
          ),
      ),
    ).map(([date, amount]) => ({
      date,
      amount,
    })),
    item => -moment(item.date, 'YYYY-MM').valueOf(),
  );

export const OrderEstimateItemsGridMonthsSum: React.FC<
  OrderEstimateItemsGridMonthsSumProps
> = props => {
  const summaryData = getItemsToMonthSummary(props.data);
  const { t, tk } = useTranslation('orderLng');

  if (summaryData.length <= 1) {
    return null;
  }

  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th>{t(tk.common.month)}</th>
          <th>{t(tk.common.sumUpperCase)}</th>
        </tr>
      </thead>
      <tbody>
        {summaryData.map(({ date, amount }, index) => (
          <tr key={index}>
            <th>{date}</th>
            <th>
              {numberFormatter(
                amount,
                true,
                (props.currencyCode as CurrencyIsoTypeEnum) || undefined,
              )}
            </th>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
