import { CurrencyIsoTypeEnum } from '../common/pokCore/validation/enums';

export class Currency {
  currencyCode?: string;
  countryCode?: string;
}

export const getMainCurrencySymbol = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return getMainCurrency()?.currencyCode as any;
};

export const getMainCurrency = (): Currency => {
  const currencyItem = sessionStorage.getItem('pok-currency');
  if (!currencyItem) {
    return {
      countryCode: undefined,
      currencyCode: undefined,
    };
  }
  const currency = JSON.parse(currencyItem);
  return {
    countryCode: currency.countryCode,
    currencyCode: currency.currencyCode,
  };
};

export const isMainCurrencyPLN = () => {
  return getMainCurrency()?.currencyCode === CurrencyIsoTypeEnum.PLN;
};

export const getCurrencyOptions = () => {
  const curr = getMainCurrencySymbol();
  if (curr === CurrencyIsoTypeEnum.PLN) {
    return Object.entries(CurrencyIsoTypeEnum).map(([key, value]) => ({
      value: key as CurrencyIsoTypeEnum,
      label: value,
    }));
  } else {
    return [{ value: curr, label: curr }];
  }
};

export const getCurrencySymbol = (currencyCode: string, locale: string) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  });
  const parts = formatter.formatToParts(0);
  const currencySymbol =
    parts.find(part => part.type === 'currency')?.value ?? '';

  return currencySymbol;
};

export const isCurrencySymbolBeforeAmount = (
  currencyCode: string,
  locale: string,
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });
  const parts = formatter.formatToParts(1);
  const currencyIndex = parts.findIndex(part => part.type === 'currency');
  const integerIndex = parts.findIndex(part => part.type === 'integer');
  return currencyIndex < integerIndex;
};

export const getThousandSeparator = (locale: string) => {
  const formatter = new Intl.NumberFormat(locale);
  const parts = formatter.formatToParts(1000);
  const thousandSeparator =
    parts.find(part => part.type === 'group')?.value ?? ' ';
  return thousandSeparator;
};

export const getDecimalSeparator = (locale: string) => {
  const formatter = new Intl.NumberFormat(locale);
  const parts = formatter.formatToParts(1.1);
  const decimalSeparator =
    parts.find(part => part.type === 'decimal')?.value ?? ',';
  return decimalSeparator;
};

export default {
  getCurrencySymbol,
  isCurrencySymbolBeforeAmount,
  getThousandSeparator,
  getDecimalSeparator,
  getCurrencyOptions,
};
