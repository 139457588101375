import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import saveAs from 'file-saver';

import {
  CreateUpdateAttachmentDto,
  GetAttachmentDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AddAttachment } from '../Attachments/AddAttachment';
import { Attachment } from '../Attachments/Attachment';
import { AttachmentsGrid } from '../Attachments/AttachmentsGrid';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { forAssociatedDocuments } from '../../../common/pokCore/contexts/AttachmentContext';
import { forProjectView } from '../../../common/pokCore/contexts/ProjectContext';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { usePokCore } from '../../../common/hooks/usePokCore';
import momencik from '../../../common/momencik';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ProjectAttachmentsTabProps {
  project?: GetProjectDto;
  downloadDocuments: boolean;
  projectNumber: string;
  projectName: string;
}

export const ProjectAttachmentsTab: React.FC<
  ProjectAttachmentsTabProps
> = props => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('projectLng');

  const [attachments, setAttachments] = useState<
    AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>
  >(forAssociatedDocuments(pok, notifications, []));

  const refreshFunc = useCallback(() => {
    if (props.project) {
      forProjectView(
        pok,
        notifications,
        props.project.id,
        props.project.purchaser.budget.id,
        props.project.company.id,
        refreshFunc,
      )
        .then(model => {
          setAttachments(model.attachments);
        })
        .catch(err => {
          notifications.caughtError(err);
        });
    }
  }, [notifications, pok, props.project]);

  useEffect(() => {
    refreshFunc();
  }, [refreshFunc]);

  const handleClick = async () => {
    if (attachments && attachments.documents?.length > 0) {
      pok.attachments
        .getZipByAttachmentIds(
          props.projectNumber,
          attachments.documents.map(o => o.id),
        )
        .then(async zip => {
          const fileName =
            `${props.projectNumber}_(${props.projectName})_${momencik(
              new Date(),
              'YYYY-MM-DD_HH-mm-ss',
            )}.zip`.replace(/[ /]/g, '_');

          if (zip) {
            const blob = new Blob([Buffer.from(zip as Buffer)], {
              type: 'application/zip',
            });
            saveAs(blob, fileName);
          }
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  };

  return (
    <div className="pt-4">
      <AssociatedDocuments
        name={t(tk.projects.attachments)}
        type="projects"
        createModalType={attachment =>
          AddAttachment(attachment, props.project?.id)
        }
        presentModalType={Attachment}
        gridType={AttachmentsGrid}
        docs={attachments}
        onRefresh={() => refreshFunc()}
        companyId={props.project?.company.id}
        withCalculator={true}
        projectId={props.project?.id}
      />
      {props.downloadDocuments &&
        attachments &&
        attachments.documents?.length > 0 && (
          <Button onClick={handleClick}>{t(tk.common.downloadDocs)}</Button>
        )}
    </div>
  );
};
