import React, { useState } from 'react';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import mime from 'mime';

import { usePokCore } from '../../hooks/usePokCore';
import { useNotifications } from '../../hooks/useNotifications';
import { useTranslation } from '../../../translations';

export const DownloadAttachment = (props: { id: string; icon?: Icon.Icon }) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('commonLng');
  const [isLoading, setIsLoading] = useState(false);

  const IconA = props.icon ? props.icon : Icon.FileImage;

  const handleClick = () => {
    setIsLoading(true);
    pok.attachments
      .getFile(props.id)
      .then(doc => {
        const file = new Blob([Buffer.from(doc.buffer as ArrayBuffer)], {
          type: mime.getType(doc.name) || undefined,
        });
        window.open(URL.createObjectURL(file));
      })
      .catch(error => {
        notifications.caughtError(error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>{t(tk.button.open)}</Tooltip>}>
        <Button
          className="ps-2 py-0"
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          disabled={isLoading}
          variant="btn-info"
        >
          {isLoading ? (
            <Spinner size="sm" className="mx-2" />
          ) : (
            <IconA size={20} />
          )}
        </Button>
      </OverlayTrigger>
    </>
  );
};
