export default {
  prLang: {
    importExcel: 'Import projects from spreadsheets',
    projects: 'Projects',
    projectsAndTeams: 'Projects and teams',
    projectsApplications: 'Project requests',
    delayedProjects: 'Overdue projects',
    canceledProjects: 'Projects to be canceled',
    project: 'Project',
    genitiveProject: 'of the project',
    genitiveProjectWithoutSpace: 'of_the_project',
    accusativeProject: 'the project',
    genitiveProjectPlural: 'of the projects',
    projectNumber: 'Project number',
    internalProject: 'Commissioning project',
    projectList: 'Project list',
    withProjects: 'with projects',
    connectionsWithProjectsFVZ:
      'Connections with projects will be explained by teams',
    estimate: 'Mediaplan',
    estimate2: 'of the mediaplan',
    estimates: 'Mediaplans',
    addNewProject: 'Add a new project',
    newProjectName: 'New project name',
    chooseProject: 'Select project',
    sourceProject: 'Source project',
    sourceProject2: 'source project',
    addProject: 'Add project',
    editProject: 'Edit project',
    emptyProjectsList: 'No projects in the system.',
    projectNotFound: 'No project found with such ID.',
    salesInvoiceToProject: 'to the project',
    estimateItemPosition: 'Mediaplan position',
    addEstimateItemPosition: 'Add mediaplan position',
    chooseEstimateItem: 'Select mediaplan position',
    estimateItemInvoiceModifyWarning:
      'Invoice items are already saved. Are you sure you want to update the mediaplan position?',
    estimateItemInvoiceTabTitle: 'Mediaplan position',
    deleteProjectWarning:
      'Are you sure you want to delete the selected project?',
    deleteProjectTooltip: 'Delete project',
    unsettledPurchaseInvoicesAssignedToProject:
      'Unsettled purchase invoices assigned to projects',
    purchaseInvoicesAssignedToProject: 'Purchase invoices assigned to projects',
    orderResultProject: 'Subcontracted project',
    readyForInvoicing: 'Projects ready for invoicing',
    readyForCorrection: 'Projects with negative invoicing amount',
    applicationUnblocks: 'Requests to unblock mediaplan',
    projectsListModifiedInvoiceAlert:
      'The project list has been modified. After saving, mediaplan positions and invoice positions will be reset.',
    selectProjectsPurchaseInvoiceAlert:
      'To add multiple projects, select them by clicking on the row, selected projects will turn yellow. After saving changes, the selected projects (one or more) will be added',
    selectProjectPurchaseInvoiceAlertError:
      'Please select one or more projects.',
    sameProject: 'of the same project',
    projectLabel: 'Project label',
    projectControl: 'Project control',
    estimateMonth: 'Mediaplan month',
    monthRangeEstimates: 'Mediaplan month range',
    teamRelatedEstimates: 'Mediaplan positions related to the team',
    forProject: 'for project',
    projectPurchase: 'Project purchase',
    estimatePositionsSum: 'Mediaplan positions sum',
    importProjectsFromFile: 'Import projects from file',
    projectNo: 'Project sys. no.',
    printProjectNumber: 'Print project number',
    saleAndCommissionSum: 'Total sales and commission cost of mediaplan',
    fromProject: 'from project',
    projectClient: 'Project contractor',
    noProjects: 'No projects.',
    errCreateProjectSendApplication:
      'Creating a project in the selected period is blocked, send an request to create the project.',
    parentProjectNumber: 'Parent project number',
    askDownloadFVZ: 'Download purchase invoices (FVZ) attached to the project?',
    askDownloadFVZDespiteWarnings:
      'Download purchase invoices (FVZ) attached to the project despite the following warnings?',
    paePositionsList:
      'PAE position list for project {{projectNo}} for {{date}}',
    maxDelay: 'Max. project delay',
    thisProject: 'this project',
    withoutTeamsProjects: 'Without teams and projects',
    settingUpProjects: 'Setting up projects and completing requests',
    importFromProjectToProject: 'Importing items from a project to a project',
    projectOrders: 'Project orders',
    importProjects: 'Import projects from sheets',
    warningPurchaseAndSalesAmountDiff:
      'The total purchase amount from the commissioning project differs from the sales amount.',
    projectsByMonths: 'Projects by months',
    positionsProjects: 'Project positions',
    reportToBudgetByMonth: 'Budget Report - Monthly by Projects',
    pbProject: 'PB project',
  },
} as const;
