import _ from 'lodash';

import administration from '../plProjects/administration';
import project from '../plProjects/project';
import personalLanguageCommon from '../plProjects/personalLanguage';
import report from '../plProjects/report';
import order from '../plProjects/order';
import fvz from '../plProjects/fvz';
import fvs from '../plProjects/fvs';
import menuNavigation from '../plProjects/menu';
import application from '../plProjects/application';
import excelImport from '../plProjects/excelImport';
import common from '../plProjects/common';
import role from '../plProjects/role';
import client from '../plProjects/client';
import validation from '../plProjects/validation';

import personalLanguage from './personalLanguage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeTranslations = <T>(base: T, override: any): T => {
  return _.merge(
    _.cloneDeep({
      ...base,
    }),
    {
      ...override,
    },
  );
};

export const menuLng = mergeTranslations(
  { ...menuNavigation, ...common, ...personalLanguageCommon },
  {
    ...personalLanguage,
  },
);

export const commonLng = mergeTranslations(
  { ...common, ...validation, ...personalLanguageCommon },
  {
    ...personalLanguage,
  },
);

export const administrationLng = mergeTranslations(
  {
    ...administration,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const roleLng = mergeTranslations(
  {
    ...role,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const clientLng = mergeTranslations(
  {
    ...client,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const projectLng = mergeTranslations(
  {
    ...project,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const fvsLng = mergeTranslations(
  {
    ...fvs,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const fvzLng = mergeTranslations(
  {
    ...fvz,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const orderLng = mergeTranslations(
  {
    ...order,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const reportLng = mergeTranslations(
  {
    ...report,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const applicationLng = mergeTranslations(
  {
    ...application,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);

export const excelImportLng = mergeTranslations(
  {
    ...excelImport,
    ...common,
    ...personalLanguageCommon,
  },
  {
    ...personalLanguage,
  },
);
