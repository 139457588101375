export default {
  finance: {
    salesInvoices: 'Sales Invoices',
    FVS: 'FVS',
    lastModification: 'Last modification to approve',
    verification: 'Verification',
    amountToCorrect: 'Amount to correct',
    amountToInvoice: 'Amount to invoice',
    forContractor: 'for contractor',
    andBudget: 'and budget',
    toInvoice: 'to invoice',
    askCreateInvoiceDraft:
      'Warning: A draft correction for sales invoice no. {{number}} will be automatically created. Are you sure you want to continue?',
    issuingCorrection: 'Issue correction',
    saveAndStepForward: 'Save and move forward',
    paymentForm: 'Payment form',
    paymentDate: 'Payment deadline',
    paymentDateFromReceive: 'Payment date from the day of receipt',
    invoiceNumber: 'Invoice number',
    nip: 'VAT ID',
    askDeleteInvoiceDraft:
      'Are you sure you want to delete the displayed invoice draft?',
    documentCodes: 'Document codes',
    gtuCode: 'GTU code',
    financialAccount: 'Financial account',
    was: 'was',
    is: 'is',
    commission: 'Commission',
    commissionFinancialAccount: 'Commission financial account',
    sumToInvoiceSaleAndCommission:
      'Total amount for invoicing sales and commission',
    mergedPositionsSameAccount:
      'Merged positions must be assigned to the same financial account and belong to',
    mergePositions: 'Merge positions',
    askResetPositions: 'Are you sure you want to reset positions?',
    clonePosition: 'Clone position',
    resetPositions: 'Reset all positions',
    mergePositionsBy: 'Merge positions by',
    mergeSelectedPositionsBy: 'Merge selected positions by',
    netAmount: 'Net amount',
    vat: 'VAT rate',
    netAmountInCurrency: 'Net amount in currency',
    grossAmount: 'Gross amount',
    sumToInvoice: 'Total amount to invoice',
    correctionAmount: 'Correction amount',
    print: 'Print',
    fvsData: 'Data for FVS',
    duplicate: 'Duplicate',
    copy: 'Copy',
    addedWithinBudget: 'added within budget',
    noInvoices: 'No sales invoices in the system.',
    buyerName: 'Buyer name',
    net: 'Net',
    handleChangeToSent: `Are you sure you want to change this invoice's status to 'Sent'?`,
    handleChangeToDraft: `Are you sure you want to change this invoice's status to 'Draft'?`,
    handleChangeToGenerated: `Are you sure you want to change this invoice's status to 'Approved'?`,
    markAsSent: 'Mark as sent',
    undoApproval: 'Undo approval',
    undoSend: 'Undo send',
    correctionReason: 'Reason for issuing correction',
    onePositionOnInvoice: 'One position on invoice',
    textOverItems: 'Text to print above items',
    documentApproved: 'The document will be approved with the issue date',
    andSalesDate: 'and sales date',
    diffVatRates:
      'Different VAT rates are present for items in the invoice. To continue, uncheck the box',
    orSetSameVatRates: 'or set the same VAT rates for items.',
    wrongTryAgain: 'Something went wrong. Please try again.',
    drafts: 'Drafts',
    approved: 'Approved',
    all: 'All',
    exportFvs: 'Export Sales Invoices',
    invoicesDomesticToSent: 'Domestic sales invoices (FVS) to send',
    createInvoicesDomesticToSent:
      'Create XML with domestic sales invoices (FVS)',
    invoicesForeignToSent: 'Foreign sales invoices (FVS) to send',
    createInvoicesForeignToSent: 'Create XML with foreign sales invoices (FVS)',
    packagesFVS: 'Sales invoices (FVS) Packages',
    basicData: 'Basic data',
    invoicePositions: 'Invoice positions',
    summary: 'Summary',
    unwrittenUppercase: 'UNSAVED',
    withCorrections: 'Including corrections',
    archiveUpperCase: 'ARCHIVE',
    packageWithNumber: 'in package with system number',
    saleInvoice: 'Sales invoice',
    correction: 'Correction',
    toSaleInvoice: 'to sales invoice',
    invoicesToCorrect: 'Sales invoices to correct',
    alertDraftInvoiceCorrectionCreation:
      'Warning: A draft correction for sales invoice no. {{number}} will be automatically created. Are you sure you want to continue?',
    noPositionsToInvoice: 'No available positions to invoice.',
    atLeastOneValueInPositionsFilled:
      'At least one position must have a sales amount or commission amount greater than 0.',
    position: 'Position',
    noSymphonyCode: 'No Symphony code for the contractor',
    correctionToInvoice: 'Correction to invoice',
    invoicesInPackage:
      'Invoices included in package with system no. {{number}} from {{date}}',
    createXML10FVS: 'Create XML with domestic sales invoices (FVS) (10 oldest)',
    oldest10: '10 oldest',
    selected: 'selected',
    askCreatePackage:
      'Are you sure you want to create sales invoices (FVS) package?',
    noUnsentInvoices: 'No unsent sales invoices in the system.',
    export: 'Export',
    domestic: 'Domestic',
    foreign: 'Foreign',
    openInstructionNewWindow: 'Open the invoicing instruction in a new window',
    invoiceInstruction: 'Invoicing instruction',
    rejected: 'Rejected',
  },
  symphony: {
    symphonyNew: 'New code',
    code: 'Code',
    noSymphonyForClient: 'No Symphony codes for this contractor.',
    symphonyEdit: 'Edit code',
    symphonyAdd: 'Add new code',
    clientsWithSymphony: 'Contractors - Symphony',
    clientsNoSymphonyFVS: 'Contractors without Symphony code (Sales invoices)',
    clientsNoSymphonyFVZ:
      'Contractors without Symphony code (Purchase invoices)',
    sendUnsentFVSAll: 'Send unsent sales invoices (all)',
    sendUnsentFVS: 'Send unsent sales invoices ({{number}} - selected)',
    sendingFVSToSymfoniaFinished:
      'Sending sales invoices to Symphony Handel staging tables has been completed.',
    exportFVSToSymfonia: 'Export sales invoices to Symphony Handel',
    FVSToSend: 'FVS to send',
    askSendToSymphony:
      'Are you sure you want to send the invoices to the Symphony Handel staging database?',
    sentFVS: 'Sent FVS',
    noUnsentFVS: 'No unsent sales invoices to Symphony Handel.',
    noSentFVS: 'No sent sales invoices to Symphony Handel.',
    symphonyNumberNotFound:
      'Symphony number not found for the settled contractor and company',
    invoiceWontBeImported:
      'The invoice will not be exported to the Symphony system after approval.',
    clients: 'Contractors',
    noClients: 'No contractors',
  },
} as const;
