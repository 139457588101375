import React from 'react';
import { Alert } from 'react-bootstrap';

import { useTranslation } from '../../hooks/useTranslation';

export const NotAuthorized: React.FC = () => {
  const { t, tk } = useTranslation('menuLng');
  return (
    <Alert variant="danger">
      <Alert.Heading>{t(tk.system.noPermissions)}</Alert.Heading>
      <hr />
      <p>{t(tk.system.increaseRights)}</p>
    </Alert>
  );
};
