import React from 'react';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import Linkify from 'react-linkify';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

import TooltipText from '../../../app/components/TooltipText';
import { GetMemoDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

import Pulse from './Pulse';

const linkify = new LinkifyIt();
linkify.tlds(tlds);
const matchDecorator = (text: string): LinkifyIt.Match[] | null => {
  const matches = linkify.match(text);
  if (!matches) {
    return matches;
  }

  const filtered = matches.filter(
    m => m.raw && !m.raw.toLowerCase().includes('wwb.'),
  );
  return filtered.length === 0 ? null : filtered;
};

export const MemoCard = (props: { memo: GetMemoDto }) => {
  const { t, tk } = useTranslation('commonLng');
  if (!props.memo) {
    return <></>;
  }
  const unread = props.memo.unread.toString() === 'true';
  const sent = props.memo.sent.toString() === 'true';
  return (
    <Card
      className={
        (sent ? 'ms-auto' : 'me-auto') + (props.memo.first ? '' : ' mt-1')
      }
    >
      <Card.Header
        className={
          'd-flex' +
          (unread ? ' fw-bold' : '') +
          (sent
            ? ' bg-light text-dark flex-row-reverse'
            : ' bg-success text-dark bg-opacity-25')
        }
      >
        <span className={sent ? undefined : 'flex-fill me-4'}>
          {unread && <Pulse variant="green" />}
          <span className={unread ? ' ms-1' : ''}>
            {props.memo.sender?.name}
          </span>
        </span>
        <span className={sent ? 'flex-fill me-4' : undefined}>
          <TooltipText
            text={moment(props.memo.date)
              .locale(t(tk.common.localeShort))
              .fromNow()}
            tooltip={moment(props.memo.date)
              .locale(t(tk.common.localeShort))
              .calendar()}
          />
        </span>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          <Linkify matchDecorator={matchDecorator}>
            {props.memo.content}
          </Linkify>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
