export default {
  role: {
    role: 'Rola',
    roles: 'Role',
    roleNew: 'Nowa rola',
    roleAdd: 'Dodawanie nowej roli',
    roleEdit: 'Edycja roli',
    noRoles: 'Brak ról w systemie',
    permissions: 'Uprawnienia',
    addToBudget: 'Przypisany do budżetu',
    chosenPermissions: 'Wybrane uprawnienia',
    askDelete: 'Czy na pewno usunąć rolę?',
    noPermissions: 'Brak uprawnień w systemie',
    askDeleteUserRole: 'Czy na pewno usunąć użytkownikowi rolę?',
  },
  permission: {
    employeesRoles: 'Powiązania użytkowników z rolami',
    employeeRoleNew: 'Nowe powiązania',
    employeeRoleEdit: 'Edycja ról użytkownika',
    employeeRoleAdd: 'Dodawanie nowego użytkownika w rolach',
    employeeRoleDelete: 'Usuwanie powiązania',
    employeeRole: 'Powiązanie',
    noEmployeeRoles: 'Brak przypisań użytkowników do ról w systemie',
    askDelete:
      'Czy na pewno usunąć wszystkie uprawnienia przypisane do tego użytkownika?',
  },
  employee: {
    employeesAssigned: 'Użytkownicy przypisani do roli',
    employees: 'Użytkownicy w roli',
    employeeNew: 'Nowy użytkownik',
    employeeEdit: `Edycja przypisania użytkownika do roli`,
    employeeAdd: 'Dodawanie nowego użytkownika do roli',
  },
} as const;
