import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface SalesInvoicePositionsControlsProps {
  handleResetPositions: () => void;
  selected: number[];
  handleMergePositions: (keys: number[]) => void;
  handleClonePosition: (key: number) => void;
  handleMergePositionsByProject: (key: number[]) => void;
  handleSave: () => void;
  isMergeDisabled: boolean;
  readOnly?: boolean;
  isLoading: boolean;
}

export const SalesInvoicePositionsControls: React.FC<
  SalesInvoicePositionsControlsProps
> = ({
  handleResetPositions,
  selected,
  handleMergePositions,
  handleClonePosition,
  handleMergePositionsByProject,
  handleSave,
  isMergeDisabled,
  readOnly,
  isLoading,
}) => {
  const { t, tk } = useTranslation('fvsLng');

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex gap-1">
        {selected.length > 1 && (
          <>
            <OverlayTrigger
              trigger={isMergeDisabled ? ['hover', 'focus'] : []}
              overlay={
                <Tooltip>
                  {t(tk.finance.mergedPositionsSameAccount)}{' '}
                  {t(tk.prLang.sameProject)}.
                </Tooltip>
              }
            >
              <div>
                <Button
                  onClick={() => handleMergePositions(selected)}
                  disabled={isMergeDisabled || readOnly}
                >
                  {t(tk.finance.mergePositions)}
                </Button>
              </div>
            </OverlayTrigger>
          </>
        )}
        <Button
          onClick={() => handleMergePositionsByProject(selected)}
          disabled={readOnly}
        >
          {selected.length
            ? t(tk.finance.mergeSelectedPositionsBy)
            : t(tk.finance.mergePositionsBy)}{' '}
          {t(tk.prLang.genitiveProject)}
        </Button>
        {selected.length === 1 && (
          <Button
            onClick={() => handleClonePosition(selected[0])}
            disabled={readOnly}
          >
            {t(tk.finance.clonePosition)}
          </Button>
        )}
        <ConfirmationButton
          variant="outline-danger"
          onOK={handleResetPositions}
          confirmation={t(tk.finance.askResetPositions)}
          disabled={readOnly}
        >
          {t(tk.finance.resetPositions)}
        </ConfirmationButton>
      </div>
      <Button onClick={handleSave} disabled={readOnly} isLoading={isLoading}>
        {t(tk.finance.saveAndStepForward)}
      </Button>
    </div>
  );
};
