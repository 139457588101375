import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetExcelConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ExcelConfigSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const excelConfigForGrid = (config: GetExcelConfigDto) => {
  return {
    key: config.id,
    values: {
      'config.name': config.name,
      'team.name': config.excelConfigTeams
        ?.map(({ team }) => team?.name + ' (' + team?.company?.shortname + ')')
        ?.join(', '),
      'company.shortname': config.company?.shortname ?? '',
      'profile.name': config.profile?.name,
    },
    isInactive: !config.active,
  };
};

export const ExcelConfigSearch: React.FC<ExcelConfigSearchProps> = props => {
  const { t, tk } = useTranslation('excelImportLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.name), property: 'config.name' },
    { header: t(tk.common.team), property: 'team.name' },
    { header: t(tk.common.company), property: 'company.shortname' },
    { header: t(tk.config.profile), property: 'profile.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="config.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.config.noImportConfigs)}
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
