import React, { useEffect, useState } from 'react';
import { Alert, Tab, Tabs } from 'react-bootstrap';

import {
  CreateUpdateOrderExecutionDto,
  GetOrderExecutionDtoStatusEnum,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient/models';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { OrderExecutionBasicData } from './OrderExecutionBasicData';
import { OrderExecutionPositions } from './OrderExecutionPositions';
import { OrderExecutionEditorTabs } from './OrderExecutionEditorTabs';

interface OrderExecutionFormProps {
  id?: string;
  orderExecution: CreateUpdateOrderExecutionDto;
  propertyChange?: (obj: Partial<CreateUpdateOrderExecutionDto>) => void;
  emails: string[];
  setEmails?: (emails: string[]) => void;
  project?: GetProjectDto;
  orderResultProject?: GetProjectDto;
  orderProject?: GetProjectDto;
  readOnly?: boolean;
  disableSumsDiscrepancyAlert?: boolean;
  status?: GetOrderExecutionDtoStatusEnum;
  rejectedNote?: string;
  isDecisionView?: boolean;
  purchaseSum?: number;
  currencyCode?: string;
}

export const OrderExecutionForm: React.FC<OrderExecutionFormProps> = props => {
  const [activeTab, setActiveTab] = useState(
    props.id
      ? OrderExecutionEditorTabs.POSITIONS
      : OrderExecutionEditorTabs.BASIC_DATA,
  );
  const [showSumsDiscrepancyAlert, setShowSumsDiscrepancyAlert] =
    useState(false);
  const [theSameCurrency, setTheSameCurrency] = useState(true);
  const [resultProject, setResultProject] = useState(props.orderResultProject);
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('orderLng');

  useEffect(() => {
    if (props.orderResultProject && props.orderResultProject?.company?.id) {
      setResultProject(props.orderResultProject);
    }
    if (props.orderResultProject && !props.orderResultProject?.company?.id) {
      pok.projects
        .getById(props.orderResultProject.id)
        .then(o => setResultProject(o))
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [pok.projects, notifications, props.orderResultProject]);

  useEffect(() => {
    if (
      resultProject &&
      props.orderProject?.company?.id &&
      resultProject?.company?.id
    ) {
      pok.companies
        .getById(props.orderProject?.company?.id)
        .then(o =>
          pok.companies
            .getById(resultProject?.company.id || '')
            .then(p =>
              setTheSameCurrency(
                p?.mainCurrency?.currencyCode === o?.mainCurrency?.currencyCode,
              ),
            )
            .catch(errorResponse => {
              notifications.caughtError(errorResponse);
            }),
        )
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [pok.companies, notifications, props.orderProject, resultProject]);

  return (
    <>
      <Tabs
        activeKey={activeTab}
        onSelect={tab => setActiveTab(tab as OrderExecutionEditorTabs)}
        className="mt-3"
      >
        <Tab
          eventKey={OrderExecutionEditorTabs.BASIC_DATA}
          title={t(tk.orderExec.basicData)}
          className="m-3"
        >
          <OrderExecutionBasicData {...props} />
        </Tab>
        <Tab
          eventKey={OrderExecutionEditorTabs.POSITIONS}
          title={t(tk.orderExec.positions)}
          className="m-3"
        >
          <OrderExecutionPositions
            {...props}
            theSameCurrency={theSameCurrency}
            setShowSumsDiscrepancyAlert={setShowSumsDiscrepancyAlert}
          />
        </Tab>
      </Tabs>
      {showSumsDiscrepancyAlert &&
        !props.disableSumsDiscrepancyAlert &&
        theSameCurrency && (
          <Alert variant="warning" className="mt-3">
            {t(tk.prLang.warningPurchaseAndSalesAmountDiff)}
          </Alert>
        )}
      {!theSameCurrency && (
        <Alert variant="warning" className="mt-3">
          {t(tk.orderExec.warningDifferentCurrencyCompanies)}
        </Alert>
      )}
    </>
  );
};
