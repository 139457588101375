/*eslint max-lines-per-function: ["error", 240]*/
import React, { useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import FormRow from '../../../app/components/FormRow';
import ProfileSelector from '../Selects/ProfileSelector';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { Option } from '../Selects/Selector';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { GetEstimateItemDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import 'react-calendar/dist/Calendar.css';
import '../../../theme/calendar.css';
import '../../../theme/datePicker.css';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';
import ProjectSelector from '../Selects/ProjectSelector';
import { NewDatePickerReact } from '../../../app/components/NewDatePickerReact';

import { EstimateItemsCloneGrid } from './EstimateItemsCloneGrid';

interface CloneEstimateItemsFormProps {
  teamIds?: string[];
  budgetId: string;
  show?: boolean;
  handleClose?: () => void;
  onCompleted: (
    items: string[],
    targetMonth: Date | undefined,
    witHAmounts: boolean,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const CloneEstimateItemsForm: React.FC<
  CloneEstimateItemsFormProps
> = props => {
  const [projectId, setProjectId] = useState<string>();
  const [filterMonth, setFilterMonth] = useState<Date | undefined>();
  const [targetMonth, setTargetMonth] = useState<Date | undefined>();
  const [filterProfile, setFilterProfile] = useState<string>();
  const [estimateItems, setEstimateItems] = useState<GetEstimateItemDto[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const notifications = useNotifications();
  const pok = usePokCore();
  const companyId = pok.getCompanyId();
  const { t, tk } = useTranslation('projectLng');

  const setDate = (date: Date | undefined) => {
    if (date) {
      //ten kalendarz inaczej ustawia godzinę niż nasz główny kalendarz
      //dlatego trzeba skorygować godzinę
      date.setMinutes(-date.getTimezoneOffset());
      return date;
    }
    return undefined;
  };

  const search = async () => {
    setSelectedPositions([]);
    setSaveErrors([]);
    setShowSaveErrors(false);
    if (!projectId) {
      setSaveErrors([
        `${t(tk.estimates.select)} ${t(tk.prLang.sourceProject2)}.`,
      ]);
      setShowSaveErrors(true);
      return;
    }

    pok.estimateItems
      .getByProjectIdProfileIdAndDate(projectId, filterProfile, filterMonth)
      .then(setEstimateItems)
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  const handleOnRowClick = (key: string) => {
    let sel = [];
    if (!selectedPositions.includes(key)) {
      sel = [...selectedPositions, key];
    } else {
      sel = selectedPositions.filter(o => o !== key);
    }
    setSelectedPositions(sel);
  };

  const cloneSelected = (witHAmounts: boolean) => {
    if (selectedPositions.length === 0) {
      setSaveErrors([t(tk.estimates.noPositionSelected)]);
      setShowSaveErrors(true);
      return;
    }
    setIsLoading(true);
    props
      .onCompleted(selectedPositions, targetMonth, witHAmounts)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const selectAll = () => {
    setSelectedPositions(estimateItems.map(p => p.id));
  };

  const deselectAll = () => {
    setSelectedPositions([]);
  };

  return (
    <Modal
      onHide={props.handleClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {`${t(tk.estimates.selectPositionsToImport)}: `}
          <NewDatePickerReact
            onChange={value =>
              setTargetMonth(setDate(value as Date | undefined))
            }
            className={
              !targetMonth
                ? ' small-calendar clone-date-selector-not'
                : ' small-calendar clone-date-selector-ok'
            }
            value={targetMonth}
          />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className="d-grid gap-4 mb-3">
          <FormRow controlId="project" label={t(tk.prLang.sourceProject)}>
            <ProjectSelector
              onChange={options => {
                setProjectId((options as Option).value);
              }}
            />
          </FormRow>

          <FormRow controlId="profile" label={t(tk.estimates.filterByProfile)}>
            <ProfileSelector
              provider={pok.profiles.getAllByCompanyIdsTeamIdsAuth}
              companyIds={[companyId || '']}
              teamIds={props.teamIds}
              budgetId={props.budgetId}
              clearable={true}
              onChange={options => {
                setFilterProfile((options as Option).value || undefined);
              }}
            />
          </FormRow>
          <FormRow controlId="month" label={t(tk.estimates.filterByMonth)}>
            <NewDatePickerReact
              onChange={value =>
                setFilterMonth(setDate(value as Date | undefined))
              }
              value={filterMonth}
            />
          </FormRow>
          <Row>
            <Col className="col-3"></Col>
            <Col className="col-9">
              <Button variant="primary" onClick={search} className="px-5">
                {t(tk.button.find)}
              </Button>
            </Col>
          </Row>
        </Form>
        <div className="clone-count">
          {t(tk.component.selected)} <b>{selectedPositions.length}</b>{' '}
          {selectedPositions.length === 1
            ? t(tk.estimates.positionAccusative1)
            : selectedPositions.length > 1 && selectedPositions.length < 5
              ? t(tk.estimates.positionAccusative2_4)
              : t(tk.estimates.positionAccusative5)}
        </div>
        <EstimateItemsCloneGrid
          data={estimateItems}
          enableMultiSelect={true}
          selectedKeys={selectedPositions}
          onRowClick={handleOnRowClick}
        />
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="m-3"
        />
        <section className="side-by-side">
          {estimateItems.length > 0 &&
            (selectedPositions.length !== estimateItems.length ? (
              <Button variant="outline-secondary" onClick={selectAll}>
                {t(tk.component.selectAll)}
                <Icon.CheckSquare className="icon mx-2 mb-1" />
              </Button>
            ) : (
              <Button variant="outline-secondary" onClick={deselectAll}>
                {t(tk.component.deselectAll)}
                <Icon.Square className="icon mx-2 mb-1" />
              </Button>
            ))}
          <div className="marginLeft">
            <Button variant="outline-secondary" onClick={props.handleClose}>
              {t(tk.button.close)}
            </Button>
            <DropdownButton
              title={
                <>
                  {isLoading && <Spinner size={'sm'} className="me-1" />}
                  {t(tk.estimates.importSelected)}
                </>
              }
              id="clone-dropdown"
              variant="outline-primary"
              className="mx-1 d-inline-block"
              disabled={isLoading}
            >
              <Dropdown.Item onClick={() => cloneSelected(false)} eventKey="1">
                {t(tk.estimates.withoutAmounts)}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => cloneSelected(true)} eventKey="2">
                {t(tk.estimates.withAmounts)}
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
