import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';

import { Option } from '../Selects/Selector';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateEmployeeRoleDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import FormRow from '../../../app/components/FormRow';
import CompanySelector from '../Selects/CompanySelector';
import TeamSelector from '../Selects/TeamSelector';
import EmployeeSelector from '../Selects/EmployeeSelector';
import BudgetSelector from '../Selects/BudgetSelector';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface UserInRoleEditorProps {
  id: string;
  employeeRoleId?: string | null;
  employeeRole: CreateUpdateEmployeeRoleDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    roleId: string,
    id?: string | null,
    employeeRole?: CreateUpdateEmployeeRoleDto,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const UserInRoleEditor: React.FC<UserInRoleEditorProps> = props => {
  const [employeeRole, setEmployeeRole] = useState(props.employeeRole);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('roleLng');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setEmployeeRole(props.employeeRole);
    }
  }, [props.employeeRole, props.show]);

  const createEmployeeRole = async () => {
    props
      .onCompleted(props.id, props.employeeRoleId, employeeRole)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  const propertyChange = (obj: Partial<CreateUpdateEmployeeRoleDto>) => {
    setEmployeeRole(prevState => ({ ...prevState, ...obj }));
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.employeeRoleId
              ? t(tk.employee.employeeEdit)
              : t(tk.employee.employeeAdd)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form className="d-grid gap-3">
              <FormRow controlId="manager" label={t(tk.common.user)}>
                <EmployeeSelector
                  value={employeeRole?.userId}
                  onChange={option => {
                    if (option) {
                      propertyChange({
                        userId: (option as Option)?.value,
                      });
                    }
                  }}
                  withOneToolId={true}
                />
              </FormRow>
              <FormRow controlId="company" label={t(tk.common.company)}>
                <CompanySelector
                  value={employeeRole.companyId}
                  onChange={option => {
                    propertyChange({
                      companyId: (option as Option)?.value,
                    });
                  }}
                  clearable
                />
              </FormRow>
              <FormRow controlId="team" label={t(tk.common.team)}>
                <TeamSelector
                  value={employeeRole.teamId}
                  companyId={employeeRole.companyId}
                  onChange={option => {
                    if (option) {
                      propertyChange({ teamId: (option as Option)?.value });
                    }
                  }}
                  clearable
                />
              </FormRow>
              <FormRow controlId="budget" label={t(tk.common.budget)}>
                <BudgetSelector
                  value={employeeRole?.budgetId}
                  onChange={option => {
                    if (option) {
                      propertyChange({ budgetId: (option as Option)?.value });
                    }
                  }}
                  clearable
                />
              </FormRow>
            </Form>
            <ValidationAlert
              show={showSaveErrors}
              errors={saveErrors}
              className="m-3"
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          {props.employeeRoleId && (
            <LastEditorInfo
              id={props.employeeRoleId}
              method={pok.employeesRoles.getLastEditor}
            />
          )}
          <Button variant="outline-secondary" onClick={props.handleClose}>
            {t(tk.button.close)}
          </Button>
          <Button variant="primary" onClick={createEmployeeRole}>
            {t(tk.button.save)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
