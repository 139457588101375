export default {
  client: {
    client: 'Contractor',
    clients: 'Contractors',
    clientAdd: 'Adding a new contractor',
    clientEdit: 'Editing contractor no. sys.',
    clientNew: 'New contractor',
    noClients: 'No contractors in the system',
    nip: 'VAT ID',
    pesel: 'PESEL',
    dealGroupNumber: 'Deal grouping',
    country: 'Country',
    place: 'City',
    postcode: 'Postal code',
    street: 'Street',
    numbers: 'Number',
    flat: 'Apartment',
    addressEx: 'Foreign address',
    isRelated: 'Related contractor',
    notToSymphony: 'No export of purchase invoice (FVZ) to Symphony',
    symphony: 'Symphony',
    address: 'Address',
    invoiceNumber: 'Invoice no.',
    enterNIP: 'Please enter VAT ID',
  },
  purchaser: {
    purchaser: 'Purchaser',
    purchaserAdd: 'Adding a new purchaser',
    purchaserEdit: 'Editing purchaser no. sys.',
    purchaserNew: 'New purchaser',
    noPurchasers: 'No purchasers in the system',
    noPurchasersForClient: 'No purchasers for this contractor.',
    manager: 'Budget manager',
    addPurchaser: 'Add purchaser',
  },
  position: {
    position: 'Profile position',
    positions: 'Profile positions',
    positionsShortName: 'Positions',
    positionAdd: 'Adding a new profile position',
    positionEdit: 'Editing profile position no. sys.',
    positionNew: 'New profile position',
    noPositions: 'No profile positions in the system',
    noPositionsForClient: 'No profile positions for this contractor',
    chooseSpecialTeam: 'Please select a special team.',
    groupName: 'Group name',
    type: 'Position type',
    communicationCanal: 'Communication channel',
    specialTeam: 'Specialized team',
    profilesEstimates: 'Mediaplan profiles',
    notPurchaseDeficiencies:
      'Not subject to purchase invoice (FVZ) deficiencies',
    profiles: 'Profiles',
  },
  budget: {
    budget: 'Budget',
    budgets: 'Budgets',
    budgetAdd: 'Adding a new budget',
    budgetEdit: 'Editing budget no. sys.',
    budgetNew: 'New budget',
    noBudgets: 'No budgets in the system',
    managerName: 'Budget manager',
    downloadDocuments: 'Bulk document download',
  },
  brand: {
    brand: 'Brand',
    brands: 'Brands',
    brandAdd: 'Adding a new brand',
    brandEdit: 'Editing brand no. sys.',
    brandNew: 'New brand',
    noBrands: 'No brands in the system',
    budget: 'Brand budget',
  },
} as const;
