export default {
  menu: {
    report: 'Zestawienie',
    reports: 'Zestawienia',
    dataDownload: 'Trwa pobieranie danych. Proszę czekać...',
  },
  filter: {
    invoiceType: 'Typ faktury',
    invoiceStatus: 'Status faktury',
    dateSale: 'Data sprzedaży',
    datePurchase: 'Data zakupu',
    dateInvoices: 'Data wystawienia',
    yearGive: 'Proszę podać rok.',
  },
  finance: {
    purchaseInvoices: 'Faktury zakupu',
    salesInvoices: 'Faktury sprzedaży',
    FVS: 'FVS',
    FVZ: 'FVZ',
    budgetReport: 'Raport do budżetu',
    estimates: 'Pozycje mediaplanu',
    controling: 'Kontroling',
    FVZWithEstimates: 'FVZ - przypisanie do pozycji mediaplanów',
    FVZWithEstimatesLong: 'Faktury zakupu w powiązaniu z pozycjami mediaplanów',
    FVZWithEstimatesShort: 'FVZ, pozycje mediaplanu',
    FVSControling: 'FVS - raport dla kontrolingu',
    FVSControlingLong: 'Faktury sprzedaży w podziale na miesiąc dowiązania',
    FVSControlingShort: 'FVS, pozycje mediaplanu',
    reportFVS: 'RaportFVS',
    reportFVZ: 'RaportFVZ',
    reportFVSByMonth: 'Zestawienie faktur sprzedaży w kontekście miesięcy',
    reportFVZLong: 'Zestawienie faktur zakupu',
    reportFVZByPosition:
      'Zestawienie faktur zakupu w kontekście pozycji mediaplanu',
    reportControlFVZ: 'RaportKontrolingFVZ',
    reportControlFVS: 'RaportKontrolingFVS',
    reportToBudget: 'RaportDoBudzetu',
    reportFVSLong: 'Zestawienie faktur sprzedaży',
    reportFVSWithPositions: 'Zestawienie faktur sprzedaży z nazwami pozycji',
  },
  administration: {
    estimatesPositions: 'Pozycje mediaplanów',
    rawData: 'Surówka',
    extensiveDataFromEstimatesByMonth: 'Rozbudowane dane z pozycji mediaplanów',
  },
  controlling: {
    positionName: 'Pozycja nazwa',
    clientName: 'Pozycja kontrahent',
    format: 'Format',
    salesNet: 'Sprzedaż netto',
    salesCommissionAmount: 'Sprzedaż prowizja kwota',
    salesNetTotal: 'Sprzedaż total netto',
    responsiblePerson: 'Osoba odpowiedzialna',
    mpSigned: 'MP podpisany',
    attachmentOrder: 'Zlecenie do dostawcy',
    acceptedOrderExec: 'Zaakceptowana realizacja zamówienia',
    clientFV: 'FV od dostawcy',
    noPositions: 'Brak pozycji do wyświetlenia',
    control: 'Kontrola',
    genitiveDocuments: 'dokumentow',
    documentList: 'Zestawienie do kontroli dokumentów',
    for: 'dla',
    documentControl: 'Kontrola dokumentów',
  },
} as const;
