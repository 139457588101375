import React from 'react';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateProjectWithTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import PurchaserSelector from '../Selects/PurchaserSelector';
import BrandSelector from '../Selects/BrandSelector';
import { Option } from '../Selects/Selector';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useAuth } from '../../../common/hooks/useAuth';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ProjectMainFieldsForm2Props {
  project: CreateUpdateProjectWithTeamDto;
  readOnly?: boolean;
  isCreatedFromOrder?: boolean;
  budgetId?: string;
  propertyChange?: (obj: Partial<CreateUpdateProjectWithTeamDto>) => void;
}

export const ProjectMainFieldsForm2: React.FC<
  ProjectMainFieldsForm2Props
> = props => {
  const pok = usePokCore();
  const auth = useAuth();
  const { t, tk } = useTranslation('projectLng');

  const notAuthorized = props.project.id
    ? !auth.check(PermissionsEnum.Administration, pok.getCompanyId())
    : false;

  return (
    <>
      <FormRow controlId="purchaser" label={t(tk.common.purchaser)}>
        <PurchaserSelector
          teamIds={props.project.teamIds}
          budgetId={props.budgetId}
          readOnly={
            props.readOnly ||
            props.project.teamIds === undefined ||
            notAuthorized
          }
          parentProjectId={props.project.parentId}
          value={props.project.purchaserId}
          onChange={option => {
            if (option && props.propertyChange) {
              props.propertyChange({ purchaserId: (option as Option).value });
            }
          }}
          role={PermissionsEnum.Projects}
        />
      </FormRow>
      <FormRow controlId="brand" label={t(tk.common.brand)}>
        <BrandSelector
          purchaserId={props.project.purchaserId}
          readOnly={
            props.readOnly ||
            props.project.purchaserId === undefined ||
            props.isCreatedFromOrder
          }
          value={props.project.brandId}
          onChange={option =>
            props.propertyChange &&
            props.propertyChange({
              brandId: option?.value,
            })
          }
        />
      </FormRow>
    </>
  );
};
