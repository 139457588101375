import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { GetEstimateItemDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import Button from '../../../common/components/Button/Button';
import FormRow from '../../../app/components/FormRow';
import CurrencyControl from '../../../app/components/CurrencyControl';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface PurchaseNetTotalEditorProps {
  estimateItem: GetEstimateItemDto;
  handleClose?: () => void;
  handleRefresh: () => void;
  show?: boolean;
  onCompleted: (amount: string) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const PurchaseNetTotalEditor: React.FC<
  PurchaseNetTotalEditorProps
> = props => {
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState<string>(
    props.estimateItem.purchaseNetTotal,
  );

  const notifications = useNotifications();
  const { t, tk } = useTranslation('fvzLng');

  const updatePurchaseNet = async () => {
    setIsLoading(true);
    props
      .onCompleted(amount)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
        props.handleRefresh();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (value: string | null) => {
    setAmount(value || '0');
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t(tk.finance.editNetAmount)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-4 mb-3">
            <FormRow controlId="purchaseNet" label={t(tk.finance.purchaseNet)}>
              <CurrencyControl
                value={amount}
                decimalScale={2}
                onChange={value => propertyChange(value)}
                allowNegative
              />
            </FormRow>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            {t(tk.button.close)}
          </Button>
          <Button
            variant="primary"
            onClick={updatePurchaseNet}
            isLoading={isLoading}
          >
            {t(tk.button.save)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
