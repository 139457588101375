export default {
  enum: {
    position: 'Position (supplier)',
    purchaser: 'Purchaser (contractor)',
    dictionaryValue: 'Dictionary value',
    brand: 'Brand',
    permission: 'Permission (access)',

    projects: 'Projects',
    campaigns: 'Campaigns',

    pl: 'Polish',
    en: 'English',

    standard: 'Standard',
    commission: 'Commission',
    transferredCommission: 'Transferred commission',

    new: 'New',
    completed: 'Completed',
    accepted: 'Accepted',
    rejected: 'Rejected',

    notSent: 'Not sent',
    sent: 'Sent',
    error: 'Error',
    ok: 'OK',
    duplicate: 'Duplicate',

    acceptedByClient: 'Accepted by purchaser',
    readyForInvoice: 'Ready for invoicing',
    invoiced: 'Invoiced',
    corrected: 'Corrected',
    settled: 'Settled',
    cancelled: 'Cancelled',

    foreign: 'Foreign',
    domestic: 'Domestic',

    draft: 'Draft',
    generated: 'Approved',
    sentShe: 'Sent',
    paid: 'Paid',

    open: 'Entered',
    approved: 'Approved',

    bankTransfer: 'Bank transfer',
    compensation: 'Compensation',
    prepayment: 'Prepayment',
    paidIt: 'Paid',

    newPlural: 'New',
    acceptedPlural: 'Accepted',
    rejectedPlural: 'Rejected',
    toBeCorrected: 'Requires correction',

    budget: 'With budget',
    project: 'With project',
    purchaseInvoice: 'With sales invoice',
    estimateItemCommentMediaplanner:
      'With media planner comment for project position',
    estimateItemCommentFinance: 'With finance comment for project position',

    sales: 'Sales',
    purchase: 'Purchase',

    projectNoun: 'Project/campaign',
    purchaseInvoiceNoun: 'Purchase invoice',
    saleInvoiceNoun: 'Sales invoice',
    specialInvoicingNoun: 'Custom invoicing',

    delayed: 'Delayed',
    toCancel: 'To cancel',
    all: 'All',

    others: 'Standard',
    tvBuying: 'TV Purchase',
    MPC: 'MPC',

    applications: 'Request',
    positionAppForeign: 'Foreign position request',
    applicationExecutions: 'Request execution',
    orders: 'Order',
    ordersRemind: 'Order - reminder',
    orderExecutions: 'Order execution',
    orderExecutionsRemind: 'Order execution - reminder',
    unblockEstimateByMonth: 'Request to unblock sales/purchases',
    projectsDelayed: 'Creating project/campaign after the permissible period',
    projectsToCancel: 'Canceling project/campaign',
    purchaseDeficiencies: 'Clarifying purchasing deficiencies',
    specialInvoicing: 'Special invoicing',

    orderExecutionReminder: 'Order execution - reminder',
    orderReminder: 'Order - reminder',
    dailyFVZReminder: 'Purchase invoicing - daily reminder',
    weekFVZReminder: 'Purchase invoicing - weekly reminder',

    poland: 'Poland',
    unitedStates: 'United States',
    germany: 'Germany',
  },
} as const;
