export default {
  common: {
    locale: 'en-GB',
    localeShort: 'en',
    company: 'Company',
    companies: 'Companies',
    team: 'Team',
    teams: 'Teams',
    budget: 'Budget',
    client: 'Contractor',
    profile: 'Profile',
    currency: 'Currency',
    employee: 'Employee',
    employees: 'Employees',
    user: 'User',
    year: 'Year',
    month: 'Month',
    monthShort: 'month',
    monthShort2: 'months',
    monthShort6: 'months',
    above: 'above',
    status: 'Status',
    lp: 'No.',
    name: 'Name',
    shortname: 'Abbreviation',
    description: 'Description',
    sysNumber: 'Sys. No.',
    sysNumberLowerCase: 'sys. no.',
    history: 'History',
    lastModification: 'Last modification',
    who: 'Who',
    when: 'When',
    noInformation: 'No information',
    creation: 'Creation',
    activeShe: 'Active',
    activeHe: 'Active',
    nonActiveHe: 'Inactive',
    yes: 'Yes',
    no: 'No',
    yesUpperCase: 'YES',
    noUpperCase: 'NO',
    notDefined: 'ND',
    partly: 'Partly',
    sumUpperCase: 'SUM',
    sum: 'Sum',
    creator: 'Creator',
    creationDate: 'Creation date',
    emails: 'Email addresses',
    noData: 'No data',
    downloadDocs: 'Download documents',
    selectMonth: 'Select month',
    positionName: 'Position Name',
    country: 'Country',
    purchaser: 'Purchaser',
    brand: 'Brand',
    profilePosition: 'Profile position',
    type: 'Type',
    number: 'Number',
    startDate: 'Start date',
    endDate: 'End date',
    title: 'Title',
    openNewWindow: 'Open in new window',
    addDate: 'Add date',
    pok: 'Platforma Obsługi Kampanii',
    help: 'Help',
    openNewCard: 'Open in a new tab',
    invoiceType: 'Invoice type',
    issueDate: 'Issue date',
    saleDate: 'Sale date',
    exchangeRate: 'Exchange rate',
    sales: 'Sales',
    amount: 'Amount',
    sysError: 'System error',
    alertNotifyAdmin:
      'If the issue persists, please notify the System Administrator.',
    contractor: 'Contractor',
  },
  component: {
    pointOut: 'Point out...',
    enter: 'Enter...',
    quickSearch: 'Quick search',
    searchInProgress: 'Searching...',
    numberOfRows: 'Number of rows',
    cleanFilter: 'Clear filter',
    noValues: 'No values',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    selected: 'Selected',
    noCurrency: 'No currency set in the company!',
  },
  button: {
    clean: 'Clear',
    export: 'Export',
    close: 'Close',
    save: 'Save',
    delete: 'Delete',
    add: 'Add',
    edit: 'Edit',
    addDots: 'Add...',
    open: 'Open',
    cancel: 'Cancel',
    find: 'Find',
    download: 'Download',
    view: 'View',
    change: 'Change',
    restore: 'Restore data',
    refresh: 'Refresh',
  },
  attachment: {
    attachments: 'Attachments',
    attachment: 'Attachment',
    attachmentNew: 'New attachment / note',
    calculatorCurrency: 'Currency calculator',
    note: 'Note',
    noteNew: 'New note',
    type: 'Type',
    title: 'Title',
    file: 'File',
    invoicing: 'Invoicing',
    positionsSum: 'Related positions - purchase sum',
    relatedItems: 'Related items',
    addNote: 'Please provide a note.',
    askDeleteNote: 'Are you sure you want to delete the note?',
    askDeleteAttachment: 'Are you sure you want to delete the attachment?',
    noteAdded: 'Note added {{date}}',
    attachmentAdded: 'Attachment added {{date}}',
    chooseFile: 'Choose file',
    deleteAttachment: 'Delete attachment',
    deleteNote: 'Delete note',
    noFileSelected: 'No file selected',
    relatedTo: 'Related to',
    noNotes: 'No notes',
    noAttachments: 'No attachments',
    warningAnotherUserNote:
      'Another user may have added their own note. Close the window and reopen the note.',
    newNote: 'New note {{text}}',
    editNote: 'Edit note {{text}}',
    comment: 'Comment',
    downloadError: 'Error downloading attachments.',
    FVZLacks: 'Purchase invoice (FVZ) lacks in items',
    withoutTv: 'Profiles except TV',
    withTv: 'TV',
    sendRemindersWithoutTv: 'Send reminders (excluding TV)',
    sendRemindersTv: 'Send reminders (TV)',
    fileToLoad: 'File to load',
  },
  email: {
    send: 'Send',
    create: 'Create email',
    recipients: 'Recipients',
    sendToTeam: 'Send email to team address',
    message: 'Message',
  },
  memo: {
    loading: 'Loading notes...',
    createNewMessage: 'Create a new message to the specified user',
    content: 'Content',
    giveContent: 'Enter content',
    purchaseInvoice: 'Purchase invoice no. sys.',
    saleInvoice: 'Sales invoice no. sys.',
    specialInvoicing: 'Special invoicing no. sys.',
    memosNumber: 'Number of notes',
    noMemos: 'No notes',
    information:
      'Notes related to {{subject}} will be visible in the notes window and to all company employees. Only commenting users will receive notifications when a new message is recorded.',
    contentPlhdr: 'Content...',
    noEmptyMessage: 'Message content cannot be empty',
    all: 'All',
    private: 'Private',
    documents: 'Documents',
    threadsCount: 'Total number of threads: ',
    newMessageThreadsCount: 'Number of threads with new messages: ',
    noMessageInSystem:
      'There are currently no messages in the system to display here',
    newMessage: 'New message',
    turnOff: 'Turn off',
    turnOn: 'Turn on',
    newMessageSound: 'incoming message sound',
    to: 'To',
  },
  concurrent: {
    editionMode: 'is in edit mode.',
    makingChanges: 'is currently making changes to the data',
    thisFVS: 'this sales invoice',
    thisFVZ: 'this purchase invoice',
    thisNotStandardInvoices: 'this special invoicing',
  },
} as const;
