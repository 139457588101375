import React, { useState } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { TabsHistory } from '../../../app/components/TabsHistory';
import {
  DecisionApplicationUnblockDto,
  GetApplicationUnblockDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { ApplicationUnblockDecisionEditor } from '../../components/ApplicationsUnblock/ApplicationUnblockDecisionEditor';
import {
  convertDecision,
  validateDecision,
} from '../../../common/pokCore/contexts/ApplicationUnblockContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { ApplicationUnblockStatusEnum } from '../../../common/pokCore/validation/enums';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { PendingApplicationUnblockTab } from './PendingApplicationUnblockTab';
import { AllApplicationUnblockTab } from './AllApplicationUnblockTab';

enum ApplicationsUnblockTabs {
  PENDING = 'pending',
  ALL = 'all',
}

export const ApplicationUnblockView: React.FC = () => {
  const { t, tk } = useTranslation('applicationLng');
  const [applicationUnblock, setApplicationUnblock] =
    useState<GetApplicationUnblockDto>();
  const [saleGlobalBlocked, setSaleGlobalBlocked] =
    React.useState<boolean>(false);
  const [purchaseGlobalBlocked, setPurchaseGlobalBlocked] =
    React.useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const pok = usePokCore();
  const notifications = useNotifications();

  const handleClick = async (id: string) => {
    const fetchedApplicationUnblock = await pok.applicationsUnblock.getById(id);
    setApplicationUnblock(fetchedApplicationUnblock);
    setShow(true);
    pok.blockades
      .checkBlockedMonth(
        fetchedApplicationUnblock.estimateByMonth.date,
        pok.getCompanyId(),
        fetchedApplicationUnblock.estimateByMonth?.project?.projectTeams?.map(
          o => o.team?.id,
        ) || [],
        true,
        false,
      )
      .then(res => {
        setSaleGlobalBlocked(res.toString() === 'true');
      })
      .catch(err => {
        notifications.caughtError(err);
      });
    pok.blockades
      .checkBlockedMonth(
        fetchedApplicationUnblock.estimateByMonth.date,
        pok.getCompanyId(),
        fetchedApplicationUnblock.estimateByMonth?.project?.projectTeams?.map(
          o => o.team?.id,
        ) || [],
        false,
        true,
      )
      .then(res => {
        setPurchaseGlobalBlocked(res.toString() === 'true');
      })
      .catch(err => {
        notifications.caughtError(err);
      });
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleClose = () => {
    setApplicationUnblock(undefined);
    setShow(false);
  };

  const editApplicationUnblockDecision = async (
    decisionApplicationCU: DecisionApplicationUnblockDto,
    id: string,
  ) => {
    const status = await validateDecision(decisionApplicationCU);
    if (status.valid) {
      try {
        await pok.applicationsUnblock.updateDecision(id, decisionApplicationCU);
        void sendEmail(id);
        setShow(false);
        setApplicationUnblock(undefined);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const sendEmail = async (id: string) => {
    pok.emails.sendUnblockEmail(id).catch(err => {
      notifications.caughtError(err);
    });
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.prLang.applicationUnblocks)}
        </Card.Title>
        <TabsHistory
          activeKey={ApplicationsUnblockTabs.PENDING}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={ApplicationsUnblockTabs.PENDING}
            title={t(tk.app.waiting)}
            className="m-3"
          >
            <PendingApplicationUnblockTab
              refresh={refresh}
              onRowClick={handleClick}
            />
          </Tab>
          <Tab
            eventKey={ApplicationsUnblockTabs.ALL}
            title={t(tk.app.all)}
            className="m-3"
          >
            <AllApplicationUnblockTab
              refresh={refresh}
              onRowClick={handleClick}
            />
          </Tab>
        </TabsHistory>
        {applicationUnblock && (
          <ApplicationUnblockDecisionEditor
            id={applicationUnblock.id}
            show={show}
            handleRefresh={handleRefresh}
            estimateByMonth={applicationUnblock.estimateByMonth}
            applicationUnblock={convertDecision(applicationUnblock)}
            handleClose={handleClose}
            author={applicationUnblock.creator}
            createdDate={applicationUnblock.creationTs}
            onCompleted={editApplicationUnblockDecision}
            systemNumber={applicationUnblock.systemNumber}
            readOnly={
              !applicationUnblock.active ||
              applicationUnblock.status !== ApplicationUnblockStatusEnum.New
            }
            salesBlockedMonth={saleGlobalBlocked}
            purchaseBlockedMonth={purchaseGlobalBlocked}
          />
        )}
      </Card.Body>
    </Card>
  );
};
