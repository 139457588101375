import React, { ReactNode } from 'react';
import { Alert, Form, Table } from 'react-bootstrap';
import Select from 'react-select';

import {
  CreateUpdateSalesInvoicePositionDto,
  GetSalesInvoiceDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import CurrencyControl from '../../../app/components/CurrencyControl';
import {
  CurrencyIsoTypeEnum,
  VatEnum,
} from '../../../common/pokCore/validation/enums';
import numberFormatter from '../../../common/numberFormatter';
import { getGrossPrice } from '../../../utils/getVatAmountByVat';
import { UseSelectedGridRowReturn } from '../../../common/hooks/useSelectGridRow';
import { calculateAmountByExchangeRate } from '../../../utils/calculateAmountByExchangeRate';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { getMainCurrencySymbol } from '../../../utils/currencyFormatUtils';

import SalesInvoicePositionsSumRow from './SalesInvoicePositionsSumRow';

interface SalesInvoicePositionsGridProps {
  select: UseSelectedGridRowReturn<number>;
  positions?: CreateUpdateSalesInvoicePositionDto[];
  propertyChange: (
    obj: Partial<CreateUpdateSalesInvoicePositionDto>,
    index: number,
  ) => void;
  readOnly?: boolean;
  salesInvoice?: GetSalesInvoiceDto;
  parentAmount?: string;
}

const vatOptions = Object.entries(VatEnum).map(([key, value]) => ({
  value: key as VatEnum,
  label: value,
}));

export interface TableColumn {
  header: string;
  isHidden?: boolean;
  renderCell: (
    item: CreateUpdateSalesInvoicePositionDto,
    isSelected: boolean,
  ) => string | ReactNode | null;
}

export const SalesInvoicePositionsGrid: React.FC<
  SalesInvoicePositionsGridProps
> = props => {
  const { select } = props;
  const { t, tk } = useTranslation('fvsLng');
  const mainCurrency = getMainCurrencySymbol();

  const columns: TableColumn[] = [
    {
      header: '',
      renderCell: (item, isSelected) => (
        <Form.Check
          type="checkbox"
          checked={isSelected}
          disabled={props.readOnly}
          onChange={e => {
            if (e.target.checked) {
              return select.handleSelect(item.order);
            }

            return select.handleUnselect(item.order);
          }}
        />
      ),
    },
    {
      header: t(tk.common.lp),
      renderCell: item => item.order,
    },
    {
      header: t(tk.prLang.projectNumber),
      renderCell: item =>
        props.salesInvoice?.salesInvoiceProjects?.find(
          ({ project }) => project.id === item.projectId,
        )?.project.number || '',
    },
    {
      header: t(tk.common.name),
      renderCell: item => (
        <Form.Control
          type="text"
          value={item.name || ''}
          disabled={props.readOnly}
          onChange={e =>
            props.propertyChange({ name: e.target.value }, item.order)
          }
        />
      ),
    },
    {
      header: t(tk.finance.financialAccount),
      renderCell: item => item.financialAccount,
    },
    {
      header: t(tk.finance.netAmount),
      renderCell: item => (
        <CurrencyControl
          value={item.amount || ''}
          decimalScale={2}
          disabled={props.readOnly}
          onChange={value =>
            props.propertyChange(
              {
                amount: value || '',
              },
              item.order,
            )
          }
        />
      ),
    },
    {
      header: t(tk.finance.vat),
      renderCell: item => (
        <Select
          value={
            item?.vat
              ? vatOptions.find(({ value }) => item?.vat === value)
              : null
          }
          isDisabled={props.readOnly}
          options={vatOptions}
          onChange={option =>
            props.propertyChange(
              {
                vat: option?.value,
              },
              item.order,
            )
          }
        />
      ),
    },
    {
      header: t(tk.finance.grossAmount),
      renderCell: item =>
        numberFormatter(getGrossPrice(item.amount, item.vat as VatEnum), true),
    },
    {
      header: t(tk.finance.netAmountInCurrency),
      renderCell: item =>
        numberFormatter(
          calculateAmountByExchangeRate(
            item.amount,
            props.salesInvoice?.exchangeRate,
          ),
          true,
          props.salesInvoice?.currency as CurrencyIsoTypeEnum,
        ),
      isHidden: props.salesInvoice?.currency === mainCurrency,
    },
  ];

  if (!props.positions) {
    return <Alert variant="warning">{t(tk.common.noData)}.</Alert>;
  }

  return (
    <Table striped>
      <thead>
        <tr>
          {columns
            .filter(({ isHidden }) => !isHidden)
            .map((col, index) => (
              <th key={`${col.header}-${index}`}>{col.header}</th>
            ))}
        </tr>
      </thead>
      <tbody>
        {props.positions.map(item => {
          const isSelected = select.selected.includes(item.order);

          return (
            <tr
              key={item.order}
              className={`align-middle ${isSelected ? 'table-warning' : ''}`}
            >
              {columns
                .filter(({ isHidden }) => !isHidden)
                .map((col, index) => {
                  return (
                    <td key={`${item.order}-${col.header}-${index}`}>
                      {col.renderCell(item, isSelected)}
                    </td>
                  );
                })}
            </tr>
          );
        })}
        <SalesInvoicePositionsSumRow
          positions={props.positions}
          salesInvoice={props.salesInvoice}
          parentAmount={props.parentAmount}
        />
      </tbody>
    </Table>
  );
};
