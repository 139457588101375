export default {
  config: {
    //konfiguracje importu
    profile: 'Profil',
    profileCategory: 'Kategoria profilu',

    importConfigsByName: 'Konfiguracja importu danych zewnętrznych o nazwie',

    importConfig: 'Konfiguracja importu',
    importConfigs: 'Konfiguracje importu danych zewnętrznych',
    importConfigAdd: 'Dodawanie nowej konfiguracji',
    importConfigNew: 'Nowa konfiguracja',
    importConfigEdit: 'Edycja konfiguracji',
    noImportConfigs: 'Brak konfiguracji importu w systemie.',
    importConfigType: 'Rodzaj importu',
    importConfigDescription: 'Instrukcja importu',
    companyIndependent: 'Niezależna od spółki',
    updateExisting: 'Aktualizacji istniejących pozycji',

    mappingNew: 'Nowe mapowanie',
    mappingEdit: 'Edycja mapowania',
    mappingAdd: 'Dodawanie nowego mapowania',
    columnInPOK: 'Kolumna w POK',
    columnInExcel: 'Kolumna w Excel',
    columnOrder: 'Kolejność kolumn w tabeli importu',
    noColumnMapping: 'Brak zdefiniowanych mapowań kolumn',

    mappingColumn: 'Mapowanie kolumn',
    mappingPosition: 'Mapowanie pozycji',
    positionInPok: 'Pozycja w POK',
    positionInExcel: 'Pozycja w Excel',
    positionParameters: 'Ustawione parametry pozycji',
    noPosition: 'Brak zdefiniowanych pozycji',
    parameters: 'Parametry',
    noConfigsDefined: 'Brak zdefiniowanych konfiguracji',
    addNewConfigsContactAdmin:
      ' W celu dodania nowych konfiguracji prosimy o kontakt z administracją.',
  },
  import: {
    correctlyImported: 'Poprawnie zaimportowanych pozycji',
    added: 'dodano',
    updated: 'zaktualizowano',
    notImported: 'Nie zaimportowanych pozycji',
    errors: 'Błędy',
    noErrors: 'Brak błędów',
    amountEdit: 'Edycja kwoty',
    editAmount: 'Edytuj kwotę',
    amount: 'Kwota',
    restoreOriginalAmount: 'Przywróć oryginalną wartość',
    amountNotEmpty: 'Kwota nie może być pusta',
    noPositionsSelected: 'Nie wybrano żadnych pozycji do importowania.',
    positions: 'Pozycje w POK',
    positionsToUpdate: 'Liczba pozycji do aktualizacji',
    newPositions: 'Liczba nowych pozycji',
    fileName: 'Nazwa arkusza',
    configName: 'Nazwa konfiguracji',
    selected: 'Wybrano',
    positionAccusative: 'pozycję',
    positionsAccusative: 'pozycje',
    positionsGenitive: 'pozycji',
    askImportSelected: 'Czy na pewno zaimportować wybrane pozycje?',
    importSelected: 'Importuj zaznaczone',
    from: 'Od',
    to: 'Do',
    update: 'Aktualizacja',
    new: 'Nowy',
    noPositions: 'Brak pozycji.',
    choose: 'Proszę wybrać',
    chooseMonth: 'Proszę wybrać miesiąc',
    fileWasModified: 'Plik został zmodyfikowany, proszę wybrać go ponownie.',
    loadingData: 'Trwa pobieranie danych...',
    comparingData: 'Trwa porównywanie danych...',
    importingData: 'Trwa importowanie danych...',
    config: 'Konfiguracja',
    excelFile: 'Plik Excel zawierający dane do zaimportowania',
    importInstruction: 'Instrukcja importu',
    import: 'Importuj',
    newImport: 'Nowy import',
    importSummary: 'Podsumowanie importu',
    askCancelImport: 'Czy na pewno wyjść z aktualnego importu?',
    aggregatePositions: 'Agreguj pozycje bez błędów',
    hideErrorPositions: 'Ukryj błędne pozycje',
    positionsPreview: 'Podgląd pozycji z arkusza',
    imported: 'Zaimportowano',
    correct: 'poprawnych',
    error: 'błędnych',
    goToImport: 'Przejdź do importu poprawnych pozycji',
    lack: 'Brak',
    positionNameInExcel: 'Nazwa pozycji w Excelu',
    noName: 'Brak nazwy',
    notFound: 'Nie znaleziono',
    rowNumber: 'Nr wiersza',
    fromConfig: ' (z konfig.)',
    changeParam: 'Zmień parametr',
    chooseParam: 'Wybór parametru',
    nameFromExcel: 'Nazwa z Excela',
    nameFromDictionary: 'Nazwa z słownika',
    changePosition: 'Zmień pozycję',
    positionChange: 'Zmiana pozycji',
    position: 'Pozycja',
    addPositionMapping: 'Dodaj mapowanie pozycji',
  },
} as const;
