/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCountryDto
 */
export interface GetCountryDto {
    /**
     * 
     * @type {string}
     * @memberof GetCountryDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCountryDto
     */
    isMain: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCountryDto
     */
    namePL: string;
    /**
     * 
     * @type {string}
     * @memberof GetCountryDto
     */
    nameEN: string;
    /**
     * 
     * @type {string}
     * @memberof GetCountryDto
     */
    nameDE: string;
}

/**
 * Check if a given object implements the GetCountryDto interface.
 */
export function instanceOfGetCountryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isMain" in value;
    isInstance = isInstance && "namePL" in value;
    isInstance = isInstance && "nameEN" in value;
    isInstance = isInstance && "nameDE" in value;

    return isInstance;
}

export function GetCountryDtoFromJSON(json: any): GetCountryDto {
    return GetCountryDtoFromJSONTyped(json, false);
}

export function GetCountryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCountryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isMain': json['isMain'],
        'namePL': json['namePL'],
        'nameEN': json['nameEN'],
        'nameDE': json['nameDE'],
    };
}

export function GetCountryDtoToJSON(value?: GetCountryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isMain': value.isMain,
        'namePL': value.namePL,
        'nameEN': value.nameEN,
        'nameDE': value.nameDE,
    };
}

