import React from 'react';
import { Form } from 'react-bootstrap';

import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';

import ProfileFieldName from './ProfileFieldName';

interface TvProfileParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  lang: string;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const TvProfileParams: React.FC<TvProfileParamsProps> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    if (props.propertyChange) {
      props.propertyChange(obj);
    }
  };

  return (
    <>
      {schema?.copySize && (
        <ProfileFieldName
          controlId="copySize"
          schema={schema?.copySize}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="TV - długość kopii"
            value={props.estimateItem?.copySizeId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                copySizeId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.orderNumber && (
        <ProfileFieldName
          controlId="orderNumber"
          schema={schema?.orderNumber}
          lang={props.lang}
        >
          <Form.Control
            type="text"
            value={props.estimateItem?.orderNumber ?? ''}
            onChange={e =>
              propertyChange({
                orderNumber: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.purchaseMethod && (
        <ProfileFieldName
          controlId="purchaseMethod"
          schema={schema?.purchaseMethod}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="TV - sposób zakupu"
            value={props.estimateItem?.purchaseMethodId || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                purchaseMethodId: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
    </>
  );
};
