import React from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { OrderSearch } from './OrderSearch';

export const OrdersWaitingTab: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const { t, tk } = useTranslation('orderLng');

  const getData = ((
    pageSize,
    pageNumber,
    orderBy,
    orderDirection,
    filterText,
    filterColumns,
  ) =>
    pok.orders.getAllByPortionWaiting(
      t,
      tk,
      pageSize,
      pageNumber,
      orderBy,
      orderDirection,
      filterText,
      filterColumns,
    )) as GridGetterFunction;

  const getCount = ((filterText, filterColumns) =>
    pok.orders.getAllCountWaiting(
      filterText,
      filterColumns,
    )) as GridCountFunction;

  return (
    <OrderSearch
      getData={getData}
      getCount={getCount}
      onRowClick={nav.orderEditorByTeam}
    />
  );
};
