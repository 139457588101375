import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import FormRow from '../../../app/components/FormRow';
import DictionarySelector from '../Selects/DictionarySelector';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { OptionWithLang } from '../Selects/Selector';
interface ParameterMapperFormProps {
  dictValue?: string;
  dictValuePl?: string;
  dictValueEn?: string;
  dictKey?: string;
  excelParameterName?: string;
  dictName?: string;
  aggregatedRows: boolean;
  handleUpdate: (
    newDictKey: string,
    newDictValue: string,
    newDictValuePl: string,
    newDictValueEn: string,
  ) => void;
}
export const ParameterMapperForm: React.FC<
  ParameterMapperFormProps
> = props => {
  const [newDictValue, setNewDictValue] = useState<string>(
    props.dictValue || '',
  );
  const [newDictValuePl, setNewDictValuePl] = useState<string>(
    props.dictValuePl || '',
  );
  const [newDictValueEn, setNewDictValueEn] = useState<string>(
    props.dictValueEn || '',
  );
  const [newDictKey, setNewDictKey] = useState<string>(props.dictKey || '');
  const [show, setShow] = useState(false);
  const { t, tk } = useTranslation('excelImportLng');

  useEffect(() => {
    setNewDictValue(props.dictValue || '');
    setNewDictKey(props.dictKey || '');
    setNewDictValuePl(props.dictValuePl || '');
    setNewDictValueEn(props.dictValueEn || '');
  }, [props.dictValue, props.dictKey, props.dictValuePl, props.dictValueEn]);

  const updateParameter = async () => {
    props.handleUpdate(
      newDictKey,
      newDictValue,
      newDictValuePl,
      newDictValueEn,
    );
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setShow(true);
  };

  const propertyChange = (option: OptionWithLang) => {
    setNewDictValue(option.label);
    setNewDictKey(option.value);
    setNewDictValuePl(option.labelPl);
    setNewDictValueEn(option.labelEn);
  };

  const ActionIcon = props.dictValue ? Icon.ArrowRepeat : Icon.PlusCircle;
  return (
    <>
      {props.dictValue ?? `[${t(tk.import.lack)}]`}
      {props.aggregatedRows ? (
        <></>
      ) : (
        <OverlayTrigger
          overlay={<Tooltip>{t(tk.import.changeParam)}</Tooltip>}
          trigger={['hover', 'hover']}
        >
          <Button
            className="py-0 btn-link btn-transparent"
            onClick={handleClick}
          >
            <ActionIcon size={18} className="icon-in-cell" />
          </Button>
        </OverlayTrigger>
      )}
      {show && (
        <>
          <Modal
            onHide={handleClose}
            show={show}
            centered={true}
            keyboard={true}
            backdrop="static"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t(tk.import.chooseParam)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form className="d-grid gap-3">
                <FormRow
                  controlId="excelName"
                  label={t(tk.import.nameFromExcel)}
                >
                  <Form.Control
                    type="text"
                    value={props.excelParameterName || ''}
                    readOnly={true}
                    disabled={true}
                  />
                </FormRow>
                <FormRow
                  controlId="params"
                  label={t(tk.import.nameFromDictionary)}
                >
                  <DictionarySelector
                    dictionary={props.dictName || ''}
                    value={newDictKey}
                    clearable={true}
                    onChange={option => propertyChange(option)}
                  />
                </FormRow>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleClose}>
                {t(tk.button.close)}
              </Button>
              <Button variant="primary" onClick={updateParameter}>
                {t(tk.button.save)}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};
