import type { TFunction } from 'i18next';

import {
  CreateUpdateOrderExecutionDto,
  CreateUpdateOrderExecutionDtoFromJSON,
  CreateUpdateOrderExecutionPositionDto,
  CreateUpdateOrderExecutionPositionDtoFromJSON,
  DefaultApi,
  GetLastEditorDto,
  GetOrderExecutionDto,
  GetOrderExecutionWithCurrencyDto,
} from '../autogenerated/pokApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { OrderExecutionSchema } from '../validation/schemas';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { orderExecutionForGrid } from '../../../pok/components/OrdersExecution/OrderExecutionSearch';
import { FilterColumn } from '../../types/FilterColumn';
import { TKeys } from '../../hooks/useTranslation';
import { OrderExecutionStatusEnum } from '../validation/enums';

export interface OrderExecutionContextInterface {
  getById: (id: string) => Promise<GetOrderExecutionWithCurrencyDto>;
  getAllByPortion: (
    t: TFunction<'orderLng', 'orderLng'>,
    tk: TKeys<'orderLng'>,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllByPortionForProject: (
    t: TFunction<'orderLng', 'orderLng'>,
    tk: TKeys<'orderLng'>,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
    projectId?: string,
    resultProjectId?: string,
    currentProjectId?: string,
  ) => Promise<GridRecord[]>;
  getAllCountForProject: (
    filterText?: string,
    filterColumns?: FilterColumn,
    projectId?: string,
    resultProjectId?: string,
    status?: OrderExecutionStatusEnum,
  ) => Promise<number>;
  getAllByPortionWaiting: (
    t: TFunction<'orderLng', 'orderLng'>,
    tk: TKeys<'orderLng'>,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCountWaiting: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  getAllByPortionCompleted: (
    t: TFunction<'orderLng', 'orderLng'>,
    tk: TKeys<'orderLng'>,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCountCompleted: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdateOrderExecutionDto) => Promise<void>;
  create: (dto: CreateUpdateOrderExecutionDto) => Promise<GetOrderExecutionDto>;
  deactivate: (id: string) => Promise<void>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  reject: (id: string, rejectedNote: string) => Promise<void>;
  accept: (id: string) => Promise<void>;
}

export const OrderExecutionContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.orderExecutionControllerGet(id),
    getAllByPortion: async (
      t: TFunction<'orderLng', 'orderLng'>,
      tk: TKeys<'orderLng'>,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.orderExecutionControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );
      return data.map(item => orderExecutionForGrid(t, tk, item));
    },
    getAllCount: (filterText?: string, filterColumns?: FilterColumn) =>
      api.orderExecutionControllerGetAllCount(filterText || '', filterColumns),
    getAllByPortionForProject: async (
      t: TFunction<'orderLng', 'orderLng'>,
      tk: TKeys<'orderLng'>,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      projectId?: string,
      resultProjectId?: string,
      currentProjectId?: string,
    ) => {
      const data = await api.orderExecutionControllerGetAllByPortionForProject(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
        projectId || '',
        resultProjectId || '',
      );
      return data.map(item =>
        orderExecutionForGrid(t, tk, item, currentProjectId),
      );
    },
    getAllCountForProject: (
      filterText?: string,
      filterColumns?: FilterColumn,
      projectId?: string,
      resultProjectId?: string,
      status?: OrderExecutionStatusEnum,
    ) =>
      api.orderExecutionControllerGetAllCountForProject(
        filterText || '',
        filterColumns,
        projectId || '',
        resultProjectId || '',
        status,
      ),
    getAllByPortionWaiting: async (
      t: TFunction<'orderLng', 'orderLng'>,
      tk: TKeys<'orderLng'>,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.orderExecutionControllerGetAllByPortionWaiting(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );
      return data.map(item => orderExecutionForGrid(t, tk, item));
    },
    getAllCountWaiting: (filterText?: string, filterColumns?: FilterColumn) =>
      api.orderExecutionControllerGetAllCountWaiting(
        filterText || '',
        filterColumns,
      ),
    getAllByPortionCompleted: async (
      t: TFunction<'orderLng', 'orderLng'>,
      tk: TKeys<'orderLng'>,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.orderExecutionControllerGetAllByPortionCompleted(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );
      return data.map(item => orderExecutionForGrid(t, tk, item));
    },
    getAllCountCompleted: (filterText?: string, filterColumns?: FilterColumn) =>
      api.orderExecutionControllerGetAllCountCompleted(
        filterText || '',
        filterColumns,
      ),
    create: (dto: CreateUpdateOrderExecutionDto) =>
      api.orderExecutionControllerCreate(dto),
    update: (id: string, dto: CreateUpdateOrderExecutionDto) =>
      api.orderExecutionControllerUpdate(id, dto),
    deactivate: (id: string) => api.orderExecutionControllerDeactivate(id),
    getLastEditor: (id: string) =>
      api.orderExecutionControllerGetLastEditor(id),
    reject: (id: string, rejectedNote: string) =>
      api.orderExecutionControllerReject(id, { rejectedNote }),
    accept: (id: string) => api.orderExecutionControllerAccept(id),
  };
};

export const newOrderExecution = (projectId?: string) => {
  return CreateUpdateOrderExecutionDtoFromJSON({
    projectId,
    addTeamToEmails: false,
    date: new Date(),
    orderExecutionEmployeesIds: [],
    emails: [],
    orderExecutionPositions: [],
    active: true,
  });
};

export const validate = (project: CreateUpdateOrderExecutionDto) => {
  return validateAgainst(OrderExecutionSchema, project);
};

export const convert = (
  orderExecution?: GetOrderExecutionDto,
  projectId?: string,
) => {
  if (!orderExecution) {
    return newOrderExecution(projectId);
  }

  const converted = CreateUpdateOrderExecutionDtoFromJSON(orderExecution);
  converted.orderExecutionEmployeesIds =
    orderExecution.orderExecutionEmployees
      ?.filter(({ active }) => active)
      .map(({ employee }) => employee?.id) || [];
  converted.projectId = orderExecution.project.id;

  return converted;
};

export const newOrderExecutionPosition = (
  json: CreateUpdateOrderExecutionPositionDto,
) => CreateUpdateOrderExecutionPositionDtoFromJSON(json);
