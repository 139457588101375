import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
  UpdateAdminApplicationDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { ApplicationTypeEnum } from '../../../common/pokCore/validation/enums';
import CountrySelector from '../Selects/CountrySelector';
import { ForeignClientFormFields } from '../Clients/ForeignClientFormFields';
import { PolishClientFormFields } from '../Clients/PolishClientFormFields';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ApplicationEditorBasicFormFields } from './ApplicationEditorBasicFormFields';

interface ApplicationEditorFormFieldsProps {
  id?: string;
  application: UpdateAdminApplicationDto;
  createdDate?: Date | null;
  handleClose?: () => void;
  show?: boolean;
  isReadOnly?: boolean;
  findByNip?: (text: string) => void;
  propertyChange?: (
    obj: Partial<CreateUpdateClientDto | CreateUpdateApplicationDto>,
  ) => void;
  updateCountryIsMain?: (isMain: boolean) => void;
}

export const ApplicationEditorFormFields: React.FC<
  ApplicationEditorFormFieldsProps
> = props => {
  const [showClientData, setShowClientData] = useState(false);
  const [countryIsMain, setCountryIsMain] = useState<boolean | undefined>();

  const { t, tk } = useTranslation('applicationLng');

  const propertyChange = (obj: Partial<UpdateAdminApplicationDto>) => {
    if (props.propertyChange) {
      props.propertyChange(obj);
    }
  };

  useEffect(() => {
    const show =
      props.application.applicationType === ApplicationTypeEnum.Permission ||
      props.application.applicationType === ApplicationTypeEnum.Position ||
      props.application.applicationType === ApplicationTypeEnum.Purchaser;
    setShowClientData(show);
  }, [props.application.applicationType]);

  const updateCountryIsMain = (isMain: boolean) => {
    setCountryIsMain(isMain);
    if (props.updateCountryIsMain) {
      props.updateCountryIsMain(isMain);
    }
  };

  return (
    <>
      <ApplicationEditorBasicFormFields
        application={props.application}
        propertyChange={propertyChange}
        show={props.show}
        isReadOnly={props.isReadOnly}
      />
      {showClientData && (
        <Card className="card flex-grow-1">
          <Card.Header>{t(tk.app.clientData)}</Card.Header>
          <Card.Body className="m-3">
            <div className="d-grid gap-4 mb-3">
              <>
                <FormRow controlId="country" label={t(tk.common.country)}>
                  <CountrySelector
                    value={props.application?.countryId}
                    onChange={data =>
                      propertyChange({
                        countryId: data.countryId,
                        countryIsMain: data.isMain,
                      })
                    }
                    updateCurrentCountryIsMain={updateCountryIsMain}
                    readOnly={props.isReadOnly}
                  />
                </FormRow>
                {showClientData && (countryIsMain ?? true) && (
                  <PolishClientFormFields
                    client={props.application}
                    propertyChange={propertyChange}
                    findByNip={props.findByNip}
                    readonly={props.isReadOnly}
                  />
                )}
                {showClientData && !(countryIsMain ?? true) && (
                  <ForeignClientFormFields
                    client={props.application}
                    propertyChange={propertyChange}
                    readonly={props.isReadOnly}
                  />
                )}
              </>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
