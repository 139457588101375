import React, { useMemo } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { FilterColumn } from '../../../common/types/FilterColumn';
import { GridGetterFunction } from '../../../common/components/Grid/GridDataTypes';

import { PurchaseInvoiceSearch } from './PurchaseInvoiceSearch';

export const ProjectAssignedPurchaseInvoicesView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const { t, tk } = useTranslation('fvzLng');

  const handleClick = (id: string, newWindow?: boolean) => {
    nav.projectAssignedPurchaseInvoiceEditor(id, newWindow);
  };

  const getSummary = useMemo(
    () => (filterText?: string, filterColumns?: FilterColumn) =>
      pok.purchaseInvoices.getSumAllUnsettledWithProjectsForPurchaseInvoices(
        t,
        tk,
        filterText,
        filterColumns,
      ),
    [pok.purchaseInvoices, t, tk],
  );

  const getDataUnsettled = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.purchaseInvoices.getAllUnsettledWithProjectsByPortion(
          t,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok, t],
  );

  const getDataForMediaplaner = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.purchaseInvoices.getAllForMediaplanerByPortion(
          t,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok, t],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.prLang.purchaseInvoicesAssignedToProject)}
        </Card.Title>
        <TabsHistory
          activeKey="unsettled_all_invoices"
          id="unsettledInvoices"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey="unsettled_all_invoices"
            title={t(tk.finance.unsettled)}
            className="m-3"
          >
            <PurchaseInvoiceSearch
              getData={getDataUnsettled}
              getCount={pok.purchaseInvoices.getAllUnsettledWithProjectsCount}
              onRowClick={handleClick}
              getSummary={getSummary}
              mode="estimateItem"
              displayTeamsProjects={true}
              withFilterInColumns={true}
              withCountDays={true}
            />
          </Tab>
          <Tab
            eventKey="all_invoices"
            title={t(tk.finance.all)}
            className="m-3"
          >
            <PurchaseInvoiceSearch
              getData={getDataForMediaplaner}
              getCount={pok.purchaseInvoices.getAllForMediaplanerCount}
              displayTeamsProjects={true}
              mode="estimateItem"
              withFilterInColumns={true}
              withCountDays={true}
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
