/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetClientDto } from './GetClientDto';
import {
    GetClientDtoFromJSON,
    GetClientDtoFromJSONTyped,
    GetClientDtoToJSON,
} from './GetClientDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetProjectDto } from './GetProjectDto';
import {
    GetProjectDtoFromJSON,
    GetProjectDtoFromJSONTyped,
    GetProjectDtoToJSON,
} from './GetProjectDto';
import type { GetSalesInvoiceDto } from './GetSalesInvoiceDto';
import {
    GetSalesInvoiceDtoFromJSON,
    GetSalesInvoiceDtoFromJSONTyped,
    GetSalesInvoiceDtoToJSON,
} from './GetSalesInvoiceDto';
import type { GetTeamDto } from './GetTeamDto';
import {
    GetTeamDtoFromJSON,
    GetTeamDtoFromJSONTyped,
    GetTeamDtoToJSON,
} from './GetTeamDto';

/**
 * 
 * @export
 * @interface GetPurchaseInvoiceDto
 */
export interface GetPurchaseInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceDto
     */
    number: string;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseInvoiceDto
     */
    systemNumber: number;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceDto
     */
    sellDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceDto
     */
    issueDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceDto
     */
    paymentDate: Date;
    /**
     * 
     * @type {GetClientDto}
     * @memberof GetPurchaseInvoiceDto
     */
    client: GetClientDto;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceDto
     */
    amountWithoutPosition: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseInvoiceDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceDto
     */
    type: GetPurchaseInvoiceDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceDto
     */
    status: GetPurchaseInvoiceDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceDto
     */
    currency: GetPurchaseInvoiceDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceDto
     */
    exchangeRate: string;
    /**
     * 
     * @type {Array<GetProjectDto>}
     * @memberof GetPurchaseInvoiceDto
     */
    projects?: Array<GetProjectDto>;
    /**
     * 
     * @type {Array<GetTeamDto>}
     * @memberof GetPurchaseInvoiceDto
     */
    teams?: Array<GetTeamDto>;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetPurchaseInvoiceDto
     */
    creator: GetEmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceDto
     */
    creationTs: Date;
    /**
     * 
     * @type {string}
     * @memberof GetPurchaseInvoiceDto
     */
    comment: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPurchaseInvoiceDto
     */
    tv: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetPurchaseInvoiceDto
     */
    changeAmountsTs?: Date;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetPurchaseInvoiceDto
     */
    changeAmountsOwner?: GetEmployeeDto;
    /**
     * 
     * @type {GetSalesInvoiceDto}
     * @memberof GetPurchaseInvoiceDto
     */
    salesInvoice?: GetSalesInvoiceDto;
}


/**
 * @export
 */
export const GetPurchaseInvoiceDtoTypeEnum = {
    Foreign: 'Foreign',
    Domestic: 'Domestic'
} as const;
export type GetPurchaseInvoiceDtoTypeEnum = typeof GetPurchaseInvoiceDtoTypeEnum[keyof typeof GetPurchaseInvoiceDtoTypeEnum];

/**
 * @export
 */
export const GetPurchaseInvoiceDtoStatusEnum = {
    Open: 'Open',
    Approved: 'Approved',
    Sent: 'Sent'
} as const;
export type GetPurchaseInvoiceDtoStatusEnum = typeof GetPurchaseInvoiceDtoStatusEnum[keyof typeof GetPurchaseInvoiceDtoStatusEnum];

/**
 * @export
 */
export const GetPurchaseInvoiceDtoCurrencyEnum = {
    Pln: 'PLN',
    Usd: 'USD',
    Eur: 'EUR',
    Czk: 'CZK',
    Rub: 'RUB',
    Huf: 'HUF',
    Gbp: 'GBP',
    Bgn: 'BGN',
    Ron: 'RON',
    Hrk: 'HRK',
    Aud: 'AUD'
} as const;
export type GetPurchaseInvoiceDtoCurrencyEnum = typeof GetPurchaseInvoiceDtoCurrencyEnum[keyof typeof GetPurchaseInvoiceDtoCurrencyEnum];


/**
 * Check if a given object implements the GetPurchaseInvoiceDto interface.
 */
export function instanceOfGetPurchaseInvoiceDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "sellDate" in value;
    isInstance = isInstance && "issueDate" in value;
    isInstance = isInstance && "paymentDate" in value;
    isInstance = isInstance && "client" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "amountWithoutPosition" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "exchangeRate" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "creationTs" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "tv" in value;

    return isInstance;
}

export function GetPurchaseInvoiceDtoFromJSON(json: any): GetPurchaseInvoiceDto {
    return GetPurchaseInvoiceDtoFromJSONTyped(json, false);
}

export function GetPurchaseInvoiceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPurchaseInvoiceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'systemNumber': json['systemNumber'],
        'sellDate': (new Date(json['sellDate'])),
        'issueDate': (new Date(json['issueDate'])),
        'paymentDate': (new Date(json['paymentDate'])),
        'client': GetClientDtoFromJSON(json['client']),
        'amount': json['amount'],
        'amountWithoutPosition': json['amountWithoutPosition'],
        'active': json['active'],
        'companyId': json['companyId'],
        'type': json['type'],
        'status': json['status'],
        'currency': json['currency'],
        'exchangeRate': json['exchangeRate'],
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(GetProjectDtoFromJSON)),
        'teams': !exists(json, 'teams') ? undefined : ((json['teams'] as Array<any>).map(GetTeamDtoFromJSON)),
        'creator': GetEmployeeDtoFromJSON(json['creator']),
        'creationTs': (new Date(json['creationTs'])),
        'comment': json['comment'],
        'tv': json['tv'],
        'changeAmountsTs': !exists(json, 'changeAmountsTs') ? undefined : (new Date(json['changeAmountsTs'])),
        'changeAmountsOwner': !exists(json, 'changeAmountsOwner') ? undefined : GetEmployeeDtoFromJSON(json['changeAmountsOwner']),
        'salesInvoice': !exists(json, 'salesInvoice') ? undefined : GetSalesInvoiceDtoFromJSON(json['salesInvoice']),
    };
}

export function GetPurchaseInvoiceDtoToJSON(value?: GetPurchaseInvoiceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'systemNumber': value.systemNumber,
        'sellDate': (value.sellDate.toISOString()),
        'issueDate': (value.issueDate.toISOString()),
        'paymentDate': (value.paymentDate.toISOString()),
        'client': GetClientDtoToJSON(value.client),
        'amount': value.amount,
        'amountWithoutPosition': value.amountWithoutPosition,
        'active': value.active,
        'companyId': value.companyId,
        'type': value.type,
        'status': value.status,
        'currency': value.currency,
        'exchangeRate': value.exchangeRate,
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(GetProjectDtoToJSON)),
        'teams': value.teams === undefined ? undefined : ((value.teams as Array<any>).map(GetTeamDtoToJSON)),
        'creator': GetEmployeeDtoToJSON(value.creator),
        'creationTs': (value.creationTs.toISOString()),
        'comment': value.comment,
        'tv': value.tv,
        'changeAmountsTs': value.changeAmountsTs === undefined ? undefined : (value.changeAmountsTs.toISOString()),
        'changeAmountsOwner': GetEmployeeDtoToJSON(value.changeAmountsOwner),
        'salesInvoice': GetSalesInvoiceDtoToJSON(value.salesInvoice),
    };
}

