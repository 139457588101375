export default {
  app: {
    askDelete: 'Czy na pewno chcesz usunąć wniosek?',
    addUnblock: 'Utwórz wniosek o odblokowanie profilu miesięcznego',
    askBlockUnblockSale: 'Czy na pewno chcesz {{block}} sprzedaż?',
    askBlockUnblockPurchase: 'Czy na pewno chcesz {{block}} zakup?',
    unblock: 'odblokować',
    block: 'zablokować',
    unblockDoIt: 'Odblokuj',
    blockDoIt: 'Zablokuj',
    sale: 'sprzedaż',
    purchase: 'zakup',
    type: 'Typ',
    reason: 'Powód',
    rejectedNote: 'Uzasadnienie odrzucenia',
    description: 'Powód zgłoszenia',
    noApplicationUnblock:
      'Brak wniosków przypisanych do tego profilu miesięcznego',
    noUnblock: 'Brak wniosków o odblokowanie.',
    salesActiveBlock: 'Aktywna globalna blokada sprzedaży w miesiącu',
    purchaseActiveBlock: 'Aktywna globalna blokada zakupu w miesiącu',
    addUnblockApp:
      'Dodawanie nowego wniosku o odblokowanie profilu miesięcznego',
    editUnblockApp:
      'Edycja wniosku o odblokowanie profilu miesięcznego (nr sys. {{sysNumber}})',
    typeApplication: 'Typ zgłoszenia',
    salesCorrect: 'Zgłoszenie dotyczy korekty sprzedaży',
    isDocumentation: 'Zmiana ma potwierdzenie w dokumentacji',
    copyEmailContent: 'Skopiuj treść e-mail do schowka',
    alertBeforeAddingForeignClient: `↓ UWAGA: w związku ze zgłoszeniem zagranicznego dostawcy,
        prosimy o przesłanie poniższej wiadomości do kontrahenta w celu uzyskania wymaganych dokumentów ↓`,
    enterNIP: 'Proszę wprowadzić numer NIP.',
    editingApplication: `Edycja wniosku z {{createdDate}}`,
    addingNewApplication: 'Dodawanie nowego wniosku',
    creator: 'autor wniosku',
    applicationExecutionWithDate: 'Realizacja wniosku z {{createdDate}}',
    applicationData: 'Dane wniosku',
    applicationType: 'Typ wniosku',
    purchaserName: 'Nazwa zamawiającego',
    clientData: 'Dane kontrahenta',
    employeeAccess: 'Dostęp dla pracownika',
    employeeBoss: 'Szef wyrażający zgodę',
    applicationExecution: 'Realizacja wniosku',
    valueName: 'Nazwa wartości',
    brandName: 'Nazwa brandu',
    profilePosition: 'Pozycja profilu',
    sharedObjectType: 'Typ udostępnionego obiektu',
    sharedObject: 'Udostępniony obiekt',
    noApplications: 'Brak wniosków w systemie.',
    applications: 'Wnioski',
    newApplication: 'Nowy wniosek',
    my: 'Moje',
    companies: 'Spółki',
    announcements: 'Zgłoszenia',
    reportingContractsForWork: 'Zgłoszenie umów o dzieło',
    pending: 'Oczekujące',
    all: 'Wszystkie',
    archive: 'Archiwum',
    specialInvoicingApplicationExecution:
      'Realizacja wniosku o niestandardowe fakturowanie (nr sys. {{sysNumber}})',
    waiting: 'Oczekujące',
    waitingForCorrection: 'Oczekujące na korektę',
    specialInvoicingApplications: 'Wnioski o niestandardowe fakturowanie',
    specialInvoicingApplicationsRealization:
      'Wnioski o niestandardowe fakturowanie - realizacja',
    specialInvoicingApplicationDetails:
      'Szczegóły wniosku o niestandardowe fakturowanie (nr sys. {{sysNumber}})',
    specialInvoicingApplicationEdit:
      'Edycja wniosku o niestandardowe fakturowanie (nr sys. {{sysNumber}})',
    createSpecialInvoicingApplication:
      'Utwórz wniosek o niestandardowe fakturowanie',
    askDeleteApplication: 'Czy na pewno chcesz usunąć ten wniosek?',
    requiredChanges: 'Wymagane zmiany',
    acceptedFinanceNote: 'Zaakceptowany opis fakturowania',
    applicationDescription: 'Treść wniosku',
    financeNote: 'Opis fakturowania',
    noSpecialInvoiceApplications:
      'Brak wniosków o niestandardowe fakturowanie w systemie.',
    applicationExecutionWithDateNo:
      'Realizacja wniosku z {{createdDate}} nr sys. {{sysNumber}}',
    globalSalesMonthLock: 'Aktywna globalna blokada sprzedaży w miesiącu',
    globalPurchaseMonthLock: 'Aktywna globalna blokada zakupu w miesiącu',
    approved: 'Zatwierdzony',
    rejected: 'Odrzucony',
    delayed: 'Oczekujące po terminie',
    canceling: 'Oczekujące do anulowania',
    allDelayed: 'Wszystkie po terminie',
    allCanceling: 'Wszystkie do anulowania',
    toApplication: 'do wniosku',
    needReload: 'konieczne przelogowanie',
  },
} as const;
