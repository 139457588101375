import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';

import {
  CreateUpdateOrderDto,
  GetOrderWithCurrencyDto,
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/OrderContext';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { OrderStatusEnum } from '../../../common/pokCore/validation/enums';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { OrderEditor } from './OrderEditor';
import { OrderSearch } from './OrderSearch';

interface OrdersTabProps {
  project: GetProjectDto;
}

export const OrdersTab: React.FC<OrdersTabProps> = props => {
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [order, setOrder] = useState<GetOrderWithCurrencyDto>();
  const pok = usePokCore();
  const notifications = useNotifications();
  const nav = useNavigation();
  const { t, tk } = useTranslation('orderLng');

  const getData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.orders.getAllByPortionForProject(
          t,
          tk,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
          props.project.id,
          props.project.id,
          props.project.id,
        )) as GridGetterFunction,
    [pok.orders, props.project.id, t, tk],
  );

  const getCount = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.orders.getAllCountForProject(
          filterText,
          filterColumns,
          props.project.id,
          props.project.id,
        )) as GridCountFunction,
    [pok.orders, props.project.id],
  );

  const addEditOrder = async (projectCU: CreateUpdateOrderDto, id?: string) => {
    const status = await validate(projectCU);
    if (!status.valid) {
      return {
        saved: false,
        errors: status.errors,
      };
    }
    return saveProject(projectCU, id);
  };

  const saveProject = async (orderCU: CreateUpdateOrderDto, id?: string) => {
    try {
      if (id) {
        await pok.orders.update(id, orderCU);
      } else {
        const order = await pok.orders.create(orderCU);
        id = order.id;
      }
      void sendEmail(orderCU, id);
      setShow(false);
      handleRefresh();
      return {
        saved: true,
        errors: [],
      };
    } catch (err) {
      return {
        saved: false,
        errors: await responseErrors(err as ResponseError),
      };
    }
  };

  const sendEmail = async (orderCU: CreateUpdateOrderDto, id: string) => {
    if (orderCU.emails.length !== 0) {
      pok.emails.sendOrderEmail(id).catch(err => {
        notifications.caughtError(err);
      });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleNew = async () => {
    setOrder(undefined);
    setShow(true);
  };

  const handleClick = async (id: string, newWindow?: boolean) => {
    const fetchedOrder = await pok.orders.getById(id);
    if (
      fetchedOrder.resultProject?.id === props.project.id &&
      fetchedOrder.status === OrderStatusEnum.New
    ) {
      return nav.orderEditorByTeam(id, newWindow);
    }

    setOrder(fetchedOrder);
    setShow(true);
  };

  const handleRefresh = () => setRefresh(!refresh);

  const deactivateOrder = async (id: string) => {
    try {
      await pok.orders.deactivate(id);
      notifications.saveCompleted();
      handleClose();
      handleRefresh();
      return {
        saved: true,
        errors: [],
      };
    } catch (err) {
      return {
        saved: false,
        errors: await responseErrors(err as ResponseError),
      };
    }
  };

  return (
    <>
      <Button variant="outline-primary" onClick={handleNew}>
        {t(tk.order.newOrder)}
      </Button>
      <OrderSearch
        getData={getData}
        getCount={getCount}
        onRowClick={handleClick}
        refresh={refresh}
        isProjectView
      />
      {show && (
        <OrderEditor
          id={order?.id}
          systemNumber={order?.systemNumber}
          project={props.project}
          resultProject={order?.resultProject}
          order={convert(order, props.project.id)}
          onCompleted={addEditOrder}
          show={show}
          handleClose={handleClose}
          status={order?.status}
          rejectedNote={order?.rejectedNote}
          orderEstimateItems={order?.orderEstimateItems}
          deactivateOrder={deactivateOrder}
          readOnly={
            order &&
            (!order.active ||
              (order?.status as OrderStatusEnum) !== OrderStatusEnum.New)
          }
          currencyCode={order?.currencyCode}
        />
      )}
    </>
  );
};
