import React from 'react';
import { NumericFormat } from 'react-number-format';

import mathUtils from '../../utils/mathUtils';
import currencyFormatUtils from '../../utils/currencyFormatUtils';
import { useTranslation } from '../../common/hooks/useTranslation';

export default (props: {
  allowNegative?: boolean;
  value: string | number | null | undefined;
  percentage?: boolean;
  decimalScale?: number;
  noThousandSeparator?: boolean;
  disabled?: boolean;
  className?: string;
  countryCode?: string;
  currencyCode?: string;
  onChange?: (value: string | null) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  max?: number;
}) => {
  const currencyItem = sessionStorage.getItem('pok-currency');
  const { t, tk } = useTranslation('commonLng');

  if (!currencyItem) {
    throw new Error(t(tk.component.noCurrency));
  }
  const currency = JSON.parse(currencyItem);
  const countryCode = props.countryCode ?? currency?.countryCode;
  const currencyCode = props.currencyCode ?? currency?.currencyCode;

  const thousandSeparator =
    currencyFormatUtils.getThousandSeparator(countryCode);
  const decimalSeparator = currencyFormatUtils.getDecimalSeparator(countryCode);
  const currencySymbol = currencyFormatUtils.getCurrencySymbol(
    currencyCode,
    countryCode,
  );

  let suffix = '';
  let prefix = '';
  if (props.percentage) {
    suffix = ' %';
  } else {
    if (
      currencyFormatUtils.isCurrencySymbolBeforeAmount(
        currencyCode,
        countryCode,
      )
    ) {
      prefix = `${currencySymbol} `;
    } else {
      suffix = ` ${currencySymbol}`;
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      if (e.target.value === '') {
        props.onChange(null);
        return;
      }
      let formattedValues = e.target.value;
      if (decimalSeparator === ',' && thousandSeparator === '.') {
        formattedValues = e.target.value
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/[^0-9.-]/g, '');
      } else if (decimalSeparator === ',') {
        formattedValues = e.target.value
          .replace(',', '.')
          .replace(/[^0-9.-]/g, '');
      } else if (decimalSeparator === '.') {
        formattedValues = e.target.value.replace(/[^0-9.-]/g, '');
      }
      if (
        formattedValues !== '-' &&
        ((!props.max && props.max !== 0) ||
          mathUtils.parse(formattedValues) <= props.max)
      ) {
        props.onChange(formattedValues);
      }
    }
  };

  return (
    <>
      <NumericFormat
        className={`form-control ${props.className ?? ''}`}
        value={props.value}
        suffix={suffix ?? ''}
        prefix={prefix}
        thousandSeparator={props.noThousandSeparator ? '' : thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={props.decimalScale ?? 4}
        disabled={props.disabled}
        onChange={e => onChange(e)}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        isAllowed={({ floatValue }) =>
          (!props.max && props.max !== 0) ||
          !floatValue ||
          floatValue <= props.max
        }
        allowNegative={props.allowNegative ?? false}
      />
    </>
  );
};
