import type { TFunction } from 'i18next';

import {
  CreateUpdateBlockadeMonthDto,
  CreateUpdateBlockadeMonthDtoFromJSON,
  DefaultApi,
  GetBlockadeMonthDto,
  GetLastEditorDto,
} from '../autogenerated/pokApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { FilterColumn } from '../../types/FilterColumn';
import { blockadeMonthForGrid } from '../../../pok/components/BlockadeMonth/BlockadeMonthSearch';
import { BlockadeMonthSchema } from '../validation/schemas';
import { TKeys } from '../../hooks/useTranslation';

export interface BlockadeMonthContextInterface {
  getById: (id: string) => Promise<GetBlockadeMonthDto>;
  getAllByPortion: (
    t: TFunction<'projectLng', 'projectLng'>,
    tk: TKeys<'projectLng'>,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdateBlockadeMonthDto) => Promise<void>;
  create: (dto: CreateUpdateBlockadeMonthDto) => Promise<GetBlockadeMonthDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  deactivate: (id: string) => Promise<void>;
  checkBlockedMonth: (
    date: Date,
    companyId: string,
    teamIds: string[],
    isSalesBlocked: boolean,
    isPurchaseBlocked: boolean,
  ) => Promise<boolean>;
}

export const BlockadeMonthContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.blockadeMonthControllerGet(id),
    getAllByPortion: async (
      t: TFunction<'projectLng', 'projectLng'>,
      tk: TKeys<'projectLng'>,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.blockadeMonthControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );

      return data.map(item => blockadeMonthForGrid(t, tk, item));
    },
    getAllCount: async (filterText?: string, filterColumns?: FilterColumn) =>
      api.blockadeMonthControllerGetAllCount(filterText || '', filterColumns),
    update: (id: string, dto: CreateUpdateBlockadeMonthDto) =>
      api.blockadeMonthControllerUpdate(id, dto),
    create: (dto: CreateUpdateBlockadeMonthDto) =>
      api.blockadeMonthControllerCreate(dto),
    getLastEditor: (id: string) => api.blockadeMonthControllerGetLastEditor(id),
    deactivate: (id: string) => api.blockadeMonthControllerDeactivate(id),
    checkBlockedMonth: (
      date: Date,
      companyId: string,
      teamIds: string[],
      isSalesBlocked: boolean,
      isPurchaseBlocked: boolean,
    ) =>
      api.blockadeMonthControllerCheckBlockedMonth(
        date,
        companyId,
        teamIds,
        isSalesBlocked,
        isPurchaseBlocked,
      ),
  };
};

export const newBlockadeMonth = (companyId?: string) => {
  return CreateUpdateBlockadeMonthDtoFromJSON({
    companyId,
    isSalesBlocked: false,
    isPurchaseBlocked: false,
    active: true,
  });
};

export const validate = (applicationUnblock: CreateUpdateBlockadeMonthDto) =>
  validateAgainst(BlockadeMonthSchema, applicationUnblock);

export const convert = (blockade?: GetBlockadeMonthDto, companyId?: string) => {
  if (!blockade) {
    return newBlockadeMonth(companyId);
  }

  const converted = CreateUpdateBlockadeMonthDtoFromJSON(blockade);
  converted.companyId = blockade.company.id;
  converted.teamIds =
    blockade.blockadeMonthTeams?.map(({ team }) => team.id) || [];

  return converted;
};
