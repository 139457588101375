import type { TFunction } from 'i18next';

import { AuthProviderState } from '../../auth';
import { TKeys } from '../../hooks/useTranslation';
import { PermissionsEnum } from '../authorization/permissions';
import {
  DefaultApi,
  MemoControllerGetSubjectThreadStatsSubjectTypeEnum,
} from '../autogenerated/pokApiClient/apis/DefaultApi';
import {
  CreatePrivateMemoDto,
  CreatePublicMemoDto,
  CreatePublicMemoDtoEnSubjectTypeEnum,
  GetEmployeeDto,
  GetMemoDto,
  GetProjectDto,
  GetPurchaseInvoiceDto,
  GetSalesInvoiceDto,
  GetSpecialInvoicingDto,
  GetSubjectThreadStatsDto,
  GetThreadDto,
  GetThreadsStatsDto,
} from '../autogenerated/pokApiClient/models';
import { ThreadChangedArgs } from '../sockets/socket.datatypes';
import { PrivateMemoSchema, PublicMemoSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export type SubjectType =
  | GetProjectDto
  | GetPurchaseInvoiceDto
  | GetSalesInvoiceDto
  | GetSpecialInvoicingDto;

export enum ThreadsFilterEnum {
  all,
  privates,
  subjects,
}

export interface MemoContextInterface {
  createPrivateMemo: (dto: CreatePrivateMemoDto) => Promise<GetMemoDto>;
  createPublicMemo: (
    dto: CreatePublicMemoDto,
    employeeId?: string,
  ) => Promise<GetMemoDto>;
  getThreadsForUser: (
    count: number,
    filter: ThreadsFilterEnum,
  ) => Promise<GetThreadDto[]>;
  getThreadsForUserStats: () => Promise<GetThreadsStatsDto>;
  getThreadMemos: (threadId: string, count: number) => Promise<GetMemoDto[]>;
  getSubjectThreadStats: (
    subject: SubjectType,
  ) => Promise<GetSubjectThreadStatsDto>;
  quickMemo: (
    content: string,
    sender: GetEmployeeDto,
    thread: GetThreadDto,
  ) => Promise<void>;
}

export const MemoContext = (api: DefaultApi) => ({
  createPrivateMemo: (dto: CreatePrivateMemoDto) =>
    api.memoControllerCreatePrivateMemo(dto),
  createPublicMemo: (dto: CreatePublicMemoDto, employeeId?: string) =>
    api.memoControllerCreatePublicMemo(dto, employeeId),
  getThreadsForUser: (count: number, filter: ThreadsFilterEnum) =>
    api.memoControllerGetThreadsForUser(count, filter),
  getThreadsForUserStats: () => api.memoControllerGetThreadsForUserStats(),
  getThreadMemos: (threadId: string, count: number) =>
    api.memoControllerGetThreadMemos(threadId, count),
  getSubjectThreadStats: (subject: SubjectType) =>
    api.memoControllerGetSubjectThreadStats(
      subjectType(
        subject,
      ) as unknown as MemoControllerGetSubjectThreadStatsSubjectTypeEnum,
      subject.id,
    ),
  quickMemo: async (
    content: string,
    sender: GetEmployeeDto,
    thread: GetThreadDto,
  ) => {
    if (!thread.subject) {
      await api.memoControllerCreatePrivateMemo({
        recipientId: getRecipient(sender, thread).id,
        content,
      });
    } else {
      const subject = thread.subject as SubjectType;
      if (!subject) {
        console.error('Current thread has neither recipient nor subject');
        return;
      }
      await api.memoControllerCreatePublicMemo({
        enSubjectType: thread.subjectType,
        subjectId: subject.id,
        content,
      });
    }
  },
});

export const validatePrivateMemo = (dto: CreatePrivateMemoDto) =>
  validateAgainst(PrivateMemoSchema, dto);

export const validatePublicMemo = (dto: CreatePublicMemoDto) =>
  validateAgainst(PublicMemoSchema, dto);

export const subjectType = (subject: SubjectType) => {
  if ('name' in subject) {
    return CreatePublicMemoDtoEnSubjectTypeEnum.Project;
  } else if ('issueDate' in subject) {
    return CreatePublicMemoDtoEnSubjectTypeEnum.PurchaseInvoice;
  } else if ('saleDate' in subject) {
    return CreatePublicMemoDtoEnSubjectTypeEnum.SaleInvoice;
  } else if ('correctionNote' in subject) {
    return CreatePublicMemoDtoEnSubjectTypeEnum.SpecialInvoicing;
  }

  throw new Error('Cannot find out subject type');
};

export const polishAblative = (
  t: TFunction<'commonLng', 'commonLng'>,
  tk: TKeys<'commonLng'>,
  subject?: SubjectType,
) => {
  if (!subject) {
    return '';
  }

  const type = subjectType(subject);
  switch (type) {
    case CreatePublicMemoDtoEnSubjectTypeEnum.Project:
      return t(tk.prLang.thisProject);
    case CreatePublicMemoDtoEnSubjectTypeEnum.PurchaseInvoice:
      return t(tk.concurrent.thisFVZ);
    case CreatePublicMemoDtoEnSubjectTypeEnum.SaleInvoice:
      return t(tk.concurrent.thisFVS);
    case CreatePublicMemoDtoEnSubjectTypeEnum.SpecialInvoicing:
      return t(tk.concurrent.thisNotStandardInvoices);
  }
};

export const navigateTo = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nav: any,
  subject?: SubjectType,
  auth?: AuthProviderState,
) => {
  if (!subject) {
    return;
  }
  const type = subjectType(subject);
  switch (type) {
    case CreatePublicMemoDtoEnSubjectTypeEnum.Project:
      nav.project(subject.id);
      break;
    case CreatePublicMemoDtoEnSubjectTypeEnum.PurchaseInvoice:
      nav.purchaseInvoice(subject.id);
      break;
    case CreatePublicMemoDtoEnSubjectTypeEnum.SaleInvoice:
      nav.saleInvoice(subject.id);
      break;
    case CreatePublicMemoDtoEnSubjectTypeEnum.SpecialInvoicing:
      if (auth) {
        if (auth.check(PermissionsEnum.Finance)) {
          nav.specialInvoicingDecisionEditor(subject.id);
        } else {
          nav.specialInvoicingEditor(subject.id);
        }
      }
      break;
  }
};

export const getRecipient = (sender: GetEmployeeDto, thread: GetThreadDto) => {
  if (thread.subject || thread.sender.id !== sender.id) {
    return thread.sender;
  }
  if (thread.recipient?.id !== sender.id) {
    return thread.recipient;
  }
  return thread.sender; // memo to yourself
};

export const unreadThread = (th?: GetThreadDto) => {
  return th && th.newMessagesCount > 0 && th.participatedCount > 0;
};

export const sameStats = (
  s1?: GetSubjectThreadStatsDto,
  s2?: GetSubjectThreadStatsDto,
) => {
  return (
    s1?.threadId === s2?.threadId &&
    s1?.messagesCount === s2?.messagesCount &&
    s1?.newMessagesCount === s2?.newMessagesCount
  );
};

export const setThreadAsRead = (threads: GetThreadDto[], threadId: string) => {
  return threads.map(th => {
    if (th.id === threadId) {
      th.newMessagesCount = 0;
    }
    return th;
  });
};

export const subjectMatch = (subject: SubjectType, args: ThreadChangedArgs) => {
  return (
    args.subjectId === subject.id &&
    (args.subjectType as unknown as CreatePublicMemoDtoEnSubjectTypeEnum) ===
      subjectType(subject)
  );
};

export const firstSubject = (memos: GetMemoDto[]) => {
  if (memos.length === 0) {
    return undefined;
  }
  return memos[0].subject as SubjectType;
};
