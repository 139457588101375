import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { CreateUpdateProjectWithTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { Option } from '../../components/Selects/Selector';
import TeamSelector from '../../components/Selects/TeamSelector';
import { ProjectApplicationTypeEnum } from '../../../common/pokCore/validation/enums';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { NewDatePicker } from '../../../app/components/NewDatePicker';

interface ProjectsApplicationDetailsEditFormProps {
  project?: CreateUpdateProjectWithTeamDto;
  applicationType: ProjectApplicationTypeEnum;
  purchaserName: string;
  brandName: string;
  readOnly?: boolean;
  status: Option;
  onStatusChange: (status: Option) => void;
  savedRejectedNote?: string;
  rejectedNote?: string;
  onRejectedNoteChange: (rejectedNote: string | undefined) => void;
}

const getValidStatus = (
  status: Option,
  type: ProjectApplicationTypeEnum,
  statusOptions: Option[],
  savedRejectedNote?: string,
  active?: boolean,
) => {
  const possibleOptions = getStatusOptionForApplicationType(
    type,
    statusOptions,
    savedRejectedNote,
    active,
  );

  if (possibleOptions.map(o => o.value).indexOf(status.value) >= 0) {
    return status;
  }
  if (possibleOptions.length) {
    return possibleOptions[0];
  }
  return undefined;
};

const getStatusOptionForApplicationType = (
  type: ProjectApplicationTypeEnum,
  statusOptions: Option[],
  savedRejectedNote?: string,
  active?: boolean,
) => {
  switch (type) {
    case ProjectApplicationTypeEnum.Delayed:
      if (active === false) {
        return [statusOptions[0]];
      }
      if (!savedRejectedNote) {
        return statusOptions;
      }
      break;
    case ProjectApplicationTypeEnum.ToCancel:
      if (active === false) {
        return [statusOptions[1]];
      }
      break;
  }
  return statusOptions;
};

export const ProjectsApplicationDetailsEditForm: React.FC<
  ProjectsApplicationDetailsEditFormProps
> = props => {
  const { t, tk } = useTranslation('applicationLng');
  const statusOptions = [
    { value: 'approved', label: t(tk.app.approved) },
    { value: 'rejected', label: t(tk.app.rejected) },
  ];
  const isStatusReversible =
    !props.project?.active &&
    (props.applicationType === ProjectApplicationTypeEnum.Delayed
      ? props.savedRejectedNote !== undefined
      : true);

  const chooseAndUpdateStatus = (): Option | null => {
    const newStatus = getValidStatus(
      props.status,
      props.applicationType,
      statusOptions,
      props.savedRejectedNote,
      props.project?.active,
    );
    if (newStatus && newStatus.value !== props.status.value) {
      props.onStatusChange(newStatus);
    }

    if (!newStatus) {
      return null;
    }
    return newStatus;
  };

  return (
    <Form className="d-grid gap-3">
      <FormRow controlId="team" label={t(tk.common.team)}>
        <TeamSelector
          value={props.project?.teamIds}
          companyId={props.project?.companyId}
          isMulti={true}
          readOnly={true}
        />
      </FormRow>
      <FormRow controlId="purchaser" label={t(tk.common.purchaser)}>
        <DynamicTextarea value={props.purchaserName} disabled={true} />
      </FormRow>
      <FormRow controlId="brand" label={t(tk.common.brand)}>
        <DynamicTextarea value={props.brandName} disabled={true} />
      </FormRow>
      <FormRow controlId="startDate" label={t(tk.common.startDate)}>
        <NewDatePicker value={props.project?.startDate} readOnly={true} />
      </FormRow>
      <FormRow controlId="endDate" label={t(tk.common.endDate)}>
        <NewDatePicker value={props.project?.endDate} readOnly={true} />
      </FormRow>
      <FormRow
        controlId="delayMessage"
        label={t(tk.app.applicationDescription)}
      >
        <DynamicTextarea
          value={
            props.applicationType === ProjectApplicationTypeEnum.Delayed
              ? props.project?.delayMessage
              : props.project?.cancelMessage
          }
          disabled={true}
        />
      </FormRow>
      {props.savedRejectedNote && (
        <FormRow controlId="rejectedNote" label={t(tk.app.rejectedNote)}>
          <DynamicTextarea
            value={props.savedRejectedNote || ''}
            disabled={true}
          />
        </FormRow>
      )}
      {props.readOnly && !isStatusReversible ? null : (
        <>
          <hr />
          <FormRow controlId="status" label={t(tk.common.status)}>
            <Select
              value={chooseAndUpdateStatus()}
              options={getStatusOptionForApplicationType(
                props.applicationType,
                statusOptions,
                props.savedRejectedNote,
                props.project?.active,
              )}
              onChange={option => {
                props.onStatusChange(option || statusOptions[0]);
              }}
            />
          </FormRow>
          {props.status.value === statusOptions[1].value && (
            <FormRow controlId="rejectedNote" label={t(tk.app.rejectedNote)}>
              <DynamicTextarea
                value={props.rejectedNote || ''}
                onChange={e =>
                  props.onRejectedNoteChange(e.target.value || undefined)
                }
              />
            </FormRow>
          )}
        </>
      )}
    </Form>
  );
};
