export default {
  enum: {
    position: 'Pozycja (dostawca)',
    purchaser: 'Zamawiający (klient)',
    dictionaryValue: 'Wartość słownikowa',
    brand: 'Brand',
    permission: 'Uprawnienie (dostęp)',

    projects: 'Projekty',
    campaigns: 'Kampanie',

    pl: 'Polski',
    en: 'Angielski',

    standard: 'Standardowa',
    commission: 'Prowizja',
    transferredCommission: 'Prowizja-Transferowana',

    new: 'Nowy',
    completed: 'Zrealizowany',
    accepted: 'Zaakceptowany',
    rejected: 'Odrzucony',

    notSent: 'Niewysłany',
    sent: 'Wysłany',
    error: 'Błąd',
    ok: 'OK',
    duplicate: 'Duplikat',

    acceptedByClient: 'Zaakceptowany przez klienta',
    readyForInvoice: 'Gotowy do fakturowania',
    invoiced: 'Zafakturowany',
    corrected: 'Korygowany',
    settled: 'Rozliczony',
    cancelled: 'Anulowany',

    foreign: 'Zagraniczna',
    domestic: 'Krajowa',

    draft: 'Szkic',
    generated: 'Zatwierdzona',
    sentShe: 'Wysłana',
    paid: 'Zapłacona',

    open: 'Wprowadzona',
    approved: 'Zatwierdzona',

    bankTransfer: 'Przelew',
    compensation: 'Kompensata',
    prepayment: 'Przedpłata',
    paidIt: 'Zapłacono',

    newPlural: 'Nowe',
    acceptedPlural: 'Zaakceptowane',
    rejectedPlural: 'Odrzucone',
    toBeCorrected: 'Wymaga korekty',

    budget: 'Budżetem',
    project: 'Projektem',
    purchaseInvoice: 'Fakturą sprzedaży',
    estimateItemCommentMediaplanner:
      'Komentarzem mediaplanera do pozycji w kampanii',
    estimateItemCommentFinance: 'Komentarzem finansów do pozycji w kampanii',

    sales: 'Sprzedaż',
    purchase: 'Zakup',

    projectNoun: 'Kampania',
    purchaseInvoiceNoun: 'Faktura zakupu',
    saleInvoiceNoun: 'Faktura sprzedaży',
    specialInvoicingNoun: 'Niestandardowe fakturowanie',

    delayed: 'Opóźniony',
    toCancel: 'Do anulowania',
    all: 'Wszystkie',

    others: 'Standardowy',
    tvBuying: 'TV Zakup',
    MPC: 'MPC',

    applications: 'Wniosek',
    positionAppForeign: 'Wniosek o pozycję zagraniczną',
    applicationExecutions: 'Realizacja wniosku',
    orders: 'Zamówienie',
    ordersRemind: 'Zamówienie - przypomnienie',
    orderExecutions: 'Realizacja zamówienia',
    orderExecutionsRemind: 'Realizacja zamówienia - przypomnienie',
    unblockEstimateByMonth: 'Wniosek o odblokowanie sprzedaży/zakupu',
    projectsDelayed: 'Założenie kampanii/projektu po terminie',
    projectsToCancel: 'Anulowanie kampanii/projektu',
    purchaseDeficiencies: 'Wyjaśnianie braków zakupowych',
    specialInvoicing: 'Niestandardowe fakturowanie',

    orderExecutionReminder: 'Realizacja zamówienia - przypomnienie',
    orderReminder: 'Zamówienie - przypomnienie',
    dailyFVZReminder: 'FVZ - przypomnienie dzienne',
    weekFVZReminder: 'FVZ - przypomnienie tygodniowe',

    poland: 'Polska',
    unitedStates: 'Stany Zjednoczone',
    germany: 'Niemcy',
  },
} as const;
