/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPositionDto } from './GetPositionDto';
import {
    GetPositionDtoFromJSON,
    GetPositionDtoFromJSONTyped,
    GetPositionDtoToJSON,
} from './GetPositionDto';
import type { ParametersSchemaDto } from './ParametersSchemaDto';
import {
    ParametersSchemaDtoFromJSON,
    ParametersSchemaDtoFromJSONTyped,
    ParametersSchemaDtoToJSON,
} from './ParametersSchemaDto';

/**
 * 
 * @export
 * @interface GetExcelConfigPositionDto
 */
export interface GetExcelConfigPositionDto {
    /**
     * 
     * @type {string}
     * @memberof GetExcelConfigPositionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelConfigPositionDto
     */
    excelPositionName: string;
    /**
     * 
     * @type {GetPositionDto}
     * @memberof GetExcelConfigPositionDto
     */
    position: GetPositionDto;
    /**
     * 
     * @type {ParametersSchemaDto}
     * @memberof GetExcelConfigPositionDto
     */
    parameters: ParametersSchemaDto;
    /**
     * 
     * @type {string}
     * @memberof GetExcelConfigPositionDto
     */
    excelConfigId: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetExcelConfigPositionDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetExcelConfigPositionDto
     */
    selectedParametersPl: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelConfigPositionDto
     */
    selectedParametersEn: string;
}

/**
 * Check if a given object implements the GetExcelConfigPositionDto interface.
 */
export function instanceOfGetExcelConfigPositionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "excelPositionName" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "parameters" in value;
    isInstance = isInstance && "excelConfigId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "selectedParametersPl" in value;
    isInstance = isInstance && "selectedParametersEn" in value;

    return isInstance;
}

export function GetExcelConfigPositionDtoFromJSON(json: any): GetExcelConfigPositionDto {
    return GetExcelConfigPositionDtoFromJSONTyped(json, false);
}

export function GetExcelConfigPositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExcelConfigPositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'excelPositionName': json['excelPositionName'],
        'position': GetPositionDtoFromJSON(json['position']),
        'parameters': ParametersSchemaDtoFromJSON(json['parameters']),
        'excelConfigId': json['excelConfigId'],
        'active': json['active'],
        'selectedParametersPl': json['selectedParametersPl'],
        'selectedParametersEn': json['selectedParametersEn'],
    };
}

export function GetExcelConfigPositionDtoToJSON(value?: GetExcelConfigPositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'excelPositionName': value.excelPositionName,
        'position': GetPositionDtoToJSON(value.position),
        'parameters': ParametersSchemaDtoToJSON(value.parameters),
        'excelConfigId': value.excelConfigId,
        'active': value.active,
        'selectedParametersPl': value.selectedParametersPl,
        'selectedParametersEn': value.selectedParametersEn,
    };
}

