export default {
  menu: {
    report: 'Report',
    reports: 'Reports',
    dataDownload: 'Data is being downloaded. Please wait...',
  },
  filter: {
    invoiceType: 'Invoice type',
    invoiceStatus: 'Invoice status',
    dateSale: 'Sale date',
    datePurchase: 'Purchase date',
    dateInvoices: 'Invoice issue date',
    yearGive: 'Please provide the year.',
  },
  finance: {
    purchaseInvoices: 'Purchase invoices',
    salesInvoices: 'Sales invoices',
    FVS: 'FVS',
    FVZ: 'FVZ',
    budgetReport: 'Budget report',
    estimates: 'Mediaplan positions',
    controling: 'Controlling',
    FVZWithEstimates: 'FVZ - assignment to mediaplan positions',
    FVZWithEstimatesLong: 'Purchase invoices linked with mediaplan positions',
    FVZWithEstimatesShort: 'FVZ, mediaplan positions',
    FVSControling: 'FVS - report for controlling',
    FVSControlingLong: 'Sales invoices divided by the month of attachment',
    FVSControlingShort: 'FVS, mediaplan positions',
    reportFVS: 'SalesInvoiceReport',
    reportFVZ: 'PurchaseInvoiceReport',
    reportFVSByMonth: 'Sales Invoice Summary by Month',
    reportFVZLong: 'Purchase Invoice Summary',
    reportFVZByPosition: 'Purchase Invoice Summary by Mediaplan Position',
    reportControlFVZ: 'PurchaseInvoiceControlReport',
    reportControlFVS: 'SaleInvoiceControlReport',
    reportToBudget: 'BudgetReport',
    reportFVSLong: 'Sales Invoice Summary',
    reportFVSWithPositions: 'Sales Invoice Summary with Position Names',
  },
  administration: {
    estimatesPositions: 'Mediaplan positions',
    rawData: 'RawData',
    extensiveDataFromEstimatesByMonth:
      'Extensive data from mediaplan positions',
  },
  controlling: {
    positionName: 'Item name',
    clientName: 'Contractor name',
    format: 'Format',
    salesNet: 'Net sales',
    salesCommissionAmount: 'Sales commission amount',
    salesNetTotal: 'Total net sales',
    responsiblePerson: 'Responsible person',
    mpSigned: 'MP signed',
    attachmentOrder: 'Order to the contractor',
    acceptedOrderExec: 'Accepted order execution',
    clientFV: 'Invoice from contractor',
    noPositions: 'No items to display',
    control: 'Control',
    genitiveDocuments: 'documents',
    documentList: 'Document control summary',
    for: 'for',
    documentControl: 'Document control',
  },
} as const;
