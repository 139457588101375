/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetClientDto } from './GetClientDto';
import {
    GetClientDtoFromJSON,
    GetClientDtoFromJSONTyped,
    GetClientDtoToJSON,
} from './GetClientDto';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetDictionaryDto } from './GetDictionaryDto';
import {
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './GetDictionaryDto';
import type { GetProfileDto } from './GetProfileDto';
import {
    GetProfileDtoFromJSON,
    GetProfileDtoFromJSONTyped,
    GetProfileDtoToJSON,
} from './GetProfileDto';
import type { GetTeamDto } from './GetTeamDto';
import {
    GetTeamDtoFromJSON,
    GetTeamDtoFromJSONTyped,
    GetTeamDtoToJSON,
} from './GetTeamDto';

/**
 * 
 * @export
 * @interface GetPositionExtendedDto
 */
export interface GetPositionExtendedDto {
    /**
     * 
     * @type {string}
     * @memberof GetPositionExtendedDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetPositionExtendedDto
     */
    systemNumber: number;
    /**
     * 
     * @type {GetClientDto}
     * @memberof GetPositionExtendedDto
     */
    client: GetClientDto;
    /**
     * 
     * @type {string}
     * @memberof GetPositionExtendedDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPositionExtendedDto
     */
    groupName: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetPositionExtendedDto
     */
    dictionary: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetPositionExtendedDto
     */
    positionType: GetPositionExtendedDtoPositionTypeEnum;
    /**
     * 
     * @type {GetTeamDto}
     * @memberof GetPositionExtendedDto
     */
    team?: GetTeamDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetPositionExtendedDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPositionExtendedDto
     */
    notPurchaseDeficiencies: boolean;
    /**
     * 
     * @type {Array<GetCompanyDto>}
     * @memberof GetPositionExtendedDto
     */
    companies?: Array<GetCompanyDto>;
    /**
     * 
     * @type {Array<GetTeamDto>}
     * @memberof GetPositionExtendedDto
     */
    teams?: Array<GetTeamDto>;
    /**
     * 
     * @type {Array<GetProfileDto>}
     * @memberof GetPositionExtendedDto
     */
    profiles?: Array<GetProfileDto>;
}


/**
 * @export
 */
export const GetPositionExtendedDtoPositionTypeEnum = {
    Standard: 'Standard',
    Commission: 'Commission',
    TransferredCommission: 'TransferredCommission'
} as const;
export type GetPositionExtendedDtoPositionTypeEnum = typeof GetPositionExtendedDtoPositionTypeEnum[keyof typeof GetPositionExtendedDtoPositionTypeEnum];


/**
 * Check if a given object implements the GetPositionExtendedDto interface.
 */
export function instanceOfGetPositionExtendedDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "client" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "groupName" in value;
    isInstance = isInstance && "dictionary" in value;
    isInstance = isInstance && "positionType" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "notPurchaseDeficiencies" in value;

    return isInstance;
}

export function GetPositionExtendedDtoFromJSON(json: any): GetPositionExtendedDto {
    return GetPositionExtendedDtoFromJSONTyped(json, false);
}

export function GetPositionExtendedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPositionExtendedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'systemNumber': json['systemNumber'],
        'client': GetClientDtoFromJSON(json['client']),
        'name': json['name'],
        'groupName': json['groupName'],
        'dictionary': GetDictionaryDtoFromJSON(json['dictionary']),
        'positionType': json['positionType'],
        'team': !exists(json, 'team') ? undefined : GetTeamDtoFromJSON(json['team']),
        'active': json['active'],
        'notPurchaseDeficiencies': json['notPurchaseDeficiencies'],
        'companies': !exists(json, 'companies') ? undefined : ((json['companies'] as Array<any>).map(GetCompanyDtoFromJSON)),
        'teams': !exists(json, 'teams') ? undefined : ((json['teams'] as Array<any>).map(GetTeamDtoFromJSON)),
        'profiles': !exists(json, 'profiles') ? undefined : ((json['profiles'] as Array<any>).map(GetProfileDtoFromJSON)),
    };
}

export function GetPositionExtendedDtoToJSON(value?: GetPositionExtendedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'systemNumber': value.systemNumber,
        'client': GetClientDtoToJSON(value.client),
        'name': value.name,
        'groupName': value.groupName,
        'dictionary': GetDictionaryDtoToJSON(value.dictionary),
        'positionType': value.positionType,
        'team': GetTeamDtoToJSON(value.team),
        'active': value.active,
        'notPurchaseDeficiencies': value.notPurchaseDeficiencies,
        'companies': value.companies === undefined ? undefined : ((value.companies as Array<any>).map(GetCompanyDtoToJSON)),
        'teams': value.teams === undefined ? undefined : ((value.teams as Array<any>).map(GetTeamDtoToJSON)),
        'profiles': value.profiles === undefined ? undefined : ((value.profiles as Array<any>).map(GetProfileDtoToJSON)),
    };
}

