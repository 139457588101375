import React, { useMemo, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import type { TFunction } from 'i18next';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetSalesInvoiceForItem } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';
import {
  InvoiceStatusEnum,
  InvoiceStatusObj,
} from '../../../common/pokCore/validation/enums';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { SalesInvoicesGrid } from './SalesInvoicesGrid';
import { OpenSaleInvoicePrintLink } from './OpenSaleInvoicePrintLink';

interface SalesInvoicesTabProps {
  estimateItemId?: string;
}

export const SalesInvoicesTab: React.FC<SalesInvoicesTabProps> = props => {
  const [salesInvoices, setSalesInvoices] = useState<GetSalesInvoiceForItem[]>(
    [],
  );

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t } = useTranslation('fvsLng');

  useMemo(() => {
    if (props.estimateItemId) {
      pok.salesInvoices
        .getByEstimateItem(props.estimateItemId)
        .then(result => {
          if (result) {
            setSalesInvoices(result);
          }
        })
        .catch(error => {
          notifications.caughtError(error);
        });
    }
  }, [notifications, pok.salesInvoices, props.estimateItemId]);

  const generateData = (t: TFunction<'fsvLng', 'fsvLng'>) => {
    const data = salesInvoices.map(invoice => {
      return {
        key: invoice.id,
        values: {
          'salesInvoice.number': invoice.salesInvoice.number,
          'salesInvoice.invoiceDate': momencik(
            invoice.salesInvoice.invoiceDate,
          ),
          'salesInvoice.systemNumber': invoice.salesInvoice.systemNumber,
          'salesInvoice.amount': numberFormatter(invoice.amount, true),
          'client.name': invoice.salesInvoice.purchaser?.client?.name,
          'salesInvoice.status': t(
            InvoiceStatusObj[invoice.salesInvoice.status],
          ),
          'employee.name': `${invoice.owner?.name} (${momencik(
            invoice.ts,
            'LLL',
          )})`,
          preview: (
            <OpenSaleInvoicePrintLink
              id={invoice.salesInvoice.id}
              status={
                invoice.salesInvoice.status as unknown as InvoiceStatusEnum
              }
              icon={Icon.FilePdf}
            />
          ),
        },
      };
    });
    return data;
  };

  return <SalesInvoicesGrid data={generateData(t)} />;
};
