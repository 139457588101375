export default {
  finance: {
    salesInvoices: 'Faktury sprzedaży',
    FVS: 'FVS',
    lastModification: 'Ostatnia modyfikacja do zatwierdzenia',
    verification: 'Weryfikacja',
    amountToCorrect: 'Kwota do korekty',
    amountToInvoice: 'Kwota do zafakturowania',
    forContractor: 'w ramach klienta',
    andBudget: 'i budżetu',
    toInvoice: 'do faktury',
    askCreateInvoiceDraft:
      'Uwaga: zostanie automatycznie utworzony szkic korekty do faktury sprzedaży nr {{number}}. Czy na pewno chcesz kontynuować?',
    issuingCorrection: 'Wystaw korektę',
    saveAndStepForward: 'Zapisz i przejdź dalej',
    paymentForm: 'Forma płatności',
    paymentDate: 'Termin płatności',
    paymentDateFromReceive: 'Data płatności od dnia otrzymania',
    invoiceNumber: 'Numer faktury',
    nip: 'NIP',
    askDeleteInvoiceDraft:
      'Czy na pewno chcesz usunąć wyświetlany szkic faktury?',
    documentCodes: 'Kody dokumentu',
    gtuCode: 'Kod GTU',
    financialAccount: 'Konto księgowe',
    was: 'było',
    is: 'jest',
    commission: 'Prowizja',
    commissionFinancialAccount: 'Konto księgowe prowizji',
    sumToInvoiceSaleAndCommission: 'Suma do fakturowania sprzedaży i prowizji',
    mergedPositionsSameAccount:
      'Scalane pozycje muszą być przypisane do tego samego konta księgowego oraz należeć do',
    mergePositions: 'Scal pozycje',
    askResetPositions: 'Czy na pewno chcesz zresetować pozycje?',
    clonePosition: 'Klonuj pozycję',
    resetPositions: 'Resetuj wszystkie pozycje',
    mergePositionsBy: 'Scal pozycje według',
    mergeSelectedPositionsBy: 'Scal wybrane pozycje według',
    netAmount: 'Kwota netto',
    vat: 'Stawka VAT',
    netAmountInCurrency: 'Kwota netto w walucie',
    grossAmount: 'Kwota brutto',
    sumToInvoice: 'Suma do fakturowania',
    correctionAmount: 'Kwota korekty',
    print: 'Wydruk',
    fvsData: 'Dane do FVS',
    duplicate: 'Duplikat',
    copy: 'Kopia',
    addedWithinBudget: 'dodanych w ramach budżetu',
    noInvoices: 'Brak faktur sprzedaży w systemie.',
    buyerName: 'Nazwa nabywcy',
    net: 'Netto',
    handleChangeToSent: `Czy na pewno chcesz zmienić status tej faktury na 'Wysłana'?`,
    handleChangeToDraft: `Czy na pewno chcesz zmienić status tej faktury na 'Szkic'?`,
    handleChangeToGenerated: `Czy na pewno chcesz zmienić status tej faktury na 'Zatwierdzona'?`,
    markAsSent: 'Oznacz jako wysłana',
    undoApproval: 'Cofnij zatwierdzenie',
    undoSend: 'Cofnij wysłanie',
    correctionReason: 'Przyczyna wystawienia korekty',
    onePositionOnInvoice: 'Jedna pozycja na fakturze',
    textOverItems: 'Tekst do wydruku nad pozycjami',
    documentApproved: 'Dokument zostanie zatwierdzony z datą wystawienia',
    andSalesDate: 'oraz z datą sprzedaży',
    diffVatRates:
      'W pozycjach faktury występują różne stawki VAT dla pozycji. Aby kontynuować, odznacz pole',
    orSetSameVatRates: 'lub ustaw jednakowe stawki VAT dla pozycji.',
    wrongTryAgain: 'Coś poszło nie tak. Spróbuj ponownie.',
    drafts: 'Szkice',
    approved: 'Zatwierdzone',
    all: 'Wszystkie',
    exportFvs: 'Eksport faktur sprzedaży',
    invoicesDomesticToSent: 'FVS krajowe do wysłania',
    createInvoicesDomesticToSent: 'Utwórz XML z krajowymi FVS',
    invoicesForeignToSent: 'FVS zagraniczne do wysłania',
    createInvoicesForeignToSent: 'Utwórz XML z zagranicznymi FVS',
    packagesFVS: 'Paczki FVS',
    basicData: 'Dane podstawowe',
    invoicePositions: 'Pozycje faktury',
    summary: 'Podsumowanie',
    unwrittenUppercase: 'NIEZAPISANA',
    withCorrections: 'Z uwzględnieniem korekt',
    archiveUpperCase: 'ARCHIWUM',
    packageWithNumber: 'w paczce o nr sys.',
    saleInvoice: 'Faktura sprzedaży',
    correction: 'Korekta',
    toSaleInvoice: 'do faktury sprzedaży',
    invoicesToCorrect: 'Faktury sprzedaży do skorygowania',
    alertDraftInvoiceCorrectionCreation:
      'Uwaga: zostanie automatycznie utworzony szkic korekty do faktury sprzedaży nr {{number}}. Czy na pewno chcesz kontynuować?',
    noPositionsToInvoice: 'Brak dostępnych pozycji do zafakturowania.',
    atLeastOneValueInPositionsFilled:
      'Przynajmniej jedna pozycja musi mieć Kwotę sprzedaży lub Kwotę prowizji większą od 0.',
    position: 'Pozycja',
    noSymphonyCode: 'Brak kodu Symfonii dla kontrahenta',
    correctionToInvoice: 'Korekta do faktury',
    invoicesInPackage:
      'Faktury zawarte w paczce nr sys. {{number}} z dnia {{date}}',
    createXML10FVS: 'Utwórz XML z krajowymi FVS (10 - najstarszych)',
    oldest10: '10 - najstarszych',
    selected: 'zaznaczone',
    askCreatePackage: 'Czy na pewno utworzyć paczkę FVS?',
    noUnsentInvoices: 'Brak niewysłanych faktur sprzedaży w systemie.',
    export: 'Eksport',
    domestic: 'Krajowe',
    foreign: 'Zagraniczne',
    openInstructionNewWindow: 'Otwórz instrukcję fakturowania w nowym oknie',
    invoiceInstruction: 'Instrukcja fakturowania',
    rejected: 'Odrzucono',
  },
  symphony: {
    symphonyNew: 'Nowy kod',
    code: 'Kod',
    noSymphonyForClient: 'Brak kodów Symfonii dla tego kontrahenta.',
    symphonyEdit: 'Edycja kodu',
    symphonyAdd: 'Dodawanie nowego kodu',
    clientsWithSymphony: 'Kontrahenci - Symfonia',
    clientsNoSymphonyFVS: 'Kontrahenci bez kodu Symfonii (Faktury sprzedaży)',
    clientsNoSymphonyFVZ: 'Kontrahenci bez kodu Symfonii (Faktury zakupu)',
    sendUnsentFVSAll: 'Wyślij niewysłane FVS (wszystkie)',
    sendUnsentFVS: 'Wyślij niewysłane FVS ({{number}} - zaznaczone)',
    sendingFVSToSymfoniaFinished:
      'Wysyłka faktur sprzedaży do tabel przejściowych Symfonia Handel została zakończona.',
    exportFVSToSymfonia: 'Eksport faktur sprzedaży do Symfonii Handel',
    FVSToSend: 'FVS do wysłania',
    askSendToSymphony:
      'Czy na pewno wysłać faktury do bazy przejściowej Symfonii Handel?',
    sentFVS: 'FVS wysłane',
    noUnsentFVS: 'Brak niewysłanych faktur sprzedaży do Symfonii Handel.',
    noSentFVS: 'Brak wysłanych faktur sprzedaży do Symfonii Handel.',
    symphonyNumberNotFound:
      'Nie znaleziono numeru Symfonii dla rozliczonego klienta i spółki',
    invoiceWontBeImported:
      'Faktura po zatwierdzeniu nie zostanie wyeksportowana do systemu Symfonia.',
    clients: 'Kontrahenci',
    noClients: 'Brak kontrahentów w systemie',
  },
} as const;
