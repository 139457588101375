import React from 'react';

import {
  CreateUpdateApplicationDto,
  CreateUpdateClientDto,
  UpdateAdminApplicationDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { ApplicationTypeEnum } from '../../../common/pokCore/validation/enums';
import EmployeeSelector from '../Selects/EmployeeSelector';
import { Option } from '../Selects/Selector';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ApplicationAdminEditorInfoPermissionProps {
  application: CreateUpdateApplicationDto;
  propertyChange?: (
    obj: Partial<CreateUpdateClientDto | CreateUpdateApplicationDto>,
  ) => void;
}

export const ApplicationAdminEditorInfoPermission: React.FC<
  ApplicationAdminEditorInfoPermissionProps
> = props => {
  const { application } = props;
  const { t, tk } = useTranslation('applicationLng');

  const propertyChange = (obj: Partial<UpdateAdminApplicationDto>) => {
    if (props.propertyChange) {
      props.propertyChange(obj);
    }
  };

  return (
    <>
      {application.applicationType === ApplicationTypeEnum.Permission && (
        <>
          <FormRow controlId="employee" label={t(tk.app.employeeAccess)}>
            <EmployeeSelector
              value={props.application.permissionEmployeeId}
              onChange={e =>
                e &&
                propertyChange({
                  permissionEmployeeId: (e as Option).value,
                })
              }
              readOnly
            />
          </FormRow>
          <FormRow controlId="employeeBoss" label={t(tk.app.employeeBoss)}>
            <EmployeeSelector
              value={props.application.permissionEmployeeBossId}
              onChange={e =>
                e &&
                propertyChange({
                  permissionEmployeeBossId: (e as Option).value,
                })
              }
              readOnly
            />
          </FormRow>
        </>
      )}
    </>
  );
};
