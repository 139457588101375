/*eslint complexity: ["error", 47]*/

import {
  CreateUpdateEstimateItemDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import mathUtils from '../../../utils/mathUtils';

export const calculateFields = (
  obj: Partial<CreateUpdateEstimateItemDto>,
  schema: ProfileSchemaDto | undefined,
  item: CreateUpdateEstimateItemDto,
) => {
  //salesNet = (1-salesDiscountPercent/100) * (1 + salesSurchargePercent/100) * rcSalesTotal
  if (
    (obj.rcSalesTotal !== undefined ||
      obj.salesDiscountPercent !== undefined ||
      obj.salesSurchargePercent !== undefined) &&
    schema?.salesNet
  ) {
    const rcs = getValue(obj.rcSalesTotal, item.rcSalesTotal, 0);
    const sdp = getValue(
      obj.salesDiscountPercent,
      item.salesDiscountPercent,
      0,
    );
    const ssp = getValue(
      obj.salesSurchargePercent,
      item.salesSurchargePercent,
      0,
    );
    const salesNet = mathUtils.multiply(
      mathUtils.subtract(1, mathUtils.divide(sdp, 100)),
      mathUtils.add(1, mathUtils.divide(ssp, 100)),
      rcs,
    );
    obj.salesNet = salesNet.toString();
  }

  //jak uzupełnimy % wylicza sie kwota
  //salesCommissionAmount = salesNet*salesCommissionPercent/100
  if (
    obj.salesCommissionPercent !== undefined &&
    schema?.salesCommissionAmount &&
    isNumber(item?.salesNet)
  ) {
    const scp = getValue(
      obj.salesCommissionPercent,
      item.salesCommissionPercent,
      0,
    );
    const sca = mathUtils.multiply(
      item?.salesNet as string,
      mathUtils.divide(scp, 100),
    );
    obj.salesCommissionAmount = sca.toString();
  }

  //jak uzupełnimy kwotę to wylicza sie %
  //salesCommissionPercent = salesCommissionAmount/salesNet*100
  if (
    obj.salesCommissionAmount !== undefined &&
    obj.salesCommissionPercent === undefined &&
    schema?.salesCommissionPercent &&
    isNumber(item?.salesNet) &&
    mathUtils.parse(item?.salesNet) !== 0
  ) {
    const sca = getValue(
      obj.salesCommissionAmount,
      item.salesCommissionAmount,
      0,
    );
    const scp = mathUtils.multiply(
      mathUtils.divide(sca, item?.salesNet as string),
      100,
    );
    obj.salesCommissionPercent = scp.toString();
  }

  //wpisane salesCommissionAmount i salesCommissionPercent, ale zmienia sie salesNet
  //salesCommissionAmount = salesNet/salesCommissionPercent*100
  if (
    obj.salesNet !== undefined &&
    schema?.salesCommissionPercent &&
    isNumber(item?.salesCommissionPercent) &&
    isNumber(item?.salesCommissionAmount)
  ) {
    const sn = getValueOrDefault(obj.salesNet, 0);
    const sca = mathUtils.divide(
      mathUtils.multiply(sn, item?.salesCommissionPercent as string),
      100,
    );
    obj.salesCommissionAmount = sca.toString();
  }

  //salesNetTotal = salesNet + salesCommissionAmount + salesNetTechnicalCost
  if (
    (obj.salesNet !== undefined ||
      obj.salesCommissionAmount !== undefined ||
      obj.salesNetTechnicalCost !== undefined) &&
    schema?.salesNetTotal
  ) {
    const sn = getValue(obj.salesNet, item.salesNet, 0);
    const sca = getValue(
      obj.salesCommissionAmount,
      item.salesCommissionAmount,
      0,
    );
    const sntc = getValue(
      obj.salesNetTechnicalCost,
      item.salesNetTechnicalCost,
      0,
    );
    const salesNetTotal = mathUtils.add(
      mathUtils.round(sn),
      mathUtils.round(sca),
      mathUtils.round(sntc),
    );
    obj.salesNetTotal = salesNetTotal.toString();
  }

  //PURCHASE purchaseDiscountPercent purchaseDiscountPercent
  //purchaseNetTotal = (1-purchaseDiscountPercent/100) * (1 + purchaseSurchargePercent/100) * rcPurchase
  if (
    obj.rcPurchase !== undefined ||
    obj.purchaseDiscountPercent !== undefined ||
    obj.purchaseSurchargePercent !== undefined
  ) {
    const rc = getValue(obj.rcPurchase, item.rcPurchase, 0);
    const pdp = getValue(
      obj.purchaseDiscountPercent,
      item.purchaseDiscountPercent,
      0,
    );
    const psp = getValue(
      obj.purchaseSurchargePercent,
      item.purchaseSurchargePercent,
      0,
    );
    const purchaseNet = mathUtils.multiply(
      mathUtils.subtract(1, mathUtils.divide(pdp, 100)),
      mathUtils.add(1, mathUtils.divide(psp, 100)),
      rc,
    );
    if (schema?.purchaseNet) {
      obj.purchaseNet = purchaseNet.toString();
      if (!schema?.purchaseTechnicalCost) {
        obj.purchaseNetTotal = mathUtils.round(purchaseNet).toString();
      }
    } else {
      obj.purchaseNetTotal = mathUtils.round(purchaseNet).toString();
    }
  }

  //suma:purchaseNetTotal =  [Netto zakup (ekspozycja)]purchaseNet + [Netto koszt techniczny] purchaseTechnicalCost
  if (
    (obj.purchaseNet !== undefined ||
      obj.purchaseTechnicalCost !== undefined) &&
    schema?.purchaseNet &&
    schema?.purchaseTechnicalCost
  ) {
    const pn = getValue(obj.purchaseNet, item.purchaseNet, 0);
    const ptc = getValue(
      obj.purchaseTechnicalCost,
      item.purchaseTechnicalCost,
      0,
    );
    const purchaseNetTotal = mathUtils.add(
      mathUtils.round(pn),
      mathUtils.round(ptc),
    );
    obj.purchaseNetTotal = mathUtils.round(purchaseNetTotal).toString();
  }
  return obj;
};

const isNumber = (value: string | undefined | null) => {
  if (value === undefined || value === null) {
    return false;
  }
  const n = Number.parseFloat(value);
  return typeof n === 'number' && isFinite(n);
};

const getValueOrDefault = (
  value: string | undefined | null,
  defaultValue: number,
) => {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  if (Number(value)) {
    return value;
  }
  return defaultValue;
};

const getValue = (
  value1: string | null | undefined,
  value2: string | null | undefined,
  defaultValue: number,
) => {
  return value1 !== undefined
    ? getValueOrDefault(value1, defaultValue)
    : getValueOrDefault(value2, defaultValue);
};
