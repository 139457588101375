export default {
  prLang: {
    importExcel: `Import kampanii z arkuszy`,
    projects: 'Kampanie',
    projectsAndTeams: 'Kampanie i zespoły',
    projectsApplications: 'Wnioski do kampanii',
    delayedProjects: 'Kampanie po terminie',
    canceledProjects: 'Kampanie do anulowania',
    project: 'Kampania',
    genitiveProject: 'kampanii',
    genitiveProjectWithoutSpace: 'kampanii',
    accusativeProject: 'kampanię',
    genitiveProjectPlural: 'kampanii',
    projectNumber: 'Numer kampanii',
    internalProject: 'Kampania zlecająca',
    projectList: 'Lista kampanii',
    withProjects: 'z kampaniami',
    connectionsWithProjectsFVZ:
      'Powiązania z kampaniami zostaną wyjaśnione przez zespoły',
    estimate: 'Mediaplan',
    estimate2: 'mediaplanu',
    estimates: 'Mediaplany',
    addNewProject: 'Dodawanie nowej kampanii',
    newProjectName: 'Nazwa nowej kampanii',
    chooseProject: 'Wybieranie kampanii',
    sourceProject: 'Kampania źródłowa',
    sourceProject2: 'kampanię źródłową',
    addProject: 'Dodaj kampanię',
    editProject: 'Edycja kampanii',
    emptyProjectsList: 'Brak kampanii w systemie.',
    projectNotFound: 'Nie znaleziono kampanii o takim ID.',
    salesInvoiceToProject: 'do kampanii',
    estimateItemPosition: 'Pozycja mediaplanu',
    addEstimateItemPosition: 'Dodaj pozycje mediaplanu',
    chooseEstimateItem: 'Wybieranie pozycji mediaplanu',
    estimateItemInvoiceModifyWarning:
      'Są już zapisane pozycje faktury. Czy na pewno chcesz zaktualizować pozycje mediaplanu?',
    estimateItemInvoiceTabTitle: 'Pozycje mediaplanu',
    deleteProjectWarning: 'Czy na pewno usunąć wybraną kampanię?',
    deleteProjectTooltip: 'Usuń kampanię',
    unsettledPurchaseInvoicesAssignedToProject:
      'Nierozliczone FVZ przypisane do kampanii',
    purchaseInvoicesAssignedToProject: 'Faktury zakupu przypisane do kampanii',
    orderResultProject: 'Kampania podzlecona',
    readyForInvoicing: 'Kampanie gotowe do fakturowania',
    readyForCorrection: 'Kampanie z ujemną kwotą do fakturowania',
    applicationUnblocks: 'Wnioski o odblokowanie mediaplanu',
    projectsListModifiedInvoiceAlert:
      'Lista kampanii została zmodyfikowana. Po zapisie nastąpi zresetowanie pozycji mediaplanu oraz pozycji faktury.',
    selectProjectsPurchaseInvoiceAlert:
      'Aby dodać kilka kampanii należy zaznaczyć je klikając w wiersz, zaznaczone kampanie zmienią kolor na żółty. Po zapisaniu zmian, wybrane kampanie (jedna lub więcej) zostaną dodane',
    selectProjectPurchaseInvoiceAlertError:
      'Proszę zaznaczyć jedną lub kilka kampanii.',
    sameProject: 'tej samej kampanii',
    projectLabel: 'Etykieta kampanii',
    projectControl: 'Kontrola kampanii',
    estimateMonth: 'Miesiąc mediaplanu',
    monthRangeEstimates: 'Zakres miesięcy mediaplanów',
    teamRelatedEstimates: 'Pozycje mediaplanu powiązane z zespołem',
    forProject: 'do kampanii',
    projectPurchase: 'Zakup w kampanii',
    estimatePositionsSum: 'Suma pozycji mediaplanu',
    importProjectsFromFile: 'Import kampanii z arkuszy',
    projectNo: 'Kampania nr sys.',
    printProjectNumber: 'Drukuj numer kampanii',
    saleAndCommissionSum: 'Suma sprzedaży i prowizji mediaplanu',
    fromProject: 'z kampanii',
    projectClient: 'Kontrahent kampanii',
    noProjects: 'Brak kampanii w systemie.',
    errCreateProjectSendApplication:
      'Założenie kampanii w wybranym okresie jest już zablokowane, wyślij wniosek o założenie kampanii.',
    parentProjectNumber: 'Kampania zleceniodawcy',
    askDownloadFVZ: 'Czy pobrać faktury FVZ dołączone do kampanii?',
    askDownloadFVZDespiteWarnings:
      'Czy pobrać faktury FVZ dołączone do kampanii pomimo następujących ostrzeżeń?',
    paePositionsList:
      'Lista pozycji w PAE dla kampanii {{projectNo}} za {{date}}',
    maxDelay: 'Maks. opóźnienie kampanii',
    thisProject: 'tą kampanią',
    withoutTeamsProjects: 'Bez zespołów i kampanii',
    settingUpProjects: 'Zakładanie kampanii i uzupełnianie wniosków',
    importFromProjectToProject: 'Import pozycji z kampanii do kampanii',
    projectOrders: 'Zamówienia do kampanii',
    importProjects: 'Import kampanii z arkuszy',
    warningPurchaseAndSalesAmountDiff:
      'Łączna kwota zakupu z kampanii zlecającej, jest różna od kwoty sprzedaży.',
    projectsByMonths: 'Kampanie wg miesięcy',
    positionsProjects: 'Pozycje kampanii',
    reportToBudgetByMonth: 'Raport do budżetów - wg miesięcy w kampaniach',
    pbProject: 'PB kampanii',
  },
  importExcel: {},
} as const;
