import React from 'react';
import DatePicker from 'react-date-picker';
import { Value } from 'react-date-picker/dist/cjs/shared/types';

import { useTranslation } from '../../common/hooks/useTranslation';

type NewDatePickerProps = React.PropsWithChildren<{
  value?: Date | null | undefined;
  onChange?: (date: Value) => void;
  className?: string;
}>;

export const NewDatePickerReact = (props: NewDatePickerProps) => {
  const { t, tk } = useTranslation('commonLng');
  return (
    <DatePicker
      onChange={props.onChange}
      monthAriaLabel="Month"
      maxDetail="year"
      format="MM.yyyy"
      value={props.value}
      className={props.className || 'small-calendar'}
      locale={t(tk.common.locale)}
    />
  );
};
