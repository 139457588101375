import React, { useState } from 'react';
import { Alert, Card, Modal } from 'react-bootstrap';

import {
  DecisionApplicationUnblockDto,
  GetEmployeeDto,
  GetEstimateByMonthDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import momencik from '../../../common/momencik';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import Button from '../../../common/components/Button/Button';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ApplicationUnblockEditorForm } from './ApplicationUnblockEditorForm';
import { ApplicationUnblockDecisionForm } from './ApplicationUnblockDecisionForm';

interface ApplicationUnblockDecisionEditorProps {
  id: string;
  systemNumber?: number;
  applicationUnblock: DecisionApplicationUnblockDto;
  handleClose?: () => void;
  handleRefresh?: () => void;
  show?: boolean;
  estimateByMonth?: GetEstimateByMonthDto;
  readOnly?: boolean;
  author: GetEmployeeDto;
  createdDate: Date | null;
  salesBlockedMonth: boolean;
  purchaseBlockedMonth: boolean;
  onCompleted: (
    client: DecisionApplicationUnblockDto,
    id: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const ApplicationUnblockDecisionEditor: React.FC<
  ApplicationUnblockDecisionEditorProps
> = props => {
  const [applicationUnblock, setApplicationUnblock] = useState(
    props.applicationUnblock,
  );
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('applicationLng');

  const propertyChange = (obj: Partial<DecisionApplicationUnblockDto>) => {
    setApplicationUnblock({ ...applicationUnblock, ...obj });
  };

  const saveApplicationUnblockDecision = async () => {
    if (!props.id) {
      return;
    }
    setIsLoading(true);
    props
      .onCompleted(applicationUnblock, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(tk.app.applicationExecutionWithDateNo, {
              createdDate: momencik(props.createdDate),
              sysNumber: props.systemNumber,
            })}
            ,{' '}
            <small>
              {t(tk.app.creator)}: {props.author.name}
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.salesBlockedMonth && (
            <Alert variant="warning">{t(tk.app.globalSalesMonthLock)}</Alert>
          )}
          {props.purchaseBlockedMonth && (
            <Alert variant="warning">{t(tk.app.globalPurchaseMonthLock)}</Alert>
          )}
          <Card className="card">
            <Card.Header>{t(tk.app.applicationData)}</Card.Header>
            <Card.Body className="m-1">
              <ApplicationUnblockEditorForm
                applicationUnblock={props.applicationUnblock}
                estimateByMonth={props.estimateByMonth}
                readOnly
              />
            </Card.Body>
          </Card>
          <ApplicationUnblockDecisionForm
            applicationUnblock={applicationUnblock}
            propertyChange={propertyChange}
            readOnly={props.readOnly}
          />
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <LastEditorInfo
            id={props.id}
            method={pok.applicationsUnblock.getLastEditor}
          />
          <Button variant="outline-secondary" onClick={props.handleClose}>
            {t(tk.button.close)}
          </Button>
          {!props.readOnly && (
            <Button
              variant="primary"
              onClick={saveApplicationUnblockDecision}
              isLoading={isLoading}
            >
              {t(tk.button.save)}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
