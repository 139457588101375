import type { TFunction } from 'i18next';

import { positionForGrid } from '../../../pok/components/Positions/PositionSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { DefaultApi } from '../autogenerated/pokApiClient';
import {
  CreateUpdatePositionDto,
  CreateUpdatePositionDtoFromJSON,
} from '../autogenerated/pokApiClient/models/CreateUpdatePositionDto';
import { GetLastEditorDto } from '../autogenerated/pokApiClient/models/GetLastEditorDto';
import { GetPositionDto } from '../autogenerated/pokApiClient/models/GetPositionDto';
import { GetPositionExtendedDto } from '../autogenerated/pokApiClient/models/GetPositionExtendedDto';
import { PositionSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import {
  defaultSelectorLimit,
  Option,
} from '../../../pok/components/Selects/Selector';
import { FilterColumn } from '../../types/FilterColumn';

export interface PositionContextInterface {
  getById: (id: string) => Promise<GetPositionExtendedDto>;
  getOptionById: (id: string) => Promise<Option>;
  getAllByPortionForClient: (
    t: TFunction<'clientLng', 'clientLng'>,
    lang: string,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
    clientId?: string,
  ) => Promise<GridRecord[]>;
  getAllCountForClient: (
    filterText?: string,
    filterColumns?: FilterColumn,
    clientId?: string,
  ) => Promise<number>;
  getOptionsByProfileIdTeamIdAndName(
    profileId: string,
    teamId: string,
    text: string,
  ): Promise<Option[]>;
  getOptionsByTeamIdsCommissionAndName(
    teamIds: string[],
    profileId: string,
    commission: boolean,
    budgetId: string,
    text: string,
  ): Promise<Option[]>;
  getClientsOptionsByProjectId(
    projectId: string,
    text: string,
  ): Promise<Option[]>;
  update: (id: string, dto: CreateUpdatePositionDto) => Promise<void>;
  create: (dto: CreateUpdatePositionDto) => Promise<GetPositionDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
  getGroupNameOptionsByText: (text: string) => Promise<Option[]>;
}

export const PositionContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.positionControllerGet(id),
    getOptionById: async (id: string) => {
      const position = await api.positionControllerGet(id);
      return {
        value: position.id,
        label: `${position.name} (${
          position.client.shortname
            ? position.client.shortname
            : position.client.name
        })`,
      };
    },
    getAllByPortionForClient: async (
      t: TFunction<'clientLng', 'clientLng'>,
      lang: string,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
      clientId?: string,
    ) => {
      const data = await api.positionControllerGetAllByPortionForClient(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
        clientId,
      );

      return data.map(item => positionForGrid(t, lang, item, orderBy));
    },

    getAllCountForClient: (
      filterText?: string,
      filterColumns?: FilterColumn,
      clientId?: string,
    ) =>
      api.positionControllerGetAllCountForClient(
        filterText || '',
        filterColumns,
        clientId,
      ),
    getOptionsByTeamIdsCommissionAndName: async (
      teamIds: string[],
      profileId: string,
      commission: boolean,
      budgetId: string,
      text: string,
    ) => {
      const data = await api.positionControllerGetAllByTeamIdsCommissionAndName(
        teamIds,
        profileId,
        commission,
        budgetId,
        text,
        defaultSelectorLimit,
      );
      return Array.from(data, position => ({
        value: position.id,
        label: `${position.name} (${
          position.client.shortname
            ? position.client.shortname
            : position.client.name
        })`,
      }));
    },
    getOptionsByProfileIdTeamIdAndName: async (
      positionId: string,
      teamId: string,
      text: string,
    ) => {
      const data = await api.positionControllerGetAllByProfileIdTeamIdAndName(
        positionId,
        teamId,
        text,
        defaultSelectorLimit,
      );

      return Array.from(data, position => ({
        value: position.id,
        label: `${position.name} (${
          position.client.shortname
            ? position.client.shortname
            : position.client.name
        })`,
      }));
    },

    getClientsOptionsByProjectId: async (projectId: string, text: string) => {
      const data = await api.positionControllerGetAllClientsByProjectId(
        projectId,
        text,
        defaultSelectorLimit,
      );

      return Array.from(data, client => ({
        value: client?.id,
        label: `${client?.name} (${client?.shortname ? client?.shortname : client?.name})`,
      }));
    },
    update: (id: string, dto: CreateUpdatePositionDto) =>
      api.positionControllerUpdate(id, dto),
    create: (dto: CreateUpdatePositionDto) => api.positionControllerCreate(dto),
    getLastEditor: (id: string) => api.positionControllerGetLastEditor(id),
    getGroupNameOptionsByText: async (text: string) => {
      const data = await api.positionControllerGetDistinctGroupNames(
        text,
        defaultSelectorLimit,
      );
      return Array.from(data, groupName => {
        return {
          value: groupName,
          label: groupName,
        };
      });
    },
  };
};

export const newPosition = (clientId?: string) => {
  return CreateUpdatePositionDtoFromJSON({ clientId });
};

export const validate = (position: CreateUpdatePositionDto) =>
  validateAgainst(PositionSchema, position);

export const convert = (position?: GetPositionExtendedDto) => {
  if (!position) {
    return newPosition();
  }
  const converted = CreateUpdatePositionDtoFromJSON(position);
  converted.dictionaryId = position.dictionary?.id;
  converted.clientId = position.client?.id;
  converted.teamId = position.team?.id;
  converted.teamIds = position.teams?.map(({ id }) => id) || [];
  converted.companyIds = position.companies?.map(({ id }) => id) || [];
  converted.profileIds = position.profiles?.map(({ id }) => id) || [];

  return converted;
};
