import React, { useEffect, useState } from 'react';
import { Alert, Card } from 'react-bootstrap';

import {
  CreateUpdateExcelDto,
  GetExcelAndPokDataDto,
  GetExcelDataDto,
  GetResultsDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { validate } from '../../../common/pokCore/contexts/ProjectExcelImportContext';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { ExcelImportSteps } from '../../components/ProjectExcelImport/ExcelImportSteps';
import { Option } from '../../../pok/components/Selects/Selector';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const ProjectExcelImportView: React.FC = () => {
  const pok = usePokCore();
  const [step, setStep] = useState(1);
  const [excelData, setExcelData] = useState<GetExcelDataDto>();
  const [compareData, setCompareData] = useState<GetExcelAndPokDataDto>();
  const [results, setResults] = useState<GetResultsDto>();
  const [waiting, setWaiting] = useState(false);
  const [company, setCompany] = useState<string>(pok.getCompanyId);
  const [options, setOptions] = useState<Option[]>([]);
  const notifications = useNotifications();
  const { t, tk } = useTranslation('excelImportLng');

  const setStartStep = () => {
    setStep(1);
    clearData();
  };

  useEffect(() => {
    if (company !== pok.getCompanyId()) {
      window.location.reload();
    }
    setCompany(pok.getCompanyId);
    pok.projectExcelConfigs
      .getOptions()
      .then(data => {
        setOptions(data);
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pok.getCompanyId]);

  const clearData = () => {
    setExcelData(undefined);
    setCompareData(undefined);
    setResults(undefined);
  };

  const handleGetExcelData = async (excel: CreateUpdateExcelDto) => {
    clearData();
    const status = await validate(excel);
    if (status.valid) {
      try {
        const data = await pok.projectExcelImports.getDataFromExcel(excel);
        setExcelData(data);
        setStep(2);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleCompareExcelAndPOK = async (excel: GetExcelDataDto) => {
    setWaiting(true);
    pok.projectExcelImports
      .getExcelAndPokData(excel)
      .then(data => {
        setCompareData(data);
        setStep(3);
      })
      .catch(error => {
        notifications.caughtError(error);
      })
      .finally(() => {
        setWaiting(false);
      });
  };

  const handleImportExcel = async (excel: GetExcelAndPokDataDto) => {
    setWaiting(true);
    setStep(4);
    pok.projectExcelImports
      .importExcelData(excel)
      .then(results => {
        setResults(results);
      })
      .catch(error => {
        notifications.caughtError(error);
      })
      .finally(() => {
        setWaiting(false);
        setExcelData(undefined);
        setCompareData(undefined);
      });
  };

  return (
    <>
      {options && options.length > 0 ? (
        <Card className="mb-2">
          <Card.Body>
            <ExcelImportSteps
              step={step}
              excelData={excelData}
              compareData={compareData}
              results={results}
              waiting={waiting}
              setStartStep={setStartStep}
              handleGetExcelData={handleGetExcelData}
              handleCompareExcelAndPOK={handleCompareExcelAndPOK}
              handleImportExcel={handleImportExcel}
            />
          </Card.Body>
        </Card>
      ) : (
        <Alert variant="info">
          <Alert.Heading>{t(tk.config.noConfigsDefined)}</Alert.Heading>
          <hr />
          <p>{t(tk.config.addNewConfigsContactAdmin)}</p>
        </Alert>
      )}
    </>
  );
};
