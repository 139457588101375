/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetExcelRowDto
 */
export interface GetExcelRowDto {
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    rowId: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetExcelRowDto
     */
    emptyRow: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExcelRowDto
     */
    otherErrors: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    positionNotFoundError?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExcelRowDto
     */
    parameterErrors: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExcelRowDto
     */
    calculationErrors: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    projectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelProjectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    profileId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    positionName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    positionType: GetExcelRowDtoPositionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    positionId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelPositionName?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetExcelRowDto
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetExcelRowDto
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    invoiceInstructionAttachment?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    duplicateMasterRowId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    rcSalesTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    salesNet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    salesCommissionAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    salesNetTechnicalCost?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    salesNetTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    rcPurchase?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseTechnicalCost?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseNetTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseNet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    netCustomerAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelRcSalesTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelSalesNet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelSalesCommissionAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelSalesNetTechnicalCost?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelSalesNetTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelRcPurchase?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelPurchaseTechnicalCost?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelPurchaseNetTotal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelPurchaseNet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelNetCustomerAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    orderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    ownName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    actionGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    additionalDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    numberOfMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    copySize?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseFormInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    categoryInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseFormInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    categoryInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    labconChannel?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    categoryLabcon?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    technologyDictionary?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    regionDictionary?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatPress?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatRadio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    emissionTypeRadio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    emissionTypeCinema?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    medium?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    copySizeValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseMethodValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseFormInternetValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    categoryInternetValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatInternetValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseFormInternetSTValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    categoryInternetSTValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatInternetSTValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    labconChannelValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    categoryLabconValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    technologyDictionaryValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    regionDictionaryValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatPressValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatRadioValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    emissionTypeRadioValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    emissionTypeCinemaValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    mediumValuePl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    copySizeValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseMethodValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseFormInternetValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    categoryInternetValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatInternetValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseFormInternetSTValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    categoryInternetSTValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatInternetSTValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    labconChannelValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    categoryLabconValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    technologyDictionaryValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    regionDictionaryValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatPressValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    formatRadioValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    emissionTypeRadioValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    emissionTypeCinemaValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    mediumValueEn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    salesSurchargePercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    salesDiscountPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    salesCommissionPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseDiscountPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    purchaseSurchargePercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    serviceExternalWorkPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    serviceCreationPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    serviceChangeProductionPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    serviceStrategyPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    serviceProductionBroadcastPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    serviceClientServicePercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelSalesSurchargePercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelSalesDiscountPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelSalesCommissionPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelPurchaseDiscountPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelPurchaseSurchargePercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelServiceExternalWorkPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelServiceCreationPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelServiceChangeProductionPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelServiceStrategyPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelServiceProductionBroadcastPercent?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelServiceClientServicePercent?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetExcelRowDto
     */
    isUpSell?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelCopySize?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelPurchaseMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelPurchaseFormInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelCategoryInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelFormatInternet?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelPurchaseFormInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelCategoryInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelFormatInternetST?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelLabconChannel?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelCategoryLabcon?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelTechnologyDictionary?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelRegionDictionary?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelFormatPress?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelFormatRadio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelEmissionTypeRadio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelEmissionTypeCinema?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExcelRowDto
     */
    excelMedium?: string;
}


/**
 * @export
 */
export const GetExcelRowDtoPositionTypeEnum = {
    Standard: 'Standard',
    Commission: 'Commission',
    TransferredCommission: 'TransferredCommission'
} as const;
export type GetExcelRowDtoPositionTypeEnum = typeof GetExcelRowDtoPositionTypeEnum[keyof typeof GetExcelRowDtoPositionTypeEnum];


/**
 * Check if a given object implements the GetExcelRowDto interface.
 */
export function instanceOfGetExcelRowDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rowId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "emptyRow" in value;
    isInstance = isInstance && "otherErrors" in value;
    isInstance = isInstance && "parameterErrors" in value;
    isInstance = isInstance && "calculationErrors" in value;
    isInstance = isInstance && "positionType" in value;

    return isInstance;
}

export function GetExcelRowDtoFromJSON(json: any): GetExcelRowDto {
    return GetExcelRowDtoFromJSONTyped(json, false);
}

export function GetExcelRowDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExcelRowDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowId': json['rowId'],
        'status': json['status'],
        'emptyRow': json['emptyRow'],
        'otherErrors': json['otherErrors'],
        'positionNotFoundError': !exists(json, 'positionNotFoundError') ? undefined : json['positionNotFoundError'],
        'parameterErrors': json['parameterErrors'],
        'calculationErrors': json['calculationErrors'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'projectNumber': !exists(json, 'projectNumber') ? undefined : json['projectNumber'],
        'excelProjectNumber': !exists(json, 'excelProjectNumber') ? undefined : json['excelProjectNumber'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
        'positionName': !exists(json, 'positionName') ? undefined : json['positionName'],
        'positionType': json['positionType'],
        'positionId': !exists(json, 'positionId') ? undefined : json['positionId'],
        'excelPositionName': !exists(json, 'excelPositionName') ? undefined : json['excelPositionName'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'excelStartDate': !exists(json, 'excelStartDate') ? undefined : json['excelStartDate'],
        'excelEndDate': !exists(json, 'excelEndDate') ? undefined : json['excelEndDate'],
        'invoiceInstructionAttachment': !exists(json, 'invoiceInstructionAttachment') ? undefined : json['invoiceInstructionAttachment'],
        'duplicateMasterRowId': !exists(json, 'duplicateMasterRowId') ? undefined : json['duplicateMasterRowId'],
        'rcSalesTotal': !exists(json, 'rcSalesTotal') ? undefined : json['rcSalesTotal'],
        'salesNet': !exists(json, 'salesNet') ? undefined : json['salesNet'],
        'salesCommissionAmount': !exists(json, 'salesCommissionAmount') ? undefined : json['salesCommissionAmount'],
        'salesNetTechnicalCost': !exists(json, 'salesNetTechnicalCost') ? undefined : json['salesNetTechnicalCost'],
        'salesNetTotal': !exists(json, 'salesNetTotal') ? undefined : json['salesNetTotal'],
        'rcPurchase': !exists(json, 'rcPurchase') ? undefined : json['rcPurchase'],
        'purchaseTechnicalCost': !exists(json, 'purchaseTechnicalCost') ? undefined : json['purchaseTechnicalCost'],
        'purchaseNetTotal': !exists(json, 'purchaseNetTotal') ? undefined : json['purchaseNetTotal'],
        'purchaseNet': !exists(json, 'purchaseNet') ? undefined : json['purchaseNet'],
        'netCustomerAccount': !exists(json, 'netCustomerAccount') ? undefined : json['netCustomerAccount'],
        'excelRcSalesTotal': !exists(json, 'excelRcSalesTotal') ? undefined : json['excelRcSalesTotal'],
        'excelSalesNet': !exists(json, 'excelSalesNet') ? undefined : json['excelSalesNet'],
        'excelSalesCommissionAmount': !exists(json, 'excelSalesCommissionAmount') ? undefined : json['excelSalesCommissionAmount'],
        'excelSalesNetTechnicalCost': !exists(json, 'excelSalesNetTechnicalCost') ? undefined : json['excelSalesNetTechnicalCost'],
        'excelSalesNetTotal': !exists(json, 'excelSalesNetTotal') ? undefined : json['excelSalesNetTotal'],
        'excelRcPurchase': !exists(json, 'excelRcPurchase') ? undefined : json['excelRcPurchase'],
        'excelPurchaseTechnicalCost': !exists(json, 'excelPurchaseTechnicalCost') ? undefined : json['excelPurchaseTechnicalCost'],
        'excelPurchaseNetTotal': !exists(json, 'excelPurchaseNetTotal') ? undefined : json['excelPurchaseNetTotal'],
        'excelPurchaseNet': !exists(json, 'excelPurchaseNet') ? undefined : json['excelPurchaseNet'],
        'excelNetCustomerAccount': !exists(json, 'excelNetCustomerAccount') ? undefined : json['excelNetCustomerAccount'],
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'ownName': !exists(json, 'ownName') ? undefined : json['ownName'],
        'actionGroup': !exists(json, 'actionGroup') ? undefined : json['actionGroup'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'additionalDescription': !exists(json, 'additionalDescription') ? undefined : json['additionalDescription'],
        'numberOfMedia': !exists(json, 'numberOfMedia') ? undefined : json['numberOfMedia'],
        'copySize': !exists(json, 'copySize') ? undefined : json['copySize'],
        'purchaseMethod': !exists(json, 'purchaseMethod') ? undefined : json['purchaseMethod'],
        'purchaseFormInternet': !exists(json, 'purchaseFormInternet') ? undefined : json['purchaseFormInternet'],
        'categoryInternet': !exists(json, 'categoryInternet') ? undefined : json['categoryInternet'],
        'formatInternet': !exists(json, 'formatInternet') ? undefined : json['formatInternet'],
        'purchaseFormInternetST': !exists(json, 'purchaseFormInternetST') ? undefined : json['purchaseFormInternetST'],
        'categoryInternetST': !exists(json, 'categoryInternetST') ? undefined : json['categoryInternetST'],
        'formatInternetST': !exists(json, 'formatInternetST') ? undefined : json['formatInternetST'],
        'labconChannel': !exists(json, 'labconChannel') ? undefined : json['labconChannel'],
        'categoryLabcon': !exists(json, 'categoryLabcon') ? undefined : json['categoryLabcon'],
        'technologyDictionary': !exists(json, 'technologyDictionary') ? undefined : json['technologyDictionary'],
        'regionDictionary': !exists(json, 'regionDictionary') ? undefined : json['regionDictionary'],
        'formatPress': !exists(json, 'formatPress') ? undefined : json['formatPress'],
        'formatRadio': !exists(json, 'formatRadio') ? undefined : json['formatRadio'],
        'emissionTypeRadio': !exists(json, 'emissionTypeRadio') ? undefined : json['emissionTypeRadio'],
        'emissionTypeCinema': !exists(json, 'emissionTypeCinema') ? undefined : json['emissionTypeCinema'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'copySizeValuePl': !exists(json, 'copySizeValuePl') ? undefined : json['copySizeValuePl'],
        'purchaseMethodValuePl': !exists(json, 'purchaseMethodValuePl') ? undefined : json['purchaseMethodValuePl'],
        'purchaseFormInternetValuePl': !exists(json, 'purchaseFormInternetValuePl') ? undefined : json['purchaseFormInternetValuePl'],
        'categoryInternetValuePl': !exists(json, 'categoryInternetValuePl') ? undefined : json['categoryInternetValuePl'],
        'formatInternetValuePl': !exists(json, 'formatInternetValuePl') ? undefined : json['formatInternetValuePl'],
        'purchaseFormInternetSTValuePl': !exists(json, 'purchaseFormInternetSTValuePl') ? undefined : json['purchaseFormInternetSTValuePl'],
        'categoryInternetSTValuePl': !exists(json, 'categoryInternetSTValuePl') ? undefined : json['categoryInternetSTValuePl'],
        'formatInternetSTValuePl': !exists(json, 'formatInternetSTValuePl') ? undefined : json['formatInternetSTValuePl'],
        'labconChannelValuePl': !exists(json, 'labconChannelValuePl') ? undefined : json['labconChannelValuePl'],
        'categoryLabconValuePl': !exists(json, 'categoryLabconValuePl') ? undefined : json['categoryLabconValuePl'],
        'technologyDictionaryValuePl': !exists(json, 'technologyDictionaryValuePl') ? undefined : json['technologyDictionaryValuePl'],
        'regionDictionaryValuePl': !exists(json, 'regionDictionaryValuePl') ? undefined : json['regionDictionaryValuePl'],
        'formatPressValuePl': !exists(json, 'formatPressValuePl') ? undefined : json['formatPressValuePl'],
        'formatRadioValuePl': !exists(json, 'formatRadioValuePl') ? undefined : json['formatRadioValuePl'],
        'emissionTypeRadioValuePl': !exists(json, 'emissionTypeRadioValuePl') ? undefined : json['emissionTypeRadioValuePl'],
        'emissionTypeCinemaValuePl': !exists(json, 'emissionTypeCinemaValuePl') ? undefined : json['emissionTypeCinemaValuePl'],
        'mediumValuePl': !exists(json, 'mediumValuePl') ? undefined : json['mediumValuePl'],
        'copySizeValueEn': !exists(json, 'copySizeValueEn') ? undefined : json['copySizeValueEn'],
        'purchaseMethodValueEn': !exists(json, 'purchaseMethodValueEn') ? undefined : json['purchaseMethodValueEn'],
        'purchaseFormInternetValueEn': !exists(json, 'purchaseFormInternetValueEn') ? undefined : json['purchaseFormInternetValueEn'],
        'categoryInternetValueEn': !exists(json, 'categoryInternetValueEn') ? undefined : json['categoryInternetValueEn'],
        'formatInternetValueEn': !exists(json, 'formatInternetValueEn') ? undefined : json['formatInternetValueEn'],
        'purchaseFormInternetSTValueEn': !exists(json, 'purchaseFormInternetSTValueEn') ? undefined : json['purchaseFormInternetSTValueEn'],
        'categoryInternetSTValueEn': !exists(json, 'categoryInternetSTValueEn') ? undefined : json['categoryInternetSTValueEn'],
        'formatInternetSTValueEn': !exists(json, 'formatInternetSTValueEn') ? undefined : json['formatInternetSTValueEn'],
        'labconChannelValueEn': !exists(json, 'labconChannelValueEn') ? undefined : json['labconChannelValueEn'],
        'categoryLabconValueEn': !exists(json, 'categoryLabconValueEn') ? undefined : json['categoryLabconValueEn'],
        'technologyDictionaryValueEn': !exists(json, 'technologyDictionaryValueEn') ? undefined : json['technologyDictionaryValueEn'],
        'regionDictionaryValueEn': !exists(json, 'regionDictionaryValueEn') ? undefined : json['regionDictionaryValueEn'],
        'formatPressValueEn': !exists(json, 'formatPressValueEn') ? undefined : json['formatPressValueEn'],
        'formatRadioValueEn': !exists(json, 'formatRadioValueEn') ? undefined : json['formatRadioValueEn'],
        'emissionTypeRadioValueEn': !exists(json, 'emissionTypeRadioValueEn') ? undefined : json['emissionTypeRadioValueEn'],
        'emissionTypeCinemaValueEn': !exists(json, 'emissionTypeCinemaValueEn') ? undefined : json['emissionTypeCinemaValueEn'],
        'mediumValueEn': !exists(json, 'mediumValueEn') ? undefined : json['mediumValueEn'],
        'salesSurchargePercent': !exists(json, 'salesSurchargePercent') ? undefined : json['salesSurchargePercent'],
        'salesDiscountPercent': !exists(json, 'salesDiscountPercent') ? undefined : json['salesDiscountPercent'],
        'salesCommissionPercent': !exists(json, 'salesCommissionPercent') ? undefined : json['salesCommissionPercent'],
        'purchaseDiscountPercent': !exists(json, 'purchaseDiscountPercent') ? undefined : json['purchaseDiscountPercent'],
        'purchaseSurchargePercent': !exists(json, 'purchaseSurchargePercent') ? undefined : json['purchaseSurchargePercent'],
        'serviceExternalWorkPercent': !exists(json, 'serviceExternalWorkPercent') ? undefined : json['serviceExternalWorkPercent'],
        'serviceCreationPercent': !exists(json, 'serviceCreationPercent') ? undefined : json['serviceCreationPercent'],
        'serviceChangeProductionPercent': !exists(json, 'serviceChangeProductionPercent') ? undefined : json['serviceChangeProductionPercent'],
        'serviceStrategyPercent': !exists(json, 'serviceStrategyPercent') ? undefined : json['serviceStrategyPercent'],
        'serviceProductionBroadcastPercent': !exists(json, 'serviceProductionBroadcastPercent') ? undefined : json['serviceProductionBroadcastPercent'],
        'serviceClientServicePercent': !exists(json, 'serviceClientServicePercent') ? undefined : json['serviceClientServicePercent'],
        'excelSalesSurchargePercent': !exists(json, 'excelSalesSurchargePercent') ? undefined : json['excelSalesSurchargePercent'],
        'excelSalesDiscountPercent': !exists(json, 'excelSalesDiscountPercent') ? undefined : json['excelSalesDiscountPercent'],
        'excelSalesCommissionPercent': !exists(json, 'excelSalesCommissionPercent') ? undefined : json['excelSalesCommissionPercent'],
        'excelPurchaseDiscountPercent': !exists(json, 'excelPurchaseDiscountPercent') ? undefined : json['excelPurchaseDiscountPercent'],
        'excelPurchaseSurchargePercent': !exists(json, 'excelPurchaseSurchargePercent') ? undefined : json['excelPurchaseSurchargePercent'],
        'excelServiceExternalWorkPercent': !exists(json, 'excelServiceExternalWorkPercent') ? undefined : json['excelServiceExternalWorkPercent'],
        'excelServiceCreationPercent': !exists(json, 'excelServiceCreationPercent') ? undefined : json['excelServiceCreationPercent'],
        'excelServiceChangeProductionPercent': !exists(json, 'excelServiceChangeProductionPercent') ? undefined : json['excelServiceChangeProductionPercent'],
        'excelServiceStrategyPercent': !exists(json, 'excelServiceStrategyPercent') ? undefined : json['excelServiceStrategyPercent'],
        'excelServiceProductionBroadcastPercent': !exists(json, 'excelServiceProductionBroadcastPercent') ? undefined : json['excelServiceProductionBroadcastPercent'],
        'excelServiceClientServicePercent': !exists(json, 'excelServiceClientServicePercent') ? undefined : json['excelServiceClientServicePercent'],
        'isUpSell': !exists(json, 'isUpSell') ? undefined : json['isUpSell'],
        'excelCopySize': !exists(json, 'excelCopySize') ? undefined : json['excelCopySize'],
        'excelPurchaseMethod': !exists(json, 'excelPurchaseMethod') ? undefined : json['excelPurchaseMethod'],
        'excelPurchaseFormInternet': !exists(json, 'excelPurchaseFormInternet') ? undefined : json['excelPurchaseFormInternet'],
        'excelCategoryInternet': !exists(json, 'excelCategoryInternet') ? undefined : json['excelCategoryInternet'],
        'excelFormatInternet': !exists(json, 'excelFormatInternet') ? undefined : json['excelFormatInternet'],
        'excelPurchaseFormInternetST': !exists(json, 'excelPurchaseFormInternetST') ? undefined : json['excelPurchaseFormInternetST'],
        'excelCategoryInternetST': !exists(json, 'excelCategoryInternetST') ? undefined : json['excelCategoryInternetST'],
        'excelFormatInternetST': !exists(json, 'excelFormatInternetST') ? undefined : json['excelFormatInternetST'],
        'excelLabconChannel': !exists(json, 'excelLabconChannel') ? undefined : json['excelLabconChannel'],
        'excelCategoryLabcon': !exists(json, 'excelCategoryLabcon') ? undefined : json['excelCategoryLabcon'],
        'excelTechnologyDictionary': !exists(json, 'excelTechnologyDictionary') ? undefined : json['excelTechnologyDictionary'],
        'excelRegionDictionary': !exists(json, 'excelRegionDictionary') ? undefined : json['excelRegionDictionary'],
        'excelFormatPress': !exists(json, 'excelFormatPress') ? undefined : json['excelFormatPress'],
        'excelFormatRadio': !exists(json, 'excelFormatRadio') ? undefined : json['excelFormatRadio'],
        'excelEmissionTypeRadio': !exists(json, 'excelEmissionTypeRadio') ? undefined : json['excelEmissionTypeRadio'],
        'excelEmissionTypeCinema': !exists(json, 'excelEmissionTypeCinema') ? undefined : json['excelEmissionTypeCinema'],
        'excelMedium': !exists(json, 'excelMedium') ? undefined : json['excelMedium'],
    };
}

export function GetExcelRowDtoToJSON(value?: GetExcelRowDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowId': value.rowId,
        'status': value.status,
        'emptyRow': value.emptyRow,
        'otherErrors': value.otherErrors,
        'positionNotFoundError': value.positionNotFoundError,
        'parameterErrors': value.parameterErrors,
        'calculationErrors': value.calculationErrors,
        'projectId': value.projectId,
        'projectNumber': value.projectNumber,
        'excelProjectNumber': value.excelProjectNumber,
        'companyId': value.companyId,
        'profileId': value.profileId,
        'positionName': value.positionName,
        'positionType': value.positionType,
        'positionId': value.positionId,
        'excelPositionName': value.excelPositionName,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'excelStartDate': value.excelStartDate,
        'excelEndDate': value.excelEndDate,
        'invoiceInstructionAttachment': value.invoiceInstructionAttachment,
        'duplicateMasterRowId': value.duplicateMasterRowId,
        'rcSalesTotal': value.rcSalesTotal,
        'salesNet': value.salesNet,
        'salesCommissionAmount': value.salesCommissionAmount,
        'salesNetTechnicalCost': value.salesNetTechnicalCost,
        'salesNetTotal': value.salesNetTotal,
        'rcPurchase': value.rcPurchase,
        'purchaseTechnicalCost': value.purchaseTechnicalCost,
        'purchaseNetTotal': value.purchaseNetTotal,
        'purchaseNet': value.purchaseNet,
        'netCustomerAccount': value.netCustomerAccount,
        'excelRcSalesTotal': value.excelRcSalesTotal,
        'excelSalesNet': value.excelSalesNet,
        'excelSalesCommissionAmount': value.excelSalesCommissionAmount,
        'excelSalesNetTechnicalCost': value.excelSalesNetTechnicalCost,
        'excelSalesNetTotal': value.excelSalesNetTotal,
        'excelRcPurchase': value.excelRcPurchase,
        'excelPurchaseTechnicalCost': value.excelPurchaseTechnicalCost,
        'excelPurchaseNetTotal': value.excelPurchaseNetTotal,
        'excelPurchaseNet': value.excelPurchaseNet,
        'excelNetCustomerAccount': value.excelNetCustomerAccount,
        'orderNumber': value.orderNumber,
        'ownName': value.ownName,
        'actionGroup': value.actionGroup,
        'action': value.action,
        'service': value.service,
        'additionalDescription': value.additionalDescription,
        'numberOfMedia': value.numberOfMedia,
        'copySize': value.copySize,
        'purchaseMethod': value.purchaseMethod,
        'purchaseFormInternet': value.purchaseFormInternet,
        'categoryInternet': value.categoryInternet,
        'formatInternet': value.formatInternet,
        'purchaseFormInternetST': value.purchaseFormInternetST,
        'categoryInternetST': value.categoryInternetST,
        'formatInternetST': value.formatInternetST,
        'labconChannel': value.labconChannel,
        'categoryLabcon': value.categoryLabcon,
        'technologyDictionary': value.technologyDictionary,
        'regionDictionary': value.regionDictionary,
        'formatPress': value.formatPress,
        'formatRadio': value.formatRadio,
        'emissionTypeRadio': value.emissionTypeRadio,
        'emissionTypeCinema': value.emissionTypeCinema,
        'medium': value.medium,
        'copySizeValuePl': value.copySizeValuePl,
        'purchaseMethodValuePl': value.purchaseMethodValuePl,
        'purchaseFormInternetValuePl': value.purchaseFormInternetValuePl,
        'categoryInternetValuePl': value.categoryInternetValuePl,
        'formatInternetValuePl': value.formatInternetValuePl,
        'purchaseFormInternetSTValuePl': value.purchaseFormInternetSTValuePl,
        'categoryInternetSTValuePl': value.categoryInternetSTValuePl,
        'formatInternetSTValuePl': value.formatInternetSTValuePl,
        'labconChannelValuePl': value.labconChannelValuePl,
        'categoryLabconValuePl': value.categoryLabconValuePl,
        'technologyDictionaryValuePl': value.technologyDictionaryValuePl,
        'regionDictionaryValuePl': value.regionDictionaryValuePl,
        'formatPressValuePl': value.formatPressValuePl,
        'formatRadioValuePl': value.formatRadioValuePl,
        'emissionTypeRadioValuePl': value.emissionTypeRadioValuePl,
        'emissionTypeCinemaValuePl': value.emissionTypeCinemaValuePl,
        'mediumValuePl': value.mediumValuePl,
        'copySizeValueEn': value.copySizeValueEn,
        'purchaseMethodValueEn': value.purchaseMethodValueEn,
        'purchaseFormInternetValueEn': value.purchaseFormInternetValueEn,
        'categoryInternetValueEn': value.categoryInternetValueEn,
        'formatInternetValueEn': value.formatInternetValueEn,
        'purchaseFormInternetSTValueEn': value.purchaseFormInternetSTValueEn,
        'categoryInternetSTValueEn': value.categoryInternetSTValueEn,
        'formatInternetSTValueEn': value.formatInternetSTValueEn,
        'labconChannelValueEn': value.labconChannelValueEn,
        'categoryLabconValueEn': value.categoryLabconValueEn,
        'technologyDictionaryValueEn': value.technologyDictionaryValueEn,
        'regionDictionaryValueEn': value.regionDictionaryValueEn,
        'formatPressValueEn': value.formatPressValueEn,
        'formatRadioValueEn': value.formatRadioValueEn,
        'emissionTypeRadioValueEn': value.emissionTypeRadioValueEn,
        'emissionTypeCinemaValueEn': value.emissionTypeCinemaValueEn,
        'mediumValueEn': value.mediumValueEn,
        'salesSurchargePercent': value.salesSurchargePercent,
        'salesDiscountPercent': value.salesDiscountPercent,
        'salesCommissionPercent': value.salesCommissionPercent,
        'purchaseDiscountPercent': value.purchaseDiscountPercent,
        'purchaseSurchargePercent': value.purchaseSurchargePercent,
        'serviceExternalWorkPercent': value.serviceExternalWorkPercent,
        'serviceCreationPercent': value.serviceCreationPercent,
        'serviceChangeProductionPercent': value.serviceChangeProductionPercent,
        'serviceStrategyPercent': value.serviceStrategyPercent,
        'serviceProductionBroadcastPercent': value.serviceProductionBroadcastPercent,
        'serviceClientServicePercent': value.serviceClientServicePercent,
        'excelSalesSurchargePercent': value.excelSalesSurchargePercent,
        'excelSalesDiscountPercent': value.excelSalesDiscountPercent,
        'excelSalesCommissionPercent': value.excelSalesCommissionPercent,
        'excelPurchaseDiscountPercent': value.excelPurchaseDiscountPercent,
        'excelPurchaseSurchargePercent': value.excelPurchaseSurchargePercent,
        'excelServiceExternalWorkPercent': value.excelServiceExternalWorkPercent,
        'excelServiceCreationPercent': value.excelServiceCreationPercent,
        'excelServiceChangeProductionPercent': value.excelServiceChangeProductionPercent,
        'excelServiceStrategyPercent': value.excelServiceStrategyPercent,
        'excelServiceProductionBroadcastPercent': value.excelServiceProductionBroadcastPercent,
        'excelServiceClientServicePercent': value.excelServiceClientServicePercent,
        'isUpSell': value.isUpSell,
        'excelCopySize': value.excelCopySize,
        'excelPurchaseMethod': value.excelPurchaseMethod,
        'excelPurchaseFormInternet': value.excelPurchaseFormInternet,
        'excelCategoryInternet': value.excelCategoryInternet,
        'excelFormatInternet': value.excelFormatInternet,
        'excelPurchaseFormInternetST': value.excelPurchaseFormInternetST,
        'excelCategoryInternetST': value.excelCategoryInternetST,
        'excelFormatInternetST': value.excelFormatInternetST,
        'excelLabconChannel': value.excelLabconChannel,
        'excelCategoryLabcon': value.excelCategoryLabcon,
        'excelTechnologyDictionary': value.excelTechnologyDictionary,
        'excelRegionDictionary': value.excelRegionDictionary,
        'excelFormatPress': value.excelFormatPress,
        'excelFormatRadio': value.excelFormatRadio,
        'excelEmissionTypeRadio': value.excelEmissionTypeRadio,
        'excelEmissionTypeCinema': value.excelEmissionTypeCinema,
        'excelMedium': value.excelMedium,
    };
}

