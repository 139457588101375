import React, { useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { JobConfigSearch } from '../../components/JobConfig/JobConfigSearch';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  CreateUpdateJobConfigDto,
  GetJobConfigDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/JobConfigContext';
import { JobConfigEditor } from '../../components/JobConfig/JobConfigEditor';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { GridGetterFunction } from '../../../common/components/Grid/GridDataTypes';

export const JobConfigView: React.FC = () => {
  const [jobConfig, setJobConfig] = useState<GetJobConfigDto>();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const pok = usePokCore();
  const [waiting, setWaiting] = useState<boolean>(false);
  const { t, tk } = useTranslation('administrationLng');

  const addEditJobConfig = async (
    jobConfigCU: CreateUpdateJobConfigDto,
    id?: string,
  ) => {
    const status = await validate(jobConfigCU);
    if (status.valid) {
      try {
        if (id) {
          await pok.jobConfig.update(id, jobConfigCU);
        } else {
          await pok.jobConfig.create(jobConfigCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const onRowClick = async (id: string) => {
    const getJobConfig = await pok.jobConfig.getById(id);
    setJobConfig(getJobConfig);
    setShow(true);
  };

  const handleAdd = () => {
    setJobConfig(undefined);
    setShow(true);
  };

  const handleClose = () => {
    setJobConfig(undefined);
    setShow(false);
  };

  const synchronizeJobs = async () => {
    setWaiting(true);
    pok.jobConfig
      .synchronizeAllJobs()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
      })
      .catch(() => {
        setWaiting(false);
      });
  };

  const getData = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.jobConfig.getAllByPortion(
          t,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok, t],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.administration.jobConfig)}
        </Card.Title>
        <Button className="mb-4" variant="outline-primary" onClick={handleAdd}>
          {t(tk.administration.jobConfigNew)}
        </Button>
        {!waiting && (
          <JobConfigSearch
            getData={getData}
            getCount={pok.jobConfig.getAllCount}
            onRowClick={onRowClick}
            refresh={refresh}
          />
        )}
        <JobConfigEditor
          jobConfig={convert(jobConfig)}
          readOnly={false}
          show={show}
          handleClose={handleClose}
          onCompleted={addEditJobConfig}
          id={jobConfig?.id}
          systemNumber={jobConfig?.systemNumber}
        />
        {waiting && <Waiting />}
        <Button variant="outline-primary" onClick={synchronizeJobs}>
          {t(tk.administration.jobConfigSynchronize)}
        </Button>
      </Card.Body>
    </Card>
  );
};
