export default {
  prLang: {
    importExcel: `Import projektów z arkuszy`,
    projects: 'Projekty',
    projectsAndTeams: 'Projekty i zespoły',
    projectsApplications: 'Wnioski do projektów',
    delayedProjects: 'Projekty po terminie',
    canceledProjects: 'Projekty do anulowania',
    project: 'Projekt',
    genitiveProject: 'projektu',
    genitiveProjectWithoutSpace: 'projektu',
    accusativeProject: 'projekt',
    genitiveProjectPlural: 'projektów',
    projectNumber: 'Numer projektu',
    internalProject: 'Projekt zlecający',
    projectList: 'Lista projektów',
    withProjects: 'z projektami',
    connectionsWithProjectsFVZ:
      'Powiązania z projektami zostaną wyjaśnione przez zespoły',
    estimate: 'Kosztorys',
    estimate2: 'kosztorysu',
    estimates: 'Kosztorysy',
    addNewProject: 'Dodawanie nowego projektu',
    newProjectName: 'Nazwa nowego projektu',
    chooseProject: 'Wybieranie projektu',
    sourceProject: 'Projekt źródłowy',
    sourceProject2: 'projekt źródłowy',
    addProject: 'Dodaj projekt',
    editProject: 'Edycja projektu',
    emptyProjectsList: 'Brak projektów w systemie.',
    projectNotFound: 'Nie znaleziono projektu o takim ID.',
    salesInvoiceToProject: 'do projektu',
    estimateItemPosition: 'Pozycja kosztorysu',
    addEstimateItemPosition: 'Dodaj pozycje kosztorysu',
    chooseEstimateItem: 'Wybieranie pozycji kosztorysu',
    estimateItemInvoiceModifyWarning:
      'Są już zapisane pozycje faktury. Czy na pewno chcesz zaktualizować pozycje kosztorysu?',
    estimateItemInvoiceTabTitle: 'Pozycje kosztorysu',
    deleteProjectWarning: 'Czy na pewno usunąć wybrany projekt?',
    deleteProjectTooltip: 'Usuń projekt',
    unsettledPurchaseInvoicesAssignedToProject:
      'Nierozliczone FVZ przypisane do projektów',
    purchaseInvoicesAssignedToProject: 'Faktury zakupu przypisane do projektów',
    orderResultProject: 'Projekt podzlecony',
    readyForInvoicing: 'Projekty gotowe do fakturowania',
    readyForCorrection: 'Projekty z ujemną kwotą do fakturowania',
    applicationUnblocks: 'Wnioski o odblokowanie kosztorysu',
    projectsListModifiedInvoiceAlert:
      'Lista projektów została zmodyfikowana. Po zapisie nastąpi zresetowanie pozycji kosztorysu oraz pozycji faktury.',
    selectProjectsPurchaseInvoiceAlert:
      'Aby dodać kilka projektów należy zaznaczyć je klikając w wiersz, zaznaczone projekty zmienią kolor na żółty. Po zapisaniu zmian, wybrane projekty (jeden lub więcej) zostaną dodane',
    selectProjectPurchaseInvoiceAlertError:
      'Proszę zaznaczyć jeden lub kilka projektów.',
    sameProject: 'tego samego projektu',
    projectLabel: 'Etykieta projektu',
    projectControl: 'Kontrola projektu',
    estimateMonth: 'Miesiąc kosztorysu',
    monthRangeEstimates: 'Zakres miesięcy kosztorysów',
    teamRelatedEstimates: 'Pozycje kosztorysu powiązane z zespołem',
    forProject: 'do projektu',
    projectPurchase: 'Zakup w projekcie',
    estimatePositionsSum: 'Suma pozycji kosztorysu',
    importProjectsFromFile: 'Import projektów z arkuszy',
    projectNo: 'Projekt nr sys.',
    printProjectNumber: 'Drukuj numer projektu',
    saleAndCommissionSum: 'Suma sprzedaży i prowizji kosztorysu',
    fromProject: 'z projektu',
    projectClient: 'Kontrahent projektu',
    noProjects: 'Brak projektów w systemie.',
    errCreateProjectSendApplication:
      'Założenie projektu w wybranym okresie jest już zablokowane, wyślij wniosek o założenie projektu.',
    parentProjectNumber: 'Projekt zleceniodawcy',
    askDownloadFVZ: 'Czy pobrać faktury FVZ dołączone do projektu?',
    askDownloadFVZDespiteWarnings:
      'Czy pobrać faktury FVZ dołączone do projektu pomimo następujących ostrzeżeń?',
    paePositionsList:
      'Lista pozycji w PAE dla projektu {{projectNo}} za {{date}}',
    maxDelay: 'Maks. opóźnienie projektu',
    thisProject: 'tym projektem',
    withoutTeamsProjects: 'Bez zespołów i projektów',
    settingUpProjects: 'Zakładanie projektów i uzupełnianie wniosków',
    importFromProjectToProject: 'Import pozycji z projektu do projektu',
    projectOrders: 'Zamówienia do projektów',
    importProjects: 'Import projektów z arkuszy',
    warningPurchaseAndSalesAmountDiff:
      'Łączna kwota zakupu z projektu zlecającego, jest różna od kwoty sprzedaży.',
    projectsByMonths: 'Projekty wg miesięcy',
    positionsProjects: 'Pozycje projektów',
    reportToBudgetByMonth: 'Raport do budżetów - wg miesięcy w projektach',
    pbProject: 'PB projektów',
  },
} as const;
