import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

import {
  CreateUpdateAttachmentDto,
  CreateUpdatePurchaseInvoiceDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow, {
  FormRow66,
  FormRow66R,
} from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { Option } from '../Selects/Selector';
import { ClientSelector } from '../Selects/ClientSelector';
import CurrencyControl from '../../../app/components/CurrencyControl';
import {
  InvoiceTypeEnum,
  InvoiceTypeObj,
} from '../../../common/pokCore/validation/enums';
import { createNewAttachment } from '../../../common/pokCore/contexts/PurchaseInvoiceContext';
import { AttachmentInput } from '../Attachments/AttachmentInput';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { isMainCurrencyPLN } from '../../../utils/currencyFormatUtils';
import { useTranslation } from '../../../common/hooks/useTranslation';
import TeamSelector from '../Selects/TeamSelector';

import { PurchaseInvoiceGeneralImportFVS } from './PurchaseInvoiceGeneralImportFVS';
import { PurchaseInvoiceGeneralFormCurrency } from './PurchaseInvoiceGeneralFormCurrency';
import { PurchaseInvoiceGeneralFormDates } from './PurchaseInvoiceGeneralFormDates';

interface PurchaseInvoiceEditorFormGeneralFormProps {
  purchaseInvoice: CreateUpdatePurchaseInvoiceDto;
  specialTeamIds?: string[];
  companyIdFVS?: string;
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  propertyChange: (obj: Partial<CreateUpdatePurchaseInvoiceDto>) => void;
  readOnly: boolean;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const PurchaseInvoiceGeneralForm: React.FC<
  PurchaseInvoiceEditorFormGeneralFormProps
> = props => {
  const notifications = useNotifications();
  const pok = usePokCore();
  const { t, tk } = useTranslation('fvzLng');

  const typeOptions = Object.entries(InvoiceTypeEnum).map(([key, value]) => ({
    value: key as InvoiceTypeEnum,
    label: t(InvoiceTypeObj[value]),
  }));

  const readOnly = props.readOnly || props.isLoading;

  const handleClientChange = (data: Option) => {
    const option = data as Option;
    pok.clients
      .getById(option.value)
      .then(({ country }) => {
        const isDomestic = country.isMain || !isMainCurrencyPLN();

        props.propertyChange({
          clientId: option?.value,
          type: isDomestic ? InvoiceTypeEnum.Domestic : InvoiceTypeEnum.Foreign,
        });
      })
      .catch(err => {
        notifications.caughtError(err);
      });
  };

  return (
    <Form className="d-grid gap-4 mb-3">
      <FormRow controlId="client" label={t(tk.finance.client)}>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <ClientSelector
              onChange={handleClientChange}
              valueId={props.purchaseInvoice.clientId}
              buildName={client =>
                `${client.name} ${client.nip ? `(${t(tk.finance.nip)}: ${client.nip})` : ''}`
              }
              readOnly={
                readOnly || !!props.extendedPurchaseInvoice?.projectIds?.length
              }
            />
          </div>
          <div className="flex-grow-0 ps-4">
            <PurchaseInvoiceGeneralImportFVS
              {...props}
              propertyChange={props.propertyChange}
            />
          </div>
        </div>
      </FormRow>
      <Row>
        <Col md={6}>
          <FormRow66 controlId="number" label={t(tk.finance.invoiceNumber)}>
            <Form.Control
              type="text"
              value={props.purchaseInvoice?.number || ''}
              onChange={e =>
                props.propertyChange({ number: e.target.value || undefined })
              }
              disabled={readOnly}
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R controlId="type" label={t(tk.finance.invoiceType)}>
            <Select
              className="full-width"
              value={typeOptions.find(
                ({ value }) => props.purchaseInvoice?.type === value,
              )}
              options={typeOptions}
              onChange={option =>
                props.propertyChange({
                  type: option?.value,
                })
              }
              isDisabled={readOnly}
            />
          </FormRow66R>
        </Col>
      </Row>
      <PurchaseInvoiceGeneralFormCurrency {...props} />
      <PurchaseInvoiceGeneralFormDates {...props} />
      <Row>
        <Col md={6}>
          <FormRow66 controlId="amount" label={t(tk.finance.invoiceAmount)}>
            <CurrencyControl
              decimalScale={2}
              value={props.purchaseInvoice?.amount}
              allowNegative
              disabled={true}
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R
            controlId="amountWithoutPosition"
            label={t(tk.finance.unsettledAmount)}
          >
            <CurrencyControl
              decimalScale={2}
              value={props.purchaseInvoice?.amountWithoutPosition}
              allowNegative
              disabled={true}
            />
          </FormRow66R>
        </Col>
      </Row>
      <FormRow controlId="attachment" label={t(tk.attachment.attachment)}>
        <AttachmentInput
          attachment={props.purchaseInvoice?.attachment}
          accept="image/*,application/pdf"
          onChange={(attachment?: CreateUpdateAttachmentDto) =>
            props.propertyChange({ attachment })
          }
          createNewAttachment={createNewAttachment}
          readOnly={readOnly}
          companyId={props.purchaseInvoice.companyId}
        />
      </FormRow>
      <FormRow controlId="comment" label={t(tk.attachment.comment)}>
        <DynamicTextarea
          value={props.purchaseInvoice?.comment ?? ''}
          onChange={e =>
            props.propertyChange &&
            props.propertyChange({ comment: e.target.value })
          }
          disabled={readOnly}
        />
      </FormRow>
      <Row>
        <Col md={6}>
          <FormRow66 controlId="tvInvoice" label={t(tk.finance.tvInvoice)}>
            <Form.Check
              type="checkbox"
              className="pt-2"
              checked={!!props.purchaseInvoice.tv}
              onChange={e => props.propertyChange({ tv: e.target.checked })}
              disabled={readOnly}
            />
          </FormRow66>
        </Col>
        {props.purchaseInvoice?.salesInvoiceId && (
          <Col md={6}>
            <FormRow66
              controlId="specialTeam"
              label={t(tk.finance.invoicingTeams)}
            >
              <TeamSelector
                value={props.specialTeamIds}
                companyId={props.companyIdFVS}
                isMulti={true}
                readOnly={true}
              />
            </FormRow66>
          </Col>
        )}
      </Row>
    </Form>
  );
};
