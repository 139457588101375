import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import type { TFunction } from 'i18next';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  CreateUpdatePurchaseInvoiceEstimateItemDto,
  GetEstimateItemDto,
  GetPurchaseInvoiceEstimateItemDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import mathUtils from '../../../utils/mathUtils';
import { TKeys } from '../../../translations';

import {
  checkAmountInfo,
  getSumData,
  SumData,
} from './purchaseInvoicePositionsGridUtils';
import { PurchaseInvoiceTabsEnum } from './PurchaseInvoiceTabsEnum';
import { checkDataChanged } from './checkDataChanged';
import { FiltersParams } from './usePurchaseInvoiceEstimateItemsFilters';

export interface UsePurchaseInvoiceEstimateItemsReturn {
  purchaseInvoiceEstimateItems: CreateUpdatePurchaseInvoiceEstimateItemDto[];
  availableEstimateItems: GetEstimateItemDto[];
  allAvailableEstimateItems: GetEstimateItemDto[];
  handleAmountChange: (
    estimateItemId: string,
    item: CreateUpdatePurchaseInvoiceEstimateItemDto | undefined,
    amount: string | null | undefined,
  ) => void;
  warnings: string[];
  sumData: SumData;
  handleFillAllAmounts: () => void;
  isListEmpty: boolean;
}

interface UsePurchaseInvoiceEstimateItemsParams {
  applyFiltersToEstimateItems: (
    estimateItems: GetEstimateItemDto[],
    onlyTV?: boolean,
  ) => GetEstimateItemDto[];
  filters: FiltersParams;
  setNotSavedTab: Dispatch<SetStateAction<PurchaseInvoiceTabsEnum | undefined>>;
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  onlyTV?: boolean;
  t: TFunction<'fvzLng', 'fvzLng'>;
  tk: TKeys<'fvzLng'>;
}

const convertPurchaseInvoiceEstimateItems = (
  items: GetPurchaseInvoiceEstimateItemDto[],
): CreateUpdatePurchaseInvoiceEstimateItemDto[] =>
  items.map(({ estimateItemId, amount }) => ({
    estimateItemId,
    amount,
  }));

const fillAmounts = ({
  availableEstimateItems,
  extendedPurchaseInvoice,
  applyFiltersToEstimateItems,
  purchaseInvoiceEstimateItems,
  onlyTV,
}: UsePurchaseInvoiceEstimateItemsParams & {
  availableEstimateItems: GetEstimateItemDto[];
  purchaseInvoiceEstimateItems: CreateUpdatePurchaseInvoiceEstimateItemDto[];
}) => {
  const notVisibleItems = purchaseInvoiceEstimateItems.filter(
    ({ estimateItemId }) =>
      !availableEstimateItems.map(({ id }) => id).includes(estimateItemId),
  );

  const updatedItems = availableEstimateItems
    ?.map(item => {
      const savedItem =
        extendedPurchaseInvoice?.purchaseInvoiceEstimateItems?.find(
          ({ estimateItemId }) => estimateItemId === item.id,
        );
      if (
        applyFiltersToEstimateItems(availableEstimateItems, onlyTV).includes(
          item,
        )
      ) {
        const notAssignedAmount = mathUtils.subtract(
          item.purchaseNetTotal,
          item.purchaseInvoicesSum,
        );

        return {
          estimateItemId: item.id,
          amount: mathUtils
            .round(mathUtils.add(savedItem?.amount || 0, notAssignedAmount))
            .toString(),
        };
      }

      return (
        purchaseInvoiceEstimateItems.find(
          pI => pI.estimateItemId === item.id,
        ) || { amount: '0', estimateItemId: item.id }
      );
    })
    .filter(({ amount }) => mathUtils.round(amount) !== 0);

  return [...updatedItems, ...notVisibleItems];
};

function usePurchaseInvoiceEstimateItems(
  args: UsePurchaseInvoiceEstimateItemsParams,
): UsePurchaseInvoiceEstimateItemsReturn {
  const {
    applyFiltersToEstimateItems,
    filters,
    setNotSavedTab,
    extendedPurchaseInvoice,
    onlyTV,
    t,
    tk,
  } = args;

  const pok = usePokCore();
  const notifications = useNotifications();
  const [
    originalPurchaseInvoiceEstimateItems,
    setOriginalPurchaseInvoiceEstimateItems,
  ] = useState<CreateUpdatePurchaseInvoiceEstimateItemDto[]>([]);
  const [purchaseInvoiceEstimateItems, setPurchaseInvoiceEstimateItems] =
    useState<CreateUpdatePurchaseInvoiceEstimateItemDto[]>([]);
  const [availableEstimateItems, setAvailableEstimateItems] = useState<
    GetEstimateItemDto[]
  >([]);
  const [sumData, setSumData] = useState<SumData>({
    amountSum: 0,
    maxSum: 0,
    notAssignedSum: 0,
    purchaseNetTotalSum: 0,
    unsettledAmount: 0,
  });
  const [warnings, setWarnings] = useState<string[]>([]);

  const updatePurchaseInvoiceEstimateItems = (
    items: CreateUpdatePurchaseInvoiceEstimateItemDto[],
  ) => {
    checkDataChanged(
      originalPurchaseInvoiceEstimateItems,
      items,
      PurchaseInvoiceTabsEnum.ESTIMATE_ITEM_POSITIONS,
      setNotSavedTab,
    );

    setTimeout(() => {
      setPurchaseInvoiceEstimateItems(items);
    }, 1);
  };

  const propertyChange = (obj: CreateUpdatePurchaseInvoiceEstimateItemDto) => {
    const updatedItems = purchaseInvoiceEstimateItems.map(estimateItem => {
      if (estimateItem.estimateItemId === obj.estimateItemId) {
        return { ...estimateItem, ...obj };
      }

      return estimateItem;
    });

    updatePurchaseInvoiceEstimateItems(updatedItems);
  };

  const handleAmountChange = (
    estimateItemId: string,
    item: CreateUpdatePurchaseInvoiceEstimateItemDto | undefined,
    value: string | null | undefined,
  ) => {
    const amount = value || '0';

    if (item?.estimateItemId) {
      return propertyChange({ ...item, amount });
    }

    const updatedItems = [
      ...purchaseInvoiceEstimateItems,
      { estimateItemId, amount },
    ].filter(({ amount }) => mathUtils.round(amount) !== 0);

    updatePurchaseInvoiceEstimateItems(updatedItems);
  };

  const handleFillAllAmounts = () => {
    const updatedItems = fillAmounts({
      ...args,
      availableEstimateItems,
      purchaseInvoiceEstimateItems,
    });

    updatePurchaseInvoiceEstimateItems(updatedItems);
  };

  useEffect(() => {
    if (extendedPurchaseInvoice && extendedPurchaseInvoice.projectIds) {
      pok.estimateItems
        .findForPurchaseInvoiceByClientAndProjects(
          extendedPurchaseInvoice.id,
          extendedPurchaseInvoice.client.id,
          extendedPurchaseInvoice.projectIds,
        )
        .then(setAvailableEstimateItems)
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [extendedPurchaseInvoice, notifications, pok.estimateItems]);

  useEffect(() => {
    if (extendedPurchaseInvoice) {
      pok.purchaseInvoices
        .getEstimateItems(extendedPurchaseInvoice.id)
        .then(items => {
          const converted = convertPurchaseInvoiceEstimateItems(items);
          setPurchaseInvoiceEstimateItems(converted);
          setOriginalPurchaseInvoiceEstimateItems(converted);
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [extendedPurchaseInvoice, notifications, pok.purchaseInvoices]);

  useEffect(() => {
    const sum = getSumData(
      purchaseInvoiceEstimateItems,
      availableEstimateItems,
      applyFiltersToEstimateItems(availableEstimateItems, onlyTV),
      extendedPurchaseInvoice,
    );

    setSumData(sum);
    setWarnings(checkAmountInfo(t, tk, sum.maxSum, sum.amountSum));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    availableEstimateItems,
    extendedPurchaseInvoice,
    purchaseInvoiceEstimateItems,
    filters,
  ]);

  return {
    availableEstimateItems: applyFiltersToEstimateItems(
      availableEstimateItems,
      onlyTV,
    ),
    allAvailableEstimateItems: availableEstimateItems,
    purchaseInvoiceEstimateItems,
    handleAmountChange,
    warnings,
    sumData,
    handleFillAllAmounts,
    isListEmpty: !availableEstimateItems.length,
  };
}

export default usePurchaseInvoiceEstimateItems;
