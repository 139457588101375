import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  CreateUpdatePurchaseInvoiceDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import ValidationAlert from '../../../app/components/ValidationAlert';

import { PurchaseInvoiceGeneralForm } from './PurchaseInvoiceGeneralForm';
import { checkDataChanged } from './checkDataChanged';
import { PurchaseInvoiceTabsEnum } from './PurchaseInvoiceTabsEnum';
import { PurchaseInvoiceEditorFormPositions } from './PurchaseInvoiceGeneralFormPositions';
import { PurchaseInvoiceEditorFormGeneralControls } from './PurchaseInvoiceGeneralFormControls';

interface PurchaseInvoiceGeneralTabProps {
  purchaseInvoice: CreateUpdatePurchaseInvoiceDto;
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  specialTeamIds?: string[];
  salesInvoiceCompanyId?: string;
  setSalesInvoiceId: Dispatch<SetStateAction<string | undefined>>;
  setNotSavedTab: Dispatch<SetStateAction<PurchaseInvoiceTabsEnum | undefined>>;
  handleRefresh: (id?: string) => Promise<void>;
  readOnly: boolean;
  changeAmountOwnerName?: string;
}

export const PurchaseInvoiceGeneralTab: React.FC<
  PurchaseInvoiceGeneralTabProps
> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [purchaseInvoice, setPurchaseInvoice] =
    useState<CreateUpdatePurchaseInvoiceDto>(props.purchaseInvoice);

  const propertyChange = (obj: Partial<CreateUpdatePurchaseInvoiceDto>) => {
    const updatedPurchaseInvoice = { ...purchaseInvoice, ...obj };
    checkDataChanged(
      props.purchaseInvoice,
      updatedPurchaseInvoice,
      PurchaseInvoiceTabsEnum.GENERAL,
      props.setNotSavedTab,
    );
    setPurchaseInvoice(updatedPurchaseInvoice);
    props.setSalesInvoiceId(updatedPurchaseInvoice.salesInvoiceId);
  };

  useEffect(() => {
    setPurchaseInvoice(props.purchaseInvoice);
  }, [props.purchaseInvoice]);

  return (
    <>
      <PurchaseInvoiceGeneralForm
        {...props}
        purchaseInvoice={purchaseInvoice}
        propertyChange={propertyChange}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        specialTeamIds={props.specialTeamIds}
        companyIdFVS={props.salesInvoiceCompanyId}
      />
      <PurchaseInvoiceEditorFormPositions
        {...props}
        purchaseInvoice={purchaseInvoice}
        propertyChange={propertyChange}
      />
      <ValidationAlert
        show={!!saveErrors.length}
        errors={saveErrors}
        className="mt-4"
      />
      <PurchaseInvoiceEditorFormGeneralControls
        {...props}
        purchaseInvoice={purchaseInvoice}
        setSaveErrors={setSaveErrors}
        changeAmountOwnerName={props.changeAmountOwnerName}
      />
    </>
  );
};
