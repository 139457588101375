import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { GetOrderExecutionDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import {
  LanguageType,
  OrderExecutionStatusEnum,
} from '../../../common/pokCore/validation/enums';
import Button from '../../../common/components/Button/Button';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useAuth } from '../../../common/hooks/useAuth';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface OrderExecutionDecisionControlsProps {
  orderExecution?: GetOrderExecutionDto;
  handleAccept: () => void;
  handleReject: (rejectedNote?: string) => void;
  handleDownload: (lang: LanguageType) => void;
  handleInactive: () => void;
  isLoading: boolean;
}

export const OrderExecutionDecisionControls: React.FC<
  OrderExecutionDecisionControlsProps
> = props => {
  const pok = usePokCore();
  const auth = useAuth();
  const { t, tk } = useTranslation('orderLng');

  const isOrderCompleted =
    props.orderExecution?.status !== OrderExecutionStatusEnum.New ||
    !props.orderExecution.active;

  const authorized = props.orderExecution?.id
    ? auth.check(PermissionsEnum.Administration, pok.getCompanyId())
    : false;

  return (
    <>
      <div className="d-flex marginLeft gap-2">
        {authorized && props.orderExecution?.active && (
          <ConfirmationButton
            variant="outline-danger"
            confirmation={t(tk.orderExec.askDeleteOrderExec)}
            confirmLabel={t(tk.orderExec.deleteOrderExecution)}
            rejectLabel={t(tk.button.cancel)}
            dynamicInput
            focusInput
            onOK={props.handleInactive}
          >
            {t(tk.orderExec.deleteOrderExec)}
          </ConfirmationButton>
        )}
        {isOrderCompleted &&
          props.orderExecution?.status ===
            OrderExecutionStatusEnum.Accepted && (
            <DropdownButton
              title={t(tk.orderExec.getConfirmation)}
              id="clone-dropdown"
              variant="outline-primary"
              className="mx-1 d-inline-block"
            >
              <Dropdown.Item
                onClick={() => props.handleDownload(LanguageType.PL)}
                eventKey="1"
              >
                {t(tk.orderExec.polishPrintout)}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.handleDownload(LanguageType.EN)}
                eventKey="2"
              >
                {t(tk.orderExec.englishPrintout)}
              </Dropdown.Item>
            </DropdownButton>
          )}
        <ConfirmationButton
          variant="outline-danger"
          confirmation={t(tk.orderExec.rejectionOrderExec)}
          confirmLabel={t(tk.orderExec.rejectOrderExec)}
          errorLabel={t(tk.orderExec.pleaseFillRejectionReason)}
          reasonText={t(tk.orderExec.rejectionReason)}
          rejectLabel={t(tk.button.cancel)}
          dynamicInput
          focusInput
          askForAReason
          reasonNotEmpty
          onOK={props.handleReject}
          disabled={isOrderCompleted}
          isLoading={props.isLoading}
        >
          {t(tk.orderExec.rejectOrderExec)}
        </ConfirmationButton>
        <Button
          disabled={isOrderCompleted}
          onClick={props.handleAccept}
          isLoading={props.isLoading}
        >
          {t(tk.orderExec.acceptOrderExec)}
        </Button>
      </div>
    </>
  );
};
