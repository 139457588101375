import React from 'react';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateProjectWithTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient/models';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { NewDatePicker } from '../../../app/components/NewDatePicker';

interface ProjectDatesFormProps {
  project: CreateUpdateProjectWithTeamDto;
  readOnly?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateProjectWithTeamDto>) => void;
  startDateChanged?: (date: Date) => void;
}

const MIN_PROJECT_DATE = '2024-01-01';

export const ProjectDatesForm: React.FC<ProjectDatesFormProps> = props => {
  const { t, tk } = useTranslation('projectLng');

  const handleDateEndChange = (endDate: Date) => {
    if (props.propertyChange) {
      props.propertyChange({ endDate });
    }
  };

  return (
    <>
      <FormRow controlId="startDate" label={t(tk.common.startDate)}>
        <NewDatePicker
          value={props.project.startDate}
          dateChanged={props.startDateChanged}
          readOnly={props.readOnly}
          min_date={MIN_PROJECT_DATE}
        />
      </FormRow>
      <FormRow controlId="endDate" label={t(tk.common.endDate)}>
        <NewDatePicker
          value={props.project.endDate}
          dateChanged={handleDateEndChange}
          readOnly={props.readOnly}
        />
      </FormRow>
    </>
  );
};
