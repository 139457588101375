export default {
  administration: {
    // companies
    companyAdd: 'Adding a new company',
    companyEdit: 'Editing company no. sys.',
    companyNew: 'New company',
    noCompanies: 'No companies in the system',
    logo: 'Logo',
    personalLanguage: 'Naming',
    language: 'Language',
    color: 'Color',
    invoiceTemplate: 'Invoice template',
    noFull: 'Item number',
    emailInvoice: 'Invoice email',
    footer: 'Footer',
    footerEn: 'Footer in English',
    uploadGraphicFile: 'Upload graphic file',
    showMargin: 'Show margin',

    // employees
    synchrWithOneTool: 'Synchronize with OneTool',
    all: 'All',
    inactive: 'Inactive with permissions',
    deletePermissions: 'Delete permissions',
    deleteUserPermissions: 'Delete user permissions',
    askDeleteUserPermissions:
      'Are you sure you want to delete all permissions assigned to this user?',
    askDeletePermissions:
      'Are you sure you want to delete all permissions for all inactive users?',
    position: 'Position',
    oneToolId: 'One Tool ID',
    nameSurname: 'Name and surname',
    login: 'Login',
    email: 'Email',
    supervisor: 'Supervisor',
    manager: 'Manager',
    technical: 'Technical',
    noEmployees: 'No employees in the system',

    // teams
    teamNew: 'New team',
    teamEdit: 'Editing team no. sys.',
    teamAdd: 'Adding a new team',
    managerName: 'Team manager',
    noTeams: 'No teams in the system',
    paeSupport: 'Supports PAE',
    tvSupport: 'Supports TV',

    // media plan profiles
    estimateProfiles: 'Mediaplan profiles',
    estimateProfileNew: 'New profile',
    estimateProfileAdd: 'Adding a new profile',
    estimateProfileEdit: 'Editing profile',
    noEstimateProfiles: 'No mediaplan profiles in the system',
    commissions: 'Commissions',
    relatedTeams: 'Related teams',
    tv: 'TV',
    tvBuying: 'TV buying',
    autoSalesBlockDisabled: 'Auto sales block disabled',
    absence: 'Absence',
    visible: 'Visible',
    obligatory: 'Mandatory',

    // profile categories
    profileCategory: 'Profile category',
    profileCategories: 'Profile categories',
    profileCategoryAdd: 'Adding a new profile category',
    profileCategoryNew: 'New profile category',
    profileCategoryEdit: 'Editing profile category',
    noProfileCategories: 'No profile categories in the system.',
    salesFinancialAccount: 'Sales financial account',
    purchasesFinancialAccount: 'Purchases financial account',
    commissionsFinancialAccount: 'Commissions financial account',
    profile: 'Profile',

    // profile fields
    sales: 'Sales',
    rcSalesTotal: 'RC Sales Total',
    salesSurchargePercent: 'Sales surcharge %',
    salesDiscountPercent: 'Sales discount %',
    salesNet: 'Sales Net',
    salesCommissionPercent: 'Sales commission %',
    salesCommissionAmount: 'Sales commission amount',
    salesNetTechnicalCost: 'Sales net technical cost',
    salesNetTotal: 'Sales net total',

    buy: 'Purchase',
    rcPurchase: 'RC Purchase',
    purchaseSurchargePercent: 'Purchase surcharge %',
    purchaseDiscountPercent: 'Purchase discount %',
    purchaseNet: 'Purchase net',
    purchaseTechnicalCost: 'Purchase technical cost',
    purchaseNetTotal: 'Purchase net total',

    media: 'Media',
    numberOfMedia: 'Number of media',
    technologyDictionary: 'Technology (dictionary)',
    regionDictionary: 'Region (dictionary)',
    additionalDescription: 'Additional description',

    format: 'Format',
    formatRadio: 'Format (Radio)',
    formatPress: 'Format (Press)',
    formatInternet: 'Format (Internet)',
    formatInternetST: 'Format (Internet ST)',

    category: 'Category',
    categoryInternet: 'Category (Internet)',
    categoryInternetST: 'Category (Internet ST)',
    categoryLabcon: 'Category (Labcon)',

    purchase: 'Purchase form',
    purchaseFormInternet: 'Purchase form (Internet)',
    purchaseFormInternetST: 'Purchase form (Internet ST)',
    purchaseMethod: 'Purchase method (TV)',

    emissionType: 'Broadcast type',
    emissionTypeRadio: 'Broadcast type (Radio)',
    emissionTypeCinema: 'Broadcast type (Cinema)',

    service: 'Service',
    action: 'Action',
    actionGroup: 'Action group',
    serviceExternalWorkPercent: 'External work %',
    serviceCreationPercent: 'Creation %',
    serviceChangeProductionPercent: 'Change production %',
    serviceStrategyPercent: 'Strategy %',
    serviceProductionBroadcastPercent: 'Broadcast production %',
    serviceClientServicePercent: 'Client service %',

    additionalInformation: 'Other',
    isUpSell: 'Is upsell',
    netCustomerAccount: 'Net customer account',
    copySize: 'Copy length',
    orderNumber: 'Order number',
    medium: 'Medium (Foreign)',
    ownName: 'Own name',
    labconChannel: 'Labcon channel',

    // email configurations
    mailConfig: 'Email configuration',
    mailConfigAdd: 'Adding a new configuration',
    mailConfigNew: 'New configuration',
    mailConfigEdit: 'Editing configuration no. sys.',
    noMailConfigs: 'No email configurations in the system.',
    type: 'Type',
    emails: 'Email addresses',
    mailConfigType: 'Configuration type',

    // task configurations
    jobConfig: 'Cyclic task configuration',
    jobConfigAdd: 'Adding a new configuration',
    jobConfigNew: 'New configuration',
    jobConfigEdit: 'Editing configuration no. sys.',
    noJobConfigs: 'No task configurations in the system.',
    jobConfigType: 'Configuration type',
    cron: 'Frequency',
    jobConfigSynchronize: 'Synchronize all tasks',
    cronError: 'Error occurred while generating frequency:',

    // bank accounts
    bankAccounts: 'Bank accounts',
    bankAccountNew: 'New bank account',
    bankAccountAdd: 'Adding a new bank account',
    bankAccountEdit: 'Editing bank account',
    noBankAccounts: 'No bank accounts in the system.',
    bankName: 'Bank name',
    accountNumber: 'Bank account number',
    swift: 'Swift BIC',
    swiftLong: 'Swift Bank Identity Code',
    invoiceType: 'Invoice type',
    VATAccountNumber: 'VAT - Bank account number',
    VATBankName: 'VAT - Bank name',

    //calendar
    calendarWorkingDay: 'Working days calendar',
    createYear: 'Create year',
    askAdd: 'Are you sure you want to add the day',
    askDelete: 'Are you sure you want to delete the day',
    to: 'to the working days calendar?',
    from: 'from the working days calendar?',
    addCalendar: 'Adding a working day',
    deleteCalendar: 'Deleting a working day',
    askAddYear: 'Are you sure you want to create the year',
    askDeleteYear: 'Are you sure you want to delete the year',
    inSystem: 'in the system?',
    warning:
      'Warning, if the year is already created, it will be deleted and recreated.',

    //dictionaries
    dictionaries: 'System dictionaries',
    dictionarySelectedDelete: 'Deleting the selected value',
    dictionarySelectedEdit: 'Editing the selected value',
    dictionarySelectedAdd: 'Adding an item under the selected value',
    dictionaryDelete: 'Deleting from the dictionary',
    dictionaryAskDelete: 'Are you sure you want to delete the value:',
    dictionaryEdit: 'Dictionary edit',
    dictionaryAdd: 'Adding value to dictionary',
    value: 'Value',
    valueEN: 'Value in English',
    symbol: 'Symbol',

    errorAttachments: 'Error while fetching attachments.',
  },
} as const;
