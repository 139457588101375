/**
 * This file is shared between pok-core & pok-web
 * If you have to change it then do it in pok-core and run yarn get:validation-schemas @pok-web
 */

import * as yup from 'yup';
import * as _ from 'lodash';
import * as iban from 'iban';

import {
  ApplicationStatusEnum,
  ApplicationTypeEnum,
  ApplicationUnblockStatusEnum,
  ApplicationUnblockTypeEnum,
  AttachmentObjectType,
  CurrencyIsoTypeEnum,
  EmailConfigTypeEnum,
  InvoiceTypeEnum,
  JobTypeEnum,
  PaymentFormEnum,
  PositionTypeEnum,
  SpecialInvoicingStatus,
  VatEnum,
} from './enums';
import { tkVal } from './tk';

const digits = /^[0-9]+$/;

function validNip(nip: string | null | undefined) {
  if (typeof nip !== 'string' || nip.length === 0) {
    return true;
  }

  nip = nip.replace(/[ -]/gi, '');
  if (nip.length !== 10) {
    return false;
  }
  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }
  return sum % 11 === controlNumber;
}

export const validPesel = (pesel?: string | null) => {
  if (!pesel) {
    return true;
  }

  if (typeof pesel !== 'string') {
    return false;
  }

  pesel = pesel.replace(/[ -]/gi, '');
  if (pesel.length !== 11) {
    return false;
  }

  const weight = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
  let sum = 0;

  for (let i = 0; i < weight.length; i++) {
    sum += parseInt(pesel.substring(i, i + 1), 10) * weight[i];
  }

  sum = sum % 10;

  const controlDigit = parseInt(pesel.substring(10, 11), 10);
  return sum === controlDigit;
};

function digitsOnly(text: string | null | undefined) {
  if (!text) {
    return true;
  }

  if (!text.match(digits)) {
    return false;
  }
  return true;
}

const nipOrPeselValidation = function (values: {
  countryIsMain: boolean;
  nip?: string;
  pesel?: string;
}) {
  const { countryIsMain, nip, pesel } = values;

  if (countryIsMain && !nip && !pesel) {
    return false;
  }

  return true;
};

export function formatAccountNumber(number: string) {
  if (iban.isValid(number) || iban.isValid('PL' + number)) {
    const cleanNumber = number.replace(/\s+/g, '');
    const hasPrefix = isNaN(+cleanNumber.substring(0, 2));

    const format = (str: string) => str.replace(/(.{4})/g, '$1 ').trim();

    if (!hasPrefix) {
      const prefix = cleanNumber.substring(0, 2);
      const rest = cleanNumber.substring(2);
      return `${prefix} ${format(rest)}`;
    }

    return format(cleanNumber);
  } else {
    return number;
  }
}

function validPostcode(postcode: string | null | undefined) {
  if (!postcode) {
    return false;
  }

  const match = postcode.match(/^[0-9]{2}-[0-9]{3}$/);
  if (!match) {
    return false;
  }
  return true;
}

const IBANSchema = yup
  .string()
  .test(
    'is-valid-iban',
    tkVal.schemas.invalidAccountNumber,
    value =>
      value !== null &&
      (iban.isValid(value || '') || iban.isValid('PL' + value || '')),
  );

export const NipSchema = yup.object().shape({
  nip: yup
    .string()
    .nullable()
    .required(tkVal.schemas.provideNip)
    .test('valid nip', tkVal.schemas.invalidNip, validNip)
    .test('only digits in nip', tkVal.schemas.nipDigitsOnly, digitsOnly),
});

interface Parent {
  parent: { [key: string]: Date };
}

const notEarlierThan = (otherDate: string) => {
  const compare = function (this: Parent, value: Date | undefined) {
    if (!this) {
      throw new Error('this is undefined');
    }

    const date = this.parent[otherDate];
    if (!date || !value || !(date instanceof Date)) {
      return true;
    }

    const thisDate = new Date(value).setHours(0, 0, 0, 0);
    const thatDate = new Date(date).setHours(0, 0, 0, 0);

    return thisDate >= thatDate;
  };
  return compare;
};

const ClientSchemaFields = {
  name: yup.string().nullable().required(tkVal.schemas.provideClientName),
  countryId: yup.string().nullable().required(tkVal.schemas.provideCountry),
  countryIsMain: yup.boolean().nullable().required(),
  nip: yup.string().when('countryIsMain', {
    is: (val: boolean) => val === true,
    then: schema =>
      schema
        .nullable()
        .test('valid nip', tkVal.schemas.invalidNip, validNip)
        .test('only digits in nip', tkVal.schemas.nipDigitsOnly, digitsOnly),
    otherwise: schema => schema.nullable(),
  }),
  pesel: yup.string().when('countryIsMain', {
    is: (val: boolean) => val === true,
    then: schema =>
      schema
        .nullable()
        .test('valid pesel', tkVal.schemas.invalidPesel, validPesel)
        .test(
          'only digits in pesel',
          tkVal.schemas.peselDigitsOnly,
          digitsOnly,
        ),
    otherwise: schema => schema.nullable(),
  }),
  postcode: yup.string().when('countryIsMain', {
    is: (val: boolean) => val === true,
    then: schema =>
      schema
        .nullable()
        .test('valid postcode', tkVal.schemas.postcodeFormat, validPostcode),
    otherwise: schema => schema.nullable(),
  }),
  place: yup.string().when('countryIsMain', {
    is: (val: boolean) => val === true,
    then: schema => schema.nullable().required(tkVal.schemas.providePlace),
    otherwise: schema => schema.nullable(),
  }),
  addressEx: yup.string().when('countryIsMain', {
    is: (val: boolean) => val === true,
    then: schema => schema.nullable(),
    otherwise: schema =>
      schema.nullable().required(tkVal.schemas.provideForeignAddress),
  }),
};

export const ClientSchema = yup
  .object()
  .shape({
    ...ClientSchemaFields,
  })
  .test('nip-or-pesel', tkVal.schemas.nipOrPeselRequired, nipOrPeselValidation);

export const CompanySchema = yup.object().shape({
  name: yup.string().nullable().required(tkVal.schemas.provideCompanyName),
  shortname: yup
    .string()
    .nullable()
    .required(tkVal.schemas.provideCompanyShortname),
  personalLanguage: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseNamingConvention),
  projectMaxDaysDelay: yup
    .number()
    .integer(tkVal.schemas.maxDaysDelayInteger)
    .typeError(tkVal.schemas.maxDaysDelayInteger)
    .min(0, tkVal.schemas.maxDaysDelayInteger)
    .nullable()
    .required(tkVal.schemas.provideMaxCampaignDelay),
  orderField: yup
    .number()
    .integer(tkVal.schemas.orderNumberInteger)
    .typeError(tkVal.schemas.orderNumberInteger)
    .min(0, tkVal.schemas.orderNumberInteger)
    .nullable(),
  templateType: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseInvoiceTemplate),
  mainCurrencyId: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseMainCurrency),
  language: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseCompanyLanguage),
});

export const DictionarySchema = yup.object().shape({
  value: yup.string().nullable().required(tkVal.schemas.provideDictionaryValue),
  valueEn: yup
    .string()
    .nullable()
    .required(tkVal.schemas.provideDictionaryValueEn),
});

export const RoleSchema = yup.object().shape({
  name: yup.string().nullable().required(tkVal.schemas.provideRoleName),
  permissions: yup
    .array()
    .of(yup.string())
    .required(tkVal.schemas.choosePermissions),
});

export const EmployeeRoleSchema = yup.object().shape({
  userId: yup.string().nullable().required(tkVal.schemas.chooseEmployee),
  roleId: yup.string().nullable().required(tkVal.schemas.chooseRole),
});

export const EmployeeRolesSchema = yup.object().shape({
  employeeId: yup.string().nullable().required(tkVal.schemas.chooseUser),
  rolesIds: yup
    .array()
    .of(yup.string().nullable())
    .required(tkVal.schemas.chooseRoles)
    .min(1, tkVal.schemas.chooseAtLeastOneRole),
});

export const RoleEmployeesSchema = yup.object().shape({
  roleId: yup.string().nullable().required(tkVal.schemas.chooseRole),
});

export const TeamSchema = yup.object().shape({
  name: yup.string().nullable().required(tkVal.schemas.provideTeamName),
  email: yup
    .string()
    .nullable()
    .required(tkVal.schemas.provideEmail)
    .test({
      name: 'emails',
      test: function (value) {
        const firstInvalidEmail =
          value &&
          value
            .split(',')
            .map(email => email.trim())
            .filter(v => v.length > 0)
            .find(v => !isEmailSchema.isValidSync(v));

        return !firstInvalidEmail
          ? true
          : this.createError({
              message: tkVal.schemas.invalidEmails,
              params: { i18params: { firstInvalidEmail: firstInvalidEmail } },
            });
      },
    }),
  managerId: yup.string().required(tkVal.schemas.provideTeamManager),
});

const isEmailSchema = yup.string().email();
export const BudgetSchema = yup.object().shape({
  name: yup.string().nullable().required(tkVal.schemas.provideBudgetName),
  managerId: yup
    .string()
    .nullable()
    .required(tkVal.schemas.provideBudgetManager),
});

export const BrandSchema = yup.object().shape({
  name: yup.string().nullable().required(tkVal.schemas.provideBrandName),
  budgetId: yup.string().nullable().required(tkVal.schemas.provideBrandBudget),
});

export const PurchaserSchema = yup.object().shape({
  name: yup.string().nullable().required(tkVal.schemas.providePurchaserName),
  companyId: yup.string().nullable().required(tkVal.schemas.chooseCompany),
  clientId: yup.string().nullable().required(tkVal.schemas.chooseClient),
  budgetId: yup.string().nullable().required(tkVal.schemas.chooseClientBudget),
  teamIds: yup.array().of(yup.string()).nullable(),
});

export const PositionSchema = yup.object().shape({
  clientId: yup.string().required(tkVal.schemas.chooseClient),
  name: yup.string().required(tkVal.schemas.providePositionName),
  groupName: yup.string().required(tkVal.schemas.provideGroupName),
  dictionaryId: yup.string().required(tkVal.schemas.chooseCommunicationChannel),
  positionType: yup
    .mixed<PositionTypeEnum>()
    .oneOf(Object.values(PositionTypeEnum))
    .required(tkVal.schemas.choosePositionType),
  companyIds: yup
    .array()
    .required(tkVal.schemas.chooseCompany)
    .min(1, tkVal.schemas.chooseAtLeastOneCompany),
  profileIds: yup
    .array()
    .required(tkVal.schemas.chooseProfile)
    .min(1, tkVal.schemas.chooseAtLeastOneProfile),
});

export const AttachmentSchema = yup
  .object()
  .shape({
    name: yup.string().nullable(),
    note: yup.string().nullable(),
    dictionaryShortname: yup.string().nullable(),
    dictionaryTypeId: yup
      .string()
      .nullable()
      .required(tkVal.schemas.specifyAttachmentType),
    enObjectType: yup
      .mixed<AttachmentObjectType>()
      .oneOf(Object.values(AttachmentObjectType))
      .required(tkVal.schemas.attachmentObjectTypeRequired),
    companyId: yup.string().nullable().required(tkVal.schemas.specifyCompany),
    clientId: yup.string().when('dictionaryShortname', {
      is: (val: string) => val === 'POZ',
      then: schema => schema.nullable().required(tkVal.schemas.provideInvoicer),
      otherwise: schema => schema.nullable(),
    }),
    estimateItemsIds: yup
      .array()
      .of(yup.string().nullable())
      .when('dictionaryShortname', {
        is: (val: string) => val === 'POZ',
        then: schema =>
          schema
            .nullable()
            .required(tkVal.schemas.chooseRelatedItems)
            .min(1, tkVal.schemas.chooseAtLeastOneRelatedItem),
        otherwise: schema => schema.nullable(),
      }),
  })
  .test(
    'name-or-note',
    tkVal.schemas.addAttachmentOrNote,
    object => object?.name !== 'Notatka' || !!object?.note,
  );

export const ProjectSchema = yup.object().shape({
  name: yup.string().nullable().required(tkVal.schemas.provideProjectName),
  purchaserId: yup.string().nullable().required(tkVal.schemas.choosePurchaser),
  teamIds: yup
    .array()
    .of(yup.string().nullable())
    .required(tkVal.schemas.chooseTeam)
    .min(1, tkVal.schemas.chooseAtLeastOneTeam),
  companyId: yup.string().nullable().required(tkVal.schemas.companySetupError),
  countryDictionaryId: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseCountryOrMarket),
  startDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .min(new Date(2024, 0, 1), tkVal.schemas.startDateAfterJan2024)
    .required(tkVal.schemas.provideStartDate),
  endDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test(
      'not earlier than date',
      tkVal.schemas.endDateAfterStartDate,
      notEarlierThan('startDate'),
    )
    .required(tkVal.schemas.provideEndDate),
  delayMessage: yup.string().when('delayVerification', {
    is: (val: boolean) => val === true,
    then: schema =>
      schema.nullable().required(tkVal.schemas.fillRequestContent),
    otherwise: schema => schema.nullable(),
  }),
  profileId: yup
    .string()
    .nullable()
    .when('id', {
      is: (value: string | undefined) => {
        return value === undefined;
      },
      then: schema => schema.nullable().required(tkVal.schemas.chooseProfile),
    }),
});

export const ProjectDeactivateSchema = yup.object().shape({
  rejectedNote: yup.string().required(tkVal.schemas.provideRejectionReason),
});

export const ProjectApplicationRejectSchema = yup.object().shape({
  rejectedNote: yup.string().required(tkVal.schemas.provideRejectionReason),
});
export const SymphonyClientSchema = yup.object().shape({
  code: yup
    .number()
    .integer(tkVal.schemas.integerValue)
    .typeError(tkVal.schemas.integerValue)
    .min(0, tkVal.schemas.nonNegativeCode)
    .nullable()
    .required(tkVal.schemas.provideSymphonyCode),
  companyId: yup.string().nullable().required(tkVal.schemas.chooseCompany),
});

export const CloneProjectSchema = yup.object().shape({
  name: yup.string().required(tkVal.schemas.provideName),
});

const ProfileSchemaItemSchema = yup.object().shape({
  displayNamePl: yup.string().optional(),
  displayNameEn: yup.string().optional(),
  required: yup.boolean().optional(),
});

export const ProfileSchemaSchema = yup
  .object()
  .shape({
    rcSalesTotal: ProfileSchemaItemSchema.optional(),
    salesSurchargePercent: ProfileSchemaItemSchema.optional(),
    salesDiscountPercent: ProfileSchemaItemSchema.optional(),
    salesNet: ProfileSchemaItemSchema.optional(),
    salesCommissionPercent: ProfileSchemaItemSchema.optional(),
    salesCommissionAmount: ProfileSchemaItemSchema.optional(),
    salesNetTechnicalCost: ProfileSchemaItemSchema.optional(),
    salesNetTotal: ProfileSchemaItemSchema.optional(),
    rcPurchase: ProfileSchemaItemSchema.optional(),
    purchaseTechnicalCost: ProfileSchemaItemSchema.optional(),
    purchaseDiscountPercent: ProfileSchemaItemSchema.optional(),
    purchaseNetTotal: ProfileSchemaItemSchema.optional(),
    purchaseNet: ProfileSchemaItemSchema.optional(),
    purchaseSurchargePercent: ProfileSchemaItemSchema.optional(),
  })
  .optional()
  .test('is-unique-pl', function (value) {
    if (value) {
      const displayNames = Object.values(value)
        .map(schemaItem => schemaItem?.displayNamePl)
        .filter(displayNamePl => displayNamePl);
      if (displayNames.length) {
        const counts = _.countBy(displayNames);
        const duplicatedDisplayNames = _.keys(counts).filter(
          key => counts[key] > 1,
        );
        if (duplicatedDisplayNames.length) {
          return this.createError({
            message: tkVal.schemas.namesNotUnique,
            params: { i18params: { names: duplicatedDisplayNames.join(', ') } },
          });
        }
      }
    }
    return true;
  })
  .test('is-unique-en', function (value) {
    if (value) {
      const displayNames = Object.values(value)
        .map(schemaItem => schemaItem?.displayNameEn)
        .filter(displayNameEn => displayNameEn);
      if (displayNames.length) {
        const counts = _.countBy(displayNames);
        const duplicatedDisplayNames = _.keys(counts).filter(
          key => counts[key] > 1,
        );
        if (duplicatedDisplayNames.length) {
          return this.createError({
            message: tkVal.schemas.englishNamesNotUnique,
            params: {
              i18params: {
                names: duplicatedDisplayNames.join(', '),
              },
            },
          });
        }
      }
    }
    return true;
  });

export const ProfileSchema = yup.object().shape({
  name: yup.string().required(tkVal.schemas.provideProfileName),
  schema: ProfileSchemaSchema.optional(),
});

export const ProfileCategorySchema = yup.object().shape({
  name: yup.string().required(tkVal.schemas.provideProfileCategoryName),
  purchasesFinancialAccount: yup
    .string()
    .required(tkVal.schemas.providePurchaseAccount),
  salesFinancialAccount: yup
    .string()
    .required(tkVal.schemas.provideSalesAccount),
  commissionFinancialAccount: yup
    .string()
    .required(tkVal.schemas.provideCommissionAccount),
  profileId: yup.string().nullable().required(tkVal.schemas.chooseProfile),
});

const ApplicationSchemaCommonFields = {
  applicationType: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseApplicationType),
  teamId: yup.string().nullable().required(tkVal.schemas.chooseTeam),
  elementName: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) => val !== ApplicationTypeEnum.Permission,
    then: schema =>
      schema.nullable().required(tkVal.schemas.provideElementName),
    otherwise: schema => schema.nullable(),
  }),
  profileId: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) => val === ApplicationTypeEnum.Position,
    then: schema => schema.nullable().required(tkVal.schemas.chooseProfile),
    otherwise: schema => schema.nullable(),
  }),
  description: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) =>
      val === ApplicationTypeEnum.DictionaryValue,
    then: schema =>
      schema.nullable().required(tkVal.schemas.provideDescription),
    otherwise: schema => schema.nullable(),
  }),
  brandApplicationPurchaserId: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) => val === ApplicationTypeEnum.Brand,
    then: schema => schema.nullable().required(tkVal.schemas.choosePurchaser),
    otherwise: schema => schema.nullable(),
  }),
  permissionEmployeeId: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) => val === ApplicationTypeEnum.Permission,
    then: schema => schema.nullable().required(tkVal.schemas.provideEmployee),
    otherwise: schema => schema.nullable(),
  }),
  permissionEmployeeBossId: yup.string().when('applicationType', {
    is: (val: ApplicationTypeEnum) => val === ApplicationTypeEnum.Permission,
    then: schema =>
      schema.nullable().required(tkVal.schemas.provideEmployeeBoss),
    otherwise: schema => schema.nullable(),
  }),
};

export const ApplicationSchema = yup.object().shape({
  ...ApplicationSchemaCommonFields,
});

export const ApplicationWithClientSchema = yup
  .object()
  .shape({
    ...ApplicationSchemaCommonFields,
    ...ClientSchemaFields,
  })
  .test('nip-or-pesel', tkVal.schemas.nipOrPeselRequired, nipOrPeselValidation);

export const ApplicationAdminSchema = yup.object().shape({
  rejectedNote: yup
    .string()
    .nullable()
    .when('applicationStatus', {
      is: ApplicationStatusEnum.Rejected,
      then: schema => schema.required(tkVal.schemas.provideRejectionReason2),
      otherwise: schema => schema.nullable(),
    }),
  positionId: yup
    .string()
    .nullable()
    .when(
      ['applicationStatus', 'applicationType'],
      ([applicationStatus, applicationType], schema) =>
        applicationStatus === ApplicationStatusEnum.Completed &&
        applicationType === ApplicationTypeEnum.Position
          ? schema.required(tkVal.schemas.providePosition)
          : schema,
    ),
  purchaserId: yup
    .string()
    .nullable()
    .when(
      ['applicationStatus', 'applicationType'],
      ([applicationStatus, applicationType], schema) =>
        applicationStatus === ApplicationStatusEnum.Completed &&
        applicationType === ApplicationTypeEnum.Purchaser
          ? schema.required(tkVal.schemas.providePurchaser)
          : schema,
    ),
  brandId: yup
    .string()
    .nullable()
    .when(
      ['applicationStatus', 'applicationType'],
      ([applicationStatus, applicationType], schema) =>
        applicationStatus === ApplicationStatusEnum.Completed &&
        applicationType === ApplicationTypeEnum.Brand
          ? schema.required(tkVal.schemas.provideBrand)
          : schema,
    ),
});
export const CurrencyIsoSchema = yup.object().shape({
  isoCode: yup
    .mixed<CurrencyIsoTypeEnum>()
    .oneOf(Object.values(CurrencyIsoTypeEnum))
    .required(tkVal.schemas.chooseValidCurrencyIso),
});

export const EstimateByMonthSchema = yup.object().shape({
  profileId: yup.string().nullable().required(tkVal.schemas.chooseProfile),
  projectId: yup.string().nullable().required(tkVal.schemas.chooseProject),
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(tkVal.schemas.chooseSomeMonth),
});

export const EstimateItemSchema = yup.object().shape({
  profileCategoryId: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseProfileCategory),
  startDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(tkVal.schemas.chooseStartDate),
  endDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test(
      'not earlier than date',
      tkVal.schemas.endDateNotEarlierThanStartDate,
      notEarlierThan('startDate'),
    )
    .required(tkVal.schemas.chooseEndDate),
  positionId: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseProfilePosition),
});

export const PurchaseInvoicePositionSchema = yup.object().shape({
  netAmount: yup.number().required(tkVal.schemas.chooseNetAmount),
  vat: yup
    .mixed<VatEnum>()
    .oneOf(Object.values(VatEnum))
    .required(tkVal.schemas.chooseVatRate),
  vatAmount: yup.number().required(tkVal.schemas.chooseVatAmount),
});

export const PurchaseInvoiceGeneralTabSchema = yup.object().shape({
  number: yup.string().required(tkVal.schemas.chooseInvoiceNumber),
  sellDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(tkVal.schemas.chooseSaleDate),
  issueDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(tkVal.schemas.chooseIssueDate),
  paymentDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(tkVal.schemas.choosePaymentDate),
  clientId: yup.string().required(tkVal.schemas.chooseClient),
  companyId: yup.string().required(tkVal.schemas.chooseCompany),
  positions: yup
    .array()
    .of(PurchaseInvoicePositionSchema)
    .min(1, tkVal.schemas.addAtLeastOnePosition)
    .required(tkVal.schemas.addAtLeastOnePosition),
  currency: yup
    .mixed<CurrencyIsoTypeEnum>()
    .oneOf(Object.values(CurrencyIsoTypeEnum))
    .required(tkVal.schemas.chooseCurrency),
  type: yup
    .mixed<InvoiceTypeEnum>()
    .oneOf(Object.values(InvoiceTypeEnum))
    .required(tkVal.schemas.chooseInvoiceType),
  exchangeRate: yup.number().optional(),
  attachment: yup.object().required(tkVal.schemas.chooseAttachment),
});

const PurchaseInvoiceTeamsProjectsTabSchemaShape = {
  teamIds: yup.array().of(yup.string()).optional(),
  projectIds: yup.array().of(yup.string()).optional(),
};

export const PurchaseInvoiceTeamsProjectsTabSchema = yup
  .object()
  .shape(PurchaseInvoiceTeamsProjectsTabSchemaShape)
  .test('has-teams-or-projects', function (value) {
    if (!value.projectIds?.length && !value.teamIds?.length) {
      return this.createError({
        message: tkVal.schemas.chooseTeamsOrProjects,
      });
    }
    return true;
  });

export const PurchaseInvoiceEstimateItemSchema = yup
  .object()
  .shape({
    estimateItemId: yup
      .string()
      .required(tkVal.schemas.chooseMediaPlanPosition),
  })
  .required();

export const PurchaseInvoiceEstimateItemsTabSchema = yup
  .object()
  .shape({
    purchaseInvoiceEstimateItems: yup
      .array()
      .of(PurchaseInvoiceEstimateItemSchema)
      .optional(),
  })
  .test('hasAmounts', function () {
    for (const item of this.originalValue.purchaseInvoiceEstimateItems || []) {
      if (!item.amount) {
        return this.createError({
          message: tkVal.schemas.chooseAmount,
        });
      }
    }
    return true;
  })
  .required();

export const SalesInvoiceSchema = yup.object().shape({
  invoiceDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(tkVal.schemas.chooseIssueDate),
  saleDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(tkVal.schemas.chooseSaleDate),
  payableDaysId: yup.string().when('paymentForm', {
    is: (paymentForm: string) => paymentForm === PaymentFormEnum.BankTransfer,
    then: schema => schema.required(tkVal.schemas.choosePaymentTerm),
    otherwise: schema => schema.nullable(),
  }),

  onePositionName: yup
    .string()
    .nullable()
    .when(['isOnePositionOnInvoice'], ([isOnePositionOnInvoice], schema) =>
      isOnePositionOnInvoice
        ? schema.required(tkVal.schemas.choosePositionName)
        : schema,
    ),
});

export const SalesInvoicePositionSchema = yup.object().shape({
  name: yup.string().required(tkVal.schemas.choosePositionName),
  amount: yup
    .number()
    .typeError(tkVal.schemas.chooseAmount)
    .min(0, tkVal.schemas.amountCannotBeNegative)
    .nullable()
    .required(tkVal.schemas.chooseAmount),
  vat: yup
    .mixed<VatEnum>()
    .oneOf(Object.values(VatEnum))
    .required(tkVal.schemas.chooseVatRate),
});

export const InvoiceEstimateItemSchema = yup.object().shape({
  amount: yup
    .number()
    .typeError(tkVal.schemas.chooseSaleAmount)
    .min(0, tkVal.schemas.amountCannotBeNegative)
    .nullable()
    .required(tkVal.schemas.chooseSaleAmount),
  commissionAmount: yup
    .number()
    .typeError(tkVal.schemas.chooseCommissionAmount)
    .min(0, tkVal.schemas.amountCannotBeNegative)
    .nullable()
    .required(tkVal.schemas.chooseCommissionAmount),
  estimateItemId: yup.string().required(tkVal.schemas.chooseMediaPlanPosition),
  salesInvoiceId: yup.string().required(tkVal.schemas.chooseInvoice),
});

export const BankAccountSchema = yup.object().shape({
  companyId: yup.string().nullable().required(tkVal.schemas.chooseCompany),
  company: yup.object().shape({
    mainCurrency: yup.object().shape({
      currencyCode: yup.string().nullable(),
    }),
  }),
  number: yup.string().when('company.mainCurrency.currencyCode', {
    is: (cur: string) => cur !== 'USD',
    then: () =>
      IBANSchema.nullable().required(tkVal.schemas.chooseAccountNumber),
    otherwise: schema =>
      schema.nullable().required(tkVal.schemas.chooseAccountNumber),
  }),
  currency: yup
    .mixed<CurrencyIsoTypeEnum>()
    .oneOf(Object.values(CurrencyIsoTypeEnum))
    .required(tkVal.schemas.chooseCurrency),
  invoiceType: yup
    .mixed<InvoiceTypeEnum>()
    .oneOf(Object.values(InvoiceTypeEnum))
    .required(tkVal.schemas.chooseInvoiceType),
  swiftBIC: yup.string().nullable().required(tkVal.schemas.chooseSwiftCode),
  bankName: yup.string().nullable().required(tkVal.schemas.chooseBankName),
  vatNumber: yup
    .string()
    .nullable()
    .when(
      ['invoiceType', 'currency', 'company.mainCurrency.currencyCode'],
      ([invoiceType, currency, currencyCode], schema) =>
        invoiceType === InvoiceTypeEnum.Domestic &&
        currency !== CurrencyIsoTypeEnum.PLN &&
        currencyCode === 'PLN'
          ? IBANSchema.nullable().required(tkVal.schemas.chooseVatAccountNumber)
          : schema,
    ),
  vatBankName: yup
    .string()
    .nullable()
    .when(
      ['invoiceType', 'currency', 'company.mainCurrency.currencyCode'],
      ([invoiceType, currency, currencyCode], schema) =>
        invoiceType === InvoiceTypeEnum.Domestic &&
        currency !== CurrencyIsoTypeEnum.PLN &&
        currencyCode === 'PLN'
          ? schema.required(tkVal.schemas.chooseVatBankName)
          : schema,
    ),
});
export const OrderSchema = yup
  .object()
  .shape({
    addTeamToEmails: yup.boolean().nullable(),
    emails: yup.array(),
    projectId: yup.string().nullable().required(tkVal.schemas.giveProject),
    teamId: yup
      .string()
      .nullable()
      .required(tkVal.schemas.chooseSpecialistTeam),
  })
  .test(
    'mail',
    tkVal.schemas.chooseRecipientsOrTeamMail,
    object =>
      object?.addTeamToEmails || (object?.emails && object?.emails?.length > 0),
  );

export const OrderRejectSchema = yup.object().shape({
  rejectedNote: yup.string().required(tkVal.schemas.chooseRejectionReason),
});

export const OrderExecutionSchema = yup
  .object()
  .shape({
    addTeamToEmails: yup.boolean().nullable(),
    emails: yup.array(),
    orderExecutionPositions: yup
      .array()
      .min(1, tkVal.schemas.chooseAtLeastOnePosition)
      .of(
        yup.object().shape({
          name: yup.string().test('name-length', function (value) {
            const { path, createError } = this;
            return (
              (value && value.length > 0) ||
              createError({
                path,
                message: tkVal.schemas.givePositionName,
                params: { i18params: { order: this.parent.order } },
              })
            );
          }),
          amount: yup.string().test('amount-length', function (value) {
            const { path, createError } = this;
            return (
              (value && value.length > 0) ||
              createError({
                path,
                message: tkVal.schemas.giveAmount,
                params: { i18params: { order: this.parent.order } },
              })
            );
          }),
        }),
      ),
    date: yup.date().required(tkVal.schemas.chooseMonth),
  })
  .test(
    'mail',
    tkVal.schemas.chooseRecipientsOrTeamMail,
    object =>
      object?.addTeamToEmails || (object?.emails && object?.emails?.length > 0),
  );

export const OrderExecutionRejectSchema = yup.object().shape({
  rejectedNote: yup.string().required(tkVal.schemas.chooseRejectionReason),
});

export const ApplicationUnblockDecisionSchema = yup.object().shape({
  rejectedNote: yup
    .string()
    .nullable()
    .when('status', {
      is: ApplicationUnblockStatusEnum.Rejected,
      then: schema => schema.required(tkVal.schemas.chooseRejectionReason),
      otherwise: schema => schema.nullable(),
    }),
  status: yup
    .mixed<ApplicationUnblockStatusEnum>()
    .oneOf(Object.values(ApplicationUnblockStatusEnum))
    .required(tkVal.schemas.chooseStatus),
});

export const ApplicationUnblockSchema = yup.object().shape({
  estimateByMonthId: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseMonthlyProfile),
  description: yup.string().nullable().required(tkVal.schemas.chooseReason),
  type: yup
    .mixed<ApplicationUnblockTypeEnum>()
    .oneOf(Object.values(ApplicationUnblockTypeEnum))
    .required(tkVal.schemas.chooseApplicationType),
  inDocumentation: yup
    .boolean()
    .nullable()
    .required(tkVal.schemas.chooseDocumentationConfirmation),
  salesCorrect: yup
    .boolean()
    .nullable()
    .required(tkVal.schemas.chooseSalesCorrection),
});

export const BlockadeMonthSchema = yup
  .object()
  .shape({
    companyId: yup.string().nullable().required(tkVal.schemas.chooseCompany),
    date: yup.date().required(tkVal.schemas.chooseMonth),
    isSalesBlocked: yup.boolean().nullable(),
    isPurchaseBlocked: yup.boolean().nullable(),
  })
  .test(
    'sale-or-purchase',
    tkVal.schemas.chooseSalesOrPurchaseBlock,
    object => object?.isSalesBlocked || !!object?.isPurchaseBlocked,
  );

export const PrivateMemoSchema = yup.object().shape({
  recipientId: yup.string().nullable().required(tkVal.schemas.chooseRecipient),
  content: yup.string().nullable().required(tkVal.schemas.chooseMemoContent),
});

export const PublicMemoSchema = yup.object().shape({
  content: yup.string().nullable().required(tkVal.schemas.chooseMemoContent),
});

export const ExcelConfigSchema = yup.object().shape({
  name: yup.string().nullable().required(tkVal.schemas.chooseConfigName),
  profileId: yup.string().nullable().required(tkVal.schemas.chooseProfile),
  profileCategoryId: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseProfileCategory),
  excelConfigTeamsIds: yup
    .array()
    .of(yup.string().nullable())
    .required(tkVal.schemas.chooseTeam)
    .min(1, tkVal.schemas.chooseAtLeastOneTeam),
});

export const SpecialInvoicingSchema = yup.object().shape({
  description: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseRequestContent),
  note: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseInvoicingDescription),
  projectIds: yup
    .array()
    .of(yup.string())
    .required(tkVal.schemas.chooseAtLeastOneProject)
    .min(1, tkVal.schemas.chooseAtLeastOneProject),
});

export const SpecialInvoicingDecisionSchema = yup.object().shape({
  rejectedNote: yup
    .string()
    .nullable()
    .when('status', {
      is: SpecialInvoicingStatus.Rejected,
      then: schema => schema.required(tkVal.schemas.chooseRejectionReason),
      otherwise: schema => schema.nullable(),
    }),
  correctionNote: yup
    .string()
    .nullable()
    .when('status', {
      is: SpecialInvoicingStatus.ToBeCorrected,
      then: schema => schema.required(tkVal.schemas.chooseRequiredChanges),
      otherwise: schema => schema.nullable(),
    }),
  status: yup
    .mixed<SpecialInvoicingStatus>()
    .notOneOf([SpecialInvoicingStatus.New], tkVal.schemas.chooseRequestStatus)
    .required(tkVal.schemas.chooseRequestStatus),
  financeNote: yup
    .string()
    .nullable()
    .when('status', {
      is: SpecialInvoicingStatus.Accepted,
      then: schema =>
        schema.required(tkVal.schemas.chooseAcceptedInvoicingDescription),
      otherwise: schema => schema.nullable(),
    }),
});

export const ExcelConfigHeaderSchema = yup.object().shape({
  pokColumnName: yup.string().nullable().required(tkVal.schemas.choosePOKField),
  excelColumnName: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseExcelColumnName),
  order: yup
    .number()
    .integer(tkVal.schemas.chooseIntegerOrder)
    .typeError(tkVal.schemas.chooseIntegerOrder)
    .min(0, tkVal.schemas.chooseIntegerOrder)
    .nullable()
    .required(tkVal.schemas.chooseColumnOrder),
});

export const EmailConfigSchema = yup.object().shape({
  type: yup
    .mixed<EmailConfigTypeEnum>()
    .oneOf(Object.values(EmailConfigTypeEnum))
    .required(tkVal.schemas.chooseConfigType),
  emails: yup
    .array()
    .of(yup.string().nullable())
    .required(tkVal.schemas.chooseEmailAddress)
    .min(1, tkVal.schemas.chooseAtLeastOneEmail)
    .test({
      name: 'emails',
      test: function (value) {
        const firstInvalidEmail =
          value &&
          value
            .map(email => email?.trim())
            .filter(v => v && v.length > 0)
            .find(v => !isEmailSchema.isValidSync(v));

        return !firstInvalidEmail
          ? true
          : this.createError({
              message: tkVal.schemas.invalidEmails,
              params: { i18params: { firstInvalidEmail: firstInvalidEmail } },
            });
      },
    })
    .test({
      name: 'nonEmptyEmails',
      test: function (value) {
        return value.filter(email => email && email.trim() !== '').length > 0
          ? true
          : this.createError({
              message: tkVal.schemas.chooseAtLeastOneValidEmail,
            });
      },
    }),
});

export const JobConfigSchema = yup.object().shape({
  type: yup
    .mixed<JobTypeEnum>()
    .oneOf(Object.values(JobTypeEnum))
    .required(tkVal.schemas.chooseConfigType),
  crone: yup.string().nullable().required(tkVal.schemas.chooseFrequency),
  emails: yup
    .array()
    .of(yup.string().nullable())
    .test({
      name: 'emails',
      test: function (value) {
        const firstInvalidEmail =
          value &&
          value
            .map(email => email?.trim())
            .filter(v => v && v.length > 0)
            .find(v => !isEmailSchema.isValidSync(v));

        return !firstInvalidEmail
          ? true
          : this.createError({
              message: tkVal.schemas.invalidEmails,
              params: { i18params: { firstInvalidEmail: firstInvalidEmail } },
            });
      },
    }),
});

export const ExcelConfigPositionSchema = yup.object().shape({
  excelPositionName: yup
    .string()
    .nullable()
    .required(tkVal.schemas.chooseExcelPositionName),
  positionId: yup.string().required(tkVal.schemas.choosePosition),
});

export const ProjectExcelImportSchema = yup.object().shape({
  fileName: yup.string().nullable().required(tkVal.schemas.chooseExcel),
  configId: yup.string().required(tkVal.schemas.chooseConfigImport),
});
