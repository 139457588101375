import React, { useMemo } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { TabsHistory } from '../../../app/components/TabsHistory';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { ProjectSearch } from '../../components/Projects/ProjectSearch';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { GridGetterFunction } from '../../../common/components/Grid/GridDataTypes';

export enum VerificationTabs {
  READY_TO_CORRECTION = 'readyForCorrection',
}

export const VerificationView: React.FC = () => {
  const { t, tk } = useTranslation('fvsLng');
  const project = useTranslation('projectLng');
  const pok = usePokCore();

  const getData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.projects.getAllReadyToCorrectionByPortion(
          project.t,
          project.tk,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
        )) as GridGetterFunction,
    [pok, project.t, project.tk],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.finance.verification)}</Card.Title>
        <TabsHistory
          activeKey={VerificationTabs.READY_TO_CORRECTION}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={VerificationTabs.READY_TO_CORRECTION}
            title={t(tk.prLang[VerificationTabs.READY_TO_CORRECTION])}
            className="m-3"
          >
            <ProjectSearch
              getData={getData}
              getCount={pok.projects.getAllReadyToCorrectionCount}
              showTotalDifferenceColumn
              totalDifferenceLabel={t(tk.finance.amountToCorrect)}
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
