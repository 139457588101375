/*eslint complexity: ["error", 39]*/
import React from 'react';
import type { TFunction } from 'i18next';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { TKeys, useTranslation } from '../../../common/hooks/useTranslation';
import { GetEstimateItemDocumentControlDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';

interface ProjectFinanceSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  showTotalDifferenceColumn?: boolean;
  totalDifferenceLabel?: string;
  withFilterInColumns?: boolean;
  refresh?: boolean;
}

export const estimateItemDocumentControlForGrid = (
  t: TFunction<'reportLng', 'reportLng'>,
  tk: TKeys<'reportLng'>,
  estimateItem: GetEstimateItemDocumentControlDto,
) => {
  const getGoodText = (value: string) => {
    switch (value) {
      case 'TAK':
        return t(tk.common.yesUpperCase);
      case 'NIE':
        return t(tk.common.noUpperCase);
      case 'Częściowo':
        return t(tk.common.partly);
      case 'ND':
        return t(tk.common.notDefined);
      default:
        return '';
    }
  };

  return {
    key: estimateItem.id,
    values: {
      'project.name': estimateItem?.estimateByMonth?.project.name,
      'project.systemNumber':
        estimateItem?.estimateByMonth?.project.systemNumber,
      'project.startDate': momencik(
        estimateItem?.estimateByMonth?.project.startDate,
        'YYYY-MM-DD',
      ),
      'project.endDate': momencik(
        estimateItem?.estimateByMonth?.project.endDate,
        'YYYY-MM-DD',
      ),
      'project.number': estimateItem?.estimateByMonth?.project.number,
      'estimateByMonth.date': momencik(
        estimateItem?.estimateByMonth?.date,
        'YYYY-MM',
      ),
      'profile.name': estimateItem?.estimateByMonth?.profile?.name,
      'position.name': estimateItem?.position?.name,
      'client.name': estimateItem?.position?.client?.name,
      'estimateItem.salesCommissionAmount': numberFormatter(
        estimateItem?.salesCommissionAmount,
        true,
      ),
      'estimateItem.salesNet': numberFormatter(estimateItem?.salesNet, true),
      'estimateItem.salesNetTotal': numberFormatter(
        estimateItem?.salesNetTotal,
        true,
      ),
      'estimateItem.format':
        estimateItem?.formatRadio?.value ||
        estimateItem?.formatPress?.value ||
        estimateItem?.formatInternet?.value ||
        estimateItem?.formatInternetST?.value,
      'responsibleOwner.name':
        estimateItem?.estimateByMonth?.project?.responsibleOwner?.name,
      mpXlsx: getGoodText(estimateItem.mpXlsx),
      mpAssigned: getGoodText(estimateItem.mpAssigned),
      pmProject: getGoodText(estimateItem.pmProject),
      attachmentOrder: getGoodText(estimateItem.attachmentOrder),
      internalOrder: getGoodText(estimateItem.internalOrder),
      fvz: getGoodText(estimateItem.fvz),
      fvs: getGoodText(estimateItem.fvs),
    },
  };
};

export const DocumentControlSearch: React.FC<
  ProjectFinanceSearchProps
> = props => {
  const { t, tk } = useTranslation('reportLng');
  const columns: GridColumn[] = [
    { header: t(tk.common.month), property: 'estimateByMonth.date' },
    {
      header: `${t(tk.common.sysNumber)} ${t(tk.prLang.genitiveProject)}`,
      property: 'project.systemNumber',
    },
    {
      header: `${t(tk.common.number)} ${t(tk.prLang.genitiveProject)}`,
      property: 'project.number',
    },
    {
      header: `${t(tk.common.name)} ${t(tk.prLang.genitiveProject)}`,
      property: 'project.name',
    },
    { header: t(tk.common.profile), property: 'profile.name' },
    { header: t(tk.controlling.positionName), property: 'position.name' },
    { header: t(tk.controlling.clientName), property: 'client.name' },
    {
      header: t(tk.controlling.format),
      property: 'estimateItem.format',
      noFilter: true,
      noSorting: true,
    },
    {
      header: `${t(tk.common.startDate)} ${t(tk.prLang.genitiveProject)}`,
      property: 'project.startDate',
    },
    {
      header: `${t(tk.common.endDate)} ${t(tk.prLang.genitiveProject)}`,
      property: 'project.endDate',
    },
    { header: t(tk.controlling.salesNet), property: 'estimateItem.salesNet' },
    {
      header: t(tk.controlling.salesCommissionAmount),
      property: 'estimateItem.salesCommissionAmount',
    },
    {
      header: t(tk.controlling.salesNetTotal),
      property: 'estimateItem.salesNetTotal',
    },
    {
      header: t(tk.controlling.responsiblePerson),
      property: 'responsibleOwner.name',
    },
    { header: 'MP xlsx', property: 'mpXlsx', noSorting: true },
    {
      header: t(tk.controlling.mpSigned),
      property: 'mpAssigned',
      noSorting: true,
    },
    {
      header: t(tk.prLang.pbProject),
      property: 'pmProject',
      noSorting: true,
    },
    {
      header: t(tk.controlling.attachmentOrder),
      property: 'attachmentOrder',
      noSorting: true,
    },
    {
      header: t(tk.controlling.acceptedOrderExec),
      property: 'internalOrder',
      noSorting: true,
    },
    {
      header: t(tk.controlling.clientFV),
      property: 'fvz',
      noSorting: true,
    },
    {
      header: 'FV VM/ŻP',
      property: 'fvs',
      noSorting: true,
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn={'project.number'}
      initialOrderDirection={'DESC'}
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.controlling.noPositions)}
      onRowClick={props.onRowClick}
      withFilterInColumns={props.withFilterInColumns}
      withOverflowXAuto={true}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
