/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { App } from 'react-bootstrap-icons';

import {
  MenuItem,
  routesInMenu,
  useNavigation,
} from '../../../common/navigation';
import SavingToast from '../SavingToast';
import ValidationAlert from '../ValidationAlert';
import { SavingResult } from '../../../common/types/SavingResult';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { useNotifications } from '../../../common/hooks/useNotifications';
import ConflictsToast from '../ConflictsToast';
import ConnectionIssues from '../ConnectionIssues';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useAuth } from '../../../common/hooks/useAuth';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';
import ErrorToast from '../ErrorToast';
import { useTranslation } from '../../../common/hooks/useTranslation';

import SideNavSection from './SideNavSection';

interface SideNavProps {
  location: string;
  className: string;
  color: string;
}

const noAction = () => {
  // to be set later
};

const SideNav: React.FC<SideNavProps> = props => {
  const [collapsed, setCollapsed] = useState(false);
  const [savingResult, setSavingResult] = useState<SavingResult>();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [conflictText, setConflictText] = useState<React.ReactNode>();
  const [errorText, setErrorText] = useState<React.ReactNode>();
  const [timestamp, setTimestamp] = useState(Date.now());
  const actionCallback = useRef(noAction);
  const notifications = useNotifications();
  const nav = useNavigation();
  const pok = usePokCore();
  const auth = useAuth();
  const { t, tk } = useTranslation('menuLng');

  useEffect(() => {
    notifications.onPromise = (
      promise: Promise<void>,
      action?: () => void,
      actionIfFailed?: () => void,
    ) =>
      promise
        .then(() => {
          setSavingResult({ saved: true });
          if (action) {
            action();
          }
        })
        .catch(async (response: ResponseError) => {
          const errors = await responseErrors(response);
          setValidationErrors(errors);
          setShowValidationErrors(true);
          const a = actionIfFailed || action;
          if (a) {
            actionCallback.current = a;
          }
        });

    notifications.saveCompleted = () => {
      setSavingResult({ saved: true });
    };

    notifications.unauthorized = () => {
      setTimeout(() =>
        setSavingResult({
          saved: false,
          errors: [t(tk.system.cannotLoad)],
        }),
      );
    };

    notifications.caughtError = err => {
      const errorResponse = err.response;
      if (errorResponse === undefined) {
        notifications.conflict(t(tk.system.serverNotResponse) + err);
        return;
      }
      if (errorResponse.status === 403) {
        setTimeout(() =>
          setSavingResult({
            saved: false,
            errors: [t(tk.system.noPermissions)],
          }),
        );
        notifications.accessForbidden();
        return;
      }
      if (errorResponse.status === 409) {
        void responseErrors(err).then(errors => {
          notifications.conflict(errors);
        });
        return;
      }
      if (
        errorResponse.status === 503 ||
        errorResponse.status === 400 ||
        errorResponse.status === 404
      ) {
        void responseErrors(err).then(errors => {
          notifications.error(errors);
        });
        return;
      }
      if (errorResponse.status !== 401) {
        throw err;
      }
    };

    notifications.badLink = (reason?: string, redirectLocation?: string) => {
      setTimeout(() => {
        setSavingResult({
          saved: false,
          errors: [reason || t(tk.system.cannotLoad)],
        });
        void nav.menuItem(redirectLocation || '/');
      });
    };

    notifications.conflict = (text: React.ReactNode) => {
      setConflictText(text);
      setTimestamp(Date.now());
    };

    notifications.error = (text: React.ReactNode) => {
      setErrorText(text);
      setTimestamp(Date.now());
    };
  }, [notifications, nav, pok, auth, tk.system, t]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const classNameVersion =
    process.env.REACT_APP_VERSION?.toLocaleLowerCase() === 'demo'
      ? 'overflow-wrapper menu-container menu-container-demo'
      : 'overflow-wrapper menu-container';

  const checkPermission = (route: MenuItem) => {
    if (route.withContext && route.requiresCompanyAnyOf) {
      if (!route.requiresCompanyAnyOf?.includes(pok.getCompanyId())) {
        return false;
      }
    }

    return (
      !route.requiresAnyOf ||
      route.requiresAnyOf.length === 0 ||
      route.requiresAnyOf.filter(role =>
        auth.check(role, route.withContext ? pok.getCompanyId() : undefined),
      ).length > 0
    );
  };

  return (
    <div
      className={props.className + ' sidepanel-forsidenav d-flex flex-column'}
    >
      <div className="d-md-none py-3 d-flex align-items-center">
        <Button
          className="menubutton p-0 ms-3"
          onClick={handleCollapse}
          variant="link"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30"
            height="30"
            focusable="false"
          >
            <title>{t(tk.menu.menu)}</title>
            <path
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              d="M4 7h22M4 15h22M4 23h22"
            />
          </svg>
        </Button>
      </div>
      <Collapse in={collapsed}>
        <div className={classNameVersion}>
          <nav className="table-of-contents pt-4 pb-4" role="complementary">
            {routesInMenu().map(
              route =>
                checkPermission(route) && (
                  <SideNavSection
                    key={route.path}
                    heading={route.heading}
                    path={route.path}
                    location={props.location}
                    items={route.submenu}
                    icon={route.icon || App}
                  />
                ),
            )}
          </nav>
        </div>
      </Collapse>
      {process.env.REACT_APP_ANNOUNCEMENT && (
        <div
          className="announcement"
          style={{
            boxShadow: `inset 0 -2em 3em ${props.color},
          0 0 0 2px white,
          0.3em 0.3em 1em rgb(200 0 0 / 60%)`,
          }}
        >
          {process.env.REACT_APP_ANNOUNCEMENT}
        </div>
      )}
      <SavingToast result={savingResult} />
      <ConflictsToast text={conflictText} timestamp={timestamp} />
      <ErrorToast text={errorText} timestamp={timestamp} />
      <ConnectionIssues />
      <ValidationAlert
        show={showValidationErrors}
        modal={true}
        onHide={() => {
          setShowValidationErrors(false);
          actionCallback.current();
        }}
        errors={validationErrors}
        className="mt-3"
        buttonText={t(tk.button.restore)}
      />
    </div>
  );
};

export default SideNav;
