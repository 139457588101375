/*eslint max-lines-per-function: ["error", 165]*/
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import Button from '../../../common/components/Button/Button';
import { CreateUpdateBlockadeMonthDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import CompanySelector from '../Selects/CompanySelector';
import TeamSelector from '../Selects/TeamSelector';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { getOptionValues } from '../Selects/selectUtils';
import { NewDatePickerReact } from '../../../app/components/NewDatePickerReact';

interface BlockadeMonthEditorProps {
  id?: string;
  blockadeMonth: CreateUpdateBlockadeMonthDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    blockadeMonth: CreateUpdateBlockadeMonthDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const BlockadeMonthEditor: React.FC<
  BlockadeMonthEditorProps
> = props => {
  const [blockadeMonth, setBlockadeMonth] = useState(props.blockadeMonth);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('projectLng');

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setBlockadeMonth(props.blockadeMonth);
    }
  }, [props.blockadeMonth, props.show]);

  const createBlockadeMonth = async () => {
    setIsLoading(true);
    props
      .onCompleted(blockadeMonth, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  };

  const propertyChange = (obj: Partial<CreateUpdateBlockadeMonthDto>) => {
    setBlockadeMonth({ ...blockadeMonth, ...obj });
  };

  const setDate = (date: Date | undefined) => {
    if (date) {
      //ten kalendarz inaczej ustawia godzinę niż nasz główny kalendarz
      //dlatego trzeba skorygować godzinę
      date.setMinutes(-date.getTimezoneOffset());
      return date;
    }
    return undefined;
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? t(tk.projects.editMonthBlockade)
              : t(tk.projects.addMonthBlockade)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-grid gap-3">
            <FormRow controlId="company" label={t(tk.common.company)}>
              <CompanySelector value={pok.getCompanyId()} readOnly />
            </FormRow>
            <FormRow controlId="team" label={t(tk.common.teams)}>
              <TeamSelector
                isMulti={true}
                value={blockadeMonth.teamIds}
                companyId={blockadeMonth.companyId}
                onChange={options => {
                  propertyChange({
                    teamIds: getOptionValues(options),
                  });
                }}
                clearable
              />
            </FormRow>
            <FormRow controlId="isSalesBlockade" label={t(tk.common.month)}>
              <NewDatePickerReact
                onChange={value =>
                  propertyChange({ date: setDate(value as Date) })
                }
                value={blockadeMonth.date}
              />
            </FormRow>
            <FormRow
              controlId="isSalesBlockade"
              label={t(tk.projects.salesBlockade)}
            >
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={blockadeMonth?.isSalesBlocked ?? true}
                onChange={e =>
                  propertyChange({ isSalesBlocked: e.target.checked })
                }
              />
            </FormRow>
            <FormRow
              controlId="isPurchaseBlockade"
              label={t(tk.projects.purchaseBlockade)}
            >
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={blockadeMonth?.isPurchaseBlocked ?? true}
                onChange={e =>
                  propertyChange({ isPurchaseBlocked: e.target.checked })
                }
              />
            </FormRow>
            <FormRow controlId="active" label={t(tk.common.activeHe)}>
              <Form.Check
                type="checkbox"
                className="pt-2"
                checked={blockadeMonth?.active ?? true}
                onChange={e => propertyChange({ active: e.target.checked })}
              />
            </FormRow>
          </Form>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="m-3"
          />
        </Modal.Body>
        <Modal.Footer>
          {props.id && (
            <LastEditorInfo
              id={props.id}
              method={pok.blockades.getLastEditor}
            />
          )}
          <Button
            variant="outline-secondary"
            onClick={props.handleClose}
            disabled={isLoading}
          >
            {t(tk.button.close)}
          </Button>
          <Button
            variant="primary"
            onClick={createBlockadeMonth}
            isLoading={isLoading}
          >
            {t(tk.button.save)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
