/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUpdateAttachmentDto } from './CreateUpdateAttachmentDto';
import {
    CreateUpdateAttachmentDtoFromJSON,
    CreateUpdateAttachmentDtoFromJSONTyped,
    CreateUpdateAttachmentDtoToJSON,
} from './CreateUpdateAttachmentDto';
import type { CreateUpdatePurchaseInvoicePositionDto } from './CreateUpdatePurchaseInvoicePositionDto';
import {
    CreateUpdatePurchaseInvoicePositionDtoFromJSON,
    CreateUpdatePurchaseInvoicePositionDtoFromJSONTyped,
    CreateUpdatePurchaseInvoicePositionDtoToJSON,
} from './CreateUpdatePurchaseInvoicePositionDto';

/**
 * 
 * @export
 * @interface CreateUpdatePurchaseInvoiceDto
 */
export interface CreateUpdatePurchaseInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    number: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    systemNumber: number;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    sellDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    issueDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    paymentDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    amountWithoutPosition?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    currency: CreateUpdatePurchaseInvoiceDtoCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    exchangeRate?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    type: CreateUpdatePurchaseInvoiceDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    status?: CreateUpdatePurchaseInvoiceDtoStatusEnum;
    /**
     * 
     * @type {CreateUpdateAttachmentDto}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    attachment: CreateUpdateAttachmentDto;
    /**
     * 
     * @type {Array<CreateUpdatePurchaseInvoicePositionDto>}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    positions: Array<CreateUpdatePurchaseInvoicePositionDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    comment: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    tv: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    changeAmountsTs?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    changeAmountsOwnerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePurchaseInvoiceDto
     */
    salesInvoiceId?: string;
}


/**
 * @export
 */
export const CreateUpdatePurchaseInvoiceDtoCurrencyEnum = {
    Pln: 'PLN',
    Usd: 'USD',
    Eur: 'EUR',
    Czk: 'CZK',
    Rub: 'RUB',
    Huf: 'HUF',
    Gbp: 'GBP',
    Bgn: 'BGN',
    Ron: 'RON',
    Hrk: 'HRK',
    Aud: 'AUD'
} as const;
export type CreateUpdatePurchaseInvoiceDtoCurrencyEnum = typeof CreateUpdatePurchaseInvoiceDtoCurrencyEnum[keyof typeof CreateUpdatePurchaseInvoiceDtoCurrencyEnum];

/**
 * @export
 */
export const CreateUpdatePurchaseInvoiceDtoTypeEnum = {
    Foreign: 'Foreign',
    Domestic: 'Domestic'
} as const;
export type CreateUpdatePurchaseInvoiceDtoTypeEnum = typeof CreateUpdatePurchaseInvoiceDtoTypeEnum[keyof typeof CreateUpdatePurchaseInvoiceDtoTypeEnum];

/**
 * @export
 */
export const CreateUpdatePurchaseInvoiceDtoStatusEnum = {
    Open: 'Open',
    Approved: 'Approved',
    Sent: 'Sent'
} as const;
export type CreateUpdatePurchaseInvoiceDtoStatusEnum = typeof CreateUpdatePurchaseInvoiceDtoStatusEnum[keyof typeof CreateUpdatePurchaseInvoiceDtoStatusEnum];


/**
 * Check if a given object implements the CreateUpdatePurchaseInvoiceDto interface.
 */
export function instanceOfCreateUpdatePurchaseInvoiceDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "systemNumber" in value;
    isInstance = isInstance && "sellDate" in value;
    isInstance = isInstance && "issueDate" in value;
    isInstance = isInstance && "paymentDate" in value;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "attachment" in value;
    isInstance = isInstance && "positions" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "tv" in value;

    return isInstance;
}

export function CreateUpdatePurchaseInvoiceDtoFromJSON(json: any): CreateUpdatePurchaseInvoiceDto {
    return CreateUpdatePurchaseInvoiceDtoFromJSONTyped(json, false);
}

export function CreateUpdatePurchaseInvoiceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdatePurchaseInvoiceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'systemNumber': json['systemNumber'],
        'sellDate': (new Date(json['sellDate'])),
        'issueDate': (new Date(json['issueDate'])),
        'paymentDate': (new Date(json['paymentDate'])),
        'clientId': json['clientId'],
        'amount': json['amount'],
        'amountWithoutPosition': !exists(json, 'amountWithoutPosition') ? undefined : json['amountWithoutPosition'],
        'active': json['active'],
        'companyId': json['companyId'],
        'currency': json['currency'],
        'exchangeRate': !exists(json, 'exchangeRate') ? undefined : json['exchangeRate'],
        'type': json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'attachment': CreateUpdateAttachmentDtoFromJSON(json['attachment']),
        'positions': ((json['positions'] as Array<any>).map(CreateUpdatePurchaseInvoicePositionDtoFromJSON)),
        'comment': json['comment'],
        'tv': json['tv'],
        'changeAmountsTs': !exists(json, 'changeAmountsTs') ? undefined : (new Date(json['changeAmountsTs'])),
        'changeAmountsOwnerId': !exists(json, 'changeAmountsOwnerId') ? undefined : json['changeAmountsOwnerId'],
        'salesInvoiceId': !exists(json, 'salesInvoiceId') ? undefined : json['salesInvoiceId'],
    };
}

export function CreateUpdatePurchaseInvoiceDtoToJSON(value?: CreateUpdatePurchaseInvoiceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'systemNumber': value.systemNumber,
        'sellDate': (value.sellDate.toISOString()),
        'issueDate': (value.issueDate.toISOString()),
        'paymentDate': (value.paymentDate.toISOString()),
        'clientId': value.clientId,
        'amount': value.amount,
        'amountWithoutPosition': value.amountWithoutPosition,
        'active': value.active,
        'companyId': value.companyId,
        'currency': value.currency,
        'exchangeRate': value.exchangeRate,
        'type': value.type,
        'status': value.status,
        'attachment': CreateUpdateAttachmentDtoToJSON(value.attachment),
        'positions': ((value.positions as Array<any>).map(CreateUpdatePurchaseInvoicePositionDtoToJSON)),
        'comment': value.comment,
        'tv': value.tv,
        'changeAmountsTs': value.changeAmountsTs === undefined ? undefined : (value.changeAmountsTs.toISOString()),
        'changeAmountsOwnerId': value.changeAmountsOwnerId,
        'salesInvoiceId': value.salesInvoiceId,
    };
}

