import React, { useState } from 'react';
import { OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { GetClientExtendedDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

export default (props: {
  id?: string;
  className?: string;
  iconSize?: number;
  placement?: 'left' | 'right' | 'top' | 'bottom';
}) => {
  const [client, setClient] = useState<GetClientExtendedDto>();
  const notifications = useNotifications();
  const pok = usePokCore();
  const { t, tk } = useTranslation('clientLng');

  const getClient = () => {
    if (!props.id) {
      return;
    }
    pok.clients
      .getByIdFormattedAddress(props.id)
      .then(setClient)
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <OverlayTrigger
      placement={props.placement ?? 'top'}
      onEnter={getClient}
      delay={{ show: 250, hide: 4000 }}
      overlay={
        <Popover id="popover-basic">
          <Popover.Header as="h3" className="text-center">
            {t(tk.common.client)}
          </Popover.Header>
          <Popover.Body className="text-center">
            {client ? (
              <>
                {t(tk.common.name)}:{' '}
                <b>{client?.name || t(tk.common.noInformation)}</b> <br />
                {t(tk.client.address)}:{' '}
                <b>{client?.address || t(tk.common.noInformation)}</b> <br />
                {t(tk.client.nip)}:{' '}
                <b>{client?.nip || t(tk.common.noInformation)}</b> <br />
              </>
            ) : (
              <Spinner animation="border" variant="secondary" />
            )}
          </Popover.Body>
        </Popover>
      }
    >
      <Icon.InfoCircle
        className={props.className ?? ''}
        size={props.iconSize ?? 22}
      />
    </OverlayTrigger>
  );
};
