import React, { useRef, forwardRef, useImperativeHandle } from 'react';

import notification from '../../assets/notification.mp3';
import { useCookies } from '../../common/hooks/useCookies';

export interface Player {
  ping: () => void;
  pingIf: (condition: boolean) => void;
}

export default forwardRef<Player | undefined>((props, ref) => {
  const cookies = useCookies();
  const audioPlayer = useRef<HTMLAudioElement>(null);

  useImperativeHandle(ref, () => ({
    ping: () => {
      if (cookies.soundEnabled) {
        audioPlayer.current?.play().catch(error => {
          console.error('Error while starting audio:', error);
        });
      }
    },
    pingIf: (condition: boolean) => {
      if (cookies.soundEnabled && condition) {
        audioPlayer.current?.play().catch(error => {
          console.error('Error while starting audio:', error);
        });
      }
    },
  }));

  return (
    <audio ref={audioPlayer} src={notification} muted={false}>
      <track kind="captions" />
    </audio>
  );
});
