/*eslint max-lines-per-function: ["error", 177]*/
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

import FormRow, {
  FormRow66,
  FormRow66R,
} from '../../../app/components/FormRow';
import {
  InvoiceTypeEnum,
  InvoiceTypeObj,
  PaymentFormEnum,
  PaymentFormObj,
} from '../../../common/pokCore/validation/enums';
import {
  CreateUpdateSalesInvoiceDto,
  GetPurchaserDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';
import { isMainCurrencyPLN } from '../../../utils/currencyFormatUtils';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { NewDatePicker } from '../../../app/components/NewDatePicker';

import { SalesInvoiceCurrencyForm } from './SalesInvoiceCurrencyForm';
import { SalesInvoiceClientEditor } from './SalesInvoiceClientEditor';
import { SalesInvoiceFormCodes } from './SalesInvoiceFormCodes';
import { SalesInvoiceBasicDataNumber } from './SalesInvoiceBasicDataNumber';

interface SalesInvoiceBasicDataEditorProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdateSalesInvoiceDto>) => void;
  readOnly?: boolean;
  number?: string;
  purchaser?: GetPurchaserDto;
  refresh: boolean;
}

export const SalesInvoiceBasicDataEditor: React.FC<
  SalesInvoiceBasicDataEditorProps
> = props => {
  const { salesInvoice, propertyChange, readOnly, purchaser } = props;
  const { t, tk } = useTranslation('fvsLng');

  const paymentFormOptions = Object.entries(PaymentFormEnum).map(
    ([key, value]) => ({
      value: key as PaymentFormEnum,
      label: t(PaymentFormObj[value]),
    }),
  );

  const typeOptions = isMainCurrencyPLN()
    ? Object.entries(InvoiceTypeEnum).map(([key, value]) => ({
        value: key as InvoiceTypeEnum,
        label: t(InvoiceTypeObj[value]),
      }))
    : [
        {
          value: InvoiceTypeEnum.Domestic,
          label: t(InvoiceTypeObj[InvoiceTypeEnum.Domestic]),
        },
      ];

  const handleDateInvoiceChange = (invoiceDate: Date) => {
    if (props.propertyChange) {
      props.propertyChange({ invoiceDate });
    }
  };

  const handleDateSaleChange = (saleDate: Date) => {
    if (props.propertyChange) {
      props.propertyChange({ saleDate });
    }
  };

  return (
    <>
      <SalesInvoiceBasicDataNumber {...props} />
      <SalesInvoiceClientEditor purchaser={purchaser} />
      <SalesInvoiceCurrencyForm
        salesInvoice={salesInvoice}
        propertyChange={propertyChange}
        readOnly={readOnly}
      />
      <Row>
        <Col md={6}>
          <FormRow66 controlId="type" label={t(tk.common.invoiceType)}>
            <Select
              value={
                salesInvoice?.type
                  ? typeOptions.find(
                      ({ value }) => salesInvoice?.type === value,
                    )
                  : null
              }
              options={typeOptions}
              onChange={option =>
                propertyChange({
                  type: option?.value,
                })
              }
              isDisabled={readOnly}
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R controlId="paymentForm" label={t(tk.finance.paymentForm)}>
            <Select
              className="full-width"
              value={
                salesInvoice?.paymentForm
                  ? paymentFormOptions.find(
                      ({ value }) => salesInvoice?.paymentForm === value,
                    )
                  : null
              }
              options={paymentFormOptions}
              onChange={option =>
                propertyChange({
                  paymentForm: option?.value,
                  isPaymentFromReceiptDate:
                    option?.value === PaymentFormEnum.BankTransfer
                      ? salesInvoice.isPaymentFromReceiptDate
                      : false,
                  payableDaysId:
                    option?.value === PaymentFormEnum.BankTransfer
                      ? salesInvoice.payableDaysId
                      : null,
                })
              }
              isDisabled={readOnly}
            />
          </FormRow66R>
        </Col>
      </Row>
      {isMainCurrencyPLN() && <SalesInvoiceFormCodes {...props} />}
      <Row>
        <Col md={6}>
          <FormRow66 controlId="invoiceDate" label={t(tk.common.issueDate)}>
            <NewDatePicker
              value={salesInvoice.invoiceDate || new Date()}
              dateChanged={handleDateInvoiceChange}
              readOnly={readOnly}
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R controlId="saleDate" label={t(tk.common.saleDate)}>
            <NewDatePicker
              value={salesInvoice?.saleDate}
              dateChanged={handleDateSaleChange}
              readOnly={readOnly}
            />
          </FormRow66R>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormRow66 controlId="payableDays" label={t(tk.finance.paymentDate)}>
            <DictionarySelector
              dictionary="Termin płatności faktury"
              value={props.salesInvoice?.payableDaysId || ''}
              onChange={option =>
                propertyChange({
                  payableDaysId: option.value,
                })
              }
              clearable
              sortFunction={(a, b) => +a.value - +b.value}
              readOnly={
                readOnly ||
                salesInvoice.paymentForm !== PaymentFormEnum.BankTransfer
              }
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R
            controlId="isPaymentFromReceiptDate"
            label={t(tk.finance.paymentDateFromReceive)}
          >
            <Form.Check
              type="checkbox"
              className="pt-2"
              checked={salesInvoice.isPaymentFromReceiptDate}
              onChange={e =>
                propertyChange({ isPaymentFromReceiptDate: e.target.checked })
              }
              disabled={
                readOnly ||
                salesInvoice.paymentForm !== PaymentFormEnum.BankTransfer
              }
            />
          </FormRow66R>
        </Col>
      </Row>
      <FormRow
        controlId="printProjectNumber"
        label={t(tk.prLang.printProjectNumber)}
      >
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={salesInvoice.printProjectNumber}
          onChange={e =>
            propertyChange({ printProjectNumber: e.target.checked })
          }
          disabled={readOnly}
        />
      </FormRow>
    </>
  );
};
