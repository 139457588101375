import React from 'react';
import { Calendar } from 'react-calendar';

import { useTranslation } from '../../common/hooks/useTranslation';

type NewCalendarProps = React.PropsWithChildren<{
  value?: Date;
  onChange?: (date: Date | null) => void;
}>;

export const NewCalendar = (props: NewCalendarProps) => {
  const { t, tk } = useTranslation('commonLng');

  const setDate = (date: Date): Date | null => {
    //ten kalendarz inaczej ustawia godzinę niż nasz główny kalendarz
    //dlatego trzeba skorygować godzinę
    date.setMinutes(-date.getTimezoneOffset());
    return date;
  };

  return (
    <Calendar
      value={props.value}
      onChange={value => {
        // const now = new Date();
        //const newDate = value as Date;
        // newDate.setHours(
        //   now.getHours(),
        //   now.getMinutes(),
        //   now.getSeconds(),
        //   now.getMilliseconds(),
        // );
        if (props.onChange) {
          props.onChange(setDate(value as Date));
        }
      }}
      defaultView="year"
      minDetail="year"
      maxDetail="year"
      locale={t(tk.common.locale)}
    />
  );
};
