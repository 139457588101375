import React from 'react';
import type { TFunction } from 'i18next';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetJobConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { JobTypeObj } from '../../../common/pokCore/validation/enums';

interface JobConfigSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const jobConfigForGrid = (
  t: TFunction<'administrationLng', 'administrationLng'>,
  jobConfig: GetJobConfigDto,
) => {
  return {
    key: jobConfig.id,
    values: {
      'jobConfig.systemNumber': jobConfig.systemNumber,
      type_pl: t(JobTypeObj[jobConfig.type]),
      'team.name': jobConfig.jobConfigTeams
        ?.map(({ team }) => team?.name)
        .join(', '),
      'company.shortname': jobConfig.company?.shortname,
      'jobConfig.emails': jobConfig.emails.join(', '),
      'jobConfig.crone': jobConfig.crone,
    },
    isInactive: !jobConfig.active,
  };
};

export const JobConfigSearch: React.FC<JobConfigSearchProps> = props => {
  const { t, tk } = useTranslation('administrationLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.sysNumber), property: 'jobConfig.systemNumber' },
    { header: t(tk.administration.type), property: 'type_pl' },
    { header: t(tk.common.company), property: 'company.shortname' },
    { header: t(tk.common.teams), property: 'team.name' },
    { header: t(tk.administration.emails), property: 'jobConfig.emails' },
    { header: t(tk.administration.cron), property: 'jobConfig.crone' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="jobConfig.systemNumber"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.administration.noJobConfigs)}
      onRowClick={props.onRowClick}
      withHistory={true}
      refresh={props.refresh}
      withFilterInColumns={true}
    />
  );
};
