import React from 'react';
import { Form, InputGroup, Row } from 'react-bootstrap';

import { useTranslation } from '../../../common/hooks/useTranslation';

type Props = {
  fileInputRef: React.RefObject<HTMLInputElement>;
  fileNameRef: React.RefObject<HTMLInputElement>;
  value: string;
  handleFileChange: (files: FileList | null) => void;
  isDirectory?: boolean;
  accept?: string;
  buttonText?: string;
};

export const FileSelector: React.FC<Props> = props => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.handleFileChange((e.currentTarget as HTMLInputElement).files);
    e.target.value = '';
  };
  const { t, tk } = useTranslation('commonLng');

  return (
    <Form.Group as={Row} controlId="fileSelector">
      <div className="d-flex gap-3">
        {!props.isDirectory && (
          <Form.Control
            ref={props.fileInputRef}
            className="hidden"
            type="file"
            accept={props.accept}
            onChange={handleOnChange}
          />
        )}
        {props.isDirectory && (
          <Form.Control
            ref={props.fileInputRef}
            className="hidden"
            type="file"
            accept={props.accept}
            onChange={event =>
              props.handleFileChange(
                (event.currentTarget as HTMLInputElement).files,
              )
            }
            {...{ webkitdirectory: 'true' }}
          />
        )}
        <InputGroup className="mb-3 file-input-group">
          <InputGroup.Text
            className={`btn-light`}
            onClick={() => props.fileInputRef.current?.click()}
          >
            {props.buttonText || t(tk.attachment.chooseFile)}
          </InputGroup.Text>
          <Form.Control
            ref={props.fileNameRef}
            className={'grid-clickable-row'}
            value={props.value}
            readOnly
            placeholder={t(tk.attachment.noFileSelected)}
            onClick={() => {
              props.fileInputRef.current?.click();
              props.fileNameRef.current?.blur();
            }}
          />
        </InputGroup>
      </div>
    </Form.Group>
  );
};
