import type { TFunction } from 'i18next';

import { profileForGrid } from '../../../pok/components/Profiles/ProfileSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { TKeys } from '../../hooks/useTranslation';
import {
  DefaultApi,
  GetExtendedProfileDto,
} from '../autogenerated/pokApiClient';
import {
  CreateUpdateProfileDto,
  CreateUpdateProfileDtoFromJSON,
} from '../autogenerated/pokApiClient';
import { GetLastEditorDto } from '../autogenerated/pokApiClient';
import { GetProfileDto } from '../autogenerated/pokApiClient';
import { ProfileSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface ProfileContextInterface {
  getById: (id: string) => Promise<GetExtendedProfileDto>;
  getAllByPortion: (
    t: TFunction<'administrationLng', 'administrationLng'>,
    tk: TKeys<'administrationLng'>,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllByCompanyIdsTeamIds: (
    companyIds: string[],
    teamIds?: string[],
  ) => Promise<GetProfileDto[]>;
  getAllByCompanyIdsTeamIdsAuth: (
    companyIds: string[],
    teamIds?: string[],
    budgetId?: string,
  ) => Promise<GetProfileDto[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  update: (id: string, dto: CreateUpdateProfileDto) => Promise<void>;
  create: (dto: CreateUpdateProfileDto) => Promise<GetProfileDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const ProfileContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.profileControllerGet(id),
    getAllByPortion: async (
      t: TFunction<'administrationLng', 'administrationLng'>,
      tk: TKeys<'administrationLng'>,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.profileControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );

      return data.map(item => profileForGrid(t, tk, item, orderBy));
    },
    getAllByCompanyIdsTeamIds: (companyIds: string[], teamIds?: string[]) =>
      api.profileControllerGetAllByCompanyIdsTeamIds(companyIds, teamIds),
    getAllByCompanyIdsTeamIdsAuth: (
      companyIds: string[],
      teamIds?: string[],
      budgetId?: string,
    ) =>
      api.profileControllerGetAllByCompanyIdsTeamIdsAuth(
        companyIds,
        teamIds,
        budgetId,
      ),
    getAllCount: (filterText?: string) =>
      api.profileControllerGetAllCount(filterText || ''),
    update: (id: string, dto: CreateUpdateProfileDto) =>
      api.profileControllerUpdate(id, dto),
    create: (dto: CreateUpdateProfileDto) => api.profileControllerCreate(dto),
    getLastEditor: (id: string) => api.profileControllerGetLastEditor(id),
  };
};

export const newProfile = () => {
  return CreateUpdateProfileDtoFromJSON({ schema: {} });
};

export const validate = (profile: CreateUpdateProfileDto) =>
  validateAgainst(ProfileSchema, profile);

export const convert = (profile?: GetExtendedProfileDto) => {
  if (!profile) {
    return newProfile();
  }
  const converted = CreateUpdateProfileDtoFromJSON(profile);
  converted.companyIds = profile.companies?.map(({ id }) => id) || [];
  converted.teamIds = profile.teams?.map(({ id }) => id) || [];
  converted.relatedTeamIds =
    profile.relatedTeams?.map(({ teamId }) => teamId) || [];
  if (!profile.schema) {
    converted.schema = {};
  }

  return converted;
};
