import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ClientWithSymphonyCodeSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  withMerging?: boolean;
  clientHeadId?: string;
  refresh?: boolean;
}

// For getting grid data 'clientForGrid' from ClientSearch.ts is used (it adds symphonyClient.code)

export const ClientWithSymphonyCodeSearch: React.FC<
  ClientWithSymphonyCodeSearchProps
> = props => {
  const { t, tk } = useTranslation('fvsLng');
  const columns: GridColumn[] = [
    { header: t(tk.common.sysNumber), property: 'client.systemNumber' },
    { header: t(tk.common.name), property: 'client.name' },
    { header: t(tk.finance.nip), property: 'client.nip' },
    {
      header: t(tk.symphony.code),
      property: 'symphonyClient.code',
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="client.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.symphony.noClients)}
      additionalId={props.clientHeadId}
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
      withFilterInColumns={true}
    />
  );
};
