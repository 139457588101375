import React from 'react';
import Form from 'react-bootstrap/Form';

import { useTranslation } from '../../common/hooks/useTranslation';

const boolValue = (property?: boolean, mayBeEmpty?: boolean) => {
  if (mayBeEmpty && (property === undefined || property === null)) {
    return '0';
  }
  if (property) {
    return '1';
  }
  return '2';
};

const toBoolValue = (value: string) => {
  if (value === '0') {
    return undefined;
  }
  if (value === '1') {
    return true;
  }
  return false;
};

export default (props: {
  readOnly?: boolean;
  value?: boolean;
  onChange?: (value: boolean | undefined) => void;
  canBeUndefined?: boolean;
}) => {
  const { t, tk } = useTranslation('commonLng');

  return (
    <Form.Control
      as={Form.Select}
      disabled={props.readOnly}
      value={boolValue(props.value, props.canBeUndefined)}
      onChange={e => {
        if (props.onChange) {
          props.onChange(toBoolValue(e.target.value));
        }
      }}
    >
      {props.canBeUndefined ? <option value="0"></option> : <></>}
      <option value="1">{t(tk.common.yes)}</option>
      <option value="2">{t(tk.common.no)}</option>
    </Form.Control>
  );
};
