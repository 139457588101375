import moment from 'moment';

import { i18n } from '../translations';

export default (value: Date | null | undefined, format = 'LL') =>
  value
    ? moment(value)
        //.utc(value)
        .locale(i18n.t('commonLng:common.localeShort'))
        .format(format)
    : '';
