import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import type { TFunction } from 'i18next';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetSalesInvoiceDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import { TKeys, useTranslation } from '../../../common/hooks/useTranslation';
import {
  CurrencyIsoTypeEnum,
  InvoiceStatusEnum,
  InvoiceStatusObj,
  InvoiceTypeEnum,
  InvoiceTypeObj,
  PaymentFormObj,
} from '../../../common/pokCore/validation/enums';
import { buildProjectLabel } from '../../../utils/buildProjectLabel';
import numberFormatter from '../../../common/numberFormatter';

import { NewWindowButton } from './NewWindowButton';

interface SalesInvoiceSearchProps {
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  status?: InvoiceStatusEnum;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  withFilterInColumns?: boolean;
  gridMessage?: string;
  isImportView?: boolean;
  hideStatusColumn?: boolean;
  enableMultiSelect?: boolean;
  showNewWindowButton?: boolean;
  initialOrderDirection?: 'ASC' | 'DESC';
}

export const salesInvoiceForGrid = (
  salesInvoice: GetSalesInvoiceDto,
  t: TFunction<'fvsLng', 'fvsLng'>,
  tk: TKeys<'fvsLng'>,
) => {
  return {
    key: salesInvoice.id,
    values: {
      'salesInvoice.systemNumber': salesInvoice.systemNumber,
      'salesInvoice.currency': salesInvoice.currency,
      'salesInvoice.number': (
        <>
          {salesInvoice.number}
          {salesInvoice.number && <br />}
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t(InvoiceTypeObj[salesInvoice.type])}</Tooltip>}
          >
            {salesInvoice.type === InvoiceTypeEnum.Foreign ? (
              <Icon.Globe2 className="icon-blue me-2" />
            ) : (
              <Icon.House className="icon-blue me-2" />
            )}
          </OverlayTrigger>
          {!!salesInvoice.parent && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  {t(tk.finance.correctionToInvoice)}{' '}
                  {salesInvoice.originalInvoice?.number}
                </Tooltip>
              }
            >
              <Icon.FileEarmarkMinusFill className="icon-blue me-2" />
            </OverlayTrigger>
          )}
          {!salesInvoice.isSymphonyCode && (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{t(tk.finance.noSymphonyCode)}</Tooltip>}
            >
              <Icon.ExclamationCircle className="icon-red me-2" />
            </OverlayTrigger>
          )}
        </>
      ),
      'salesInvoice.invoiceDate': momencik(salesInvoice.invoiceDate),
      'salesInvoice.saleDate': momencik(salesInvoice.saleDate),
      invoice_status: t(InvoiceStatusObj[salesInvoice.status]),
      'salesInvoice.paymentForm': t(PaymentFormObj[salesInvoice.paymentForm]),
      'payableDays.value': salesInvoice.payableDays?.value,
      project_label: salesInvoice.salesInvoiceProjects
        ?.map(({ project }) => buildProjectLabel(project))
        .join(', '),
      'client.name': salesInvoice.purchaser?.client?.name,
      'client.nip': salesInvoice.purchaser?.client?.nip,
      'budget.name': salesInvoice.purchaser?.budget?.name,
      netSum: numberFormatter(
        salesInvoice.netSum || 0,
        true,
        salesInvoice.company.mainCurrency.currencyCode as CurrencyIsoTypeEnum,
      ),
      newWindowButton: <NewWindowButton salesInvoiceId={salesInvoice.id} />,
    },
    isInactive: !salesInvoice.active,
  };
};

export const SalesInvoiceSearch: React.FC<SalesInvoiceSearchProps> = props => {
  const { t, tk } = useTranslation('fvsLng');

  const columns: GridColumn[] = [
    ...(props.showNewWindowButton
      ? [
          {
            header: '',
            property: 'newWindowButton',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
    { header: t(tk.common.sysNumber), property: 'salesInvoice.systemNumber' },
    { header: t(tk.common.number), property: 'salesInvoice.number' },
    { header: t(tk.common.issueDate), property: 'salesInvoice.invoiceDate' },
    { header: t(tk.common.saleDate), property: 'salesInvoice.saleDate' },
    ...(props.isImportView
      ? []
      : [
          {
            header: t(tk.finance.paymentForm),
            property: 'salesInvoice.paymentForm',
            noFilter: true,
          },
          {
            header: t(tk.finance.paymentDate),
            property: 'payableDays.value',
          },
        ]),
    { header: t(tk.common.currency), property: 'salesInvoice.currency' },
    ...(props.hideStatusColumn
      ? []
      : [{ header: t(tk.common.status), property: 'invoice_status' }]),
    { header: t(tk.prLang.project), property: 'project_label' },
    { header: t(tk.finance.buyerName), property: 'client.name' },
    { header: t(tk.finance.nip), property: 'client.nip' },
    { header: t(tk.common.budget), property: 'budget.name' },
    {
      header: t(tk.finance.net),
      property: 'netSum',
      noSorting: true,
      noFilter: true,
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      refresh={props.refresh}
      initialOrderColumn="salesInvoice.systemNumber"
      initialOrderDirection={props.initialOrderDirection || 'DESC'}
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={props.gridMessage || t(tk.finance.noInvoices)}
      onRowClick={props.onRowClick}
      withHistory={true}
      withFilterInColumns={props.withFilterInColumns}
      enableMultiSelect={props.enableMultiSelect}
      withOverflowXAuto={true}
    />
  );
};
