import React, { useMemo } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { TabsHistory } from '../../../app/components/TabsHistory';
import {
  InvoiceTypeEnum,
  PackageTypeEnum,
} from '../../../common/pokCore/validation/enums';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { PackagesInvoicesXMLSearch } from '../../components/PackagesInvoicesXML/PackagesInvoicesXMLSearch';
import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ExportFVSToXMLByTypeView } from './ExportFVSToXMLByTypeView';

export const ExportFVSToXMLView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const { t, tk } = useTranslation('fvsLng');
  const fvz = useTranslation('fvzLng');

  const onRowClick = (id: string) => {
    void nav.packagesFVS(id);
  };

  const getDataPackages = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.packagesXML.getAllByPortion(
          fvz.t,
          fvz.tk,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          PackageTypeEnum.Sale,
        )) as GridGetterFunction,
    [pok.packagesXML, fvz.t, fvz.tk],
  );

  const getCountPackages = useMemo(
    () =>
      ((filterText: string) =>
        pok.packagesXML.getAllCount(
          filterText,
          PackageTypeEnum.Sale,
        )) as GridCountFunction,
    [pok.packagesXML],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.finance.exportFvs)}</Card.Title>
        <TabsHistory
          activeKey="invoicesDomesticToSent"
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey="invoicesDomesticToSent"
            title={t(tk.finance.invoicesDomesticToSent)}
            className="m-3"
          >
            <ExportFVSToXMLByTypeView
              type={InvoiceTypeEnum.Domestic}
              title={t(tk.finance.createInvoicesDomesticToSent)}
            />
          </Tab>
          <Tab
            eventKey="invoicesForeignToSent"
            title={t(tk.finance.invoicesForeignToSent)}
            className="m-3"
          >
            <ExportFVSToXMLByTypeView
              type={InvoiceTypeEnum.Foreign}
              title={t(tk.finance.createInvoicesForeignToSent)}
            />
          </Tab>
          <Tab
            eventKey="packagesFVS"
            title={t(tk.finance.packagesFVS)}
            className="m-3"
          >
            <PackagesInvoicesXMLSearch
              getData={getDataPackages}
              getCount={getCountPackages}
              // refresh={refresh}
              onRowClick={onRowClick}
              packageType={PackageTypeEnum.Sale}
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
