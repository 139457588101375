import React, { useState } from 'react';
import { Alert, Form, Tab, Tabs } from 'react-bootstrap';
import Select from 'react-select';
import Scheduler from 'material-ui-cron';

import { CreateUpdateJobConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  JobTypeEnum,
  JobTypeObj,
} from '../../../common/pokCore/validation/enums';
import FormRow from '../../../app/components/FormRow';
import { Option } from '../Selects/Selector';
import CompanySelector from '../Selects/CompanySelector';
import TeamSelector from '../Selects/TeamSelector';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { getOptionValues } from '../Selects/selectUtils';

import { translationScheduler } from './translationScheduler';

interface JobConfigEditorProps {
  jobConfig: CreateUpdateJobConfigDto;
  readOnly: boolean;
  propertyChange: (obj: Partial<CreateUpdateJobConfigDto>) => void;
  createErrors: (errors: string[]) => void;
}

export const JobConfigFrom: React.FC<JobConfigEditorProps> = props => {
  const [cronError, setCronError] = useState('');
  const { t, tk } = useTranslation('administrationLng');
  const pok = usePokCore();

  const typeOptions = Object.entries(JobTypeEnum).map(([key, value]) => ({
    value: key as JobTypeEnum,
    label: t(JobTypeObj[value]),
  }));

  return (
    <Tabs>
      <Tab
        eventKey="jobConfig"
        title={t(tk.administration.jobConfig)}
        className="m-3"
      >
        <Form className="d-grid gap-3">
          <FormRow controlId="type" label={t(tk.administration.jobConfigType)}>
            <Select
              className="full-width"
              value={
                props.jobConfig.type
                  ? typeOptions.find(
                      ({ value }) => props.jobConfig.type === value,
                    )
                  : null
              }
              options={typeOptions}
              onChange={option =>
                props.propertyChange({
                  type: option?.value,
                })
              }
              isDisabled={props.readOnly}
              placeholder={t(tk.component.pointOut)}
            />
          </FormRow>
          {(props.jobConfig.type === JobTypeEnum.DailyFVZReminder ||
            props.jobConfig.type === JobTypeEnum.WeekFVZReminder) && (
            <>
              <FormRow controlId="emails" label={t(tk.administration.emails)}>
                <Form.Control
                  type="text"
                  value={props.jobConfig.emails.join(',')}
                  onChange={e =>
                    props.propertyChange({
                      emails: e.target.value.split(','),
                    })
                  }
                  readOnly={props.readOnly}
                  placeholder="jan@kowalski.pl, adam@nowak.pl, mateusz@wojcik.pl"
                />
              </FormRow>
              <FormRow controlId="company" label={t(tk.common.company)}>
                <CompanySelector
                  readOnly={props.readOnly}
                  value={props.jobConfig.companyId}
                  onChange={option =>
                    props.propertyChange({
                      companyId: (option as Option)?.value,
                      teamIds: [],
                    })
                  }
                  clearable
                />
              </FormRow>
              <FormRow controlId="team" label={t(tk.common.teams)}>
                <TeamSelector
                  isMulti={true}
                  value={props.jobConfig.teamIds}
                  companyId={props.jobConfig.companyId}
                  onChange={options => {
                    props.propertyChange({
                      teamIds: getOptionValues(options),
                    });
                  }}
                  clearable
                />
              </FormRow>
            </>
          )}
          <FormRow controlId="active" label={t(tk.common.activeHe)}>
            <Form.Check
              type="checkbox"
              className="pt-2"
              checked={props.jobConfig?.active ?? true}
              onChange={e => props.propertyChange({ active: e.target.checked })}
            />
          </FormRow>
        </Form>
      </Tab>

      <Tab eventKey="cron" title={t(tk.administration.cron)} className="m-3">
        <Scheduler
          cron={props.jobConfig.crone || '0 8 * * 1-5'}
          setCron={e => props.propertyChange({ crone: e.toString() })}
          setCronError={setCronError}
          isAdmin={true}
          customLocale={
            pok.getLanguage() === 'PL' ? translationScheduler : undefined
          }
        />
        {cronError && (
          <Alert variant="warning">
            {`${t(tk.administration.cronError)} ${cronError}`}
          </Alert>
        )}
      </Tab>
    </Tabs>
  );
};
