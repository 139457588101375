import type { TFunction } from 'i18next';

import {
  CreateUpdateApplicationDto,
  DefaultApi,
  GetApplicationDto,
  GetLastEditorDto,
  UpdateAdminApplicationDto,
  UpdateAdminApplicationDtoFromJSON,
} from '../autogenerated/pokApiClient';
import {
  ApplicationAdminSchema,
  ApplicationSchema,
  ApplicationWithClientSchema,
} from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { applicationForGrid } from '../../../pok/components/Applications/ApplicationSearch';
import { TKeys } from '../../hooks/useTranslation';
import {
  ApplicationStatusEnum,
  ApplicationTypeEnum,
} from '../validation/enums';

export interface ApplicationContextInterface {
  getById: (id: string) => Promise<GetApplicationDto>;
  getAllByPortion: (
    t: TFunction<'applicationLng', 'applicationLng'>,
    tk: TKeys<'applicationLng'>,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    status?: ApplicationStatusEnum,
    withContextCompany?: boolean,
    onlyActive?: boolean,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    status?: ApplicationStatusEnum,
    withContextCompany?: boolean,
    active?: boolean,
  ) => Promise<number>;
  getAllByPortionByCurrentUser: (
    t: TFunction<'applicationLng', 'applicationLng'>,
    tk: TKeys<'applicationLng'>,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getCountByCurrentUser: (filterText?: string) => Promise<number>;
  update: (id: string, dto: CreateUpdateApplicationDto) => Promise<void>;
  updateByAdmin: (id: string, dto: UpdateAdminApplicationDto) => Promise<void>;
  create: (dto: CreateUpdateApplicationDto) => Promise<GetApplicationDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const ApplicationContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.applicationControllerGet(id),
    getAllByPortion: async (
      t: TFunction<'applicationLng', 'applicationLng'>,
      tk: TKeys<'applicationLng'>,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      status?: ApplicationStatusEnum,
      withContextCompany?: boolean,
      onlyActive?: boolean,
    ) => {
      const data = await api.applicationControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        status || '',
        !!onlyActive,
        withContextCompany,
      );

      return data.map(item => applicationForGrid(t, tk, item));
    },
    getAllCount: async (
      filterText?: string,
      status?: ApplicationStatusEnum,
      withContextCompany?: boolean,
      onlyActive?: boolean,
    ) =>
      api.applicationControllerGetAllCount(
        filterText || '',
        status || '',
        !!onlyActive,
        withContextCompany,
      ),
    getAllByPortionByCurrentUser: async (
      t: TFunction<'applicationLng', 'applicationLng'>,
      tk: TKeys<'applicationLng'>,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.applicationControllerGetAllByPortionByCurrentUser(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );

      return data.map(item => applicationForGrid(t, tk, item));
    },
    getCountByCurrentUser: async (filterText?: string) =>
      api.applicationControllerGetCountByCurrentUser(filterText || ''),
    update: (id: string, dto: CreateUpdateApplicationDto) =>
      api.applicationControllerUpdate(id, dto),
    updateByAdmin: (id: string, dto: UpdateAdminApplicationDto) =>
      api.applicationControllerUpdateByAdmin(id, dto),
    create: (dto: CreateUpdateApplicationDto) =>
      api.applicationControllerCreate(dto),
    getLastEditor: (id: string) => api.applicationControllerGetLastEditor(id),
  };
};

export const newApplication = (companyId?: string) => {
  return UpdateAdminApplicationDtoFromJSON({
    companyId: companyId,
    country: 'Polska',
  });
};

export const validate = (application: CreateUpdateApplicationDto) => {
  let schema = undefined;
  switch (application.applicationType) {
    case ApplicationTypeEnum.Position:
    case ApplicationTypeEnum.Purchaser:
    case ApplicationTypeEnum.Permission:
      schema = ApplicationWithClientSchema;
      break;
    default:
      schema = ApplicationSchema;
  }
  return validateAgainst(schema, application);
};

export const validateAdmin = (application: UpdateAdminApplicationDto) =>
  validateAgainst(ApplicationAdminSchema, application);

export const convert = (application?: GetApplicationDto) => {
  if (!application) {
    return newApplication();
  }
  const converted = UpdateAdminApplicationDtoFromJSON(application);

  converted.teamId = application.team.id;
  converted.companyId = application.company.id;
  converted.purchaserId = application.purchaser?.id;
  converted.positionId = application.position?.id;
  converted.profileId = application.profile?.id;
  converted.brandId = application.brand?.id;
  converted.brandApplicationPurchaserId =
    application.brandApplicationPurchaser?.id;
  converted.permissionEmployeeId = application.permissionEmployee?.id;
  converted.permissionEmployeeBossId = application.permissionEmployeeBoss?.id;
  converted.countryId = application.country?.id;
  converted.countryIsMain = application.country?.isMain;

  return converted;
};
