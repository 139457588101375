import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import type { MultiValue } from 'react-select';

import { CreateUpdateTeamDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import { Option } from '../Selects/Selector';
import CompanySelector from '../Selects/CompanySelector';
import EmployeeSelector from '../Selects/EmployeeSelector';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface TeamEditorFormProps {
  team: CreateUpdateTeamDto;
  propertyChange?: (obj: Partial<CreateUpdateTeamDto>) => void;
  readOnly?: boolean;
  creatingTeam?: boolean;
}

export const TeamEditorForm: React.FC<TeamEditorFormProps> = props => {
  const [companyIdForEmplSelector, setCompanyIdForEmplSelector] = useState<
    string | undefined
  >();
  const pok = usePokCore();
  const { t, tk } = useTranslation('administrationLng');

  useEffect(() => {
    const companyId = props.team.companyId ?? pok.getCompanyId();
    if (companyId !== companyIdForEmplSelector) {
      setCompanyIdForEmplSelector(companyId);
    }
  }, [props.team.companyId, pok, companyIdForEmplSelector]);

  const handleEmployeeSelected = async (
    option: Option | MultiValue<Option> | null | undefined,
  ) => {
    if (option && (option as Option)?.value) {
      if (props.propertyChange) {
        props.propertyChange({ managerId: (option as Option)?.value });
      }
    }
  };

  return (
    <>
      <Form className="d-grid gap-3">
        <FormRow controlId="name" label={t(tk.common.name)}>
          <Form.Control
            type="text"
            value={props.team?.name || ''}
            onChange={e =>
              props.propertyChange &&
              props.propertyChange({ name: e.target.value })
            }
            disabled={props.readOnly}
          />
        </FormRow>

        <FormRow controlId="email" label={t(tk.administration.email)}>
          <Form.Control
            type="text"
            value={props.team?.email || ''}
            onChange={e =>
              props.propertyChange &&
              props.propertyChange({ email: e.target.value })
            }
            disabled={props.readOnly}
          />
        </FormRow>
        <FormRow controlId="company" label={t(tk.common.company)}>
          <CompanySelector
            value={companyIdForEmplSelector}
            onChange={e => {
              if (props.propertyChange) {
                props.propertyChange({
                  companyId: (e as Option).value,
                  managerId: undefined,
                });
              }
            }}
            readOnly={props.readOnly || !props.creatingTeam}
          />
        </FormRow>
        <FormRow controlId="manager" label={t(tk.administration.managerName)}>
          <EmployeeSelector
            value={props.team?.managerId}
            onChange={handleEmployeeSelected}
            readOnly={props.readOnly || companyIdForEmplSelector === null}
          />
        </FormRow>
        <FormRow controlId="pae" label={t(tk.administration.paeSupport)}>
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={props.team?.pae ?? true}
            onChange={e =>
              props.propertyChange &&
              props.propertyChange({ pae: e.target.checked })
            }
            disabled={props.readOnly}
          />
        </FormRow>
        <FormRow controlId="tv" label={t(tk.administration.tvSupport)}>
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={props.team?.tv ? true : false}
            onChange={e =>
              props.propertyChange &&
              props.propertyChange({ tv: e.target.checked })
            }
            disabled={props.readOnly}
          />
        </FormRow>
        {!props.readOnly && (
          <FormRow controlId="active" label={t(tk.common.activeHe)}>
            <Form.Check
              type="checkbox"
              className="pt-2"
              checked={props.team?.active ?? true}
              onChange={e =>
                props.propertyChange &&
                props.propertyChange({ active: e.target.checked })
              }
              disabled={props.readOnly}
            />
          </FormRow>
        )}
      </Form>
    </>
  );
};
