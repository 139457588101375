/*eslint max-lines-per-function: ["error", 250]*/
/*eslint complexity: ["error", 30]*/
import React, { useEffect, useState } from 'react';
import { Modal, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import mime from 'mime';
import type { MultiValue } from 'react-select';

import { Option } from '../Selects/Selector';
import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateAttachmentDto,
  GetAttachmentDto,
  GetAttachmentDtoEnObjectTypeEnum,
  GetClientDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { AssociateDocumentModalProps } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { convert } from '../../../common/pokCore/contexts/AttachmentContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import momencik from '../../../common/momencik';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import ValidationAlert from '../../../app/components/ValidationAlert';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import Button from '../../../common/components/Button/Button';
import { ClientSelector } from '../Selects/ClientSelector';
import EstimateItemSelector from '../Selects/EstimateItemSelector';
import CurrencyControl from '../../../app/components/CurrencyControl';
import mathUtils from '../../../utils/mathUtils';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { getOptionValues } from '../Selects/selectUtils';

export const Attachment = (
  props: AssociateDocumentModalProps<
    GetAttachmentDto,
    CreateUpdateAttachmentDto
  >,
) => {
  const pok = usePokCore();
  const lang = pok.getLanguage();
  const { t, tk } = useTranslation('commonLng');
  const isNote = props.type === 'notes';
  const isFileExist = mime.getType(props.document?.name || '') !== null;
  const [attachment, setAttachment] = useState(props.document);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [client, setClient] = useState<GetClientDto>();
  const [estimateItemIds, setEstimateItemIds] = useState<string[]>([]);
  const [positionsSum, setPositionsSum] = useState(0);

  const saveAttachment = () => {
    const converted = convert(attachment, attachment.dictionaryType?.shortname);
    converted.clientId = client?.id;
    converted.estimateItemsIds = estimateItemIds;
    if (attachment.name === 'Notatka' && !attachment.note) {
      setShowSaveErrors(true);
      setSaveErrors([t(tk.attachment.addNote)]);
      return;
    }

    void props.validate?.(converted).then(result => {
      if (result.valid) {
        props.onUpdate?.(attachment.id, converted);
        return;
      }
      setShowSaveErrors(true);
      setSaveErrors(result.errors);
    });
  };

  const onChangePositions = async (
    options: Option | MultiValue<Option> | null | undefined,
  ) => {
    setEstimateItemIds(getOptionValues(options));

    let result = 0;

    for (const { value } of options as Option[]) {
      result = mathUtils.add(
        result,
        (await pok.estimateItems.getById(value))?.purchaseNetTotal || 0,
      );
    }

    setPositionsSum(result);
  };

  useEffect(() => {
    setShowSaveErrors(false);
    setSaveErrors([]);
    setAttachment(props.document);
    setClient(props.document?.client);
    setEstimateItemIds(
      props.document?.estimateItems?.map(o => o.estimateItem?.id) || [],
    );
    setPositionsSum(
      props.document?.estimateItems?.reduce(
        (acc, { estimateItem }) =>
          mathUtils.add(acc, estimateItem?.purchaseNetTotal || 0),
        0,
      ) || 0,
    );
  }, [props.document]);

  if (!props.document) {
    return null;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      className="modal-on-modal"
      backdropClassName="modal-on-modal-backdrop"
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isNote
            ? t(tk.attachment.noteAdded, {
                date: momencik(props.document.creationTs),
              })
            : t(tk.attachment.attachmentAdded, {
                date: momencik(props.document.creationTs),
              })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-grid gap-3">
          <FormRow
            controlId="company"
            label={t(tk.common.company)}
            static={true}
          >
            {props.document.company?.name || ''}
          </FormRow>
          <FormRow controlId="type" label={t(tk.attachment.type)} static={true}>
            {lang === 'PL'
              ? props.document.dictionaryType.value
              : props.document.dictionaryType.valueEn}
          </FormRow>
          <FormRow controlId="description" label={t(tk.common.title)}>
            <DynamicTextarea
              value={attachment?.description || ''}
              onChange={event =>
                setAttachment({
                  ...attachment,
                  description: event.target.value,
                })
              }
              readOnly={props.readonly}
            />
          </FormRow>
          {isFileExist && (
            <FormRow controlId="file" label={t(tk.common.name)}>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t(tk.common.openNewWindow)}</Tooltip>}
              >
                <Button
                  variant="link"
                  className="px-0"
                  onClick={() =>
                    props.onGet && props.onGet(props.document, 'open')
                  }
                >
                  {props.document.name}
                </Button>
              </OverlayTrigger>
            </FormRow>
          )}
          <FormRow controlId="note" label={t(tk.attachment.note)} static>
            <DynamicTextarea
              minRows={3}
              value={attachment?.note || ''}
              onChange={e =>
                setAttachment({ ...attachment, note: e.target.value })
              }
              readOnly={props.readonly}
            />
          </FormRow>
          {attachment &&
            attachment.dictionaryType?.shortname?.startsWith('POZ') &&
            attachment.enObjectType ===
              GetAttachmentDtoEnObjectTypeEnum.Project && (
              <>
                <FormRow
                  controlId="clientId"
                  label={t(tk.attachment.invoicing)}
                >
                  <ClientSelector
                    projectId={attachment.objectId}
                    onChange={option =>
                      setClient({
                        id: option.value,
                        name: option.label,
                      } as GetClientDto)
                    }
                    value={attachment.client}
                  />
                </FormRow>
                <FormRow
                  controlId="estimateItemIds"
                  label={t(tk.attachment.relatedItems)}
                >
                  <EstimateItemSelector
                    isMulti={true}
                    projectId={attachment.objectId}
                    clientId={client?.id || undefined}
                    value={estimateItemIds}
                    onChange={options => onChangePositions(options)}
                    readOnly={!client?.id}
                  />
                </FormRow>
                <FormRow
                  controlId="positionsSum"
                  label={t(tk.attachment.positionsSum)}
                >
                  <CurrencyControl
                    decimalScale={2}
                    value={positionsSum}
                    allowNegative
                    disabled={true}
                  />
                </FormRow>
              </>
            )}
        </Form>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <LastEditorInfo id={props.document.id} method={props.onGetLastEditor} />
        <Button variant="outline-secondary" onClick={props.onClose}>
          {t(tk.button.close)}
        </Button>
        {!props.readonly && (
          <ConfirmationButton
            variant="outline-danger"
            onOK={() => props.onDelete(props.document.id)}
            confirmation={
              isNote
                ? t(tk.attachment.askDeleteNote)
                : t(tk.attachment.askDeleteAttachment)
            }
            isLoading={props.isLoadingDelete}
          >
            {t(tk.button.delete)}
          </ConfirmationButton>
        )}
        {isFileExist && (
          <Button
            variant="outline-primary"
            onClick={() =>
              props.onGet && props.onGet(props.document, 'download')
            }
          >
            {t(tk.button.download)}
          </Button>
        )}
        {(!props.readonly ||
          (attachment &&
            attachment.dictionaryType?.shortname?.startsWith('POZ') &&
            attachment.enObjectType ===
              GetAttachmentDtoEnObjectTypeEnum.Project)) && (
          <Button
            variant="primary"
            onClick={saveAttachment}
            isLoading={props.isLoadingSave}
          >
            {t(tk.button.save)}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
