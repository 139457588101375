import React, { useState, useRef, useEffect } from 'react';
import { ButtonGroup, Button, Spinner } from 'react-bootstrap';

import { useMemosProvider } from '../../../common/hooks/useMemosProvider';
import { ThreadsFilterEnum } from '../../../common/pokCore/contexts/MemoContext';
import { useTranslation } from '../../../common/hooks/useTranslation';

const noneLoading = {
  all: false,
  privates: false,
  subjects: false,
};

export const ThreadsFilter = () => {
  const memosProvider = useMemosProvider();
  const { t, tk } = useTranslation('commonLng');
  const [current, setCurrent] = useState(memosProvider.threadsFilter);
  const [loading, setLoading] = useState(noneLoading);
  const btnAllRef = useRef<HTMLButtonElement>(null);
  const btnPrivatesRef = useRef<HTMLButtonElement>(null);
  const btnSubjectsRef = useRef<HTMLButtonElement>(null);

  const variant = (condition: boolean) =>
    condition ? 'secondary' : 'outline-secondary';

  const changeTo = (
    value: ThreadsFilterEnum,
    btn: React.RefObject<HTMLButtonElement>,
  ) => {
    if (value === ThreadsFilterEnum.all) {
      setLoading({ ...noneLoading, all: true });
    } else if (value === ThreadsFilterEnum.privates) {
      setLoading({ ...noneLoading, privates: true });
    } else {
      setLoading({ ...noneLoading, subjects: true });
    }

    if (current !== value) {
      memosProvider.setThreadsFilter(value);
      setCurrent(value);
    }
    btn.current?.blur();
  };

  const spin = () => (
    <Spinner role="status" variant="light" animation="border" size="sm" />
  );

  useEffect(() => {
    setLoading(noneLoading);
  }, [memosProvider.threads]);

  const allCount = memosProvider.threadsStats?.threadsCount || 0;
  const subjectCount = memosProvider.threadsStats?.subjectThreadsCount || 0;
  if (allCount === 0 || subjectCount === 0 || allCount === subjectCount) {
    return <></>;
  }

  return (
    <ButtonGroup className="m-auto">
      <Button
        className="d-flex px-2"
        ref={btnAllRef}
        variant={variant(current === ThreadsFilterEnum.all)}
        onClick={() => changeTo(ThreadsFilterEnum.all, btnAllRef)}
      >
        <span className="ms-3">{t(tk.memo.all)}</span>
        <span className="ms-1 threads-filter-spinner">
          {loading.all && spin()}
        </span>
      </Button>
      <Button
        className="d-flex px-2"
        ref={btnPrivatesRef}
        variant={variant(current === ThreadsFilterEnum.privates)}
        onClick={() => changeTo(ThreadsFilterEnum.privates, btnPrivatesRef)}
      >
        <span className="ms-3">{t(tk.memo.private)}</span>
        <span className="ms-1 threads-filter-spinner">
          {loading.privates && spin()}
        </span>
      </Button>
      <Button
        className="d-flex px-2"
        ref={btnSubjectsRef}
        variant={variant(current === ThreadsFilterEnum.subjects)}
        onClick={() => changeTo(ThreadsFilterEnum.subjects, btnSubjectsRef)}
      >
        <span className="ms-3">{t(tk.memo.documents)}</span>
        <span className="ms-1 threads-filter-spinner">
          {loading.subjects && spin()}
        </span>
      </Button>
    </ButtonGroup>
  );
};
