/*eslint complexity: ["error", 27]*/
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useNotifications } from '../../../common/hooks/useNotifications';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetProjectDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import momencik from '../../../common/momencik';
import { BadCompany } from '../../../common/components/NotAuthorized/BadCompany';
import { useAuth } from '../../../common/hooks/useAuth';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import ClientInfo from '../../components/Clients/ClientInfo';
import { ProjectTabs } from '../../components/Projects/ProjectTabs';

type ParamTypes = {
  projectId: string;
};

export const ProjectDetailsView: React.FC = () => {
  const [project, setProject] = useState<GetProjectDto>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [uniqueServices, setUniqueServices] = useState<string[]>();
  const [paeTeam, setPaeTeam] = useState<boolean>(false);
  const { projectId: projectId } = useParams<ParamTypes>() as {
    projectId: string;
  };
  const pok = usePokCore();
  const auth = useAuth();
  const notifications = useNotifications();
  const [authorized, setAuthorized] = useState<boolean>();
  const { t, tk } = useTranslation('projectLng');

  useEffect(() => {
    if (project && project?.company?.id !== pok.getCompanyId()) {
      setAuthorized(false);
    } else {
      if (project) {
        setAuthorized(
          auth.check(PermissionsEnum.Projects, project?.company?.id),
        );
      }
    }
  }, [pok, project, auth]);

  useEffect(() => {
    pok.projects
      .getUniqueServicesNames(projectId)
      .then(setUniqueServices)
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [notifications, pok.projects, projectId, refresh]);

  useEffect(() => {
    pok.projects
      .getWithParent(projectId)
      .then(setProject)
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [notifications, pok.clients, pok.projects, projectId, refresh]);

  useEffect(() => {
    pok.teams
      .paeTeam()
      .then(setPaeTeam)
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [notifications, pok.teams]);

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      {!authorized && project && (
        <BadCompany companyId={project?.company?.id} />
      )}
      {authorized && project && (
        <Card className="main-card">
          <Card.Body className="m-3">
            <Card.Title className="pb-3">
              <div className="side-by-side">
                <div className="left-70">
                  <p>
                    {t(tk.prLang.project)}:{' '}
                    <b>
                      {project?.name} ({project?.number})
                    </b>
                    {`, ${t(tk.common.sysNumberLowerCase)} `}
                    {project?.systemNumber}
                  </p>
                  {project?.parent && (
                    <p>
                      <small>
                        {t(tk.prLang.internalProject)}: {project?.parent?.name}{' '}
                        ({project?.parent?.number})
                      </small>
                    </p>
                  )}
                  <p className="font-90">
                    {`${t(tk.projects.for)}: `}
                    {project?.purchaser.name} (
                    {project?.purchaser?.client?.name})
                    <ClientInfo
                      id={project?.purchaser?.client.id}
                      className="color-primary ms-2 mb-1"
                      iconSize={18}
                      placement="right"
                    />
                  </p>
                </div>
                <div className="right-30">
                  <p>
                    {`${t(tk.projects.from)}: `}
                    <b>{momencik(project?.startDate, 'L')}</b>{' '}
                    {`${t(tk.projects.to)}: `}
                    <b>{momencik(project?.endDate, 'L')}</b>
                  </p>
                  <p className="font-90">
                    {`${t(tk.common.team)}: `}
                    {project?.projectTeams?.map(t => t.team.name).join(', ')}
                  </p>
                </div>
              </div>
            </Card.Title>
            {project && (
              <ProjectTabs
                project={project}
                uniqueServices={uniqueServices}
                paeTeam={paeTeam}
                onRefresh={onRefresh}
              />
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};
