import type { TFunction } from 'i18next';

import { employeeForGrid } from '../../../pok/components/Employees/EmployeeSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { DefaultApi, GetEmployeeDto } from '../autogenerated/pokApiClient';
import { Option } from '../../../pok/components/Selects/Selector';
import { TKeys } from '../../hooks/useTranslation';

export interface EmployeeContextInterface {
  getCurrent: () => Promise<GetEmployeeDto>;
  getAllByPortion: (
    t: TFunction<'administrationLng', 'administrationLng'>,
    tk: TKeys<'administrationLng'>,
    refresh: boolean,
    setRefresh: (refresh: boolean) => void,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getNotActiveByPortion: (
    t: TFunction<'administrationLng', 'administrationLng'>,
    tk: TKeys<'administrationLng'>,
    refresh: boolean,
    setRefresh: (refresh: boolean) => void,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getNotActiveCount: (filterText?: string) => Promise<number>;
  addToEmployeesFromOneTool: () => Promise<void>;
  getByCompanyId: (
    id?: string,
    withTechnical?: boolean,
  ) => Promise<GetEmployeeDto[]>;
  getAllForOrders: () => Promise<GetEmployeeDto[]>;
  getOptionById: (id: string, withOneToolId?: boolean) => Promise<Option>;
}

export const EmployeeContext = (api: DefaultApi) => {
  return {
    getCurrent: () => api.employeeControllerGetCurrent(),
    getAllByPortion: async (
      t: TFunction<'administrationLng', 'administrationLng'>,
      tk: TKeys<'administrationLng'>,
      refresh: boolean,
      setRefresh: (refresh: boolean) => void,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.employeeControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );

      return data.map(empl =>
        employeeForGrid(t, tk, empl, refresh, setRefresh),
      );
    },

    getAllCount: (filterText?: string) =>
      api.employeeControllerGetAllCount(filterText || ''),
    getNotActiveByPortion: async (
      t: TFunction<'administrationLng', 'administrationLng'>,
      tk: TKeys<'administrationLng'>,
      refresh: boolean,
      setRefresh: (refresh: boolean) => void,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.employeeControllerGetNotActiveByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );

      return data.map(empl =>
        employeeForGrid(t, tk, empl, refresh, setRefresh),
      );
    },

    getNotActiveCount: (filterText?: string) =>
      api.employeeControllerGetNotActiveCount(filterText || ''),

    addToEmployeesFromOneTool: () =>
      api.employeeControllerAddToEmployeesFromOneTool(),
    getByCompanyId: (id?: string, withTechnical?: boolean) =>
      api
        .employeeControllerGetByCompany(id, withTechnical)
        .then(emp => emp.sort((a, b) => a.name?.localeCompare(b.name, 'pl'))),
    getAllForOrders: () => api.employeeControllerGetAllForOrders(),
    getOptionById: async (id: string, withOneToolId?: boolean) => {
      const data = await api.employeeControllerGet(id);
      return {
        value: data.id,
        label: withOneToolId
          ? `${data.name} (${data.supervisor} - OneTool: ${data.oneToolId})`
          : `${data.name} (${data.supervisor})`,
      };
    },
  };
};
