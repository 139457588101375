import * as plProjects from './plProjects';
import * as plCampaigns from './plCampaigns';
import * as enProjects from './enProjects';
import * as enCampaigns from './enCampaigns';

export const schema = plProjects;
export type TranslationSchema = typeof plProjects;

export const resources = {
  plProjects,
  plCampaigns,
  enProjects,
  enCampaigns,
} as const;
