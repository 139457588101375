import React, { Dispatch, SetStateAction, useState } from 'react';

import {
  CreateUpdatePurchaseInvoiceDto,
  CreateUpdatePurchaseInvoiceEstimateItemDto,
  GetEstimateItemDto,
  GetPurchaseInvoiceExtendedDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { validateEstimateItemsTab } from '../../../common/pokCore/contexts/PurchaseInvoiceContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import Button from '../../../common/components/Button/Button';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { PurchaseInvoiceTabsEnum } from './PurchaseInvoiceTabsEnum';
import { checkDivergedPurchaseInvoiceAmounts } from './checkDivergedPurchaseInvoiceAmounts';

interface PurchaseInvoiceEditorFormEstimateItemPositionsControlsProps {
  purchaseInvoice: CreateUpdatePurchaseInvoiceDto;
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  setNotSavedTab: Dispatch<SetStateAction<PurchaseInvoiceTabsEnum | undefined>>;
  handleRefresh: () => Promise<void>;
  readOnly: boolean;
  setSaveErrors: Dispatch<SetStateAction<string[]>>;
  allAvailableEstimateItems: GetEstimateItemDto[];
  purchaseInvoiceEstimateItems: CreateUpdatePurchaseInvoiceEstimateItemDto[];
  warnings: string[];
}

export const PurchaseInvoiceEditorFormEstimateItemPositionsControls: React.FC<
  PurchaseInvoiceEditorFormEstimateItemPositionsControlsProps
> = props => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('fvzLng');
  const [isLoading, setIsLoading] = useState(false);

  const id = props.extendedPurchaseInvoice?.id || '';
  const { hasNegativeAmounts, hasMoreThenAvailableAmounts } =
    checkDivergedPurchaseInvoiceAmounts(
      props.purchaseInvoiceEstimateItems,
      props.allAvailableEstimateItems,
      props.extendedPurchaseInvoice,
    );

  const handleSave = async () => {
    const status = await validateEstimateItemsTab({
      purchaseInvoiceEstimateItems: props.purchaseInvoiceEstimateItems,
    });
    if (status.valid) {
      try {
        setIsLoading(true);
        await pok.purchaseInvoices.updateEstimateItems(id, {
          purchaseInvoiceEstimateItems: props.purchaseInvoiceEstimateItems,
          changeStatus: true,
        });
        props.setSaveErrors([]);
        await props.handleRefresh();
        notifications.saveCompleted();
      } catch (response) {
        props.setSaveErrors(await responseErrors(response as ResponseError));
      } finally {
        setIsLoading(false);
      }
    } else {
      props.setSaveErrors(status.errors);
    }
  };

  if (props.readOnly) {
    return null;
  }

  return (
    <div className="d-flex justify-content-end">
      {!props.warnings.length &&
        (hasNegativeAmounts || hasMoreThenAvailableAmounts ? (
          <ConfirmationButton
            className="ms-2 mt-4"
            variant="primary"
            confirmation={
              hasNegativeAmounts
                ? t(tk.finance.hasNegativeAmounts, {
                    estimate: t(tk.prLang.estimate2),
                  })
                : t(tk.finance.hasMoreThenAvailableAmounts, {
                    estimate: t(tk.prLang.estimate2),
                  })
            }
            onOK={handleSave}
            isLoading={isLoading}
            modalSize="lg"
          >
            {t(tk.button.save)}
          </ConfirmationButton>
        ) : (
          <Button
            className="ms-2 mt-4"
            variant="primary"
            onClick={handleSave}
            isLoading={isLoading}
          >
            {t(tk.button.save)}
          </Button>
        ))}
    </div>
  );
};
