export default {
  schemas: {
    chooseExcel: 'Proszę załadować plik Excel.',
    chooseConfigImport: 'Proszę wybrać konfigurację importu.',

    invalidAccountNumber: 'Niepoprawny numer konta',
    provideNip: 'Proszę podać numer NIP.',
    invalidNip: 'Podany NIP jest niepoprawny.',
    nipDigitsOnly: 'Numer NIP powinien się składać wyłącznie z cyfr.',
    invalidPesel: 'Podany PESEL jest niepoprawny.',
    peselDigitsOnly: 'Numer PESEL powinien się składać wyłącznie z cyfr.',
    nipOrPeselRequired: 'Proszę podać numer NIP lub PESEL.',
    provideClientName: 'Proszę podać nazwę kontrahenta.',
    provideCountry: 'Proszę wskazać państwo.',
    postcodeFormat: 'Poczta powinna być w formacie XX-XXX.',
    providePlace: 'Proszę wprowadzić miejscowość.',
    provideForeignAddress: 'Dla podmiotów zagranicznych należy podać adres.',
    provideCompanyName: 'Proszę podać nazwę spółki.',
    provideCompanyShortname: 'Proszę podać skrót spółki.',
    chooseNamingConvention: 'Proszę wybrać nazewnictwo.',
    maxDaysDelayInteger:
      'Maksymalne opóźnienie musi być liczbą całkowitą i nieujemną.',
    provideMaxCampaignDelay:
      'Proszę podać maksymalne dopuszczalne opóźnienie zakładania kampanii.',
    orderNumberInteger:
      'Liczba porządkowa musi być liczbą całkowitą i nieujemną.',
    chooseInvoiceTemplate: 'Proszę wybrać szablon faktur.',
    chooseMainCurrency: 'Proszę wybrać walutę główną.',
    chooseCompanyLanguage: 'Proszę wybrać język spółki.',
    provideDictionaryValue: 'Proszę podać wartość słownika.',
    provideDictionaryValueEn:
      'Proszę podać angielską wersję wartości słownika.',
    provideRoleName: 'Proszę podać nazwę roli.',
    choosePermissions: 'Proszę wybrać uprawnienia.',
    chooseEmployee: 'Proszę wybrać pracownika.',
    chooseRole: 'Proszę wybrać rolę.',
    chooseUser: 'Proszę wybrać użytkownika.',
    chooseRoles: 'Proszę wybrać role.',
    chooseAtLeastOneRole: 'Proszę wybrać co najmniej jedną rolę.',
    provideTeamName: 'Proszę podać nazwę zespołu.',
    provideEmail: 'Proszę podać adres e-mail.',
    invalidEmail:
      "Adres e-mail: '{{email}}' jest niepoprawny. Kilka adresów proszę rozdzielić przecinkiem.",
    provideTeamManager: 'Proszę podać szefa zespołu.',
    provideBudgetName: 'Proszę podać nazwę budżetu.',
    provideBudgetManager: 'Proszę podać szefa budżetu.',
    provideBrandName: 'Proszę podać nazwę brandu.',
    provideBrandBudget: 'Proszę podać budżet brandu.',
    providePurchaserName: 'Proszę podać nazwę zamawiającego.',
    chooseCompany: 'Proszę wybrać spółkę.',
    chooseClient: 'Proszę wybrać kontrahenta.',
    chooseClientBudget: 'Proszę wybrać budżet zamawiającego.',
    providePositionName: 'Proszę podać nazwę.',
    provideGroupName: 'Proszę podać nazwę grupy.',
    chooseCommunicationChannel: 'Proszę wybrać kanał komunikacji.',
    choosePositionType: 'Proszę wybrać typ pozycji.',
    chooseAtLeastOneCompany: 'Proszę wybrać co najmniej jedną spółkę.',
    chooseProfile: 'Proszę wybrać profil.',
    chooseAtLeastOneProfile: 'Proszę wybrać co najmniej jeden profil.',
    specifyAttachmentType: 'Należy wskazać typ załącznika.',
    attachmentObjectTypeRequired:
      'Typ obiektu skojarzonego z załącznikiem musi być ustalony.',
    specifyCompany: 'Należy wskazać spółkę.',
    provideInvoicer: 'Proszę podać fakturującego.',
    chooseRelatedItems:
      'Proszę wskazać pozycje, których dotyczy ten załącznik.',
    chooseAtLeastOneRelatedItem:
      'Proszę wybrać co najmniej jedną powiązaną pozycję.',
    addAttachmentOrNote: 'Proszę dodać załącznik lub wpisać notatkę.',
    provideProjectName: 'Proszę podać nazwę.',
    choosePurchaser: 'Proszę wybrać zamawiającego.',
    chooseTeam: 'Proszę wybrać zespół.',
    chooseAtLeastOneTeam: 'Proszę wybrać co najmniej jeden zespół.',
    companySetupError: 'Błąd przy ustawianiu spółki.',
    chooseCountryOrMarket: 'Proszę wybrać kraj/rynek.',
    startDateAfterJan2024:
      'Data rozpoczęcia nie może być wcześniejsza niż 1 stycznia 2024.',
    provideStartDate: 'Proszę podać datę rozpoczęcia.',
    endDateAfterStartDate:
      'Data zakończenia nie może być wcześniejsza od daty rozpoczęcia.',
    provideEndDate: 'Proszę podać datę zakończenia.',
    fillRequestContent: 'Proszę uzupełnić treść wniosku.',
    provideRejectionReason: 'Proszę podać uzasadnienie odrzucenia.',
    integerValue: 'Wartość musi być liczbą całkowitą.',
    nonNegativeCode: 'Kod musi być liczbą nieujemną.',
    provideSymphonyCode: 'Proszę podać kod Symfonii.',
    provideName: 'Proszę podać nazwę.',
    provideProfileName: 'Proszę podać nazwę profilu.',
    namesNotUniquePl: 'Nazwy nie są unikalne',
    namesNotUniqueEn: 'Nazwy angielskie nie są unikalne',
    provideProfileCategoryName: 'Proszę podać nazwę kategorii profilu.',
    providePurchaseAccount: 'Proszę podać konto księgowe zakupu.',
    provideSalesAccount: 'Proszę podać konto księgowe sprzedaży.',
    provideCommissionAccount: 'Proszę podać konto księgowe prowizji.',
    chooseApplicationType: 'Proszę wybrać typ wniosku.',
    provideElementName: 'Proszę uzupełnić nazwę wnioskowanego elementu.',
    provideDescription: 'Proszę podać opis.',
    provideEmployee: 'Proszę uzupełnić pracownika.',
    provideEmployeeBoss: 'Proszę uzupełnić przełożonego pracownika.',
    provideRejectionReason2: 'Proszę uzupełnić powód odrzucenia.',
    providePosition: 'Proszę uzupełnić pozycję.',
    providePurchaser: 'Proszę uzupełnić zamawiającego.',
    provideBrand: 'Proszę uzupełnić brand.',
    chooseValidCurrencyIso: 'Proszę podać prawidłowy kod ISO waluty.',
    chooseProject: 'Nie wskazano kampanii/projektu.',
    chooseMonth: 'Proszę wskazać miesiąc.',
    chooseProfileCategory: 'Proszę wybrać kategorię profilu.',
    chooseStartDate: 'Proszę podać datę rozpoczęcia.',
    chooseEndDate: 'Proszę podać datę zakończenia.',
    endDateNotEarlierThanStartDate:
      'Data zakończenia nie może być wcześniejsza od daty rozpoczęcia.',
    chooseProfilePosition: 'Proszę wybrać pozycję profilu.',
    chooseNetAmount: 'Proszę podać kwotę netto pozycji faktury.',
    chooseVatRate: 'Proszę wybrać stawkę VAT.',
    chooseVatAmount: 'Proszę podać kwotę VAT pozycji faktury.',
    chooseInvoiceNumber: 'Proszę podać numer faktury.',

    giveProject: 'Proszę podać projekt.',
    chooseSpecialistTeam: 'Proszę wybrać zespół specjalistyczny.',
    chooseRecipientsOrTeamMail:
      'Wybierz odbiorców lub zaznacz wysyłkę maila na adres zespołu.',
    chooseRejectionReason: 'Proszę podać uzasadnienie odrzucenia.',
    addAtLeastOnePosition: 'Proszę dodać przynajmniej jedną pozycję.',
    chooseAtLeastOnePosition:
      'Wymagana jest minimum jedna pozycja w realizacji zamówienia.',
    choosePositionName: 'Proszę podać nazwę pozycji.',
    choosePositionAmount: 'Proszę podać kwotę pozycji.',
    chooseSomeMonth: 'Proszę wybrać miesiąc.',
    chooseConfigType: 'Proszę wybrać typ konfiguracji.',
    chooseFrequency: 'Proszę podać częstotliwość.',
    chooseInvalidEmail:
      'Adres e-mail: {{email}} jest niepoprawny. Kilka adresów proszę rozdzielić przecinkiem.',
    chooseExcelPositionName: 'Proszę podać nazwę pozycji w Excelu.',
    choosePosition: 'Proszę wybrać pozycję.',
    chooseSaleDate: 'Proszę podać datę sprzedaży.',
    chooseIssueDate: 'Proszę podać datę wystawienia.',
    choosePaymentDate: 'Proszę podać termin płatności.',
    choosePaymentTerm: 'Proszę podać termin płatności.',
    chooseAttachment: 'Proszę dodać załącznik.',
    chooseTeamsOrProjects: 'Proszę dodać kampanie/projekty lub zespoły.',
    chooseConfigName: 'Proszę podać nazwę konfiguracji.',
    choosePOKField: 'Proszę wybrać pole w POK.',
    chooseExcelColumnName: 'Proszę podać nazwę kolumny w Excelu.',
    chooseColumnOrder: 'Proszę podać kolejność kolumny.',
    chooseIntegerOrder: 'Kolejność musi być liczbą całkowitą i nieujemną.',
    chooseAcceptedInvoicingDescription:
      'Proszę uzupełnić zaakceptowany opis fakturowania.',
    chooseEmailAddress: 'Proszę podać adres e-mail.',
    chooseAtLeastOneEmail: 'Proszę podać co najmniej jeden adres e-mail.',
    chooseAtLeastOneValidEmail:
      'Proszę podać co najmniej jeden niepusty adres e-mail.',
    chooseAccountNumber: 'Proszę podać numer konta.',
    chooseCurrency: 'Proszę wybrać walutę.',
    chooseInvoiceType: 'Proszę wybrać typ faktury.',
    chooseSwiftCode: 'Proszę podać kod SWIFT.',
    chooseBankName: 'Proszę podać nazwę banku',
    chooseVatAccountNumber: 'Proszę podać numer konta VAT.',
    chooseVatBankName: 'Proszę podać nazwę banku VAT.',
    chooseStatus: 'Proszę wybrać status.',
    chooseMonthlyProfile: 'Proszę wybrać profil miesięczny.',
    chooseReason: 'Proszę podać powód zgłoszenia.',
    chooseDocumentationConfirmation:
      'Proszę zaznaczyć, czy zmiana ma potwierdzenie w dokumentacji.',
    chooseSalesCorrection:
      'Proszę zaznaczyć, czy zgłoszenie dotyczy korekty sprzedaży.',
    chooseSalesOrPurchaseBlock:
      'Proszę zaznaczyć blokadę sprzedaży lub zakupu.',
    chooseRecipient: 'Wiadomość musi być skierowana do kogoś.',
    chooseMemoContent: 'Należy podać treść notatki.',
    chooseRequestContent: 'Proszę podać treść wniosku.',
    chooseInvoicingDescription: 'Proszę podać opis fakturowania.',
    chooseAtLeastOneProject:
      'Proszę dodać przynajmniej jedną kampanię/projekt.',
    chooseRequiredChanges: 'Proszę uzupełnić wymagane zmiany.',
    chooseRequestStatus: 'Proszę ustawić status wniosku.',
    chooseAmount: 'Proszę podać kwotę.',
    amountCannotBeNegative: 'Kwota pozycji musi być liczbą nieujemną.',
    chooseSaleAmount: 'Proszę podać kwotę sprzedaży.',
    chooseCommissionAmount: 'Proszę podać kwotę prowizji.',
    chooseMediaPlanPosition: 'Proszę wybrać pozycję mediaplanu.',
    chooseInvoice: 'Proszę wybrać fakturę.',
    invalidEmails:
      'Adres e-mail: {{firstInvalidEmail}} jest niepoprawny. Kilka adresów proszę rozdzielić przecinkiem.',
    namesNotUnique: 'Nazwy nie są unikalne ({{names}}).',
    englishNamesNotUnique: 'Nazwy angielskie nie są unikalne ({{names}}).',
    givePositionName: 'L.p. {{order}}: proszę podać nazwę pozycji.',
    giveAmount: 'L.p. {{order}}: proszę podać kwotę pozycji.',
  },
};
