import React, { useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';

import Button from '../components/Button/Button';
import { useTranslation } from '../../translations';

type ResolveReject = [(value: void) => void, (reason?: Error) => void];

export interface useSelectedGridRowReturn {
  confirm: (message: string) => Promise<void>;
  RenderModal: () => JSX.Element;
}

const useConfirmationModal = () => {
  const { t, tk } = useTranslation('fvsLng');
  const [show, setShow] = useState(false);
  const [resolveReject, setResolveReject] = useState<ResolveReject | null>(
    null,
  );
  const [message, setMessage] = useState('');

  const confirm = useCallback((msg: string): Promise<void> => {
    setMessage(msg);
    setShow(true);
    return new Promise<void>((resolve, reject) => {
      setResolveReject([resolve, reject]);
    });
  }, []);

  const handleClose = useCallback(() => setShow(false), []);

  const handleConfirm = useCallback(() => {
    if (resolveReject) {
      const [resolve] = resolveReject;
      resolve();
    }
    handleClose();
  }, [resolveReject, handleClose]);

  const handleReject = useCallback(() => {
    if (resolveReject) {
      const [, reject] = resolveReject;
      reject(new Error(t(tk.finance.rejected)));
    }
    handleClose();
  }, [resolveReject, handleClose, t, tk.finance.rejected]);

  const RenderModal = ({
    size,
    isLoading,
  }: {
    size?: 'sm' | 'lg' | 'xl';
    isLoading?: boolean;
  }): JSX.Element => (
    <Modal
      show={show}
      onHide={handleReject}
      centered
      backdrop="static"
      backdropClassName="modal-on-modal-backdrop"
      className="modal-on-modal"
      size={size}
    >
      <Modal.Header>
        <Modal.Title>{message}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={handleReject}
          isLoading={isLoading}
        >
          {t(tk.common.no)}
        </Button>
        <Button variant="primary" onClick={handleConfirm} isLoading={isLoading}>
          {t(tk.common.yes)}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return { confirm, RenderModal };
};

export default useConfirmationModal;
