/*eslint max-lines-per-function: ["error", 190]*/
import React, { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { useNavigate } from 'react-router-dom';

import {
  CreateUpdateSalesInvoicePositionDto,
  GetProjectDto,
  GetSalesInvoiceDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  buildPositionsFromInvoiceEstimateItems,
  convertInvoicePosition,
  validateInvoicePosition,
} from '../../../common/pokCore/contexts/SalesInvoicesContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { SalesInvoiceEditorTabs } from '../../containers/SalesInvoiceView/SalesInvoiceEditorTabs';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useLocation } from '../../../common/hooks/useLocation';
import useSelectGrid from '../../../common/hooks/useSelectGridRow';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { SalesInvoicePositionsGrid } from './SalesInvoicePositionsGrid';
import { SalesInvoicePositionsControls } from './SalesInvoicePositionsControls';
import useSalesInvoicePositions from './useSalesInvoicePositions';

interface SalesInvoicePositionsProps {
  readOnly?: boolean;
  salesInvoice?: GetSalesInvoiceDto;
  id?: string;
  handleRefresh: () => void;
  setNotSavedTab: (tab: SalesInvoiceEditorTabs | undefined) => void;
  projects: GetProjectDto[];
  parentAmount?: string;
}

export type TabLocationState = {
  tabKey?: string;
  resetSalesInvoicePositions?: boolean;
  notSavedTab?: string;
};

const sortPositions = (
  positions: CreateUpdateSalesInvoicePositionDto[],
  projects: GetProjectDto[],
) => {
  const result = sortBy(
    positions,
    [
      position => projects.find(({ id }) => position.projectId === id)?.number,
      position => position.financialAccount,
      position => position.name,
      position => position.order,
    ],
    ['asc', 'asc', 'asc', 'asc'],
  );
  return result.map((position, index) => ({ ...position, order: index + 1 }));
};

export const SalesInvoicePositions: React.FC<
  SalesInvoicePositionsProps
> = props => {
  const select = useSelectGrid<number>();
  const {
    positions,
    parentPositions,
    estimateItems,
    setPositions,
    setInitialPositions,
    propertyChange,
    clonePosition,
    mergePositions,
    mergePositionsByProject,
    isMergeDisabled,
    checkDidDataChanged,
  } = useSalesInvoicePositions(
    props.projects,
    select,
    props.setNotSavedTab,
    props.salesInvoice,
  );
  const location = useLocation<TabLocationState>();
  const pok = usePokCore();
  const navigate = useNavigate();
  const { t, tk } = useTranslation('fvsLng');
  const notifications = useNotifications();
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const isCurrentTabActive =
    location.state?.tabKey === SalesInvoiceEditorTabs.INVOICE_POSITIONS;

  const resetPositions = () => {
    const newPositions = sortPositions(
      buildPositionsFromInvoiceEstimateItems(
        props.salesInvoice,
        parentPositions,
        estimateItems,
      ),
      props.projects,
    );
    setPositions(sortBy(newPositions, ['order']));
    select.handleUnselectAll();
    checkDidDataChanged(newPositions);
  };

  const handleGoToNextStep = () => {
    props.handleRefresh();
    void navigate(`${location.pathname}${location.search || ''}`, {
      state: {
        tabKey: SalesInvoiceEditorTabs.SUMMARY,
      },
    });
  };

  const onComplete = async (): Promise<{
    saved: boolean;
    errors: string[];
  }> => {
    const errors: string[] = [];

    const validationResults = await Promise.all(
      positions.map(async position => ({
        ...(await validateInvoicePosition(position)),
        order: position.order,
      })),
    );

    const isValid = validationResults.every(result => result.valid);
    validationResults.forEach(result => {
      if (!result.valid) {
        errors.push(
          ...result.errors.map(
            error => `${t(tk.common.lp)} ${result.order}: ${error}`,
          ),
        );
      }
    });

    if (isValid) {
      try {
        await pok.salesInvoices.createPositions({
          positions,
          salesInvoiceId: props.salesInvoice?.id || '',
        });
        props.setNotSavedTab(undefined);

        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors,
      };
    }
  };

  const createSalesInvoicePositions = async () => {
    setIsSaveLoading(true);
    onComplete()
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        setSaveErrors(saveStatus.errors);
        setShowSaveErrors(saveStatus.saved);
        notifications.saveCompleted();
        handleGoToNextStep();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsSaveLoading(false));
  };

  useEffect(() => {
    if (props.salesInvoice) {
      const positionsToSet = sortBy(
        sortPositions(
          props.salesInvoice.salesInvoicePositions?.length &&
            !location.state?.resetSalesInvoicePositions
            ? props.salesInvoice.salesInvoicePositions.map(
                convertInvoicePosition,
              )
            : buildPositionsFromInvoiceEstimateItems(
                props.salesInvoice,
                parentPositions,
                estimateItems,
              ),
          props.projects,
        ),
        ['order'],
      );

      setInitialPositions(positionsToSet);
      setPositions(positionsToSet);

      if (
        isCurrentTabActive &&
        !props.salesInvoice.salesInvoicePositions?.length
      ) {
        props.setNotSavedTab(SalesInvoiceEditorTabs.INVOICE_POSITIONS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.salesInvoice, estimateItems]);

  return (
    <>
      <SalesInvoicePositionsGrid
        positions={positions}
        propertyChange={propertyChange}
        select={select}
        readOnly={props.readOnly}
        salesInvoice={props.salesInvoice}
        parentAmount={props.parentAmount}
      />
      <ValidationAlert
        show={showSaveErrors}
        errors={saveErrors}
        className="m-3"
      />
      <SalesInvoicePositionsControls
        selected={select.selected}
        isMergeDisabled={isMergeDisabled}
        handleSave={createSalesInvoicePositions}
        handleResetPositions={resetPositions}
        handleClonePosition={clonePosition}
        handleMergePositions={mergePositions}
        handleMergePositionsByProject={mergePositionsByProject}
        readOnly={props.readOnly}
        isLoading={isSaveLoading}
      />
    </>
  );
};
