import { Buffer } from 'buffer';

import Form from 'react-bootstrap/Form';
import React, { useRef, forwardRef } from 'react';
import { InputGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { CreateUpdateAttachmentDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { AttachmentInputDownloadIcon } from './AttachmentInputDownloadIcon';

interface AttachmentInputProps {
  accept?: string;
  attachment?: CreateUpdateAttachmentDto;
  onChange: (attachment?: CreateUpdateAttachmentDto) => void;
  createNewAttachment: (id?: string) => CreateUpdateAttachmentDto;
  readOnly?: boolean;
  companyId?: string;
}
export const AttachmentInput = forwardRef<
  HTMLInputElement,
  AttachmentInputProps
>((props, ref) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileNameRef = useRef<HTMLInputElement>(null);
  const { t, tk } = useTranslation('commonLng');

  const handleFileChange = (files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }
    const f: File = files[0];
    const reader = new FileReader();
    const name = f.name;

    reader.onload = function (e) {
      if (e.target) {
        const target: FileReader = e.target;
        if (target.result) {
          const attachment = props.createNewAttachment();
          attachment.fileContent = Buffer.from(target.result as ArrayBuffer);
          attachment.name = name;
          if (props.companyId) {
            attachment.companyId = props.companyId;
          }
          props.onChange(attachment);
        }
      }
    };
    reader.readAsArrayBuffer(f);
  };

  return (
    <div className="d-flex gap-3">
      <Form.Control
        ref={fileInputRef}
        className="hidden"
        type="file"
        accept={props.accept}
        disabled={props.readOnly}
        onChange={event =>
          handleFileChange((event.currentTarget as HTMLInputElement).files)
        }
      />
      <InputGroup className="mb-3 file-input-group">
        <InputGroup.Text
          className={`btn-light ${
            props.readOnly ? 'disabled' : 'grid-clickable-row'
          }`}
          onClick={() => !props.readOnly && fileInputRef.current?.click()}
        >
          {t(tk.attachment.chooseFile)}
        </InputGroup.Text>
        <Form.Control
          ref={ref}
          className={props.readOnly ? undefined : 'grid-clickable-row'}
          value={props.attachment?.name || ''}
          placeholder={t(tk.attachment.noFileSelected)}
          disabled={props.readOnly}
          onClick={() => {
            if (!props.readOnly) {
              fileInputRef.current?.click();
              fileNameRef.current?.blur();
            }
          }}
          onChange={() => {}}
        />
      </InputGroup>
      <AttachmentInputDownloadIcon attachment={props.attachment} />
      <AttachmentInputDownloadIcon
        attachment={props.attachment}
        isOpen={true}
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={e => e.stopPropagation()}>
        <ConfirmationButton
          variant="outline-danger"
          confirmation={t(tk.attachment.askDeleteAttachment)}
          tooltip={t(tk.attachment.deleteAttachment)}
          onOK={() => props.onChange()}
          disabled={!props.attachment || props.readOnly}
        >
          <Trash />
        </ConfirmationButton>
      </div>
    </div>
  );
});
