/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetBrandDto } from './GetBrandDto';
import {
    GetBrandDtoFromJSON,
    GetBrandDtoFromJSONTyped,
    GetBrandDtoToJSON,
} from './GetBrandDto';
import type { GetCompanyDto } from './GetCompanyDto';
import {
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
} from './GetCompanyDto';
import type { GetCountryDto } from './GetCountryDto';
import {
    GetCountryDtoFromJSON,
    GetCountryDtoFromJSONTyped,
    GetCountryDtoToJSON,
} from './GetCountryDto';
import type { GetEmployeeDto } from './GetEmployeeDto';
import {
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './GetEmployeeDto';
import type { GetPositionDto } from './GetPositionDto';
import {
    GetPositionDtoFromJSON,
    GetPositionDtoFromJSONTyped,
    GetPositionDtoToJSON,
} from './GetPositionDto';
import type { GetProfileDto } from './GetProfileDto';
import {
    GetProfileDtoFromJSON,
    GetProfileDtoFromJSONTyped,
    GetProfileDtoToJSON,
} from './GetProfileDto';
import type { GetPurchaserDto } from './GetPurchaserDto';
import {
    GetPurchaserDtoFromJSON,
    GetPurchaserDtoFromJSONTyped,
    GetPurchaserDtoToJSON,
} from './GetPurchaserDto';
import type { GetTeamDto } from './GetTeamDto';
import {
    GetTeamDtoFromJSON,
    GetTeamDtoFromJSONTyped,
    GetTeamDtoToJSON,
} from './GetTeamDto';

/**
 * 
 * @export
 * @interface GetApplicationDto
 */
export interface GetApplicationDto {
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    applicationType: GetApplicationDtoApplicationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    description: string;
    /**
     * 
     * @type {GetTeamDto}
     * @memberof GetApplicationDto
     */
    team: GetTeamDto;
    /**
     * 
     * @type {GetBrandDto}
     * @memberof GetApplicationDto
     */
    brand: GetBrandDto;
    /**
     * 
     * @type {GetPurchaserDto}
     * @memberof GetApplicationDto
     */
    brandApplicationPurchaser: GetPurchaserDto;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetApplicationDto
     */
    company: GetCompanyDto;
    /**
     * 
     * @type {GetPositionDto}
     * @memberof GetApplicationDto
     */
    position: GetPositionDto;
    /**
     * 
     * @type {GetPurchaserDto}
     * @memberof GetApplicationDto
     */
    purchaser: GetPurchaserDto;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetApplicationDto
     */
    author: GetEmployeeDto;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetApplicationDto
     */
    permissionEmployee: GetEmployeeDto;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetApplicationDto
     */
    permissionEmployeeBoss: GetEmployeeDto;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    elementName: string;
    /**
     * 
     * @type {GetProfileDto}
     * @memberof GetApplicationDto
     */
    profile: GetProfileDto;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    shortname: string;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    nip: string;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    pesel: string;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    addressEx: string;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    postcode: string;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    place: string;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    street: string;
    /**
     * 
     * @type {GetCountryDto}
     * @memberof GetApplicationDto
     */
    country?: GetCountryDto;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    numbers: string;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    flat: string;
    /**
     * 
     * @type {Date}
     * @memberof GetApplicationDto
     */
    createdDate: Date;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    applicationStatus: GetApplicationDtoApplicationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetApplicationDto
     */
    rejectedNote: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetApplicationDto
     */
    active: boolean;
}


/**
 * @export
 */
export const GetApplicationDtoApplicationTypeEnum = {
    Position: 'Position',
    Purchaser: 'Purchaser',
    DictionaryValue: 'DictionaryValue',
    Brand: 'Brand',
    Permission: 'Permission'
} as const;
export type GetApplicationDtoApplicationTypeEnum = typeof GetApplicationDtoApplicationTypeEnum[keyof typeof GetApplicationDtoApplicationTypeEnum];

/**
 * @export
 */
export const GetApplicationDtoApplicationStatusEnum = {
    New: 'New',
    Completed: 'Completed',
    Rejected: 'Rejected'
} as const;
export type GetApplicationDtoApplicationStatusEnum = typeof GetApplicationDtoApplicationStatusEnum[keyof typeof GetApplicationDtoApplicationStatusEnum];


/**
 * Check if a given object implements the GetApplicationDto interface.
 */
export function instanceOfGetApplicationDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "applicationType" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "team" in value;
    isInstance = isInstance && "brand" in value;
    isInstance = isInstance && "brandApplicationPurchaser" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "purchaser" in value;
    isInstance = isInstance && "author" in value;
    isInstance = isInstance && "permissionEmployee" in value;
    isInstance = isInstance && "permissionEmployeeBoss" in value;
    isInstance = isInstance && "elementName" in value;
    isInstance = isInstance && "profile" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "shortname" in value;
    isInstance = isInstance && "nip" in value;
    isInstance = isInstance && "pesel" in value;
    isInstance = isInstance && "addressEx" in value;
    isInstance = isInstance && "postcode" in value;
    isInstance = isInstance && "place" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "numbers" in value;
    isInstance = isInstance && "flat" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "applicationStatus" in value;
    isInstance = isInstance && "rejectedNote" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function GetApplicationDtoFromJSON(json: any): GetApplicationDto {
    return GetApplicationDtoFromJSONTyped(json, false);
}

export function GetApplicationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApplicationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'applicationType': json['applicationType'],
        'description': json['description'],
        'team': GetTeamDtoFromJSON(json['team']),
        'brand': GetBrandDtoFromJSON(json['brand']),
        'brandApplicationPurchaser': GetPurchaserDtoFromJSON(json['brandApplicationPurchaser']),
        'company': GetCompanyDtoFromJSON(json['company']),
        'position': GetPositionDtoFromJSON(json['position']),
        'purchaser': GetPurchaserDtoFromJSON(json['purchaser']),
        'author': GetEmployeeDtoFromJSON(json['author']),
        'permissionEmployee': GetEmployeeDtoFromJSON(json['permissionEmployee']),
        'permissionEmployeeBoss': GetEmployeeDtoFromJSON(json['permissionEmployeeBoss']),
        'elementName': json['elementName'],
        'profile': GetProfileDtoFromJSON(json['profile']),
        'name': json['name'],
        'shortname': json['shortname'],
        'nip': json['nip'],
        'pesel': json['pesel'],
        'addressEx': json['addressEx'],
        'postcode': json['postcode'],
        'place': json['place'],
        'street': json['street'],
        'country': !exists(json, 'country') ? undefined : GetCountryDtoFromJSON(json['country']),
        'numbers': json['numbers'],
        'flat': json['flat'],
        'createdDate': (new Date(json['createdDate'])),
        'applicationStatus': json['applicationStatus'],
        'rejectedNote': json['rejectedNote'],
        'active': json['active'],
    };
}

export function GetApplicationDtoToJSON(value?: GetApplicationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'applicationType': value.applicationType,
        'description': value.description,
        'team': GetTeamDtoToJSON(value.team),
        'brand': GetBrandDtoToJSON(value.brand),
        'brandApplicationPurchaser': GetPurchaserDtoToJSON(value.brandApplicationPurchaser),
        'company': GetCompanyDtoToJSON(value.company),
        'position': GetPositionDtoToJSON(value.position),
        'purchaser': GetPurchaserDtoToJSON(value.purchaser),
        'author': GetEmployeeDtoToJSON(value.author),
        'permissionEmployee': GetEmployeeDtoToJSON(value.permissionEmployee),
        'permissionEmployeeBoss': GetEmployeeDtoToJSON(value.permissionEmployeeBoss),
        'elementName': value.elementName,
        'profile': GetProfileDtoToJSON(value.profile),
        'name': value.name,
        'shortname': value.shortname,
        'nip': value.nip,
        'pesel': value.pesel,
        'addressEx': value.addressEx,
        'postcode': value.postcode,
        'place': value.place,
        'street': value.street,
        'country': GetCountryDtoToJSON(value.country),
        'numbers': value.numbers,
        'flat': value.flat,
        'createdDate': (value.createdDate.toISOString()),
        'applicationStatus': value.applicationStatus,
        'rejectedNote': value.rejectedNote,
        'active': value.active,
    };
}

