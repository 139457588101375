import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

import FormRow from '../../../app/components/FormRow';
import { GetEstimateByMonthDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  ProjectEstimatesStatusEnum,
  ProjectEstimatesStatusObj,
} from '../../../common/pokCore/validation/enums';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface EstimateByMonthStatusProps {
  estimateByMonth?: GetEstimateByMonthDto;
  updateStatus: (status?: ProjectEstimatesStatusEnum) => void;
  isLoadingStatus: boolean;
}

export const EstimateByMonthStatus: React.FC<
  EstimateByMonthStatusProps
> = props => {
  const [status, setStatus] = React.useState<ProjectEstimatesStatusEnum>();
  const { t, tk } = useTranslation('projectLng');

  let statusOptions = Object.entries(ProjectEstimatesStatusEnum).map(
    ([key, value]) => ({
      value: key as ProjectEstimatesStatusEnum,
      label: t(ProjectEstimatesStatusObj[value]),
    }),
  );
  statusOptions = statusOptions.filter(
    ({ value }) =>
      value !== ProjectEstimatesStatusEnum.Settled &&
      value !== ProjectEstimatesStatusEnum.Invoiced &&
      value !== ProjectEstimatesStatusEnum.Corrected,
  );

  React.useEffect(() => {
    setStatus(props.estimateByMonth?.status as ProjectEstimatesStatusEnum);
  }, [props.estimateByMonth?.status]);

  return (
    <Row className="mt-5 mb-5">
      <Col className="col-lg-6 col-12 mb-1">
        <Form className="d-grid gap-4 mb-3">
          <FormRow controlId="Status" label={t(tk.common.status)}>
            <Select
              value={
                status
                  ? {
                      value: status,
                      label: t(ProjectEstimatesStatusObj[status]),
                    }
                  : null
              }
              options={statusOptions}
              onChange={option => setStatus(option?.value)}
              placeholder={t(tk.component.pointOut)}
            />
          </FormRow>
        </Form>
        <div className="d-flex justify-content-end">
          <ConfirmationButton
            className="mx-1"
            confirmation={t(tk.estimates.changeStatus)}
            variant="primary"
            onOK={() => props.updateStatus(status)}
            isLoading={props.isLoadingStatus}
            disabled={!statusOptions.some(({ value }) => value === status)}
          >
            {t(tk.estimates.saveStatus)}
          </ConfirmationButton>
        </div>
      </Col>
    </Row>
  );
};
