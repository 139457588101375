import React from 'react';
import { Form } from 'react-bootstrap';
import type { MultiValue } from 'react-select';

import { CreateUpdateProfileCategoryDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import ProfileSelector from '../Selects/ProfileSelector';
import { Option } from '../Selects/Selector';
import { useTranslation } from '../../../common/hooks/useTranslation';

const getOptionValue = (
  options: Option | MultiValue<Option> | null | undefined,
) => {
  return options ? (options as Option).value : undefined;
};

interface ProfileEditorCategoryFormProps {
  companyIds?: string[];
  profileCategory: CreateUpdateProfileCategoryDto;
  propertyChange: (obj: Partial<CreateUpdateProfileCategoryDto>) => void;
  readOnly?: boolean;
}

export const ProfileCategoryEditorForm: React.FC<
  ProfileEditorCategoryFormProps
> = props => {
  const { t, tk } = useTranslation('administrationLng');

  return (
    <Form className="d-grid gap-4 mb-3">
      <FormRow controlId="name" label={t(tk.common.name)}>
        <Form.Control
          type="text"
          value={props.profileCategory.name || ''}
          onChange={e => props.propertyChange({ name: e.target.value })}
        />
      </FormRow>
      <FormRow
        controlId="salesFinancialAccount"
        label={t(tk.administration.salesFinancialAccount)}
      >
        <Form.Control
          type="text"
          value={props.profileCategory.salesFinancialAccount || ''}
          onChange={e =>
            props.propertyChange({ salesFinancialAccount: e.target.value })
          }
        />
      </FormRow>
      <FormRow
        controlId="purchasesFinancialAccount"
        label={t(tk.administration.purchasesFinancialAccount)}
      >
        <Form.Control
          type="text"
          value={props.profileCategory.purchasesFinancialAccount || ''}
          onChange={e =>
            props.propertyChange({
              purchasesFinancialAccount: e.target.value,
            })
          }
        />
      </FormRow>
      <FormRow
        controlId="commissionFinancialAccount"
        label={t(tk.administration.commissionsFinancialAccount)}
      >
        <Form.Control
          type="text"
          value={props.profileCategory.commissionFinancialAccount || ''}
          onChange={e =>
            props.propertyChange({
              commissionFinancialAccount: e.target.value,
            })
          }
        />
      </FormRow>
      <FormRow controlId="profileId" label={t(tk.administration.profile)}>
        <ProfileSelector
          isMulti={false}
          value={props.profileCategory.profileId || undefined}
          companyIds={props.companyIds}
          onChange={options => {
            if (options) {
              props.propertyChange({
                profileId: getOptionValue(options),
              });
            }
          }}
          readOnly={props.readOnly}
        />
      </FormRow>
      <FormRow controlId="active" label={t(tk.common.activeHe)}>
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.profileCategory.active ?? true}
          onChange={e => props.propertyChange({ active: e.target.checked })}
        />
      </FormRow>
    </Form>
  );
};
