/*eslint complexity: ["error", 25]*/
import mathUtils from '../../../utils/mathUtils';
import { FilterColumn } from '../../types/FilterColumn';

import { GridRecord } from './GridDataTypes';
import { nvl } from './nvl';

export default (
  data: GridRecord[],
  size: number,
  pageNo: number,
  orderBy?: string,
  orderDirection?: string,
  filterText?: string,
  filterColumns?: FilterColumn,
) => {
  let pageData = data;

  if (orderBy) {
    const property = orderBy;
    const direction = nvl(orderDirection, 'ASC');
    pageData.sort((o1: GridRecord, o2: GridRecord) => {
      if (
        o1.orderByNumberValues &&
        o2.orderByNumberValues &&
        (o1.orderByNumberValues[property] ||
          o1.orderByNumberValues[property] === 0) &&
        (o2.orderByNumberValues[property] ||
          o2.orderByNumberValues[property] === 0)
      ) {
        const comparement =
          o1.orderByNumberValues[property] - o2.orderByNumberValues[property];
        return direction === 'ASC' ? comparement : -comparement;
      }
      const o1vp =
        (o1.orderByStringValues && o1.orderByStringValues[property]) ||
        o1.values[property]?.toLocaleString() ||
        '';
      const o2vp =
        (o2.orderByStringValues && o2.orderByStringValues[property]) ||
        o2.values[property]?.toLocaleString() ||
        '';

      const comparement = o1vp.localeCompare(o2vp);
      return direction === 'ASC' ? comparement : -comparement;
    });
  }

  if (filterText) {
    pageData = pageData.filter(record => {
      for (const p in record.values) {
        let val = record.values[p]?.toString();
        if (val === '[object Object]' && record.orderByStringValues) {
          val = record.orderByStringValues[p];
        }
        // escape special regular expression characters (like . * ?)
        const escaped = filterText
          .toLocaleLowerCase()
          .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        // treat % sign as a wildcard
        const pattern = new RegExp(`.*${escaped.replaceAll('%', '.*')}.*`);

        if (val && pattern.test(val.toLocaleLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }

  if (filterColumns && Object.entries(filterColumns)?.length > 0) {
    const filtersArray = Object.entries(filterColumns);
    pageData = pageData.filter(record => {
      for (const filterColumn of filtersArray) {
        const p = filterColumn[0];
        let val = record.values[p]?.toString();
        let valAsNumber = null;

        if (
          val === '[object Object]' &&
          record.orderByStringValues &&
          record.orderByStringValues[p]
        ) {
          val = record.orderByStringValues[p];
        }
        if (
          val === '[object Object]' &&
          record.orderByNumberValues &&
          record.orderByNumberValues[p]
        ) {
          val = record.orderByNumberValues[p].toString();
          valAsNumber = record.orderByNumberValues[p];
        }

        if (filterColumn && filterColumn[1]) {
          const escaped = filterColumn[1]
            ?.toLocaleLowerCase()
            .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

          let filterValueAsNumber = null;
          if (
            record.orderByNumberValues &&
            record.orderByNumberValues[p] &&
            val
          ) {
            val = val.replace('.', ',');
            filterValueAsNumber = mathUtils.parse(
              filterColumn[1].replace(',', '.'),
            );
          }
          const newVal = val?.replace(/\s/g, '')?.toLocaleLowerCase();
          const pattern = new RegExp(`.*${escaped.replaceAll('%', '.*')}.*`);

          if (
            valAsNumber &&
            filterValueAsNumber &&
            valAsNumber === filterValueAsNumber
          ) {
            return true;
          }
          if (
            !newVal ||
            !val ||
            (!pattern.test(newVal) && !pattern.test(val?.toLocaleLowerCase()))
          ) {
            return false;
          }
        }
      }
      return true;
    });
  }

  const startElement = size * (pageNo - 1);
  const endElement = Math.min(size * pageNo, pageData.length);

  return pageData.slice(startElement, endElement);
};
