import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  CreateUpdateExcelConfigPositionDto,
  GetPositionExtendedDtoPositionTypeEnum,
  GetProfileDto,
  ParametersSchemaDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import {
  newPosition,
  validate,
} from '../../../common/pokCore/contexts/ExcelConfigPositionContext';
import { PositionEditor } from '../ExcelConfig/PositionEditor';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface PositionMapFormProps {
  excelPositionName: string;
  profile: GetProfileDto;
  teams: string[];
  configId: string;
  handleUpdate: (
    positionName: string,
    positionType: GetPositionExtendedDtoPositionTypeEnum,
    positionId: string,
    parameters?: ParametersSchemaDto,
  ) => void;
}
export const PositionMapForm: React.FC<PositionMapFormProps> = props => {
  const pok = usePokCore();
  const { t, tk } = useTranslation('excelImportLng');
  const [show, setShow] = useState(false);

  const updatePositionMapping = async (
    positionCU: CreateUpdateExcelConfigPositionDto,
  ) => {
    const status = await validate(positionCU);
    if (status.valid) {
      try {
        await pok.excelConfigPositions.create(positionCU);
        const position = await pok.positions.getById(positionCU.positionId);
        let parameters = positionCU.parameters;
        if (!parameters) {
          parameters = (
            await pok.excelConfigPositions.getSimilarPositionWithParams(
              positionCU.positionId,
              props.configId,
            )
          )?.parameters;
        }
        setShow(false);
        props.handleUpdate(
          position.name,
          position.positionType,
          positionCU.positionId,
          parameters,
        );
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setShow(true);
  };

  return (
    <>
      [{t(tk.import.notFound)}]
      <OverlayTrigger
        overlay={<Tooltip>{t(tk.import.addPositionMapping)}</Tooltip>}
        trigger={['hover', 'hover']}
      >
        <Button className="py-0 btn-link btn-transparent" onClick={handleClick}>
          <Icon.PlusCircle size={18} className="icon-in-cell" />
        </Button>
      </OverlayTrigger>
      {show && (
        <PositionEditor
          onCompleted={updatePositionMapping}
          show={show}
          position={newPosition(props.configId, props.excelPositionName)}
          handleClose={handleClose}
          profile={props.profile}
          teamIds={props.teams}
          hideActiveChbx={true}
          showParameters={true}
        />
      )}
    </>
  );
};
