import React, { useState, useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import type { MultiValue } from 'react-select';

import { GetDictionaryDto as Dictionary } from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Option, Selector } from '../Selects/Selector';

export interface DictionaryMultiSelectorProps {
  dictionary: string;
  clearable?: boolean;
  readOnly?: boolean;
  value?: string | string[];
  className?: string;
  onChange?: (options: Option | MultiValue<Option> | null | undefined) => void;
}

export default (props: DictionaryMultiSelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [options, setOptions] = useState<Dictionary[]>([]);
  const [language, setLanguage] = useState<string>();

  useEffect(() => {
    setLanguage(pok.getLanguage());
  }, [pok]);

  useEffect(() => {
    let mounted = true;
    pok.dictionaries
      .getByDictionaryType(props.dictionary)
      .then(values => {
        if (mounted) {
          const optionParentIds = values.map(({ parentId }) => parentId);
          setOptions(values.filter(({ id }) => !optionParentIds.includes(id)));
        }
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });

    return () => {
      mounted = false;
    };
  }, [pok.dictionaries, notifications, props.dictionary, language]);

  const provider = useMemo(
    () => async (text: string) => {
      const values =
        options?.filter(
          v =>
            v.shortname
              ?.toLocaleLowerCase()
              .includes(text.toLocaleLowerCase()) ||
            (language === 'PL'
              ? v.value?.toLocaleLowerCase().includes(text.toLocaleLowerCase())
              : v.valueEn
                  ?.toLocaleLowerCase()
                  .includes(text.toLocaleLowerCase())),
        ) || [];

      return values.map(v => ({
        label: language === 'PL' ? v.value : v.valueEn || '',
        value: v.id,
        badge: v.shortname,
      }));
    },
    [options, language],
  );

  if (!options.length) {
    return <Skeleton />;
  }

  return (
    <Selector
      key={language + JSON.stringify(options)}
      className={props.className}
      isMulti
      readOnly={props.readOnly}
      creatable={false}
      provider={provider}
      value={props.value}
      onChange={props.onChange}
      clearable={props.clearable}
    />
  );
};
