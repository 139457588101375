export default {
  menu: {
    menu: 'Menu',
    applications: 'Wnioski',
    specialInvoicingApplication: 'Wnioski o niestandardowe fakturowanie',
    purchaseInvoicesToBeClarified: 'Faktury zakupu do wyjaśnienia',
    purchaseInvoicesTv: 'Faktury TV',
    estimateItemPurchaseDeficiencies: 'Wyjaśnienia braków FVZ',
    orders: 'Zamówienia',
    ordersExecutions: 'Realizacje zamówień',
    finance: 'Finanse',
    salesInvoices: 'Faktury sprzedaży',
    salesInvoicesReadyToCorrection: 'Faktury sprzedaży do skorygowania',
    purchaseInvoices: 'Faktury zakupu',
    verifications: 'Weryfikacja',
    imports: 'Importy',
    specialInvoicingDecision: 'Wnioski o niestandardowe fakturowanie',
    blockade: 'Blokady',
    exportFVZToXml: 'Eksport FVZ do XML',
    exportFVSToXml: 'Eksport FVS do XML',
    exportFVSToSymphony: 'Eksport FVS do Symfonii Handel',
    clientsWithSymphony: 'Kontrahenci - Symfonia',
    reportsFinance: 'Zestawienia finanse',
    reportsFinanceAdministration: 'Finanse - administracja',
    bankAccounts: 'Konta bankowe',
    clients: 'Kontrahenci',
    clientsList: 'Lista kontrahentów',
    purchasers: 'Zamawiający',
    positions: 'Pozycje profili',
    budgets: 'Budżety',
    brands: 'Brandy',
    administrations: 'Administracja',
    companies: 'Spółki',
    employees: 'Pracownicy',
    teams: 'Zespoły',
    profiles: 'Profile mediaplanów',
    profileCategories: 'Kategorie profili',
    dictionaries: 'Słowniki',
    calendar: 'Kalendarz',
    roles: 'Role',
    employeesRoles: 'Uprawnienia',
    emailConfigs: 'Konfiguracja maili',
    jobConfig: 'Konfiguracja zadań cyklicznych',
    excelConfig: 'Konfiguracja importu',
    reports: 'Zestawienia',
    help: 'Pomoc',
    instructions: 'Instrukcje obsługi',
    procedures: 'Procedury',
    documentation: 'Dokumentacja',
    logout: 'Wyloguj',
  },
  docs: {
    addingPurchaseInvoices: 'Dodawanie i akceptacja faktur zakupu',
    settlementWithholdingTax: 'Rozliczanie podatku u źródła',
    orderFulfillment: 'Realizacja zamówienia (specjaliści)',
    acceptanceInvoices: 'Akceptacja faktur',
  },
  system: {
    noPermissions: 'Brak wymaganych uprawnień',
    unknownError: 'Nieznany błąd',
    cannotBePerformed: 'Nie można wykonać tej operacji',
    increaseRights:
      'W celu podwyższenia uprawnień prosimy o kontakt z administracją.',
    badCompany: 'Niezgodna spółka',
    badCompanyLongText:
      'Spółka, w której obecnie pracujesz, nie może obsługiwać wybranego obiektu.',
    rightCompany: 'Obiekt jest obsługiwany przez spółkę:',
    waitingForConnection: 'Czekamy na przywrócenie połączenia...',
    problemsWithConnection:
      '⛈️ Kłopoty z połączeniem - dane mogą nie zostać zapisane!',
    connectionRestored: '☀️ Połączenie przywrócone',
    cannotLoad: 'Strona nie może być załadowana',
    writingError: 'Błąd przy zapisie.',
    savedChanges: 'Zmiany zostały zapisane.',
    copyClipboardError: 'Coś poszło nie tak przy kopiowaniu tekstu do schowka.',
    copyClipboard: 'Kopiuj do schowka',
    ctrlV: 'Skopiowano do schowka. Wklej skrótem Ctrl+V.',
    serverNotResponse: 'Serwer nie odpowiada... ',
    login: 'LOGOWANIE',
    loginError: 'Błąd podczas logowania: ',
  },
} as const;
