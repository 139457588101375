import React from 'react';
import type { TFunction } from 'i18next';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetBlockadeMonthDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import { TKeys, useTranslation } from '../../../common/hooks/useTranslation';

interface BlockedMonthSearchProps {
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  isNavView?: boolean;
  showFilter?: boolean;
  emptyGridMessage?: string;
}

export const blockadeMonthForGrid = (
  t: TFunction<'projectLng', 'projectLng'>,
  tk: TKeys<'projectLng'>,
  blockadeMonth: GetBlockadeMonthDto,
) => {
  return {
    key: blockadeMonth.id,
    values: {
      sale_yes_no: blockadeMonth.isSalesBlocked ? t(tk.common.yes) : '',
      purchase_yes_no: blockadeMonth.isPurchaseBlocked ? t(tk.common.yes) : '',
      'company.shortname': blockadeMonth.company?.shortname,
      'creator.name': blockadeMonth.creator?.name,
      'blockadeMonth.date': momencik(blockadeMonth.date, 'YYYY-MM'),
      'team.name': blockadeMonth.blockadeMonthTeams
        ?.map(o => o?.team?.name || '')
        .join(', '),
    },
    isInactive: !blockadeMonth.active,
  };
};

export const BlockadeMonthSearch: React.FC<BlockedMonthSearchProps> = props => {
  const { t, tk } = useTranslation('projectLng');
  const columns: GridColumn[] = [
    { header: t(tk.common.company), property: 'company.shortname' },
    { header: t(tk.common.teams), property: 'team.name' },
    { header: t(tk.common.month), property: 'blockadeMonth.date' },
    { header: t(tk.projects.salesBlockade), property: 'sale_yes_no' },
    { header: t(tk.projects.purchaseBlockade), property: 'purchase_yes_no' },
    { header: t(tk.common.creator), property: 'creator.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      refresh={props.refresh}
      initialOrderColumn="blockadeMonth.date"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={props.emptyGridMessage || t(tk.projects.noBlockades)}
      onRowClick={props.onRowClick}
      withHistory={true}
      showFilter={props.showFilter}
      withFilterInColumns={true}
    />
  );
};
