import React, { useMemo, useState } from 'react';
import { Button, Card, Col, Form, Row, Tab } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { ProjectSearch } from '../../components/Projects/ProjectSearch';
import { useNavigation } from '../../../common/navigation';
import { useTranslation } from '../../../common/hooks/useTranslation';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { TabsHistory } from '../../../app/components/TabsHistory';
import FormRow from '../../../app/components/FormRow';
import { NewDateRangePicker } from '../../../app/components/NewDateRangePicker';

export const ProjectsReadyToInvoiceView: React.FC = () => {
  const { t, tk } = useTranslation('fvzLng');
  const project = useTranslation('projectLng');
  const pok = usePokCore();
  const nav = useNavigation();

  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
  );
  const [dateTo, setDateTo] = useState<Date | undefined>(new Date());
  const [rangeDate, setRangeDate] = useState<[Date | null, Date | null] | null>(
    dateFrom && dateTo ? [dateFrom, dateTo] : null,
  );

  const handleSearch = () => {
    setDateFrom(rangeDate && rangeDate[0] ? rangeDate[0] : undefined);
    setDateTo(rangeDate && rangeDate[1] ? rangeDate[1] : undefined);
  };

  const getDataReadyToInvoices = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.projects.getAllReadyToInvoiceByPortion(
          project.t,
          project.tk,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          undefined,
          undefined,
          undefined,
          undefined,
          false,
          dateFrom,
          dateTo,
        )) as GridGetterFunction,
    [pok.projects, dateFrom, dateTo, project.t, project.tk],
  );

  const getCountReadyToInvoices = useMemo(
    () =>
      (filterText =>
        pok.projects.getAllReadyToInvoiceCount(
          filterText,
          undefined,
          undefined,
          undefined,
          false,
          dateFrom,
          dateTo,
        )) as GridCountFunction,
    [pok.projects, dateFrom, dateTo],
  );

  const getDataCorrected = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.projects.getAllCorrectedByPortion(
          project.t,
          project.tk,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          dateFrom,
          dateTo,
        )) as GridGetterFunction,
    [pok.projects, dateFrom, dateTo, project.t, project.tk],
  );

  const getCountCorrected = useMemo(
    () =>
      (filterText =>
        pok.projects.getAllCorrectedCount(
          filterText,
          dateFrom,
          dateTo,
        )) as GridCountFunction,
    [pok.projects, dateFrom, dateTo],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.prLang.readyForInvoicing)}
        </Card.Title>
        <Form className="d-grid gap-4 mb-3">
          <FormRow controlId="month" label={t(tk.prLang.monthRangeEstimates)}>
            <div className="small-calendar">
              <NewDateRangePicker
                onChange={value => {
                  setRangeDate(
                    value as unknown as [Date | null, Date | null] | null,
                  );
                }}
                value={rangeDate}
              />
            </div>
          </FormRow>
          <Row>
            <Col className="col-3"></Col>
            <Col className="col-9">
              <Button variant="primary" className="px-5" onClick={handleSearch}>
                {t(tk.button.find)}
              </Button>
            </Col>
          </Row>
        </Form>
        <TabsHistory
          activeKey="readyForInvoices"
          id="readyForInvoices"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey="readyForInvoices"
            title={t(tk.finance.readyForInvoices)}
            className="m-3"
          >
            <ProjectSearch
              getData={getDataReadyToInvoices}
              getCount={getCountReadyToInvoices}
              onRowClick={id => nav.salesInvoiceEditor({ projectId: id })}
              showTotalDifferenceColumn
              totalDifferenceLabel={t(tk.finance.accountToInvoice)}
              orderBy="estimate_status_ts"
              orderDirection="ASC"
            />
          </Tab>
          <Tab
            eventKey="corrected"
            title={t(tk.finance.corrected)}
            className="m-3"
          >
            <ProjectSearch
              getData={getDataCorrected}
              getCount={getCountCorrected}
              onRowClick={id => nav.salesInvoiceEditor({ projectId: id })}
              showTotalDifferenceColumn
              totalDifferenceLabel={t(tk.finance.accountToInvoice)}
              orderBy="estimate_status_ts"
              orderDirection="ASC"
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
