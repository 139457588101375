import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import {
  GetExcelAndPokDataDto,
  GetExcelAndPokRowDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ExcelCompareGrid } from './ExcelCompareGrid';

interface PreviewImportDataProps {
  data: GetExcelAndPokDataDto;
  handleImportExcel: (data: GetExcelAndPokDataDto) => void;
}

export const PreviewImportData: React.FC<PreviewImportDataProps> = props => {
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [data, setData] = useState<GetExcelAndPokDataDto>(props.data);
  const [refresh, setRefresh] = useState(false);

  const { t, tk } = useTranslation('excelImportLng');

  const handleOnRowClick = (key: string) => {
    let sel = [];

    if (!selectedPositions.includes(key)) {
      sel = [...selectedPositions, key];
    } else {
      sel = selectedPositions.filter(o => o !== key);
    }
    setSelectedPositions(sel);
  };

  const importSelected = () => {
    if (selectedPositions.length === 0) {
      setSaveErrors([t(tk.import.noPositionsSelected)]);
      setShowSaveErrors(true);
      return;
    }
    data.excelRows = data.excelRows.filter(p =>
      selectedPositions.includes(p.rowId),
    );
    props.handleImportExcel(data);
    setSelectedPositions([]);
    setSaveErrors([]);
  };

  const selectAll = () => {
    setSelectedPositions(data.excelRows.map(p => p.rowId));
  };

  const deselectAll = () => {
    setSelectedPositions([]);
  };

  const updateData = (obj: Partial<GetExcelAndPokRowDto>) => {
    if (obj.rowId) {
      const updatedData = data.excelRows.map(r => {
        if (r.rowId === obj.rowId) {
          return { ...r, ...obj };
        }
        return r;
      });
      data.excelRows = updatedData;
      setData(data);
      setRefresh(!refresh);
    }
  };

  return (
    <>
      <Card.Title className="pb-3">{t(tk.import.positions)}</Card.Title>

      <div className="side-by-side">
        <div className="left">
          <p>
            {t(tk.import.positionsToUpdate)}: <b>{data.updateCount}</b>
            <br />
            {t(tk.import.newPositions)}: <b>{data.newCount}</b>
            <br />
          </p>
        </div>
        <div className="right">
          <p>
            {t(tk.import.fileName)}: <b>{data.fileName}</b> <br />
            {t(tk.import.configName)}: <b>{data.config.name}</b> <br />
            {data.projectName && (
              <>
                {t(tk.prLang.project)}: <b>{data.projectName}</b> <br />
              </>
            )}
            {data.projectMonth && (
              <>
                {t(tk.common.month)}: <b>{data.projectMonth}</b>
              </>
            )}
          </p>
        </div>
      </div>
      <div className="clone-count">
        {t(tk.import.selected)} <b>{selectedPositions.length}</b>{' '}
        {selectedPositions.length === 1
          ? t(tk.import.positionAccusative)
          : selectedPositions.length > 1 && selectedPositions.length < 5
            ? t(tk.import.positionsAccusative)
            : t(tk.import.positionsGenitive)}
      </div>
      <ExcelCompareGrid
        data={data.excelRows || []}
        columns={data?.columns || []}
        config={props.data?.config}
        selectedKeys={selectedPositions}
        refresh={refresh}
        onRowClick={handleOnRowClick}
        updateData={updateData}
      />
      <section className="side-by-side">
        {selectedPositions.length !== data.excelRows.length ? (
          <Button
            variant="outline-secondary"
            onClick={selectAll}
            className="mt-1"
          >
            {t(tk.component.selectAll)}
            <Icon.CheckSquare className="icon mx-2 mb-1" />
          </Button>
        ) : (
          <Button
            variant="outline-secondary"
            onClick={deselectAll}
            className="mt-1"
          >
            {t(tk.component.deselectAll)}
            <Icon.Square className="icon mx-2 mb-1" />
          </Button>
        )}
        <div className="marginLeft">
          <ConfirmationButton
            onOK={() => importSelected()}
            confirmation={t(tk.import.askImportSelected)}
            className="mt-1"
          >
            {t(tk.import.importSelected)}
          </ConfirmationButton>
        </div>
      </section>
      <ValidationAlert
        show={showSaveErrors}
        errors={saveErrors}
        className="m-3"
      />
    </>
  );
};
