import React, { useEffect, useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import momencik from '../../../common/momencik';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetPackageInvoicesXMLDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useNavigation } from '../../../common/navigation';
import { SalesInvoiceSearch } from '../../components/SalesInvoices/SalesInvoiceSearch';
import { useTranslation } from '../../../common/hooks/useTranslation';

type ParamTypes = {
  id: string;
};

export const PackageFVSView: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const pok = usePokCore();
  const nav = useNavigation();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('fvsLng');

  const [packageFVS, setPackageFVS] = useState<GetPackageInvoicesXMLDto>();

  useEffect(() => {
    if (id) {
      pok.packagesXML
        .getById(id)
        .then(packageFVZ => {
          setPackageFVS(packageFVZ);
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [notifications, pok.packagesXML, id]);

  const getData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.salesInvoices.getAllByPackageXML(
          t,
          tk,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          id,
        )) as GridGetterFunction,
    [pok.salesInvoices, t, tk, id],
  );

  const getCount = useMemo(
    () =>
      ((filterText: string) =>
        pok.salesInvoices.getCountByPackageXML(
          filterText,
          id,
        )) as GridCountFunction,
    [pok.salesInvoices, id],
  );

  const onRowClick = (salesInvoiceId: string) => {
    nav.salesInvoiceEditorNewWindow(salesInvoiceId, true);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.finance.invoicesInPackage, {
            number: packageFVS?.systemNumber,
            date: momencik(packageFVS?.creationTs, 'LLL'),
          })}
        </Card.Title>

        <SalesInvoiceSearch
          getData={getData}
          getCount={getCount}
          onRowClick={onRowClick}
        />
      </Card.Body>
    </Card>
  );
};
