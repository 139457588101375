import React from 'react';

import { FilterInvoiceTypeEnum } from '../../../common/pokCore/validation/enums';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { FilterColumn } from '../../../common/types/FilterColumn';

import { ClientWithoutSymphonySearch } from './ClientWithoutSymphonySearch';

interface ClientWithoutSymphonyWithInvoiceSearchProps {
  invoiceType: FilterInvoiceTypeEnum;
  onRowClick: (key: string) => Promise<void> | void | undefined;
}

export const ClientWithoutSymphonyWithInvoiceSearch: React.FC<
  ClientWithoutSymphonyWithInvoiceSearchProps
> = props => {
  const pok = usePokCore();
  return (
    <ClientWithoutSymphonySearch
      getData={(
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) => {
        return pok.clients.getAllWithoutSymphonyByPortion(
          props.invoiceType,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        );
      }}
      getCount={(filterText?: string, filterColumns?: FilterColumn) => {
        return pok.clients.getAllWithoutSymphonyCount(
          props.invoiceType,
          filterText,
          filterColumns,
        );
      }}
      onRowClick={props.onRowClick}
    />
  );
};
