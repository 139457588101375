import React from 'react';
import 'react-circular-progressbar/dist/styles.css';

import {
  GetEstimateItemDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { Grid } from '../../../common/components/Grid/Grid';
import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';

import {
  getItemsGridData,
  getSummary as getItemsGridSummary,
} from './EstimateItemsGridData';

interface EstimateItemsGridProps {
  data: GetEstimateItemDto[];
  schema?: ProfileSchemaDto;
}

export const EstimateItemsGrid: React.FC<EstimateItemsGridProps> = props => {
  const { t, tk } = useTranslation('projectLng');
  const pok = usePokCore();
  const lang = pok.getLanguage();
  const columns: GridColumn[] = [
    { header: t(tk.estimates.positionName), property: 'positionName' },
  ];

  for (const key in props.schema) {
    if (
      props.schema[key as keyof ProfileSchemaDto] === undefined ||
      props.schema[key as keyof ProfileSchemaDto]?.displayNamePl?.includes('%')
    ) {
      continue;
    }
    columns.push({
      header:
        (lang === 'PL'
          ? props.schema[key as keyof ProfileSchemaDto]?.displayNamePl
          : props.schema[key as keyof ProfileSchemaDto]?.displayNameEn) || '',
      property: key,
    });
  }

  columns.push(
    {
      header: t(tk.estimates.amountToInvoice),
      property: 'salesAmountToInvoice',
    },
    {
      header: '',
      property: 'salesAmountToInvoicePB',
      noFilter: true,
      noSorting: true,
    },
    {
      header: t(tk.estimates.amountToAccept),
      property: 'purchaseAmountToAccept',
    },
    {
      header: '',
      property: 'purchaseAmountToAcceptPB',
      noFilter: true,
      noSorting: true,
    },
  );

  return (
    <Grid
      data={getItemsGridData(props.data, t, tk, pok.getLanguage())}
      columns={columns}
      showFilter={false}
      pageSize={20}
      emptyGridMessage={t(tk.estimates.noPositions)}
      summaryData={getItemsGridSummary(props.data, t, tk)}
      trClassName="amounts-align-right font-90"
      withFilterInColumns={true}
    />
  );
};
