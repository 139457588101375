import React from 'react';
import { Form } from 'react-bootstrap';

import momencik from '../../common/momencik';

type NewDatePickerProps = React.PropsWithChildren<{
  value?: Date | null | undefined;
  dateChanged?: (date: Date) => void;
  readOnly?: boolean;
  min_date?: string;
}>;

export const NewDatePicker = (props: NewDatePickerProps) => {
  return (
    <Form.Control
      type="date"
      name="startDatePic"
      placeholder="DateRange"
      value={momencik(props.value, 'YYYY-MM-DD')}
      onChange={e => {
        //const now = new Date();
        const isoDate = new Date(e.target.value);

        // const isoDate = new Date(
        //   e.target.value +
        //     `T${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`,
        // );

        return props.dateChanged && props.dateChanged(isoDate);
      }}
      disabled={props.readOnly}
      min={props.min_date}
    />
  );
};
