import React, { useRef } from 'react';
import { Alert, Form } from 'react-bootstrap';
import Select from 'react-select';

import {
  CreateUpdateSalesInvoiceDto,
  GetCompanyDto,
  GetPurchaserDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import {
  InvoiceStatusEnum,
  InvoiceStatusObj,
} from '../../../common/pokCore/validation/enums';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import momencik from '../../../common/momencik';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { SalesInvoiceMissingSymphonyCodeAlert } from './SalesInvoiceMissingSymphonyCodeAlert';

interface SalesInvoiceBasicDataEditorProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdateSalesInvoiceDto>) => void;
  readOnly?: boolean;
  isOnePositionEnabled: boolean;
  purchaser?: GetPurchaserDto;
  company?: GetCompanyDto;
}

const availableStatusesToEditByUser = [
  InvoiceStatusEnum.Draft,
  InvoiceStatusEnum.Generated,
];

const isOnePositionAlertVisible = ({
  isOnePositionEnabled,
  salesInvoice,
}: SalesInvoiceBasicDataEditorProps) =>
  !isOnePositionEnabled && salesInvoice.isOnePositionOnInvoice;

const isInvoiceDateAlertVisible = ({
  salesInvoice,
  readOnly,
}: SalesInvoiceBasicDataEditorProps) =>
  salesInvoice.status === InvoiceStatusEnum.Generated &&
  !readOnly &&
  momencik(salesInvoice.invoiceDate) !== momencik(new Date());

const isMissingSymphonyCodeAlertVisible = ({
  salesInvoice,
}: SalesInvoiceBasicDataEditorProps) =>
  salesInvoice.status !== InvoiceStatusEnum.Sent;

export const SalesInvoiceSummaryEditor: React.FC<
  SalesInvoiceBasicDataEditorProps
> = props => {
  const { t, tk } = useTranslation('fvsLng');
  const { salesInvoice, propertyChange, readOnly } = props;
  const onePositionNameRef = useRef<HTMLTextAreaElement>(null);

  const statusOptions = Object.entries(InvoiceStatusEnum).map(
    ([key, value]) => ({
      value: key as InvoiceStatusEnum,
      label: t(InvoiceStatusObj[value]),
    }),
  );

  return (
    <>
      <FormRow controlId="Status" label={t(tk.common.status)}>
        <Select
          value={
            salesInvoice.status
              ? statusOptions.find(({ value }) => salesInvoice.status === value)
              : null
          }
          options={statusOptions.filter(({ value }) =>
            availableStatusesToEditByUser.includes(value),
          )}
          onChange={option =>
            propertyChange({
              status: option?.value,
            })
          }
          isDisabled={readOnly}
        />
      </FormRow>
      {salesInvoice.parentId && (
        <FormRow
          controlId="correctionReason"
          label={t(tk.finance.correctionReason)}
        >
          <DynamicTextarea
            value={salesInvoice.correctionReason || ''}
            onChange={e => propertyChange({ correctionReason: e.target.value })}
            disabled={readOnly}
          />
        </FormRow>
      )}
      <FormRow
        controlId="isOnePositionOnInvoice"
        label={t(tk.finance.onePositionOnInvoice)}
      >
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={salesInvoice.isOnePositionOnInvoice ?? false}
          disabled={readOnly}
          onChange={e => {
            const { checked } = e.target;
            propertyChange({
              isOnePositionOnInvoice: checked,
              onePositionName: checked ? salesInvoice.onePositionName : null!,
            });

            if (checked) {
              setTimeout(() => {
                if (onePositionNameRef.current) {
                  onePositionNameRef.current?.focus();
                }
              }, 1);
            }
          }}
        />
      </FormRow>
      {salesInvoice.isOnePositionOnInvoice && (
        <FormRow controlId="onePositionName" label={t(tk.common.positionName)}>
          <DynamicTextarea
            ref={onePositionNameRef}
            value={salesInvoice.onePositionName || ''}
            onChange={e => propertyChange({ onePositionName: e.target.value })}
            disabled={readOnly}
          />
        </FormRow>
      )}
      <FormRow controlId="textOverItems" label={t(tk.finance.textOverItems)}>
        <DynamicTextarea
          value={salesInvoice.textOverItems || ''}
          onChange={e => propertyChange({ textOverItems: e.target.value })}
          disabled={readOnly}
        />
      </FormRow>
      {isInvoiceDateAlertVisible(props) && (
        <Alert variant="warning">
          <p className="m-0">
            {t(tk.finance.documentApproved)}{' '}
            {momencik(salesInvoice.invoiceDate, 'DD.MM.YYYY')}{' '}
            {t(tk.finance.andSalesDate)}{' '}
            {momencik(salesInvoice.saleDate, 'DD.MM.YYYY')}.
          </p>
        </Alert>
      )}
      {isOnePositionAlertVisible(props) && (
        <Alert variant="warning">
          <p className="m-0">
            {t(tk.finance.diffVatRates)}{' '}
            <b>{t(tk.finance.onePositionOnInvoice)}</b>{' '}
            {t(tk.finance.orSetSameVatRates)}
          </p>
        </Alert>
      )}
      {isMissingSymphonyCodeAlertVisible(props) && (
        <SalesInvoiceMissingSymphonyCodeAlert
          purchaser={props.purchaser}
          company={props.company}
        />
      )}
    </>
  );
};
