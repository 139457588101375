import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetEmployeeRoleDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface EmployeeRoleSearchProps {
  data?: GetEmployeeRoleDto[];
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  withMerging?: boolean;
  clientHeadId?: string;
  refresh?: boolean;
}

export const employeeRoleForGrid = (employeeRole: GetEmployeeRoleDto) => {
  return {
    key: employeeRole.id,
    values: {
      'employee.name': `${employeeRole?.user.name} (${employeeRole?.user?.supervisor} - OneTool: ${employeeRole?.user.oneToolId})`,
      'role.name': employeeRole?.role?.name || '',
      'company.shortname': employeeRole?.company?.shortname || '',
      'team.name': employeeRole?.team?.name || '',
      'budget.name': employeeRole?.budget?.name || '',
    },
    orderByStringValues: {
      name: employeeRole?.user.name,
    },
  };
};

export const EmployeeRoleSearch: React.FC<EmployeeRoleSearchProps> = props => {
  const { t, tk } = useTranslation('roleLng');

  const columns: GridColumn[] = [
    { header: t(tk.common.user), property: 'employee.name' },
    ...(!props.data
      ? [{ header: t(tk.role.role), property: 'role.name' }]
      : []),
    { header: t(tk.common.company), property: 'company.shortname' },
    { header: t(tk.common.team), property: 'team.name' },
    { header: t(tk.common.budget), property: 'budget.name' },
  ];

  const data =
    props.data &&
    props.data.map(employeeRole => {
      return {
        key: employeeRole.id,
        values: {
          'employee.name': `${employeeRole?.user.name} (${employeeRole?.user?.supervisor} - OneTool: ${employeeRole?.user.oneToolId})`,
          'role.name': employeeRole?.role.name || '',
          'company.shortname': employeeRole?.company?.shortname || '',
          'team.name': employeeRole?.team?.name || '',
          'budget.name': employeeRole?.budget?.name || '',
        },
        orderByStringValues: {
          name: employeeRole?.user.name,
        },
      };
    });

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      data={data}
      columns={columns}
      showFilter={true}
      initialOrderColumn="employee.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.permission.noEmployeeRoles)}
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
      withFilterInColumns={true}
    />
  );
};
