import React from 'react';
import { Card } from 'react-bootstrap';

import { PurchaserTab } from '../../components/Purchasers/PurchaserTab';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const AllPurchasersView: React.FC = () => {
  const { t, tk } = useTranslation('commonLng');
  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">{t(tk.common.purchaser)}</Card.Title>
        <PurchaserTab />
      </Card.Body>
    </Card>
  );
};
