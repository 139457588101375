import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import type { MultiValue } from 'react-select';

import {
  GetEmployeeDto,
  GetTeamDto,
} from '../../../common/pokCore/autogenerated/pokApiClient/models';
import FormRow from '../../../app/components/FormRow';
import { Option } from '../Selects/Selector';
import TeamSelector from '../Selects/TeamSelector';
import EmployeeSelector from '../Selects/EmployeeSelector';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { MailTeamParams } from './MailTeamModal';

interface MailTeamFormProps {
  emailParams: MailTeamParams;
  setEmailParams: (params: MailTeamParams) => void;
  emails: string[];
  setEmails: (emails: string[]) => void;
  tv: boolean;
}

export const MailTeamForm: React.FC<MailTeamFormProps> = ({
  emailParams,
  setEmailParams,
  emails,
  setEmails,
  tv,
}) => {
  const pok = usePokCore();
  const { t, tk } = useTranslation('commonLng');
  const [fetchedEmployees, setFetchedEmployees] = useState<GetEmployeeDto[]>(
    [],
  );
  const [fetchedTeams, setFetchedTeams] = useState<GetTeamDto[]>([]);

  const onChangeEmployees = (
    option: Option | MultiValue<Option> | null | undefined,
  ) => {
    if (option && Array.isArray(option)) {
      setEmailParams?.({
        ...emailParams,
        employeesIds: (option as Option[]).map(({ value }) => value),
      });
    }
  };

  const generateEmailsList = (): string[] => {
    const teamEmail = fetchedTeams.find(
      team => team.id === emailParams.teamId,
    )?.email;

    const employeesEmails = emailParams.employeesIds?.map(employee => {
      return fetchedEmployees.find(fE => fE.id === employee)?.email;
    });

    const emailsList = emailParams.addTeamToEmails
      ? [teamEmail, ...employeesEmails]
      : employeesEmails;

    return emailsList.filter(value => !!value) as string[];
  };

  useEffect(() => {
    setEmails?.(generateEmailsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedTeams,
    emailParams.teamId,
    fetchedEmployees,
    emailParams.employeesIds,
    emailParams.addTeamToEmails,
  ]);

  const handleSetFetchedEmployees = (options: GetEmployeeDto[]) =>
    setFetchedEmployees(options);

  const handleSetFetchedTeams = (options: GetTeamDto[]) =>
    setFetchedTeams(options);

  return (
    <Form className="d-grid gap-4 mb-3">
      <FormRow controlId="team" label={t(tk.common.team)}>
        <TeamSelector
          value={emailParams.teamId}
          companyId={pok.getCompanyId()}
          setOptions={handleSetFetchedTeams}
          onChange={option => {
            const newOption = option as Option;
            setEmailParams({
              ...emailParams,
              teamId: newOption?.value || undefined,
            });
          }}
          clearable={true}
          tv={tv}
        />
      </FormRow>
      <FormRow controlId="employees" label={t(tk.email.recipients)}>
        <EmployeeSelector
          value={emailParams.employeesIds}
          isMulti
          fetchForOrders
          setOptions={handleSetFetchedEmployees}
          onChange={onChangeEmployees}
        />
      </FormRow>
      <FormRow controlId="sendEmail" label={t(tk.email.sendToTeam)}>
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={emailParams.addTeamToEmails}
          onChange={e => {
            const { checked } = e.target;
            setEmailParams({
              ...emailParams,
              addTeamToEmails: checked,
            });
          }}
        />
      </FormRow>
      <FormRow controlId="emails" label={t(tk.common.emails)}>
        <DynamicTextarea value={emails?.join(', ')} disabled />
      </FormRow>
      <FormRow controlId="message" label={t(tk.email.message)}>
        <DynamicTextarea
          minRows={3}
          value={emailParams.message || ''}
          onChange={e =>
            setEmailParams({
              ...emailParams,
              message: e.target.value || '',
            })
          }
        />
      </FormRow>
    </Form>
  );
};
