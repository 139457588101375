import type { TFunction } from 'i18next';

import { bankAccountForGrid } from '../../../pok/components/BankAccounts/BankAccountSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { FilterColumn } from '../../types/FilterColumn';
import { DefaultApi, GetBankAccountDto } from '../autogenerated/pokApiClient';
import {
  CreateUpdateBankAccountDto,
  CreateUpdateBankAccountDtoFromJSON,
} from '../autogenerated/pokApiClient';
import { GetLastEditorDto } from '../autogenerated/pokApiClient';
import { BankAccountSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface BankAccountContextInterface {
  getById: (id: string) => Promise<GetBankAccountDto>;
  getAllByPortion: (
    t: TFunction<'administrationLng', 'administrationLng'>,
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    filterColumns?: FilterColumn,
  ) => Promise<number>;
  update: (id: string, dto: CreateUpdateBankAccountDto) => Promise<void>;
  create: (dto: CreateUpdateBankAccountDto) => Promise<GetBankAccountDto>;
  getLastEditor: (id: string) => Promise<GetLastEditorDto>;
}

export const BankAccountContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.bankAccountControllerGet(id),
    getAllByPortion: async (
      t: TFunction<'administrationLng', 'administrationLng'>,
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterColumns?: FilterColumn,
    ) => {
      const data = await api.bankAccountControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        filterColumns,
      );

      return data.map(item => bankAccountForGrid(t, item));
    },
    getAllCount: (filterText?: string, filterColumns?: FilterColumn) =>
      api.bankAccountControllerGetAllCount(filterText || '', filterColumns),
    update: (id: string, dto: CreateUpdateBankAccountDto) =>
      api.bankAccountControllerUpdate(id, dto),
    create: (dto: CreateUpdateBankAccountDto) =>
      api.bankAccountControllerCreate(dto),
    getLastEditor: (id: string) => api.bankAccountControllerGetLastEditor(id),
  };
};

export const newBankAccount = () => {
  return CreateUpdateBankAccountDtoFromJSON({ active: true });
};

export const validate = (bankAccount: CreateUpdateBankAccountDto) =>
  validateAgainst(BankAccountSchema, bankAccount);

export const convert = (bankAccount?: GetBankAccountDto) => {
  if (!bankAccount) {
    return newBankAccount();
  }
  const converted = CreateUpdateBankAccountDtoFromJSON(bankAccount);
  converted.companyId = bankAccount.company.id;
  converted.clientId = bankAccount.client?.id;

  return converted;
};
