import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { maxBy, minBy } from 'lodash';

import { GetEstimateItemDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { NewDateRangePicker } from '../../../app/components/NewDateRangePicker';

export type RangeFilter = [Date | null, Date | null];

interface InvoiceEstimateItemsFiltersProps {
  estimateItems: GetEstimateItemDto[];
  dateFilter: RangeFilter;
  setDateFilter: Dispatch<SetStateAction<RangeFilter>>;
  additionalFilters?: React.ReactNode;
  disableMinMaxBorders?: boolean;
}

export const InvoiceEstimateItemsFilters: React.FC<
  InvoiceEstimateItemsFiltersProps
> = props => {
  const { t, tk } = useTranslation('projectLng');
  const minDate = useMemo(
    () =>
      minBy(props.estimateItems, item => item.estimateByMonth?.date)
        ?.estimateByMonth.date,
    [props.estimateItems],
  );
  const maxDate = useMemo(
    () =>
      maxBy(props.estimateItems, item => item.estimateByMonth?.date)
        ?.estimateByMonth.date,
    [props.estimateItems],
  );

  return (
    <>
      <Row className="p-2">
        <Form.Group as={Row} controlId="monthFilter">
          <Form.Label column sm="2">
            {t(tk.estimates.filterByMonth)}
          </Form.Label>
          <Col>
            <NewDateRangePicker
              value={props.dateFilter}
              onChange={value =>
                props.setDateFilter(
                  value ? (value as RangeFilter) : [null, null],
                )
              }
              minDate={props.disableMinMaxBorders ? undefined : minDate}
              maxDate={props.disableMinMaxBorders ? undefined : maxDate}
            />
          </Col>
        </Form.Group>
      </Row>
      {props.additionalFilters}
      <hr />
    </>
  );
};
