/*eslint complexity: ["error", 25]*/
import React, { ReactNode } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import type { TFunction } from 'i18next';

import { GetPurchaseInvoiceExtendedDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { DownloadAttachment } from '../Attachments/DownloadAttachment';
import ClientInfo from '../Clients/ClientInfo';
import {
  PurchaseInvoiceStatusEnum,
  PurchaseInvoiceStatusObj,
} from '../../../common/pokCore/validation/enums';
import { TKeys } from '../../../translations';

function buildPurchaseInvoiceTitle(
  purchaseInvoiceId: string,
  t: TFunction<'fvzLng', 'fvzLng'>,
  tk: TKeys<'fvzLng'>,
  purchaseInvoice?: GetPurchaseInvoiceExtendedDto,
  tv?: boolean,
): ReactNode {
  const inActive = purchaseInvoice && !purchaseInvoice.active;

  return (
    <>
      {inActive && <b>{t(tk.finance.archive)} </b>}
      <span style={inActive ? { textDecoration: 'line-through' } : {}}>
        {purchaseInvoiceId === 'utworz' ? (
          t(tk.finance.newFvz)
        ) : (
          <>
            <p>
              {t(tk.finance.fvz)} <b>{purchaseInvoice?.number}</b>
              {purchaseInvoice?.client?.name ? (
                <>
                  {' '}
                  {t(tk.finance.for)} <b>{purchaseInvoice.client.name}</b>
                  <ClientInfo
                    id={purchaseInvoice.client.id}
                    className="color-primary ms-2 mb-1"
                    iconSize={18}
                    placement="left"
                  />
                </>
              ) : null}
            </p>
            <p className="d-flex">
              {purchaseInvoice
                ? `${t(tk.common.sysNumber)} ${purchaseInvoice.systemNumber} `
                : null}
              {purchaseInvoice?.status ? (
                <>
                  [
                  <b>
                    {t(
                      PurchaseInvoiceStatusObj[purchaseInvoice?.status],
                    ).toUpperCase()}
                    {purchaseInvoice?.status ===
                      PurchaseInvoiceStatusEnum.Sent &&
                    purchaseInvoice?.packageFVZNumber
                      ? ` ${t(tk.finance.packageWithNumber)} ${purchaseInvoice?.packageFVZNumber}`
                      : ''}
                  </b>
                  ]
                  {purchaseInvoice?.status !== PurchaseInvoiceStatusEnum.Sent &&
                  purchaseInvoice?.packageFVZNumber ? (
                    <>
                      &nbsp;
                      <span className="text-red">
                        {t(tk.finance.revertFromSent)}
                      </span>
                    </>
                  ) : null}
                </>
              ) : null}
              {tv && (
                <OverlayTrigger
                  overlay={<Tooltip>{t(tk.finance.tvInvoice)}</Tooltip>}
                >
                  <Icon.DisplayFill size={20} className="ms-2 icon-blue" />
                </OverlayTrigger>
              )}
              {purchaseInvoice?.attachment ? (
                <DownloadAttachment
                  id={purchaseInvoice.attachment.id}
                  icon={Icon.FilePdf}
                  tooltipText={t(tk.finance.openNewWindow)}
                />
              ) : null}
            </p>
          </>
        )}
      </span>
    </>
  );
}

export default buildPurchaseInvoiceTitle;
