import {
  CreateUpdateInvoiceEstimateItemDto,
  CreateUpdateSalesInvoicePositionDto,
  GetEstimateItemDto,
  GetInvoiceEstimateItemDto,
  GetSalesInvoiceDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import mathUtils from '../../../utils/mathUtils';
import { getGrossPrice } from '../../../utils/getVatAmountByVat';
import { VatEnum } from '../../../common/pokCore/validation/enums';
import { calculateAmountByExchangeRate } from '../../../utils/calculateAmountByExchangeRate';

export const calculateEstimateItemSalesNet = (
  item: GetEstimateItemDto,
  salesInvoiceOriginalEstimateItems?: CreateUpdateInvoiceEstimateItemDto[],
) =>
  mathUtils.add(
    mathUtils.subtract(
      mathUtils.round(item.salesNetTotal),
      mathUtils.round(item.salesCommissionAmount),
      mathUtils.round(item.salesInvoicesSum),
    ),
    salesInvoiceOriginalEstimateItems?.find(
      ({ estimateItemId }) => estimateItemId === item.id,
    )?.amount || '0',
    mathUtils.round(item.salesCommissionSum),
  );

export const calculateEstimateItemCommission = (
  item: GetEstimateItemDto,
  salesInvoiceOriginalEstimateItems?: CreateUpdateInvoiceEstimateItemDto[],
) =>
  mathUtils.add(
    mathUtils.subtract(
      mathUtils.round(item.salesCommissionAmount),
      mathUtils.round(item.salesCommissionSum),
    ),
    salesInvoiceOriginalEstimateItems?.find(
      ({ estimateItemId }) => estimateItemId === item.id,
    )?.commissionAmount || '0',
  );

export const calculateEstimateItemSalesSum = (
  availableEstimateItems?: GetEstimateItemDto[],
  salesInvoiceOriginalEstimateItems?: CreateUpdateInvoiceEstimateItemDto[],
) =>
  mathUtils.add(
    ...(availableEstimateItems?.map(item =>
      calculateEstimateItemSalesNet(item, salesInvoiceOriginalEstimateItems),
    ) || []),
  );

export const calculateEstimateItemAmountSum = (
  availableEstimateItems?: GetEstimateItemDto[],
  salesInvoiceEstimateItems?: CreateUpdateInvoiceEstimateItemDto[],
) =>
  mathUtils.add(
    ...(availableEstimateItems?.map(
      item =>
        salesInvoiceEstimateItems?.find(
          ({ estimateItemId }) => estimateItemId === item.id,
        )?.amount || '0',
    ) || []),
  );

export const calculatePreviousEstimateItemAmountSum = (
  availableEstimateItems?: GetEstimateItemDto[],
  salesInvoiceEstimateItems?: GetInvoiceEstimateItemDto[],
) =>
  mathUtils.add(
    ...(availableEstimateItems?.map(
      item =>
        salesInvoiceEstimateItems?.find(
          ({ estimateItem }) => estimateItem.id === item.id,
        )?.amount || '0',
    ) || []),
  );

export const calculateEstimateItemCommissionSum = (
  availableEstimateItems?: GetEstimateItemDto[],
  salesInvoiceOriginalEstimateItems?: CreateUpdateInvoiceEstimateItemDto[],
) =>
  mathUtils.add(
    ...(availableEstimateItems?.map(item =>
      calculateEstimateItemCommission(item, salesInvoiceOriginalEstimateItems),
    ) || []),
  );

export const calculateEstimateItemCommissionAmountSum = (
  availableEstimateItems?: GetEstimateItemDto[],
  salesInvoiceEstimateItems?: CreateUpdateInvoiceEstimateItemDto[],
) =>
  mathUtils.add(
    ...(availableEstimateItems?.map(
      item =>
        salesInvoiceEstimateItems?.find(
          ({ estimateItemId }) => estimateItemId === item.id,
        )?.commissionAmount || '0',
    ) || []),
  );

export const calculatePreviousEstimateItemCommissionAmountSum = (
  availableEstimateItems?: GetEstimateItemDto[],
  salesInvoiceEstimateItems?: GetInvoiceEstimateItemDto[],
) =>
  mathUtils.add(
    ...(availableEstimateItems?.map(
      item =>
        salesInvoiceEstimateItems?.find(
          ({ estimateItem }) => estimateItem.id === item.id,
        )?.commissionAmount || '0',
    ) || []),
  );

export const calculatePositionNetSum = (
  positions: CreateUpdateSalesInvoicePositionDto[],
) => mathUtils.add(...positions.map(({ amount }) => amount));

export const calculatePositionGrossSum = (
  positions: CreateUpdateSalesInvoicePositionDto[],
) =>
  mathUtils.add(
    ...positions.map(({ amount, vat }) =>
      getGrossPrice(amount, vat as VatEnum),
    ),
  );

export const calculateSumToInvoice = (
  estimateItems: GetInvoiceEstimateItemDto[],
) =>
  mathUtils.add(
    ...estimateItems.map(({ amount, commissionAmount }) =>
      mathUtils.add(amount, commissionAmount),
    ),
  );

export const calculatePositionCurrencySum = (
  positions: CreateUpdateSalesInvoicePositionDto[],
  salesInvoice?: GetSalesInvoiceDto,
) =>
  mathUtils.add(
    ...positions.map(({ amount }) =>
      calculateAmountByExchangeRate(amount, salesInvoice?.exchangeRate),
    ),
  );
