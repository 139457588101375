/*eslint complexity: ["error", 54]*/
import React from 'react';
import { uniq, sortBy } from 'lodash';
import type { TFunction } from 'i18next';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
  GridRecord,
  GridSummaryFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetProjectForFinanceDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import { TKeys, useTranslation } from '../../../common/hooks/useTranslation';
import numberFormatter from '../../../common/numberFormatter';
import { ProjectEstimatesStatusEnum } from '../../../common/pokCore/validation/enums';
import mathUtils from '../../../utils/mathUtils';

interface ProjectFinanceSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  getSummary: GridSummaryFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  showTotalDifferenceColumn?: boolean;
  totalDifferenceLabel?: string;
  withFilterInColumns?: boolean;
  refresh?: boolean;
}

export const projectFinanceForGrid = (
  t: TFunction<'projectLng', 'projectLng'>,
  tk: TKeys<'projectLng'>,
  project: GetProjectForFinanceDto,
) => {
  const saleInvoicesDifference =
    project?.salesTotal || project?.salesInvoicesAmount
      ? mathUtils.subtract(
          project?.salesTotal || 0,
          project?.salesInvoicesAmount || 0,
        )
      : null;
  const saleInvoicesCss =
    saleInvoicesDifference !== null && saleInvoicesDifference !== undefined
      ? mathUtils.Big(saleInvoicesDifference).toNumber() === 0
        ? 'bg-custom-green amounts-align-right'
        : 'bg-custom-orange amounts-align-right'
      : '';

  const purchaseInvoicesDifference =
    project?.purchaseTotal || project?.purchaseInvoicesAmount
      ? mathUtils.subtract(
          project?.purchaseTotal || 0,
          project?.purchaseInvoicesAmount || 0,
        )
      : null;
  const purchaseInvoicesCss =
    purchaseInvoicesDifference !== null &&
    purchaseInvoicesDifference !== undefined
      ? mathUtils.Big(purchaseInvoicesDifference).toNumber() === 0
        ? 'bg-custom-green amounts-align-right'
        : 'bg-custom-orange amounts-align-right'
      : '';

  return {
    key: project.id,
    values: {
      'project.name': project.name,
      'project.systemNumber': project.systemNumber,
      'budget.name': project.purchaser?.budget?.name,
      'project.number': project.number,
      'project.invoicesNumber': project?.invoicesNumber,
      'project.salesTotal': project?.salesTotal ? (
        <div className={saleInvoicesCss}>
          {numberFormatter(project?.salesTotal, true)}
        </div>
      ) : (
        ''
      ),
      'project.salesInvoicesAmount': project?.salesInvoicesAmount ? (
        <div className={saleInvoicesCss}>
          {numberFormatter(project?.salesInvoicesAmount, true)}
        </div>
      ) : (
        ''
      ),
      saleInvoicesDifference: (
        <div className={saleInvoicesCss}>
          {numberFormatter(saleInvoicesDifference, true)}
        </div>
      ),
      'project.purchaseTotal': project?.purchaseTotal ? (
        <div className={purchaseInvoicesCss}>
          {numberFormatter(project?.purchaseTotal, true)}
        </div>
      ) : (
        ''
      ),
      'project.purchaseInvoicesAmount': project?.purchaseInvoicesAmount ? (
        <div className={purchaseInvoicesCss}>
          {numberFormatter(project?.purchaseInvoicesAmount, true)}
        </div>
      ) : (
        ''
      ),
      purchaseInvoicesDifference: (
        <div className={purchaseInvoicesCss}>
          {numberFormatter(purchaseInvoicesDifference, true)}
        </div>
      ),
      project_number_active: project.systemNumber,
      'project.startDate': momencik(project.startDate),
      'project.endDate': momencik(project.endDate),
      'parent.number': project.parent?.number || '',
      'team.name': project.projectTeams
        ?.map(({ team }) => team?.name + ' (' + team?.company?.shortname + ')')
        ?.join(', '),
      'purchaser.name': project?.purchaser?.name,
      'client.name': project?.purchaser?.client?.name,
      'profile.name': sortBy(
        uniq(
          project.estimatesByMonths
            ?.filter(
              ebm =>
                ebm?.status !== ProjectEstimatesStatusEnum.Cancelled &&
                ebm?.active !== false,
            )
            .map(ebm => ebm?.profile?.name),
        ),
      )?.join(', '),
      'responsibleOwner.name': project.responsibleOwner?.name || '',
      'project.complianceOfOrder':
        project.complianceOfOrder === 'Tak'
          ? t(tk.common.yesUpperCase)
          : project.complianceOfOrder === 'Nie'
            ? t(tk.common.noUpperCase)
            : project.complianceOfOrder === 'ND'
              ? t(tk.common.notDefined)
              : '',
    },
    isInactive: !project.active,
    readOnly: project.delayVerification,
  };
};

export const getSummary = (
  t: TFunction<'projectLng', 'projectLng'>,
  tk: TKeys<'projectLng'>,
  data: GetProjectForFinanceDto,
) => {
  const saleInvoicesDifference =
    data?.salesTotal || data?.salesInvoicesAmount
      ? mathUtils.subtract(
          data?.salesTotal || 0,
          data?.salesInvoicesAmount || 0,
        )
      : null;

  const purchaseInvoicesDifference =
    data?.purchaseTotal || data?.purchaseInvoicesAmount
      ? mathUtils.subtract(
          data?.purchaseTotal || 0,
          data?.purchaseInvoicesAmount || 0,
        )
      : null;
  return {
    key: 'summary',
    values: {
      'project.systemNumber': t(tk.common.sumUpperCase),
      'project.salesTotal': numberFormatter(data.salesTotal || 0, true),
      'project.salesInvoicesAmount': numberFormatter(
        data.salesInvoicesAmount || 0,
        true,
      ),
      saleInvoicesDifference: numberFormatter(
        saleInvoicesDifference || 0,
        true,
      ),
      'project.purchaseTotal': numberFormatter(data.purchaseTotal || 0, true),
      'project.purchaseInvoicesAmount': numberFormatter(
        data.purchaseInvoicesAmount || 0,
        true,
      ),
      purchaseInvoicesDifference: numberFormatter(
        purchaseInvoicesDifference || 0,
        true,
      ),
    },
  } as unknown as GridRecord;
};

export const ProjectFinanceSearch: React.FC<
  ProjectFinanceSearchProps
> = props => {
  const { t, tk } = useTranslation('projectLng');
  const columns: GridColumn[] = [
    {
      header: t(tk.common.sysNumber),
      property: 'project.systemNumber',
    },
    { header: t(tk.common.number), property: 'project.number' },
    { header: t(tk.common.budget), property: 'budget.name' },
    {
      header: t(tk.projects.invoicesNumber),
      property: 'project.invoicesNumber',
      noSorting: true,
      noFilter: true,
    },
    { header: t(tk.projects.activeProfiles), property: 'profile.name' },
    { header: t(tk.common.name), property: 'project.name' },
    { header: t(tk.common.purchaser), property: 'purchaser.name' },
    { header: t(tk.common.contractor), property: 'client.name' },
    { header: t(tk.common.team), property: 'team.name' },
    { header: t(tk.common.startDate), property: 'project.startDate' },
    { header: t(tk.common.endDate), property: 'project.endDate' },
    {
      header: t(tk.projects.salesTotal),
      property: 'project.salesTotal',
      noSorting: true,
      noFilter: true,
    },
    {
      header: t(tk.projects.salesInvoicesAmount),
      property: 'project.salesInvoicesAmount',
      noSorting: true,
      noFilter: true,
    },
    {
      header: t(tk.projects.salesDiff),
      property: 'saleInvoicesDifference',
      noSorting: true,
      noFilter: true,
    },
    {
      header: t(tk.projects.purchaseTotal),
      property: 'project.purchaseTotal',
      noSorting: true,
      noFilter: true,
    },
    {
      header: t(tk.projects.purchaseInvoicesAmount),
      property: 'project.purchaseInvoicesAmount',
      noSorting: true,
      noFilter: true,
    },
    {
      header: t(tk.projects.purchaseDiff),
      property: 'purchaseInvoicesDifference',
      noSorting: true,
      noFilter: true,
    },
    {
      header: t(tk.projects.responsiblePerson),
      property: 'responsibleOwner.name',
    },
    { header: t(tk.prLang.parentProjectNumber), property: 'parent.number' },
    {
      header: t(tk.projects.complianceWithSalesTotal),
      property: 'project.complianceOfOrder',
      noSorting: true,
      noFilter: true,
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      getSummaryData={props.getSummary}
      columns={columns}
      showFilter={true}
      initialOrderColumn={'project_number_active'}
      initialOrderDirection={'DESC'}
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.prLang.emptyProjectsList)}
      onRowClick={props.onRowClick}
      withFilterInColumns={props.withFilterInColumns}
      withOverflowXAuto={true}
      refresh={props.refresh}
      withHistory={true}
    />
  );
};
