import React from 'react';
import { Form } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import ClientInfo from '../Clients/ClientInfo';
import { GetPurchaserDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface SalesInvoiceClientEditorProps {
  purchaser?: GetPurchaserDto;
}

export const SalesInvoiceClientEditor: React.FC<
  SalesInvoiceClientEditorProps
> = props => {
  const { t, tk } = useTranslation('fvsLng');
  return (
    <>
      <FormRow controlId="client" label={t(tk.common.contractor)}>
        <div className="d-flex">
          <div className="flex-grow-1">
            <Form.Control
              type="text"
              value={props.purchaser?.client?.name || ''}
              disabled
            />
          </div>
          <div className="flex-grow-0">
            <ClientInfo
              id={props.purchaser?.client.id}
              className="color-primary ms-2 mt-2"
              iconSize={20}
              placement="left"
            />
          </div>
        </div>
      </FormRow>
      <FormRow controlId="nip" label={t(tk.finance.nip)}>
        <Form.Control
          type="text"
          value={props.purchaser?.client?.nip || ''}
          disabled
        />
      </FormRow>
    </>
  );
};
