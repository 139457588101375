import React from 'react';
import { Card, Tab, Row } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';
import { useTranslation } from '../../../common/hooks/useTranslation';

export const ImportFinanceView: React.FC = () => {
  const nav = useNavigation();
  const { t, tk } = useTranslation('fvzLng');

  return (
    <Card>
      <TabsHistory activeKey="imports" id="imports" className="mb-3">
        <Tab eventKey="imports" title={t(tk.finance.imports)}>
          <Row xs={1} md={3} className="g-4">
            <ReportCardMenu
              title={t(tk.finance.googleImport)}
              text={t(tk.finance.googleImportDesc)}
              footer={t(tk.finance.importFvz)}
              onClick={nav.purchasesInvoicesImport}
            />
          </Row>
        </Tab>
      </TabsHistory>
    </Card>
  );
};
