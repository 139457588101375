/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateClientDto
 */
export interface CreateUpdateClientDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    shortname: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    nip?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    pesel?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    addressEx: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    postcode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    place: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    countryId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateClientDto
     */
    countryIsMain: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    numbers: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    flat: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateClientDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateClientDto
     */
    companyId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateClientDto
     */
    isRelated: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateClientDto
     */
    dealGroupNumber: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateClientDto
     */
    notToSymphony: boolean;
}

/**
 * Check if a given object implements the CreateUpdateClientDto interface.
 */
export function instanceOfCreateUpdateClientDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "shortname" in value;
    isInstance = isInstance && "addressEx" in value;
    isInstance = isInstance && "postcode" in value;
    isInstance = isInstance && "place" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "countryId" in value;
    isInstance = isInstance && "countryIsMain" in value;
    isInstance = isInstance && "numbers" in value;
    isInstance = isInstance && "flat" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "isRelated" in value;
    isInstance = isInstance && "dealGroupNumber" in value;
    isInstance = isInstance && "notToSymphony" in value;

    return isInstance;
}

export function CreateUpdateClientDtoFromJSON(json: any): CreateUpdateClientDto {
    return CreateUpdateClientDtoFromJSONTyped(json, false);
}

export function CreateUpdateClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'shortname': json['shortname'],
        'nip': !exists(json, 'nip') ? undefined : json['nip'],
        'pesel': !exists(json, 'pesel') ? undefined : json['pesel'],
        'addressEx': json['addressEx'],
        'postcode': json['postcode'],
        'place': json['place'],
        'street': json['street'],
        'countryId': json['countryId'],
        'countryIsMain': json['countryIsMain'],
        'numbers': json['numbers'],
        'flat': json['flat'],
        'active': json['active'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'isRelated': json['isRelated'],
        'dealGroupNumber': json['dealGroupNumber'],
        'notToSymphony': json['notToSymphony'],
    };
}

export function CreateUpdateClientDtoToJSON(value?: CreateUpdateClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'shortname': value.shortname,
        'nip': value.nip,
        'pesel': value.pesel,
        'addressEx': value.addressEx,
        'postcode': value.postcode,
        'place': value.place,
        'street': value.street,
        'countryId': value.countryId,
        'countryIsMain': value.countryIsMain,
        'numbers': value.numbers,
        'flat': value.flat,
        'active': value.active,
        'companyId': value.companyId,
        'isRelated': value.isRelated,
        'dealGroupNumber': value.dealGroupNumber,
        'notToSymphony': value.notToSymphony,
    };
}

