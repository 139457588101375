import React from 'react';
import { Form } from 'react-bootstrap';

import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { Option } from '../Selects/Selector';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import { usePokCore } from '../../../common/hooks/usePokCore';

import ProfileFieldName from './ProfileFieldName';

interface LabconSpecialParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  project?: GetProjectDto;
  readonly?: boolean;
  lang: string;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const LabconSpecialParams: React.FC<
  LabconSpecialParamsProps
> = props => {
  const pok = usePokCore();
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    if (props.propertyChange) {
      props.propertyChange(obj);
    }
  };

  return (
    <>
      {schema?.service && (
        <ProfileFieldName
          controlId="service"
          schema={schema?.service}
          lang={props.lang}
        >
          <Form.Control
            type="text"
            value={props.estimateItem?.service ?? ''}
            onChange={e =>
              propertyChange({
                service: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.action && (
        <ProfileFieldName
          controlId="action"
          schema={schema?.action}
          lang={props.lang}
        >
          <Form.Control
            type="text"
            value={props.estimateItem?.action ?? ''}
            onChange={e =>
              propertyChange({
                action: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.actionGroup && (
        <ProfileFieldName
          controlId="actionGroup"
          schema={schema?.actionGroup}
          lang={props.lang}
        >
          <CreatableClearableSelector
            value={props.estimateItem?.actionGroup ?? ''}
            onChange={option =>
              propertyChange({
                actionGroup: option ? (option as Option).label : option,
              })
            }
            provider={(text: string) =>
              pok.estimateItems.getActionGroupsOptionsByTextByCompanyAndBudget(
                text,
                props.project?.company?.id || '',
                props.project?.purchaser.budget.id || '',
              )
            }
          />
        </ProfileFieldName>
      )}
    </>
  );
};
