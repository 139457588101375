import React from 'react';
import type { TFunction } from 'i18next';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetEmployeeWithRolesDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { TKeys, useTranslation } from '../../../common/hooks/useTranslation';

import { DeleteButton } from './DeleteButton';

interface EmployeeSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  withMerging?: boolean;
  refresh?: boolean;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  selectedKeys?: string[];
  withHistory?: boolean;
}

export const employeeForGrid = (
  t: TFunction<'administrationLng', 'administrationLng'>,
  tk: TKeys<'administrationLng'>,
  employee: GetEmployeeWithRolesDto,
  refresh: boolean,
  setRefresh: (refresh: boolean) => void,
) => {
  return {
    key: employee.id,
    values: {
      'employee.oneToolId': employee.oneToolId,
      'employee.name': employee.name,
      'employee.login': employee.login,
      'company.shortname': employee.company?.shortname,
      'employee.email': employee.email,
      'employee.teamName': employee.teamName,
      'employee.supervisor': employee.supervisor,
      'employee.manager': employee.manager,
      'employee.position': employee.position,
      'employee.technical': employee.technical ? t(tk.common.yesUpperCase) : '',
      deleteButton: employee.roles && (
        <DeleteButton
          employeeId={employee.id}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ),
    },
    isInactive: !employee.active,
  };
};

export const EmployeeSearch: React.FC<EmployeeSearchProps> = props => {
  const { t, tk } = useTranslation('administrationLng');

  const columns: GridColumn[] = [
    { header: t(tk.administration.oneToolId), property: 'employee.oneToolId' },
    { header: t(tk.administration.nameSurname), property: 'employee.name' },
    { header: t(tk.administration.login), property: 'employee.login' },
    { header: t(tk.administration.email), property: 'employee.email' },
    { header: t(tk.common.company), property: 'company.shortname' },
    { header: t(tk.common.team), property: 'employee.teamName' },
    { header: t(tk.administration.position), property: 'employee.position' },
    {
      header: t(tk.administration.supervisor),
      property: 'employee.supervisor',
    },
    { header: t(tk.administration.manager), property: 'employee.manager' },
    { header: t(tk.administration.technical), property: 'employee.technical' },
    { header: '', property: 'deleteButton', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="employee.name"
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.administration.noEmployees)}
      refresh={props.refresh}
      onRowClick={props.onRowClick}
      enableMultiSelect={props.selectedKeys ? true : false}
      selectedKeys={props.selectedKeys}
      withHistory={props.withHistory}
    />
  );
};
