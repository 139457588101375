/*eslint max-lines-per-function: ["error", 180]*/
import React, { useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';

import {
  GetEstimateByMonthDto,
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  ApplicationUnblockTypeEnum,
  ProjectEstimatesStatusEnum,
} from '../../../common/pokCore/validation/enums';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { ApplicationUnblockSection } from '../ApplicationsUnblock/ApplicationUnblockSection';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { EstimateItemsTab } from './EstimateItemsTab';
import { EstimateSummaryTab } from './EstimateSummaryTab';
import { EstimateByMonthStatus } from './EstimateByMonthStatus';
import { CloneProfileForm } from './CloneProfileForm';

interface EstimateProfileTabsProps {
  estimateByMonth?: GetEstimateByMonthDto;
  project?: GetProjectDto;
  paeTeam: boolean;
  saleGlobalBlocked: boolean;
  purchaseGlobalBlocked: boolean;
  updateStatus?: (status: ProjectEstimatesStatusEnum) => void;
  onDelete: (id?: string) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  onRefresh: () => void;
  clone: (
    id: string,
    date: Date,
    withAmounts: boolean,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const EstimateProfileTabs: React.FC<
  EstimateProfileTabsProps
> = props => {
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [isLoadingToggle, setIsLoadingToggle] = useState(false);

  const notifications = useNotifications();
  const pok = usePokCore();
  const { t, tk } = useTranslation('projectLng');

  const deleteProfile = async (id?: string) => {
    setIsLoadingDelete(true);
    props
      .onDelete(id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        notifications.saveCompleted();
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoadingDelete(false));
  };

  const updateStatus = (status?: ProjectEstimatesStatusEnum) => {
    if (status && props.estimateByMonth?.id) {
      setIsLoadingStatus(true);
      pok.estimatesByMonth
        .updateStatus(status, props.estimateByMonth?.id)
        .then(() => {
          props.estimateByMonth!.status = status;
          if (props.updateStatus) {
            props.updateStatus(status);
          }
          notifications.saveCompleted();
          setShowSaveErrors(false);
        })
        .catch(async errorResponse => {
          setShowSaveErrors(true);
          setSaveErrors(await responseErrors(errorResponse as ResponseError));
        })
        .finally(() => setIsLoadingStatus(false));
    }
  };

  const toggleBlocked = (value: boolean, type: ApplicationUnblockTypeEnum) => {
    if (props.estimateByMonth?.id) {
      setIsLoadingToggle(true);
      pok.estimatesByMonth
        .toggleBlocked(props.estimateByMonth?.id, value, type)
        .then(() => {
          if (type === ApplicationUnblockTypeEnum.Sales) {
            props.estimateByMonth!.isSalesBlocked = value;
          } else {
            props.estimateByMonth!.isPurchaseBlocked = value;
          }
          props.onRefresh();
          notifications.saveCompleted();
        })
        .catch(async errorResponse => {
          notifications.caughtError(errorResponse);
        })
        .finally(() => setIsLoadingToggle(false));
    }
  };

  return (
    <>
      <Card className="my-3">
        <Tabs
          defaultActiveKey="positions"
          id="estimateProfileTabs"
          onSelect={tab =>
            ['summary', 'applicationsUnblock'].includes(tab as string) &&
            setRefresh(!refresh)
          }
        >
          <Tab
            eventKey="summary"
            title={t(tk.estimates.summary)}
            className="m-4"
          >
            <EstimateSummaryTab
              estimateByMonth={props.estimateByMonth}
              refresh={refresh}
            />
          </Tab>
          <Tab
            eventKey="positions"
            title={t(tk.estimates.positions)}
            className="m-4"
          >
            <EstimateItemsTab
              estimateByMonth={props.estimateByMonth}
              project={props.project}
              paeTeam={props.paeTeam}
              onRefresh={props.onRefresh}
              salesBlockedMonth={props.saleGlobalBlocked}
              purchaseBlockedMonth={props.purchaseGlobalBlocked}
              salesBlocked={
                props.saleGlobalBlocked ||
                !!props.estimateByMonth?.isSalesBlocked
              }
              purchaseBlocked={
                props.purchaseGlobalBlocked ||
                !!props.estimateByMonth?.isPurchaseBlocked
              }
            />
          </Tab>
          <Tab eventKey="status" title={t(tk.estimates.status)} className="m-5">
            <EstimateByMonthStatus
              estimateByMonth={props.estimateByMonth}
              updateStatus={updateStatus}
              isLoadingStatus={isLoadingStatus}
            />
          </Tab>
          <Tab
            eventKey="applicationsUnblock"
            title={t(tk.estimates.applications)}
            className="m-5"
          >
            <ApplicationUnblockSection
              estimateByMonth={props.estimateByMonth}
              toggleBlocked={toggleBlocked}
              project={props.project}
              isLoadingToggle={isLoadingToggle}
              salesBlockedMonth={props.saleGlobalBlocked}
              purchaseBlockedMonth={props.purchaseGlobalBlocked}
            />
          </Tab>
        </Tabs>
      </Card>
      <ValidationAlert
        show={showSaveErrors}
        errors={saveErrors}
        className="m-3"
      />
      <div className="side-by-side">
        <div className="left">
          {props.estimateByMonth?.id && (
            <LastEditorInfo
              className="color-primary"
              id={props.estimateByMonth?.id || ''}
              method={pok.estimatesByMonth.getLastEditor}
            />
          )}
        </div>
        <div className="right">
          {props.estimateByMonth && (
            <CloneProfileForm
              estimateByMonthId={props.estimateByMonth.id}
              clone={props.clone}
            />
          )}
          {!props.estimateByMonth?.isSalesBlocked && (
            <ConfirmationButton
              className="mx-1"
              variant="outline-danger"
              onOK={() => deleteProfile(props.estimateByMonth?.id)}
              confirmation={t(tk.estimates.askDeleteProfile)}
              isLoading={isLoadingDelete}
            >
              {t(tk.estimates.deleteProfile)}
            </ConfirmationButton>
          )}
        </div>
      </div>
    </>
  );
};
