export default {
  app: {
    askDelete: 'Are you sure you want to delete the request?',
    addUnblock: 'Create a request to unblock the monthly profile',
    askBlockUnblockSale: 'Are you sure you want to {{block}} sales?',
    askBlockUnblockPurchase: 'Are you sure you want to {{block}} purchases?',
    unblock: 'unblock',
    block: 'block',
    unblockDoIt: 'Unblock',
    blockDoIt: 'Block',
    sale: 'sale',
    purchase: 'purchase',
    type: 'Type',
    reason: 'Reason',
    rejectedNote: 'Rejection reason',
    description: 'Reason for submission',
    noApplicationUnblock: 'No requests assigned to this monthly profile',
    noUnblock: 'No unblocking requests.',
    salesActiveBlock: 'Active global sales block for the month',
    purchaseActiveBlock: 'Active global purchase block for the month',
    addUnblockApp: 'Adding a new request to unblock the monthly profile',
    editUnblockApp:
      'Editing request to unblock the monthly profile (system no. {{sysNumber}})',
    typeApplication: 'Type of request',
    salesCorrect: 'The request concerns a sales correction',
    isDocumentation: 'The change is confirmed by documentation',
    copyEmailContent: 'Copy email content to clipboard',
    alertBeforeAddingForeignClient: `↓ WARNING: Due to the registration of the contractor from outside Poland, 
        please send the following message to the contractor to obtain the required documents ↓`,
    enterNIP: 'Please enter VAT ID',
    editingApplication: `Edit request from {{createdDate}}`,
    addingNewApplication: 'Add a new request',
    creator: 'request author',
    applicationExecutionWithDate: 'Request execution from {{createdDate}}',
    applicationData: 'Request data',
    applicationType: 'Request type',
    purchaserName: 'Purchaser name',
    clientData: 'Contractor data',
    employeeAccess: 'Employee access',
    employeeBoss: 'Approving manager',
    applicationExecution: 'Request execution',
    valueName: 'Value name',
    brandName: 'Brand name',
    profilePosition: 'Profile position',
    sharedObjectType: 'Shared object type',
    sharedObject: 'Shared object',
    noApplications: 'No requests in the system.',
    applications: 'Requests',
    newApplication: 'New request',
    my: 'My',
    companies: 'Companies',
    announcements: 'Announcements',
    reportingContractsForWork: 'Reporting contracts for work',
    pending: 'Pending',
    all: 'All',
    archive: 'Archive',
    specialInvoicingApplicationExecution:
      'Execution of special invoicing request (sys. no. {{sysNumber}})',
    waiting: 'Pending',
    waitingForCorrection: 'Pending correction',
    specialInvoicingApplications: 'Special invoicing requests',
    specialInvoicingApplicationsRealization:
      'Special invoicing requests - realization',
    specialInvoicingApplicationDetails:
      'Special invoicing request details (sys. no. {{sysNumber}})',
    specialInvoicingApplicationEdit:
      'Edit special invoicing request (sys. no. {{sysNumber}})',
    createSpecialInvoicingApplication: 'Create special invoicing request',
    askDeleteApplication: 'Are you sure you want to delete this request?',
    requiredChanges: 'Required changes',
    acceptedFinanceNote: 'Accepted invoicing description',
    applicationDescription: 'Request content',
    financeNote: 'Invoicing description',
    noSpecialInvoiceApplications:
      'No special invoicing requests in the system.',
    applicationExecutionWithDateNo:
      'Request execution from {{createdDate}} sys. no. {{sysNumber}}',
    globalSalesMonthLock: 'Active global sales lock for the month',
    globalPurchaseMonthLock: 'Active global purchase lock for the month',
    approved: 'Approved',
    rejected: 'Rejected',
    delayed: 'Pending overdue',
    canceling: 'Pending cancellation',
    allDelayed: 'All overdue',
    allCanceling: 'All pending cancellation',
    toApplication: 'to request',
    needReload: 'need to be reloaded (by logout and login)',
  },
} as const;
