import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import mathUtils from '../../utils/mathUtils';

export default (props: {
  width?: number;
  height?: number;
  percentage?: number;
  text?: string;
  currentVal?: string | number;
  maxVal?: string | number;
}) => {
  let backgroundColor = '#ddd';
  let pieColor = '#2486FF'; //blue
  let percentage = props.percentage || 0;
  if (props.currentVal && props.maxVal && props.maxVal !== 0) {
    percentage = mathUtils.round(
      mathUtils.multiply(mathUtils.divide(props.currentVal, props.maxVal), 100),
    );
  } else if (props.currentVal === 0 && props.maxVal === 0) {
    percentage = 100;
  }

  if (percentage > 100) {
    pieColor = '#FF0000'; //red
  } else if (percentage === 100) {
    pieColor = '#40C057'; //green
  } else if (percentage > 75) {
    pieColor = '#2486FF'; //blue
  } else if (percentage > 20) {
    pieColor = '#FFA500'; //yellow
  } else {
    pieColor = '#FF0000'; //red
  }

  if (percentage === 0) {
    backgroundColor = '#FF0000';
  }

  return (
    <OverlayTrigger
      overlay={
        <Tooltip>{`${props.text ?? ''} ${percentage.toFixed(2)} %`}</Tooltip>
      }
    >
      <div style={{ width: props.width || 30, height: props.height || 30 }}>
        <CircularProgressbar
          value={percentage}
          strokeWidth={50}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathColor: pieColor,
            trailColor: backgroundColor,
          })}
        />
      </div>
    </OverlayTrigger>
  );
};
