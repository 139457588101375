import React, { useEffect, useState } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetClientDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { Selector, Option } from './Selector';

export interface ClientSelectorProps {
  projectId?: string;
  readOnly?: boolean;
  value?: GetClientDto;
  valueId?: string;
  buildName?: (client: GetClientDto) => string;
  className?: string;
  onChange?: (option: Option) => void;
  clearable?: boolean;
}

export const ClientSelector: React.FC<ClientSelectorProps> = (
  props: ClientSelectorProps,
) => {
  const pok = usePokCore();
  const [name, setName] = useState<string>('');
  const { t, tk, i18n } = useTranslation('clientLng');

  useEffect(() => {
    if (props.value) {
      setName(props.value?.name);
      return;
    }
    if (!props.valueId) {
      setName('');
      return;
    }
    void pok.clients.getById(props.valueId).then(client => {
      setName(
        props.buildName
          ? props.buildName(client)
          : `${client.name} (${client.nip})`,
      );
    });
  }, [pok.clients, props.value, props.valueId, props]);

  const provider = (text: string) =>
    props.projectId
      ? pok.positions.getClientsOptionsByProjectId(props.projectId, text)
      : pok.clients.getOptionsByNipPeselOrName(t, tk, text);

  return (
    <Selector
      key={
        'clients-' + JSON.stringify(provider) + JSON.stringify(i18n.language)
      }
      className={props.className}
      readOnly={props.readOnly}
      clearable={props.clearable}
      creatable={false}
      provider={provider}
      value={name}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
