import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import mathUtils from '../../../utils/mathUtils';
import {
  CreateUpdatePurchaseInvoiceDto,
  CreateUpdatePurchaseInvoicePositionDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import numberFormatter from '../../../common/numberFormatter';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { newPurchaseInvoicePosition } from '../../../common/pokCore/contexts/PurchaseInvoicePositionContext';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { PurchaseInvoicePositionEditor } from './PurchaseInvoicePositionEditor';

interface PurchaseInvoiceEditorFormPositionsProps {
  purchaseInvoice: CreateUpdatePurchaseInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdatePurchaseInvoiceDto>) => void;
  readOnly?: boolean;
}

export const PurchaseInvoiceEditorFormPositions: React.FC<
  PurchaseInvoiceEditorFormPositionsProps
> = props => {
  const { t, tk } = useTranslation('fvzLng');
  const [position, setPosition] = useState<
    CreateUpdatePurchaseInvoicePositionDto | undefined
  >();
  const [show, setShow] = useState(false);

  const COLUMNS: GridColumn[] = [
    { header: t(tk.finance.netAmount), property: 'netAmount' },
    { header: t(tk.finance.vatRate), property: 'vat' },
    { header: t(tk.finance.vatAmount), property: 'vatAmount' },
    ...(props.readOnly
      ? []
      : [{ header: '', property: 'deleteLink', noSorting: true }]),
  ];

  const handleClick = async (id: string) => {
    const foundPosition = props.purchaseInvoice.positions?.find(
      (position, index) => position.id === id || index.toString() === id,
    );
    const fetchedId = foundPosition?.id || id;
    setPosition(
      foundPosition
        ? {
            ...foundPosition,
            id: fetchedId,
          }
        : undefined,
    );
    setShow(true);
  };

  const handleAdd = () => {
    setShow(true);
    setPosition(undefined);
  };

  const handleClose = () => {
    setShow(false);
    setPosition(undefined);
  };

  const handleSave = (
    positionCU: CreateUpdatePurchaseInvoicePositionDto,
    id?: string,
  ) => {
    let positions = props.purchaseInvoice.positions || [];
    if (id) {
      positions = positions.map((position, index) => {
        if (position.id === id || index.toString() === id) {
          return { ...positionCU, id: position.id || undefined };
        }
        return position;
      });
    } else {
      positions = [...positions, positionCU];
    }
    const amount = mathUtils
      .add(...positions.map(({ netAmount }) => netAmount))
      .toString();
    props.propertyChange({ positions, amount, amountWithoutPosition: amount });
    handleClose();
  };

  const handleDelete = (id: string) => {
    const newPositions = (props.purchaseInvoice.positions || []).filter(
      (position, index) => position.id !== id && index.toString() !== id,
    );
    const amount = mathUtils
      .add(...newPositions.map(({ netAmount }) => netAmount))
      .toString();
    props.propertyChange({
      positions: newPositions,
      amount,
      amountWithoutPosition: amount,
    });
  };

  const data = (props.purchaseInvoice.positions || []).map(
    (position, index) => ({
      key: position.id || index.toString(),
      values: {
        ...position,
        netAmount: numberFormatter(position.netAmount, true),
        vatAmount: numberFormatter(position.vatAmount, true),
        deleteLink: (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div onClick={e => e.stopPropagation()}>
            <ConfirmationButton
              variant="outline-danger"
              confirmation={t(tk.finance.askDeletePositions)}
              tooltip={t(tk.finance.deletePosition)}
              onOK={() => handleDelete(position.id || index.toString())}
            >
              <Trash size={16} />
            </ConfirmationButton>
          </div>
        ),
      },
    }),
  ) as unknown as GridRecord[];

  return (
    <>
      {props.readOnly ? null : (
        <Button variant="outline-primary" className="mb-4" onClick={handleAdd}>
          {t(tk.finance.addPosition)}
        </Button>
      )}
      <Grid
        data={data}
        columns={COLUMNS}
        showFilter={false}
        onRowClick={props.readOnly ? undefined : handleClick}
        emptyGridMessage={t(tk.finance.noPositions)}
        trClassName="amounts-align-right"
      />
      {show && (
        <PurchaseInvoicePositionEditor
          id={position?.id}
          position={position || newPurchaseInvoicePosition()}
          handleClose={handleClose}
          show={show}
          onCompleted={handleSave}
        />
      )}
    </>
  );
};
