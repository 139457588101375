/*eslint max-lines-per-function: ["error", 200]*/
/*eslint complexity: ["error", 22]*/
import React, { useMemo, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import type { TFunction } from 'i18next';

import { TabsHistory } from '../../../app/components/TabsHistory';
import { SalesInvoiceTab } from '../../components/SalesInvoices/SalesInvoiceTab';
import { TKeys, useTranslation } from '../../../common/hooks/useTranslation';
import { SalesInvoiceBasicData } from '../../components/SalesInvoices/SalesInvoiceBasicData';
import {
  InvoiceStatusEnum,
  InvoiceStatusObj,
} from '../../../common/pokCore/validation/enums';
import {
  SalesInvoicePositions,
  TabLocationState,
} from '../../components/SalesInvoices/SalesInvoicePositions';
import { SalesInvoiceMediaPlanPositions } from '../../components/SalesInvoices/SalesInvoiceMediaplanPositions';
import { SalesInvoiceSummary } from '../../components/SalesInvoices/SalesInvoiceSummary';
import useSalesInvoiceData, {
  AttachmentId,
} from '../../components/SalesInvoices/useSalesInvoiceData';
import { BadCompany } from '../../../common/components/NotAuthorized/BadCompany';
import {
  GetExtendedSalesInvoiceDto,
  GetProjectDto,
  GetSalesInvoiceDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNavigation } from '../../../common/navigation';
import { useLocation } from '../../../common/hooks/useLocation';
import { DownloadAttachment } from '../../components/Attachments/DownloadAttachment';
import { Waiting } from '../../../common/components/Waiting/Waiting';

import { SalesInvoiceEditorTabs } from './SalesInvoiceEditorTabs';
import useIsAuthorized from './useIsAuthorized';

interface TabProps {
  key: SalesInvoiceEditorTabs;
  title: string;
  component: React.ReactNode;
  disabled: boolean;
  notSaved?: boolean;
}

const renderAttachmentIcon = (
  t: TFunction<'fvsLng', 'fvsLng'>,
  tk: TKeys<'fvsLng'>,
  attachmentId?: string,
) =>
  attachmentId ? (
    <DownloadAttachment
      id={attachmentId}
      icon={Icon.FilePdf}
      tooltipText={t(tk.finance.openInstructionNewWindow)}
      modalTitleText={t(tk.finance.invoiceInstruction)}
    />
  ) : null;

const buildTitle = ({
  t,
  tk,
  isInActive,
  salesInvoice,
  projects,
  attachmentIds,
  label,
  goToProject,
}: {
  t: TFunction<'fvsLng', 'fvsLng'>;
  tk: TKeys<'fvsLng'>;
  isInActive?: boolean;
  salesInvoice?: GetExtendedSalesInvoiceDto;
  projects: GetProjectDto[];
  attachmentIds?: AttachmentId[];
  label: string;
  goToProject: (id: string) => void;
}) => {
  return (
    <>
      {isInActive && <b>{t(tk.finance.archiveUpperCase)} </b>}
      <span style={isInActive ? { textDecoration: 'line-through' } : {}}>
        {salesInvoice?.parent ? (
          <>
            {t(tk.finance.correction)} {salesInvoice?.number}{' '}
            {t(tk.finance.toSaleInvoice)}{' '}
            {salesInvoice?.originalInvoice?.number}
          </>
        ) : (
          <>
            {t(tk.finance.saleInvoice)}{' '}
            {salesInvoice && <b>{salesInvoice.number}</b>}{' '}
          </>
        )}{' '}
        {label}{' '}
        {projects.map((project, index) => (
          <React.Fragment key={project.id}>
            <b>
              {project?.name} (
              <Button
                variant="link"
                className="p-0 title-size"
                onClick={() => goToProject(project.id)}
              >
                {project?.number}
              </Button>
              )
            </b>
            {renderAttachmentIcon(
              t,
              tk,
              attachmentIds?.find(({ projectId }) => projectId === project.id)
                ?.id,
            )}
            {index < projects.length - 1 ? ' | ' : ' '}
          </React.Fragment>
        ))}
        <b>
          [
          {t(
            InvoiceStatusObj[salesInvoice?.status || InvoiceStatusEnum.Draft],
          ).toUpperCase()}
          {salesInvoice?.status === InvoiceStatusEnum.Sent &&
          salesInvoice?.packageFVSNumber
            ? ` ${t(tk.finance.packageWithNumber)} ${salesInvoice?.packageFVSNumber}`
            : ''}
          ]
        </b>
        {salesInvoice?.systemNumber
          ? ` (${t(tk.common.sysNumberLowerCase)} ${salesInvoice.systemNumber})`
          : ''}
      </span>
    </>
  );
};

const getTabClassName = (projects: GetProjectDto[], notSaved?: boolean) =>
  notSaved && projects.length ? 'not-saved-tab' : '';

const isBasicDataNotSaved = (
  notSavedTab: SalesInvoiceEditorTabs | undefined,
  salesInvoice?: GetSalesInvoiceDto,
) =>
  notSavedTab === SalesInvoiceEditorTabs.BASIC_DATA ||
  !(salesInvoice && salesInvoice.id);

const checkIsReadOnly = (salesInvoice?: GetSalesInvoiceDto) =>
  [
    InvoiceStatusEnum.Generated,
    InvoiceStatusEnum.Sent,
    InvoiceStatusEnum.Paid,
  ].includes(salesInvoice?.status as InvoiceStatusEnum);

export const SalesInvoiceEditorView: React.FC = () => {
  const {
    projects,
    setProjects,
    error,
    convertedSalesInvoice,
    handleRefresh,
    handleRemove,
    salesInvoice,
    attachmentIds,
    isReady,
    isLoading,
    refresh,
    parentAmount,
  } = useSalesInvoiceData();
  const location = useLocation<TabLocationState>();
  const nav = useNavigation();
  const { t, tk } = useTranslation('fvsLng');
  const authorized = useIsAuthorized(projects);
  const [notSavedTab, setNotSavedTab] = useState<
    SalesInvoiceEditorTabs | undefined
  >(location?.state?.notSavedTab as SalesInvoiceEditorTabs);

  const isInActive = salesInvoice && !salesInvoice.active;
  const isReadOnly = checkIsReadOnly(salesInvoice) || isInActive;
  const initialProjectIds = salesInvoice?.salesInvoiceProjects?.map(
    ({ project }) => project.id,
  );

  const tabs: TabProps[] = useMemo(() => {
    const commonProps = {
      convertedSalesInvoice,
      salesInvoice,
      projects,
      setProjects,
      id: salesInvoice?.id,
      handleRefresh,
      handleRemove,
      setNotSavedTab,
      refresh,
      purchaser: salesInvoice?.purchaser || projects?.[0]?.purchaser,
      positions: salesInvoice?.salesInvoicePositions,
      company: salesInvoice?.company,
      number: salesInvoice?.number,
      allowCorrection: salesInvoice?.allowCorrection,
      isDeactivated: isInActive,
    };

    return [
      {
        key: SalesInvoiceEditorTabs.BASIC_DATA,
        title: t(tk.finance.basicData),
        component: (
          <SalesInvoiceBasicData
            {...commonProps}
            initialProjectIds={initialProjectIds}
            readOnly={
              isReadOnly ||
              (!!notSavedTab &&
                notSavedTab !== SalesInvoiceEditorTabs.BASIC_DATA)
            }
          />
        ),
        disabled: false,
        notSaved: isBasicDataNotSaved(notSavedTab, salesInvoice),
      },
      {
        key: SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS,
        title: t(tk.prLang[SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS]),
        component: (
          <SalesInvoiceMediaPlanPositions
            {...commonProps}
            salesInvoice={salesInvoice}
            readOnly={
              isReadOnly ||
              (!!notSavedTab &&
                notSavedTab !== SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS)
            }
            parentAmount={parentAmount}
          />
        ),
        disabled: !salesInvoice,
        notSaved: notSavedTab === SalesInvoiceEditorTabs.MEDIAPLAN_POSITIONS,
      },
      {
        key: SalesInvoiceEditorTabs.INVOICE_POSITIONS,
        title: t(tk.finance.invoicePositions),
        component: (
          <SalesInvoicePositions
            {...commonProps}
            salesInvoice={salesInvoice}
            readOnly={
              isReadOnly ||
              (!!notSavedTab &&
                notSavedTab !== SalesInvoiceEditorTabs.INVOICE_POSITIONS)
            }
            parentAmount={parentAmount}
          />
        ),
        disabled: !salesInvoice?.invoiceEstimateItems?.length,
        notSaved: notSavedTab === SalesInvoiceEditorTabs.INVOICE_POSITIONS,
      },
      {
        key: SalesInvoiceEditorTabs.SUMMARY,
        title: t(tk.finance.summary),
        component: (
          <SalesInvoiceSummary
            {...commonProps}
            readOnly={
              isReadOnly ||
              (!!notSavedTab && notSavedTab !== SalesInvoiceEditorTabs.SUMMARY)
            }
          />
        ),
        disabled: !salesInvoice?.salesInvoicePositions?.length,
        notSaved: notSavedTab === SalesInvoiceEditorTabs.SUMMARY,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesInvoice, convertedSalesInvoice, isReady]);

  if (!authorized) {
    return <BadCompany companyId={salesInvoice?.company?.id} />;
  }

  if (isLoading) {
    return <Waiting />;
  }

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title>
          {buildTitle({
            isInActive,
            salesInvoice,
            projects,
            attachmentIds,
            label: t(tk.prLang.salesInvoiceToProject),
            goToProject: id => nav.projectDetails(id, true, 'attachments'),
            t,
            tk,
          })}
        </Card.Title>
        {salesInvoice?.correctionsNumbers?.length ? (
          <Card.Subtitle className="pt-3">
            {t(tk.finance.withCorrections)}:{' '}
            {salesInvoice?.correctionsNumbers?.join(', ')}
          </Card.Subtitle>
        ) : null}
        <TabsHistory
          activeKey={SalesInvoiceEditorTabs.BASIC_DATA}
          id="product"
          className="my-3"
        >
          {isReady &&
            tabs.map(({ key, title, component, notSaved, disabled }) => (
              <Tab
                key={key}
                eventKey={key}
                tabClassName={getTabClassName(projects, notSaved)}
                title={`${title} ${notSaved && projects.length ? `(${t(tk.finance.unwrittenUppercase)})` : ''}`}
                className="m-3"
                disabled={disabled}
              >
                <SalesInvoiceTab
                  isLoading={!projects.length}
                  component={component}
                  error={error}
                />
              </Tab>
            ))}
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
