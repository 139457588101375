import React, { PropsWithChildren } from 'react';

import { useLocation } from '../../common/hooks/useLocation';

import NavMain from './NavMain';
import Main from './Main';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const [color, setColor] = React.useState<string>('');

  return (
    <div>
      <NavMain activePage={useLocation().pathname} changeColor={setColor} />
      <Main location={useLocation().pathname} color={color}>
        {children}
      </Main>
    </div>
  );
};
export default Layout;
