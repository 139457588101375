import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import {
  CreateUpdateBankAccountDto,
  GetClientDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import CompanySelector from '../Selects/CompanySelector';
import { Option } from '../Selects/Selector';
import {
  CurrencyIsoTypeEnum,
  InvoiceTypeEnum,
  InvoiceTypeObj,
} from '../../../common/pokCore/validation/enums';
import { ClientSelector } from '../Selects/ClientSelector';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { formatAccountNumber } from '../../../common/pokCore/validation/schemas';

interface BankAccountEditorFormProps {
  id?: string;
  bankAccount: CreateUpdateBankAccountDto;
  client?: GetClientDto;
  propertyChange: (obj: Partial<CreateUpdateBankAccountDto>) => void;
}

const currencyOptions = Object.entries(CurrencyIsoTypeEnum).map(
  ([key, value]) => ({
    value: key as CurrencyIsoTypeEnum,
    label: value,
  }),
);

export const BankAccountEditorForm: React.FC<
  BankAccountEditorFormProps
> = props => {
  const { t, tk } = useTranslation('administrationLng');
  const invoiceTypeOptions = Object.entries(InvoiceTypeEnum).map(
    ([key, value]) => ({
      value: key as InvoiceTypeEnum,
      label: t(InvoiceTypeObj[value]),
    }),
  );

  return (
    <Form className="d-grid gap-4 mb-3">
      <FormRow controlId="companyId" label={t(tk.common.company)}>
        <CompanySelector
          value={props.bankAccount.companyId}
          onChange={option => {
            props.propertyChange({
              companyId: (option as Option)?.value,
            });
          }}
        />
      </FormRow>
      <FormRow controlId="invoiceType" label={t(tk.administration.invoiceType)}>
        <Select
          value={
            props.bankAccount?.invoiceType
              ? invoiceTypeOptions.find(
                  ({ value }) => props.bankAccount?.invoiceType === value,
                )
              : null
          }
          options={invoiceTypeOptions}
          onChange={option =>
            props.propertyChange({
              invoiceType: option?.value,
            })
          }
          placeholder={t(tk.component.pointOut)}
        />
      </FormRow>
      <FormRow controlId="currency" label={t(tk.common.currency)}>
        <Select
          value={
            props.bankAccount?.currency
              ? currencyOptions.find(
                  ({ value }) => props.bankAccount?.currency === value,
                )
              : null
          }
          options={currencyOptions}
          onChange={option =>
            props.propertyChange({
              currency: option?.value,
            })
          }
          placeholder={t(tk.component.pointOut)}
        />
      </FormRow>
      <FormRow controlId="client" label={t(tk.common.client)}>
        <ClientSelector
          onChange={option =>
            props.propertyChange({
              clientId: option?.value,
            })
          }
          value={props.client}
        />
      </FormRow>

      <FormRow controlId="number" label={t(tk.administration.accountNumber)}>
        <Form.Control
          type="text"
          value={props.bankAccount.number || ''}
          onChange={e =>
            props.propertyChange({ number: e.target.value || undefined })
          }
          onBlur={e =>
            props.propertyChange({
              number: formatAccountNumber(e.target.value) || undefined,
            })
          }
        />
      </FormRow>
      <FormRow controlId="bankName" label={t(tk.administration.bankName)}>
        <Form.Control
          type="text"
          value={props.bankAccount.bankName || ''}
          onChange={e => props.propertyChange({ bankName: e.target.value })}
        />
      </FormRow>
      <FormRow controlId="swiftbic" label={t(tk.administration.swift)}>
        <Form.Control
          type="text"
          value={props.bankAccount.swiftBIC || ''}
          onChange={e => props.propertyChange({ swiftBIC: e.target.value })}
        />
      </FormRow>
      <FormRow
        controlId="vatNumber"
        label={t(tk.administration.VATAccountNumber)}
      >
        <Form.Control
          type="text"
          value={props.bankAccount.vatNumber || ''}
          onChange={e =>
            props.propertyChange({ vatNumber: e.target.value || '' })
          }
          onBlur={e =>
            props.propertyChange({
              vatNumber: formatAccountNumber(e.target.value) || '',
            })
          }
        />
      </FormRow>
      <FormRow controlId="vatBankName" label={t(tk.administration.VATBankName)}>
        <Form.Control
          type="text"
          value={props.bankAccount.vatBankName || ''}
          onChange={e => props.propertyChange({ vatBankName: e.target.value })}
        />
      </FormRow>

      <FormRow controlId="active" label={t(tk.common.activeHe)}>
        <Form.Check
          type="checkbox"
          className="pt-2"
          checked={props.bankAccount.active ?? true}
          onChange={e => props.propertyChange({ active: e.target.checked })}
        />
      </FormRow>
    </Form>
  );
};
