import React, { useMemo } from 'react';

import {
  CreateUpdateSalesInvoicePositionDto,
  GetSalesInvoiceDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import numberFormatter from '../../../common/numberFormatter';
import { CurrencyIsoTypeEnum } from '../../../common/pokCore/validation/enums';
import mathUtils from '../../../utils/mathUtils';
import { getMainCurrencySymbol } from '../../../utils/currencyFormatUtils';
import { useTranslation } from '../../../common/hooks/useTranslation';

import {
  calculatePositionCurrencySum,
  calculatePositionGrossSum,
  calculatePositionNetSum,
  calculateSumToInvoice,
} from './calculateSalesInvoiceAmountsUtils';

const SalesInvoicePositionsSumRow = ({
  positions,
  salesInvoice,
  parentAmount,
}: {
  positions: CreateUpdateSalesInvoicePositionDto[];
  salesInvoice?: GetSalesInvoiceDto;
  parentAmount?: string;
}) => {
  const { t, tk } = useTranslation('fvsLng');
  const mainCurrency = getMainCurrencySymbol();
  const isForeignInvoice = salesInvoice?.currency !== mainCurrency;
  const netSum = useMemo(() => calculatePositionNetSum(positions), [positions]);
  const grossSum = useMemo(
    () => calculatePositionGrossSum(positions),
    [positions],
  );
  const sumToInvoice = useMemo(
    () => calculateSumToInvoice(salesInvoice?.invoiceEstimateItems || []),
    [salesInvoice],
  );
  const currencySum = useMemo(
    () => calculatePositionCurrencySum(positions, salesInvoice),
    [positions, salesInvoice],
  );

  return (
    <>
      <tr className="align-middle">
        <td colSpan={2}></td>
        <td>
          <b>{t(tk.common.sumUpperCase)}</b>
        </td>
        <td colSpan={2}></td>
        <td>{numberFormatter(netSum, true)}</td>
        <td></td>
        <td>{numberFormatter(grossSum, true)}</td>
        {isForeignInvoice && (
          <td>
            {numberFormatter(
              currencySum,
              true,
              salesInvoice?.currency as CurrencyIsoTypeEnum,
            )}
          </td>
        )}
      </tr>
      <tr className="align-middle">
        <td colSpan={2} />
        <td colSpan={3}>
          <b>{t(tk.finance.sumToInvoice)}</b>
        </td>
        <td>
          <b>{numberFormatter(sumToInvoice, true)}</b>
        </td>
        <td colSpan={2} />
        {isForeignInvoice && <td />}
      </tr>
      {salesInvoice?.parent?.id && (
        <tr className="align-middle">
          <td colSpan={2} />
          <td colSpan={3}>
            <b>{t(tk.finance.correctionAmount)}</b>
          </td>
          <td>
            <b>
              {numberFormatter(
                mathUtils.subtract(sumToInvoice, parentAmount),
                true,
              )}
            </b>
          </td>
          <td colSpan={2} />
        </tr>
      )}
    </>
  );
};

export default SalesInvoicePositionsSumRow;
