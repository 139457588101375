import React from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { SpecialInvoicingSearch } from '../../components/SpecialInvoicing/SpecialInvoicingSearch';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { SpecialInvoicingStatus } from '../../../common/pokCore/validation/enums';
import { useTranslation } from '../../../common/hooks/useTranslation';

enum SpecialInvoicingDecisionTabs {
  NEW = 'new',
  PENDING = 'pending',
  ALL = 'all',
}

export const SpecialInvoicingView: React.FC = () => {
  const nav = useNavigation();
  const pok = usePokCore();
  const { t, tk } = useTranslation('applicationLng');

  const onRowClick = (id: string, newWindow?: boolean) => {
    nav.specialInvoicingEditor(id, undefined, newWindow);
  };

  const handleAdd = () => {
    nav.specialInvoicingEditor('utworz');
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.app.specialInvoicingApplications)}
        </Card.Title>
        <Button className="mb-4" variant="outline-primary" onClick={handleAdd}>
          {t(tk.app.newApplication)}
        </Button>
        <TabsHistory
          activeKey={SpecialInvoicingDecisionTabs.NEW}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={SpecialInvoicingDecisionTabs.NEW}
            title={t(tk.app.waiting)}
            className="m-3"
          >
            <SpecialInvoicingSearch
              getData={(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              ) =>
                pok.specialInvoicing.getAllByPortion(
                  t,
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                  [SpecialInvoicingStatus.New],
                )
              }
              getCount={filterText =>
                pok.specialInvoicing.getAllCount(filterText, [
                  SpecialInvoicingStatus.New,
                ])
              }
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab
            eventKey={SpecialInvoicingDecisionTabs.PENDING}
            title={t(tk.app.waitingForCorrection)}
            className="m-3"
          >
            <SpecialInvoicingSearch
              getData={(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              ) =>
                pok.specialInvoicing.getAllByPortion(
                  t,
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                  [SpecialInvoicingStatus.ToBeCorrected],
                )
              }
              getCount={filterText =>
                pok.specialInvoicing.getAllCount(filterText, [
                  SpecialInvoicingStatus.ToBeCorrected,
                ])
              }
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab
            eventKey={SpecialInvoicingDecisionTabs.ALL}
            title={t(tk.app.all)}
            className="m-3"
          >
            <SpecialInvoicingSearch
              getData={(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              ) =>
                pok.specialInvoicing.getAllByPortion(
                  t,
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                )
              }
              getCount={filterText =>
                pok.specialInvoicing.getAllCount(filterText)
              }
              onRowClick={onRowClick}
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
