/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateExcelConfigHeaderDto
 */
export interface CreateUpdateExcelConfigHeaderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigHeaderDto
     */
    pokColumnName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigHeaderDto
     */
    pokColumnNamePl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigHeaderDto
     */
    pokColumnNameEn: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigHeaderDto
     */
    excelColumnName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExcelConfigHeaderDto
     */
    excelConfigId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExcelConfigHeaderDto
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateExcelConfigHeaderDto
     */
    order?: number;
}

/**
 * Check if a given object implements the CreateUpdateExcelConfigHeaderDto interface.
 */
export function instanceOfCreateUpdateExcelConfigHeaderDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pokColumnName" in value;
    isInstance = isInstance && "pokColumnNamePl" in value;
    isInstance = isInstance && "pokColumnNameEn" in value;
    isInstance = isInstance && "excelColumnName" in value;
    isInstance = isInstance && "excelConfigId" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function CreateUpdateExcelConfigHeaderDtoFromJSON(json: any): CreateUpdateExcelConfigHeaderDto {
    return CreateUpdateExcelConfigHeaderDtoFromJSONTyped(json, false);
}

export function CreateUpdateExcelConfigHeaderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExcelConfigHeaderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pokColumnName': json['pokColumnName'],
        'pokColumnNamePl': json['pokColumnNamePl'],
        'pokColumnNameEn': json['pokColumnNameEn'],
        'excelColumnName': json['excelColumnName'],
        'excelConfigId': json['excelConfigId'],
        'active': json['active'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function CreateUpdateExcelConfigHeaderDtoToJSON(value?: CreateUpdateExcelConfigHeaderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pokColumnName': value.pokColumnName,
        'pokColumnNamePl': value.pokColumnNamePl,
        'pokColumnNameEn': value.pokColumnNameEn,
        'excelColumnName': value.excelColumnName,
        'excelConfigId': value.excelConfigId,
        'active': value.active,
        'order': value.order,
    };
}

