/*eslint max-lines-per-function: ["error", 240]*/
/*eslint complexity: ["error", 27]*/
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import {
  CreateUpdateCompanyDto,
  CreateUpdateCompanyDtoLanguageEnum,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow from '../../../app/components/FormRow';
import {
  PersonalLanguageType,
  PersonalLanguageTypeObj,
  TemplateTypeEnum,
  TemplateTypeObj,
} from '../../../common/pokCore/validation/enums';
import { UploadFile } from '../../../common/components/File/UploadFile';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import CurrencySelector from '../Selects/CurrencySelector';
import { LanguageSelector } from '../Selects/LanguageSelector';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface CompanyEditorFormProps {
  id?: string;
  company: CreateUpdateCompanyDto;
  show?: boolean;
  creatingCompany?: boolean;
  propertyChange?: (obj: Partial<CreateUpdateCompanyDto>) => void;
}

export const CompanyEditorForm: React.FC<CompanyEditorFormProps> = props => {
  const { t, tk } = useTranslation('administrationLng');
  const propertyChange = (obj: Partial<CreateUpdateCompanyDto>) => {
    if (props.propertyChange) {
      props.propertyChange(obj);
    }
  };

  const setFile = (logo: string) => {
    propertyChange({ logo: logo });
  };

  const getAllPersonalLanguages = useCallback(() => {
    const arr = Object.keys(PersonalLanguageTypeObj).map(name => {
      return {
        value: PersonalLanguageType[name as keyof typeof PersonalLanguageType],
        label: t(
          PersonalLanguageTypeObj[name as keyof typeof PersonalLanguageTypeObj],
        ),
      };
    });
    return arr;
  }, [t]);

  const templateTypeOptions = useMemo(() => {
    return Object.entries(TemplateTypeEnum).map(([key, value]) => ({
      value: key as TemplateTypeEnum,
      label: t(TemplateTypeObj[value]),
    }));
  }, [t]);

  const getSelectedLanguage = useCallback(() => {
    return getAllPersonalLanguages().filter(
      item => item.value === props.company.personalLanguage,
    );
  }, [props.company.personalLanguage, getAllPersonalLanguages]);

  const [selectedLanguage, setSelectedLanguage] = useState(
    getSelectedLanguage(),
  );

  useEffect(() => {
    const lang = getSelectedLanguage();
    setSelectedLanguage(lang);
  }, [props.company.personalLanguage, getSelectedLanguage]);

  return (
    <>
      <Form className="d-grid gap-3">
        <FormRow controlId="name" label={t(tk.common.name)}>
          <Form.Control
            type="text"
            value={props.company?.name || ''}
            onChange={e => propertyChange({ name: e.target.value })}
          />
        </FormRow>

        <FormRow controlId="shortname" label={t(tk.common.shortname)}>
          <Form.Control
            type="text"
            value={props.company?.shortname || ''}
            onChange={e => propertyChange({ shortname: e.target.value })}
          />
        </FormRow>

        <FormRow
          controlId="personalLanguage"
          label={t(tk.administration.personalLanguage)}
        >
          <Select
            options={getAllPersonalLanguages()}
            value={selectedLanguage}
            onChange={options =>
              propertyChange({ personalLanguage: options?.value })
            }
            placeholder={t(tk.component.pointOut)}
          />
        </FormRow>
        <FormRow
          controlId="applicationType"
          label={t(tk.administration.invoiceTemplate)}
        >
          <Select
            value={
              props.company?.templateType
                ? templateTypeOptions.find(
                    ({ value }) => props.company?.templateType === value,
                  )
                : null
            }
            options={templateTypeOptions}
            onChange={option =>
              propertyChange({
                templateType: option?.value,
              })
            }
            placeholder={t(tk.component.pointOut)}
          />
        </FormRow>
        <FormRow controlId="currency" label={t(tk.common.currency)}>
          <CurrencySelector
            value={props.company?.mainCurrencyId || ''}
            onChange={option =>
              propertyChange({ mainCurrencyId: option.value })
            }
          />
        </FormRow>
        <FormRow controlId="language" label={t(tk.administration.language)}>
          <LanguageSelector
            value={props.company.language}
            onChange={options =>
              propertyChange({
                language: options.value as CreateUpdateCompanyDtoLanguageEnum,
              })
            }
            withName={true}
          />
        </FormRow>
        <FormRow controlId="delay" label={t(tk.prLang.maxDelay)}>
          <Form.Control
            type="number"
            min={0}
            value={props.company?.projectMaxDaysDelay ?? ''}
            onChange={e =>
              propertyChange({
                projectMaxDaysDelay: parseInt(e.target.value, 10),
              })
            }
          />
        </FormRow>

        <FormRow controlId="order" label={t(tk.administration.noFull)}>
          <Form.Control
            type="number"
            min={0}
            value={props.company?.orderField ?? ''}
            onChange={e =>
              propertyChange({
                orderField: parseInt(e.target.value, 10),
              })
            }
          />
        </FormRow>
        <FormRow
          controlId="invoicesEmail"
          label={t(tk.administration.emailInvoice)}
        >
          <Form.Control
            type="text"
            value={props.company?.invoicesEmail || ''}
            onChange={e => propertyChange({ invoicesEmail: e.target.value })}
          />
        </FormRow>
        <FormRow controlId="footer" label={t(tk.administration.footer)}>
          <DynamicTextarea
            onChange={e => propertyChange({ footer: e.target.value })}
            value={props.company?.footer || ''}
          />
        </FormRow>
        <FormRow
          controlId="footerInEnglish"
          label={t(tk.administration.footerEn)}
        >
          <DynamicTextarea
            onChange={e => propertyChange({ footerInEnglish: e.target.value })}
            value={props.company?.footerInEnglish || ''}
          />
        </FormRow>

        <FormRow controlId="color" label={t(tk.administration.color)}>
          <Form.Control
            type="color"
            value={props.company?.color || '#ffffff'}
            onChange={e => propertyChange({ color: e.target.value })}
          />
        </FormRow>

        <FormRow
          controlId="logo"
          label={t(tk.administration.logo)}
          className="mt-3"
        >
          <UploadFile
            file={props.company.logo}
            setFile={setFile}
            imageHeight={50}
            buttonText={t(tk.administration.uploadGraphicFile)}
          />
        </FormRow>
        <FormRow
          controlId="showMargin"
          label={t(tk.administration.showMargin)}
          className="mt-3"
        >
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={props.company.showMargin}
            onChange={e => propertyChange({ showMargin: e.target.checked })}
          />
        </FormRow>
        <FormRow controlId="active" label={t(tk.common.activeShe)}>
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={
              props.company?.active ||
              props.company?.active === undefined ||
              props.company?.active === null
                ? true
                : false
            }
            onChange={e => propertyChange({ active: e.target.checked })}
          />
        </FormRow>
      </Form>
    </>
  );
};
