/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import React from 'react';
import { Form } from 'react-bootstrap';

import {
  ParametersSchemaDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import DictionarySelector from '../Selects/DictionarySelector';
import ProfileFieldName from '../Estimates/ProfileFieldName';

interface PositionParametersProps {
  schema: ProfileSchemaDto;
  parameters: ParametersSchemaDto;
  lang: string;
  propertyChange?: (obj: Partial<ParametersSchemaDto>) => void;
}

export const PositionParameters: React.FC<PositionParametersProps> = props => {
  const schema = props.schema;
  const propertyChange = (obj: Partial<ParametersSchemaDto>) => {
    if (props.propertyChange) {
      props.propertyChange(obj);
    }
  };

  return (
    <>
      {/* INTERNET */}
      {schema?.purchaseFormInternet && (
        <ProfileFieldName
          controlId="purchaseFormInternet"
          schema={schema?.purchaseFormInternet}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Internet - forma zakupu"
            value={props.parameters?.purchaseFormInternet || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                purchaseFormInternet: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.categoryInternet && (
        <ProfileFieldName
          controlId="categoryInternet"
          schema={schema?.categoryInternet}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Internet - kategoria"
            value={props.parameters?.categoryInternet || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                categoryInternet: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.formatInternet && (
        <ProfileFieldName
          controlId="formatInternet"
          schema={schema?.formatInternet}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Internet - format"
            value={props.parameters?.formatInternet || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatInternet: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {/* INTERNET ST */}
      {schema?.purchaseFormInternetST && (
        <ProfileFieldName
          controlId="purchaseFormInternetST"
          schema={schema?.purchaseFormInternetST}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="InternetST - forma zakupu"
            value={props.parameters?.purchaseFormInternetST || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                purchaseFormInternetST: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.categoryInternetST && (
        <ProfileFieldName
          controlId="categoryInternetST"
          schema={schema?.categoryInternetST}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="InternetST - kategoria"
            value={props.parameters?.categoryInternetST || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                categoryInternetST: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.formatInternetST && (
        <ProfileFieldName
          controlId="formatInternetST"
          schema={schema?.formatInternetST}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="InternetST - format"
            value={props.parameters?.formatInternetST || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatInternetST: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}

      {/* LABCON */}
      {schema?.labconChannel && (
        <ProfileFieldName
          controlId="labconChannel"
          schema={schema?.labconChannel}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Labcon - kanał"
            value={props.parameters?.labconChannel || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                labconChannel: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.categoryLabcon && (
        <ProfileFieldName
          controlId="categoryLabcon"
          schema={schema?.categoryLabcon}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Labcon - kategoria"
            value={props.parameters?.categoryLabcon || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                categoryLabcon: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.service && (
        <ProfileFieldName
          controlId="service"
          schema={schema?.service}
          lang={props.lang}
        >
          <Form.Control
            type="text"
            value={props.parameters?.service ?? ''}
            onChange={e =>
              propertyChange({
                service: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.action && (
        <ProfileFieldName
          controlId="action"
          schema={schema?.action}
          lang={props.lang}
        >
          <Form.Control
            type="text"
            value={props.parameters?.action ?? ''}
            onChange={e =>
              propertyChange({
                action: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {/* {schema?.actionGroup && (
        <ProfileFieldName
          controlId="actionGroup"
          schema={schema?.actionGroup}
        >
          <CreatableClearableSelector
            value={props.attributes?.actionGroup ?? ''}
            onChange={option =>
              propertyChange({
                actionGroup: option ? (option as Option).schema : option,
              })
            }
            provider={(text: string) =>
              pok.estimateItems.getActionGroupsOptionsByTextByCompanyAndBudget(
                text,
                props.project?.company?.id || '',
                props.project?.purchaser.budget.id || '',
              )
            }
          />
        </ProfileFieldName>
      )} */}
      {/* OFFLINE */}
      {schema?.numberOfMedia && (
        <ProfileFieldName
          controlId="numberOfMedia"
          schema={schema?.numberOfMedia}
          lang={props.lang}
        >
          <Form.Control
            type="number"
            min={0}
            value={props.parameters?.numberOfMedia || undefined}
            onChange={e =>
              propertyChange({
                numberOfMedia: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}

      {schema?.technologyDictionary && (
        <ProfileFieldName
          controlId="technologyDictionary"
          schema={schema?.technologyDictionary}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="OOH - technologia"
            value={props.parameters?.technologyDictionary || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                technologyDictionary: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.regionDictionary && (
        <ProfileFieldName
          controlId="regionDictionary"
          schema={schema?.regionDictionary}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="OOH - rejon"
            value={props.parameters?.regionDictionary || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                regionDictionary: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.additionalDescription && (
        <ProfileFieldName
          controlId="additionalDescription"
          schema={schema?.additionalDescription}
          lang={props.lang}
        >
          <Form.Control
            type="text"
            value={props.parameters?.additionalDescription ?? ''}
            onChange={e =>
              propertyChange({
                additionalDescription: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {/* PRASA */}
      {schema?.formatPress && (
        <ProfileFieldName
          controlId="formatPress"
          schema={schema?.formatPress}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Prasa - format"
            value={props.parameters?.formatPress || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatPress: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {/* RADIO */}
      {schema?.formatRadio && (
        <ProfileFieldName
          controlId="formatRadio"
          schema={schema?.formatRadio}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Radio - format"
            value={props.parameters?.formatRadio || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                formatRadio: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.emissionTypeRadio && (
        <ProfileFieldName
          controlId="emissionTypeRadio"
          schema={schema?.emissionTypeRadio}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Radio - typ emisji"
            value={props.parameters?.emissionTypeRadio || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                emissionTypeRadio: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {/* KINO */}
      {schema?.emissionTypeCinema && (
        <ProfileFieldName
          controlId="emissionTypeCinema"
          schema={schema?.emissionTypeCinema}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Kino - typ emisji"
            value={props.parameters?.emissionTypeCinema || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                emissionTypeCinema: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {/* PROFILE PARAMS */}
      {schema?.medium && (
        <ProfileFieldName
          controlId="medium"
          schema={schema?.medium}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="Zagraniczne - medium"
            value={props.parameters?.medium || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                medium: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.ownName && (
        <ProfileFieldName
          controlId="ownName"
          schema={schema?.ownName}
          lang={props.lang}
        >
          <Form.Control
            type="text"
            value={props.parameters?.ownName ?? ''}
            onChange={e =>
              propertyChange({
                ownName: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.isUpSell && (
        <ProfileFieldName
          controlId="isUpSell"
          schema={schema?.isUpSell}
          lang={props.lang}
        >
          <Form.Check
            type="checkbox"
            className="pt-2"
            checked={props.parameters?.isUpSell}
            onChange={e => propertyChange({ isUpSell: e.target.checked })}
          />
        </ProfileFieldName>
      )}
      {/* TV */}
      {schema?.copySize && (
        <ProfileFieldName
          controlId="copySize"
          schema={schema?.copySize}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="TV - długość kopii"
            value={props.parameters?.copySize || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                copySize: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.orderNumber && (
        <ProfileFieldName
          controlId="orderNumber"
          schema={schema?.orderNumber}
          lang={props.lang}
        >
          <Form.Control
            type="text"
            value={props.parameters?.orderNumber ?? ''}
            onChange={e =>
              propertyChange({
                orderNumber: e.target.value,
              })
            }
          />
        </ProfileFieldName>
      )}
      {schema?.purchaseMethod && (
        <ProfileFieldName
          controlId="purchaseMethod"
          schema={schema?.purchaseMethod}
          lang={props.lang}
        >
          <DictionarySelector
            dictionary="TV - sposób zakupu"
            value={props.parameters?.purchaseMethod || ''}
            clearable={true}
            onChange={option =>
              propertyChange({
                purchaseMethod: option.value,
              })
            }
          />
        </ProfileFieldName>
      )}
    </>
  );
};
