import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { FilterInvoiceTypeEnum } from '../../../common/pokCore/validation/enums';
import { ClientWithoutSymphonyWithInvoiceSearch } from '../../components/Clients/ClientWithoutSymphonyWithInvoiceSearch';
import { ClientWithSymphonyCodeSearch } from '../../components/Clients/ClientWithSymphonyCodeSearch';
import { useTranslation } from '../../../common/hooks/useTranslation';

enum ClientsWithSymphonyTabs {
  ALL = 'all',
  WITH_SELL_INVOICE = 'withSellInvoice',
  WITH_PURCHASE_INVOICE = 'withPurchaseInvoice',
}

export const ClientsWithSymphonyView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const { t, tk } = useTranslation('fvsLng');

  const handleClick = async (id: string, newWindow?: boolean) => {
    nav.clientSymphonyEditor(id, newWindow);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.symphony.clientsWithSymphony)}
        </Card.Title>
        <TabsHistory
          activeKey={ClientsWithSymphonyTabs.ALL}
          id="clientsWithSymphonyView"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={ClientsWithSymphonyTabs.ALL}
            title={t(tk.symphony.clients)}
            className="m-3"
          >
            <ClientWithSymphonyCodeSearch
              getData={pok.clients.getAllWithSymphonyByPortion}
              getCount={pok.clients.getAllWithSymphonyCount}
              onRowClick={handleClick}
            />
          </Tab>
          <Tab
            eventKey={ClientsWithSymphonyTabs.WITH_SELL_INVOICE}
            title={t(tk.symphony.clientsNoSymphonyFVS)}
            className="m-3"
          >
            <ClientWithoutSymphonyWithInvoiceSearch
              invoiceType={FilterInvoiceTypeEnum.SalesInvoice}
              onRowClick={handleClick}
            />
          </Tab>
          <Tab
            eventKey={ClientsWithSymphonyTabs.WITH_PURCHASE_INVOICE}
            title={t(tk.symphony.clientsNoSymphonyFVZ)}
            className="m-3"
          >
            <ClientWithoutSymphonyWithInvoiceSearch
              invoiceType={FilterInvoiceTypeEnum.PurchaseInvoice}
              onRowClick={handleClick}
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
