import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { useTranslation } from '../../../common/hooks/useTranslation';
import { NewDateRangePicker } from '../../../app/components/NewDateRangePicker';

import { FiltersParams } from './usePurchaseInvoiceEstimateItemsFilters';

interface PurchaseInvoiceEstimateItemPositionsFiltersProps {
  filters: FiltersParams;
  handleChangeFilters: (filters: Partial<FiltersParams>) => void;
}

export const PurchaseInvoiceEstimateItemPositionsFilters: React.FC<
  PurchaseInvoiceEstimateItemPositionsFiltersProps
> = props => {
  const { t, tk } = useTranslation('fvzLng');
  return (
    <>
      <Row className="p-2">
        <Form.Group as={Row} controlId="monthFilter">
          <Form.Label column sm="2">
            {t(tk.finance.monthFilter)}
          </Form.Label>
          <Col>
            <div className="small-calendar">
              <NewDateRangePicker
                onChange={value => {
                  if (value) {
                    value = value as [Date | null, Date | null];
                    return props.handleChangeFilters({
                      dateFrom: value[0],
                      dateTo: value[1],
                    });
                  }

                  return props.handleChangeFilters({
                    dateFrom: null,
                    dateTo: null,
                  });
                }}
                value={
                  props.filters.dateFrom && props.filters.dateTo
                    ? [props.filters.dateFrom, props.filters.dateTo]
                    : null
                }
              />
            </div>
          </Col>
        </Form.Group>
      </Row>
      <Row className="p-2">
        <Form.Group as={Row} controlId="monthFilter">
          <Form.Label column sm="2">
            {t(tk.finance.showSettled)}
          </Form.Label>
          <Col>
            <Form.Check
              type="checkbox"
              className="pt-2"
              checked={props.filters.showSettled}
              onChange={e =>
                props.handleChangeFilters({ showSettled: e.target.checked })
              }
            />
          </Col>
        </Form.Group>
      </Row>
      <Row className="p-2">
        <Form.Group as={Row} controlId="specialTeamFilter">
          <Form.Label column sm="2">
            {t(tk.finance.showSpecialTeam)}
          </Form.Label>
          <Col>
            <Form.Check
              type="checkbox"
              className="pt-2"
              checked={props.filters.showSpecialTeam}
              onChange={e =>
                props.handleChangeFilters({ showSpecialTeam: e.target.checked })
              }
            />
          </Col>
        </Form.Group>
      </Row>
      <hr />
    </>
  );
};
