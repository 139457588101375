export default {
  finance: {
    purchaseInvoices: 'Purchase invoices',
    FVZ: 'FVZ',
    readyForInvoices: 'Ready for invoices',
    corrected: 'Corrected',
    accountToInvoice: 'Account to invoice',
    lastModification: 'Last modification of position links',
    imports: 'Imports',
    googleImport: 'Google purchase invoice import',
    googleImportDesc:
      'Import purchase invoices based on a file from Google and a directory with invoice prints',
    importFvz: 'Purchase invoices (FVZ) Import',
    invoice: 'Invoice',
    fileNotFound: 'File not found',
    finishDownload: 'Data download completed.',
    userInCompanyContext:
      'The user must be in the context of the company where purchase invoices will be received',
    assumptions: 'Assumptions',
    assumption1: 'The first row in the Excel file contains the header row',
    assumption2:
      'If the contractor’s country is Poland, invoices are imported as domestic; otherwise, as foreign',
    assumption3: 'The amount field must be recognized as a number by Excel',
    assumption4: 'Three date fields should be recognized as dates by Excel',
    client: 'Biller (contractor)',
    excelWithGoogleInvoices: 'Excel file containing data on Google invoices',
    directoryWithGoogleInvoices:
      'Directory containing invoices issued by Google',
    chooseDirectory: 'Choose directory',
    errorSelectData:
      'Please specify the biller, an Excel file with data, and a directory with attachments.',
    import: 'Import',
    askImport: 'Are you sure you want to import the invoices?',
    dataDownload: 'Downloading data. Please wait...',
    archive: 'ARCHIVE',
    newFvz: 'New purchase invoice',
    fvz: 'Purchase invoice',
    for: 'for',
    packageWithNumber: 'in package no.',
    revertFromSent: 'Reverted from sent state',
    tvInvoice: 'TV invoice',
    purchaseInvoicesToBeClarified: 'Purchase invoices to be clarified',
    hasNegativeAmounts:
      'The invoice binding amount exceeds the position amount {{estimate}}. Are you sure you want to save the invoice?',
    hasMoreThenAvailableAmounts:
      'The invoice binding amount exceeds the unsettled position amount {{estimate}}. Are you sure you want to save the invoice?',
    monthFilter: 'Filter by month',
    showSettled: 'Show settled positions',
    showSpecialTeam: 'Show positions matching specialist teams',
    orderNumber: 'Order number',
    purchaseNetTotal: 'Purchase net total',
    notAssignedAmount: 'Unsettled amount',
    fillAllFields: 'Fill in all visible fields with unsettled amount',
    fillNotAssignedAmount: 'Fill with unsettled amount',
    invoiceAmountUppercase: 'INVOICE AMOUNT',
    invoiceAmount: 'Invoice amount',
    unsettledAmountUppercase: 'UNSETTLED AMOUNT',
    unsettledAmount: 'Unsettled amount',
    nip: 'VAT ID',
    invoiceNumber: 'Invoice number',
    invoiceType: 'Invoice type',
    saveAndConfirm: 'Save and confirm',
    askDeleteFvz:
      'Are you sure you want to delete the displayed purchase invoice?',
    askChangeStatus: `Are you sure you want to change the invoice status to 'Entered'?`,
    undoState: 'Undo state',
    saveAndStepForward: 'Save and move forward',
    stepForward: 'Move forward',
    paymentDate: 'Payment date',
    netAmount: 'Net amount',
    vatRate: 'VAT rate',
    vatAmount: 'VAT amount',
    vat: 'VAT',
    askDeletePositions:
      'Are you sure you want to delete the selected position?',
    deletePosition: 'Delete position',
    addPosition: 'Add position',
    noPositions: 'No positions',
    importFvs: 'Import FVS',
    selectFvsToImport: 'Select sales invoice to import',
    yearOfSale: 'Year of sale',
    warningDifferenceCurrencyImport:
      'When importing an invoice from a company billed in a different currency, the invoice amounts will not be imported.',
    dataImportedFromInvoice: 'Data imported from sales invoice no.',
    editFvzPosition: 'Edit purchase invoice position',
    addFvzPosition: 'Add new purchase invoice position',
    choose: 'Choose...',
    forClient: 'for biller',
    toInvoice: 'to invoice',
    sumClientAmount: 'Biller’s purchase net total',
    sumClientAmountWithoutPosition: 'Biller’s unsettled amount',
    activeProfiles: 'Active profiles',
    created: 'Created by',
    countDaysFromCreation: 'Days since creation',
    noInvoices: 'No purchase invoices',
    tvInvoices: 'TV purchase invoices',
    unsettled: 'Unsettled',
    all: 'All',
    settled: 'Entered, settled',
    generated: 'Entered',
    teamsToClarify: 'Teams to clarify',
    fvzSysNumber: 'Purchase invoice with system no.',
    editNetAmount: 'Edit purchase net total',
    purchaseNet: 'Purchase net total',
    editTotalAmount: 'Edit total amount',
    exportFvz: 'Export purchase invoices',
    invoicesDomesticToSent: 'Domestic purchase invoices (FVZ) to send',
    createInvoicesDomesticToSent:
      'Create XML with domestic purchase invoices (FVZ)',
    invoicesForeignToSent: 'Foreign purchase invoices (FVZ) to send',
    createInvoicesForeignToSent:
      'Create XML with foreign purchase invoices (FVZ)',
    invoicesDomesticToSentAgain: 'Domestic purchase invoices (FVZ) to Resend',
    createInvoicesDomesticToSentAgain:
      'Create XML with domestic purchase invoices (FVZ) that have already been sent',
    invoicesForeignToSentAgain: 'Foreign purchase invoices (FVZ) to resend',
    createInvoicesForeignToSentAgain:
      'Create XML with foreign purchase invoices (FVZ) that have already been sent',
    packagesFVZ: 'Purchase invoices (FVZ) packages',
    oldest: 'oldest',
    createCountryXML: 'Create XML with domestic invoices',
    selected: 'selected',
    askCreatePackage: 'Are you sure you want to create an invoice package?',
    invoicesPackage: 'XML package with invoices',
    cantDownloadPackage: 'Cannot download the package.',
    zipPackage: 'ZIP package with invoice prints',
    generatedBy: 'Generated by',
    generatedDate: 'Generation date',
    invoicesType: 'Invoice type',
    repeat: 'Sent again',
    packageCount: 'Number of invoices in the package',
    downloadPackage: 'Download XML package',
    downloadPdfs: 'Download prints',
    downloadCopies: 'Download copies',
    noPackages: 'No purchase invoice packages in the system',
    notes: 'Notes',
    general: 'Basic data',
    openNewWindow: 'Open invoice in a new window',
    sumExceedsInvoiceAmount:
      'The sum of recorded assignments exceeds the total invoice amount.',
    unwrittenUppercase: 'UNSAVED',
    invoicesInPackage:
      'Invoices included in package with system no. {{number}} from {{date}}',
    invoicingTeams: 'Invoicing teams',
  },
} as const;
