import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  CreateUpdatePurchaseInvoiceDto,
  CreateUpdatePurchaseInvoiceTeamsAndProjectsDto,
  GetProjectExtendedDto,
  GetPurchaseInvoiceExtendedDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  convertPurchaseInvoiceEstimateItems,
  validateTeamsProjectsTab,
} from '../../../common/pokCore/contexts/PurchaseInvoiceContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { usePokCore } from '../../../common/hooks/usePokCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import Button from '../../../common/components/Button/Button';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { checkDataChanged } from './checkDataChanged';
import { PurchaseInvoiceTabsEnum } from './PurchaseInvoiceTabsEnum';
import useChangeTab from './useChangeTab';
import { PurchaseInvoiceEditorFormProjects } from './PurchaseInvoiceProjects';
import { PurchaseInvoiceTeamsForm } from './PurchaseInvoiceTeamsForm';

interface PurchaseInvoiceEditorFormTeamsAndProjectsProps {
  teamsAndProjects: CreateUpdatePurchaseInvoiceTeamsAndProjectsDto;
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  purchaseInvoice: CreateUpdatePurchaseInvoiceDto;
  setNotSavedTab: Dispatch<SetStateAction<PurchaseInvoiceTabsEnum | undefined>>;
  handleRefresh: () => Promise<void>;
  readOnly: boolean;
}

export const PurchaseInvoiceTeamsAndProjectsTab: React.FC<
  PurchaseInvoiceEditorFormTeamsAndProjectsProps
> = props => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('fvzLng');

  const handleChangeTab = useChangeTab<PurchaseInvoiceTabsEnum>();
  const [projects, setProjects] = useState<GetProjectExtendedDto[]>([]);
  const [teamsAndProjects, setTeamsAndProjects] = useState(
    props.teamsAndProjects,
  );
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const id = props.extendedPurchaseInvoice?.id || '';

  const handleTeamsAndProjectsSave = async () => {
    await Promise.all([
      pok.purchaseInvoices.updateTeamsAndProjects(id, teamsAndProjects),
      pok.purchaseInvoices.updateEstimateItems(id, {
        purchaseInvoiceEstimateItems: convertPurchaseInvoiceEstimateItems(
          props.extendedPurchaseInvoice,
        ).filter(
          ({ projectId }) =>
            projectId && teamsAndProjects.projectIds.includes(projectId),
        ),
      }),
    ]);
  };

  const handleGoNext = () =>
    handleChangeTab(PurchaseInvoiceTabsEnum.ESTIMATE_ITEM_POSITIONS);

  const editTeamsAndProjects = async () => {
    const status = await validateTeamsProjectsTab(teamsAndProjects);
    if (status.valid) {
      setIsLoading(true);
      try {
        await handleTeamsAndProjectsSave();
        await props.handleRefresh();
        setSaveErrors([]);
        void handleGoNext();
        notifications.saveCompleted();
      } catch (response) {
        setSaveErrors(await responseErrors(response as ResponseError));
      } finally {
        setIsLoading(false);
      }
    } else {
      setSaveErrors(status.errors);
    }
  };

  const propertyChange = (
    obj: Partial<CreateUpdatePurchaseInvoiceTeamsAndProjectsDto> & {
      projects?: GetProjectExtendedDto[];
    },
  ) => {
    let updatedTeamsAndProjects = { ...teamsAndProjects, ...obj };
    if (obj.projects) {
      const projectIds = obj.projects.map(({ id }) => id);
      setProjects(obj.projects);
      updatedTeamsAndProjects = { ...teamsAndProjects, projectIds };
    }

    checkDataChanged(
      props.teamsAndProjects,
      updatedTeamsAndProjects,
      PurchaseInvoiceTabsEnum.TEAM_PROJECTS,
      props.setNotSavedTab,
    );

    setTimeout(() => {
      setTeamsAndProjects(
        updatedTeamsAndProjects as CreateUpdatePurchaseInvoiceTeamsAndProjectsDto,
      );
    }, 1);
  };

  useEffect(() => {
    if (id) {
      pok.projects
        .getAllForPurchaseInvoiceByPurchaseInvoice(id)
        .then(setProjects)
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [id, pok.projects, notifications, props.teamsAndProjects]);

  useEffect(() => {
    setTeamsAndProjects(props.teamsAndProjects);
  }, [props.teamsAndProjects]);

  return (
    <>
      <PurchaseInvoiceTeamsForm
        {...props}
        teamsAndProjects={teamsAndProjects}
        propertyChange={propertyChange}
      />
      <br />
      <PurchaseInvoiceEditorFormProjects
        projects={projects}
        teamsAndProjects={teamsAndProjects}
        readOnly={props.readOnly}
        extendedPurchaseInvoice={props.extendedPurchaseInvoice}
        propertyChange={propertyChange}
      />
      <ValidationAlert
        show={!!saveErrors.length}
        errors={saveErrors}
        className="m-0 mt-4"
      />
      <div className="d-flex justify-content-end">
        <Button
          className="ms-2 mt-4"
          variant="primary"
          onClick={props.readOnly ? handleGoNext : editTeamsAndProjects}
          isLoading={isLoading}
        >
          {props.readOnly
            ? t(tk.finance.stepForward)
            : t(tk.finance.saveAndStepForward)}
        </Button>
      </div>
    </>
  );
};
