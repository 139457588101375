import React, { useState, useRef, useEffect } from 'react';
import { Button, Alert, CloseButton, Overlay, Popover } from 'react-bootstrap';
import * as Icons from 'react-bootstrap-icons';
import { useInView } from 'react-cool-inview';

import DynamicTextarea from '../../../app/components/DynamicTextarea';
import * as MemoContext from '../../../common/pokCore/contexts/MemoContext';
import { useMemosProvider } from '../../../common/hooks/useMemosProvider';
import { useNavigation } from '../../../common/navigation';
import { useAuth } from '../../../common/hooks/useAuth';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { MemoCard } from './MemoCard';
import SubjectDescription from './SubjectDescription';
import MemosLoadingSpinner from './MemosLoadingSpinner';

export const ThreadContent = (props: {
  variant: 'userMemos' | 'subjectMemos';
  threadId: string;
  subject?: MemoContext.SubjectType;
  onQuickMemo: (content: string) => void;
  onClose?: () => void;
}) => {
  const nav = useNavigation();
  const auth = useAuth();
  const { t, tk } = useTranslation('commonLng');
  const memosProvider = useMemosProvider();
  const [memoContent, setMemoContent] = useState('');
  const [showError, setShowError] = useState(false);
  const validationDiv = useRef<HTMLDivElement>(null);
  const [subscribe, unsubscribe] = [
    memosProvider.subscribeListening,
    memosProvider.unsubscribeListening,
  ];
  const { observe } = useInView({
    onEnter: ({ unobserve }) => {
      unobserve();
      memosProvider.refreshMemos(props.threadId, 'more', observe);
    },
  });
  const thereIsMore =
    memosProvider.threadMemos &&
    memosProvider.threadMemos.length > 0 &&
    !memosProvider.threadMemos[memosProvider.threadMemos.length - 1].first;

  useEffect(() => {
    subscribe(props.threadId);
    return () => {
      unsubscribe();
    };
  }, [props.threadId, subscribe, unsubscribe]);

  return (
    <>
      {props.subject && (
        <Alert variant="primary" className=" d-md-none d-lg-flex mb-2">
          <div className="flex-fill">
            <SubjectDescription subject={props.subject} maxlen={200} />
          </div>
          {props.variant === 'subjectMemos' ? (
            <CloseButton onClick={props.onClose} />
          ) : (
            <Button
              variant="outline-primary"
              onClick={() => {
                if (props.onClose) {
                  props.onClose();
                }
                MemoContext.navigateTo(nav, props.subject, auth);
              }}
            >
              {t(tk.button.view)}
            </Button>
          )}
        </Alert>
      )}
      <div className="flex-fill d-flex flex-column-reverse overflow-auto">
        {memosProvider.threadMemos.length === 0 && (
          <Alert variant="success">
            <Alert.Heading>{t(tk.memo.noMemos)}</Alert.Heading>
            <hr />
            <p className="mb-0">
              {t(tk.memo.information, {
                subject: MemoContext.polishAblative(t, tk, props.subject),
              })}
            </p>
          </Alert>
        )}
        {memosProvider.threadMemos.map(memo => (
          <MemoCard key={memo.id} memo={memo} />
        ))}
        {thereIsMore && (
          <div className="d-flex loading-spinner-height" ref={observe}>
            <MemosLoadingSpinner variant="success" />
          </div>
        )}
        <div
          className="flex-fill"
          data-comment="keeps messages down with a scrollbar if needed"
        />
      </div>
      <div className="d-flex justify-content-between mt-2">
        <div className="m-auto">
          <Icons.ChatLeft />
        </div>
        <div className="flex-fill mx-2" ref={validationDiv}>
          <DynamicTextarea
            placeholder={t(tk.memo.contentPlhdr)}
            className="max-rows-5"
            value={memoContent}
            onChange={e => setMemoContent(e.target.value)}
          />
        </div>
        <Button
          variant="outline-primary"
          onClick={() => {
            if (!memoContent || !memoContent.trim()) {
              setShowError(true);
              return;
            }
            props.onQuickMemo(memoContent);
            setShowError(false);
            setMemoContent('');
          }}
        >
          <Icons.SendFill />
        </Button>
      </div>
      <Overlay
        target={validationDiv}
        show={showError}
        placement="bottom"
        rootClose
        onHide={() => setShowError(false)}
      >
        <Popover>
          <Popover.Body className="text-danger fw-bold">
            {t(tk.memo.noEmptyMessage)}
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};
