import saveAs from 'file-saver';
import type { TFunction } from 'i18next';

import { GetPackageInvoicesXMLDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  InvoiceTypeEnum,
  PackageTypeEnum,
} from '../../../common/pokCore/validation/enums';
import momencik from '../../../common/momencik';
import { TKeys } from '../../../translations';

export const downloadZIP = async (
  t: TFunction<'fvsLng', 'fvsLng'>,
  tk: TKeys<'fvsLng'>,
  packageXML: GetPackageInvoicesXMLDto,
  zip: object,
) => {
  let fileName =
    packageXML.packageType === PackageTypeEnum.Purchase
      ? `${t(tk.finance.export)}_FVZ`
      : `${t(tk.finance.export)}_FVS`;

  fileName =
    packageXML.invoiceType === InvoiceTypeEnum.Domestic
      ? `${fileName}_${t(tk.finance.domestic)}`
      : `${fileName}_${t(tk.finance.foreign)}`;

  fileName = `${fileName}(${packageXML.systemNumber})_${momencik(
    packageXML.creationTs,
    'YYYY-MM-DD_HH-mm-ss',
  )}.zip`;

  if (zip) {
    const blob = new Blob([Buffer.from(zip as Buffer)], {
      type: 'application/zip',
    });
    saveAs(blob, fileName);
  }
};
