import React from 'react';
import { Button, Card } from 'react-bootstrap';

import {
  CreateUpdateExcelDto,
  GetExcelAndPokDataDto,
  GetExcelDataDto,
  GetResultsDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { ExcelImportSummary } from '../ExcelConfig/ExcelImportSummary';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ExcelPreviewData } from './ExcelPreviewData';
import { ExcelImportForm } from './ExcelImportForm';
import { PreviewImportData } from './ExcelCompareData';

interface ExcelImportStepsProps {
  step: number;
  waiting: boolean;
  excelData: GetExcelDataDto | undefined;
  compareData: GetExcelAndPokDataDto | undefined;
  results: GetResultsDto | undefined;
  handleGetExcelData: (excel: CreateUpdateExcelDto) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  handleCompareExcelAndPOK: (excel: GetExcelDataDto) => void;
  handleImportExcel: (data: GetExcelAndPokDataDto) => void;
  setStartStep: () => void;
}

export const ExcelImportSteps: React.FC<ExcelImportStepsProps> = props => {
  const { t, tk } = useTranslation('excelImportLng');
  if (props.waiting) {
    return (
      <div className="">
        <h2 className="text-center pt-5">
          {props.step === 1
            ? t(tk.import.loadingData)
            : props.step === 2
              ? t(tk.import.comparingData)
              : t(tk.import.importingData)}
        </h2>
        <Waiting />
      </div>
    );
  }

  switch (props.step) {
    case 1:
      return <ExcelImportForm onImport={props.handleGetExcelData} />;
    case 2:
      return (
        <>
          {props.excelData ? (
            <ExcelPreviewData
              data={props.excelData}
              handleCompareExcelAndPOK={props.handleCompareExcelAndPOK}
            />
          ) : (
            <div>{t(tk.common.noData)}</div>
          )}
          <ConfirmationButton
            variant="outline-primary"
            onOK={() => props.setStartStep()}
            confirmation={t(tk.import.askCancelImport)}
          >
            {t(tk.import.newImport)}
          </ConfirmationButton>
        </>
      );
    case 3:
      return (
        <>
          {props.compareData ? (
            <PreviewImportData
              data={props.compareData}
              handleImportExcel={props.handleImportExcel}
            />
          ) : (
            <div>{t(tk.common.noData)}</div>
          )}
          <ConfirmationButton
            variant="outline-primary"
            onOK={() => props.setStartStep()}
            confirmation={t(tk.import.askCancelImport)}
            className="mt-5"
          >
            {t(tk.import.newImport)}
          </ConfirmationButton>
        </>
      );
    case 4:
      return (
        <>
          <Card.Title className="pb-3">{t(tk.import.importSummary)}</Card.Title>
          <ExcelImportSummary results={props.results} />
          <Button
            variant="outline-primary"
            onClick={() => props.setStartStep()}
            className="mt-5"
          >
            {t(tk.import.newImport)}
          </Button>
        </>
      );
    default:
      return null;
  }
};
