import React from 'react';
import * as Icons from 'react-bootstrap-icons';

import oneliner from '../../../common/oneliner';
import {
  CreatePublicMemoDtoEnSubjectTypeEnum,
  GetProjectDto,
  GetPurchaseInvoiceDto,
  GetSalesInvoiceDto,
  GetSpecialInvoicingDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import {
  subjectType,
  SubjectType,
} from '../../../common/pokCore/contexts/MemoContext';
import { useTranslation } from '../../../common/hooks/useTranslation';

export default (props: { subject: SubjectType; maxlen: number }) => {
  const type = subjectType(props.subject);
  const { t, tk } = useTranslation('commonLng');

  const icon = () => {
    switch (type) {
      case CreatePublicMemoDtoEnSubjectTypeEnum.Project:
        return <Icons.CardChecklist />;
      case CreatePublicMemoDtoEnSubjectTypeEnum.PurchaseInvoice:
        return <Icons.Search />;
      case CreatePublicMemoDtoEnSubjectTypeEnum.SaleInvoice:
        return <Icons.CardList />;
      case CreatePublicMemoDtoEnSubjectTypeEnum.SpecialInvoicing:
        return <Icons.CardChecklist />;
    }
  };

  const identity = () => {
    switch (type) {
      case CreatePublicMemoDtoEnSubjectTypeEnum.Project: {
        const project = props.subject as GetProjectDto;
        return `${t(tk.prLang.projectNo)} ${project.systemNumber}`;
      }
      case CreatePublicMemoDtoEnSubjectTypeEnum.PurchaseInvoice: {
        const purchaseInvoice = props.subject as GetPurchaseInvoiceDto;
        return `${t(tk.memo.purchaseInvoice)} ${purchaseInvoice.systemNumber}`;
      }
      case CreatePublicMemoDtoEnSubjectTypeEnum.SaleInvoice: {
        const saleInvoice = props.subject as GetSalesInvoiceDto;
        return `${t(tk.memo.saleInvoice)} ${saleInvoice.systemNumber}`;
      }
      case CreatePublicMemoDtoEnSubjectTypeEnum.SpecialInvoicing: {
        const specialInvoicing = props.subject as GetSpecialInvoicingDto;
        return `${t(tk.memo.specialInvoicing)} ${specialInvoicing.systemNumber}`;
      }
    }
  };

  return (
    <div className="d-flex text-start align-items-center">
      {icon()}
      <span className="ms-1 flex-fill">
        {oneliner(identity(), props.maxlen)}
      </span>
    </div>
  );
};
