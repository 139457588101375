/*eslint complexity: ["error", 35]*/
import React from 'react';

import {
  CreateUpdateEstimateItemDto,
  GetPositionDto,
  GetProfileDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import CurrencyControl from '../../../app/components/CurrencyControl';

import { isFieldHidden } from './CommissionService';
import ProfileFieldName from './ProfileFieldName';

interface SalesParamsProps {
  estimateItem: CreateUpdateEstimateItemDto;
  profile?: GetProfileDto;
  position?: GetPositionDto;
  readonly?: boolean;
  lang: string;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const SalesParams: React.FC<SalesParamsProps> = props => {
  const schema = props.profile?.schema;

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    if (props.propertyChange) {
      props.propertyChange(obj);
    }
  };

  return (
    <>
      {schema?.rcSalesTotal && (
        <ProfileFieldName
          controlId="rcSalesTotal"
          schema={schema.rcSalesTotal}
          lang={props.lang}
        >
          <CurrencyControl
            value={props.estimateItem?.rcSalesTotal}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                rcSalesTotal: value,
              })
            }
            allowNegative
          />
        </ProfileFieldName>
      )}
      {schema?.salesSurchargePercent && (
        <ProfileFieldName
          controlId="salesSurchargePercent"
          schema={schema?.salesSurchargePercent}
          lang={props.lang}
        >
          <CurrencyControl
            value={props.estimateItem?.salesSurchargePercent}
            percentage={true}
            decimalScale={20}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                salesSurchargePercent: value,
              })
            }
            allowNegative
          />
        </ProfileFieldName>
      )}
      {schema?.salesDiscountPercent && (
        <ProfileFieldName
          controlId="salesDiscountPercent"
          schema={schema?.salesDiscountPercent}
          lang={props.lang}
        >
          <CurrencyControl
            value={props.estimateItem?.salesDiscountPercent}
            percentage={true}
            max={100}
            decimalScale={20}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                salesDiscountPercent: value,
              })
            }
            allowNegative
          />
        </ProfileFieldName>
      )}
      {schema?.salesNet &&
        !isFieldHidden(
          'salesNet',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <ProfileFieldName
            controlId="salesNet"
            schema={schema?.salesNet}
            lang={props.lang}
          >
            <CurrencyControl
              value={props.estimateItem?.salesNet}
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  salesNet: value,
                })
              }
              allowNegative
            />
          </ProfileFieldName>
        )}
      {schema?.salesCommissionPercent &&
        !isFieldHidden(
          'salesCommissionPercent',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <ProfileFieldName
            controlId="salesCommissionPercent"
            schema={schema?.salesCommissionPercent}
            lang={props.lang}
          >
            <CurrencyControl
              value={props.estimateItem?.salesCommissionPercent}
              percentage={true}
              decimalScale={12}
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  salesCommissionPercent: value,
                })
              }
              allowNegative
            />
          </ProfileFieldName>
        )}
      {schema?.salesCommissionAmount &&
        !isFieldHidden(
          'salesCommissionAmount',
          props.estimateItem.positionType,
          props.profile?.commission,
        ) && (
          <ProfileFieldName
            controlId="salesCommissionAmount"
            schema={schema?.salesCommissionAmount}
            lang={props.lang}
          >
            <CurrencyControl
              value={props.estimateItem?.salesCommissionAmount}
              disabled={props.readonly}
              onChange={value =>
                propertyChange({
                  salesCommissionAmount: value,
                })
              }
              allowNegative
            />
          </ProfileFieldName>
        )}
      {schema?.salesNetTechnicalCost && (
        <ProfileFieldName
          controlId="salesNetTechnicalCost"
          schema={schema?.salesNetTechnicalCost}
          lang={props.lang}
        >
          <CurrencyControl
            value={props.estimateItem?.salesNetTechnicalCost}
            disabled={props.readonly}
            onChange={value =>
              propertyChange({
                salesNetTechnicalCost: value,
              })
            }
            allowNegative
          />
        </ProfileFieldName>
      )}
    </>
  );
};
