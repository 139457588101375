import React, { useCallback, useEffect, useState } from 'react';

import {
  CreateUpdateAttachmentDto,
  GetAttachmentDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { forAssociatedDocuments } from '../../../common/pokCore/contexts/AttachmentContext';
import { notesForPurchaseInvoiceView } from '../../../common/pokCore/contexts/PurchaseInvoiceContext';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AddAttachment } from '../Attachments/AddAttachment';
import { Attachment } from '../Attachments/Attachment';
import { AttachmentsGrid } from '../Attachments/AttachmentsGrid';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface PurchaseInvoiceEditorNotesProps {
  purchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  id?: string;
}

export const PurchaseInvoiceEditorNotes: React.FC<
  PurchaseInvoiceEditorNotesProps
> = props => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('fvzLng');
  const [noteDictionaryId, setNoteDictionaryId] = useState<string>();

  const [attachments, setAttachments] = useState<
    AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>
  >(forAssociatedDocuments(pok, notifications, []));

  const refreshFunc = useCallback(() => {
    if (props.purchaseInvoice && props.id) {
      notesForPurchaseInvoiceView(pok, notifications, props.id, refreshFunc)
        .then(model => {
          setAttachments(model.attachments);
        })
        .catch(err => {
          notifications.caughtError(err);
        });
    }
  }, [notifications, pok, props.id, props.purchaseInvoice]);

  useEffect(() => {
    pok.dictionaries
      .getByDictionaryType('Załączniki')
      .then(values => {
        const foundDictionary = values.find(
          ({ shortname }) => shortname === 'NOTE',
        );
        if (foundDictionary) {
          setNoteDictionaryId(foundDictionary.id);
        }
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [notifications, pok.dictionaries]);

  useEffect(() => {
    refreshFunc();
  }, [refreshFunc]);

  return (
    <div className="pt-4">
      <AssociatedDocuments
        name={t(tk.finance.notes)}
        createModalType={AddAttachment}
        presentModalType={Attachment}
        gridType={AttachmentsGrid}
        docs={attachments}
        onRefresh={() => refreshFunc()}
        companyId={props.purchaseInvoice?.companyId}
        dictionaryId={noteDictionaryId}
        type="notes"
      />
    </div>
  );
};
