import React, { ErrorInfo, ReactNode } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Component } from 'react';
import type { TFunction } from 'i18next';

import { TKeys } from '../../hooks/useTranslation';

interface Props {
  children: ReactNode;
  t: TFunction<'commonLng', 'commonLng'>;
  tk: TKeys<'commonLng'>;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    console.error('ErrorBoundary caught the error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Modal centered={true} backdrop="static" show={true}>
          <Modal.Body>
            <Alert variant="danger" className="m-0">
              <Alert.Heading>
                {this.props.t(this.props.tk.common.sysError)}
              </Alert.Heading>
              <hr />
              {this.props.t(this.props.tk.common.alertNotifyAdmin)}
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => window.open('/', '_self')}
                  variant="outline-danger"
                >
                  {this.props.t(this.props.tk.button.close)}
                </Button>
              </div>
            </Alert>
          </Modal.Body>
        </Modal>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
