/*eslint max-lines-per-function: ["error", 211]*/
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import {
  GetExcelDataDto,
  GetExcelRowDto,
  GetExcelRowDtoPositionTypeEnum,
  GetPositionExtendedDtoPositionTypeEnum,
  ParametersSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { ExcelImportStatusEnum } from '../../../common/pokCore/validation/enums';
import FormRow from '../../../app/components/FormRow';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { ExcelPreviewGrid } from './ExcelPreviewGrid';

interface ExcelPreviewDataProps {
  data: GetExcelDataDto;
  handleCompareExcelAndPOK: (excel: GetExcelDataDto) => void;
}

export const ExcelPreviewData: React.FC<ExcelPreviewDataProps> = props => {
  const [hideErrors, setHideErrors] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [aggregatePositions, setAggregatePositions] = useState(false);
  const [excelRows, setExcelRows] = useState<GetExcelRowDto[]>(
    props.data.excelRows,
  );
  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('excelImportLng');

  const hideErrorsChanged = (show: boolean) => {
    setHideErrors(show);
    setRefresh(!refresh);
  };

  const aggregatePositionsChanged = (aggregate: boolean) => {
    setAggregatePositions(aggregate);
    pok.projectExcelImports
      .recheckData(props.data, aggregate)
      .then(res => {
        setExcelRows(res.excelRows);
      })
      .catch(err => {
        notifications.caughtError(err);
      });
  };

  const updateParams = (
    rowId: string,
    key: string,
    newValue: string,
    dictValue: string,
    dictValuePl: string,
    dictValueEn: string,
  ) => {
    props.data.excelRows.forEach(row => {
      if (row.rowId === rowId) {
        (row[(key + 'ValuePl') as keyof GetExcelRowDto] as string) =
          dictValuePl;
        (row[(key + 'ValueEn') as keyof GetExcelRowDto] as string) =
          dictValueEn;
        (row[key as keyof GetExcelRowDto] as string) = newValue;
      }
    });
    recheckData();
  };

  const changePosition = (
    rowId: string,
    newPositionName: string,
    newPositionId: string,
    newPositionType: GetPositionExtendedDtoPositionTypeEnum,
  ) => {
    props.data.excelRows.forEach(row => {
      if (row.rowId === rowId) {
        row.positionId = newPositionId;
        row.positionName = newPositionName;
        row.positionType = newPositionType;
      }
    });
    recheckData();
  };

  const updatePosition = (
    obj: Partial<GetExcelRowDto>,
    newParameters?: ParametersSchemaDto,
  ) => {
    if (obj.positionId && obj.excelPositionName && obj.positionName) {
      props.data.excelRows.forEach(row => {
        if (row.excelPositionName === obj.excelPositionName) {
          row.positionId = obj.positionId;
          row.positionName = obj.positionName;
          row.positionType =
            obj.positionType || GetExcelRowDtoPositionTypeEnum.Standard;
          row.positionNotFoundError = undefined;
          if (
            row.otherErrors.length === 0 &&
            row.parameterErrors.length === 0
          ) {
            row.status = ExcelImportStatusEnum.OK;
          }
          updateMissingParameters(row, newParameters);
        }
      });
      recheckData();
    }
  };

  const recheckData = () => {
    pok.projectExcelImports
      .recheckData(props.data, aggregatePositions)
      .then(res => {
        setExcelRows(res.excelRows);
      })
      .catch(err => {
        notifications.caughtError(err);
      });
    setRefresh(!refresh);
  };

  const updateMissingParameters = (
    row: GetExcelRowDto,
    newParameters?: ParametersSchemaDto,
  ) => {
    if (newParameters) {
      for (const key in newParameters) {
        if (
          newParameters[key as keyof ParametersSchemaDto] !== undefined &&
          row[key as keyof GetExcelRowDto] === undefined
        ) {
          (row[key as keyof GetExcelRowDto] as unknown) =
            newParameters[key as keyof ParametersSchemaDto];
        }
      }
    }
  };

  const errorCount = excelRows.filter(
    row => row.status === ExcelImportStatusEnum.Error && !row.emptyRow,
  ).length;

  const allCount = excelRows.filter(row => !row.emptyRow).length;

  return (
    <>
      <Card.Title className="pb-3">{t(tk.import.positionsPreview)}</Card.Title>

      <div className="side-by-side">
        <div className="left">
          <p>
            {t(tk.import.imported)} <b>{allCount}</b>{' '}
            {t(tk.import.positionsGenitive)} (<b>{allCount - errorCount}</b>{' '}
            {t(tk.import.correct)}, <b>{errorCount}</b> {t(tk.import.error)})
          </p>
        </div>
        <div className="right">
          <p>
            {t(tk.import.fileName)}: <b>{props.data.fileName}</b> <br />
            {t(tk.import.configName)}: <b>{props.data.config.name}</b> <br />
            {props.data.projectName && (
              <>
                {t(tk.prLang.project)}: <b>{props.data.projectName}</b> <br />
              </>
            )}
            {props.data.projectMonth && (
              <>
                {t(tk.common.month)}: <b>{props.data.projectMonth}</b>
              </>
            )}
          </p>
        </div>
      </div>
      <div>
        <Row>
          <Col md={8}>
            <Form className="d-grid gap-3">
              <FormRow
                controlId="aggregatePositions"
                label={t(tk.import.aggregatePositions)}
              >
                <Form.Check
                  type="checkbox"
                  id="aggregatePositions"
                  className="pt-2"
                  checked={aggregatePositions}
                  onChange={e => aggregatePositionsChanged(e.target.checked)}
                />
              </FormRow>
            </Form>
            <Form className="d-grid gap-3 mb-3">
              <FormRow
                controlId="hideErrors"
                label={t(tk.import.hideErrorPositions)}
              >
                <Form.Check
                  type="checkbox"
                  id="hideErrors"
                  className="pt-2"
                  checked={hideErrors}
                  onChange={e => hideErrorsChanged(e.target.checked)}
                />
              </FormRow>
            </Form>
          </Col>
        </Row>
      </div>
      <ExcelPreviewGrid
        data={excelRows || []}
        columns={props.data?.columns || []}
        config={props.data?.config}
        otherRequiredColumns={props.data?.otherRequiredColumns || []}
        hideErrors={hideErrors}
        refresh={refresh}
        updatePosition={updatePosition}
        changePosition={changePosition}
        updateParams={updateParams}
      />
      <div className="d-flex justify-content-end mt-3">
        <Button
          onClick={() =>
            props.handleCompareExcelAndPOK({ ...props.data, excelRows })
          }
        >
          {t(tk.import.goToImport)} <Icon.ArrowRight />
        </Button>
      </div>
    </>
  );
};
