import React from 'react';
import type { TFunction } from 'i18next';

import PieChartProgressBar from '../../../app/components/PieChartProgressBar';
import { GridRecord } from '../../../common/components/Grid/GridDataTypes';
import numberFormatter from '../../../common/numberFormatter';
import {
  GetDictionaryDto,
  GetEstimateItemDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import mathUtils from '../../../utils/mathUtils';
import { TKeys } from '../../../translations';

export const getItemsGridData = (
  items: GetEstimateItemDto[],
  t: TFunction<'projectLng', 'projectLng'>,
  tk: TKeys<'projectLng'>,
  lang: string,
) => {
  const data: GridRecord[] = [];

  const getDictionaryPlEnValue = (dictionary: GetDictionaryDto) =>
    lang === 'PL' ? dictionary?.value : dictionary?.valueEn;

  for (const d of items) {
    const purchaseAmountToAccept = mathUtils.subtract(
      mathUtils.round(d.purchaseNetTotal),
      mathUtils.round(d.purchaseInvoicesSum),
    );
    const salesAmountToInvoice = mathUtils.subtract(
      mathUtils.round(d.salesNetTotal),
      mathUtils.round(d.salesInvoicesSum),
    );
    data.push({
      key: d.id,
      values: {
        positionName: d.position.name,
        rcSalesTotal: numberFormatter(d.rcSalesTotal, true),
        salesNetTotal: numberFormatter(d.salesNetTotal, true),
        salesNet: numberFormatter(d.salesNet, true),
        salesCommissionAmount: numberFormatter(d.salesCommissionAmount, true),
        salesDiscountPercent: d.salesDiscountPercent,
        salesSurchargePercent: d.salesSurchargePercent,
        salesNetTechnicalCost: numberFormatter(d.salesNetTechnicalCost, true),
        rcPurchase: numberFormatter(d.rcPurchase, true),
        purchaseNetTotal: numberFormatter(d.purchaseNetTotal, true),
        purchaseNet: numberFormatter(d.purchaseNet, true),
        purchaseTechnicalCost: numberFormatter(d.purchaseTechnicalCost, true),
        purchaseDiscountPercent: d.purchaseDiscountPercent,
        purchaseSurchargePercent: d.purchaseSurchargePercent,
        profileCategory: d.profileCategory?.name,
        additionalDescription: d.additionalDescription,
        categoryInternet: getDictionaryPlEnValue(d.categoryInternet),
        isUpSell: d.isUpSell ? t(tk.common.yes) : t(tk.common.no),
        netCustomerAccount: d.netCustomerAccount,
        numberOfMedia: d.numberOfMedia,
        orderNumber: d.orderNumber,
        ownName: d.ownName,
        technologyDictionary: getDictionaryPlEnValue(d.technologyDictionary),
        regionDictionary: getDictionaryPlEnValue(d.regionDictionary),
        formatRadio: getDictionaryPlEnValue(d.formatRadio),
        formatPress: getDictionaryPlEnValue(d.formatPress),
        formatInternet: getDictionaryPlEnValue(d.formatInternet),
        formatInternetST: getDictionaryPlEnValue(d.formatInternetST),
        emissionTypeRadio: getDictionaryPlEnValue(d.emissionTypeRadio),
        emissionTypeCinema: getDictionaryPlEnValue(d.emissionTypeCinema),
        purchaseFormInternet: getDictionaryPlEnValue(d.purchaseFormInternet),
        purchaseFormInternetST: getDictionaryPlEnValue(
          d.purchaseFormInternetST,
        ),
        categoryInternetST: getDictionaryPlEnValue(d.categoryInternetST),
        categoryLabcon: getDictionaryPlEnValue(d.categoryLabcon),
        copySize: getDictionaryPlEnValue(d.copySize),
        purchaseMethod: getDictionaryPlEnValue(d.purchaseMethod),
        medium: getDictionaryPlEnValue(d.medium),
        labconChannel: getDictionaryPlEnValue(d.labconChannel),
        service: d.service,
        action: d.action,
        actionGroup: d.actionGroup,
        salesAmountToInvoice: numberFormatter(salesAmountToInvoice, true),
        purchaseAmountToAccept: numberFormatter(purchaseAmountToAccept, true),
        salesAmountToInvoicePB: (
          <PieChartProgressBar
            currentVal={mathUtils.round(d.salesInvoicesSum)}
            text={t(tk.estimates.invoiced)}
            maxVal={mathUtils.round(d.salesNetTotal)}
            width={20}
            height={20}
          />
        ),
        purchaseAmountToAcceptPB: (
          <PieChartProgressBar
            currentVal={mathUtils.round(d.purchaseInvoicesSum)}
            text={t(tk.estimates.accepted)}
            maxVal={mathUtils.round(d.purchaseNetTotal)}
            width={20}
            height={20}
          />
        ),
      },
    });
  }
  return data;
};

export const getSummary = (
  data: GetEstimateItemDto[],
  t: TFunction<'projectLng', 'projectLng'>,
  tk: TKeys<'projectLng'>,
) => {
  let rcSalesTotal = 0;
  let salesNetTotal = 0;
  let salesNet = 0;
  let salesCommissionAmount = 0;
  let salesNetTechnicalCost = 0;
  let rcPurchase = 0;
  let purchaseNetTotal = 0;
  let purchaseNet = 0;
  let purchaseTechnicalCost = 0;
  let salesAmountToInvoice = 0;
  let purchaseAmountToAccept = 0;
  let salesInvoicesSum = 0;
  let purchaseInvoicesSum = 0;

  data.map(rec => {
    rcSalesTotal = mathUtils.add(
      rcSalesTotal,
      mathUtils.round(rec.rcSalesTotal),
    );
    salesNetTotal = mathUtils.add(
      salesNetTotal,
      mathUtils.round(rec.salesNetTotal),
    );
    salesNet = mathUtils.add(salesNet, mathUtils.round(rec.salesNet));
    salesCommissionAmount = mathUtils.add(
      salesCommissionAmount,
      mathUtils.round(rec.salesCommissionAmount),
    );
    salesNetTechnicalCost = mathUtils.add(
      salesNetTechnicalCost,
      mathUtils.round(rec.salesNetTechnicalCost),
    );
    rcPurchase = mathUtils.add(rcPurchase, mathUtils.round(rec.rcPurchase));
    purchaseNetTotal = mathUtils.add(
      purchaseNetTotal,
      mathUtils.round(rec.purchaseNetTotal),
    );
    purchaseNet = mathUtils.add(purchaseNet, mathUtils.round(rec.purchaseNet));
    purchaseTechnicalCost = mathUtils.add(
      purchaseTechnicalCost,
      mathUtils.round(rec.purchaseTechnicalCost),
    );
    salesAmountToInvoice = mathUtils.add(
      salesAmountToInvoice,
      mathUtils.subtract(
        mathUtils.round(rec.salesNetTotal),
        mathUtils.round(rec.salesInvoicesSum),
      ),
    );
    purchaseAmountToAccept = mathUtils.add(
      purchaseAmountToAccept,
      mathUtils.subtract(
        mathUtils.round(rec.purchaseNetTotal),
        mathUtils.round(rec.purchaseInvoicesSum),
      ),
    );
    salesInvoicesSum = mathUtils.add(
      salesInvoicesSum,
      mathUtils.round(rec.salesInvoicesSum),
    );
    purchaseInvoicesSum = mathUtils.add(
      purchaseInvoicesSum,
      mathUtils.round(rec.purchaseInvoicesSum),
    );
  });
  return {
    key: 'summary',
    values: {
      positionName: t(tk.common.sumUpperCase),
      rcSalesTotal: numberFormatter(rcSalesTotal, true),
      salesNetTotal: numberFormatter(salesNetTotal, true),
      salesNet: numberFormatter(salesNet, true),
      salesCommissionAmount: numberFormatter(salesCommissionAmount, true),
      salesNetTechnicalCost: numberFormatter(salesNetTechnicalCost, true),
      rcPurchase: numberFormatter(rcPurchase, true),
      purchaseNetTotal: numberFormatter(purchaseNetTotal, true),
      purchaseNet: numberFormatter(purchaseNet, true),
      purchaseTechnicalCost: numberFormatter(purchaseTechnicalCost, true),
      salesAmountToInvoice: numberFormatter(salesAmountToInvoice, true),
      purchaseAmountToAccept: numberFormatter(purchaseAmountToAccept, true),
      salesAmountToInvoicePB: (
        <PieChartProgressBar
          currentVal={salesInvoicesSum}
          text={t(tk.estimates.invoiced)}
          maxVal={salesNetTotal}
          width={20}
          height={20}
        />
      ),
      purchaseAmountToAcceptPB: (
        <PieChartProgressBar
          currentVal={purchaseInvoicesSum}
          text={t(tk.estimates.accepted)}
          maxVal={purchaseNetTotal}
          width={20}
          height={20}
        />
      ),
    },
  };
};
