import React from 'react';
import { Trash } from 'react-bootstrap-icons';
import moment from 'moment/moment';

import { GetAttachmentDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import momencik from '../../../common/momencik';
import {
  GridColumn,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import { Grid } from '../../../common/components/Grid/Grid';
import { AttachmentObjectTypeObj } from '../../../common/pokCore/validation/enums';
import SelectOptionInfo from '../Selects/SelectOptionInfo';
import oneliner from '../../../common/oneliner';
import mathUtils from '../../../utils/mathUtils';
import numberFormatter from '../../../common/numberFormatter';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';

import { AttachmentViewTypes } from './AttachmentViewTypes';
import useIsEditAttachmentAllowedProjects from './useIsEditAttachmentAllowedProjects';

export const AttachmentsGrid = (props: {
  documents: GetAttachmentDto[];
  onClick: (doc: GetAttachmentDto) => void;
  onDelete: (id: string) => Promise<void>;
  readOnly?: boolean;
  type?: AttachmentViewTypes;
}) => {
  const { t, tk } = useTranslation('commonLng');
  const isDeleteAllow = useIsEditAttachmentAllowedProjects(props.type);
  const isNote = props.type === 'notes';
  const pok = usePokCore();
  const lang = pok.getLanguage();

  const COLUMNS: GridColumn[] = [
    ...(isNote ? [] : [{ header: t(tk.attachment.type), property: 'type' }]),
    { header: t(tk.common.title), property: 'description' },
    { header: t(tk.common.name), property: 'name' },
    ...(isNote
      ? []
      : [{ header: t(tk.attachment.invoicing), property: 'client.name' }]),
    ...(isNote
      ? []
      : [
          {
            header: t(tk.attachment.positionsSum),
            property: 'positionsSum',
            noSorting: true,
          },
        ]),
    { header: t(tk.common.addDate), property: 'date' },
    ...(isNote
      ? []
      : [{ header: t(tk.attachment.relatedTo), property: 'relationType' }]),
    { header: t(tk.attachment.note), property: 'note', noSorting: true },
    { header: t(tk.common.creator), property: 'creator', noSorting: true },
    ...(props.readOnly
      ? []
      : [{ header: '', property: 'deleteLink', noSorting: true }]),
  ];
  const NOTE_MAX_LENGTH = 150;
  const data = (props.documents || []).map(document => {
    const didCreatedLessThanHourAgo = !moment(document.creationTs).isBefore(
      moment().subtract(1, 'hours'),
    );

    return {
      key: document.id,
      values: {
        name:
          document.name === 'Notatka' ? t(tk.attachment.note) : document.name,
        description: document.description,
        note: document.note && (
          <>
            {oneliner(document.note, NOTE_MAX_LENGTH)}
            <SelectOptionInfo
              label={document.note}
              title={t(tk.attachment.note)}
              placement="auto"
            />
          </>
        ),
        sortDate: new Date(document.creationTs || new Date()).getTime(),
        date: momencik(document.creationTs),
        type:
          lang === 'PL'
            ? document.dictionaryType.value
            : document.dictionaryType.valueEn,
        relationType: t(AttachmentObjectTypeObj[document.enObjectType]),
        creator: document.creator?.name,
        'client.name': document?.client?.name,
        positionsSum: document?.estimateItems?.length
          ? numberFormatter(
              document?.estimateItems?.reduce(
                (acc, { estimateItem }) =>
                  mathUtils.add(acc, estimateItem?.purchaseNetTotal || 0),
                0,
              ),
              true,
            )
          : '',

        deleteLink: (isDeleteAllow || didCreatedLessThanHourAgo) && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            className="d-flex justify-content-center"
            onClick={e => e.stopPropagation()}
          >
            <ConfirmationButton
              variant="outline-danger"
              confirmation={
                isNote
                  ? t(tk.attachment.askDeleteNote)
                  : t(tk.attachment.askDeleteAttachment)
              }
              tooltip={
                isNote
                  ? t(tk.attachment.deleteNote)
                  : t(tk.attachment.deleteAttachment)
              }
              onOK={() => props.onDelete(document.id)}
            >
              <Trash size={16} />
            </ConfirmationButton>
          </div>
        ),
      },
    };
  }) as unknown as GridRecord[];

  return (
    <Grid
      data={data}
      columns={COLUMNS}
      showFilter={true}
      onRowClick={
        props.readOnly
          ? undefined
          : (id: string) => {
              const foundDocument = props.documents.find(doc => doc.id === id);
              if (foundDocument) {
                props.onClick(foundDocument);
              }
            }
      }
      emptyGridMessage={
        isNote ? t(tk.attachment.noNotes) : t(tk.attachment.noAttachments)
      }
      initialOrderColumn="sortDate"
      initialOrderDirection="DESC"
    />
  );
};
