import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import FormRow from '../../../app/components/FormRow';
import ValidationAlert from '../../../app/components/ValidationAlert';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import Button from '../../../common/components/Button/Button';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ReasonInputProps {
  objectGenitiveName: string;
  reason: string | undefined;
  setReason: Dispatch<SetStateAction<string | undefined>>;
  showError: boolean;
  reasonText?: string;
}

const ReasonInput: React.FC<ReasonInputProps> = props => {
  const { t, tk } = useTranslation('projectLng');
  return (
    <>
      <Form className="d-grid mb-3">
        <FormRow
          controlId="reason"
          label={`${t(tk.projects.cancelReason)} ${props.objectGenitiveName}`}
        >
          <DynamicTextarea
            value={props.reason || ''}
            onChange={e => props.setReason(e.target.value)}
            autoFocus={true}
          />
        </FormRow>
      </Form>
      <ValidationAlert
        show={props.showError}
        errors={[
          `${t(tk.projects.fillCancelReason)} ${props.objectGenitiveName}.`,
        ]}
      />
    </>
  );
};

export default (
  props: React.PropsWithChildren<{
    objectGenitiveName: string;
    applicationExists: boolean;
    className?: string;
    reason?: string;
    onCreateUpdateCancelApplication: (reason?: string) => Promise<void>;
    onRemoveCancelApplication?: () => Promise<void>;
  }>,
) => {
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState<string>();
  const [showError, setShowError] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const variant = 'outline-danger';

  const { t, tk } = useTranslation('projectLng');

  const removeCancelApplication = async () => {
    setIsLoadingRemove(true);
    if (props.onRemoveCancelApplication) {
      await props.onRemoveCancelApplication();
    }
    setIsLoadingRemove(false);
    setShow(false);
  };

  const addCancelApplication = async () => {
    if (!reason) {
      setShowError(true);
      return;
    }
    setIsLoadingSave(true);
    await props.onCreateUpdateCancelApplication(reason);
    setIsLoadingSave(false);
    setShow(false);
  };

  useEffect(() => {
    setShowError(false);
    setReason(props.reason);
  }, [show, props.reason]);

  return (
    <>
      <Button
        className={props.className}
        variant={variant}
        onClick={() => setShow(true)}
      >
        {props.children}
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size={'lg'}
        centered={true}
        backdrop="static"
        backdropClassName="modal-on-modal-backdrop"
        className="modal-on-modal"
      >
        <Modal.Header>
          <Modal.Title>{`${t(tk.projects.cancelApplication)} ${props.objectGenitiveName}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReasonInput
            {...props}
            showError={showError}
            reason={reason}
            setReason={setReason}
          />
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShow(false)} variant="outline-secondary">
              {t(tk.button.cancel)}
            </Button>
            <Button
              className="mx-1"
              onClick={addCancelApplication}
              variant={variant}
              isLoading={isLoadingSave}
            >
              {props.applicationExists
                ? t(tk.projects.saveApplication)
                : t(tk.projects.createApplication)}
            </Button>
            {props.onRemoveCancelApplication && props.applicationExists && (
              <ConfirmationButton
                className="mx-1"
                variant={variant}
                onOK={removeCancelApplication}
                confirmation={t(tk.projects.askRemoveApplication)}
                isLoading={isLoadingRemove}
              >
                {t(tk.projects.deleteApplication)}
              </ConfirmationButton>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
