import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { CreateUpdatePurchaseInvoiceDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import FormRow, {
  FormRow66,
  FormRow66R,
} from '../../../app/components/FormRow';
import { NewDatePicker } from '../../../app/components/NewDatePicker';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface PurchaseInvoiceEditorFormGeneralFormDatesProps {
  purchaseInvoice?: CreateUpdatePurchaseInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdatePurchaseInvoiceDto>) => void;
  readOnly?: boolean;
}

export const PurchaseInvoiceGeneralFormDates: React.FC<
  PurchaseInvoiceEditorFormGeneralFormDatesProps
> = props => {
  const { t, tk } = useTranslation('fvzLng');

  const handleDateIssueChange = (issueDate: Date) => {
    if (props.propertyChange) {
      props.propertyChange({ issueDate });
    }
  };

  const handleDateSellChange = (sellDate: Date) => {
    if (props.propertyChange) {
      props.propertyChange({ sellDate });
    }
  };

  const handleDatePaymentChange = (paymentDate: Date) => {
    if (props.propertyChange) {
      props.propertyChange({ paymentDate });
    }
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <FormRow66 controlId="issueDate" label={t(tk.common.issueDate)}>
            <NewDatePicker
              value={props.purchaseInvoice?.issueDate}
              dateChanged={handleDateIssueChange}
              readOnly={props.readOnly}
            />
          </FormRow66>
        </Col>
        <Col md={6}>
          <FormRow66R controlId="sellDate" label={t(tk.common.saleDate)}>
            <NewDatePicker
              value={props.purchaseInvoice?.sellDate}
              dateChanged={handleDateSellChange}
              readOnly={props.readOnly}
            />
          </FormRow66R>
        </Col>
      </Row>
      <FormRow controlId="paymentDate" label={t(tk.finance.paymentDate)}>
        <NewDatePicker
          value={props.purchaseInvoice?.paymentDate}
          dateChanged={handleDatePaymentChange}
          readOnly={props.readOnly}
        />
      </FormRow>
    </>
  );
};
