/*eslint max-lines-per-function: ["error", 534]*/
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Select from 'react-select';
import 'react-calendar/dist/Calendar.css';

import { Option } from '../Selects/Selector';
import CompanySelector from '../Selects/CompanySelector';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  CurrencyIsoTypeEnum,
  InvoiceTypeEnum,
  InvoiceTypeObj,
  PurchaseInvoiceStatusEnum,
  PurchaseInvoiceStatusObj,
} from '../../../common/pokCore/validation/enums';
import BudgetSelector from '../Selects/BudgetSelector';
import { ClientSelector } from '../Selects/ClientSelector';
import Button from '../../../common/components/Button/Button';
import TeamSelector from '../Selects/TeamSelector';
import { getCurrencyOptions } from '../../../utils/currencyFormatUtils';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { NewDateRangePicker } from '../../../app/components/NewDateRangePicker';

export type FilterYearReportData = {
  year: number;
  companyId?: string;
  type?: InvoiceTypeEnum;
  budget?: string;
  currency?: CurrencyIsoTypeEnum;
  clientId?: string;
  saleDateFrom?: Date;
  saleDateTo?: Date;
  invoiceDateFrom?: Date;
  invoiceDateTo?: Date;
  status?: PurchaseInvoiceStatusEnum;
  teamId?: string;
};

interface FilterYearProps {
  onSearch: (filter: FilterYearReportData) => void;
  reportName: string;
  withoutCompany?: boolean;
  withInvoiceType?: boolean;
  withBudget?: boolean;
  withCurrency?: boolean;
  withClient?: boolean;
  withDataSales?: boolean;
  withDataInvoices?: boolean;
  withStatus?: boolean;
  withTeam?: boolean;
  isLoading?: boolean;
}

export const FilterYear: React.FC<FilterYearProps> = props => {
  const pok = usePokCore();
  const [showNewAlert, setShowNewAlert] = useState(false);

  const [year, setYear] = useState<number>();
  const [yearsOptions, setYearsOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [companyId, setCompanyId] = useState<string>();
  const [type, setType] = useState<InvoiceTypeEnum>();
  const [budget, setBudget] = useState<string>();
  const [currency, setCurrency] = useState<CurrencyIsoTypeEnum>();
  const [clientId, setClientId] = useState<string>();
  const [status, setStatus] = useState<PurchaseInvoiceStatusEnum>();
  const [teamId, setTeamId] = useState<string>();

  const [saleDate, setSaleDate] = useState<[Date | null, Date | null] | null>();
  const [invoiceDate, setInvoiceDate] = useState<
    [Date | null, Date | null] | null
  >();

  const { t, tk } = useTranslation('reportLng');

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const options = [];
    for (let year: number = 2024; year <= currentYear + 1; year++) {
      options.push({
        value: year,
        label: String(year),
      });
    }
    setYearsOptions(options);
    setYear(currentYear);
    setCompanyId(undefined);
    setBudget(undefined);
    setCurrency(undefined);
    setType(undefined);
    setClientId(undefined);
    setSaleDate(undefined);
    setInvoiceDate(undefined);
    setStatus(undefined);
    setTeamId(undefined);
  }, []);

  const handleClear = () => {
    setYear(new Date().getFullYear());
    setCompanyId('');
    setBudget('');
    setClientId('');
    setCurrency(undefined);
    setType(undefined);
    setShowNewAlert(false);
    setSaleDate(undefined);
    setInvoiceDate(undefined);
    setStatus(undefined);
    setTeamId(undefined);
  };

  const handleSearch = () => {
    if (!year) {
      setShowNewAlert(true);
      return;
    }
    setShowNewAlert(false);
    props.onSearch({
      year: year,
      companyId: props.withoutCompany ? pok.getCompanyId() : companyId,
      type: type,
      budget: budget,
      currency: currency,
      clientId: clientId,
      saleDateFrom: saleDate && saleDate[0] ? saleDate[0] : undefined,
      saleDateTo: saleDate && saleDate[1] ? saleDate[1] : undefined,
      invoiceDateFrom:
        invoiceDate && invoiceDate[0] ? invoiceDate[0] : undefined,
      invoiceDateTo: invoiceDate && invoiceDate[1] ? invoiceDate[1] : undefined,
      status: status,
      teamId: teamId,
    });
  };

  const typeOptions = Object.entries(InvoiceTypeEnum).map(([key, value]) => ({
    value: key as InvoiceTypeEnum,
    label: t(InvoiceTypeObj[value]),
  }));

  const statusOptions = Object.entries(PurchaseInvoiceStatusEnum).map(
    ([key, value]) => ({
      value: key as PurchaseInvoiceStatusEnum,
      label: t(PurchaseInvoiceStatusObj[value]),
    }),
  );

  return (
    <Card className="mb-2">
      <Card.Header>{props.reportName}</Card.Header>
      <Card.Body>
        <Form className="d-grid gap-3">
          <Row>
            <Form.Group as={Row} controlId="dateFrom">
              <Form.Label column sm="3">
                {t(tk.common.year)}
              </Form.Label>
              <Col sm="9">
                <Select
                  value={yearsOptions.find(({ value }) => value === year)}
                  options={yearsOptions}
                  onChange={option => setYear(Number(option?.value))}
                />
              </Col>
            </Form.Group>
          </Row>
          {!props.withoutCompany && (
            <Row>
              <Form.Group as={Row} controlId="dateFrom">
                <Form.Label column sm="3">
                  {t(tk.common.company)}
                </Form.Label>
                <Col sm="9">
                  <CompanySelector
                    value={companyId}
                    onChange={option => {
                      setCompanyId((option as Option)?.value);
                    }}
                    clearable={true}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withTeam && (
            <Row>
              <Form.Group as={Row} controlId="teamId">
                <Form.Label column sm="3">
                  {t(tk.common.team)}
                </Form.Label>
                <Col sm="9">
                  <TeamSelector
                    value={teamId}
                    companyId={
                      props.withoutCompany ? pok.getCompanyId() : companyId
                    }
                    onChange={option => {
                      setTeamId((option as Option)?.value);
                    }}
                    clearable={true}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withInvoiceType && (
            <Row>
              {' '}
              <Form.Group as={Row} controlId="invoiceType">
                <Form.Label column sm="3">
                  {t(tk.filter.invoiceType)}
                </Form.Label>
                <Col sm="9">
                  <Select
                    value={
                      type
                        ? typeOptions.find(({ value }) => type === value)
                        : null
                    }
                    options={typeOptions}
                    onChange={option => setType(option?.value)}
                    placeholder={t(tk.component.pointOut)}
                    isClearable={true}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withStatus && (
            <Row>
              {' '}
              <Form.Group as={Row} controlId="status">
                <Form.Label column sm="3">
                  {t(tk.filter.invoiceStatus)}
                </Form.Label>
                <Col sm="9">
                  <Select
                    value={
                      status
                        ? statusOptions.find(({ value }) => status === value)
                        : null
                    }
                    options={statusOptions}
                    onChange={option => setStatus(option?.value)}
                    placeholder={t(tk.component.pointOut)}
                    isClearable={true}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withBudget && (
            <Row>
              <Form.Group as={Row} controlId="budget">
                <Form.Label column sm="3">
                  {t(tk.common.budget)}
                </Form.Label>
                <Col sm="9">
                  <BudgetSelector
                    value={budget}
                    onChange={option => setBudget(option?.value)}
                    clearable={true}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withCurrency && (
            <Row>
              <Form.Group as={Row} controlId="currency">
                <Form.Label column sm="3">
                  {t(tk.common.currency)}
                </Form.Label>
                <Col sm="9">
                  <Select
                    value={
                      currency
                        ? getCurrencyOptions().find(
                            ({ value }) => currency === value,
                          )
                        : null
                    }
                    options={getCurrencyOptions()}
                    onChange={option => setCurrency(option?.value)}
                    placeholder={t(tk.component.pointOut)}
                    isClearable={true}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withClient && (
            <Row>
              <Form.Group as={Row} controlId="client">
                <Form.Label column sm="3">
                  {t(tk.common.client)}
                </Form.Label>
                <Col sm="9">
                  <ClientSelector
                    valueId={clientId}
                    onChange={option => {
                      setClientId((option as Option)?.value);
                    }}
                    clearable={true}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withDataSales && (
            <Row>
              <Form.Group as={Row} controlId="dataSales">
                <Form.Label column sm="3">
                  {t(tk.filter.dateSale)}
                </Form.Label>
                <Col sm="9">
                  <div className="small-calendar">
                    <NewDateRangePicker
                      onChange={value => {
                        setSaleDate(
                          value as unknown as [Date | null, Date | null] | null,
                        );
                      }}
                      value={saleDate}
                    />
                  </div>
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withDataInvoices && (
            <Row>
              <Form.Group as={Row} controlId="dataInvoices">
                <Form.Label column sm="3">
                  {t(tk.filter.dateInvoices)}
                </Form.Label>
                <Col sm="9">
                  <div className="small-calendar">
                    <NewDateRangePicker
                      onChange={value => {
                        setInvoiceDate(
                          value as unknown as [Date | null, Date | null] | null,
                        );
                      }}
                      value={invoiceDate}
                    />
                  </div>
                </Col>
              </Form.Group>
            </Row>
          )}
        </Form>

        {showNewAlert && (
          <Alert variant="danger" className="mt-3">
            {t(tk.filter.yearGive)}
          </Alert>
        )}

        <div className="d-flex justify-content-end mt-3">
          <Button className="me-2" variant="outline-dark" onClick={handleClear}>
            {t(tk.button.clean)}
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleSearch}
            isLoading={props.isLoading}
          >
            {t(tk.button.export)}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
