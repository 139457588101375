import React from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';

import {
  CreateUpdateEstimateItemDto,
  GetProfileDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import CurrencyControl from '../../../app/components/CurrencyControl';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { calculateFields } from './FieldCalculator';
import { SalesParams } from './SalesParams';
import { PurchaseParams } from './PurchaseParams';
import {
  isFieldHidden,
  isPurchaseTotalDisabled,
  isSalesTotalDisabled,
} from './CommissionService';
import { SpecialSalesParams } from './SpecialSalesParams';
import { SpecialSalesParams2 } from './SpecialSalesParams2';
import ProfileFieldName from './ProfileFieldName';

interface SalesTabProps {
  estimateItem: CreateUpdateEstimateItemDto;
  project?: GetProjectDto;
  profile?: GetProfileDto;
  readonly?: boolean;
  isSalesBlocked?: boolean;
  isPurchaseBlocked?: boolean;
  lang: string;
  propertyChange?: (obj: Partial<CreateUpdateEstimateItemDto>) => void;
}

export const SalesTab: React.FC<SalesTabProps> = props => {
  const schema = props.profile?.schema;
  const { t, tk } = useTranslation('projectLng');

  const propertyChange = (obj: Partial<CreateUpdateEstimateItemDto>) => {
    obj = calculateFields(obj, schema, props.estimateItem);
    if (props.propertyChange) {
      props.propertyChange(obj);
    }
  };

  return (
    <>
      {props.isSalesBlocked && (
        <Alert variant="warning">{t(tk.estimates.profileSalesBlockade)}</Alert>
      )}
      {props.isPurchaseBlocked && (
        <Alert variant="warning">
          {t(tk.estimates.profilePurchaseBlockade)}
        </Alert>
      )}
      <Row>
        <Col className="col-lg-6 col-12 mb-1">
          <Form className="d-grid gap-4 mb-3 pe-3">
            <SalesParams
              estimateItem={props.estimateItem}
              profile={props.profile}
              propertyChange={propertyChange}
              readonly={props.isSalesBlocked}
              lang={props.lang}
            />
            <SpecialSalesParams
              estimateItem={props.estimateItem}
              profile={props.profile}
              propertyChange={propertyChange}
              readonly={props.isSalesBlocked}
              lang={props.lang}
            />
            <SpecialSalesParams2
              estimateItem={props.estimateItem}
              profile={props.profile}
              propertyChange={propertyChange}
              readonly={props.isSalesBlocked}
              lang={props.lang}
            />
          </Form>
        </Col>
        <Col className="col-lg-6 col-12 mb-1">
          <Form className="d-grid gap-4 mb-3 ps-3">
            <PurchaseParams
              estimateItem={props.estimateItem}
              profile={props.profile}
              propertyChange={propertyChange}
              readonly={props.isPurchaseBlocked}
              lang={props.lang}
            />
          </Form>
        </Col>
        <Col className="col-lg-6 col-12 mb-1">
          <Form className="d-grid gap-4 mb-3 pe-3">
            {schema?.salesNetTotal && (
              <ProfileFieldName
                controlId="salesNetTotal"
                schema={schema?.salesNetTotal}
                lang={props.lang}
              >
                <CurrencyControl
                  value={props.estimateItem?.salesNetTotal}
                  className="border-bold"
                  onChange={value =>
                    propertyChange({
                      salesNetTotal: value,
                    })
                  }
                  disabled={
                    isSalesTotalDisabled(
                      schema,
                      props.estimateItem,
                      props.profile?.commission,
                    ) || props.isSalesBlocked
                  }
                  allowNegative
                />
              </ProfileFieldName>
            )}
          </Form>
        </Col>
        <Col className="col-lg-6 col-12 mb-1">
          <Form className="d-grid gap-4 mb-3 ps-3">
            {schema?.purchaseNetTotal &&
              !isFieldHidden(
                'purchaseNetTotal',
                props.estimateItem.positionType,
                props.profile?.commission,
              ) && (
                <ProfileFieldName
                  controlId="purchaseNetTotal"
                  schema={schema?.purchaseNetTotal}
                  lang={props.lang}
                >
                  <CurrencyControl
                    value={props.estimateItem?.purchaseNetTotal}
                    className="border-bold"
                    decimalScale={2}
                    onChange={value =>
                      propertyChange({
                        purchaseNetTotal: value,
                      })
                    }
                    disabled={
                      isPurchaseTotalDisabled(
                        schema,
                        props.estimateItem,
                        props.profile?.commission,
                      ) || props.isPurchaseBlocked
                    }
                    allowNegative
                  />
                </ProfileFieldName>
              )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
