/*eslint max-lines-per-function: ["error", 190]*/
import React, { useMemo } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { GridGetterFunction } from '../../../common/components/Grid/GridDataTypes';

import { PurchaseInvoiceSearch } from './PurchaseInvoiceSearch';

enum PurchaseInvoicesTabs {
  ALL = 'all',
  SETTLED = 'settled',
  WITHOUT_TEAMS_PROJECTS = 'withoutTeamsProjects',
  GENERATED = 'generated',
}

export const PurchaseInvoicesView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const { t, tk } = useTranslation('fvzLng');

  const handleClick = (id: string = 'utworz', newWindow?: boolean) => {
    nav.purchaseInvoiceEditor(id, newWindow);
  };

  const getDataGenerated = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.purchaseInvoices.getAllGeneratedByPortion(
          t,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok, t],
  );

  const getDataSettled = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.purchaseInvoices.getAllGeneratedSettledByPortion(
          t,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok, t],
  );

  const getWithoutTeams = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.purchaseInvoices.getAllWithoutTeamsAndProjectsPortion(
          t,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok, t],
  );

  const getDataByPortion = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.purchaseInvoices.getAllByPortion(
          t,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
        )) as GridGetterFunction,
    [pok, t],
  );

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.finance.purchaseInvoices)}
        </Card.Title>
        <Button
          variant="outline-primary"
          onClick={() => handleClick()}
          className="mb-3"
        >
          {t(tk.finance.newFvz)}
        </Button>
        <TabsHistory
          activeKey={PurchaseInvoicesTabs.GENERATED}
          id="purchaseInvoicesView"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={PurchaseInvoicesTabs.GENERATED}
            title={t(tk.finance.generated)}
            className="m-3"
          >
            <PurchaseInvoiceSearch
              getData={getDataGenerated}
              getCount={pok.purchaseInvoices.getCountGenerated}
              onRowClick={handleClick}
              withCountDays={true}
              displayCurrency={true}
              displayTeamsProjects={true}
              withFilterInColumns={true}
              showNewWindowButton={true}
            />
          </Tab>
          <Tab
            eventKey={PurchaseInvoicesTabs.SETTLED}
            title={t(tk.finance.settled)}
            className="m-3"
          >
            <PurchaseInvoiceSearch
              getData={getDataSettled}
              getCount={pok.purchaseInvoices.getCountGeneratedSettled}
              onRowClick={handleClick}
              withCountDays={true}
              displayCurrency={true}
              displayTeamsProjects={true}
              withFilterInColumns={true}
              showNewWindowButton={true}
            />
          </Tab>
          <Tab
            eventKey={PurchaseInvoicesTabs.WITHOUT_TEAMS_PROJECTS}
            title={t(tk.prLang.withoutTeamsProjects)}
            className="m-3"
          >
            <PurchaseInvoiceSearch
              getData={getWithoutTeams}
              getCount={pok.purchaseInvoices.getCountWithoutTeamsAndProjects}
              onRowClick={handleClick}
              withCountDays={true}
              displayCurrency={true}
              withFilterInColumns={true}
              showNewWindowButton={true}
            />
          </Tab>
          <Tab
            eventKey={PurchaseInvoicesTabs.ALL}
            title={t(tk.finance.all)}
            className="m-3"
          >
            <PurchaseInvoiceSearch
              getData={getDataByPortion}
              getCount={pok.purchaseInvoices.getAllCount}
              onRowClick={handleClick}
              displayStatus={true}
              displayCurrency={true}
              withCountDays={true}
              displayTeamsProjects={true}
              withFilterInColumns={true}
              showNewWindowButton={true}
            />
          </Tab>
        </TabsHistory>
      </Card.Body>
    </Card>
  );
};
