import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateSalesInvoiceDto,
  GetPurchaserDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { InvoiceTypeEnum } from '../../../common/pokCore/validation/enums';
import { Option } from '../Selects/Selector';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface SalesInvoiceBasicDataNumberProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  propertyChange: (obj: Partial<CreateUpdateSalesInvoiceDto>) => void;
  readOnly?: boolean;
  number?: string;
  purchaser?: GetPurchaserDto;
  refresh: boolean;
}

export const SalesInvoiceBasicDataNumber: React.FC<
  SalesInvoiceBasicDataNumberProps
> = props => {
  const [isChangingMode, setIsChangingMode] = useState(false);
  const [numberOptions, setNumberOptions] = useState<Option[]>([]);
  const pok = usePokCore();
  const notifications = useNotifications();

  const { t, tk } = useTranslation('fvsLng');

  const handleChangeMode = () => {
    if (isChangingMode) {
      props.propertyChange({ number: props.number });
      return setIsChangingMode(false);
    }

    return setIsChangingMode(true);
  };

  useEffect(() => {
    const companyId =
      props.salesInvoice.companyId || props.purchaser?.company?.id;
    if (props.salesInvoice && companyId) {
      pok.salesInvoices
        .findAvailableInvoiceNumbers(
          companyId,
          !!props.salesInvoice.parentId,
          props.salesInvoice.type as InvoiceTypeEnum,
          props.salesInvoice.invoiceDate,
        )
        .then(data =>
          setNumberOptions(
            data.map(
              ({ number }) =>
                ({
                  value: number,
                  label: number,
                }) as Option,
            ),
          ),
        )
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.salesInvoice.invoiceDate, props.salesInvoice.type, props.refresh]);

  useEffect(() => {
    if (isChangingMode) {
      setIsChangingMode(!isChangingMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  return (
    <FormRow controlId="systemNumber" label={t(tk.finance.invoiceNumber)}>
      <div className="d-flex gap-2">
        <div className="flex-grow-1">
          {isChangingMode ? (
            <Select
              value={
                props.salesInvoice.number
                  ? numberOptions.find(
                      ({ value }) => props.salesInvoice.number === value,
                    )
                  : null
              }
              options={numberOptions}
              onChange={option => {
                props.propertyChange({
                  number: option ? option.value : props.number,
                });
              }}
              placeholder={t(tk.component.pointOut)}
              isClearable
              isDisabled={props.readOnly}
            />
          ) : (
            <Form.Control type="text" value={props.number || '-'} disabled />
          )}
        </div>
        <div className="flex-grow-0">
          <Button
            variant="outline-secondary"
            onClick={handleChangeMode}
            className="h-100"
            disabled={props.readOnly}
          >
            {isChangingMode ? t(tk.button.cancel) : t(tk.button.change)}
          </Button>
        </div>
      </div>
    </FormRow>
  );
};
