import React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';

import '../../theme/dateRangePicker.css';
import { useTranslation } from '../../common/hooks/useTranslation';

type NewDateRangePickerProps = React.PropsWithChildren<{
  value?: Value;
  onChange?: (value: Value) => void | undefined;
  minDate?: Date;
  maxDate?: Date;
}>;

export const NewDateRangePicker = (props: NewDateRangePickerProps) => {
  const { t, tk } = useTranslation('commonLng');
  return (
    <div className="small-calendar">
      <DateRangePicker
        onChange={props.onChange}
        value={props.value}
        monthAriaLabel="Month"
        maxDetail="year"
        format="MM.yyyy"
        minDate={props.minDate}
        maxDate={props.maxDate}
        locale={t(tk.common.locale)}
      />
    </div>
  );
};
