import { Option } from '../../../pok/components/Selects/Selector';
import { getCountryName } from '../../../utils/getCountryName';
import { DefaultApi, GetCountryDto } from '../autogenerated/pokApiClient';

export interface OptionForCountry extends Option {
  isMain: boolean;
}

export interface CountryContextInterface {
  getById: (id: string) => Promise<GetCountryDto>;
  getMainCountry: () => Promise<GetCountryDto>;
  getOptions: (lang: string, text?: string) => Promise<OptionForCountry[]>;
}

export const CountryContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.countryControllerGet(id),
    getMainCountry: () => api.countryControllerGetMainCountry(),
    getOptions: async (lang: string, text?: string) => {
      const data = await api.countryControllerGetCountriesByName(text ?? '');

      return Array.from(data, c => ({
        value: c.id,
        label: getCountryName(lang, c),
        isMain: c.isMain,
      })).sort((a, b) => a.label.localeCompare(b.label, lang));
    },
  };
};
