import React from 'react';

import {
  GetProfileDto,
  ProfileSchemaDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { Selector, Option, OptionWithLang } from './Selector';

export interface FieldSelectorProps {
  readOnly?: boolean;
  profile: GetProfileDto;
  value?: string;
  lang: string;
  onChange?: (option: OptionWithLang) => void;
  clearable?: boolean;
}

export const FieldSelector: React.FC<FieldSelectorProps> = props => {
  const { t, tk } = useTranslation('projectLng');
  const [values, setValues] = React.useState<OptionWithLang[]>([]);
  const provider = async (text: string) => {
    const coreValues = [
      {
        badge: '',
        label: t(tk.prLang.projectNumber),
        value: 'projectNumber',
        labelPl: 'Numer kampanii',
        labelEn: 'Project number',
      },
      {
        badge: '',
        label: t(tk.common.positionName),
        value: 'positionName',
        labelPl: 'Nazwa pozycji',
        labelEn: 'Position name',
      },
      {
        badge: '',
        label: t(tk.estimates.positionMonth),
        value: 'month',
        labelPl: 'Miesiąc pozycji',
        labelEn: 'Month',
      },
      {
        badge: '',
        label: t(tk.estimates.positionStartDate),
        value: 'startDate',
        labelPl: 'Data rozpoczęcia pozycji',
        labelEn: 'Start date',
      },
      {
        badge: '',
        label: t(tk.estimates.positionEndDate),
        value: 'endDate',
        labelPl: 'Data zakończenia pozycji',
        labelEn: 'End date',
      },
      {
        badge: '',
        label: t(tk.estimates.invoiceInstruction),
        value: 'invoiceInstructionAttachment',
        labelPl: 'Instrukcja fakturowania',
        labelEn: 'Invoice instruction',
      },
    ];
    const values: OptionWithLang[] = [];
    const schemas = props.profile.schema;
    for (const key of Object.keys(schemas)) {
      const value = schemas[key as keyof ProfileSchemaDto];
      if (value !== undefined) {
        values.push({
          badge: '',
          label:
            props.lang === 'PL' ? value.displayNamePl : value.displayNameEn,
          value: key,
          labelPl: value.displayNamePl,
          labelEn: value.displayNameEn,
        });
      }
    }
    values.sort((a, b) => a.label.localeCompare(b.label));
    values.unshift(...coreValues);
    setValues(values);
    return values.filter(v =>
      v.label.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
    );
  };

  const getExtendedOption = (option: Option): OptionWithLang => {
    const value = values.find(o => o.value === option.value);
    return value || (option as OptionWithLang);
  };

  return (
    <Selector
      readOnly={props.readOnly}
      clearable={props.clearable}
      creatable={false}
      provider={provider}
      value={props.value}
      onChange={option =>
        props.onChange && props.onChange(getExtendedOption(option as Option))
      }
    />
  );
};
