export default {
  finance: {
    purchaseInvoices: 'Faktury zakupu',
    FVZ: 'FVZ',
    readyForInvoices: 'Gotowe do fakturowania',
    corrected: 'Korygowane',
    accountToInvoice: 'Kwota do zafakturowania',
    lastModification: 'Ostatnia modyfikacja dowiązań do pozycji',
    imports: 'Importy',
    googleImport: 'Import faktur zakupu z Google',
    googleImportDesc:
      'Import faktur zakupu na podstawie pliku z Google i katalogu z wydrukami faktur',
    importFvz: 'Import FVZ',
    invoice: 'Faktura',
    fileNotFound: 'Nie znaleziono pliku',
    finishDownload: 'Zakończono pobieranie danych.',
    userInCompanyContext:
      'Użytkownik musi być w kontekście spółki, do której mają trafić faktury FVZ',
    assumptions: 'Założenia',
    assumption1: 'W pierwszym wierszu w excelu znajduje się wiersz nagłówkowy',
    assumption2:
      'Jeśli kontrahent ma państwo Polska - faktury są importowane jako krajowe, wpp. jako zagraniczne',
    assumption3: 'Pole z kwotą musi być przez Excel rozpoznawane jako liczba',
    assumption4:
      'Trzy pola z datami powinny być rozpoznawane przez Excel jako data',
    client: 'Fakturujący (kontrahent)',
    excelWithGoogleInvoices: 'Plik Excel zawierający dane o fakturach z Google',
    directoryWithGoogleInvoices:
      'Katalog zawierający faktury wystawione przez Google',
    chooseDirectory: 'Wybierz katalog',
    errorSelectData:
      'Proszę wskazać fakturującego, plik w formacie Excel z danymi i katalog z załącznikami.',
    import: 'Importuj',
    askImport: 'Czy na pewno zaimportować faktury?',
    dataDownload: 'Trwa pobieranie danych. Proszę czekać...',
    archive: 'ARCHIWUM',
    newFvz: 'Nowa faktura zakupu',
    fvz: 'Faktura zakupu',
    for: 'dla',
    packageWithNumber: 'w paczce o nr sys.',
    revertFromSent: 'Cofnięta ze stanu wysłana',
    tvInvoice: 'Faktura TV',
    purchaseInvoicesToBeClarified: 'Faktury zakupu do wyjaśnienia',
    hasNegativeAmounts:
      'Kwota dowiązania z faktury przekracza kwotę pozycji {{estimate}}. Czy na pewno zapisać fakturę?',
    hasMoreThenAvailableAmounts:
      'Kwota dowiązania z faktury przekracza nierozliczoną kwotę pozycji {{estimate}}. Czy na pewno zapisać fakturę?',
    monthFilter: 'Filtruj po miesiącu',
    showSettled: 'Pokaż rozliczone pozycje',
    showSpecialTeam: 'Pokaż pasujące do zespołów specjalistycznych',
    orderNumber: 'Numer zlecenia',
    purchaseNetTotal: 'Netto zakup total',
    notAssignedAmount: 'Nierozliczona kwota',
    fillAllFields: 'Wypełnij wszystkie widoczne pola kwotą nierozliczoną',
    fillNotAssignedAmount: 'Wypełnij kwotą nierozliczoną',
    invoiceAmountUppercase: 'KWOTA FAKTURY',
    invoiceAmount: 'Kwota faktury',
    unsettledAmountUppercase: 'KWOTA NIEROZLICZONA',
    unsettledAmount: 'Kwota nierozliczona',
    nip: 'NIP',
    invoiceNumber: 'Numer faktury',
    invoiceType: 'Typ faktury',
    saveAndConfirm: 'Zapisz i zatwierdź',
    askDeleteFvz: 'Czy na pewno usunąć wyświetlaną fakturę zakupu?',
    askChangeStatus: `Czy na pewno zmienić status faktury na 'Wprowadzona'?`,
    undoState: 'Cofnij stan',
    saveAndStepForward: 'Zapisz i przejdź dalej',
    stepForward: 'Przejdź dalej',
    paymentDate: 'Termin płatności',
    netAmount: 'Kwota netto',
    vatRate: 'Stawka VAT',
    vatAmount: 'Kwota VAT',
    vat: 'VAT',
    askDeletePositions: 'Czy na pewno usunąć wybraną pozycję?',
    deletePosition: 'Usuń pozycję',
    addPosition: 'Dodaj pozycję',
    noPositions: 'Brak pozycji',
    importFvs: 'Importuj FVS',
    selectFvsToImport: 'Wybierz fakturę sprzedaży do zaimportowania',
    yearOfSale: 'Rok sprzedaży',
    warningDifferenceCurrencyImport:
      'W przypadku importowania faktury ze spółki rozliczanej w innej walucie, kwoty faktury nie zostaną zaimportowane.',
    dataImportedFromInvoice: 'Dane zaimportowano z faktury sprzedaży nr',
    editFvzPosition: 'Edycja pozycji faktury zakupu',
    addFvzPosition: 'Dodawanie nowej pozycji faktury zakupu',
    choose: 'Wybierz...',
    forClient: 'dla fakturującego',
    toInvoice: 'do faktury',
    sumClientAmount: 'Netto zakup total fakturującego',
    sumClientAmountWithoutPosition: 'Nierozliczona kwota fakturującego',
    activeProfiles: 'Aktywne profile',
    created: 'Utworzył',
    countDaysFromCreation: 'Liczba dni od utworzenia',
    noInvoices: 'Brak faktur zakupu',
    tvInvoices: 'Faktury zakupu TV',
    unsettled: 'Nierozliczone',
    all: 'Wszystkie',
    settled: 'Wprowadzone, rozliczone',
    generated: 'Wprowadzone',
    teamsToClarify: 'Zespoły do wyjaśnienia',
    fvzSysNumber: 'Faktura zakupu o nr sys.',
    editNetAmount: 'Edycja kwoty netto zakup total',
    purchaseNet: 'Netto zakup total',
    editTotalAmount: 'Edytuj kwotę total',
    exportFvz: 'Eksport faktur zakupu',
    invoicesDomesticToSent: 'FVZ krajowe do wysłania',
    createInvoicesDomesticToSent: 'Utwórz XML z krajowymi FVZ',
    invoicesForeignToSent: 'FVZ zagraniczne do wysłania',
    createInvoicesForeignToSent: 'Utwórz XML z zagranicznymi FVZ',
    invoicesDomesticToSentAgain: 'FVZ krajowe ponownie do wysłania',
    createInvoicesDomesticToSentAgain:
      'Utwórz XML z krajowymi FVZ, które już były wysyłane',
    invoicesForeignToSentAgain: 'FVZ zagraniczne ponownie do wysłania',
    createInvoicesForeignToSentAgain:
      'Utwórz XML z zagranicznymi FVZ, które już były wysyłane',
    packagesFVZ: 'Paczki FVZ',
    oldest: 'najstarszych',
    createCountryXML: 'Utwórz XML z krajowymi FVZ',
    selected: 'zaznaczone',
    askCreatePackage: 'Czy na pewno utworzyć paczkę FVZ?',
    invoicesPackage: 'Paczka XML z fakturami',
    cantDownloadPackage: 'Nie można pobrać paczki.',
    zipPackage: 'Paczka ZIP z wydrukami faktur',
    generatedBy: 'Wygenerowana przez',
    generatedDate: 'Data wygenerowania',
    invoicesType: 'Typ faktur',
    repeat: 'Wysłane ponownie',
    packageCount: 'Liczba faktur w paczce',
    downloadPackage: 'Pobierz paczkę XML',
    downloadPdfs: 'Pobierz wydruki',
    downloadCopies: 'Pobierz kopie',
    noPackages: 'Brak paczek faktur zakupu w systemie',
    notes: 'Notatki',
    general: 'Dane podstawowe',
    openNewWindow: 'Otwórz fakturę w nowym oknie',
    sumExceedsInvoiceAmount:
      'Suma kwot zapisanych przypisań, przekracza kwotę całkowitą faktury.',
    unwrittenUppercase: 'NIEZAPISANA',
    invoicesInPackage:
      'Faktury zawarte w paczce nr sys. {{number}} z dnia {{date}}',
    invoicingTeams: 'Zespoły fakturujące',
  },
} as const;
