import React from 'react';
import { Spinner } from 'react-bootstrap';

import { useTranslation } from '../../../common/hooks/useTranslation';

export default (props: { variant?: string }) => {
  const { t, tk } = useTranslation('commonLng');
  return (
    <Spinner
      animation="border"
      role="status"
      variant={props.variant || 'primary'}
      className="m-auto"
    >
      <span className="visually-hidden">{t(tk.memo.loading)}</span>
    </Spinner>
  );
};
