import React, { useMemo, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { EstimateItemPurchaseDeficienciesSearch } from '../../components/EstimateItemPurchaseDeficiencies/EstimateItemPurchaseDeficienciesSearch';
import { TabsHistory } from '../../../app/components/TabsHistory';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useNavigation } from '../../../common/navigation';
import { MailTeamModal } from '../../components/MailTeam/MailTeamModal';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useAuth } from '../../../common/hooks/useAuth';

enum EstimateItemTabs {
  WITHOUT_TV = 'withoutTv',
  WITH_TV = 'Tv',
}

export const EstimateItemPurchaseDeficienciesView: React.FC = () => {
  const pok = usePokCore();
  const nav = useNavigation();
  const auth = useAuth();
  const { t, tk } = useTranslation('projectLng');
  const [show, setShow] = useState(false);
  const [tv, setTv] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const getDataWithoutTV = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.estimateItems.getPurchaseDeficiencies(
          t,
          tk,
          refresh,
          setRefresh,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
          false,
        )) as GridGetterFunction,
    [pok.estimateItems, refresh, setRefresh, t, tk],
  );

  const getCountWithoutTV = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.estimateItems.getPurchaseDeficienciesCount(
          filterText,
          filterColumns,
          false,
        )) as GridCountFunction,
    [pok.estimateItems],
  );
  const getDataWithTV = useMemo(
    () =>
      ((
        pageSize,
        pageNumber,
        orderBy,
        orderDirection,
        filterText,
        filterColumns,
      ) =>
        pok.estimateItems.getPurchaseDeficiencies(
          t,
          tk,
          refresh,
          setRefresh,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
          filterColumns,
          true,
        )) as GridGetterFunction,
    [pok.estimateItems, refresh, setRefresh, t, tk],
  );

  const getCountWithTV = useMemo(
    () =>
      ((filterText, filterColumns) =>
        pok.estimateItems.getPurchaseDeficienciesCount(
          filterText,
          filterColumns,
          true,
        )) as GridCountFunction,
    [pok.estimateItems],
  );

  const handleClick = async (id: string) => {
    const estimateItem = await pok.estimateItems.getById(id);
    return nav.projectDetails(
      estimateItem.estimateByMonth.project.id,
      true,
      'estimates',
    );
  };

  const handleMailWithoutTv = () => {
    setTv(false);
    setShow(true);
  };

  const handleMailWithTv = () => {
    setTv(true);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">
          {t(tk.attachment.FVZLacks)} {t(tk.prLang.genitiveProject)}
        </Card.Title>

        <TabsHistory
          activeKey={EstimateItemTabs.WITHOUT_TV}
          id="product"
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab
            eventKey={EstimateItemTabs.WITHOUT_TV}
            title={t(tk.attachment.withoutTv)}
            className="m-3"
          >
            {auth.check(PermissionsEnum.Finance) && (
              <Button
                className="mb-4"
                variant="outline-primary"
                onClick={handleMailWithoutTv}
              >
                {t(tk.attachment.sendRemindersWithoutTv)}
              </Button>
            )}
            <EstimateItemPurchaseDeficienciesSearch
              getData={getDataWithoutTV}
              getCount={getCountWithoutTV}
              onRowClick={handleClick}
              refresh={refresh}
            />
          </Tab>
          <Tab
            eventKey={EstimateItemTabs.WITH_TV}
            title={t(tk.attachment.withTv)}
            className="m-3"
          >
            {auth.check(PermissionsEnum.Finance) && (
              <Button
                className="mb-4"
                variant="outline-primary"
                onClick={handleMailWithTv}
              >
                {t(tk.attachment.sendRemindersTv)}
              </Button>
            )}
            <EstimateItemPurchaseDeficienciesSearch
              getData={getDataWithTV}
              getCount={getCountWithTV}
              onRowClick={handleClick}
              refresh={refresh}
            />
          </Tab>
        </TabsHistory>
        <MailTeamModal show={show} handleClose={handleClose} tv={tv} />
      </Card.Body>
    </Card>
  );
};
