import React, { useEffect, useState } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { GetProfileCategoryDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { Selector, Option } from './Selector';

export interface ProfileCategorySelectorProps {
  value?: string;
  profileId: string;
  readOnly?: boolean;
  className?: string;
  clearable?: boolean;
  autoSelect?: boolean;
  onChange?: (option: Option) => void;
}

export default (props: ProfileCategorySelectorProps) => {
  const [profileCategories, setProfileCategories] = useState<
    GetProfileCategoryDto[]
  >([]);
  const [value, setValue] = useState<string>();
  const pok = usePokCore();
  const notifications = useNotifications();

  useEffect(() => {
    pok.profileCategories
      .getByProfile(props.profileId)
      .then(res => {
        setProfileCategories(res);
      })
      .catch(err => {
        notifications.caughtError(err);
      });
  }, [notifications, pok.profileCategories, props.profileId]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    } else if (profileCategories.length === 1 && props.autoSelect) {
      setValue(profileCategories[0].id);
      if (props.onChange) {
        props.onChange({
          badge: '',
          label: profileCategories[0].name,
          value: profileCategories[0].id,
        });
      }
    } else {
      setValue(undefined);
    }
  }, [profileCategories, props, props.value]);

  const provider = async (text: string) => {
    const values = profileCategories.filter(v =>
      v.name?.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
    );

    return values.map(v => {
      return {
        badge: '',
        label: v.name,
        value: v.id,
      };
    });
  };

  return (
    <Selector
      uniqueKey={Date.now().toString()}
      className={props.className}
      readOnly={props.readOnly}
      provider={provider}
      creatable={false}
      clearable={props.clearable}
      value={value}
      deletedValueName={id => pok.profileCategories.getOptionById(id)}
      onChange={option => props.onChange && props.onChange(option as Option)}
    />
  );
};
