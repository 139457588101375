import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetClientWithInvoiceNumbers } from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';

interface ClientWithoutSymphonySearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  withMerging?: boolean;
  clientHeadId?: string;
  refresh?: boolean;
}

export const clientWithInvoiceNumbersForGrid = (
  client: GetClientWithInvoiceNumbers & { headId?: string },
) => {
  return {
    key: client.id,
    values: {
      'client.systemNumber': client.systemNumber,
      'client.name': client.name,
      'client.nip': client.nip,
      invoice_numbers: client.invoiceNumbers,
    },
  };
};

export const ClientWithoutSymphonySearch: React.FC<
  ClientWithoutSymphonySearchProps
> = props => {
  const { t, tk } = useTranslation('clientLng');
  const columns: GridColumn[] = [
    { header: t(tk.common.sysNumber), property: 'client.systemNumber' },
    { header: t(tk.common.name), property: 'client.name' },
    { header: t(tk.client.nip), property: 'client.nip' },
    {
      header: t(tk.client.invoiceNumber),
      property: 'invoice_numbers',
      noSorting: true,
      noFilter: true,
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="client.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.client.noClients)}
      additionalId={props.clientHeadId}
      onRowClick={props.onRowClick}
      refresh={props.refresh}
      withHistory={true}
      withFilterInColumns={true}
    />
  );
};
