export default {
  order: {
    specialTeam: 'Zespół specjalistyczny',
    receivers: 'Odbiorcy',
    rejectedNote: 'Uzasadnienie odrzucenia',
    noOrders: 'Brak zamówień',
    askDeleteOrder: 'Czy na pewno chcesz usunąć to zamówienie?',
    deleteOrder: 'Usuń zamówienie',
    orderRejection: 'Odrzucenie zamówienia',
    rejectionReason: 'Powód odrzucenia',
    rejectOrder: 'Odrzuć zamówienie',
    fillRejectionReason: 'Proszę uzupełnić powód odrzucenia zamówienia.',
    acceptOrder: 'Zaakceptuj zamówienie',
    orderAcceptance: 'Akceptacja zamówienia  (nr sys. {{sysNumber}})',
    orderDetails: 'Szczegóły zamówienia (nr sys. {{sysNumber}})',
    orderEdit: 'Edycja zamówienia (nr sys. {{sysNumber}})',
    createOrder: 'Utwórz zamówienie',
    deleted: 'usunięte',
    sendEmailOfToSpecialTeam:
      'Wyślij e-mail na adres zespołu specjalistycznego',
    sendEmailToTeam: 'Wyślij e-mail na adres zespołu',
    newOrder: 'Nowe zamówienie',
    askChangeMonthConsequences:
      'Zmiana miesiąca spowoduje dostosowanie pozycji w realizacji zamówienia. Czy na pewno kontynuować?',
    formalsConditions: 'Warunki formalne',
    orders: 'Zamówienia',
    sent: 'Wysłane',
    waiting: 'Otrzymane oczekujące',
    completed: 'Otrzymane obsłużone',
    incomingOrder: 'Zamówienie przychodzące',
    outgoingOrder: 'Zamówienie wychodzące',
  },

  orderExec: {
    askDeleteOrderExec: 'Czy na pewno usunąć tę realizację zamówienia?',
    deleteOrderExecution: 'Usuń realizację',
    deleteOrderExec: 'Usuń realizację',
    getConfirmation: 'Pobierz potwierdzenie',
    rejectionOrderExec: 'Odrzucenie realizacji zamówienia',
    rejectOrderExec: 'Odrzuć realizację zamówienia',
    acceptOrderExec: 'Zaakceptuj realizację zamówienia',
    pleaseFillRejectionReason: 'Proszę uzupełnić powód odrzucenia',
    rejectionReason: 'Powód odrzucenia',
    orderExecDetails: 'Szczegóły realizacji zamówienia (nr sys. {{sysNumber}})',
    orderExecEdit: 'Edycja realizacji zamówienia (nr sys. {{sysNumber}})',
    createOrderExec: 'Utwórz realizację zamówienia',
    warningDifferentCurrencyCompanies:
      'Realizacja zamówienia dotyczy spółek pracujących na różnych walutach. Kontrola zgodności kwot została wyłączona.',
    mergePositions: 'Scal pozycje',
    clonePosition: 'Klonuj pozycję',
    askResetPositions: 'Czy na pewno chcesz zresetować pozycje?',
    resetPositions: 'Resetuj wszystkie pozycje',
    mergePositionsByActionGroup: 'Scal{{selected}}pozycje według grupy działań',
    selected: 'wybrane',
    ordersExec: 'Realizacje zamówień',
    acceptOrderExecSysNum:
      'Akceptacja realizacji zamówienia (nr sys. {{sysNumber}})',
    purchaser: 'zamawiający',
    forRealizationTeam: 'dla zespołu realizującego',
    realizationMonth: 'Miesiąc realizacji',
    realizationTeam: 'zespół realizujący',
    realizationTeamUpperCase: 'Zespół realizujący',
    realizationAmount: 'kwota realizacji',
    actionGroup: 'Grupa działań',
    amount: 'Kwota',
    noOrderExec: 'Brak realizacji zamówień',
    newOrderExec: 'Nowa realizacja zamówienia',
    basicData: 'Dane podstawowe',
    positions: 'Pozycje',
    polishPrintout: 'wydruk po polsku',
    englishPrintout: 'wydruk po angielsku',
    incomingOrder: 'Realizacja zamówienia przychodząca',
    outgoingOrder: 'Realizacja zamówienia wychodząca',
  },
} as const;
