import React from 'react';

import { GridColumn } from '../../../common/components/Grid/GridDataTypes';
import { Grid } from '../../../common/components/Grid/Grid';
import { GetEstimateItemDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import numberFormatter from '../../../common/numberFormatter';
import { buildParametersString } from '../Orders/OrderEstimateItemsGrid';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { usePokCore } from '../../../common/hooks/usePokCore';

interface EstimateItemsCloneGridProps {
  data: GetEstimateItemDto[];
  enableMultiSelect?: boolean;
  selectedKeys?: string[];
  onRowClick?: (key: string) => Promise<void> | void | undefined;
}

export const EstimateItemsCloneGrid: React.FC<
  EstimateItemsCloneGridProps
> = props => {
  const { t, tk } = useTranslation('projectLng');
  const pok = usePokCore();
  const columns: GridColumn[] = [
    { header: t(tk.common.name), property: 'position.name' },
    { header: t(tk.estimates.month), property: 'estimateByMonth.date' },
    { header: t(tk.estimates.profile), property: 'profile.name' },
    { header: t(tk.common.client), property: 'client.name' },
    {
      header: t(tk.estimates.parameters),
      property: 'parameters',
      noSorting: true,
    },
    {
      header: t(tk.estimates.salesNetTotal),
      property: 'estimateItem.salesNetTotal',
    },
    {
      header: t(tk.estimates.purchaseNetTotal),
      property: 'estimateItem.purchaseNetTotal',
    },
  ];

  const data = props.data.map(estimateItem => {
    return {
      key: estimateItem.id,
      values: {
        'position.name': estimateItem.position.name,
        'estimateByMonth.date': momencik(
          estimateItem.estimateByMonth.date,
          'YYYY-MM',
        ),
        'client.name':
          estimateItem.position.client.shortname ||
          estimateItem.position.client.name,
        'profile.name': estimateItem.estimateByMonth.profile.name,
        'estimateItem.salesNetTotal': numberFormatter(
          estimateItem.salesNetTotal || '0',
          true,
        ),
        'estimateItem.purchaseNetTotal': numberFormatter(
          estimateItem.purchaseNetTotal || '0',
          true,
        ),
        parameters: buildParametersString(estimateItem, pok.getLanguage()),
      },
      orderByStringValues: {
        name: estimateItem.position.name,
      },
    };
  });

  return (
    <Grid
      key={props.data.length}
      data={data}
      columns={columns}
      showFilter={true}
      initialOrderColumn="position.name"
      pageSize={10}
      emptyGridMessage={t(tk.estimates.noPositions)}
      enableMultiSelect={props.enableMultiSelect}
      selectedKeys={props.selectedKeys}
      onRowClick={props.onRowClick}
    />
  );
};
