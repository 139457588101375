import React, { useEffect, useState } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { getCountryName } from '../../../utils/getCountryName';
import { OptionForCountry } from '../../../common/pokCore/contexts/CountryContext';

import { Option, Selector } from './Selector';

export interface CountryIdAndIsMain {
  countryId: string;
  isMain: boolean;
}

export interface CountrySelectorProps {
  onChange?: (data: CountryIdAndIsMain) => void;
  updateCurrentCountryIsMain?: (isMain: boolean) => void;
  value?: string;
  readOnly?: boolean;
}

export default (props: CountrySelectorProps) => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [value, setValue] = useState<string>();
  const [allCountries, setAllCountries] = useState<OptionForCountry[]>();

  useEffect(() => {
    let getCountryPromise = undefined;

    if (props.value) {
      getCountryPromise = pok.countries.getById(props.value);
    } else {
      getCountryPromise = pok.countries.getMainCountry();
    }

    getCountryPromise
      .then(country => {
        setValue(getCountryName(pok.getLanguage(), country));
        if (!props.value && props.onChange) {
          props.onChange({ countryId: country.id, isMain: country.isMain });
        }
        if (props.updateCurrentCountryIsMain) {
          props.updateCurrentCountryIsMain(country.isMain);
        }
      })
      .catch(errorResponse => {
        notifications.caughtError(errorResponse);
      });
  }, [props.value, notifications, pok, props]);

  const provider = async (text: string) => {
    if (!allCountries) {
      setAllCountries(await pok.countries.getOptions(pok.getLanguage(), ''));
    }
    const filteredContries = await pok.countries.getOptions(
      pok.getLanguage(),
      text,
    );
    return filteredContries;
  };

  const handleCountryChange = (option: Option) => {
    if (allCountries) {
      const optionForCountry = allCountries.find(o => o.value === option.value);

      if (optionForCountry) {
        if (props.updateCurrentCountryIsMain) {
          props.updateCurrentCountryIsMain(optionForCountry.isMain);
        }

        const data: CountryIdAndIsMain = {
          countryId: optionForCountry.value,
          isMain: optionForCountry.isMain,
        };

        if (props.onChange) {
          props.onChange(data);
        }
      }
    }
  };

  return (
    <Selector
      creatable={false}
      provider={provider}
      onChange={option => handleCountryChange(option as Option)}
      value={value}
      readOnly={props.readOnly}
    />
  );
};
