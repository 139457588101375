import React, { useMemo } from 'react';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { useTranslation } from '../../../common/hooks/useTranslation';
import { GridGetterFunction } from '../../../common/components/Grid/GridDataTypes';

import { ApplicationSearch } from './ApplicationSearch';

interface UserApplicationTabProps {
  refresh?: boolean;
  onRowClick?: (key: string) => void;
}

export const UserApplicationTab: React.FC<UserApplicationTabProps> = props => {
  const pok = usePokCore();
  const { t, tk } = useTranslation('applicationLng');

  const getData = useMemo(
    () =>
      ((pageSize, pageNumber, orderBy, orderDirection, filterText) =>
        pok.applications.getAllByPortionByCurrentUser(
          t,
          tk,
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          filterText,
        )) as GridGetterFunction,
    [pok.applications, t, tk],
  );

  return (
    <ApplicationSearch
      getData={getData}
      getCount={pok.applications.getCountByCurrentUser}
      hideAuthorColumn={true}
      hideCompanyColumn={true}
      {...props}
    />
  );
};
