import React from 'react';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { CreateUpdateSalesInvoiceDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import { InvoiceStatusEnum } from '../../../common/pokCore/validation/enums';
import Button from '../../../common/components/Button/Button';
import { useAuth } from '../../../common/hooks/useAuth';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { useTranslation } from '../../../common/hooks/useTranslation';

import { SalesInvoiceDeactivateConfirm } from './SalesInvoiceDeactivateConfirm';

interface SalesInvoiceSummaryControlsProps {
  salesInvoice: CreateUpdateSalesInvoiceDto;
  handleRemove: () => void;
  readOnly?: boolean;
  isDeactivated?: boolean;
  handleChangeToSent: () => void;
  handleChangeToDraft: () => void;
  handleChangeToGenerated: () => void;
  handleSave: () => void;
  isOnePositionEnabled: boolean;
  isOnePositionOnInvoice: boolean;
  isLoadingSave: boolean;
  isLoadingStatusDraft: boolean;
  isLoadingStatusSent: boolean;
  isLoadingStatusGenerated: boolean;
}

const isChangeToGeneratedAllowed = (
  salesInvoice: CreateUpdateSalesInvoiceDto,
  isPermission: boolean,
) => salesInvoice.status === InvoiceStatusEnum.Sent && isPermission;

const isChangeToSentAndDraftAllowed = (
  salesInvoice: CreateUpdateSalesInvoiceDto,
  readOnly?: boolean,
  isDeactivated?: boolean,
) =>
  [InvoiceStatusEnum.Generated].includes(
    salesInvoice.status as InvoiceStatusEnum,
  ) &&
  readOnly &&
  !isDeactivated;

export const SalesInvoiceSummaryControls: React.FC<
  SalesInvoiceSummaryControlsProps
> = props => {
  const auth = useAuth();
  const pok = usePokCore();
  const { t, tk } = useTranslation('fvsLng');

  const isFinanceAdminUser = auth.check(
    PermissionsEnum.FinanceAdministration,
    pok.getCompanyId(),
  );

  const isLoading =
    props.isLoadingSave ||
    props.isLoadingStatusDraft ||
    props.isLoadingStatusSent ||
    props.isLoadingStatusGenerated;

  return (
    <div className="d-flex justify-content-end gap-2">
      <SalesInvoiceDeactivateConfirm
        invoiceStatus={props.salesInvoice.status}
        isDeactivated={props.isDeactivated}
        handleRemove={props.handleRemove}
        disabled={isLoading}
      />
      {isChangeToSentAndDraftAllowed(
        props.salesInvoice,
        props.readOnly,
        props.isDeactivated,
      ) && (
        <>
          <ConfirmationButton
            variant="outline-primary"
            confirmation={t(tk.finance.handleChangeToSent)}
            onOK={props.handleChangeToSent}
            disabled={props.isLoadingSave}
            isLoading={props.isLoadingStatusSent}
          >
            {t(tk.finance.markAsSent)}
          </ConfirmationButton>
          <ConfirmationButton
            variant="outline-primary"
            confirmation={t(tk.finance.handleChangeToDraft)}
            onOK={props.handleChangeToDraft}
            disabled={props.isLoadingSave}
            isLoading={props.isLoadingStatusDraft}
          >
            {t(tk.finance.undoApproval)}
          </ConfirmationButton>
        </>
      )}
      {isChangeToGeneratedAllowed(props.salesInvoice, isFinanceAdminUser) && (
        <ConfirmationButton
          variant="outline-primary"
          confirmation={t(tk.finance.handleChangeToGenerated)}
          onOK={props.handleChangeToGenerated}
          disabled={props.isLoadingSave}
          isLoading={props.isLoadingStatusGenerated}
        >
          {t(tk.finance.undoSend)}
        </ConfirmationButton>
      )}
      <Button
        variant="primary"
        onClick={props.handleSave}
        disabled={
          props.readOnly ||
          props.isLoadingStatusSent ||
          props.isLoadingStatusDraft ||
          (!props.isOnePositionEnabled && props.isOnePositionOnInvoice)
        }
        isLoading={props.isLoadingSave}
      >
        {t(tk.button.save)}
      </Button>
    </div>
  );
};
